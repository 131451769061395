// @flow
import React from 'react';

import l from 'helpers/locale';

function renderRow(contact, ...keys) {
  const fields = keys.filter(key => contact[key] !== '');

  if (!fields.length) return null;

  return (
    <React.Fragment>
      <span>
        {fields.map((field, key) => (
          <React.Fragment key={key}>{contact[field]}</React.Fragment>
        ))}
      </span>
      <br />
    </React.Fragment>
  );
}

function ListRowRender({ row, label, selectRow }) {
  return (
    <div key={row.contact_id} className="popup__inner__container__body__item">
      <button
        type="button"
        className="gw--no-guts big-info-btn"
        onClick={() => selectRow(row)}>
        <span className="g g-1/1">
          <label
            className="form__label--small push--smallest--bottom"
            htmlFor="__">
            {l(label)}
          </label>
        </span>
        <span className="g g-1/2 big-info-btn__info">
          <span className="text--dk--medium">{row.full_name}</span>
        </span>
        <span className="g g-1/2 big-info-btn__info__right">
          <span className="text--dk-medium">
            {renderRow(row, 'email')}
            {renderRow(row, 'street')}
            {renderRow(row, 'postal_code', 'city')}
          </span>
        </span>
      </button>
    </div>
  );
}

export default ListRowRender;
