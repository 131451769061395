import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateSimpleList } from 'components/ConnectedSimpleList/actions';
import { SimplePopup, Overlay } from '@bonlineza/b-lib';
import l from 'helpers/locale';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import FooterBar from 'components/FooterBar';
import ButtonGroup from 'components/ButtonGroup';
import GraphQLPageReady from 'components/GraphQLPageReady';
import request from 'constants/request';
import { ids as RoleId } from 'constants/roles';
import axios from 'helpers/axios.js';
import formatErrors from 'helpers/formatErrors';
import { transitions } from 'constants/complianceStates';
import {
  toggleEdit,
  submitContractorRelationship,
} from './components/CustomerRelationshipEdit/actions.js';
import { rateContractor } from './actions';
import RatingModal from './components/RatingModal';
import { getContractorQuery } from './query';
import Cards from './cards';

const {
  ContactsCard,
  JobsCard,
  MainCard,
  PropertyCard,
  NotificationsCard,
  RatingsCard,
  CustomerCard,
  ComplianceQuestionnaireCard,
} = Cards;

type ReduxActionShape = {
  toggleCustomerEdit: Function,
  submitContractor: Function,
  updateList: Function,
};

type PropsShape = {
  dataUpdatedCB: Function,
} & ReduxActionShape;

type StateShape = {
  data: Object,
  message: string,
};

class SingleContractor extends React.Component<PropsShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      request: {
        ...request,
      },
      data: {},
      compliancePopup: {
        visible: false,
        title: '',
        body: '',
        options: [],
      },
      ratingPopup: {
        visible: false,
        errors: [],
      },
    };
  }

  state: StateShape;

  componentDidMount() {
    this.props.toggleCustomerEdit({ toggleMode: false });
  }

  componentDidUpdate(prevProps: PropsShape) {
    if (
      prevProps.match.params.contractor_id !==
        this.props.match.params.contractor_id &&
      this.props.match.params.contractor_id !== undefined
    ) {
      this.fetchData();
    }
  }

  onGraphQLResponse = responseData => {
    const contractor = responseData?.data?.data?.vmp_contractor;
    this.setState({
      data: contractor,
    });
    this.props.dataUpdatedCB(contractor);
  };

  getFooterButtons = () => {
    const { data } = this.state;
    const { location } = this.props;

    if (this.props.isEditting) {
      const actions = [];
      actions.push({
        id: 'action-contractor-cancel',
        type: 'text',
        label: l('cancel'),
        onClick: this.toggleEditState,
      });
      actions.push({
        id: 'action-contractor-submit',
        type: 'default',
        label: l('submit'),
        onClick: this.submitContractor,
      });
      return actions;
    }

    const filterVisibleProfileTransitions = trans =>
      ['submit_invitation', 'rate'].includes(trans.name);

    const filterVisibleCustomerTransitions = trans => trans.name === 'update';

    const transistions = [
      ...(data?.compliance?.state_machine?.available_transitions || []),
      ...(data?.profile?.state_machine?.available_transitions.filter(
        filterVisibleProfileTransitions,
      ) || []),
      ...(data?.customer?.state_machine?.available_transitions.filter(
        filterVisibleCustomerTransitions,
      ) || []),
    ];

    const newActions = [
      {
        id: 'action-contractor-back',
        type: 'text',
        label: l('JOB-ACTIONS-BACK'),
        onClick: () =>
          this.props.backAction
            ? this.props.backAction()
            : this.props.history.go(-1),
      },
    ];

    const transActions = transistions.map(trans => {
      switch (trans.name) {
        case 'rate':
          return {
            id: 'btn-contractor-rate',
            type: 'default',
            label: l('ACTIONS-RATE'),
            onClick: () => this.setPopupVisible('ratingPopup'),
          };
        case 'update':
          return {
            id: 'btn-contractor-edit',
            type: 'default',
            label: l('ACTIONS-EDIT'),
            onClick: this.toggleEditState,
          };

        case 'compliance_pass':
          return {
            id: 'action-click-contractor-pass',
            type: 'default',
            label: l('COMPLIANCE_ACTION-pass'),
            customRenderer: () => {
              return (
                <button
                  type="button"
                  className="button-group__primary__item__btn"
                />
              );
            },
            onClick: () => this.openPassCompliancePopup(),
          };

        case 'compliance_initiate':
          return {
            id: 'action-click-contractor-initiate',
            type: 'default',
            label: l('COMPLIANCE_ACTION-initiate'),
            customRenderer: () => {
              return (
                <button
                  type="button"
                  className="button-group__primary__item__btn"
                />
              );
            },
            onClick: () => this.openInitiateCompliancePopup(),
          };

        case 'compliance_fail':
          return {
            id: 'action-click-contractor-fail',
            type: 'default',
            label: l('COMPLIANCE_ACTION-fail'),
            customRenderer: () => {
              return (
                <button
                  type="button"
                  className="button-group__primary__item__btn"
                />
              );
            },
            onClick: () => this.openFailCompliancePopup(),
          };

        case 'submit_invitation':
          return {
            id: 'action-contractor-invite_button',
            type: 'default',
            label: l('ACTION-invite'),
            onClick: () => {
              this.props.history.push(`${location.pathname}/invite`);
            },
          };

        default:
          return null;
      }
    });

    return newActions.concat(transActions);
  };

  setCompliancePopupVisible = (flag = true) => {
    this.setPopupVisible('compliancePopup', flag);
  };

  setPopupVisible = (stateKey, flag = true) => {
    if (this.state[stateKey]) {
      this.setState(prevState => ({
        ...prevState,
        [stateKey]: { ...prevState[stateKey], visible: flag },
      }));
    }
  };

  getCompliancePopupProps = action => {
    const contractorId = this.props.match.params.contractor_id;
    const defaultPopProps = {
      title: '',
      body: '',
      options: [],
    };

    const defaultOptions = [
      {
        cb: () => this.setCompliancePopupVisible(false),
        buttonText: l('cancel'),
        buttonClass: 'btn--text',
      },
    ];

    switch (action) {
      case transitions.initiate:
        return {
          visible: true,
          title: l('COMPLIANCE_POPUP-initiate-title'),
          body: l('COMPLIANCE_POPUP-initiate-body').replace(
            ':contractor_name',
            this.state.data.billing_company,
          ),
          options: [
            ...defaultOptions,
            {
              cb: () => {
                this.initiateComplianceConfirm(contractorId);
              },
              buttonText: l('COMPLIANCE_POPUP-initiate-action'),
              buttonClass: 'btn--primary',
              dataQeId: 'compliance-confirm-initiate',
            },
          ],
        };
      case transitions.pass:
        return {
          visible: true,
          title: l('COMPLIANCE_POPUP-pass-title'),
          body: l('COMPLIANCE_POPUP-pass-body'),
          options: [
            ...defaultOptions,
            {
              cb: () => {
                this.passComplianceConfirm(contractorId);
              },
              buttonText: l('COMPLIANCE_POPUP-pass-action'),
              buttonClass: 'btn--primary',
              dataQeId: 'compliance-confirm-pass',
            },
          ],
        };
      case transitions.fail:
        return {
          visible: true,
          title: l('COMPLIANCE_POPUP-fail-title'),
          body: l('COMPLIANCE_POPUP-fail-body'),
          options: [
            ...defaultOptions,
            {
              cb: () => {
                this.failComplianceConfirm(contractorId);
              },
              buttonText: l('COMPLIANCE_POPUP-fail-action'),
              buttonClass: 'btn--primary',
              dataQeId: 'compliance-confirm-fail',
            },
          ],
        };
      default:
        return defaultPopProps;
    }
  };

  graphi: GraphQLPageReady;

  fetchData = () => {
    this.graphi.refreshPage();
  };

  refreshPageAndList = () => {
    this.fetchData();
    this.props.updateList();
  };

  openInitiateCompliancePopup = () => {
    this.setState({
      compliancePopup: this.getCompliancePopupProps(transitions.initiate),
    });
  };

  openPassCompliancePopup = () => {
    this.setState({
      compliancePopup: this.getCompliancePopupProps(transitions.pass),
    });
  };

  openFailCompliancePopup = () => {
    this.setState({
      compliancePopup: this.getCompliancePopupProps(transitions.fail),
    });
  };

  initiateComplianceConfirm = contractorId => {
    axios
      .post(`/api/1/contractors/${contractorId}/compliance/compliance_initiate`)
      .then(() => {
        this.setCompliancePopupVisible(false);
        this.refreshPageAndList();
      });
  };

  passComplianceConfirm = contractorId => {
    axios
      .post(`/api/1/contractors/${contractorId}/compliance/compliance_pass`)
      .then(() => {
        this.setCompliancePopupVisible(false);
        this.refreshPageAndList();
      });
  };

  failComplianceConfirm = contractorId => {
    axios
      .post(`/api/1/contractors/${contractorId}/compliance/compliance_fail`)
      .then(() => {
        this.setCompliancePopupVisible(false);
        this.refreshPageAndList();
      });
  };

  toggleEditState = () => {
    if (!this.props.isEditting) {
      this.props.scrollToTop();
    }
    this.props.toggleCustomerEdit();
  };

  submitContractor = () =>
    this.props.submitContractor(
      this.props.match.params.contractor_id,
      () => {
        this.refreshPageAndList();
      },
      this.props.scrollToTop,
    );

  rateContractor = (rating, comment) => {
    this.props.rateContractor(
      { contractor_id: this.state.data.profile.id, rating, comment },
      () => {
        this.setState(prevState => ({
          ...prevState,
          ratingPopup: {
            ...prevState.ratingPopup,
            visible: false,
          },
        }));
        this.refreshPageAndList();
      },
      error => {
        const errorFormatting = [
          {
            field: 'rating',
            condition: 'required',
            message: l('VALIDATION-required'),
          },
          {
            field: 'comment',
            condition: 'required',
            message: l('VALIDATION-required'),
          },
        ];
        this.setState(prevState => ({
          ...prevState,
          ratingPopup: {
            ...prevState.ratingPopup,
            errors: formatErrors(error.data.payload, errorFormatting),
          },
        }));
      },
    );
  };

  render(): React$Element<*> {
    const { data, compliancePopup, ratingPopup } = this.state;
    const { canEditProfile, isEditting } = this.props;
    const {
      title: popupTitle,
      body: popupBody,
      options: popupOptions,
      visible: popupVisible,
    } = compliancePopup;
    return (
      <Fragment>
        <ActionBar name="single_contractor" />
        <GraphQLPageReady
          ref={inst => {
            this.graphi = inst;
          }}
          onData={this.onGraphQLResponse}
          queryId="getContractor"
          queryString={getContractorQuery(
            this.props.match.params.contractor_id,
          )}>
          <div className="gw">
            <div className="g--tall g-1/1">
              <MainCard
                uid={data.uid_number || ''}
                contact={
                  [
                    data.street,
                    data.post_city,
                    data.billing_telephone,
                    data.billing_email,
                  ] || []
                }
                trades={data.trades?.map(trade => trade.value) || []}
                lastJob={data.job_last_date || 0}
                recommended={data.recommendation_status || false}
                earnings={data.current_year_payments_volume || ''}
                current_year={data.current_year || ''}
                totalJobs={data.jobs_count || ''}
                geocode={data.geocode || {}}
                lastQuestionnaire={data.compliance?.last_completed}
                questionnaireStatus={data.compliance?.status}
                status={data.status}
                job_locations={
                  data.job_locations
                    ? [
                        ...data.job_locations.map((item: Object): Object => ({
                          ...item,
                          color: 'blue',
                        })),
                        {
                          ...data.geocode,
                          name: data.billing_company,
                          color: 'red',
                        },
                      ]
                    : []
                }
                canEditProfile={canEditProfile && isEditting}
              />
            </div>
          </div>

          {data.users && data.users.length ? (
            <div className="gw">
              <div className="g g-1/1">
                <ContactsCard contacts={data.users || [['']]} />
              </div>
            </div>
          ) : null}

          <div className="gw">
            <div className="g g-1/1">
              <ComplianceQuestionnaireCard
                submission={data.compliance?.latest_questionnaire?.submission}
              />
            </div>
          </div>

          <div className="gw">
            <div className="g g-1/1">
              <CustomerCard
                status={data.customer?.state_machine?.status || ''}
                customerNumber={data.customer?.customer_code || ''}
                recommended={data.recommendation_status || 'no_recommendation'}
                editMode={this.props.isEditting}
              />
            </div>
          </div>
          <div className="push--bottom">
            <NotificationsCard
              total={this.state.data.notifications_count || 0}
              notifications={this.state.data.notifications}
              contractor={this.state.data}
              contractorId={this.props.match.params.contractor_id}
            />
          </div>
          <div className="push--bottom">
            <RatingsCard
              currentRating={this.state.data.rating_avg}
              total={this.state.data.ratings_count || 0}
              notifications={this.state.data.ratings}
              contractor={this.state.data}
              contractorId={this.props.match.params.contractor_id}
            />
          </div>
          <div className="gw">
            <div className="g g-1/1">
              <div>
                <JobsCard
                  apiEndpoint={`/api/1/view/contractors/${this.props.match.params.contractor_id}/jobs`}
                  listName="contractor_jobs_list"
                  enableDetailView
                />
              </div>
            </div>
          </div>
          <div className="gw">
            <div className="g g-1/1">
              <div>
                <PropertyCard
                  apiEndpoint={`/api/1/view/contractors/${this.props.match.params.contractor_id}/properties`}
                  enableDetailView
                />
              </div>
            </div>
          </div>
        </GraphQLPageReady>

        <SimplePopup
          isOpen={popupVisible}
          close={() => this.setCompliancePopupVisible(false)}
          title={popupTitle}
          description={popupBody}
          options={popupOptions}
        />

        <Overlay isOpen={ratingPopup.visible}>
          <RatingModal
            close={() => this.setPopupVisible('ratingPopup', false)}
            callback={(rating: number, comment: string): any => {
              this.rateContractor(rating, comment);
            }}
            errors={ratingPopup.errors}
          />
        </Overlay>
        <FooterBar>
          <div className="fl-right">
            <div className="fl-right__item">
              <ButtonGroup alt numButtons={3}>
                {this.getFooterButtons().map((item, key) => {
                  if (item.customRender) {
                    return item.customRender();
                  }
                  return (
                    <button
                      key={key}
                      type="button"
                      data-meta={{ type: item.type || 'default' }}
                      onClick={item.onClick}
                      data-qe-id={item.id}>
                      {item.label}
                    </button>
                  );
                })}
              </ButtonGroup>
            </div>
          </div>
        </FooterBar>
      </Fragment>
    );
  }
}

const mapState = ({ customerRelationship, authStore }) => ({
  isEditting: customerRelationship.isEditting,
  canEditProfile: authStore.user.role === RoleId.ADMINISTRATION,
});

const mapActions = (dispatch): ReduxActionShape => ({
  updateList() {
    return dispatch(updateSimpleList('contractor_list'));
  },
  toggleCustomerEdit(payload = null) {
    return dispatch(toggleEdit(payload));
  },
  submitContractor(contractor, successCb, errorCb) {
    return dispatch(
      submitContractorRelationship(contractor, successCb, errorCb),
    );
  },
  rateContractor(data, successCb, errorCb) {
    return dispatch(rateContractor(data, successCb, errorCb));
  },
});

SingleContractor.defaultProps = {
  scrollToTop: () => null,
};

export default withRouter(connect(mapState, mapActions)(SingleContractor));
