/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import AgreementPanel from 'shared/jobs/Panels/items/Agreement';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import MachineBuilder from './machine';
import { updateJobForm } from '../../actions';

type ReduxActionShape = {
  update: Function,
};

type ReduxStateShape = {
  status: string,
  panelEditMode: boolean,
  according_to: string,
  // conditions: string,
  fixed_price: string,
  vat_included: string | boolean,
  discount: string,
  cash_discount: string,
  payment_inert: number | void,
  // agreement_conditions_options: string[],
  agreement_according_to_options: Array<{ key: string, value: string }>,
  agreement_price: number | void,
  agreement_price_error: boolean,
  agreement_includes_vat: boolean,
  agreement_discount: string,
  agreement_cash_discount: string,
  agreement_payment_inert: number | void,
};

export const AgreementCardMachine = MachineBuilder();

const AgreementCard = ({
  panelEditMode,
  status,
  according_to,
  fixed_price,
  vat_included,
  discount,
  cash_discount,
  payment_inert,
  agreement_according_to_options,
  agreement_price,
  agreement_price_error,
  agreement_includes_vat,
  agreement_discount,
  agreement_cash_discount,
  agreement_payment_inert,
  update,
  machine_state,
}) => (
  <StateConditional
    state={machine_state}
    whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
    <AgreementPanel
      // eslint-disable-next-line
      updateField={update}
      jobState={status}
      formData={{
        // agreement_conditions_options,
        agreement_according_to_options,
        agreement_price,
        agreement_price_error,
        agreement_includes_vat,
        agreement_discount,
        agreement_cash_discount,
        agreement_payment_inert,
        updateField(): any {
          return true;
        },
      }}
      data={{
        according_to,
        // conditions,
        fixed_price,
        vat_included,
        discount,
        cash_discount,
        payment_inert,
      }}
      editNow={panelEditMode}
    />
  </StateConditional>
);

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  panelEditMode: machineStore.agreement_card === PanelStates.EDITING,
  machine_state: machineStore.agreement_card,

  status: jobStore.data.job.info_status,
  according_to: jobStore.data.job.agreement_according_to || '',
  // conditions: state.jobStore.data.agreement_conditions,
  fixed_price: jobStore.data.job.agreement_price,
  discount: jobStore.data.job.agreement_discount,
  cash_discount: jobStore.data.job.agreement_cash_discount,
  payment_inert: jobStore.data.job.agreement_payment_inert,
  agreement_includes_vat: !!jobStore.data.job.agreement_includes_vat,
  // agreement_conditions_options:
  // state.jobStore.data.agreement_conditions_options,
  agreement_according_to_options: jobStore.data.agreement_according_tos,
  // form...
  agreement_price: jobStore.form.agreement_price,
  agreement_price_error: !!jobStore.errors.agreement_price,

  vat_included: !!jobStore.form.agreement_includes_vat,
  agreement_discount: jobStore.form.agreement_discount,
  agreement_cash_discount: jobStore.form.agreement_cash_discount,
  agreement_payment_inert: jobStore.form.agreement_payment_inert,
});

const mapAction = (dispatch: Function): ReduxActionShape => ({
  update(key: string, value: string): any {
    return dispatch(updateJobForm(key, value));
  },
});

export default connect(mapState, mapAction)(AgreementCard);
