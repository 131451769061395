/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import StaticDocuments from 'shared/projects/panels/items/StaticDocuments';
import type { DocumentType } from 'views/singleJob/constants';
import DocConstants from 'components/connected/DocumentUploader/constants';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';

import { removeProjectDocument } from '../../actions';
import MachineBuilder, { MACHINE_NAME } from './machine';

const { DELETE, VIEW, DOWNLOAD } = DocConstants.DOCUMENT_ACTION;

export const SOURCE_TAG = 'static';

export const StaticDocsCardMachine = MachineBuilder();

type ReduxActionShape = {
  removeImage: Function,
};

type ReduxStateShape = {
  status: string,
  documentList: [DocumentType],
};

type PropsShape = {
  projectId: string | number,
} & ReduxStateShape &
  ReduxActionShape;

const StaticDocsCard = ({
  status,
  projectId,
  removeImage,
  documentList,
  machineState,
}: PropsShape): React$Element<*> => (
  <StateConditional whenState={[PanelStates.VISIBLE]} state={machineState}>
    <StaticDocuments
      listName={SOURCE_TAG}
      data={{
        uploadProps: {
          source_tag: SOURCE_TAG,
          source_type: 'project',
          source_id: projectId,
          projectId,
        },
        endpoint: '/api/1/documents/action/add',
      }}
      projectStatus={status}
      list={documentList}
      controls={[
        {
          documentAction: DELETE,
          svgName: 'close',
          action: (id: string | number, api: string): any =>
            removeImage(id, api),
          canDisplay: true,
        },
        {
          documentAction: VIEW,
          svgName: 'view',
          action: null,
          canDisplay: true,
        },
        {
          documentAction: DOWNLOAD,
          svgName: 'download',
          action: null,
          canDisplay: true,
        },
      ]}
    />
  </StateConditional>
);

const mapState = ({
  projectStore,
  authStore,
  machineStore,
}): ReduxStateShape => ({
  status: projectStore.data.status,
  userRole: authStore.user.role,

  documentList:
    projectStore.data.documents.filter(doc => doc.source_tag === SOURCE_TAG) ||
    [],

  isEditing: projectStore.isEditing,
  projectId: projectStore.data.project_id,

  machineState: machineStore[MACHINE_NAME],
});

const mapActions = (
  dispatch: Function,
  { projectId }: { projectId: string | number },
): ReduxActionShape => ({
  removeImage(id: string | number, api: string): any {
    return removeProjectDocument(
      id,
      SOURCE_TAG,
      projectId,
      dispatch,
      false,
      api,
    );
  },
});

export default connect(mapState, mapActions)(StaticDocsCard);
