/** @format */

import React from 'react';

import Card from '../../../../../components/Card';
import l from '../../../../../helpers/locale';

const ContactPersonPanel = ({
  companyName,
  salutation,
  firstName,
  surname,
  email,
}) => (
  <Card title="CONTACT-contact_person" isOpen canCollapse={false}>
    <div className="gw">
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-company')}
          </span>
          <p className="text--dk--flushed">{companyName}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-salutation')}
          </span>
          <p className="text--dk--flushed">{salutation}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-first_name')}
          </span>
          <p className="text--dk--flushed">{firstName}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-last_name')}
          </span>
          <p className="text--dk--flushed">{surname}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-email')}
          </span>
          <p className="text--dk--flushed">{email}</p>
        </div>
      </div>
    </div>
  </Card>
);

export default ContactPersonPanel;
