/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import assign from 'object.assign';

import * as AuthActions from '../../actions/auth';
import l from '../../helpers/locale';
import handleRef from '../Form/helpers/handleRef';
import LoginResponse from './LoginResponse.js';

const Login = ({ actions, forgotAct }) => {
  const refStore = handleRef();

  function submitHandler(e, store, cb) {
    e.preventDefault();

    const payload = store
      .map(v => ({
        [v.name]: v.input.value,
      }))
      .reduce((curr, item) => assign(curr, item));
    return cb(payload);
  }

  return (
    <form
      onSubmit={function attemptLogin(e) {
        return submitHandler(e, refStore.getRefs(), actions.makeRequest);
      }}>
      <div>
        <div className="form__input-container">
          <label htmlFor="email" className="form__label--small">
            {l('LOGIN-login_with_email')}
          </label>
          <input
            type="text"
            name="email"
            className="form__input"
            data-qe-id="input-login-field"
            autoCapitalize="none"
            ref={input => {
              refStore.setRef(input, 'email');
            }}
          />
        </div>
        <div className="form__input-container">
          <label htmlFor="password" className="form__label--small">
            {l('LOGIN-password')}
          </label>
          <input
            type="password"
            name="password"
            className="form__input"
            data-qe-id="input-password-field"
            ref={input => {
              refStore.setRef(input, 'password');
            }}
          />
        </div>
      </div>
      <div className="align--right push--small--bottom">
        <button
          type="button"
          className="btn--text--base"
          data-qe-id="action-reset-password"
          onClick={function forgotPasswordClicked() {
            return forgotAct();
          }}>
          <span>{l('LOGIN-forgot')}</span>
        </button>
      </div>
      <LoginResponse />
      <div className="align--right">
        <button
          type="submit"
          className="btn--primary"
          data-qe-id="action-login-submit">
          {l('LOGIN-submit')}
        </button>
      </div>
    </form>
  );
};

Login.propTypes = {
  actions: PropTypes.object.isRequired,
  forgotAct: PropTypes.func.isRequired,
};

const mapState = () => ({});

const mapDispatch = dispatch => ({
  actions: bindActionCreators(AuthActions, dispatch),
});

export default connect(mapState, mapDispatch)(Login);
