export const transitions = {
  initiate: 'compliance_initiate',
  refresh: 'compliance_refresh',
  fail: 'compliance_fail',
  pass: 'compliance_pass',
  timeout: 'compliance_timeout',
  submit: 'compliance_submit',
};

export default {
  unchecked: 'unchecked',
  failed: 'failed',
  initiated: 'initiated',
  passed: 'passed',
  refresh_needed: 'refresh_needed',
  submitted: 'submitted',
  timed_out: 'timed_out',
};
