/** @format */

export default {
  back: {
    title: 'JOB-ACTIONS-BACK',
    btn: 'btn--text',
  },
  delete: {
    title: 'JOB-ACTIONS-ERASE',
    btn: 'btn--secondary',
  },
  cancel: {
    title: 'JOB-ACTIONS-CANCEL',
    btn: 'btn--secondary',
  },
  copy: {
    title: 'JOB-ACTIONS-COPY',
    btn: 'btn--inverse--primary',
  },
  addContractor: {
    title: 'JOB-ACTIONS-ADD_CONTRACTOR',
    btn: 'btn--primary',
  },
  update: {
    title: 'JOB-ACTIONS-EDIT-MODE',
    btn: 'btn--inverse--primary',
  },
  grant: {
    title: 'JOB-ACTIONS-GRANT',
    btn: 'btn--primary',
  },
  resend_grant: {
    title: 'JOB-ACTIONS-RESEND_GRANT',
    btn: 'btn--primary',
  },
  resend_offer_grant: {
    title: 'JOB-ACTIONS-RESEND_OFFER_GRANT',
    btn: 'btn--primary',
  },
  request: {
    title: 'JOB-ACTIONS-REQUEST_OFFERS',
    btn: 'btn--primary',
  },
  cancelEdit: {
    title: 'JOB-ACTIONS-CANCEL-EDIT-MODE',
    btn: 'btn--text',
  },
  change: {
    title: 'JOB_ACTIONS-SAVE_EDIT',
    btn: 'btn--inverse--primary',
  },
  create_offer: {
    title: 'JOB-ACTIONS-CREATE-OFFER',
    btn: 'btn--primary',
  },
  accept: {
    title: 'JOB-ACTIONS-ACCEPT',
    btn: 'btn--primary',
  },
  execute: {
    title: 'JOB-ACTIONS-EXECUTE',
    btn: 'btn--primary',
  },
  no_interest: {
    title: 'JOB-ACTIONS-NO_INTEREST',
    btn: 'btn--inverse--secondary',
  },
  add_contractor: {
    title: 'JOB-ACTIONS-ADD_CONTRACTOR',
    btn: 'btn--primary',
  },
  create_orphan: {
    title: 'JOB_ACTIONS-SAVE_DRAFT',
    btn: 'btn--primary',
  },
  create_project_direct: {
    title: 'JOB_ACTIONS-SAVE_DRAFT',
    btn: 'btn--primary',
  },
  create_project_offer: {
    title: 'JOB_ACTIONS-SAVE_DRAFT',
    btn: 'btn--primary',
  },
  export_grant_request: {
    title: 'JOB_ACTIONS-GRANT_REQUEST',
    btn: 'btn--primary',
  },
  complete: {
    title: 'JOB_ACTIONS-COMPLETE',
    btn: 'btn--primary',
  },
};
