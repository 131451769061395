import React, { useState, Fragment, useContext } from 'react';
import DocConstants from 'components/connected/DocumentUploader/constants';
import type { DocumentDataShape } from 'components/connected/DocumentUploader';
import PreviewSlider from 'components/PreviewSlider';
import ControlItem from './item';
import { PreviewContext } from '../index';

export type ControlShape = {
  svgName: string,
  action: Function,
  documentAction: string,
  canDisplay: boolean,
};

const { DELETE, VIEW, DOWNLOAD } = DocConstants.DOCUMENT_ACTION;

export const canControl = controls => {
  return (
    controls.filter(
      (item: { canDisplay: any }): boolean => item.canDisplay === true,
    ).length > 0
  );
};

const canView = control =>
  control.documentAction === VIEW && control.canDisplay;

const canDownload = control =>
  control.documentAction === DOWNLOAD && control.canDisplay;

const canDelete = (control, transitions) =>
  transitions.some(item => item.name === 'trash') &&
  control.documentAction === DELETE &&
  control.canDisplay;

const PreviewDefaultControls = ({
  controls,
  transitions,
  data,
}: {
  controls: [ControlShape],
  transitions: [],
  data: DocumentDataShape,
}) => {
  const itemOrder = [VIEW, DOWNLOAD, DELETE];
  const [previewOpen, setPreviewOpen] = useState(false);
  const { onActionClicked } = useContext(PreviewContext);

  function renderControl(actionName) {
    const controlItem = controls.find(
      item => item.documentAction === actionName && item.canDisplay,
    );

    if (!controlItem) return null;

    if (
      canView(controlItem, transitions) ||
      canDownload(controlItem, transitions)
    ) {
      return (
        <ControlItem
          data={data}
          item={controlItem}
          wrappingClassName="image-preview__inner__controls__item"
          transitions={transitions}
        />
      );
    }
    if (canDelete(controlItem, transitions)) {
      return (
        <ControlItem
          data={data}
          item={controlItem}
          wrappingClassName="image-preview__inner__controls__item"
          transitions={transitions}
          clickAction={function(): any {
            return onActionClicked('trash', data);
          }}
        />
      );
    }
    return null;
  }

  return (
    <div className="image-preview__inner__controls-container">
      <div className="image-preview__inner__controls">
        {itemOrder.map((action, key) => (
          <Fragment key={key}>{renderControl(action)}</Fragment>
        ))}
      </div>
      <PreviewSlider
        data={data}
        onCloseClick={setPreviewOpen.bind(null, false)}
        open={previewOpen}
      />
    </div>
  );
};

export default PreviewDefaultControls;
