/** @format */

// @flow

import React from 'react';
import { connect } from 'react-redux';
import l from '../../helpers/locale';

type PropsShape = {
  loading: boolean,
  failed: boolean,
};

function classTags(
  strings: string[],
  loading: boolean,
  failed: boolean,
): string {
  switch (true) {
    case failed:
      return `${strings[0]}--quinary`;
    default:
      return strings[0];
  }
}

const LoginResponse = ({ loading, failed }: PropsShape): React$Element<*> => (
  <div className="align--right">
    <span className={classTags`fc${loading}${failed}`}>
      {(loading && l('RESULT-loading')) || (failed && l('LOGIN-failed')) || ''}
    </span>
  </div>
);

const mapState = (state: Object): PropsShape => ({
  failed: state.authStore.request.failed,
  loading: state.authStore.request.fetching,
});

export default connect(mapState)(LoginResponse);
