import React from 'react';
import { withRouter } from 'react-router-dom';
import ContractorView from 'workflows/jobAssign/views/contractorView';

const ContractorSelectionContainer = ({ history, match }) => (
  <ContractorView
    concern_type={match.params.type}
    concern_id={match.params.type_id}
    nextAct={(contractor: Object) => {
      history.push(`${match.url}/${contractor.id}`);
    }}
    stateTarget="contractorList"
  />
);

export const ContractorSelection = withRouter(ContractorSelectionContainer);
