import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import CloseButton from 'components/AsideSlide/CloseButton';
import { AsideSlide } from '@bonlineza/b-lib';
import GetSvg from 'components/GetSvg';
import l from 'helpers/locale';

import ProjectTeamList from './projectTeamList';

type StateShape = {
  isOpen: boolean,
};

type PropsShape = {
  update: Function,
  loading: boolean,
};

type ReduxStateShape = {
  support: Array<*> | void,
  owner: Object,
};

class ProjectTeamAdd extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    support: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  open = () => this.setState({ isOpen: true });

  close = () => this.setState({ isOpen: false });

  fireUpdate = (...params) => {
    this.close();
    this.props.update(...params);
  };

  render() {
    return (
      <Fragment>
        <div className="push--bottom">
          <label className="form__label--small" htmlFor="__">
            {l('PROJECT-support_title')}
          </label>
          {this.props.loading ? (
            <GetSvg svg="loading" wrapperClass="loader-base" />
          ) : (
            <button
              type="button"
              className="btn--text--primary"
              onClick={this.open}
              data-qe-id="action-add-support">
              {l('PROJECT_TEAM-click_to_add')}
            </button>
          )}
        </div>

        <AsideSlide
          isOpen={this.state.isOpen}
          title={l('PROJECT-SUPPORT_TEAM-add')}
          toggle={this.close}
          toggleButton={() => <CloseButton onClick={this.close} />}
          qeId="project-support-list">
          <div>
            {this.state.isOpen ? (
              <ProjectTeamList
                target="supports"
                update={this.fireUpdate}
                ignoreIds={this.props.support.reduce(
                  (a, v) => [...a, v.user_id],
                  [this.props.owner.user_id],
                )}
              />
            ) : null}
          </div>
        </AsideSlide>
      </Fragment>
    );
  }
}

const mapState = ({ projectStore }): ReduxStateShape => ({
  support: projectStore.form.project_supports,
  owner: projectStore.form.project_owner,
});

export default connect(mapState)(ProjectTeamAdd);
