/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import BillOrderPanel from 'shared/projects/panels/items/BillOrder';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';

import MachineBuilder from './machine';

export const InvoicingProcessCardMachine = MachineBuilder();

type ReduxStateShape = {
  address: string[],
  status: string,
  coversheet: string,
  address_owner: string[],
};

type PropsShape = ReduxStateShape;

const InvoicingProcessCard = ({
  address,
  status,
  coversheet,
  machine_state,
  address_owner,
}: PropsShape): React$Element<*> => (
  <StateConditional state={machine_state} whenState={[PanelStates.VISIBLE]}>
    <BillOrderPanel
      address={address}
      projectStatus={status}
      coversheet={coversheet}
      address_account={address_owner}
    />
  </StateConditional>
);

const mapState = ({ projectStore, machineStore }): ReduxStateShape => ({
  address: projectStore.data.address_scan_center,
  coversheet: projectStore.data.coversheet,
  status: projectStore.data.status,
  machine_state: machineStore.invoicingprocess_card,
  address_owner: projectStore.data.address_owner,
});

export default connect(mapState)(InvoicingProcessCard);
