import React, { Fragment } from 'react';
import Control from 'components/Preview/Controls/item';

function PreviewSliderImage({ customControls, data }) {
  return (
    <Fragment>
      <div className="preview-slider-image">
        <div className="preview-slider-image__img">
          <img src={`${data.url}`} alt={data.name} />
        </div>
        <div className="pdf-preview__footer">
          <div className="pdf-preview__footer__group">
            {customControls.map((control, key) => (
              <Fragment key={key}>
                <Control
                  item={control}
                  wrappingClassName="image-preview__inner__controls__item"
                  data={data}
                  showPreviewButton={false}
                />
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default PreviewSliderImage;
