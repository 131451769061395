/** @format */

// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import UntilReady from 'components/UntilReady';
import FooterBar from 'components/FooterBar';

import ProjectActions from 'views/singleProject/components/ProjectActions';

import { fetchProjectCreateData } from '../../actions';
import ProjectViewCards from './cards';

const {
  ObjectCard,
  GeneralCard,
  FinanceCard,
  AddressesCard,
  DescriptionCard,
  ProjectTeamCard,
  ProjectTeamDocsCard,
  InternalDocsCard,
} = ProjectViewCards;

type ReduxActionsShape = {
  getData: () => any,
};

type ReduxStateShape = {
  request: {
    fetching: boolean,
    success: boolean,
  },
};

type PropsShape = ReduxActionsShape & ReduxStateShape;

class ProjectCreateView extends React.Component<PropsShape> {
  componentDidMount() {
    this.props.getData();
  }

  render(): React$Element<*> {
    return (
      <Fragment>
        <ActionBar name="create_project_view" />
        <UntilReady
          ready={this.props.request.success}
          waiting={this.props.request.fetching}>
          <div className="gw">
            <div className="g--tall g-1/3">
              <ObjectCard />
            </div>
            <div className="g--tall g-1/3">
              <GeneralCard />
            </div>
            <div className="g--tall g-1/3">
              <FinanceCard />
            </div>
          </div>
          <div className="gw">
            <div className="g--tall g-1/1">
              <ProjectTeamCard />
            </div>
            <div className="g--tall g-1/1">
              <AddressesCard />
            </div>
            <div className="g--tall g-1/1">
              <DescriptionCard />
            </div>
            <div className="g--tall g-1/1">
              <InternalDocsCard
                projectId={this.props.match.params.project_id}
              />
            </div>
            <div className="g--tall g-1/1">
              <ProjectTeamDocsCard
                projectId={this.props.match.params.project_id}
              />
            </div>
          </div>
        </UntilReady>
        <FooterBar>
          <ProjectActions isProjectCreate backAction={this.props.backAction} />
        </FooterBar>
      </Fragment>
    );
  }
}

const mapState = ({ projectStore }): ReduxStateShape => ({
  request: projectStore.form.getCreateRequest,
});

const mapDispatch = (dispatch: Function, { match }): ReduxActionsShape => ({
  getData(): any {
    return dispatch(
      fetchProjectCreateData(match.params.type, match.params.type_id),
    );
  },
});

export default withRouter(connect(mapState, mapDispatch)(ProjectCreateView));
