/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import ProjectTeamPanel from 'shared/projects/panels/items/ProjectTeam';
import { updateForm } from 'views/singleProject/actions';

type ReduxStateShape = {
  editTeam: Array,
};

type PropsShape = ReduxStateShape;

const ProjectTeamCard = ({
  editTeam,
  editOwner,
  updateFormField,
}: PropsShape): React$Element<*> => (
  <ProjectTeamPanel
    data={[{ ...editOwner, is_owner: true }].concat(editTeam)}
    projectStatus="draft"
    isEditing
    updateField={updateFormField}
    storeTargetLabel="exists"
    isCreating
  />
);

const mapState = ({ projectStore }): ReduxStateShape => ({
  editTeam: projectStore.form.project_supports,
  editOwner: projectStore.form.project_owner,
});

const mapActions = (dispatch: Function) => ({
  updateFormField(key: string, value: string): any {
    return dispatch(updateForm(key, value));
  },
});

export default connect(mapState, mapActions)(ProjectTeamCard);
