import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import SingleNominationView from 'views/nominations/views/SingleNomination';
import { connect } from 'react-redux';
import { updateTitle } from 'components/RouteSlider/actions';
import { Names } from '../constants';

const NominationViewRender = ({ context, setTitle }) => {
  useEffect(() => {
    setTitle('-');
    return () => setTitle('-');
  }, [setTitle]);
  return (
    <SingleNominationView
      backAction={context.backAction}
      scrollToTop={context.scrollToTop}
      dataUpdatedCB={payload => setTitle(payload.nomination_company)}
    />
  );
};

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.NOMINATION_VIEW, title));
  },
});

export const NominationView = withRouter(
  connect(null, mapActions)(NominationViewRender),
);
