import React from 'react';

function transformTransitions(transition, transitions, openPopup) {
  const transitionIndex = transitions.findIndex(v => v.name === transition);
  switch (transition) {
    case 'update':
      return {
        name: 'edit',
        type: 'default',
      };
    case 'open':
    case 'complete':
      return {
        name: transition,
        type: 'default',
        action: null,
        actionFunction: () => openPopup(transition),
      };
    case 'project_export':
      return {
        ...transitions[transitionIndex],
        href: transitions[transitionIndex].action,
      };
    default:
      return transitions[transitionIndex];
  }
}

function orderTransitions(transitions, openPopup) {
  const order = [
    'update',
    'open',
    'create_job_direct',
    'create_job_offer',
    'add_payment',
    'copy',
    'complete',
    'cancel',
    'erase',
    'project_export',
  ];

  const orderedTransitions = order
    .filter(t => {
      const transitionExists = transitions.findIndex(v => v.name === t);
      return transitionExists !== -1;
    })
    .map(t => transformTransitions(t, transitions, openPopup));

  return orderedTransitions;
}

function getDefaultEditing() {
  return [
    {
      name: 'cancelEdit',
      type: 'text',
    },
    {
      name: 'update',
      type: 'default',
    },
  ];
}

function getDefaultCreating() {
  return [
    {
      name: 'save',
      type: 'default',
    },
  ];
}

function withBack(arr, backAction) {
  return [
    {
      name: 'back',
      type: 'text',
      action: null,
      actionFunction: backAction,
    },
    ...arr,
  ];
}

export default function useProjectTransitions(
  projectTransitions = [],
  { isCreating, isEditing, backAction, openPopup },
) {
  const [transitions, setTransitions] = React.useState([]);

  React.useEffect(() => {
    let next = [];
    if (isCreating) {
      next = withBack(getDefaultCreating(), backAction);
    } else if (isEditing) {
      next = getDefaultEditing();
    } else {
      next = withBack(
        orderTransitions(projectTransitions, openPopup),
        backAction,
      );
    }
    setTransitions(next);
  }, [isCreating, isEditing, backAction, projectTransitions, openPopup]);

  return transitions;
}
