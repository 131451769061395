import React from 'react';
import OfferForm from 'views/jobs/components/offerForm';
import { Names } from 'components/RouteSlider/routes/jobs/constants';
import { connect } from 'react-redux';
import { updateTitle } from 'components/RouteSlider/actions';

const OfferViewRender = ({ setTitle, closeForm, context }) => (
  <OfferForm
    scrollToTop={() => context.scrollToTop(context.sliderIndex)}
    closeForm={closeForm}
    onViewData={data => setTitle(data.title)}
  />
);

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.JOB_VIEW_OFFER_VIEW, title));
  },
});

export const OfferView = connect(null, mapActions)(OfferViewRender);
