import React, { Fragment, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GetSvg from 'components/GetSvg';

const OfferSwitcher = ({ offers, match, history }) => {
  const [parentMatch, setParentMatch] = useState({
    path: match.path.split('/offers').shift(),
    url: match.url.split('/offers').shift(),
  });

  const {
    path,
    url,
    params: { offer_id },
  } = match;

  const offerLength = offers.length;
  const currentIndex = offerLength
    ? offers.findIndex((offer: Object): any => offer.offer_id === offer_id)
    : 0;
  const maxIndex = offerLength ? offerLength - 1 : 0;
  const showNextButton = currentIndex < maxIndex;
  const showPrevButton = currentIndex > 0;

  useLayoutEffect(() => {
    setParentMatch({
      path: path.split('/offers').shift(),
      url: url.split('/offers').shift(),
    });
  }, [path, url]);

  const setOfferByIndex = (index: number): any => {
    if (index < 0 || index > offerLength - 1) {
      throw new Error(
        `Offer index (${index}) invalid - possible race condition`,
      );
    }
    const offerId = offers[index]?.offer_id || null;
    if (offerId === null) {
      throw new Error('Offer not found while trying to pagingate');
    }
    history.push(`${parentMatch.url}/offers/${offerId}`);
  };

  const nextPage = (e: Event) => {
    e.preventDefault();
    setOfferByIndex(currentIndex + 1);
  };

  const prevPage = (e: Event) => {
    e.preventDefault();
    setOfferByIndex(currentIndex - 1);
  };

  if (maxIndex) {
    return (
      <div className="paginator-container">
        <ul className="paginator">
          <Fragment>
            <li className="paginator__item">
              <button
                data-qe-id="action-paginate-offer-prev"
                type="button"
                onClick={showPrevButton ? prevPage : undefined}
                className={
                  'paginator__item__btn ' +
                  `${!showPrevButton ? 'paginator__item__btn--disabled' : ''}`
                }>
                <GetSvg svg="angle_left" />
              </button>
            </li>
            <li className="paginator__item">
              <button
                data-qe-id="action-paginate-offer-next"
                type="button"
                onClick={showNextButton ? nextPage : undefined}
                className={
                  'paginator__item__btn ' +
                  `${!showNextButton ? 'paginator__item__btn--disabled' : ''}`
                }>
                <GetSvg svg="angle_right" />
              </button>
            </li>
          </Fragment>
        </ul>
      </div>
    );
  }
  return null;
};

const mapState = ({ jobStore }) => ({
  offers: jobStore.data.offers || [],
});

export default withRouter(connect(mapState)(OfferSwitcher));
