// export machines
// trigger transition on job state change

export { AccountCardMachine } from './AccountCard';
export { AgreementCardMachine } from './AgreementCard';
export { BudgetCardMachine } from './BudgetCard';
export { ContactsCardMachine } from './ContactsCard';
export { ContractorCardMachine } from './ContractorCard';
export { DocumentExchangeCardMachine } from './DocumentExchangeCard';
export { InternalDocsCardMachine } from './InternalDocsCard';
export { InvoiceProcessCardMachine } from './InvoiceProcessCard';
export { InformationCardMachine } from './InformationCard';
export { NotificationCardMachine } from './NotificationCard';
export { ObjectCardMachine } from './ObjectCard';
export { OfferListCardMachine } from './OfferListCard';
export { ParticipantsCardMachine } from './ParticipantsCard';
export { PaymentListCardMachine } from './PaymentListCard';
export { ProjectTeamDocsCardMachine } from './ProjectTeamDocsCard';
export { RatingCardMachine } from './RatingCard';
export { TenderDocumentsCardMachine } from './TenderDocumentsCard';
