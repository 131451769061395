/** @format */

import React from 'react';
import Panel from './financePanel.js';

const FinancePanelWrapper = ({ isEditing, updateField, data }) => (
  <Panel isEditing={isEditing} data={data} updateField={updateField} />
);

export default FinancePanelWrapper;
