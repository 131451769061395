/** @format */

// @flow

import l from './locale';

/**
 * Example Error Formatting Object

const errorFormatting = [
  {
    field: 'nomination_email',
    condition: 'unique',
    message: 'VALIDATION-email_in_use',
  },
];
*/
type ErrorFormat = {
  field: String,
  condition: String,
  message: String,
};

export default (
  errors: Object,
  errorFormatting: Array<ErrorFormat> = [],
): Object => {
  const formattedErrors = {
    ...errors,
  };

  errorFormatting.forEach((errorFormat: ErrorFormat) => {
    if (
      errors &&
      Object.prototype.hasOwnProperty.call(errors, errorFormat.field) &&
      errors[errorFormat.field][0] === errorFormat.condition
    ) {
      formattedErrors[errorFormat.field] = errorFormat.message;
    }
  });
  return formattedErrors;
};

export const interpretErrorMessage = (
  error: Object,
  customMessage: String,
): String => {
  const message = customMessage || l('RESULT-ERROR-retrieving_data');
  return message.replace(
    ':error_code',
    error.status || l('RESULT-ERROR-network_down'),
  );
};

export function translateApiErrorMessages(err: string): string {
  let res = err;
  const source = [
    'validation.valid_customer_code',
    'required_if customer status former',
    'required_if customer status current',
    'validation.recommendations_limit_not_exceeded',
    'required',
    'email',
  ];
  const translated = [
    'VALIDATION-valid_customer_number',
    'VALIDATION-required',
    'VALIDATION-required',
    'RECOMMENDATION_LIMIT-BODY',
    'VALIDATION-required',
    'VALIDATION-required',
  ];
  if (source.indexOf(err) >= 0) {
    res = translated[source.indexOf(err)];
  }

  return l(res);
}
