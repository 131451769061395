import React from 'react';
import AssetHousePropertyList from 'shared/jobs/AssetHousePropertySelectionList';

const AssetHousePropertyView = ({
  nextAct,
}: {
  nextAct: Function,
}): React$Element<*> => (
  <div>
    <AssetHousePropertyList nextAct={nextAct} />
  </div>
);

export default AssetHousePropertyView;
