export default [
  {
    field: 'email',
    condition: 'unique',
    message: 'VALIDATION-email_in_use',
  },
  {
    field: 'email',
    condition: 'email',
    message: 'VALIDATION-required',
  },
];
