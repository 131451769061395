/** @format */

import React from 'react';

import GetSvg from 'components/GetSvg';

const ViewItem = ({ label, value, hasSvg, prependSvg }) => (
  <div className="push--bottom">
    <label className="form__label--small" htmlFor="__">
      {label}
    </label>
    {hasSvg ? (
      <p className="texticon--dk--flushed v-align--center">
        <span className={prependSvg.svgClass}>
          <GetSvg svg={prependSvg.name} wrapperClass="" />
        </span>
        <span className="text--dk--flushed">{value}</span>
      </p>
    ) : (
      <p className="text--dk--flushed">{value}</p>
    )}
  </div>
);

export default ViewItem;
