import hasOwn from 'functions/hasOwn.func.js';

export default function flattenRoutes(routeObject, children = []) {
  const items = Object.keys(routeObject).map(routeName => {
    if (hasOwn(routeObject[routeName], 'children')) {
      return flattenRoutes(routeObject[routeName].children, [
        ...children,
        { ...routeObject[routeName], name: routeName },
      ]);
    }

    return [{ ...routeObject[routeName], name: routeName }, ...children];
  });

  const res = items
    .reduce((acc, cur) => [...acc, ...cur], [])
    .filter(
      (v, index, self) => self.findIndex(iv => iv.name === v.name) === index,
    );
  return res;
}
