/** @format */

// @flow
import Constants from '../constants';

const { APP } = Constants;

export function dispatchLocale(): any {
  return function getLocaleThunk(dispatch: Function): any {
    let storedLocale = '';
    if (typeof window !== 'undefined') {
      storedLocale = window.localStorage.getItem('_sl');
    }

    return dispatch(setLocale(storedLocale));
  };
}

export function setLocale(
  locale: 'en-GB' | 'de-CH' | 'fr-CH' | 'it-CH' = 'de-CH',
): Object {
  // necessary side effect
  window.localStorage.setItem('_sl', locale);
  return { type: APP.SET_LOCALE, payload: { locale } };
}

export function setLoginRedirect(path: string): Object {
  return { type: APP.SET_LOGIN_REDIRECT, payload: { path } };
}

export function setStatusBar(message: string): Object {
  return { type: APP.SET_STATUS_BAR, payload: message };
}
