// @flow

import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserStatus from 'constants/userStatus';
import Form from 'components/Form';
import PageReady from 'components/AllianzPageReady';
import GraphQLPageReady from 'components/GraphQLPageReady';
import l from 'helpers/locale';
import axios from 'helpers/axios';
import { getRoleNameFromId } from 'helpers/roleStringNumberSwitch';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import { emitAction } from 'components/ConnectedActionBar/actions';
import formatErrors from 'helpers/formatErrors';
import FooterBar from 'components/FooterBar';
import ButtonGroup from 'components/ButtonGroup';
import { SliderContext } from 'components/RouteSlider';
import type { DocumentType } from 'views/singleJob/constants';
import accountFields from '../createAccount/accountFields';
import * as Actions from '../../actions';
import accountCreateUpdateValidation from '../../helpers/accountCreateUpdateValidation';
import getAccountDocuments from './query';

const errorFormatting = [
  {
    field: 'email',
    condition: 'unique',
    message: 'VALIDATION-email_in_use',
  },
  {
    field: 'email',
    condition: 'email',
    message: 'VALIDATION-required',
  },
];

type ActionsShape = {
  emitUpdate: Function,
  removeDocument: Function,
  updateDocs: Function,
  actions: {
    updateAccountFailed: Function,
  },
};

type PropsShape = {
  documents: Array<Object>,
  history: Object,
  match: Object,
  canEdit: boolean,
  updateTitle: Function,
} & ActionsShape;

type StateShape = {
  fields: Object,
  data: Object,
  editMode: boolean,
  errors: {
    [string]: string,
  },
};

class SingleAccount extends React.Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      data: {},
      fields: {
        role_id: null,
        salutations: [],
        references: [],
        branches: [],
        agencies: [],
      },
      editMode: false,
      errors: {},
    };
  }

  state: StateShape;

  componentDidUpdate(prevProps: PropsShape) {
    if (
      prevProps.match.params.account_id !==
        this.props.match.params.account_id &&
      this.props.match.params.account_id !== undefined
    ) {
      this.pageReady.refreshPage();
    }
  }

  getFooterButtons = actions => {
    if (!this.props.canEdit)
      return (
        <button
          data-meta={{
            type: 'text',
          }}
          type="button"
          className="btn--text"
          onClick={() => this.props.history.push('/accounts')}>
          {l('JOB-ACTIONS-BACK')}
        </button>
      );

    if (!this.state.editMode) {
      const response = [];
      response.push(
        <button
          data-meta={{
            type: 'text',
          }}
          type="button"
          className="btn--text"
          onClick={() => this.props.history.push('/accounts')}>
          {l('JOB-ACTIONS-BACK')}
        </button>,
      );

      if (this.state.data.status !== UserStatus.IDs.DELETED) {
        response.push(
          <button
            type="button"
            className="btn--primary"
            onClick={this.toggleEdit}>
            {l('ACTIONS-EDIT')}
          </button>,
        );
      }

      return response;
    }

    const actionConfig = [
      {
        name: 'cancel-form',
        'data-meta': {
          type: 'text',
        },
        type: 'button',
        label: 'JOB-ACTIONS-BACK',
        action: this.toggleEdit,
      },
      { name: 'submit', className: 'btn--primary', type: 'submit' },
    ];

    return actionConfig
      .map(act => ({
        ...act,
        ...actions.find(item => item.name === act.name),
      }))
      .map(act => (
        <button
          {...act.attrs}
          data-meta={act['data-meta']}
          className={act.className}
          type={act.type}
          name={act.name}
          onClick={e => {
            if (act.action) {
              act.action(e);
            }
          }}>
          {l(act.label)}
        </button>
      ));
  };

  getFields = (): any => [
    ...[
      {
        title: 'CREATE_ACCOUNT-general',
        sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
        fields: [
          {
            type: 'switch',
            id: '21',
            name: 'login_allowed',
            label: 'ACCOUNT-login_allowed',
            itemClass: 'g g-1/1',
            value: this.state.data.login_allowed,
            containerClass: 'form__input-container g--shrink-wrap hard--bottom',
            description: l('ACCOUNT-login_allowed-description'),
            descriptionClass: 'g g--auto fl-h-center',
            meta: {
              onLabel: l('SWITCH-on_label'),
              offLabel: l('SWITCH-off_label'),
            },
          },
        ],
      },
    ],
    ...accountFields(getRoleNameFromId(this.state.fields.role_id), {
      salutations: this.state.fields.salutations,
      references: this.state.fields.references,
      branches: this.state.fields.branches,
      agencies: this.state.fields.agencies,
      documents: this.props.documents,
      user_id: this.props.match.params.account_id,
      data: this.state.data,
      removeAccountDoc: this.props.removeDocument,
    }),
  ];

  pageReady: GraphQLPageReady;

  handleResult = (res: Object): boolean => {
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        role_id: res.data.payload.role.id,
        salutations: res.data.payload.salutations,
        references: res.data.payload.references,
        branches: res.data.payload.branches,
        agencies: res.data.payload.agencies,
      },
      data: {
        ...res.data.payload,
        status: parseInt(res.data.payload.status, 10),
      },
    }));
    return this.updatePageTitleFromReload();
  };

  handleError = (err: Object): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      errors: err.data.payload,
    }));

  submitData = (payload: Object): Promise<*> => {
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      errors: {},
    }));
    return axios
      .post(`/api/1/users/${this.state.data.id}`, {
        user_id: this.state.data.id,
        role_id: this.state.data.role.id,
        ...payload,
      })
      .then((): any => this.handleSubmitSuccess())
      .catch((err: Object): any => this.handleSubmitError(err));
  };

  toggleEdit = (e): any => {
    if (e) {
      e.preventDefault();
    }

    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      editMode: !prevState.editMode,
    }));
  };

  updatePageTitleFromReload = (): any =>
    this.props.updateTitle(
      this.state.data.role.description,
      `${this.state.data.name} ${this.state.data.surname}`,
    );

  handleSubmitSuccess = (): any => {
    this.setState(
      (prevState: StateShape): StateShape => ({
        ...prevState,
        editMode: false,
      }),
      () => {
        this.pageReady.refreshPage();
        this.props.emitUpdate();
      },
    );
  };

  handleSubmitError = (err: Object): any => {
    this.props.actions.updateAccountFailed();
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      errors: err.data.payload,
    }));
  };

  onDocumentsResponse = ({ data }) => {
    this.props.updateDocs(data?.data?.user);
  };

  render = () => {
    return (
      <SliderContext.Consumer>
        {context => (
          <Fragment>
            <ActionBar name="single_account" />
            <GraphQLPageReady
              ref={instance => {
                this.pageReady = instance;
              }}
              onData={res => this.onDocumentsResponse(res)}
              queryId="getAccountDocuments"
              queryString={getAccountDocuments(
                this.props.match.params.account_id,
              )}>
              <PageReady
                url={`/api/1/view/users/${this.props.match.params.account_id}`}
                onData={res => this.handleResult(res)}>
                <Form
                  scrollToTop={context.scrollToTop}
                  submitAct={this.submitData}
                  sections={this.getFields()}
                  wrappingClass="gw"
                  wrappingOuterClass=""
                  afterSubmitErrors={formatErrors(
                    this.state.errors,
                    errorFormatting,
                  )}
                  validateBefore={accountCreateUpdateValidation(
                    getRoleNameFromId(
                      (this.state.data.role && this.state.data.role.id) || null,
                    ),
                    false,
                    this.props.actions.updateAccountFailed,
                  )}
                  viewOnly={!this.props.canEdit || !this.state.editMode}
                  cancelAct={this.toggleEdit}
                  renderFooter={actions => (
                    <FooterBar>
                      <div className="fl-right">
                        <div className="fl-right__item">
                          <ButtonGroup alt numButtons={3}>
                            {this.getFooterButtons(actions)}
                          </ButtonGroup>
                        </div>
                      </div>
                    </FooterBar>
                  )}
                />
              </PageReady>
            </GraphQLPageReady>
          </Fragment>
        )}
      </SliderContext.Consumer>
    );
  };
}

type ReduxStateShape = {
  documents?: [DocumentType],
};

const mapState = ({ accountsStore }): ReduxStateShape => ({
  documents: [...accountsStore.base.documents],
});

const mapActions = (dispatch: Function, { match }: { match: Object }) => ({
  emitUpdate() {
    dispatch(emitAction('SINGLE_ACCOUNT_UPDATE_SUCCESS'));
  },
  updateDocs(payload) {
    dispatch({ type: 'ACCOUNTS_GET_DOCS', payload });
  },
  removeDocument(id: string | number, api: string): any {
    return Actions.removeAccountDocument(
      id,
      'static',
      match.params.account_id,
      dispatch,
      api,
    );
  },
  actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapState, mapActions)(SingleAccount));
