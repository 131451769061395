import generator from 'constants/generator';

const UploadRequest = generator('FILE_COMPONENT_UPLOAD');

export default {
  REMOVE_FILE: 'DOCUMENT_ACTION_REMOVE_FILE',
  UploadRequest,
  DOCUMENT_ACTION: {
    DELETE: 'DOCUMENT_ACTION_DELETE',
    VIEW: 'DOCUMENT_ACTION_VIEW',
    DOWNLOAD: 'DOCUMENT_ACTION_DOWNLOAD',
    MINIMIZE: 'DOCUMENT_ACTION_MINIMIZE',
  },
};
