import isContractor from 'machines/conditions/isContractor';
import isPropertyManager from 'machines/conditions/isPropertyManager';
import isConstructionManager from 'machines/conditions/isConstructionManager';
import hasPayments from './hasPayments';
import isAssigned from './isAssigned';
import isDirectJob from './isDirectJob';
import isInvitee from './isInvitee';
import isJobStateWithin from './isJobStateWithin';
import isOfferJob from './isOfferJob';
import isOnProjectTeam from './isOnProjectTeam';
import isProjectJob from './isProjectJob';
import isProjectOwner from './isProjectOwner';
import isRejectedContractor from './isRejectedContractor';
import isCreating from './isCreating';

export default {
  isContractor,
  isPropertyManager,
  isConstructionManager,
  hasPayments,
  isAssigned,
  isDirectJob,
  isInvitee,
  isJobStateWithin,
  isOfferJob,
  isOnProjectTeam,
  isProjectJob,
  isProjectOwner,
  isRejectedContractor,
  isCreating,
};
