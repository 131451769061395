import React from 'react';
import GetSvg from 'components/GetSvg';

export default ({ toggle, isOpen, title }) => (
  <button type="button" onClick={toggle} className="btn--text--base">
    <GetSvg
      svg={isOpen ? 'angle_down' : 'angle_up'}
      wrapperClass="simple-list__body__group__header__handle"
    />
    <span className="fs--h4 lh--reset">{title}</span>
  </button>
);
