/** @format */

// @flow
import React from 'react';

import SwitchView from 'components/SwitchView';
import l from 'helpers/locale';

import List from './list.js';
import NewForm from './form.js';

type PropsShape = {
  close: Function,
  onListClick: Function,
  hiddenIds: number[],
  onFormSubmit: Function,
  listQuery: Function,
  // views:
};

type StateShape = {
  popupClass: string,
};

class GrantPopup extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    disconnectSoEndpoint: '',
  };

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      popupClass: 'popup',
    };
  }

  state: StateShape;

  componentDidMount() {
    this.startListener();

    if (this.reactPopup && this.reactPopup.getBoundingClientRect().left < 52) {
      this.setClass();
    }
  }

  componentWillUnmount() {
    this.killListener();
  }

  setClass(): any {
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      popupClass: 'popup popup--pushed-right',
    }));
  }

  // refactor...
  views = [
    {
      type: 'list',
      label: l('CONTACT_LIST-list'),
      active: true,
    },
    {
      type: 'form',
      label: l('CONTACT_LIST-new'),
      active: false,
    },
  ];

  listener: any;

  reactPopup: HTMLElement;

  itemClick = (id: Number, contact: Object): any => {
    this.props.onListClick(contact);
    return this.props.close();
  };

  startListener = () => {
    document.addEventListener('click', this.listenerAction);
  };

  killListener = () => {
    document.removeEventListener('click', this.listenerAction);
  };

  listenerAction = (event: Object) => {
    if (this.reactPopup && !this.reactPopup.contains(event.target)) {
      /*
       * @author @robguy21
       * This is some horrible code. I am ashamed by this. However, I couldn't find a way
       * to reliably change react-select components so that I could nullify the event bubbling
       * throughout the dom after an option is selected.
       *
       * Because react-select removes the menu list from the dom after an option is selected
       * when the event gets here we aren't able to get the "real" dom tree (which is called
       * by reactPopup.contains(event.target) ).
       *
       * This means that even though the react-select option *was* a child of reactPopup, it no longer
       * is which means that the entire popup closes.
       *
       * https://github.com/sensational/offer-marketplace/issues/3470
       */
      const isReactSelectOption = Array.from(
        event?.target?.classList || [],
      ).some(cName => cName.includes('Select__'));
      if (!isReactSelectOption) {
        this.props.close();
      }
    }
  };

  formSubmit = (payload: Object): any => {
    this.props.close();
    return this.props.onFormSubmit(payload);
  };

  render = (): React$Element<*> => (
    <div
      className={this.state.popupClass}
      ref={(c: HTMLElement): boolean => {
        this.reactPopup = c;
        return true;
      }}>
      <div className="popup__inner">
        <div className="popup__inner__container">
          <SwitchView views={this.views}>
            <List
              type="list"
              clickAct={this.itemClick}
              hiddenIds={this.props.hiddenIds}
              listQuery={this.props.listQuery}
            />
            <NewForm type="form" callback={this.formSubmit} />
          </SwitchView>
        </div>
      </div>
      <div className="triangle-with-shadow" />
    </div>
  );
}

export default GrantPopup;
