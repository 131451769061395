/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';

import l from '../../../../helpers/locale';

type ReduxStateShape = {
  contractors: Object[],
};

type PropsShape = {
  callback: any => any,
  close: () => any,
} & ReduxStateShape;

type StateShape = {};

class RequestOffers extends React.Component<PropsShape, StateShape> {
  static defaultProps = {};

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      contractors: props.contractors,
    };
  }

  state: StateShape;

  submit = (): any => {
    this.props.callback({
      contractors: this.state.contractors,
    });
    return this.closeModal({ preventDefault: (): boolean => true });
  };

  closeModal = (e: Object): any => this.props.close(e);

  replaceAfterLang = (str: string): string =>
    str.replace(':contractor_count', this.props.contractors.length);

  render(): React$Element<*> {
    return (
      <div className="layout-container">
        <div className="layout-container__header">
          <div className="gw">
            <div className="g g-1/1">
              <h2 className="align--left">{l('REQUEST_OFFERS-title')}</h2>
            </div>
          </div>
        </div>
        <div className="layout-container__body">
          <h3 className="u-text__base--small push--bottom">
            {this.replaceAfterLang(l('REQUEST_OFFERS-description'))}
          </h3>
          {this.state.contractors.map(
            (item: Object, k: number): React$Element => (
              <div className="gw" key={k}>
                <div className="g g--auto">
                  <div className="gw">
                    <div className="g g-2/3">{item.billing_company}</div>
                    <div
                      className="g g-1/3"
                      data-qe-id={`action-send-request-commit_mail_${k}`}>
                      {item.key_user_email}
                    </div>
                  </div>
                </div>
              </div>
            ),
          )}
          <div>
            <hr className="push--small--bottom" />
          </div>
        </div>

        <div className="layout-container__footer">
          <div className="layout-container__footer__item">
            <button
              type="button"
              className="btn--text"
              onClick={this.props.close}>
              {l('cancel')}
            </button>
          </div>
          <div className="layout-container__footer__item">
            <button
              type="button"
              className="btn--primary"
              onClick={this.submit}
              data-qe-id="action-click-invite">
              {l('ACTION-invite')}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: Object): ReduxStateShape => ({
  contractors: state.jobStore.data.job.vmp_subscribers || [],
});

export default connect(mapState)(RequestOffers);
