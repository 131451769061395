import { useState, useLayoutEffect, useDebugValue } from 'react';

function useCardClass(isOpen, withPadding, withWatermark, hasError) {
  const [cardClass, setCardClass] = useState('card');

  useLayoutEffect(() => {
    const sibling = isOpen ? 'is-open' : 'is-closed';
    const baseClass = 'card';
    let classes = ['card'];

    if (!withPadding) {
      classes = classes.concat(`${baseClass}--no-padding`);
    }

    if (withWatermark) {
      classes = classes.concat(`${baseClass}--with-watermark`);
    }

    if (hasError) {
      classes = classes.concat(`${baseClass}--error`);
    }

    const finalClass = `${classes.join(' ')} ${sibling}`;

    setCardClass(() => finalClass);
  }, [isOpen, withPadding, withWatermark, hasError]);
  useDebugValue(cardClass);
  return cardClass;
}

export default useCardClass;
