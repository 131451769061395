/**
 * /* eslint-disable
 *
 * @format
 */

import React from 'react';
import Card from '../../../components/Card';

const TermsAndConditions = (): React$Element<*> => (
  <Card title="GENERAL-terms" canCollapse={false}>
    <div className="u-para--small">
      <h2>
        Conditions d'utilisation Virtual Marketplace{' '}
        <small>(ci-après dénommée „VMP“)</small>
      </h2>
      <p>
        Les présentes conditions contractuelles s&rsquo;appliquent à
        l&rsquo;utilisation des services fournis par
      </p>
      <p>
        <strong>Allianz Suisse Immobilien AG</strong>
        <br /> Richtiplatz 1
        <br /> 8304 Wallisellen - Schweiz
      </p>
      <p>
        (ci-après dénommée &quot;<strong>ASI</strong>&quot;).
      </p>
      <p>
        Vous devez accepter ces conditions contractuelles lors de
        l&rsquo;ouverture d&rsquo;un compte dans le Virtual Marketplace (VMP).
        Elles s&rsquo;appliquent aussi bien aux utilisateurs commanditaires
        (ci-après dénommés &quot; Entité contractante &quot;, ce qui inclut ASI
        et ses partenaires/entreprises associés) qu&rsquo;aux utilisateurs
        contractuels (ci-après dénommés &quot; Contractant &quot;).
      </p>
      <p>
        Toute modification future de ces conditions contractuelles prendra effet
        pour vous à la date indiquée dans les lignes directrices actualisées du
        VMP. ASI se réserve le droit de modifier les présentes conditions
        générales unilatéralement.
      </p>
      <p>
        Les conditions contractuelles et toute autre disposition légale ou
        information relative aux services Allianz sont disponibles à tout moment
        sur la page d&rsquo;accueil du VMP sous le lien &quot; Conditions
        générales &quot;.
      </p>
      <p>
        Les conditions contractuelles sont divisées en
        <br />
        A) Fair Play dans le VMP
        <br />
        B) Conditions d’utilisation
        <br />
        C) Conditions générales
        <br />
        D) Comportement des nombres entiers et lutte contre la corruption
        <br />
        F) Confidentialité et protection des données
        <br />
        Wallisellen, 1.1.2020
      </p>

      <h2>A) Fair Play dans le VMP</h2>
      <p>
        Dans le cadre du VMP, les règles de Fair Play suivantes
        s&rsquo;appliquent de la même manière à tous les utilisateurs. Le
        non-respect de ces principes peut entraîner l&rsquo;exclusion du VMP.
      </p>
      <p>
        <strong>
          ASI exige le respect et l&rsquo;acceptation dans le VMP.
        </strong>
      </p>
      <p>
        Pour cette raison, les utilisateurs doivent noter et suivre les
        principes suivants :
      </p>
      <ol>
        <li>
          Les utilisateurs reconnaissent le VMP comme un système fermé
          d&rsquo;Allianz Suisse
        </li>
        <li>
          Les utilisateurs respectent le fait que tous les utilisateurs du VMP
          représentent des intérêts différents.
        </li>
      </ol>
      <p>
        Les contractants acceptent que tant qu&rsquo;utilisateurs du VMP ils
        soient des contractants d&rsquo;ASI.
      </p>
      <p>
        Les contractants préparent des offres dans le cadre du VMP au mieux de
        leurs connaissances et de leurs convictions.
      </p>
      <p>
        Les contractants comprennent qu&rsquo;aucune correspondance ne sera
        échangée concernant l&rsquo;évaluation des performances par Allianz.
      </p>
      <p>
        Les contractants acceptent que le VMP puisse également afficher le
        statut de client Allianz respectif (numéro de client, le cas échéant),
        l&rsquo;évaluation du travail et les recommandations éventuelles des
        agents d&rsquo;assurance aux partenaires commerciaux / sous-traitants /
        prestataires de services.
      </p>

      <h2>B) Conditions d&rsquo;utilisation</h2>
      <h4>1. Gamme de services</h4>
      <p>
        Le VMP d&rsquo;ASI est une plateforme numérique qui permet de concevoir
        plus efficacement les processus impliqués dans l&rsquo;entretien et la
        réparation des immeubles et dans les projets de construction. Les appels
        d&rsquo;offres pour les travaux de service dans l&rsquo;industrie suisse
        de la construction sont réalisés à l&rsquo;aide d&rsquo;une interface
        utilisateur graphique. Le VMP est conçu comme un système fermé de
        l&rsquo;ASI.
      </p>

      <h4>2. Vérification d‘identité</h4>
      <p>
        Toute entité qui s&rsquo;identifie correctement à l&rsquo;aide des
        moyens d&rsquo;identification appropriés est considérée comme autorisée
        par ASI à utiliser les services offerts par le VMP. Dans ce cas, ASI
        peut supposer que les instructions et les notifications émises
        proviennent de l&rsquo;utilisateur. Les utilisateurs sont responsables
        de l&rsquo;exactitude de toutes les données qu&rsquo;ils transmettent en
        ligne à l&rsquo;aide de leurs moyens d&rsquo;identification. Les
        dommages résultant de la non-reconnaissance des défauts de légitimation
        et des faux sont à la charge des utilisateurs eux-mêmes, à condition
        qu&rsquo;ASI ne soit pas en faute.
      </p>

      <h4>3. Sécurité</h4>
      <p>
        L&rsquo;Internet et le réseau de radio publique sont des réseaux
        mondiaux et ouverts qui sont en principe librement accessibles. Dans le
        cadre des possibilités dont elle dispose, ASI prend les mesures
        appropriées pour rendre la communication par ces canaux aussi sûre que
        possible.
      </p>
      <p>
        Tout dommage résultant de l&rsquo;utilisation du courrier, du téléphone,
        du courrier électronique, des notifications du cockpit ou d&rsquo;autres
        moyens de transmission est à la charge de l&rsquo;utilisateur, à
        condition qu&rsquo;ASI ne soit pas en faute.
      </p>

      <h4>4. Matériel, logiciel, accès, verrouillage</h4>
      <p>
        4.1 Pour accéder au VMP, l&rsquo;utilisateur a besoin de matériel et de
        logiciels appropriés ainsi que d&rsquo;une connexion Internet. Ceux-ci
        ne sont pas fournis par ASI.
      </p>
      <p>
        4.2 Après une identification réussie, l&rsquo;utilisateur est autorisé à
        accéder au VMP en saisissant les marques de légitimation (nom
        d&rsquo;utilisateur et mot de passe personnel).
      </p>
      <p>
        4.3 ASI a le droit de bloquer l&rsquo;accès au VMP à tout moment, sans
        indication de motifs et sans autre notification.
      </p>
      <p>
        Par exemple, ASI bloquera l&rsquo;accès à la plate-forme s&rsquo;il
        existe des motifs raisonnables de soupçonner une utilisation frauduleuse
        des justificatifs d&rsquo;identité.
      </p>

      <h4>5. Précautions de sécurité de l&rsquo;utilisateur</h4>
      <p>
        L&rsquo;utilisateur est tenu de garder ses caractéristiques
        d&rsquo;identification secrètes afin d&rsquo;éviter tout abus par des
        tiers non autorisés.
      </p>
      <p>
        L&rsquo;utilisateur doit prendre les précautions de sécurité nécessaires
        pour le dispositif terminal utilisé pour l&rsquo;accès à la plateforme.
        En particulier, l&rsquo;utilisateur doit protéger de manière adéquate
        cet équipement terminal contre l&rsquo;accès par des tiers non autorisés
        et contre les virus informatiques.
      </p>

      <h4>6. Communication</h4>
      <p>
        L&rsquo;acceptation d&rsquo;une mission de service de la part du
        Contractant constitue un contrat juridiquement contraignant entre le
        Contractant et ASI. Les autres dispositions contractuelles se trouvent
        dans les AGB dans la partie B, conformément à la norme SIA 118.
      </p>

      <h4>7. Modification des conditions contractuelles</h4>
      <p>
        ASI est en droit de modifier les présentes conditions contractuelles, y
        compris les règles de fair-play et les conditions générales, à tout
        moment et sans préavis. Si elles sont modifiées, elles sont réputées
        acceptées si le VMP continue d&rsquo;être utilisé.
      </p>

      <h4>
        8. Protection des données et autorisation d&rsquo;utilisation des
        données
      </h4>
      <p>
        La protection des données du participant au VMP est très importante pour
        l&rsquo;ASI. Dans la mesure où ASI recueille, traite ou utilise les
        données personnelles du participant au VMP dans le cadre de ses
        activités, cela se fait exclusivement dans le respect des dispositions
        de la loi sur la protection des données.
      </p>
      <p>
        L&rsquo;utilisateur accorde à ASI le droit d&rsquo;enregistrer, de
        stocker et, si nécessaire, d&rsquo;évaluer les contenus et les
        informations ainsi que les transactions commerciales traitées par le
        biais du VMP, en tenant compte des exigences légales, et de les
        transmettre à d&rsquo;autres sociétés du groupe Allianz Suisse ainsi
        qu&rsquo;aux agences générales de Allianz Suisse. Les sociétés du groupe
        Allianz Suisse et les agences générales d&rsquo;Allianz Suisse sont
        habilitées à utiliser toutes les données transmises via le VMP à des
        fins de marketing et de publicité internes. En outre,
        l&rsquo;utilisateur accepte que son statut de client Allianz (numéro de
        client, le cas échéant), son évaluation des travaux et les
        recommandations éventuelles des agents d&rsquo;assurance puissent
        également être affichés dans le VMP aux partenaires commerciaux /
        sous-traitants / prestataires de services d&rsquo;Allianz Suisse.
      </p>

      <h4>9. Utilisation de la plate-forme VMP</h4>
      <p>
        Toutes les informations, les services et les données du VMP d&rsquo;ASI
        sont utilisées exclusivement aux fins définies dans les présentes
        conditions générales. Il est interdit de copier, reproduire et
        distribuer à des fins commerciales ou de modifier la plate-forme, y
        compris toutes les informations qui s&rsquo;y rapportent.
      </p>

      <h4>10. Respect des critères d&rsquo;éligibilité</h4>
      <p>
        Pour pouvoir accéder au VMP, l&rsquo;utilisateur est tenu de remplir les
        critères d&rsquo;aptitude d&rsquo;ASI. Ceux-ci sont demandés par
        l&rsquo;utilisateur lors de l&rsquo;inscription. ASI n&rsquo;est pas
        responsable des dommages éventuels si les critères d&rsquo;adéquation
        sont mal saisis.
      </p>

      <h4>11. Décharge de responsabilité</h4>
      <p>
        Les informations sur le VMP sont régulièrement vérifiées et mises à
        jour. Néanmoins, les informations peuvent changer entre-temps. ASI
        décline donc toute responsabilité quant à l&rsquo;exactitude,
        l&rsquo;exhaustivité, la confidentialité ou le secret des données ou
        quant à l&rsquo;actualité des services et des informations proposés sur
        le VMP. ASI se réserve le droit de changer ou de modifier le contenu de
        sa plate-forme à tout moment. En particulier, toute responsabilité pour
        des dommages directs ou indirects ou des dommages consécutifs que les
        utilisateurs ou des tiers pourraient subir en raison de
        l&rsquo;utilisation du VMP et des informations qu&rsquo;il contient est
        exclue. Cette exclusion de responsabilité comprend également, entre
        autres, la perte de données due à l&rsquo;utilisation abusive de tiers
        et aux virus informatiques. En particulier, ASI n&rsquo;assume aucune
        responsabilité pour les contenus qui ne sont pas compatibles avec les
        dispositions légales en vigueur en dehors de la Suisse.
      </p>
      <p>
        La responsabilité est également exclue pour les erreurs de transmission,
        les défauts techniques, les dysfonctionnements ou pour
        l&rsquo;utilisation abusive du réseau téléphonique et de données, pour
        la surcharge du réseau et pour tout manque de légitimité.
      </p>
      <p>
        ASI n&rsquo;est pas responsable des entrées faites par les utilisateurs
        ou du contenu des sites qui ne sont pas vérifiés ou maintenus par ASI
        (par exemple, les liens).
      </p>

      <h2>C) Conditions générales (CG)</h2>
      <h4>1. Portée et validité</h4>
      <p>
        1.1 Les CG règlent la conclusion, le contenu et le traitement des
        contrats pour les mandats d&rsquo;entreprise et de droit de commande
        dans le domaine des travaux de maintenance et de réparation des biens
        immobiliers ainsi que pour les projets de construction entre ASI et un
        contractant. Dans ce qui suit, ils sont désignés conjointement par le
        terme &quot; partenaires contractuels &quot;.
      </p>
      <p>
        1.2 ASI se réfère aux conditions générales applicables dans la demande
        de devis. En soumettant une offre écrite ou, à défaut, au plus tard lors
        de l&rsquo;acceptation d&rsquo;une adjudication, le contractant
        reconnaît l&rsquo;applicabilité des présentes CG. Les conditions
        générales de vente ou de livraison du contractant ne sont pas
        applicables, même s&rsquo;il y est fait référence dans son offre ou dans
        d&rsquo;autres documents connexes.
      </p>
      <p>
        1.3 Les dérogations aux présentes conditions générales doivent être
        expressément désignées comme telles dans la demande d&rsquo;offre ou
        l&rsquo;offre. Pour être valables, elles doivent être mentionnées par
        écrit dans le document contractuel.
      </p>

      <h4>2. Éléments du contrat et ordre de priorité</h4>
      <p>
        En cas de contradictions, le contenu du contrat selon le document
        contractuel prime sur ces CG.
      </p>

      <h4>3. Offre</h4>
      <p>
        3.1 La soumission des offres du contractant dans le VMP est gratuite.
      </p>
      <p>
        3.2 Si l&rsquo;offre s&rsquo;écarte de la demande d&rsquo;offre
        d&rsquo;ASI, le contractant le signalera expressément.
      </p>
      <p>
        3.3 Sauf indication contraire dans la demande de devis, le contractant
        reste lié pendant trois mois à compter de la date de soumission du
        devis.
      </p>
      <p>
        3.4 Jusqu&rsquo;à la signature du document contractuel ou
        l&rsquo;acceptation numérique de l&rsquo;offre (commande) par ASI, les
        parties peuvent se retirer des négociations contractuelles sans
        conséquences financières. L&rsquo;engagement des entrepreneurs à
        l&rsquo;égard de leur offre conformément au point 3.3 reste
        réservé.&nbsp;
      </p>

      <h4>4. Produits et services, livraisons</h4>
      <p>
        4.1 Le type, l&rsquo;étendue et les caractéristiques des produits et
        services etc. sont réglementés dans le document contractuel. Il peut se
        référer à d&rsquo;autres documents.
      </p>
      <p>
        4.2 Le transfert des avantages et des risques a lieu dès
        l&rsquo;acceptation du service ou de la prestation par ASI sur le lieu
        d&rsquo;exécution (chiffre 17).
      </p>

      <h4>5. Exécution</h4>
      <p>
        5.1 Les partenaires contractuels doivent s&rsquo;informer immédiatement
        de toute circonstance de leur ressort qui met ou pourrait mettre en
        danger l&rsquo;exécution du contrat.
      </p>
      <p>
        5.2 L&rsquo;exécution des services est effectuée selon des méthodes
        reconnues et les normes en vigueur et conformément à toutes les
        instructions données par ASI conformément au contrat.
      </p>
      <p>
        5.3 Le contractant informe régulièrement ASI de l&rsquo;avancement des
        travaux et obtient d&rsquo;ASI toutes les spécifications nécessaires en
        cas d&rsquo;ambiguïté.
      </p>

      <h4>6. Participation des sous-traitants</h4>
      <p>
        Le contractant ne doit faire appel à des sous-traitants qu&rsquo;avec
        l&rsquo;approbation écrite d&rsquo;ASI. ASI ne peut pas refuser
        l&rsquo;approbation sans motif justifié, les motifs soumis au secret
        officiel ne devant pas être divulgués. Le contractant reste responsable
        envers ASI de la fourniture des services.
      </p>

      <h4>7. Rémunération</h4>
      <p>
        7.1 Le contractant fournit les services à des prix fixes ou en fonction
        des dépenses avec une limite supérieure de rémunération (plafond des
        coûts), en annonçant dans son offre les types de coûts et les taux qui
        font désormais partie du contrat.
      </p>
      <p>
        7.2 Si le contractant fournit des services contre remboursement des
        dépenses, il doit fournir un rapport accompagné de la facture, qui doit
        être approuvé par la personne responsable chez ASI. Le rapport doit
        énumérer les services et le temps et les efforts de chaque personne
        employée par le contractant sur une base quotidienne.
      </p>
      <p>
        7.3 La rémunération convenue couvre toutes les prestations qui sont
        nécessaires à la bonne exécution du contrat.
      </p>
      <p>
        7.4 Les factures sont émises après la fourniture des prestations ou, si
        cela a été convenu contractuellement, ─ après acceptation des
        prestations facturées. Les factures sont payables dans les 30 jours
        suivant leur réception. Nous nous réservons le droit de déroger aux
        accords figurant dans le document contractuel, en particulier à un
        éventuel plan de paiement.
      </p>
      <p>
        7.5 S&rsquo;il est convenu de paiements partiels (acomptes et paiements
        de comptes), ASI peut exiger que le contractant fournisse une garantie
        pour le montant de manière appropriée (par exemple au moyen d&rsquo;une
        garantie bancaire).
      </p>
      <p>
        7.6 Un ajustement de la rémunération pendant la durée du contrat ne sera
        effectué que si cela est indiqué dans le document contractuel.
      </p>

      <h4>8. Modifications des services</h4>
      <p>
        8.1 Le contractant doit informer ASI des améliorations et des
        développements ultérieurs qui, pour des raisons techniques ou
        économiques, font qu&rsquo;une modification des services semble
        appropriée. En outre, il informe ASI des conséquences d&rsquo;une
        modification des services sur les progrès existants.
      </p>
      <p>
        8.2 Les deux partenaires contractuels peuvent demander par écrit des
        modifications des prestations convenues par l&rsquo;intermédiaire des
        personnes responsables (voir paragraphe 11.2). Si des effets sur les
        coûts ou les délais sont à prévoir, les modifications de la prestation
        doivent être proposées dans un délai à convenir entre les partenaires
        contractuels. Cette offre comprend une évaluation de la faisabilité, la
        description des services supplémentaires nécessaires et les conséquences
        pour les services, notamment en ce qui concerne les coûts et les délais.
        Il contient une indication de si la fourniture de services doit être
        interrompue en tout ou en partie jusqu&rsquo;à ce qu&rsquo;une décision
        ait été prise pour mettre en œuvre le changement et comment une telle
        interruption affecterait la rémunération et les délais. Le mandataire ne
        reçoit une rémunération pour ces offres que si cela a été expressément
        convenu.
      </p>
      <p>
        8.3 Sauf convention contraire, le contractant poursuit ses travaux
        conformément au contrat pendant l&rsquo;examen des modifications
        proposées.
      </p>
      <p>
        8.4 La modification de la prestation et les éventuelles adaptations de
        la rémunération, des délais et autres points contractuels doivent être
        consignées par écrit dans un supplément au document contractuel avant
        l&rsquo;exécution. L&rsquo;ajustement de la rémunération est calculé en
        fonction des taux en vigueur au moment où la modification a été
        convenue. Pour l&rsquo;approbation des modifications qui n&rsquo;ont
        aucune influence sur les coûts, les délais et la qualité, la signature
        d&rsquo;un protocole de modification par les personnes responsables
        d&rsquo;ASI et du contractant est suffisante (voir point 11.2).
      </p>

      <h4>9. Garantie légale</h4>
      <p>
        9.1 Le contractant garantit que son offre et ses prestations ne portent
        pas atteinte aux droits de propriété industrielle reconnus de tiers.
      </p>
      <p>
        9.2 Les réclamations de tiers pour cause de violation de droits de
        propriété industrielle sont traitées par le fournisseur à ses frais et à
        ses risques. ASI informe le contractant de ces réclamations par écrit et
        sans délai, et charge le contractant, dans la mesure du possible
        conformément au droit procédural applicable, de mener toute procédure
        judiciaire et de prendre les mesures appropriées pour le règlement
        judiciaire ou extrajudiciaire du litige. Dans ces conditions, le
        contractant prend en charge les frais de justice, d&rsquo;avocat et
        autres frais raisonnables et les droits de licence imposés, ainsi que
        les paiements de satisfaction et d&rsquo;indemnisation, qu&rsquo;ASI a
        encourus en rapport avec le litige, à condition que la violation des
        droits de propriété industrielle ne soit pas due à l&rsquo;utilisation
        des services du contractant par ASI en violation du contrat.
      </p>
      <p>
        9.3 Si une plainte est déposée pour violation des droits de propriété
        industrielle ou si une mesure de précaution est demandée, le contractant
        peut, à ses frais et à sa discrétion, soit obtenir pour ASI le droit
        d&rsquo;utiliser les services sans responsabilité pour violation des
        droits de propriété industrielle, soit adapter les services ou les
        remplacer par d&rsquo;autres qui remplissent les exigences
        contractuelles de manière équivalente. Si ces possibilités
        n&rsquo;existent pas, le contractant remboursera la rémunération versée
        pour le service, en déduisant un montant proportionnel à
        l&rsquo;utilisation du service déjà effectuée par rapport à la durée
        totale (du service) ou à l&rsquo;utilisation habituelle (du produit). En
        outre, la responsabilité de l&rsquo;entrepreneur pour les dommages
        éventuels conformément à le chiffre 14 est réservée.
      </p>

      <h4>
        10. Sécurité de l&rsquo;information, confidentialité et protection des
        données
      </h4>
      <p>
        10.1 Les partenaires contractuels s&rsquo;engagent à préserver la
        confidentialité des faits et des données qui ne sont ni évidents ni
        généralement accessibles. Cette obligation est également imposée par la
        partie contractante aux tiers concernés, par exemple les sous-traitants.
        En cas de doute, les faits et les données sont traités
        confidentiellement. Les obligations de confidentialité subsistent avant
        la conclusion du contrat et également après la fin de la relation
        contractuelle ou après l&rsquo;exécution des prestations convenues. Ceci
        est soumis à l&rsquo;obligation légale de fournir des renseignements et
        des précisions.
      </p>
      <p>
        10.2 Le contractant peut divulguer l&rsquo;essentiel du contenu de la
        demande de prix aux sous-traitants potentiels qui seront mis en service,
        mais il doit par ailleurs traiter la demande de devis comme étant
        confidentielle.
      </p>
      <p>
        10.3 La publicité et les publications concernant des services
        spécifiques à un projet nécessitent l&rsquo;accord écrit préalable
        d&rsquo;ASI, ainsi que sa désignation en tant que référence.
      </p>
      <p>
        10.4 Si un partenaire contractuel ou un tiers impliqué par lui viole les
        obligations de confidentialité susmentionnées, le partenaire contractuel
        contrevenant doit à l&rsquo;autre partenaire contractuel une pénalité
        contractuelle, à moins qu&rsquo;il ne puisse prouver que ni lui ni le
        tiers impliqué ne sont en faute. Cette pénalité s&rsquo;élève à 10% de
        la rémunération totale par cas, mais ne peut dépasser CHF 50&rsquo;000
        par cas. Le paiement de la pénalité contractuelle ne libère pas la
        partie contractante de son obligation de garder le secret. Les droits
        aux dommages et intérêts selon les principes généraux de responsabilité
        (CO 97 et suivants) ou le chiffre 14 sont réservés; la pénalité
        contractuelle sera déduite des indemnités à verser éventuelles.
      </p>
      <p>
        10.5 Le contractant s&rsquo;engage à respecter, pour lui-même et son
        personnel, les prescriptions d&rsquo;ASI relatives à
        l&rsquo;exploitation, à la technique et à la sécurité, en particulier
        les exigences d&rsquo;accès aux systèmes, etc., à condition que
        celles-ci lui soient communiquées par écrit avant la conclusion du
        contrat ou qu&rsquo;elles soient convenues ultérieurement.
      </p>
      <p>
        10.6 Les dispositions applicables en matière de protection des données
        et de sécurité ainsi que les dispositions relatives au secret
        professionnel et de fonction (art. 320 et 321 du Code pénal) doivent
        être respectées. En particulier, le contractant est tenu de traiter les
        données personnelles d&rsquo;ASI qui lui sont transmises ou qui lui sont
        accessibles uniquement dans la mesure et exclusivement aux fins
        nécessaires à l&rsquo;exécution du contrat. Les parties peuvent conclure
        d&rsquo;autres accords contractuels, par exemple des accords de
        confidentialité.
      </p>

      <p>
        10.7 Le contractant doit garder secrets tous les renseignements
        confidentiels du client et le contenu du contrat pendant la durée de
        celui-ci et pendant [trois] ans par la suite. Il s&rsquo;engage à
        respecter à tout moment les lois applicables en matière de protection
        des données.
      </p>

      <p>
        En cas de perte de données ou d&rsquo;informations confidentielles, ASI
        doit être immédiatement informé à data-privacy@allianz.ch et le client
        doit coopérer à la limitation des dommages conformément aux instructions
        d&rsquo;ASI. Le contractant est responsable de tous les dommages à cet
        égard.
      </p>

      <h4>11. Protection de l&rsquo;environnement</h4>
      <p>
        Le contractant doit s&rsquo;efforcer de ne pas utiliser de matériaux ou
        de méthodes de construction qui mettent en danger la santé, la sécurité
        ou l&rsquo;environnement. Il doit prendre toutes les précautions
        possibles pour protéger l&rsquo;environnement.
      </p>

      <h4>12. Déploiement du personnel</h4>
      <p>
        12.1 Pour l&rsquo;exécution des prestations, le contractant ne doit
        faire appel qu&rsquo;à un personnel digne de confiance, soigneusement
        sélectionné et bien formé, même s&rsquo;il ne s&rsquo;agit que
        d&rsquo;une prestation accessoire, pour l&rsquo;exécution du contrat. A
        la demande d&rsquo;ASI, il remplace, dans un délai raisonnable, les
        personnes qui, selon l&rsquo;évaluation d&rsquo;ASI, ne possèdent pas
        les connaissances spécialisées nécessaires ou qui compromettent
        d&rsquo;une autre manière l&rsquo;exécution du contrat.
      </p>
      <p>
        12.2 Les partenaires contractuels s&rsquo;accordent sur
        l&rsquo;organisation du travail et désignent les personnes responsables.
      </p>
      <p>
        12.3 Dans les cas où, à son avis, il existe un besoin accru de
        protection, ASI peut demander au prestataire de services de lui
        soumettre des documents relatifs à des éclaircissements supplémentaires
        concernant les employés qu&rsquo;il emploie (par exemple, un extrait du
        casier judiciaire). Les détails sont réglés dans le contrat.
      </p>

      <h4>13. Retard</h4>
      <p>
        13.1 Les parties contractantes sont en retard sans autre formalité en
        cas de non-respect des délais convenus dans le document contractuel,
        dans le cas d&rsquo;autres délais après un rappel avec octroi d&rsquo;un
        délai de grâce raisonnable.
      </p>
      <p>
        13.2 Si le mandataire est en retard, ASI peut, à sa discrétion, si
        l&rsquo;exécution n&rsquo;est pas encore terminée, même après
        l&rsquo;expiration d&rsquo;un délai de grâce raisonnable accordé au
        contractant :
      </p>
      <ul>
        <li>
          insister sur l&rsquo;exécution ultérieure par le contractant et, en
          cas de faute, réclamer à celui-ci la réparation du dommage qui est la
          conséquence de l&rsquo;exécution non contractuelle, ou
        </li>
        <li>
          renoncer à l&rsquo;exécution ultérieure du contrat et, si le
          contractant est fautif, demander une indemnisation pour le dommage
          résultant de l&rsquo;inexécution, ou
        </li>
        <li>
          renoncer à l&rsquo;exécution ultérieure du contrat et de résilier le
          contrat en tout ou en partie avec effet rétroactif au moment de la
          conclusion du contrat, tout en annulant les services mutuels qui ont
          été précédemment fournis les uns aux autres et qui sont concernés par
          la résiliation, et, en cas de faute de la part du contractant,
          demander la réparation du préjudice qu&rsquo;ASI a subi du fait de la
          résiliation du contrat. Dans le cas de contrats à durée indéterminée,
          la résiliation extraordinaire du contrat avec effet immédiat remplace
          la résiliation rétroactive du contrat.
        </li>
      </ul>
      <p>
        13.3 Si le mandataire est en retard, il est redevable d&rsquo;une
        pénalité contractuelle pour les dates indiquées en conséquence dans le
        document contractuel, à moins qu&rsquo;il ne prouve que ni lui ni les
        tiers qu&rsquo;il a mandatés ne sont en faute. Cette pénalité
        s&rsquo;élève à 1 pour mille par jour de retard et par jour de
        défaillance, sans toutefois dépasser un total de 10 % de la rémunération
        totale du contrat correspondant dans le cas de services ponctuels ou de
        la rémunération pour 12 mois dans le cas de services récurrents. La
        pénalité contractuelle est également due si les prestations sont
        acceptées sans réserve ou si le destinataire des prestations fait usage
        des recours légaux conformément au point 12.2 ci-dessus. Le paiement de
        la pénalité contractuelle ne libère pas le mandataire des autres
        obligations contractuelles. ASI se réserve le droit de demander des
        dommages et intérêts conformément à le chiffre 14 ci-dessus ; la
        pénalité contractuelle sera compensée par les dommages et intérêts
        éventuellement dus.
      </p>

      <h4>14. Garantie</h4>
      <p>
        14.1 Le mandataire garantit que les produits et les services
        contractuels qu&rsquo;il a livrés présentent les caractéristiques
        convenues, en outre les caractéristiques qu&rsquo;ASI peut exiger même
        sans accord spécial selon l&rsquo;état de la technique respectif au
        moment de la conclusion du contrat (sauf mention contraire dans le
        contrat) et en toute bonne foi.
      </p>
      <p>
        14.2 En cas de défaut, ASI peut exiger que le défaut soit réparé
        gratuitement ou peut effectuer une déduction sur la rémunération
        correspondant à la valeur réduite. Le contractant doit remédier au
        défaut dans un délai raisonnable et supporter tous les frais qui en
        découlent.
      </p>
      <p>
        14.3 Si le mandataire n&rsquo;a pas, pas à temps ou n&rsquo;a pas
        effectué avec succès la rectification des défauts demandée, ASI peut
        effectuer une déduction sur la rémunération correspondant à la valeur
        réduite. En cas de défauts importants, il peut également procéder
        conformément à la section 12.2.
      </p>
      <p>
        14.4 Les défauts doivent être signalés dans les 60 jours suivant leur
        découverte. Les droits de garantie expirent dans un délai d&rsquo;un an
        à compter de la livraison ou de la réception. Après l&rsquo;élimination
        des défauts dénoncés, les délais de livraison des pièces de rechange
        recommencent à courir. Les défauts malicieusement dissimulés peuvent
        être réclamés pendant dix ans à compter de la livraison ou de la
        réception.
      </p>
      <p>
        14.5 Les services rendus après l&rsquo;expiration de la période de
        garantie sont payants et sont effectués aux conditions normales du
        marché.
      </p>
      <p>
        14.6 Les garanties dérogatoires pour les produits de tiers doivent être
        réglées dans le document contractuel.
      </p>

      <h4>15. Responsabilité</h4>
      <p>
        15.1 Le contractant est responsable des dommages causés par lui, ses
        auxiliaires et les sous-traitants impliqués dans le cadre de la relation
        contractuelle, à moins qu&rsquo;il ne prouve que ni lui ni les
        auxiliaires/sous-traitants ne sont fautifs. Sauf convention contraire
        dans l&rsquo;acte contractuel, la responsabilité pour négligence légère
        est limitée à un maximum de CHF 1 million par contrat.
      </p>
      <p>
        15.2 Est exclue, dans la mesure où la loi le permet, la responsabilité
        pour perte de profit.
      </p>

      <h4>16. Conséquences de la résiliation de la relation contractuelle</h4>
      <p>
        Les partenaires contractuels déterminent dans le contrat quels moyens
        d&rsquo;exploitation, données et documents mis à disposition dans le
        cadre de la relation contractuelle doivent être restitués à
        l&rsquo;autre partenaire contractuel ou détruits de manière irrémédiable
        à la fin de la relation contractuelle et dans quel délai.
      </p>

      <h4>17. Cession, transfert et mise en gage</h4>
      <p>
        Les droits et obligations découlant de la relation contractuelle ne
        peuvent être cédés, transférés ou mis en gage à des tiers sans
        l&rsquo;accord écrit préalable du partenaire contractuel. ASI ne
        refusera d&rsquo;accorder l&rsquo;autorisation de cession et de mise en
        gage des créances du contractant que dans des cas justifiés.
      </p>

      <h4>18. Lieu d&rsquo;exécution</h4>
      <p>
        Le lieu d&rsquo;exécution des prestations du contractant est le lieu
        convenu dans le document contractuel ou, à défaut, l&rsquo;adresse du
        destinataire de la prestation.
      </p>

      <h4>19. Droit applicable et lieu de juridiction</h4>
      <p>19.1 Le droit suisse est applicable à la relation contractuelle.</p>
      <p>
        19.2 Il est renoncé aux dispositions de la Convention de Vienne sur les
        ventes (Convention des Nations Unies sur les contrats de vente
        internationale de marchandises, conclue à Vienne le 11.4.1980).
      </p>
      <p>
        19.3 Le lieu de juridiction est le siège social d&rsquo;ASI, sauf
        stipulation contraire dans le contrat.
      </p>
      <p>
        Dans des cas justifiés, il peut être dérogé (dans des cas particuliers)
        aux dispositions ci-dessus du chapitre C des conditions générales.
      </p>

      <h2>D) Intégrité de la conduite et lutte contre la corruption </h2>
      <p>
        Dans le cadre de la conclusion du contrat, le contractant désigné est
        soumis à un contrôle régulier de l&rsquo;intégrité du vendeur
        (&quot;Vendor Integrity Screening, VIS&quot;), qui constitue la base
        essentielle du présent contrat. Conformément aux instructions
        d&rsquo;ASI, le contractant se conforme dûment à une enquête numérique
        du VIS.
      </p>
      <p>
        Les parties s&rsquo;engagent à ne pas violer les lois applicables en
        matière de corruption. Cela s&rsquo;applique en particulier aux
        paiements illicites à des fonctionnaires ou à des représentants des
        autorités publiques et aux cadeaux inappropriés faits à des
        fonctionnaires ou à des tiers/sous-traitants ou de la part de ces
        derniers à l&rsquo;une des parties.
      </p>
      <h2>E) Confidentialité et protection des données</h2>
      <p>
        Le commissaire doit garder secrètes toutes les informations
        confidentielles du client et le contenu de l&rsquo;accord pendant sa
        durée et [trois] ans par la suite. Elle s&rsquo;engage à respecter à
        tout moment les lois applicables en matière de protection des données.
        <br />
        En cas de perte de données ou d&rsquo;informations confidentielles, le
        client doit être immédiatement informé à l&rsquo;adresse{' '}
        <a href="mailto:data-privacy@allianz.ch">data-privacy@allianz.ch</a> et
        une coopération doit être recherchée dans la limitation des dommages
        conformément aux instructions du client. L&rsquo;agent est responsable
        de tous les dommages à cet égard.
      </p>
    </div>
  </Card>
);

export default TermsAndConditions;
