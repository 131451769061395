import React, { Component, Fragment } from 'react';
import { withRouter, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import { SimplePopup } from '@bonlineza/b-lib';
import { setListItem } from 'components/ConnectedActionBar/actions';
import l from 'helpers/locale';
import FormComponent from 'components/Form';
import UntilReady from 'components/UntilReady';
import JobConstants from 'shared/jobs/Panels/constants';
import formatErrors from 'helpers/formatErrors';
import axios from 'helpers/axios';
import defaultRequest from 'constants/request';
import type { RequestShape } from 'constants/request';
import graphi from 'helpers/graphi';

import formFields from './helpers/offerFormFields.js';
import formValidation from './helpers/offerFormValidation.js';

import OfferFormFooter from './components/offerFormFooter.js';
import ContactPanel from './components/contactPanel.js';

import OfferContext from './context';
import offerQuery from './query';
import { resetOfferState, setOffers } from './actions/index.js';

const { grant } = JobConstants;

type ReduxPropsShape = {
  acceptSuccess: Function,
};

type PropsShape = {
  onViewData?: Function,
  closeForm: Function,
  role: number | string,
  documents: Object[],
} & ReduxPropsShape;

type StateShape = {
  errors: {
    [string]: [string],
  },
  requests: {
    submit: RequestShape,
    get: RequestShape,
  },
  payload: Object,
  showPop: string | null,
  currentOfferId: string | number,
};

class OfferForm extends Component<PropsShape, StateShape> {
  static defaultProps = {
    onViewData: () => null,
  };

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      fields: [],
      errors: {},
      requests: {
        submit: {
          ...defaultRequest,
        },
        get: {
          ...defaultRequest,
        },
        accept: {
          ...defaultRequest,
        },
      },
      payload: {},
      // eslint-disable-next-line react/no-unused-state
      state_machine: {},
      formViewOnly: false,
      // eslint-disable-next-line react/no-unused-state
      visibleAcceptOfferModal: false,
      // eslint-disable-next-line react/no-unused-state
      visibleDeclineOfferModal: false,
      // eslint-disable-next-line react/no-unused-state
      visibleSubmit: false,
      // eslint-disable-next-line react/no-unused-state
      visibleDelete: false,
    };
    this.currentOfferId = null;
    this.errorFormatting = [
      {
        field: 'file_id',
        condition: 'required',
        message: 'VALIDATION-required',
      },
      {
        field: 'payment_conditions',
        condition: 'present',
        message: 'VALIDATION-required',
      },
      {
        field: 'title',
        condition: 'required',
        message: 'VALIDATION-required',
      },
      {
        field: 'total',
        condition: 'required',
        message: 'VALIDATION-required',
      },
    ];
  }

  componentDidMount() {
    if (this.props.match.params.offer_id) {
      this.currentOfferId = this.props.match.params.offer_id;
      this.getData(this.currentOfferId);
    } else {
      this.setInitialMode('edit');
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.offer_id) {
      if (
        this.props.match.params.offer_id !== prevProps.match.params.offer_id
      ) {
        this.getData(this.props.match.params.offer_id);
      }
    }
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  getFields = (payload: Object, viewOnly: boolean): Array<Object> =>
    formFields(
      {
        ...payload,
        jobId: this.props.match.params.job_id,
        ...(this.props.match.params.offer_id !== 'create'
          ? {
              offerId: this.props.match.params.offer_id,
            }
          : {}),
      },
      viewOnly,
    );

  getData = (offerId: string | number) => {
    this.setRequestState('get', 'fetching', true);
    graphi(offerQuery(offerId, this.props.match.params.job_id), 'getOffer')
      .then((res: Object): any => this.handleDataSuccess(res.data.data))
      .catch((err: Object): any => this.handleDataError(err));
  };

  setInitialMode = (type: 'edit' | 'view', e?: any): any => {
    if (e) e.preventDefault();
    if (
      type === 'view' &&
      matchPath(this.props.location.pathname, {
        path: '/jobs/:job_id/offer',
        exact: true,
      })
    ) {
      this.props.closeForm();
    } else {
      this.props.scrollToTop();
      this.setState((prevState: StateShape): StateShape => ({
        ...prevState,
        formViewOnly: type === 'view',
        fields: this.getFields(prevState.payload, type === 'view'),
        requests: {
          ...prevState.requests,
          get: {
            ...defaultRequest,
            success: true,
          },
        },
      }));
      if (type === 'view') {
        this.getData(this.props.match.params.offer_id);
      }
    }
    return true;
  };

  setRequestState = (key: string, name: string, value: boolean): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      requests: {
        ...prevState.requests,
        [key]: {
          ...defaultRequest,
          [name]: value,
        },
      },
    }));

  runValidation = (payload: Object): Object => formValidation(payload);

  submitForm = (creation: boolean, payload: Array<Object>): any => {
    this.setRequestState('submit', 'fetching', true);

    const endpoint = creation
      ? `/api/1/jobs/${this.props.match.params.job_id}/offers/create`
      : `/api/1/jobs/${this.props.match.params.job_id}/offers/${this.props.match.params.offer_id}/action/update`;
    const data = {
      ...payload,
      file_id: this.props.documents.reduce(
        (pV: any, cV: any) => cV.document_id,
        null,
      ),
      file: null,
    };
    // fix incl_vat being an annoying string
    data.incl_vat = data.incl_vat === 'true' || data.incl_vat === true;
    return axios
      .post(endpoint, data)
      .then((res: Object): any => this.handleSubmitSuccess(res.data, creation))
      .catch((err: Object): any => this.handleRequestError(err, 'submit'));
  };

  handleDataError = (error: Object): any => {
    this.setRequestState('get', 'error', true);

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      errors: error,
    }));
  };

  handleDataSuccess = ({ offer }): any => {
    this.props.setOffers(offer.job.offers);
    this.props.onViewData(offer);
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      payload: offer,
      state_machine: offer.state_machine,
      fields: this.getFields(offer, true),
      formViewOnly: true,
      requests: {
        ...prevState.requests,
        get: {
          ...defaultRequest,
          success: true,
        },
      },
    }));
  };

  // save
  handleSubmitSuccess = (res: Object, creation: boolean) => {
    if (creation) {
      return this.props.history.push(
        `/jobs/${this.props.match.params.job_id}/offers/${res.payload.id}`,
      );
    }

    this.setRequestState('submit', 'succes', true);
    return this.getData(this.currentOfferId);
  };

  handleRequestError = (err: Object, requestType: 'submit' | 'delete'): any => {
    this.setRequestState(requestType, 'error', true);
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      errors: err.data && err.data.payload ? err.data.payload : {},
    }));
  };

  offerActionSubmit = () => this.toggleVisibility('Submit');

  offerActionSubmitConfirm = (): Promise<*> => {
    this.setRequestState('submit', 'fetching', true);
    return axios
      .post(
        `api/1/jobs/${this.props.match.params.job_id}/offers/${this.props.match.params.offer_id}/action/submit`,
      )
      .then((): any => {
        this.props.setNotification(l('ACTION_BAR-offer_variant_submitted'));
        this.getData(this.props.match.params.offer_id);
      })
      .catch((err: any): any => this.handleRequestError(err, 'submit'));
  };

  offerActionDelete = () => this.toggleVisibility('Delete');

  offerActionDeleteConfirm = (): Promise<*> => {
    this.setRequestState('delete', 'fetching', true);
    return axios
      .post(
        `api/1/jobs/${this.props.match.params.job_id}/offers/${this.props.match.params.offer_id}/action/delete`,
      )
      .then((): any => {
        this.props.onViewData({ id: null, title: null });
        if (Object.keys(this.props.match.params).includes('project_id')) {
          return this.props.history.push(
            `/projects/${this.props.match.params.project_id}/${this.props.match.params.job_id}`,
          );
        }
        return this.props.history.push(
          `/jobs/${this.props.match.params.job_id}`,
        );
      })
      .catch((err: any): any => this.handleRequestError(err, 'delete'));
  };

  offerActionAccept = (): any => this.toggleVisibility('AcceptOfferModal');

  offerActionAcceptSubmit = (payload: Object): any => {
    this.setRequestState('accept', 'fetching', true);
    return axios
      .post(
        `api/1/jobs/${this.props.match.params.job_id}/offers/${this.props.match.params.offer_id}/action/accept`,
        {
          ...payload,
        },
      )
      .then((res: Object): any => this.handleAcceptSuccess(res))
      .catch((err: any): any => this.handleAcceptError(err));
  };

  handleAcceptSuccess = (res: Object): any => {
    this.setRequestState('accept', 'succes', true);
    this.toggleVisibility('AcceptOfferModal');
    this.props.acceptSuccess(res);
    if (Object.keys(this.props.match.params).includes('project_id')) {
      return this.props.history.push(
        `/projects/${this.props.match.params.project_id}/${this.props.match.params.job_id}`,
      );
    }
    return this.props.history.push(`/jobs/${this.props.match.params.job_id}`);
  };

  handleAcceptError = (err: Object): any => {
    this.setRequestState('accept', 'error', true);
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      errors: err.data && err.data.payload ? err.data.payload : {},
    }));
  };

  offerActionDecline = (): any => this.toggleVisibility('DeclineOfferModal');

  offerActionDeclineConfirm = (): any => {
    this.setRequestState('decline', 'fetching', true);
    return axios
      .post(
        `api/1/jobs/${this.props.match.params.job_id}/offers/${this.props.match.params.offer_id}/action/reject`,
      )
      .then((res: Object): any => this.offerActionDeclineConfirmSuccess(res))
      .catch((err: any): any => this.offerActionDeclineConfirmError(err));
  };

  offerActionDeclineConfirmSuccess = (): any => {
    this.props.onViewData({
      id: this.state.payload.offer_id,
      title: this.state.payload.title,
    });
    this.setRequestState('decline', 'success', true);
    this.toggleVisibility('DeclineOfferModal');
    this.getData(this.state.payload.offer_id);
  };

  offerActionDeclineConfirmError = (): any => {
    this.setRequestState('decline', 'failed', true);
    this.toggleVisibility('DeclineOfferModal');
  };

  toggleVisibility = (
    target: 'AcceptOfferModal' | 'DeclineOfferModal' | 'Submit' | 'Delete',
    e?: Object,
  ): any => {
    if (e) {
      e.preventDefault();
    }
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      [`visible${target}`]: !prevState[`visible${target}`],
    }));
  };

  isCreate = () => !this.props.match.params.offer_id;

  render(): React$Element<*> {
    return (
      <Fragment>
        <ActionBar name="single_offer_actions" />
        <UntilReady
          ready={this.state.requests.get.success}
          waiting={this.state.requests.get.fetching}>
          <FormComponent
            submitAct={this.submitForm.bind(null, this.isCreate())}
            wrappingOuterClass=""
            validateBefore={this.runValidation}
            sections={this.state.fields}
            afterSubmitErrors={formatErrors(
              this.state.errors,
              this.errorFormatting,
            )}
            isProcessing={this.state.requests.submit.fetching}
            wrappingClass="gw"
            viewOnly={this.state.formViewOnly}
            cancelAct={this.setInitialMode.bind(null, 'view')}
            submitButtonLocaleKey="OFFER_FORM-save_offer"
            renderFooter={actions => (
              <OfferContext.Provider value={this.state}>
                <OfferFormFooter
                  machine={this.state.state_machine}
                  roleId={this.props.role}
                  changeEditState={this.setInitialMode}
                  close={this.props.closeForm}
                  updateOffer={this.submitForm.bind(null, this.isCreate())}
                  viewState={this.state.formViewOnly}
                  submitOffer={this.offerActionSubmit}
                  deleteOffer={this.offerActionDelete}
                  acceptOfferFn={this.offerActionAccept}
                  acceptOfferAction={this.offerActionAcceptSubmit}
                  declineOfferFn={this.offerActionDecline}
                  declineOfferAction={this.offerActionDeclineConfirm}
                  toggleVisibility={this.toggleVisibility}
                  formActions={actions}
                />
              </OfferContext.Provider>
            )}
          />

          <ContactPanel
            visible={!!this.state.payload.offer_id}
            details={
              this.state.payload.vmp_contractor
                ? [
                    this.state.payload.vmp_contractor.key_user_full_name,
                    this.state.payload.vmp_contractor.street,
                    this.state.payload.vmp_contractor.post_city,
                    this.state.payload.vmp_contractor.key_user_email,
                    this.state.payload.vmp_contractor.key_user_phone,
                  ]
                : []
            }
            contractorName={this.state.payload.participant || ''}
          />
          <SimplePopup
            isOpen={this.state.visibleSubmit}
            close={this.toggleVisibility.bind(null, 'Submit')}
            title={l('POPUP-submit-title')}
            description={l('POPUP-submit-body')}
            options={[
              {
                cb: () => {
                  this.toggleVisibility('Submit');
                },
                dataQeId: 'action-offer-submit-cancel',
                buttonText: l('cancel'),
                buttonClass: 'btn--text',
              },
              {
                cb: () => {
                  this.toggleVisibility('Submit');
                  this.offerActionSubmitConfirm();
                },
                dataQeId: 'action-offer-submit-confirm',
                buttonText: l('ACTION-SUBMIT-OFFER'),
                buttonClass: 'btn--primary',
              },
            ]}
          />
          <SimplePopup
            isOpen={this.state.visibleDelete}
            close={this.toggleVisibility.bind(null, 'Delete')}
            title={l('POPUP-delete-title')}
            description={l('POPUP-delete-body')}
            options={[
              {
                cb: () => {
                  this.toggleVisibility('Delete');
                },
                dataQeId: 'action-offer-trash-cancel',
                buttonText: l('cancel'),
                buttonClass: 'btn--text',
              },
              {
                cb: () => {
                  this.toggleVisibility('Delete');
                  this.offerActionDeleteConfirm();
                },
                dataQeId: 'action-offer-trash-confirm',
                buttonText: l('ACTION-delete'),
                buttonClass: 'btn--inverse--secondary',
              },
            ]}
          />
        </UntilReady>
      </Fragment>
    );
  }
}

const mapActions = (
  dispatch: Function,
  { jobId }: { jobId: string | number },
): ReduxPropsShape => ({
  acceptSuccess(res: Object): Function {
    return dispatch({
      type: grant.success,
      jobId,
      payload: (res && res.data) || undefined,
    });
  },
  setNotification(message): any {
    return dispatch(setListItem('single_offer_actions', message));
  },
  setOffers(offers): any {
    return setOffers(offers, dispatch);
  },
  resetState() {
    return resetOfferState(dispatch);
  },
});

const mapStateToProps = (state: Object): Object => ({
  role: state.authStore.user.role,
  documents: state.docStore.create_offer.docs,
});

export default withRouter(connect(mapStateToProps, mapActions)(OfferForm));
