// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import { StateConditional } from 'machines/components/RenderConditional';
import GetSvg from 'components/GetSvg';
import FooterBar from 'components/FooterBar';
import { updateTitle } from 'components/RouteSlider/actions';
import FooterActions from './components/FooterActions';
import { updateJobConcern, updateJobContractor } from './actions';
import Cards from './cards';
import MachineBuilder from './machine';

export const JobMachine = MachineBuilder();

const {
  ObjectCard,
  ContractorCard,
  InformationCard,
  ParticipantsCard,
  AgreementCard,
  OfferListCard,
  AccountCard,
  ContactCard,
  NotificationCard,
  InternalDocsCard,
  TenderDocumentsCard,
  DocumentExchangeCard,
  ProjectTeamDocsCard,
  BudgetCard,
  InvoiceProcessCard,
  RatingCard,
  PaymentListCard,
} = Cards;

type ReduxActionsShape = {
  setTitle: string => any,
  updateConcern: (string, number) => any,
  updateContractor: number => any,
};

type JobProps = {
  location: {
    pathname: string,
  },
  match: {
    // eslint-disable-next-line react/no-unused-prop-types
    params: {
      job_id: string | void,
    },
  },
  // eslint-disable-next-line react/no-unused-prop-types
  project_id: string | void,
  // eslint-disable-next-line react/no-unused-prop-types
  concern_id: number | void,
  // eslint-disable-next-line react/no-unused-prop-types
  concern_type: string | void,
  // eslint-disable-next-line react/no-unused-prop-types
  routeName: string, // only used in mapActions
  // eslint-disable-next-line react/no-unused-prop-types
  backAction: Function | void,
} & ReduxActionsShape;

export function useGetTitleFromProps(jobId, trade) {
  const [parts, setParts] = React.useState(['Job']);
  React.useEffect(() => {
    if (jobId) {
      setParts(['Job', jobId]);

      if (trade) {
        setParts(current => [trade, ...current]);
      }
    }
  }, [jobId, trade, setParts]);

  return parts.join(' ');
}

function getMetaJobData(route, props) {
  return {
    job_id: route.job_id,
    concern_id: route.type_id || props.concern_id,
    concern_type: route.type || props.concern_type,
    contractor_id: route.contractor_id || props.contractor_id,
    project_id: route.project_id || props.project_id,
  };
}

function Job(props: JobProps) {
  // --> Get all relevant data to fetch job information
  const {
    job_id,
    concern_id,
    concern_type,
    contractor_id,
    project_id,
  } = getMetaJobData(props.match.params, props);

  React.useLayoutEffect(() => {
    if (concern_id || job_id || project_id) {
      JobMachine.start({
        job_id,
        project_id,
        concern_id,
        concern_type,
        contractor_id,
      });
    }
  }, [job_id, concern_id, concern_type, contractor_id, project_id]);
  // --> Done fetching job data

  const pageTitle = useGetTitleFromProps(
    job_id,
    (props.jobId === job_id && props.trade) || null,
  );
  // --> calculate and update data

  const { setTitle } = props;

  React.useEffect(() => {
    setTitle(pageTitle);
  }, [pageTitle, setTitle]);
  // --> Done calculating data

  function onConcernChange(nextConcern) {
    props.updateConcern(nextConcern.concern_type, nextConcern.concern_id);
  }

  function onContractorChange(nextContractor) {
    props.updateContractor(nextContractor.contractor_id);
  }

  return (
    <Fragment>
      <ActionBar name="single_job_actions" />
      <StateConditional
        whenState={['INIT', 'LOADING']}
        state={props.machine_state}>
        <div className="align--center">
          <GetSvg svg="loading" wrapperClass="loading-spinner--large" />
        </div>
      </StateConditional>
      <StateConditional
        state={props.machine_state}
        whenState={['VIEW', 'EDIT', 'UPDATING', 'UPDATE_ERROR']}>
        <Fragment>
          <div className="gw">
            <div className="g--tall g-2/3">
              {/* @todo object card should be state machine to handle change */}
              <ObjectCard getNewInformation={onConcernChange} />
            </div>
            <div className="g--tall g-1/3">
              {/* @todo hw card should be state machine to handle change */}
              <ContractorCard getNewInformation={onContractorChange} />
            </div>
          </div>
          <div className="gw">
            <div className="g--tall g-1/1">
              <InformationCard isJobOffer={props.jobType === 'offer'} />
            </div>
            <div className="g--tall g-1/1">
              <ParticipantsCard />
            </div>
            <div className="g--tall g-1/1">
              <AgreementCard />
            </div>
            <div className="g--tall g-1/1">
              <PaymentListCard />
            </div>
            <div className="g--tall g-1/1">
              <OfferListCard
                enableDetailView
                apiEndpoint={`/api/1/view/jobs/${props.jobId}/offer`}
                listName="single_job_offers"
              />
            </div>
            <div className="g--tall g-1/1">
              <AccountCard />
            </div>
            <div className="g--tall g-1/1">
              <ContactCard />
            </div>
            <div className="g--tall g-1/1">
              <NotificationCard />
            </div>
            <div className="g--tall g-1/1">
              <BudgetCard />
            </div>
            <div className="g--tall g-1/1">
              <ProjectTeamDocsCard />
            </div>
            <div className="g--tall g-1/1">
              <TenderDocumentsCard />
            </div>
            <div className="g--tall g-1/1">
              <InternalDocsCard />
            </div>
            <div className="g--tall g-1/1">
              <DocumentExchangeCard />
            </div>
            <div className="g--tall g-1/1">
              <InvoiceProcessCard />
            </div>
            <div className="g--tall g-1/1">
              <RatingCard />
            </div>
          </div>
        </Fragment>
      </StateConditional>
      <FooterBar>
        <FooterActions backAction={props.backAction} />
      </FooterBar>
    </Fragment>
  );
}

type ReduxStateShape = {
  machine_state: string,
  lastRefresh: any,
  jobId: string | number | void,
  trade: string,
  jobType: 'offer' | 'direct',
};

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.job,
  lastRefresh: jobStore.lastRefresh,
  jobId: jobStore.data.job?.job_id,
  trade: jobStore.data.job?.trade?.value,
  jobType: jobStore.data.job?.type,
});

const mapActions = (
  dispatch: Function,
  props: JobProps,
): ReduxActionsShape => ({
  updateConcern(type: string, id: number): any {
    return dispatch(updateJobConcern(type, id));
  },
  updateContractor(id: number): any {
    return dispatch(updateJobContractor(id));
  },

  setTitle(title) {
    dispatch(updateTitle(props.routeName, title));
  },
});

export default withRouter(connect(mapState, mapActions)(Job));
