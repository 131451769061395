import React, { Fragment } from 'react';
import numeral from 'numeral';
import formatCurrency from 'functions/formatCurrency';
import store from '../store';

// PROJECT - keep this in new file - currencyFormatter.js
// 1. register custom locale
// Register a locale for Swiss Franc
numeral.register('locale', 'de-ch', {
  delimiters: {
    thousands: "'", // added upside down comma
    decimal: '.',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: (): string => '.',
  currency: {
    symbol: 'CHF',
  },
});

// 2. set current locale (once)
numeral.locale('de-ch');

// 3. export custom currency formatter function
const CURRENCY_FORMAT = {
  WITH_DECIMAL: '$ 0,0.00',
  OPTIONAL_DECIMAL: '$ 0,0[.]00',
};

export const AllianzCurrencyFormatter = (
  number: number | string,
  round: boolean = true,
  forceDecimals = false,
): string => {
  return number && number !== '-' && number !== '0'
    ? formatCurrency(
        number,
        round,
        forceDecimals
          ? CURRENCY_FORMAT.WITH_DECIMAL
          : CURRENCY_FORMAT.OPTIONAL_DECIMAL,
      )
    : 'CHF -';
};

export const fC = AllianzCurrencyFormatter;

// TODO:  UNRAVEL ...
//
export function getLocale(): string {
  if (typeof window === 'undefined') {
    return '';
  }

  return store
    ? store.getState().appStore.locale
    : window.localStorage.getItem('_sl');
}

export default function handleLocale(localeKey: string): string {
  String.locale = getLocale();
  if (!String.locale) {
    // localisation is not ready
    return localeKey;
  }

  if (localeKey) {
    try {
      return localeKey.toLocaleString() || localeKey;
    } catch (e) {
      return localeKey;
    }
  }
  return null;
}

export function getLocaleAsElement(localeKey: string): React$Element<*> {
  const translated = handleLocale(localeKey);

  if (!translated.includes(':link')) {
    return <Fragment>{translated}</Fragment>;
  }

  const translatedArray = translated.split(':link').reduce((acc, curr, i) => {
    if (
      curr.match(/\(([^)]+)\)/) === null ||
      curr.match(/\[([^)]+)\]/) === null
    ) {
      return [...acc, <Fragment key={i}>{curr}</Fragment>];
    }

    const anchorText = curr.match(/\(([^)]+)\)/)[1];
    const anchorHref = curr.match(/\[([^)]+)\]/)[1];
    const textAfterAnchor = curr.match(/(\[([^)]+)\])(.+)/)[3];

    return [
      ...acc,
      <Fragment key={i}>
        <a href={anchorHref} target="_blank" rel="noopener noreferrer">
          {anchorText}
        </a>
        {textAfterAnchor}
      </Fragment>,
    ];
  }, []);

  return <Fragment>{translatedArray}</Fragment>;
}
