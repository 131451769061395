import React, { useState } from 'react';
import { AllianzSimpleItem } from 'components/ConnectedSimpleList';
import GroupTitleButton from 'components/ListGroup/GroupTitleButton';
import ListGroup from 'components/ListGroup/ListGroup';
import ListGroupBody from 'components/ListGroup/ListGroupBody';
import ListGroupHeader from 'components/ListGroup/ListGroupHeader';

type PropsShape = {
  groupRows: Object[],
  headings: Object[],
  canCollapse?: boolean,
  title: string,
  removedIDs: Array,
};

const getFlexStyle = (baseLength = 1, columnCount = 0) => ({
  flexBasis: `${baseLength / columnCount}%`,
});

const OfferListGroup = ({
  groupRows,
  headings,
  canCollapse,
  title,
  removedIDs,
}: PropsShape) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const columnCount = headings.length;
  return (
    <ListGroup>
      <ListGroupHeader>
        <div
          style={getFlexStyle(3, columnCount)}
          className="simple-list__body__row__item">
          {canCollapse ? (
            <GroupTitleButton title={title} isOpen={isOpen} toggle={toggle} />
          ) : null}
        </div>
      </ListGroupHeader>
      <ListGroupBody isOpen={isOpen}>
        {groupRows.map((item, itemKey) => {
          const rowClass = removedIDs.includes(item.id)
            ? 'simple-list__body__group__row--removed'
            : 'simple-list__body__group__row--disable-hover';
          return (
            <div key={itemKey} className={rowClass}>
              {headings.map((col: Object, index: number) => (
                <AllianzSimpleItem
                  key={index}
                  row={item}
                  text={item[col.name]}
                  flex={col.flex}
                  align={col.align}
                  column={col.name}
                  itemClass="simple-list__body__row__item"
                  customFormatter={col.customFormatter}
                  customRenderer={col.customRenderer}
                />
              ))}
            </div>
          );
        })}
      </ListGroupBody>
    </ListGroup>
  );
};
OfferListGroup.defaultProps = {
  canCollapse: true,
};
export default OfferListGroup;
