import React from 'react';
import SimpleList from 'components/ConnectedSimpleList';
import l from 'helpers/locale';
import GetSvg from 'components/GetSvg';

type DataShape = {
  house_id: string,
  property_id: string,
  type_lang: string,
  street: string,
  post_city: string,
  tenants: Array<string>,
  house_reference: string,
  property_reference: string,
};

const HouseList = ({ nextAct }: { nextAct: Function }): React$Element<*> => {
  const columnOrder = [
    'type_lang',
    'street',
    'post_city',
    'tenants',
    'id_list',
  ];

  const flexStyle = {
    flexBasis: `${(1 / columnOrder.length) * 100}%`,
  };

  return (
    <div>
      <SimpleList
        api="/api/1/view/jobs/house"
        bgcAlt
        name="house"
        headings={[
          {
            name: 'type_lang',
            sortable: true,
            text: l('HOUSE_LIST-type_lang'),
          },
          {
            name: 'street',
            sortable: true,
            text: l('HOUSE_LIST-street'),
          },
          {
            name: 'post_city',
            sortable: true,
            text: l('HOUSE_LIST-post_city'),
          },
          {
            name: 'tenant_name',
            sortable: false,
            text: l('HOUSE_LIST-tenants'),
          },
          {
            name: 'id_list',
            sortable: true,
            text: l('HOUSE_LIST-id_list'),
          },
        ]}
        filterIsCentered>
        {row =>
          row.map((item: DataShape, key: number): React$Element<*> => (
            <div
              key={key}
              id={item.asset_id}
              className="custom-list__body__row">
              <div className="custom-list__body__row__item" style={flexStyle}>
                {item.type_lang}
              </div>
              <div className="custom-list__body__row__item" style={flexStyle}>
                {item.street}
              </div>
              <div className="custom-list__body__row__item" style={flexStyle}>
                {item.post_city}
              </div>
              <div className="custom-list__body__row__item" style={flexStyle}>
                {item.tenant_name ? (
                  item.tenant_name.map((v: string, k: number): React$Element<
                    *,
                  > => (
                    <div key={k}>
                      {v}
                      <br />
                    </div>
                  ))
                ) : (
                  <div />
                )}
              </div>
              <div className="custom-list__body__row__item" style={flexStyle}>
                <div className="align--right">
                  <div className="tiny-group-buttons">
                    <div className="tiny-group-buttons__item">
                      <span className="tiny-group-buttons__item__header">
                        {item.property_reference}
                        &nbsp;&nbsp;.
                      </span>
                      <button
                        type="button"
                        onClick={function(): boolean {
                          nextAct(item.property_id, 'property');
                          return true;
                        }}
                        className="btn--tooltip"
                        data-qe-id={`houses-table-${key}-property-btn`}>
                        <GetSvg
                          svg="building"
                          wrapperClass="tiny-group-buttons__icon"
                        />
                        <span className="btn__tooltip">
                          {l('PROPERTY-singular')}
                        </span>
                      </button>
                    </div>
                    <div className="tiny-group-buttons__item">
                      <span className="tiny-group-buttons__item__header">
                        &nbsp;&nbsp;
                        {item.house_reference}
                      </span>
                      <button
                        type="button"
                        onClick={function(): boolean {
                          nextAct(item.house_id, 'house');
                          return true;
                        }}
                        className="btn--tooltip"
                        data-qe-id={`houses-table-${key}-house-btn`}>
                        <GetSvg
                          svg="house"
                          wrapperClass="tiny-group-buttons__icon"
                        />
                        <span className="btn__tooltip">
                          {l('HOUSE-singular')}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </SimpleList>
    </div>
  );
};

export default HouseList;
