/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { StateConditional } from 'machines/components/RenderConditional';
import DocConstants from 'components/connected/DocumentUploader/constants';
import type { DocumentType } from 'views/singleJob/constants';
import TenderDocs from 'shared/jobs/Panels/items/TenderDocuments';
import { removeJobDocument } from '../../actions';
import MachineBuilder from './machine';

const { DELETE, VIEW, DOWNLOAD } = DocConstants.DOCUMENT_ACTION;

type ReduxActionShape = {
  removeImage: Function,
};

type ReduxStateShape = {
  status: string,
  documentList?: [DocumentType],
  footer?: any,
};

export const TenderDocumentsCardMachine = MachineBuilder();

const TenderDocumentsCard = ({
  status,
  match,
  documentList,
  removeImage,
  footer = null,
  machine_state,
}) => (
  <StateConditional state={machine_state} whenState={['VISIBLE', 'EDITING']}>
    <TenderDocs
      listName="single_job_tender"
      data={{
        uploadProps: {
          source_id: match.params.job_id,
          source_type: 'job',
          source_tag: 'tender',
        },
        endpoint: '/api/1/documents/action/add',
      }}
      jobState={status}
      list={documentList}
      controls={[
        {
          documentAction: DELETE,
          svgName: 'close',
          action: removeImage,
          canDisplay: true,
        },
        {
          documentAction: VIEW,
          svgName: 'view',
          canDisplay: true,
        },
        {
          documentAction: DOWNLOAD,
          svgName: 'download',
          canDisplay: true,
        },
      ]}
      footer={footer}
    />
  </StateConditional>
);

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.tenderdocuments_card,
  panelEditMode: machineStore.tenderdocuments_card === 'EDITING',

  status: jobStore.data.job.info_status,
  documentList:
    jobStore.data.job.documents.filter(doc => doc.source_tag === 'tender') ||
    [],
});

const mapActions = (
  dispatch: Function,
  { match }: { match: Object },
): ReduxActionShape => ({
  removeImage(id: string | number, api: string): any {
    return removeJobDocument(id, 'tender', match.params.job_id, dispatch, api);
  },
});

export default withRouter(connect(mapState, mapActions)(TenderDocumentsCard));
