/** @format */

// @flow

import React from 'react';
import GenericDocumentsPanel from 'shared/jobs/Panels/items/GenericDocuments';
import type { DocumentType } from 'views/singleJob/constants';

type PropsShape = {
  isEditing?: boolean,
  data: Object,
  listName: string,
  list?: [DocumentType],
  controls: Array<*>,
};

const InternalDocsPanelWrapper = ({
  isEditing,
  data,
  list,
  listName,
  controls,
}: PropsShape) => (
  <GenericDocumentsPanel
    isEditing={isEditing}
    data={data}
    listName={listName}
    list={list}
    controls={controls}
    title="INTERNAL_DOCS-title"
    identifier="action-drop-internal_document"
    footer="DOCUMENTS-for_internal_use"
  />
);

InternalDocsPanelWrapper.defaultProps = {
  isEditing: false,
  list: [],
};
export default InternalDocsPanelWrapper;
