// @flow
import StateMachine from 'machines/reduxMachine.js';
import { transitions } from 'machines/constants.js';
import { State, Transition, Edge as EdgeBuilder } from 'async-state-machine';
import JobConditions from '../../conditions';

const { hasPayments } = JobConditions;

// Transitions for single job

export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) => hasPayments(params));

function PaymentListCardMachine() {
  const Edge = new EdgeBuilder();

  // States for Single Job
  const HIDDEN = Object.create(State).setName('HIDDEN');
  const VISIBLE = Object.create(State).setName('VISIBLE');

  // Register Machine
  const Machine = new StateMachine('paymentlist_machine')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN])
        .to(VISIBLE),
    )
    .setInitialState(HIDDEN, {}, true);

  return Machine;
}

export default PaymentListCardMachine;
