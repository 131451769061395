/** @format */

import React from 'react';

import Card from 'components/Card';
import l from 'helpers/locale';

const AgencyPanel = ({ agency }) => (
  <Card title="AGENCY-title" isOpen canCollapse={false}>
    <div>
      <span className="form__label--small">{l('AGENCY-title')}</span>
      <p className="text--dk--flushed">{agency}</p>
    </div>
  </Card>
);

export default AgencyPanel;
