import React from 'react';
import { withRouter } from 'react-router-dom';
import formatString from 'functions/formatString';
import GroupTitleButton from 'components/ListGroup/GroupTitleButton';
import { fC } from 'helpers/locale';
import ContractorColumnFormatter from './ContractorColumnFormatter';

type PropsShape = {
  canCollapse?: boolean,
  groupRows: Object[],
  title: string,
  estimateTotal: string | number,
  offerTotal: string | number,
  status: any,
  headings: Object[],
};

type StateShape = {
  open: boolean,
};

class JobListGroup extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    canCollapse: true,
    isOpen: true,
  };

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      open: props.isOpen,
    };
  }

  getFlexStyle = (baseLength = 1, lessPercent = 0) => ({
    flexBasis: `${(baseLength / this.props.headings.length) *
      (100 - lessPercent)}%`,
  });

  toggle = (): boolean => {
    this.setState((prevState: StateShape): StateShape => ({
      open: !prevState.open,
    }));
    return true;
  };

  render() {
    const { getFlexStyle, toggle } = this;
    const {
      canCollapse,
      title,
      estimateTotal,
      offerTotal,
      status,
      location,
      history,
    } = this.props;
    return (
      <div className="simple-list__body__group">
        <div className="simple-list__body__group__header">
          <div style={getFlexStyle(3)} className="simple-list__body__row__item">
            {canCollapse ? (
              <GroupTitleButton
                title={title}
                toggle={toggle}
                isOpen={this.state.open}
              />
            ) : null}
          </div>
          <div style={getFlexStyle(1)} className="simple-list__body__row__item">
            {fC(estimateTotal)}
          </div>
          <div style={getFlexStyle(1)} className="simple-list__body__row__item">
            {fC(offerTotal)}
          </div>
          <div style={getFlexStyle(1)} className="simple-list__body__row__item">
            {fC(status)}
          </div>
        </div>
        <div
          className={
            this.state.open
              ? 'simple-list__body__group__list'
              : 'simple-list__body__group__list--closed'
          }>
          {this.props.groupRows.map((item, itemKey) => (
            <button
              type="button"
              key={itemKey}
              className="simple-list__body__group__row"
              onClick={() =>
                history.push(`${location.pathname}/${item.job_id_raw}`)
              }>
              <div
                style={getFlexStyle(1)}
                className="simple-list__body__row__item">
                {formatString(`${item.job_id}`)}
              </div>
              <div
                style={getFlexStyle(1)}
                className="simple-list__body__row__item">
                <ContractorColumnFormatter data={item.contractor} />
              </div>
              <div
                style={getFlexStyle(1)}
                className="simple-list__body__row__item">
                {item.description}
              </div>
              <div
                style={getFlexStyle(1)}
                className="simple-list__body__row__item">
                {fC(item.job_estimate)}
              </div>
              <div
                style={getFlexStyle(1)}
                className="simple-list__body__row__item">
                {fC(item.offer_total)}
              </div>
              <div
                style={getFlexStyle(1)}
                className="simple-list__body__row__item">
                {item.status.map((v: string, k: number): React$Element<*> => (
                  <div key={k}>
                    {formatString(v)}
                    <br />
                  </div>
                ))}
              </div>
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(JobListGroup);
