/** @format */

import React from 'react';

import LinkContractor from './linkContractor.js';

const LinkContractorWrapper = ({ visible, ...rest }) => (
  <div className="wrap--small">
    {visible ? <LinkContractor {...rest} /> : null}
  </div>
);

export default LinkContractorWrapper;
