import React from 'react';
import Card from 'components/Card';
import l from 'helpers/locale';
import ContactBlock from 'components/GraphQLContactBlock';

const ContactsCard = ({ contacts }) => (
  <div>
    <Card title="JOB-contacts-title">
      <div className="gw">
        {contacts.map((v, k) => (
          <div className="g g-1/4" key={k}>
            <div className="push--small--bottom">
              <ContactBlock contact={v} label={l(`CONTACT_LIST-${v.type}`)} />
            </div>
          </div>
        ))}
      </div>
    </Card>
  </div>
);

export default ContactsCard;
