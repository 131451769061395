/** @format */

// @flow

const names = {
  ADMINISTRATION: 'adm',
  CENTRAL_PROCUREMENT: 'zek',
  PROPERTY_MANAGER: 'pm',
  AGENCY_MANAGER: 'lga',
  CONTRACTOR: 'hw',
  CONSTRUCTION_MANAGER: 'cm',
  HEAD_CONSTRUCTION_MANAGER: 'hcm',
  ACCOUNT_MANAGER: 'acc',
};

export const ids = {
  ADMINISTRATION: 1,
  CENTRAL_PROCUREMENT: 2,
  PROPERTY_MANAGER: 3,
  AGENCY_MANAGER: 4,
  CONTRACTOR: 5,
  CONSTRUCTION_MANAGER: 6,
  HEAD_CONSTRUCTION_MANAGER: 7,
  ACCOUNT_MANAGER: 8,
};

export function getRoleIdFromShortName(roleShort: string): number {
  switch (roleShort) {
    case names.PROPERTY_MANAGER:
      return ids.PROPERTY_MANAGER;
    case names.ADMINISTRATION:
      return ids.ADMINISTRATION;
    case names.CONTRACTOR:
      return ids.CONTRACTOR;
    case names.CENTRAL_PROCUREMENT:
      return ids.CENTRAL_PROCUREMENT;
    case names.AGENCY_MANAGER:
      return ids.AGENCY_MANAGER;
    case names.CONSTRUCTION_MANAGER:
      return ids.CONSTRUCTION_MANAGER;
    case names.HEAD_CONSTRUCTION_MANAGER:
      return ids.HEAD_CONSTRUCTION_MANAGER;
    case names.ACCOUNT_MANAGER:
      return ids.ACCOUNT_MANAGER;
    default:
      return 0;
  }
}

export function getRoleNameFromId(roleId: string | number): string {
  switch (roleId) {
    case ids.PROPERTY_MANAGER:
      return names.PROPERTY_MANAGER;
    case ids.ADMINISTRATION:
      return names.ADMINISTRATION;
    case ids.CONTRACTOR:
      return names.CONTRACTOR;
    case ids.CENTRAL_PROCUREMENT:
      return names.CENTRAL_PROCUREMENT;
    case ids.AGENCY_MANAGER:
      return names.AGENCY_MANAGER;
    case ids.CONSTRUCTION_MANAGER:
      return names.CONSTRUCTION_MANAGER;
    case ids.HEAD_CONSTRUCTION_MANAGER:
      return names.HEAD_CONSTRUCTION_MANAGER;
    case ids.ACCOUNT_MANAGER:
      return names.ACCOUNT_MANAGER;
    default:
      return '';
  }
}

export type RoleTypes =
  | names.ADMINISTRATION
  | names.CENTRAL_PROCUREMENT
  | names.PROPERTY_MANAGER
  | names.AGENCY_MANAGER
  | names.CONTRACTOR
  | names.CONSTRUCTION_MANAGER
  | names.HEAD_CONSTRUCTION_MANAGER
  | names.ACCOUNT_MANAGER
  | '';

export default {
  RoleName: names,
  RoleIds: ids,
};
