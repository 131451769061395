import AgencyPanel from './AgencyPanel.js';
import ContactPersonPanel from './ContactPersonPanel.js';
import GeneralPanel from './GeneralPanel.js';
import InvitationPanel from './InvitationPanel.js';
import NominationPanel from './NominationPanel.js';
import BillingPanel from './BillingPanel.js';
import BankPanel from './BankPanel.js';

export default {
  AgencyPanel,
  ContactPersonPanel,
  GeneralPanel,
  InvitationPanel,
  NominationPanel,
  BillingPanel,
  BankPanel,
};
