import React from 'react';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import replaceSubKeys from 'functions/replaceSubKeys';
import AcceptOffersModal from 'components/GrantModal/AcceptOfferModal';
import ActionRules from '../helpers/actionRules';
import OfferContext from '../context';

type PropsShape = {
  acceptOfferAction: Function,
  toggleVisibility: Function,
  userRole: number,
};

const AcceptOffer = ({
  acceptOfferAction,
  toggleVisibility,
  userRole,
}: PropsShape): React$Element<*> => (
  <ActionRules view="accept_offer" role={userRole}>
    {() => (
      <OfferContext.Consumer>
        {offer => {
          if (offer.payload && offer.payload.job) {
            const {
              vmp_contractor,
              job: { trade, vmp_subscribers, job_id },
            } = offer.payload;

            return (
              offer.payload.vmp_contractor &&
              offer.payload.job && (
                <AcceptOffersModal
                  jobId={job_id}
                  subTitle={replaceSubKeys(l('ACCEPT_OFFERS-description'), {
                    job_name: `${(trade && trade.value) || ''} Job ${job_id}`,
                    contractor_name: vmp_contractor.billing_company,
                  })}
                  isOpen={offer.visibleAcceptOfferModal}
                  selectedContractorId={vmp_contractor.contractor_id}
                  submitLoading={offer.requests.accept.fetching}
                  contractors={vmp_subscribers}
                  close={toggleVisibility.bind(null, 'AcceptOfferModal')}
                  callback={(payload: any): any => acceptOfferAction(payload)}
                />
              )
            );
          }
          return null;
        }}
      </OfferContext.Consumer>
    )}
  </ActionRules>
);

const mapStateToProps = state => ({
  userRole: state.authStore.user.role,
});

export default connect(mapStateToProps)(AcceptOffer);
