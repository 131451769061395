import React, { useContext } from 'react';
import { connect } from 'react-redux';
import PaymentList from 'shared/cards/PaymentList';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import { SliderContext } from 'components/RouteSlider';
import If from 'components/Conditions/If';
import MachineBuilder from './machine';

export const PaymentListCardMachine = MachineBuilder();

const PaymentListCard = ({ projectId, machine_state }) => {
  const { isActive } = useContext(SliderContext);
  return (
    <StateConditional
      state={machine_state}
      whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
      <If condition={isActive}>
        <PaymentList
          list_name="project_payments_list"
          api={`/api/1/view/projects/${projectId}/payments`}
        />
      </If>
    </StateConditional>
  );
};

const mapState = ({ machineStore }) => ({
  machine_state: machineStore.paymentlist_card,
});

export default connect(mapState)(PaymentListCard);
