import React from 'react';
import l from 'helpers/locale';
import { SimplePopup } from '@bonlineza/b-lib';

const InviteModal = ({ isOpen, onInviteConfirm, close, description }) => {
  return (
    <SimplePopup
      close={close}
      isOpen={isOpen}
      title={l('GRANT_JOB-INVITATION-title')}
      description={description}
      options={[
        {
          cb: close,
          buttonText: l('cancel'),
          buttonClass: 'btn--text',
          dataQeId: 'grant-modal-cancel',
        },
        {
          cb: onInviteConfirm,
          buttonText: l('ACTION-invite'),
          buttonClass: 'btn--primary',
          dataQeId: 'action-click-invite_before_grant',
        },
      ]}
    />
  );
};

export default InviteModal;
