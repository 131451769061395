/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import GeneralPanel from 'shared/projects/panels/items/General';
import { updateProjectFormData } from '../../../actions';

type ReduxStateShape = {
  occasion: string | number,
  occasions: [
    {
      key: number,
      value: string,
    },
  ],
  target_date: number,
};

type PropsShape = {} & ReduxStateShape;

const GeneralCard = ({
  occasion,
  occasions,
  target_date,
  onChange,
  updateFormField,
}: PropsShape): React$Element<*> => (
  <GeneralPanel
    data={{
      isCreating: true,
      status: 'draft',
      occasion,
      target_date,
      occasions,
    }}
    projectStatus="draft"
    isEditing
    onChange={onChange}
    updateField={updateFormField}
  />
);

// @todo - 'Create' type cards should always use the 'form' Store
// and 'View/Edit' would use either 'form' or 'data' from the Store, depending on their mode
const mapState = ({ projectStore }): ReduxStateShape => ({
  occasion: projectStore.form.occasion || '',
  occasions: projectStore.form.occasions || [],
  target_date: projectStore.form.target_date,
});

const mapActions = (dispatch: Function) => ({
  updateFormField(key: string, value: string): any {
    return dispatch(updateProjectFormData(key, value));
  },
});

export default connect(mapState, mapActions)(GeneralCard);
