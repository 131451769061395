/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import PaymentList from 'shared/cards/PaymentList';
import { StateConditional } from 'machines/components/RenderConditional';
import MachineBuilder from './machine';

export const PaymentListCardMachine = MachineBuilder();

const PaymentListCard = ({ machine_state, jobId }) => (
  <StateConditional state={machine_state} whenState={['VISIBLE']}>
    <PaymentList
      api={`/api/1/view/jobs/${jobId}/payments`}
      list_name="job_payments_list"
    />
  </StateConditional>
);

const mapState = ({ machineStore, jobStore }) => ({
  machine_state: machineStore.paymentlist_machine,
  jobId: jobStore.data.job.job_id,
});

export default connect(mapState)(PaymentListCard);
