/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { ids as RoleIds } from 'constants/roles';
import PropertyManager from './layouts/propertyManager';
import Contractor from './layouts/contractor';
import Admin from './layouts/admin';
import AgencyManager from './layouts/agencyManager';
import ConstructionManager from './layouts/constructionManager';
import HeadConstructionManager from './layouts/headConstructionManager';

const Dashboard = ({ role }) => {
  switch (role) {
    case RoleIds.ADMINISTRATION:
      return <Admin />;
    case RoleIds.CENTRAL_PROCUREMENT:
      return <Admin />;
    case RoleIds.PROPERTY_MANAGER:
      return <PropertyManager />;
    case RoleIds.AGENCY_MANAGER:
      return <AgencyManager />;
    case RoleIds.CONTRACTOR:
      return <Contractor />;
    case RoleIds.CONSTRUCTION_MANAGER:
      return <ConstructionManager />;
    case RoleIds.HEAD_CONSTRUCTION_MANAGER:
      return <HeadConstructionManager />;
    case RoleIds.ACCOUNT_MANAGER:
      return <Admin />;
    default:
      return <div>No Dashboard found for this Role</div>;
  }
};

const mapState = state => ({
  role: state.authStore.user.role,
});

export default connect(mapState)(Dashboard);
