import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'components/Card';
import SimpleList from 'components/ConnectedSimpleList';
import l from 'helpers/locale';
import groupBy from 'functions/groupBy.func';
import GetSvg from 'components/GetSvg';
import FooterBar from 'components/FooterBar';
import { SimpleButton } from 'components/FooterBar/buttons';
import ButtonGroup from 'components/ButtonGroup';
import getToken from 'functions/getToken';
import OfferListGroup from './OfferListGroup';
import { grantRequestExporting } from './actions';
import { LIST_NAME } from './constants';

type OfferDataShape = {
  cash_discount: string | number,
  contact: Object,
  contractor_id: string | number,
  created_at: string | number,
  description: string,
  discount: string | number,
  id: string | number,
  incl_vat: string | number,
  job_id: string | number,
  offer_status: string,
  participant: string,
  payment_conditions: string | number,
  status: string,
  submitted_at: string,
  title: string,
  total: string,
  user_id: string | number,
};

const GrantRequest = ({
  match: {
    params: { job_id },
  },
  backAction,
  onExport,
}) => {
  const [removedOfferIDs, setRemovedOfferIDs] = useState([]);
  const [selectedOfferID, setSelectedOfferID] = useState();
  const [formError, setFormError] = useState(false);
  const getHeadings = () => [
    {
      name: 'Select',
      text: l('OFFERS-COL-select'),
      flex: '10%',
      customRenderer: (_: any, item: OfferDataShape) => (
        <div>
          <input
            name="offer_id"
            type="radio"
            value={item.id}
            onChange={() => {
              setFormError(false);
              setSelectedOfferID(item.id);
            }}
          />
        </div>
      ),
    },
    {
      name: 'title',
      sortable: true,
      text: l('OFFERS-COL-title'),
      flex: '50%',
    },
    {
      name: 'submitted_at',
      sortable: true,
      text: l('OFFERS-COL-submitted_at'),
      flex: '10%',
    },
    {
      name: 'status',
      sortable: true,
      text: l('JOB-COL-status'),
      flex: '10%',
    },
    {
      name: 'total',
      sortable: true,
      text: l('OFFERS-COL-total'),
      flex: '10%',
    },
    {
      name: 'remove',
      text: '',
      flex: '10%',
      customRenderer: (item: OfferDataShape) => (
        <div style={{ textAlign: 'right' }}>
          <button
            type="button"
            onClick={() => addToRemovedOfferIDs(item.id)}
            className="btn--svg">
            <GetSvg svg="close" />
          </button>
        </div>
      ),
    },
  ];
  const getGroupTitle = contractorRows =>
    contractorRows[0].participant || l('GROUPED_LIST-TITLE-UNKNOWN');

  const addToRemovedOfferIDs = id => {
    if (id === selectedOfferID) {
      setSelectedOfferID(undefined);
    }
    setRemovedOfferIDs(removedOfferIDs.concat(id));
  };

  const groupRowsByContractorId = rows => {
    const groupedJobs = groupBy(
      rows,
      (obj: OfferDataShape) => obj.contractor_id,
    );
    return Object.keys(groupedJobs).map((key, index) => (
      <OfferListGroup
        onRemove={addToRemovedOfferIDs}
        removedIDs={removedOfferIDs}
        onSelect={setSelectedOfferID}
        selectedID={selectedOfferID}
        key={index}
        groupRows={groupedJobs[key]}
        title={getGroupTitle(groupedJobs[key])}
        headings={getHeadings()}
      />
    ));
  };

  const getExportLink = () => {
    if (selectedOfferID === undefined) return '';
    const requestString = `/exports/${job_id}/grant_request_export?selectedOfferID=${selectedOfferID}&removedOfferIDs=${removedOfferIDs.join(
      ',',
    )}&token=${getToken()}`;
    return requestString;
  };

  const exportButtonClicked = e => {
    if (!canDownload()) {
      e.preventDefault();
      setFormError(true);
    } else {
      onExport();
      backAction();
    }
  };

  const canDownload = () => {
    return selectedOfferID !== undefined;
  };

  return (
    <Fragment>
      <Card
        hasError={formError}
        canCollapse={false}
        title={l('GRANT-REQUEST-OFFERS-title')}
        isOpen
        qeId="grant-request-offers">
        <div className="push--bottom">
          {l('GRANT-REQUEST-OFFERS-description')}
        </div>
        <SimpleList
          enableSearch={false}
          allowClick={false}
          api={`/api/1/view/jobs/${job_id}/offer`}
          clickPropTwo="id"
          clickAct={(_: any, id: string) => {
            setSelectedOfferID(id);
          }}
          name={LIST_NAME}
          headings={getHeadings()}>
          {groupRowsByContractorId}
        </SimpleList>
      </Card>

      <FooterBar>
        <div className="fl-right">
          <div className="fl-right__item">
            <ButtonGroup alt>
              <SimpleButton
                data-meta={{ type: 'text' }}
                label={l('JOB-ACTIONS-BACK')}
                onClick={backAction}
              />
              <a
                disabled
                href={getExportLink()}
                download={canDownload() && 'offers.xlsx'}
                onClick={exportButtonClicked}>
                {l('ACTION-export_list')}
              </a>
            </ButtonGroup>
          </div>
        </div>
      </FooterBar>
    </Fragment>
  );
};

GrantRequest.defaultProps = {};

const mapActions = (dispatch: Function) => ({
  onExport: () => dispatch(grantRequestExporting()),
});

export default withRouter(connect(null, mapActions)(GrantRequest));
