/** @format */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import GetSvg from 'components/GetSvg';
import Card from 'components/Card';
import l from 'helpers/locale';
import ContractorSelectorPanelList from './contractorSelectorPanelList';

// eslint-disable-next-line

type ContractorShape = {
  id: string,
  details: string[],
};

type ReduxStateShape = {
  hasError: boolean,
};

type StateShape = {
  isOpen: boolean,
  data: Object,
  selected: {
    data: Object[],
  },
};

export type PropsShape =
  | {
      isEditing: boolean, // required from form component
      prefillData?: Object[],
      hasError?: boolean,
      label?: string,
      listName: string,
      updateField: Function,
    }
  | {
      // we suck at refactoring this away
      editMode: boolean,
      prefillData?: Object[],
      hasError?: boolean,
      label?: string,
      listName: string,
      updateField: Function,
    };

class ContractorSelectorPanel extends React.Component<PropsShape> {
  static defaultProps = {
    prefillData: [],
    hasError: false,
    label: '',
  };

  constructor(props: PropsShape) {
    super(props);

    this.state = {
      isOpen: false,
      initialData: props.prefillData,
      selected: {
        data: props.prefillData || [],
      },
    };

    this.apiUrl = `/api/1/view/jobs/${this.props.placeType}/${this.props.place}/contractors`;
    this.clickAct = this.selectContractor;
    this.replaceContractor = this.replaceContractor.bind(this);
  }

  state: StateShape;

  componentDidUpdate(prevProps) {
    if (!prevProps.isEditing && this.props.isEditing) {
      this.checkForInitialFormPopulation();
    }
  }

  apiUrl: string;

  toggle = (replaceContractorId?: string): Function => (e: Object): any => {
    if (Object.prototype.hasOwnProperty.call(e, 'preventDefault')) {
      e.preventDefault();
    }

    if (replaceContractorId) {
      this.clickAct = this.replaceContractor(replaceContractorId);
    } else {
      this.clickAct = this.selectContractor;
    }
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  checkForInitialFormPopulation = (): boolean => {
    if (this.props.prefillData.length > 0) {
      const initialAdd = this.props.prefillData.map(
        (item: Object): any => item.contractor_id,
      );
      this.updateFormField(initialAdd);
    }

    return true;
  };

  replaceContractor = (prevContractorId: string): Function => (
    _: any,
    contractorData: Object,
  ): any =>
    this.setState((prevState: StateShape): StateShape => {
      const prevContractorIndex = prevState.selected.data.findIndex(
        (contractor: Object): any =>
          contractor.contractor_id === prevContractorId,
      );
      const newData = [...prevState.selected.data];

      newData.splice(prevContractorIndex, 1, { ...contractorData });

      const updatedList = this.selectedContractorIdList().filter(
        v => v !== prevContractorId,
      );
      updatedList.push(contractorData.contractor_id);

      this.updateFormField(updatedList);

      return {
        ...prevState,
        isOpen: false,
        selected: {
          data: newData,
        },
      };
    });

  selectContractor = (contractorId: string, contractorData: Object): any => {
    const newList = this.selectedContractorIdList().concat(contractorId);
    this.updateFormField(newList);
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      isOpen: false,
      selected: {
        data: [
          ...prevState.selected.data,
          {
            contractor_id: contractorId,
            ...contractorData,
          },
        ],
      },
    }));
  };

  removeContractor(contractor_id: string): any {
    const newList = this.selectedContractorIdList().filter(
      (id: string): string => id !== contractor_id,
    );
    this.updateFormField(newList);
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      isOpen: false,
      selected: {
        data: [
          ...prevState.selected.data.filter(
            (contractor: ContractorShape): ContractorShape =>
              contractor.contractor_id !== contractor_id,
          ),
        ],
      },
    }));
  }

  updateFormField(value: string[]) {
    this.props.updateField({
      key: 'selected_contractors_ids',
      value,
    });
  }

  selectedContractorIdList(): string[] {
    return this.state.selected.data.map(
      (contractor: Object): string => contractor.contractor_id,
    );
  }

  render(): React$Element<*> {
    const dataUsage = !this.props.isEditing
      ? this.state.initialData
      : this.state.selected.data;
    return (
      <Card
        title="PARTICIPANTS-title"
        isOpen
        canCollapse={false}
        qeId="job_card-participants">
        <div className="gw">
          {(dataUsage.length &&
            dataUsage.map((contractor: Object, k: number): React$Element<*> => (
              <div
                className="g g-1/5 wrap-text"
                key={k}
                data-qe-id="job_card-participants-item">
                <label className="form__label--small">
                  {this.props.label} {k + 1}
                </label>
                <div className="push--bottom text--dk--flushed">
                  {contractor.has_profile ? (
                    <Fragment>
                      <div>{contractor.billing_company}</div>
                      <div>{contractor.key_user_full_name}</div>
                      <div>{contractor.key_user_email}</div>
                      <div>{contractor.key_user_phone}</div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div>{contractor.billing_company}</div>
                      <div>{contractor.billing_email}</div>
                      <div>{contractor.billing_telephone}</div>
                    </Fragment>
                  )}
                  {this.props.isEditing ? (
                    <div className="fl-left">
                      <div className="fl-left__item">
                        <button
                          className="btn--text--primary"
                          onClick={this.toggle(contractor.contractor_id)}
                          type="button">
                          {l('JOB-ACTIONS-click_to_add_contact')}
                        </button>
                      </div>
                      <div className="fl-left__item">
                        <button
                          className="btn--text--primary"
                          onClick={(): any =>
                            this.removeContractor(contractor.contractor_id)
                          }
                          type="button">
                          {l('ACTION-delete')}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ))) ||
            null}
          {this.props.isEditing ? (
            <div className="g g-1/5">
              <button
                data-qe-id="contractor_selector_button"
                onClick={this.toggle()}
                className={`button-container-bordered${
                  this.props.hasError ? '--danger' : ''
                }`}
                type="button">
                <GetSvg
                  svg="add_file"
                  wrapperClass="button-container-bordered__inner"
                />
              </button>
            </div>
          ) : null}
        </div>
        <ContractorSelectorPanelList
          concernType={this.props.concernType}
          concernId={this.props.concernId}
          ignoreContractors={this.selectedContractorIdList()}
          isOpen={this.state.isOpen}
          listName={this.props.listName}
          toggle={this.toggle()}
          clickAct={this.clickAct}
        />
      </Card>
    );
  }
}

const mapState = (state: Object): ReduxStateShape => ({
  hasError: !!state.jobStore.errors.selected_contractors_ids,
});

export default connect(mapState)(ContractorSelectorPanel);
