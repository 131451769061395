/** @format */

// @flow
import React from 'react';
import l from 'helpers/locale';
import Card from 'components/Card';

type PanelShape = {
  contractorName: string,
  visible: boolean,
  details: string[],
};

const ContactPanel = ({
  contractorName,
  visible,
  details,
}: PanelShape): React$Element<*> =>
  visible ? (
    <Card title="OFFER_FORM-contact_information" isOpen canCollapse={false}>
      <div className="gw">
        <div className="g g-1/3">
          <div className="push--bottom">
            <label className="form__label--small" htmlFor="__">
              {l('OFFER_FORM-contact_person_for').replace(
                ':contractor_name',
                contractorName,
              )}
            </label>
            <p className="text--dk--flushed">
              <span>
                {details &&
                  details.map((val: string, key: number): React$Element<*> => (
                    <span key={key}>
                      {val !== '' ? (
                        <span>
                          {val}
                          <br />
                        </span>
                      ) : null}
                    </span>
                  ))}
              </span>
            </p>
          </div>
        </div>
      </div>
    </Card>
  ) : null;

export default ContactPanel;
