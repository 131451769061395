/** @format */

// import axios from 'axios';
import axios from '../helpers/axios';
import authConst, { auth } from '../constants/auth';
import history from '../helpers/history';
import hasOwn from '../functions/hasOwn.func.js';

import { flushAuth, decryptAuth } from '../functions/authenticator.func.js';

export function unAuth(dispatch = false) {
  // side affect to remove local storage
  flushAuth();
  // end

  // side affect to ensure that we are going home
  history.push('/');
  // end

  // result of this request is a side affect
  unAuthRequest();
  // end

  if (dispatch) {
    return dispatch({ type: auth.AUTH_REMOVE });
  }

  return { type: auth.AUTH_REMOVE };
}

function checkPayload(payload) {
  return (
    hasOwn(payload, 'id') &&
    hasOwn(payload, 'role') &&
    hasOwn(payload, 'name') &&
    hasOwn(payload, 'email') &&
    hasOwn(payload, 'lang')
  );
}

export function isAuth() {
  const payload = decryptAuth();
  const action = { type: authConst.success, payload };

  const noRefresh = checkPayload(payload);

  if (!noRefresh) {
    return dispatch => getNewToken(action, dispatch);
  }

  return action;
}

function getNewToken(action, dispatch) {
  return axios
    .post('/auth/manage/refresh')
    .then(() => dispatch(action))
    .catch(() => unAuth(dispatch));
}

function responseSuccess(type) {
  const payload = decryptAuth();
  return { type, payload };
}

function responseError(type, error) {
  return { type, error };
}

function responseRequest(type) {
  return { type };
}

function unAuthRequest() {
  return axios
    .post('/auth/logout')
    .then(res => res)
    .catch(err => err);
}

export const makeRequest = ({ email, password }) =>
  function makeRequestThunk(dispatch) {
    dispatch(responseRequest(authConst.fetching));
    axios
      .post('/auth/login', {
        email,
        password,
      })
      .then(res => dispatch(responseSuccess(authConst.success, res)))
      .catch(err => dispatch(responseError(authConst.failed, err)));
  };

export const setToken = nextToken => {
  return { type: auth.AUTH_SET_TOKEN, payload: nextToken };
};
