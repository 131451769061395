import React from 'react';
import { withRouter } from 'react-router-dom';

import AssetHousePropertyView from 'workflows/jobAssign/views/assetHousePropertyView';

const TargetSelection = ({ history, match, nextPath }) => (
  <AssetHousePropertyView
    nextAct={(id: string, type: string) => {
      history.push(`${match.url}/${type}/${id}/${nextPath}`);
    }}
  />
);

export default withRouter(TargetSelection);
