// @flow
import StateMachine from 'machines/reduxMachine.js';
import { transitions } from 'machines/constants.js';

import { State, Transition, Edge as EdgeBuilder } from 'async-state-machine';

import isInternalStaff from '../../conditions/isInternalStaff';

export const MACHINE_NAME = 'project_staticdocs_card';

// Transitions for internal docs card
export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) => isInternalStaff(params));

function generateStaticDocsCardStateMachine() {
  const Edge = new EdgeBuilder();

  // States for Internal Docs Card
  const HIDDEN = Object.create(State).setName('HIDDEN');
  const VISIBLE = Object.create(State).setName('VISIBLE');

  // Register Machine
  const Machine = new StateMachine(MACHINE_NAME)
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN])
        .to(VISIBLE),
    )
    .setInitialState(HIDDEN, {}, true);

  return Machine;
}

export default generateStaticDocsCardStateMachine;
