import React from 'react';
import PDFPreview from 'components/PDFPreview';
import SimpleErrorBoundary from 'components/SimpleErrorBoundary';
import Controls, { canControl } from '../Controls';

const PreviewPdf = ({ data, controls }) => (
  <div className="image-preview__inner--wide">
    {canControl(controls) ? (
      <Controls
        controls={controls}
        transitions={data.state_machine.available_transitions}
        data={data}
      />
    ) : null}
    <div className="image-preview__inner">
      <PDFPreview
        customControls={
          canControl ? data.state_machine.available_transitions : []
        }
        data={data}
      />
    </div>
  </div>
);

const SafePreviewPDF = props => {
  return (
    <SimpleErrorBoundary showMessageOnError={false}>
      <PreviewPdf {...props} />
    </SimpleErrorBoundary>
  );
};

export default SafePreviewPDF;
