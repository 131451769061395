/** @format */

// @flow
import React from 'react';
import Card from 'components/Card';
import BillingOrder from 'components/BillingOrder';

type PropsShape = {
  address: string[],
  coversheet: string,
  token: string,
  address_account: string[],
};

const BillOrderPanel = ({
  address,
  coversheet,
  token,
  address_account,
}: PropsShape): React$Element<*> => (
  <Card
    title="BILLING-ORDER-singular"
    isOpen
    canCollapse={false}
    qeId="job_card-billing_order">
    <BillingOrder
      address={address}
      downloadUrl={`${coversheet}?token=${token}`}
      address_account={address_account}
    />
  </Card>
);

BillOrderPanel.defaultProps = {
  token: '',
  coversheet: '',
  address: [],
  address_account: [],
};

export default BillOrderPanel;
