import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import SimpleList, {
  SimpleListItemArrayFormatter,
} from 'components/ConnectedSimpleList';
import Card from 'components/Card';
import { nominationsExporting } from './actions';
import NominationActionButtons from './components/NominationActionButtons';

type ReduxActionsShape = {
  exporting: Function,
};

type PropsShape = {
  history: Object,
} & ReduxActionsShape;

const Nominations = ({ history, userRole, exporting }: PropsShape) => {
  const openSingle = ({ id }): any => {
    history.push(`/nominations/${id}`);
  };

  const exportNominations = (): any => exporting();

  return (
    <div>
      <ActionBar name="nominations_list" />
      <Card
        title="NOMINATION-title-plural"
        asideComponent={NominationActionButtons(
          () => history.push(`/nominations/new-nomination`),
          exportNominations,
          userRole,
        )}
        watermarkName="nominee"
        canCollapse={false}>
        <SimpleList
          api="/api/1/contractor_profiles"
          name="nominations"
          clickAct={openSingle}
          headings={[
            {
              name: 'name',
              sortable: true,
              text: l('CANDIDATE-COL-name'),
              customFormatter: SimpleListItemArrayFormatter(),
            },
            {
              name: 'user',
              sortable: true,
              text: l('CANDIDATE-COL-nominated_by'),
            },
            {
              name: 'last_updated',
              sortable: true,
              text: l('CANDIDATE-COL-last_updated'),
            },
            {
              name: 'status',
              sortable: true,
              text: l('CANDIDATE-COL-status'),
            },
          ]}
          bgcAlt
          filterOpts={[
            {
              text: l('NOMINATIONS_OVERVIEW_FILTER-open_signups'),
              value: `status:"${l('NOMINATION_STATE-signup_pending')}"`,
            },
            {
              text: l('NOMINATIONS_OVERVIEW_FILTER-open_reviews'),
              value: `status:"${l('NOMINATION_STATE-manual_review_pending')}"`,
            },
            {
              text: l('NOMINATIONS_OVERVIEW_FILTER-open_creations'),
              value: `status:"${l(
                'NOMINATION_STATE-external_creation_pending',
              )}"`,
            },
            {
              text: l('NOMINATIONS_OVERVIEW_FILTER-declined'),
              value: `status:"${l('NOMINATION_STATE-declined')}"`,
            },
          ]}
        />
      </Card>
    </div>
  );
};

const mapState = (state): Object => ({
  userRole: state.authStore.user.role,
});

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  exporting(): any {
    return dispatch(nominationsExporting());
  },
});

export default withRouter(connect(mapState, mapActions)(Nominations));
