/** @format */

// @flow
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Overlay } from '@bonlineza/b-lib';
import ResetPwForm from 'components/Form';
import l from 'helpers/locale';
import MapComp from 'components/Map/SafeMap';
import mapConfig from 'components/Map/mapConfig.js';

import * as Actions from './actions';

type PropsShape = {
  // eslint-disable-next-line react/no-unused-prop-types
  fetching: boolean,
  success: boolean,
  failed: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  status: number,
  // eslint-disable-next-line react/no-unused-prop-types
  payload: Object,
};

class ResetPassword extends Component {
  static getDerivedStateFromProps(props) {
    return {
      failed: props.failed,
      success: props.success,
      message: ResetPassword.getErrorMessage(props),
    };
  }

  static getErrorMessage(props: PropsShape) {
    if (props.failed && props.payload && props.payload.password) {
      return 'RESET_PASSWORD-error';
    }
    if (props.failed && props.payload && !props.payload.password) {
      return 'RESET_PASSWORD-general_error';
    }
    return '';
  }

  constructor(props: Object) {
    super(props);
    this.state = {
      failed: false,
      success: false,
      message: '',
    };
  }

  render(): any {
    return (
      <div className="layout-bg">
        <div className="layout-bg__bg">
          <MapComp
            mapConfig={{
              ...mapConfig,
              options: {
                ...mapConfig.options,
                panControl: false,
                zoomControl: false,
                scrollwheel: false,
                gestureHandling: 'none',
              },
            }}
          />
        </div>
        <div className="layout-bg__header">
          <a href="/">
            <img
              className="layout-bg__logo"
              alt="Allianz"
              src="/images/logo.png?1"
              height="40"
            />
          </a>
        </div>
        <div className="layout-bg__content">
          <Overlay isOpen>
            <div className="wrap--small">
              <div className="layout-container">
                {(!this.state.success && (
                  <Fragment>
                    <div className="layout-container__header">
                      <h1 className="layout-container__header__lt-section">
                        {l('RESET_PASSWORD-title')}
                      </h1>
                      <div className="layout-container__header__subtitle-section">
                        {l('RESET_PASSWORD-subtitle')}
                      </div>
                    </div>
                    <div className="gw">
                      <div className="g g-1/1">
                        <ResetPwForm
                          submitAct={this.props.actions.requestResetPassword}
                          sections={[
                            {
                              fields: [
                                {
                                  name: 'password',
                                  label: 'RESET_PASSWORD-new_password',
                                  id: '0',
                                  type: 'password',
                                },
                                {
                                  name: 'password-repeat',
                                  label: 'RESET_PASSWORD-repeat_password',
                                  id: '1',
                                  type: 'password',
                                },
                              ],
                            },
                          ]}
                        />
                      </div>
                      {this.state.failed ? (
                        <div className="g fl-right align--right">
                          <div className="fl-right__item push--top">
                            <div className="fc--danger">
                              {l(this.state.message)}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Fragment>
                )) || (
                  <Fragment>
                    <h1>
                      <span className="layout-container__header__lt-section">
                        {l('RESET_PASSWORD-change')}
                      </span>
                      {l('RESET_PASSWORD-change-success')}
                    </h1>
                    <div className="layout-container__body">
                      <div className="gw">
                        <div className="g g-1/1">
                          <div className="layout-container__body-section">
                            {l('RESET_PASSWORD-change-success-message')}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="align--right">
                      <button
                        type="button"
                        className="btn--primary"
                        data-qe-id="action-login-submit"
                        onClick={() => this.props.history.push('/')}>
                        {l('LOGIN-submit')}
                      </button>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </Overlay>
        </div>
      </div>
    );
  }
}

const mapState = (state: Object): PropsShape => ({
  fetching: state.resetPasswordStore.request.fetching,
  success: state.resetPasswordStore.request.success,
  failed: state.resetPasswordStore.request.failed,
  payload: state.resetPasswordStore.payload,
});

const mapDispatch = (dispatch: any): any => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapState, mapDispatch)(ResetPassword));
