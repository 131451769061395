/** @format */

// @flow

const ids = {
  DRAFT: 'pending',
  REJECTED: 'rejected',
  SUBMITTED: 'submitted',
  DELETED: 'deleted',
  ACCEPTED: 'accepted',
  NO_INTEREST: 'no_interest',
};

export default {
  OfferStatusIDs: ids,
};
