import React from 'react';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import SimpleList, {
  SimpleListItemArrayFormatter,
} from 'components/ConnectedSimpleList';

type PropShape = {
  target: 'owners' | 'supports' | 'audits',
  project_id: number | string | false,
  update: Function,
  ignoreIds?: Array<*>,
};

type StateShape = {
  api: string,
};

class ProjectTeamList extends React.Component<PropShape, StateShape> {
  static defaultProps = {
    ignoreIds: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      api: `/api/1/view/projects/contacts/${
        props.project_id ? `${props.project_id}/` : ''
      }${props.target}`,
    };
  }

  handleClick = obj => {
    this.props.update(obj);
    return true;
  };

  render() {
    return (
      <SimpleList
        api={this.state.api}
        name={`project_contacts_${this.props.target}`}
        bgcAlt
        clickAct={this.handleClick}
        presetQuery={{ exclude: this.props.ignoreIds }}
        enableSearch
        headings={[
          {
            name: 'name',
            sortable: true,
            text: l('PROJECT_SUPPORT-name'),
            customFormatter: SimpleListItemArrayFormatter(),
          },
          {
            name: 'telephone',
            sortable: true,
            text: l('PROJECT_SUPPORT-telephone'),
            customFormatter: SimpleListItemArrayFormatter(),
          },
          {
            name: 'email',
            sortable: true,
            text: l('PROJECT_SUPPORT-email'),
          },
          {
            name: 'created',
            sortable: true,
            text: l('PROJECT_SUPPORT-created'),
          },
          {
            name: 'last_updated',
            sortable: true,
            text: l('PROJECT_SUPPORT-last_updated'),
          },
        ]}
      />
    );
  }
}

const mapState = state => ({
  project_id: state.projectStore.data.project_id || false,
});

export default connect(mapState)(ProjectTeamList);
