/** @format */

import assign from 'object.assign';
import { combineReducers } from 'redux';
import defaultRequest from '../../../constants/request';
import Constants from '../constants';

const {
  viewAccounts,
  ACCOUNTS_ADD_DOC,
  ACCOUNTS_GET_DOCS,
  ACCOUNTS_REMOVE_DOC,
} = Constants;

const initialState = {
  data: [],
  documents: [],
  rawData: {},
  pagination: {
    total: 1,
    current: 1,
  },
  request: {
    ...defaultRequest,
  },
};

type PropsShape = {
  type: string,
  payload: Object,
  file: Object,
  machine: Object,
  target?: string,
};

function viewAccountsReducer(
  state = initialState,
  // eslint-disable-next-line
  { type, payload, target, file, machine }: PropsShape,
) {
  let index = -1;
  switch (type) {
    case viewAccounts.success:
      return assign({}, state, {
        request: assign({}, defaultRequest, {
          success: true,
        }),
        data: payload.payload.data,
        rawData: payload.payload,
        pagination: {
          total: payload.payload.last_page,
          current: payload.payload.current_page,
        },
      });
    case viewAccounts.failed:
      return assign({}, state, {
        request: assign({}, defaultRequest, {
          failed: true,
        }),
      });
    case viewAccounts.fetching:
      return assign({}, state, {
        request: assign({}, defaultRequest, {
          fetching: true,
        }),
        data: [],
        rawData: {},
      });

    case ACCOUNTS_GET_DOCS:
      return {
        ...state,
        documents: payload.documents,
      };

    case ACCOUNTS_ADD_DOC:
      return {
        ...state,
        documents: [
          ...state.documents,
          {
            ...file,
            document_id: file.id,
            user: file.user,
            state_machine: {
              status: machine.current,
              available_transitions: machine.transitions,
            },
          },
        ],
      };

    case ACCOUNTS_REMOVE_DOC:
      index = state.documents.findIndex(
        (v: Object): boolean => v.document_id === payload.id,
      );
      return {
        ...state,
        documents: [
          ...state.documents.slice(0, index),
          ...state.documents.slice(index + 1),
        ],
      };
    default:
      return state;
  }
}

export default combineReducers({
  base: viewAccountsReducer,
  // propertyManager: PropertyManagerReferences,
  // admin: AdminReducer,
  // contractor: ContractorReducer,
  // procurement: ProcurementReducer,
  // agency: AgencyReducer,
});
