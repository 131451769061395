import React, { Fragment } from 'react';

export const StateConditional = ({ state, whenState, children }) => {
  if (
    (typeof whenState === 'string' && whenState === state) ||
    (Array.isArray(whenState) &&
      whenState.some(condition => condition === state))
  ) {
    return <Fragment>{children}</Fragment>;
  }

  return null;
};
