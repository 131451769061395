import React, { Fragment } from 'react';

const If = ({ children, condition }) => {
  if ((typeof condition === 'function' && !condition()) || !condition) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export default If;
