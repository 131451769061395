/** @format */

import React from 'react';
import Panel from './generalPanel.js';

const ObjectPanelWrapper = ({ isEditing, updateField, data }) => (
  <Panel isEditing={isEditing} data={data} updateField={updateField} />
);

export default ObjectPanelWrapper;
