import React from 'react';
import { connect } from 'react-redux';
import ObjectPanel from 'shared/projects/panels/items/Object';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import * as actions from '../../actions';

type ReduxStateShape = {
  data: Object,
  status: string,
};

type PropsShape = ReduxStateShape;

const ObjectCard = ({
  data,
  status,
  updateFormField,
  onChange,
  machine_state,
  panelEditMode,
}: PropsShape): React$Element<*> => (
  <StateConditional
    state={machine_state}
    whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
    <ObjectPanel
      data={data}
      projectStatus={status}
      isEditing={panelEditMode}
      onChange={onChange}
      updateField={updateFormField}
    />
  </StateConditional>
);

const mapState = ({ projectStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.object_general_finance_cards,
  panelEditMode:
    machineStore.object_general_finance_cards === PanelStates.EDITING,
  data: projectStore.form.concern,
  status: projectStore.data.status,
});

const mapActions = (dispatch: Function) => ({
  updateFormField(key: string, value: string): any {
    return dispatch(actions.updateForm(key, value));
  },
  onChange({ target, targetId }) {
    return dispatch(actions.getNewConcern(target, targetId));
  },
});

export default connect(mapState, mapActions)(ObjectCard);
