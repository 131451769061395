/** @format */

export default function generateConstants(name) {
  const upperName = name.toUpperCase();
  return {
    success: `${upperName}_REQUEST_SUCCESS`,
    failed: `${upperName}_REQUEST_FAILED`,
    fetching: `${upperName}_REQUEST_FETCHING`,
  };
}
