/** @format */

// @flow
import React, { Component } from 'react';
import l, { fC } from 'helpers/locale';
import InputFocus from 'helpers/InputFocus';

import InvoiceMath from '../../helpers/invoiceMathFunctions';
import InvoiceSummaryLayout from './invoiceSummaryLayout';
import ItemGroup from './invoiceSummaryItemGroup';

const inputFocus = new InputFocus();

type PropsShape = {
  controlRef: Function,
  batchUpdate: Function,
  viewOnly: boolean,
  fetchedData: Object,
  errors: Object,
};

type StateShape = {};

class InvoiceSummary extends Component<PropsShape, StateShape> {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.viewOnly && nextProps.errors !== prevState.errors) {
      return {
        errors: nextProps.errors,
        errorFields: Object.keys(nextProps.errors).map(k => ({
          name: k,
          error: nextProps.errors[k],
        })),
      };
    }
    return null;
  }

  constructor(props: PropsShape) {
    super(props);
    this.listeners = [];
    this.state = {
      errorFields: [],
      errors: props.errors,
      discount: 0,
      cash_discount: 0,
      payment_conditions: 0,
      total: '',
      calculations: {
        discount: '',
        gross: '',
        grossWithCashDiscount: '',
      },
    };
  }

  componentDidMount() {
    this.setDefaultValues();
    if (Object.keys(this.props.fetchedData).length > 0) {
      this.setPreloadedData();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.viewOnly && !this.props.viewOnly) {
      // eslint-disable-next-line no-unused-vars
      const { calculations, ...fields } = this.state;
      const updates = Object.keys(fields).map((name: string): Object => ({
        name,
        value: fields[name],
      }));
      this.props.batchUpdate(updates);
    }
  }

  componentWillUnmount() {
    inputFocus.clearListeners();
  }

  onFocus = () => inputFocus.selectTargetOnFocus();

  setDefaultValues = (): any => {
    this.props.controlRef('discount', this.state.discount);
    this.props.controlRef('cash_discount', this.state.cash_discount);
    this.props.controlRef('payment_conditions', this.state.payment_conditions);
  };

  setPreloadedData = (): any =>
    this.setState(
      (prevState: StateShape): StateShape => ({
        ...prevState,
        ...this.props.fetchedData,
      }),
      this.calculateTotals,
    );

  calculateTotals = (): StateShape =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      calculations: {
        discount: InvoiceMath.calculateDiscount(
          prevState.total,
          prevState.discount,
        ),
        gross: InvoiceMath.calculateGross(prevState.total, prevState.discount),
        grossWithCashDiscount: InvoiceMath.calculateGrossWithDiscounts(
          prevState.total,
          prevState.discount,
          prevState.cash_discount,
        ),
        vat: InvoiceMath.getVatAmount(
          InvoiceMath.calculateGross(prevState.total, prevState.discount),
        ),
      },
    }));

  handleUpdate = (field, evnt) => {
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      errorFields: prevState.errorFields.filter(ef => ef.name !== field),
    }));
    this.updateValue(field, evnt);
  };

  updateValue = (name: string, event: Object): any => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value;

    const sanitiseFieldValues = {
      payment_conditions: val => `${Math.abs(parseInt(val, 10))}`,
      discount: val => `${Math.abs(parseInt(val, 10))}`,
      cash_discount: val => `${Math.abs(parseInt(val, 10))}`,
      total: val => `${Math.abs(parseFloat(val).toFixed(2))}`,
    };

    const canSanitise = !!Object.keys(sanitiseFieldValues).filter(
      sK => sK === name,
    ).length;
    value =
      (canSanitise &&
        Object.keys(sanitiseFieldValues)
          .filter(sK => sK === name)
          .map(sK => sanitiseFieldValues[sK](value))
          .reduce((pV, cV) => cV)) ||
      value;

    this.props.controlRef(name, value);

    return this.setState(
      (prevState: StateShape): StateShape => ({
        ...prevState,
        [name]: value,
      }),
      this.calculateTotals,
    );
  };

  fieldHasError(field) {
    return !!this.state.errorFields.filter(f => f.name === field).length;
  }

  renderDiscountInput = (
    viewOnly,
    value,
    onChange,
    hasError,
  ): React$Element<*> => (
    <ItemGroup
      name="discount"
      label="offer-discount-percent"
      labelTextClass="form__label"
      hasError={hasError}
      extension="%"
      viewOnly={viewOnly}
      inputProps={{
        type: 'number',
        onChange,
        onFocus: this.onFocus(),
        value,
        'data-qe-id': 'input-offer-discount-percent',
        className: 'form__item__input',
      }}
    />
  );

  renderCashDiscountInput = (): React$Element<*> => (
    <ItemGroup
      name="cash_discount"
      label="offer-cash-discount"
      labelTextClass="form__label"
      hasError={this.fieldHasError('cash_discount')}
      extension="%"
      viewOnly={this.props.viewOnly}
      inputProps={{
        type: 'number',
        onChange: this.handleUpdate.bind(null, 'cash_discount'),
        onFocus: this.onFocus(),
        value: this.state.cash_discount,
        'data-qe-id': 'input-offer-cash-discount-percent',
        className: 'form__item__input',
      }}
    />
  );

  renderPaymentConditionsInput = (): React$Element<*> => (
    <ItemGroup
      name="payment_conditions"
      label="offer-payment-conditions"
      labelTextClass="form__label"
      hasError={this.fieldHasError('payment_conditions')}
      extension={l('GENERAL-days')}
      viewOnly={this.props.viewOnly}
      inputProps={{
        type: 'number',
        onChange: this.handleUpdate.bind(null, 'payment_conditions'),
        onFocus: this.onFocus(),
        value: this.state.payment_conditions,
        'data-qe-id': 'input-offer-payment-within',
        className: 'form__item__input',
      }}
    />
  );

  renderTotalInput = (): React$Element<*> => (
    <ItemGroup
      name="total"
      label="offer-total"
      hasError={this.fieldHasError('total')}
      extension="CHF"
      extensionIsLeft
      viewOnly={this.props.viewOnly}
      viewOnlyFormatter={val => fC(val, false, true)}
      labelTextClass="form__label"
      isRequired
      inputProps={{
        type: 'number',
        onChange: this.handleUpdate.bind(null, 'total'),
        onFocus: this.onFocus(),
        value: this.state.total,
        'data-qe-id': 'input-offer-total',
        className: 'form__item__input',
      }}
    />
  );

  renderTotalDisplay = (): React$Element<*> => (
    <ItemGroup
      name="total_calculated"
      label="offer-total"
      horizontal
      viewOnly={this.props.viewOnly}
      wrappingClass="form__item push--smaller--bottom"
      labelClass="form__item__label--bold form__item__label--is-tall"
      labelTextClass="form__item__label__text--large"
      readOnlyParaClass="text--dk--flushed align--right"
      inputProps={{
        readOnly: true,
        type: 'text',
        value: fC(this.state.total, false, true),
        className: 'form__item__input--read-only align--right',
      }}
    />
  );

  renderDiscountDisplay = (viewOnly, value): React$Element<*> => (
    <ItemGroup
      name="discount_calculated"
      label="discount-total-calculated"
      horizontal
      viewOnly={viewOnly}
      wrappingClass="form__item push--smaller--bottom"
      labelClass="form__item__label--bold form__item__label--is-tall"
      labelTextClass="form__item__label__text--large"
      readOnlyParaClass="text--dk--flushed align--right"
      inputProps={{
        readOnly: true,
        type: 'text',
        value: fC(value, false, true),
        className: 'form__item__input--read-only align--right',
      }}
    />
  );

  renderGrossDiplay = (): React$Element<*> => (
    <ItemGroup
      name="gross_calculatedd"
      label="offer-gross-calculated"
      horizontal
      viewOnly={this.props.viewOnly}
      labelClass="form__item__label--bold form__item__label--is-tall"
      labelTextClass="form__item__label__text--large"
      wrappingClass="form__item flush--bottom"
      readOnlyParaClass="text--dk--flushed align--right"
      inputProps={{
        readOnly: true,
        type: 'text',
        value: fC(this.state.calculations.gross, false, true),
        className: 'form__item__input--read-only align--right',
      }}
    />
  );

  renderGrossInclCashDiscount = (): React$Element<*> => (
    <ItemGroup
      name="gross_incl_cash_discount_calculated"
      label="offer-gross-cash_discount"
      horizontal
      viewOnly={this.props.viewOnly}
      labelClass="form__item__label--bold form__item__label--is-tall"
      labelTextClass="form__item__label__text--large"
      wrappingClass="form__item flush--bottom"
      readOnlyParaClass="text--dk--flushed align--right"
      inputProps={{
        readOnly: true,
        type: 'text',
        value: fC(this.state.calculations.grossWithCashDiscount, false, true),
        className: 'form__item__input--read-only align--right',
      }}
    />
  );

  renderVatDisplay = (): React$Element<*> => (
    <ItemGroup
      name="vat_calculated"
      label="OFFER_FORM-vat"
      horizontal
      viewOnly={this.props.viewOnly}
      labelClass="form__item__label--bold form__item__label--is-tall"
      labelTextClass="form__item__label__text--large"
      wrappingClass="form__item flush--bottom"
      readOnlyParaClass="text--dk--flushed align--right"
      inputProps={{
        readOnly: true,
        type: 'text',
        value: fC(this.state.calculations.vat, false, true),
        className: 'form__item__input--read-only align--right',
      }}
    />
  );

  render(): React$Element<*> {
    return (
      <InvoiceSummaryLayout
        DiscountInput={() =>
          this.renderDiscountInput(
            this.props.viewOnly,
            this.state.discount,
            this.handleUpdate.bind(null, 'discount'),
            this.fieldHasError('discount'),
          )
        }
        CashDiscountInput={this.renderCashDiscountInput}
        PaymentConditionsInput={this.renderPaymentConditionsInput}
        TotalInput={this.renderTotalInput}
        TotalDisplay={this.renderTotalDisplay}
        DiscountDisplay={() =>
          this.renderDiscountDisplay(
            this.props.viewOnly,
            this.state.calculations.discount,
          )
        }
        GrossDisplay={this.renderGrossDiplay}
        GrossInclCashLabel={this.renderGrossInclCashLabel}
        GrossInclCash={this.renderGrossInclCashDiscount}
        VatDisplay={this.renderVatDisplay}
      />
    );
  }
}

export default (payload: Object): Function => ({
  key,
  ...props
}: {
  key: any,
  props: any,
}): React$Element<*> => (
  <InvoiceSummary key={key} {...props} fetchedData={payload} />
);
