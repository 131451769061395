import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import CloseButton from 'components/AsideSlide/CloseButton';
import { AsideSlide } from '@bonlineza/b-lib';
import ProjectTeamList from './projectTeamList';

type StateShape = {
  slideActive: boolean,
  target: 'support' | 'owners',
};

type ReduxStateShape = {
  support?: Array<*>,
  remove_support: boolean,
  change_support: boolean,
  change_owner: boolean,
  owner: Object,
};

type PropsShape = {
  isCreating: boolean,
  member: {
    is_owner: boolean | void,
    user_id: string | number,
  },
} & ReduxStateShape;

class ProjectTeamControls extends React.Component<PropsShape, StateShape> {
  constructor(props) {
    super(props);
    this.state = {
      slideActive: false,
      target:
        props.member.user_id === props.owner.user_id ? 'owners' : 'supports',
    };
  }

  openSlide = () =>
    this.setState({
      slideActive: true,
    });

  closeSlide = () =>
    this.setState({
      slideActive: false,
    });

  canRemove = () =>
    (this.props.isCreating && this.isSupport()) ||
    (this.props.remove_support && this.isSupport());

  canEdit = () =>
    this.props.isCreating ||
    (this.props.change_owner && this.isOwner()) ||
    (this.props.change_support && this.isSupport());

  isSupport = () => !this.props.member.is_owner;

  isOwner = () => this.props.member.is_owner;

  removeTeamMember = () => {
    this.props.supportAction('remove', this.props.member);
    return true;
  };

  editProjectTeam = data => {
    if (this.isOwner()) {
      this.props.ownerAction('change', {
        oldUser: this.props.member,
        newUser: data,
      });
    } else {
      this.props.supportAction('change', {
        oldUser: this.props.member,
        newUser: data,
      });
    }

    return true;
  };

  render() {
    const ignoredIds = this.props.support
      .reduce((a, v) => [...a, `${v.user_id}`], [])
      .concat(this.props.owner.user_id);
    return (
      <Fragment>
        <Fragment>
          <Fragment>
            {this.canRemove() ? (
              <div className="pos-relative">
                <button
                  type="button"
                  className="btn--text--primary"
                  onClick={this.removeTeamMember}
                  data-qe-id="action-remove-project_support">
                  {l('PROJECT_TEAM-click_to_remove')}
                </button>
              </div>
            ) : null}
          </Fragment>
          <Fragment>
            {this.canEdit() ? (
              <div className="pos-relative">
                <button
                  type="button"
                  className="btn--text--primary"
                  onClick={this.openSlide}
                  data-qe-id={`action-edit-${
                    this.props.member.is_owner
                      ? 'project_owner'
                      : 'project_support'
                  }`}>
                  {l(`PROJECT_TEAM-click_to_change_${this.state.target}`)}
                </button>
              </div>
            ) : null}
          </Fragment>
          <AsideSlide
            isOpen={this.state.slideActive}
            title={l('PROJECT-SUPPORT_TEAM-update')}
            toggle={this.closeSlide}
            toggleButton={() => <CloseButton onClick={this.closeSlide} />}>
            <div>
              {this.state.slideActive ? (
                <ProjectTeamList
                  target={this.state.target}
                  update={this.editProjectTeam}
                  ignoreIds={ignoredIds}
                />
              ) : null}
            </div>
          </AsideSlide>
        </Fragment>
      </Fragment>
    );
  }
}

ProjectTeamControls.defaultProps = {
  support: [],
};

const mapState = ({ projectStore }, { isEditing }): ReduxStateShape => ({
  support: isEditing
    ? projectStore.form.project_supports
    : projectStore.data.project_supports,
  owner: isEditing
    ? projectStore.form.project_owner
    : projectStore.data.project_owner,
  remove_support:
    projectStore.data.state_machine.available_transitions.findIndex(
      v => v.name === 'remove_support',
    ) >= 0,
  change_support:
    projectStore.data.state_machine.available_transitions.findIndex(
      v => v.name === 'change_support',
    ) >= 0,
  change_owner:
    projectStore.data.state_machine.available_transitions.findIndex(
      v => v.name === 'change_owner',
    ) >= 0,
});

export default connect(mapState)(ProjectTeamControls);
