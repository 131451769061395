/** @format */

// @flow
import Transitions from 'views/singleProject/components/ProjectActions/transitions';
import { getMoment } from 'helpers/dates';
import {
  ProjectFetchCreateData,
  CLEAR_PROJECT_FORM_VALIDATION,
  PROJECT_CREATE_UPDATE,
} from 'workflows/projectCreate/actions';
import request from 'constants/request';
import hasOwnVal from 'functions/hasOwnVal.func';

// PROJECT CREATE and PROJECT UPDATES are used in FORM
// PROJECT VIEW are used in DATA

import {
  CLEAR_SUB_REQUEST,
  PROJECT_SET_DATA,
  PROJECT_RESET_STATE,
  PROJECT_FLUSH_STATE,
  PROJECT_UPDATE_PLACE,
  PROJECT_FORM_UPDATE,
  PROJECT_FORM_UPDATE_CONTACTS,
  PROJECT_FORM_ADD_CUSTOM_CONTACT,
  PROJECT_ADD_DOC,
  PROJECT_REMOVE_DOC,
  CREATE_PROJECT_SUPPORT_ADD,
  CREATE_PROJECT_SUPPORT_REMOVE,
  CREATE_PROJECT_SUPPORT_CHANGE,
  CREATE_PROJECT_OWNER_CHANGE,
  PROJECT_SUPPORT_ADD,
  PROJECT_SUPPORT_REMOVE,
  PROJECT_SUPPORT_CHANGE,
  PROJECT_OWNER_CHANGE,
  PROJECT_UPDATE_CONCERN,
} from '../actions';

const {
  copy,
  cancel,
  execute,
  open,
  change_owner,
  change_support,
  complete,
  end,
  remove_support,
  remove_owner,
  update,
  save,
  change_audit,
  add_audit,
} = Transitions;

type StateShape = {
  data: Object,
  form: Object,
  errors: { [string]: string[] },
  machine: Object,
};

type PropsShape = {
  type: string,
  payload?: Object,
  target?: string,
  rest: mixed,
};

const initialState: StateShape = {
  lastRefresh: 0,
  data: {
    documents: [],
  },
  form: {
    address_owner: [],
    address_scan_center: [],
    concern: null,
    contact_audit: null,
    contact_audit_id: null,
    contact_audit_type: null,
    occasions: [],
    project_account: null,
    project_supports: [],
    internal_documents: [],
    project_team_documents: [],
    summary: '',
    title: '',
    budget: 0,
    documents: [],
    getCreateRequest: {
      ...request,
    },
    errors: {},
  }, // remember to set defaults here
  errors: {},
  machine: {
    transitions: [],
  },
  subRequest: request,
};

function filterFormUpdateErrors(errors: any, formUpdate: any): any {
  const newObject = {};
  Object.keys(errors)
    .filter((key: string): boolean => key !== formUpdate.key)
    .map((item: string): Object => ({ [item]: errors[item] }))
    .forEach((item: any): any => {
      newObject[Object.keys(item)[0]] = item[Object.keys(item)[0]];
      return item;
    });
  return newObject;
}

export default function SingleProjectReducer(
  state: StateShape = initialState,
  // eslint-disable-next-line
  { type, payload, target, data, ...rest }: PropsShape,
): StateShape {
  let index = -1;
  switch (type) {
    case 'PROJECT_RELOAD_DATA':
      return {
        ...state,
        lastRefresh: Date.now(),
      };

    case copy.success:
    case cancel.success:
    case execute.success:
    case open.success:
    case complete.success:
    case end.success:
    case update.success:
    case save.success:
      return {
        ...state,
        subRequest: {
          ...request,
          succes: true,
        },
      };

    case copy.fetching:
    case cancel.fetching:
    case execute.fetching:
    case open.fetching:
    case complete.fetching:
    case end.fetching:
    case update.fetching:
    case save.fetching:
      return {
        ...state,
        subRequest: {
          ...request,
          fetching: true,
        },
      };

    case copy.failed:
    case cancel.failed:
    case execute.failed:
    case open.failed:
    case complete.failed:
    case end.failed:
      return {
        ...state,
        subRequest: {
          ...request,
          failed: true,
        },
      };

    case save.failed:
      return {
        ...state,
        form: {
          ...state.form,
          errors: hasOwnVal(payload, 'status', 422) ? data : state.form.errors,
        },
        subRequest: {
          ...request,
          failed: !hasOwnVal(payload, 'status', 422),
        },
      };

    case update.failed:
      return {
        ...state,
        form: {
          ...state.form,
          errors: hasOwnVal(payload, 'status', 422)
            ? payload.data.payload
            : state.form.errors,
        },
        subRequest: {
          ...request,
          failed: !hasOwnVal(payload, 'status', 422),
        },
      };

    case CLEAR_SUB_REQUEST:
      return {
        ...state,
        subRequest: {
          ...request,
        },
      };

    case PROJECT_SET_DATA:
    case change_owner.success:
    case change_support.success:
    case remove_support.success:
    case remove_owner.success:
      return {
        ...state,
        data: {
          ...payload,
        },
        form: {
          ...state.form,
          ...payload,
          errors: {},
          contact_audit_id: payload.contact_audit.contact_id,
          contact_audit_type: payload.contact_audit.type,
        },
        errors: {},
        machine: payload ? payload.state_machine : {},
      };

    case PROJECT_UPDATE_PLACE:
      return {
        ...state,
        data: {
          ...state.data,
          object: payload.concern,
          building_selected: null,
        },
        form: {
          ...state.form,
          concern: payload.concern,
        },
      };

    case PROJECT_CREATE_UPDATE: // updateProjectFormData
      return {
        ...state,
        form: {
          ...state.form,
          [payload.key]: payload.value,
          errors: filterFormUpdateErrors(state.form.errors, payload),
        },
      };

    case ProjectFetchCreateData.success:
      return {
        ...state,
        data: {
          ...data,
          state_machine: data.state_machine,
        },
        form: {
          ...state.form,
          ...data,
          target_date: parseInt(getMoment()().format('X'), 10),
          getCreateRequest: {
            ...request,
            success: true,
          },
        },
      };
    case ProjectFetchCreateData.fetching:
      return {
        ...state,
        form: {
          ...state.form,
          getCreateRequest: {
            ...request,
            fetching: true,
          },
        },
      };
    case ProjectFetchCreateData.failed:
      return {
        ...state,
        form: {
          ...state.form,
          getCreateRequest: {
            ...request,
            failed: true,
          },
        },
      };
    case CLEAR_PROJECT_FORM_VALIDATION:
      return {
        ...state,
        form: {
          ...state.form,
          requestErrors: {},
        },
      };
    case PROJECT_FORM_UPDATE:
      return {
        ...state,
        form: {
          ...state.form,
          [payload.key]: payload.value,
          errors: filterFormUpdateErrors(state.form.errors, payload),
        },
      };

    case PROJECT_FORM_UPDATE_CONTACTS:
      return {
        ...state,
        form: {
          ...state.form,
          contact_audit_id:
            payload.key === 'contact_audit' && !payload.value
              ? null
              : state.form.contact_audit_id,
          [payload.key]: {
            ...payload.value,
            state_machine: state.form[payload.key].state_machine,
          },
        },
      };

    case PROJECT_FORM_ADD_CUSTOM_CONTACT:
      return {
        ...state,
        form: {
          ...state.form,
          [payload.key]: {
            ...payload.value,
            state_machine: state.form[payload.key].state_machine,
          },
          [`${payload.key}_id`]: payload.value.contact_id,
          [`${payload.key}_type`]: 'contact', // CODE SMELL: should be 'custom' instead of redundant 'contact' key
        },
      };

    case add_audit:
      return {
        ...state,
        form: {
          ...state.form,
          contact_audit: {
            ...payload,
            isNew: true,
          },
        },
      };

    case change_audit:
      return {
        ...state,
        form: {
          ...state.form,
          contact_audit: rest.contactObj,
          contact_audit_id: payload,
        },
      };
    case PROJECT_ADD_DOC:
      return Object.prototype.hasOwnProperty.call(rest, 'projectId')
        ? {
            ...state,
            data: {
              ...state.data,
              documents: [
                ...state.data.documents,
                {
                  ...rest.file,
                  document_id: rest.file.id,
                  user: rest.file.user,
                  state_machine: {
                    status: rest.machine.current,
                    available_transitions: rest.machine.transitions,
                  },
                },
              ],
            },
          }
        : {
            ...state,
            form: {
              ...state.form,
              documents: [
                ...state.form.documents,
                {
                  ...rest.file,
                  document_id: rest.file.id,
                  user: rest.file.user,
                  state_machine: {
                    status: rest.machine.current,
                    available_transitions: rest.machine.transitions,
                  },
                },
              ],
            },
          };

    case PROJECT_REMOVE_DOC:
      index = state[target].documents.findIndex(
        (v: Object): boolean => v.document_id === payload.id,
      );

      return {
        ...state,
        [target]: {
          ...state[target],

          documents: [
            ...state[target].documents.slice(0, index),
            ...state[target].documents.slice(index + 1),
          ],
        },
      };

    case PROJECT_FLUSH_STATE:
      return {
        ...initialState,
      };

    case PROJECT_RESET_STATE:
      return {
        ...state,
        form: {
          ...state.form,
          ...state.data,
        },
      };

    case PROJECT_SUPPORT_ADD:
      return {
        ...state,
        form: {
          ...state.form,
          project_supports: [...state.form.project_supports, payload],
        },
      };
    case CREATE_PROJECT_SUPPORT_ADD:
      return {
        ...state,
        form: {
          ...state.form,
          project_supports: [...state.form.project_supports, payload],
        },
      };

    case PROJECT_SUPPORT_REMOVE:
      index = state.form.project_supports.findIndex(
        v => v.user_id === payload.user_id,
      );
      return {
        ...state,
        form: {
          ...state.form,
          project_supports: [
            ...state.form.project_supports.slice(0, index),
            ...state.form.project_supports.slice(index + 1),
          ],
        },
      };

    case CREATE_PROJECT_SUPPORT_REMOVE:
      index = state.form.project_supports.findIndex(
        v => v.user_id === payload.user_id,
      );
      return {
        ...state,
        form: {
          ...state.form,
          project_supports: [
            ...state.form.project_supports.slice(0, index),
            ...state.form.project_supports.slice(index + 1),
          ],
        },
      };

    case PROJECT_SUPPORT_CHANGE:
      index = state.form.project_supports.findIndex(
        v => v.user_id === payload.old.user_id,
      );

      return {
        ...state,
        form: {
          ...state.form,
          project_supports: [
            ...state.form.project_supports.slice(0, index),
            payload.new,
            ...state.form.project_supports.slice(index + 1),
          ],
        },
      };

    case CREATE_PROJECT_SUPPORT_CHANGE:
      index = state.form.project_supports.findIndex(
        v => v.user_id === payload.old.user_id,
      );

      return {
        ...state,
        form: {
          ...state.form,
          project_supports: [
            ...state.form.project_supports.slice(0, index),
            payload.new,
            ...state.form.project_supports.slice(index + 1),
          ],
        },
      };

    case PROJECT_OWNER_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          project_owner: { ...payload },
          project_owner_id: payload.user_id,
        },
      };

    case CREATE_PROJECT_OWNER_CHANGE:
      return {
        ...state,
        form: {
          ...state.form,
          project_owner: { ...payload },
          project_owner_id: payload.user_id,
        },
      };

    case PROJECT_UPDATE_CONCERN:
      return {
        ...state,
        form: {
          ...state.form,
          concern: payload,
          concern_id: payload.concern_id,
          concern_type: payload.concern_type,
        },
      };
    default:
      return state;
  }
}
