/** @format */

import React from 'react';
import getToken from 'functions/getToken';
import Panel from './billOrderPanel.js';

const BillManagementPanelWrapper = ({
  address,
  coversheet,
  isEditing,
  address_account,
}) => (
  <Panel
    isEditing={isEditing}
    address={address}
    token={getToken()}
    coversheet={coversheet}
    address_account={address_account}
  />
);

export default BillManagementPanelWrapper;
