/** @format */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@bonlineza/b-lib/init';

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import LocalisationReady from 'components/LocalisationReady';
import SimpleLoadable from 'components/SimpleLoadable';

import store from './store';
import history from './helpers/history';
import { AuthWrapperConnected } from './functions/authenticator.func.js';

/* Components */
import AppLayout from './layouts/app.layout.js';

const App = SimpleLoadable({
  loader: () => import(/* webpackChunkName: "view.App" */ 'views/App'),
});

const Jobs = SimpleLoadable({
  loader: () => import(/* webpackChunkName: "view.jobs" */ 'views/jobs'),
});

const ResetPassword = SimpleLoadable({
  loader: () =>
    import(/* webpackChunkName: "view.resetPassword" */ 'views/resetPassword'),
});

const Accounts = SimpleLoadable({
  loader: () =>
    import(/* webpackChunkName: "view.accounts" */ 'views/accounts'),
});

const Dashboard = SimpleLoadable({
  loader: () =>
    import(/* webpackChunkName: "view.dashboard" */ 'views/dashboard'),
});

const Contractors = SimpleLoadable({
  loader: () =>
    import(/* webpackChunkName: "view.contractors" */ './views/contractors'),
});

const Properties = SimpleLoadable({
  loader: () =>
    import(/* webpackChunkName: "view.properties" */ './views/properties'),
});

const Nominations = SimpleLoadable({
  loader: () =>
    import(/* webpackChunkName: "view.nominations" */ './views/nominations'),
});

const Invitation = SimpleLoadable({
  loader: () =>
    import(/* webpackChunkName: "view.invitation" */ './views/invitation'),
});

const TermsAndConditionsENGB = SimpleLoadable({
  loader: () =>
    import(
      /* webpackChunkName: "view.terms-en-gb" */
      './views/static/termsAndConditions-en-GB'
    ),
});

const TermsAndConditionsDECH = SimpleLoadable({
  loader: () =>
    import(
      /* webpackChunkName: "view.terms-de-ch" */
      './views/static/termsAndConditions-de-CH'
    ),
});

const TermsAndConditionsFRCH = SimpleLoadable({
  loader: () =>
    import(
      /* webpackChunkName: "view.terms-fr-ch" */
      './views/static/termsAndConditions-fr-CH'
    ),
});

const Impressum = lang =>
  SimpleLoadable({
    loader: () =>
      import(
        /* webpackChunkName: "view.impressum" */
        './views/static/impressum'
      ),
    lang,
  });

const Projects = SimpleLoadable({
  loader: () =>
    import(/* webpackChunkName: "view.projects" */ './views/projects'),
});

const ComplianceQuestionnaire = SimpleLoadable({
  loader: () =>
    import(
      /* webpackChunkName: "view.compliance-form" */ './views/complianceQuestionnaire'
    ),
});

render(
  <Provider store={store}>
    <AuthWrapperConnected>
      <ConnectedRouter history={history}>
        <div>
          <LocalisationReady>
            <Switch>
              <Route exact path="/" component={App} />
              <Route path="/reset-password" component={ResetPassword} />
              <AppLayout
                path="/invitations/:public_id"
                component={Invitation}
              />
              <AppLayout exact path="/accounts" component={Accounts} auth />
              <AppLayout
                exact
                path="/accounts/create"
                component={Accounts}
                auth
              />
              <AppLayout
                exact
                path="/accounts/create/:role_abbrv"
                component={Accounts}
                auth
              />
              <AppLayout
                exact
                path="/accounts/:account_id(\d+)"
                component={Accounts}
                auth
              />
              <AppLayout exact path="/dashboard" component={Dashboard} auth />
              <AppLayout
                exact
                path="/dashboard/project-create"
                component={Dashboard}
                auth
              />
              <AppLayout
                exact
                path="/dashboard/project-create/:type/:type_id/new"
                component={Dashboard}
                auth
              />
              <AppLayout exact path="/jobs" component={Jobs} auth />
              <AppLayout
                exact
                path="/jobs/:job_id(\d+)"
                component={Jobs}
                auth
              />
              <AppLayout
                exact
                path="/jobs/:job_id(\d+)/contractor/:contractor_id(\d+)"
                component={Jobs}
                auth
              />
              <AppLayout
                exact
                path="/jobs/:job_id(\d+)/offer"
                component={Jobs}
                auth
              />
              <AppLayout
                exact
                path="/jobs/:job_id(\d+)/grant-request"
                component={Jobs}
                auth
              />
              <AppLayout
                exact
                path="/dashboard/job-create"
                component={Dashboard}
                auth
              />
              <AppLayout
                exact
                path="/dashboard/job-create/:type/:type_id/job-offer"
                component={Dashboard}
                auth
              />
              <AppLayout
                exact
                path="/profile/view"
                component={Dashboard}
                auth
              />
              <AppLayout
                exact
                path="/dashboard/job-assign"
                component={Dashboard}
                auth
              />
              <AppLayout
                exact
                path="/dashboard/job-assign/:type/:type_id/contractor"
                component={Dashboard}
                auth
              />
              <AppLayout
                exact
                path="/dashboard/job-assign/:type/:type_id/contractor/:contractor_id(\d+)"
                component={Dashboard}
                auth
              />

              <AppLayout exact path="/jobs/job-create" component={Jobs} auth />
              <AppLayout
                exact
                path="/jobs/job-create/:type/:type_id/job-offer"
                component={Jobs}
                auth
              />
              <AppLayout exact path="/jobs/job-assign" component={Jobs} auth />
              <AppLayout
                exact
                path="/jobs/job-assign/:type/:type_id/contractor"
                component={Jobs}
                auth
              />
              <AppLayout
                exact
                path="/jobs/job-assign/:type/:type_id/contractor/:contractor_id(\d+)"
                component={Jobs}
                auth
              />
              <AppLayout
                exact
                path="/jobs/:job_id(\d+)/offers/:offer_id"
                component={Jobs}
                auth
              />
              <AppLayout
                exact
                path="/contractors"
                component={Contractors}
                auth
              />
              <AppLayout
                exact
                path="/contractors/nomination"
                component={Contractors}
                auth
              />
              <AppLayout
                exact
                path="/contractors/:contractor_id(\d+)"
                component={Contractors}
                auth
              />
              <AppLayout
                exact
                path="/contractors/:contractor_id(\d+)/profile"
                component={Contractors}
                auth
              />
              <AppLayout
                exact
                path="/contractors/:contractor_id(\d+)/invite"
                component={Contractors}
                auth
              />
              <AppLayout
                exact
                path="/contractors/:contractor_id(\d+)/jobs/:job_id(\d+)"
                component={Contractors}
                auth
              />
              <AppLayout
                exact
                path="/contractors/:contractor_id(\d+)/properties/:property_id(\d+)"
                component={Contractors}
                auth
              />
              <AppLayout exact path="/properties" component={Properties} auth />
              <AppLayout
                exact
                path="/properties/:property_id(\d+)"
                component={Properties}
                auth
              />
              <AppLayout
                exact
                path="/properties/:property_id(\d+)/jobs/:job_id(\d+)"
                component={Properties}
                auth
              />
              <AppLayout
                exact
                path="/nominations"
                component={Nominations}
                auth
              />
              <AppLayout
                exact
                path="/nominations/new-nomination"
                component={Nominations}
                auth
              />
              <AppLayout
                exact
                path="/nominations/:nomination_id(\d+)"
                component={Nominations}
                auth
              />
              <AppLayout
                exact
                path="/en-gb/terms-and-conditions"
                component={TermsAndConditionsENGB}
                showTermsPopUp={false}
              />
              <AppLayout
                exact
                path="/de-ch/terms-and-conditions"
                component={TermsAndConditionsDECH}
                showTermsPopUp={false}
              />
              <AppLayout
                exact
                path="/fr-ch/terms-and-conditions"
                component={TermsAndConditionsFRCH}
                showTermsPopUp={false}
              />
              <AppLayout
                exact
                path="/en-gb/impressum"
                component={Impressum('en-gb')}
              />
              <AppLayout
                exact
                path="/de-ch/impressum"
                component={Impressum('de-ch')}
              />
              <AppLayout
                exact
                path="/fr-ch/impressum"
                component={Impressum('fr-ch')}
              />
              <AppLayout path="/impressum" component={Impressum()} />
              <AppLayout exact path="/projects" component={Projects} auth />
              <AppLayout
                exact
                path="/projects/project-create"
                component={Projects}
                auth
              />
              <AppLayout
                exact
                path="/projects/project-create/:type/:type_id/new"
                component={Projects}
                auth
              />
              <AppLayout
                exact
                path="/projects/:project_id(\d+)"
                component={Projects}
                auth
              />
              <AppLayout
                exact
                path="/projects/:project_id(\d+)/add-payment"
                component={Projects}
                auth
              />
              <AppLayout
                exact
                path="/projects/:project_id(\d+)/edit-payment/:payment_id(\d+)"
                component={Projects}
                auth
              />
              <AppLayout
                exact
                path="/projects/:project_id(\d+)/add-team"
                component={Projects}
                auth
              />

              <AppLayout
                exact
                path="/projects/:project_id(\d+)/open-job"
                component={Projects}
                auth
              />

              <AppLayout
                exact
                path="/projects/:project_id(\d+)/:type/:type_id(\d+)/assign-job"
                component={Projects}
                auth
              />

              <AppLayout
                exact
                path="/projects/:project_id(\d+)/:type/:type_id(\d+)/assign-job/:contractor_id(\d+)"
                component={Projects}
                auth
              />

              <AppLayout
                exact
                path="/projects/:project_id(\d+)/:job_id(\d+)"
                component={Projects}
                auth
              />

              <AppLayout
                exact
                path="/projects/:project_id(\d+)/:job_id(\d+)/offers/:offer_id(\d+)"
                component={Projects}
                auth
              />

              <AppLayout
                exact
                path="/projects/:project_id(\d+)/:job_id(\d+)/add-contractor"
                component={Projects}
                auth
              />

              <AppLayout
                exact
                path="/projects/:project_id(\d+)/:job_id(\d+)/grant-request"
                component={Projects}
                auth
              />

              <AppLayout
                exact
                path="/compliance-questionnaire"
                component={ComplianceQuestionnaire}
                auth
              />

              <Route component={App} />
            </Switch>
          </LocalisationReady>
        </div>
      </ConnectedRouter>
    </AuthWrapperConnected>
  </Provider>,

  document.querySelector('.js-mount-point'),
);

export default store;
