/** @format */

import l from 'helpers/locale';
import hasOwn from 'functions/hasOwn.func.js';
import Documents from '../components/documents';
import InvoiceSummary from '../components/InvoiceSummary';
import SecondaryDocuments from '../components/SecondaryDocuments';

export default function(payload, formViewOnly) {
  const check = hasOwn.bind(null, payload);
  const firstField = !formViewOnly
    ? {
        type: 'text',
        id: '0',
        name: 'title',
        label: l('OFFER_FORM-offer_title'),
        itemClass: 'g g-1/2 g--fill',
        mandatory: true,
        value: check('title') ? payload.title : '',
      }
    : {
        type: 'text',
        id: '1',
        name: 'status',
        label: l('JOB-COL-status'),
        itemClass: 'g g-1/2 g--fill',
        value: check('status') ? payload.status_translated : '',
      };

  const generalFields = [
    { ...firstField },
    {
      type: 'text_editor',
      dataQeId: 'input-edit-offer_description',
      id: '2',
      name: 'description',
      label: l('OFFER_FORM-description'),
      itemClass: 'g g-1/1',
      value: check('description') ? payload.description : '',
    },
  ];

  return [
    {
      title: l('OFFER_FORM-general'),
      sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
      fields: [...generalFields],
    },
    {
      title: l('OFFER_FORM-offer_info'),
      sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
      fields: [
        {
          type: 'component',
          id: '3',
          name: 'file_id',
          itemClass: 'g g-1/1',
          component: Documents(payload, formViewOnly),
        },
        {
          type: 'component',
          id: '4',
          name: 'invoice_summary',
          itemClass: 'g g-1/1',
          component: InvoiceSummary(payload),
        },
      ],
    },
    {
      title: l('OFFER_FORM-secondary_documents'),
      sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
      fields: [
        {
          type: 'component',
          id: '5',
          name: 'file_id',
          itemClass: 'g g-1/1',
          component: SecondaryDocuments(payload, formViewOnly),
        },
      ],
    },
  ];
}
