import React from 'react';
import Panel from './accountPanel.js';

const AccountPanelWrapper = ({ editNow, updateField, data, formData }) => (
  <Panel
    data={data}
    formData={formData}
    updateField={updateField}
    isEditing={editNow}
  />
);

AccountPanelWrapper.defaultProps = {
  editNow: false,
  updateField: false,
};

export default AccountPanelWrapper;
