import graphi from 'helpers/graphi';
import { hasValue } from '@bonlineza/b-lib/functions';

export const getContractorQuery = contractorId => {
  const withContractorQuery =
    (contractorId && `(contractor_id:${contractorId})`) || '';
  return `
  {
    vmp_contractor${withContractorQuery} {
      contractor_id,

      billing_company,
      company_2,
      billing_lang_code,
      billing_email,
      billing_telephone,

      street,
      pobox,
      postal_code,
      city,
      country{
        id,
        name,
        shortname
      }
      website,

      key_user_id,
      key_user_salutation,
      key_user_phone,
      key_user_full_name,
      key_user_email,

      uid_number,
      has_profile,
      post_city,
      trades{
        key,
        value
      },

      iban,
      bank_address,

      profile{
        id,
        state_machine{
          status,
          available_transitions{
            name,
            action,
            type
          }
        }
      },

      compliance{
        last_completed,
        status,
        state_machine{
          status,
          available_transitions{
            name,
            action,
            type
          }
        }
        latest_questionnaire{
          submission,
        }
      },

      current_year_payments_volume,
      ratings_count,
      rating_avg,
      recommendation_status,
      status,
      customer{
        customer_code,
        state_machine{
          status
          available_transitions{
            name,
            action,
            type
          }
        }
      },
      geocode{
        lat,
        lng,
      },
      jobs_count,
      job_last_date,
      job_locations{
        lat,
        lng
      },

      users{
        user_id,
        name,
        surname,
        telephone,
        role_id,
        email,
        full_name
      }
      notifications_count,
      notifications(limit:6){
        subject,
        message,
        timestamp,
      },
      ratings_count,
      ratings(limit:6){
        user{
          full_name
        },
        comment,
        timestamp,
        rating
      },
    }
  }
  `;
};

export async function getContractorProfile(contractorId): Object {
  const { data } = await graphi(
    getContractorQuery(contractorId),
    'getContractorProfile',
  );
  return data.data.vmp_contractor;
}

function getNotificationsQuery(contractorId, limit = 200) {
  return `{
    vmp_contractor(contractor_id:${contractorId}) {
      notifications(limit:${limit}){
        subject,
        message,
        timestamp,
      },
    },
  }`;
}

export async function getNotifications(contractorId): Object[] {
  if (!hasValue(contractorId)) return [];
  const { data } = await graphi(
    getNotificationsQuery(contractorId),
    'getContractorNotifications',
  );
  return data.data.vmp_contractor.notifications;
}

function getRatingsQuery(contractorId, limit = 200) {
  return `{
    vmp_contractor(contractor_id:${contractorId}) {
      ratings(limit:${limit}){
        user{
          full_name
        },
        comment,
        timestamp,
        rating
      },
    },
  }`;
}

export async function getRatings(contractorId): Object[] {
  if (!hasValue(contractorId)) return [];
  const { data } = await graphi(
    getRatingsQuery(contractorId),
    'getContractorRatings',
  );
  return data.data.vmp_contractor.ratings;
}
