/** @format */

// @flow
function toFloatFixed(amount: number): number {
  let result = (0).toFixed(2);

  if (amount) {
    result = parseFloat(amount).toFixed(2);
  }

  return result;
}

const InvoiceMathFunctions = {
  vat: 1.077,

  calculateDiscount: (total: number, discount: number): number =>
    discount ? toFloatFixed((discount / 100) * total) : 0.0,

  calculateGross: (_total: number, _discount: number): number => {
    const total = toFloatFixed(_total);
    const discount = toFloatFixed(_discount);
    return discount
      ? toFloatFixed(total - (discount / 100) * total)
      : toFloatFixed(total);
  },

  calculateGrossWithDiscounts: (
    _total: number,
    _discount: number,
    _earlyDiscount,
  ): number => {
    const discount = toFloatFixed(_discount);
    const earlyDiscount = toFloatFixed(_earlyDiscount);
    const total = toFloatFixed(_total);

    return toFloatFixed(
      InvoiceMathFunctions.getVatTotal(total * (1 - discount / 100)) *
        (1 - earlyDiscount / 100),
    );
  },

  getVatTotal: (amount: number): number =>
    toFloatFixed(InvoiceMathFunctions.vat * amount),

  getVatAmount: (amount: number) =>
    toFloatFixed((1 - InvoiceMathFunctions.vat) * amount * -1),
};

export default InvoiceMathFunctions;
