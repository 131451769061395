import React, { Fragment, useState } from 'react';
import GetSvg from 'components/GetSvg';
import GrantPopup from 'components/GrantModal/GrantPopup';

type AddContactPopupProps = {
  buttonLabel: string,
  onAdd: Function,
  hiddenIds: Array,
  listQuery: Function,
};

const AddContactPopup = ({
  buttonLabel,
  onAdd,
  hiddenIds = [],
  listQuery,
}: AddContactPopupProps) => {
  const [popVisible, setPopVisible] = useState(false);

  const onFormSubmit = (salutations: Array<*>): Function => (
    formData: Object,
  ): any => {
    const selectedSalutation = salutations
      .filter((v: any): boolean => `${v.value}` === formData.salutation_id)
      .reduce((pv, cv) => cv, '');
    onAdd({
      id: 'new',
      full_name: `${formData.name} ${formData.surname}`,
      email: formData.email,
      type: '',
      salutation: {
        description: selectedSalutation.label,
        id: `${selectedSalutation.value}`,
      },
      lang: formData.lang,
    });

    return false;
  };

  return (
    <Fragment>
      <button
        className="btn--text--primary"
        type="button"
        onClick={(e: Event): any => {
          e.preventDefault();
          return setPopVisible(true);
        }}>
        <GetSvg svg="circle_plus" wrapperClass="u-active" />
        {buttonLabel}
      </button>
      {popVisible ? (
        <GrantPopup
          listQuery={listQuery}
          close={() => setPopVisible(false)}
          onListClick={onAdd}
          hiddenIds={hiddenIds}
          onFormSubmit={onFormSubmit}
        />
      ) : null}
    </Fragment>
  );
};

export default AddContactPopup;
