import React from 'react';
import SwitchView from 'components/SwitchView';
import l from 'helpers/locale';
import ContactForm from './ContactForm';
import AllianzList from './AllianzList';
import CareTakerList from './CareTakerList';
import TenantList from './TenantList';
import DeliveryList from './DeliveryList';

function AuditContact(props) {
  return (
    <SwitchView
      views={[
        {
          type: 'form',
          label: l('CONTACT_LIST-new'),
          active: true,
        },
      ]}>
      <div type="form">
        <ContactForm {...props} type="form" />
      </div>
    </SwitchView>
  );
}

function AllianzContact(props) {
  return (
    <SwitchView
      views={[
        {
          type: 'list',
          label: l('CONTACT_LIST-list'),
          active: true,
        },
      ]}>
      <div type="list">
        <AllianzList {...props} />
      </div>
    </SwitchView>
  );
}

function CareTakerContact(props) {
  return (
    <SwitchView
      views={[
        {
          type: 'list',
          label: l('CONTACT_LIST-list'),
          active: true,
        },
      ]}>
      <div type="list">
        <CareTakerList {...props} />
      </div>
    </SwitchView>
  );
}

function TenantContact(props) {
  return (
    <SwitchView
      views={[
        {
          type: 'list',
          label: l('CONTACT_LIST-list'),
          active: true,
        },
      ]}>
      <div type="list">
        <TenantList {...props} />
      </div>
    </SwitchView>
  );
}

function DeliveryContact(props) {
  return (
    <SwitchView
      views={[
        {
          type: 'list',
          label: l('CONTACT_LIST-list'),
          active: true,
        },
        {
          type: 'form',
          label: l('CONTACT_LIST-form'),
          active: false,
        },
      ]}>
      <div type="list">
        <DeliveryList {...props} />
      </div>
      <div type="form">
        <ContactForm {...props} />
      </div>
    </SwitchView>
  );
}

function UnknownContact() {
  return (
    <div>
      <h1>UNKNOWN CONTACT TYPE</h1>
    </div>
  );
}

type Props = {
  type: string,
  create: Function,
  update: Function,
  setPopup?: Function,
};

function ContactPopup(props: Props) {
  switch (props.type) {
    case 'audit':
      return <AuditContact {...props} />;
    case 'delivery':
      return <DeliveryContact {...props} />;
    case 'allianz':
      return <AllianzContact {...props} />;
    case 'care_taker':
      return <CareTakerContact {...props} />;
    case 'tenant':
      return <TenantContact {...props} />;
    default:
      return <UnknownContact {...props} />;
  }
}

ContactPopup.defaultProps = {
  setPopup() {
    return true;
  },
};

export default ContactPopup;
