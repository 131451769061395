/** @format */

// @flow

import React, { Fragment } from 'react';

import FormInputAttachment from '../../../../../../components/Form/FormInputAttachment';

import l from '../../../../../../helpers/locale';

type PropsShape = {
  inputProps: Object,
  name: string,
  viewOnly: boolean,
  viewOnlyFormatter?: Function,
  label?: string | Function,
  horizontal?: boolean,
  extension?: string,
  extensionIsLeft?: boolean,
  isRequired?: boolean,
};

const InvoiceSummaryItemGroup = ({
  name,
  label = '',
  viewOnly,
  viewOnlyFormatter,
  inputProps,
  horizontal = false,
  extension = '',
  extensionIsLeft = false,
  hasError = false,
  isRequired = false,
  wrappingClass = 'form__item',
  labelClass = 'form__item__label',
  labelTextClass = 'form__item__label__text',
  readOnlyParaClass = '',
}: PropsShape): React$Element<*> => (
  <div className={`${wrappingClass} ${hasError ? 'form__item--danger' : ''}`}>
    <div className="gw">
      {label !== '' ? (
        <div className={horizontal ? 'g g-2/3' : 'g g-1/1'}>
          <label htmlFor={name} className={labelClass}>
            <span className={labelTextClass}>
              <Fragment>
                {typeof label !== 'function' ? l(label) : label()}
              </Fragment>
              <Fragment>{isRequired ? <span>*</span> : null}</Fragment>
            </span>
          </label>
        </div>
      ) : null}
      <div className={horizontal ? 'g g-1/3' : 'g g-1/1'}>
        <FormInputAttachment
          attach={extension !== ''}
          text={extension}
          toLeft={extensionIsLeft}
          viewOnly={viewOnly}>
          {viewOnly ? (
            <p className={readOnlyParaClass}>
              {(viewOnlyFormatter && viewOnlyFormatter(inputProps.value)) ||
                `${inputProps.value} ${extension}`}
            </p>
          ) : (
            <input
              {...inputProps} // must have className
              name={name}
            />
          )}
        </FormInputAttachment>
      </div>
    </div>
  </div>
);

InvoiceSummaryItemGroup.defaultProps = {
  label: '',
  horizontal: false,
  extension: '',
  extensionIsLeft: false,
  hasError: false,
  isRequired: false,
  viewOnlyFormatter: null,
  wrappingClass: 'form__item',
  labelClass: 'form__item__label',
  labelTextClass: 'form__item__label__text',
  readOnlyParaClass: 'text--dk--flushed',
};

export default InvoiceSummaryItemGroup;
