import isContractor from 'machines/conditions/isContractor';
import isPropertyManager from 'machines/conditions/isPropertyManager';
import isConstructionManager from 'machines/conditions/isConstructionManager';
import isHeadConstructionManager from 'machines/conditions/isHeadConstructionManager';
import isProjectStateWithin from './isProjectStateWithin';
import isProjectOwner from './isProjectOwner';
import isProjectSupport from './isProjectSupport';
import hasJobs from './hasJobs';

export default {
  isContractor,
  isPropertyManager,
  isConstructionManager,
  isHeadConstructionManager,
  isProjectStateWithin,
  isProjectOwner,
  isProjectSupport,
  hasJobs,
};
