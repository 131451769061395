/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import Card from 'components/Card';
import GetSvg from 'components/GetSvg';
import l from 'helpers/locale';

import {
  projectSupportAdd,
  projectSupportChange,
  projectSupportRemove,
  projectOwnerChange,
} from 'views/singleProject/actions';

import ProjectTeamControls from './projectTeamControls.js';
import ProjectTeamAdd from './projectTeamAdd.js';

import { requestUser } from './queries';

type ReduxStateShape = {
  add_support: boolean,
};

type PropsShape = {
  data: Array<*>,
  isCreating: boolean,
} & ReduxStateShape;

const ProjectTeamPanel = ({
  data,
  isCreating,
  add_support,
  isEditing,
  projectId,

  reduxAddSupport,
  reduxChangeSupport,
  reduxRemoveSupport,
  reduxChangeOwner,
}: PropsShape): React$Element<*> => {
  const [addUserLoading, setAddUserLoading] = React.useState(false);
  const [idLoading, setIdLoading] = React.useState(0);

  async function supportUserAction(action, userData) {
    let nextUser = null;
    const context = projectId ? 'update' : 'create';
    switch (action) {
      case 'add':
        setAddUserLoading(true);
        nextUser = await requestUser(userData.id);
        reduxAddSupport(nextUser, context);
        return setAddUserLoading(false);
      case 'change':
        setIdLoading(userData.oldUser.user_id);
        nextUser = await requestUser(userData.newUser.id);
        reduxChangeSupport(userData.oldUser, nextUser, context);
        return setIdLoading(false);
      case 'remove':
        setIdLoading(userData.user_id);
        reduxRemoveSupport(userData, context);
        // fake loading
        window.setTimeout(() => {
          window.requestAnimationFrame(() => setIdLoading(false));
        }, 200);
        return null;
      default:
        return false;
    }
  }

  async function ownerUserAction(action, userData) {
    const context = projectId ? 'update' : 'create';
    let newOwner = null;
    switch (action) {
      case 'change':
        setIdLoading(userData.oldUser.user_id);
        newOwner = await requestUser(userData.newUser.id);
        reduxChangeOwner(newOwner, context);
        return setIdLoading(true);
      default:
        return false;
    }
  }

  return (
    <Card title="PROJECT-TEAM-title" isOpen canCollapse={false}>
      <div className="gw">
        {data.map((member, key) => (
          <div className="g g-1/3" key={key}>
            <div className="push--bottom">
              <label className="form__label--small" htmlFor="__">
                {l(
                  `PROJECT-team_${
                    member.is_owner ? 'project_owner' : 'project_support'
                  }`,
                )}
              </label>
              {idLoading === member.user_id ? (
                <GetSvg svg="loading" wrapperClass="loader-base" />
              ) : (
                <React.Fragment>
                  <p className="text--dk--flushed">
                    {['full_name', 'telephone', 'email'].map(
                      (item, itemKey) => (
                        <span key={itemKey}>
                          {member[item]}
                          <br />
                        </span>
                      ),
                    )}
                  </p>
                  {(isCreating || add_support) && isEditing ? (
                    <div className="pos-relative display--iblock soft--small--bottom">
                      <ProjectTeamControls
                        member={member}
                        isCreating={isCreating}
                        isEditing={isEditing}
                        supportAction={supportUserAction}
                        ownerAction={ownerUserAction}
                      />
                    </div>
                  ) : null}
                </React.Fragment>
              )}
            </div>
          </div>
        ))}
        <div className="g g-1/3">
          {(isCreating || add_support) && isEditing ? (
            <ProjectTeamAdd
              loading={addUserLoading}
              update={supportUserAction.bind(null, 'add')}
            />
          ) : null}
        </div>
      </div>
    </Card>
  );
};

const mapState = (state): ReduxStateShape => ({
  add_support:
    state.projectStore.data.state_machine.available_transitions.findIndex(
      v => v.name === 'add_support',
    ) >= 0,
  projectId: state.projectStore.data.project_id,
});

const mapDispatch = dispatch => ({
  reduxAddSupport(...params) {
    return dispatch(projectSupportAdd(...params));
  },
  reduxChangeSupport(...params) {
    return dispatch(projectSupportChange(...params));
  },
  reduxRemoveSupport(...params) {
    return dispatch(projectSupportRemove(...params));
  },
  reduxChangeOwner(...params) {
    return dispatch(projectOwnerChange(...params));
  },
});

export default connect(mapState, mapDispatch)(ProjectTeamPanel);
