import React from 'react';
import AllianzSelect from 'components/AllianzSelect';

type SelectShape = {
  label: string,
  placeholder: string,
  classSuffix: string,
  formatLabel: Function,
  value: string | number,
  options: Object[],
  onChange: Function,
  description: string,
  isEditing: boolean,
  hasError: boolean,
};

export default ({
  label,
  placeholder,
  classSuffix,
  formatLabel,
  value,
  options,
  onChange,
  description,
  isEditing,
  hasError,
}: SelectShape) => (
  <div className="g g-1/2">
    <div
      className={`form-and-view-container ${classSuffix} ${
        hasError ? 'form-and-view-container--error' : ''
      }
      `}>
      <label className="form__label--small" htmlFor="__">
        {label}
      </label>
      <div className="form__input" data-qe-id="action-click-occasion_selector">
        {isEditing && options.length ? (
          <AllianzSelect
            placeholder={placeholder}
            defaultValue={{
              label: value ? formatLabel(value) : '-',
              value,
            }}
            disabled={!isEditing}
            onValueChange={(changedValue: {
              value: string,
              label: string,
            }): any => onChange(changedValue ? changedValue.value : null)}
            options={options.map(v => ({
              label: formatLabel(v.key),
              value: v.key,
            }))}
          />
        ) : (
          <p className="text--dk--flushed">{description}</p>
        )}
      </div>
    </div>
  </div>
);
