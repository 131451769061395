import React from 'react';
import l from 'helpers/locale';
import Card from 'components/Card';
import ComplianceForm from 'components/ComplianceForm';

function ComplianceQuestionnaireCard({ submission }) {
  if (!submission) return null;
  let parsedData = null;

  parsedData = JSON.parse(submission);

  return (
    <div className="compliance-questionnaire--view">
      <Card title={l('CONTRACTOR-compliance_questionnaire')} isOpen={false}>
        <ComplianceForm
          version={parsedData.version}
          data={parsedData.formData}
          api=""
          formProps={{
            viewOnly: true,
            viewOptions: true,
            renderFooter: () => null, // do not render a footer
          }}
        />
      </Card>
    </div>
  );
}

export default ComplianceQuestionnaireCard;
