import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Names } from 'components/RouteSlider/routes/accounts/constants';
import SingleAccountView from 'views/accounts/components/singleAccount';
import { connect } from 'react-redux';
import { updateTitle } from 'components/RouteSlider/actions';
import { userRoles as ROLE } from '../../../../../constants/auth';

const AccountViewRender = ({ setTitle, userType }) => {
  useEffect(() => {
    setTitle('-');
    return () => setTitle('-');
  }, [setTitle]);
  return (
    <SingleAccountView
      updateTitle={(role: string, name: string) =>
        setTitle(`${role} ${name ? `- ${name}` : ''}`)
      }
      canEdit={[ROLE.ADMINISTRATOR].includes(userType)}
    />
  );
};
type ReduxStateShape = {
  userType: number,
};

const mapState = (state: Object): ReduxStateShape => ({
  userType: state.authStore.user.role,
});

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.ACCOUNT_VIEW, title));
  },
});

export const AccountView = withRouter(
  connect(mapState, mapActions)(AccountViewRender),
);
