import React from 'react';
import GetSvg from 'components/GetSvg';
import l from '../../helpers/locale';

const TermsAndConditions = ({ alertBody, handleClick, isLoading }) => {
  return (
    <div className="popup__inner__container__body">
      {isLoading ? (
        <div className="fl-vh-center">
          <GetSvg svg="loading" wrapperClass="loader-base" />
        </div>
      ) : (
        <div className="wrap">
          <div className="layout-container alert-layout-container">
            <h1>
              <span className="layout-container__header__lt-section">
                {alertBody?.header || null}
              </span>
            </h1>
            <div className="layout-container__body alert-layout-container__body">
              {/* eslint-disable-next-line react/no-danger */}
              <p dangerouslySetInnerHTML={{ __html: alertBody?.body || '' }} />
              <div className="align--right">
                <button
                  className="btn--success-lt"
                  type="button"
                  onClick={handleClick}>
                  {l('TERMS_POPUP-accept')}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsAndConditions;
