import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import Form from 'components/Form';
import UntilReady from 'components/UntilReady';
import FooterBar from 'components/FooterBar';
import { updateSimpleList } from 'components/ConnectedSimpleList/actions';
import { SliderContext } from 'components/RouteSlider';

import axios from 'helpers/axios';
import l from 'helpers/locale';
import { interpretErrorMessage } from 'helpers/formatErrors';
import hasOwn from 'functions/hasOwn.func.js';

import FormConfig, { validationConfig } from './paymentFormConfig.js';

class AddPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request: {
        success: false,
        failed: false,
        message: '',
        fetching: true,
      },
      data: {},
    };
    this.getForm();
  }

  getForm = () =>
    axios
      .get(this.fetchApi)
      .then(this.handleResponse)
      .catch(this.handleError);

  fetchApi = `/api/1/projects/${this.props.match.params.project_id}/data/add_payment`;

  submitApi = `/api/1/projects/${this.props.match.params.project_id}/action/add_payment`;

  submitForm = data =>
    axios
      .post(this.submitApi, data)
      .then(this.handleSubmitted)
      .catch(this.handleError);

  cancelForm = () => this.props.history.goBack();

  handleResponse = res =>
    this.setState({
      request: {
        success: true,
        failed: false,
        message: '',
        fetching: false,
      },
      data: res.data.payload,
    });

  handleError = err =>
    this.setState({
      request: {
        success: false,
        failed: true,
        message: interpretErrorMessage(err),
        fetching: false,
      },
    });

  handleSubmitted = () => {
    this.props.dispatch(updateSimpleList('project_payments_list'));
    return this.props.history.goBack();
  };

  validator = fields => {
    let failedFields = {};
    let result = true;
    validationConfig.map(v => {
      if (!hasOwn(fields, v) || fields[v] === '' || fields[v] === null) {
        result = false;
        failedFields = { ...failedFields, [v]: 'VALIDATION-required' };
        return false;
      }

      if (v === 'total' && Number.isNaN(parseInt(fields[v], 10))) {
        result = false;
        failedFields = {
          ...failedFields,
          [v]: 'VALIDATION-invalid_number',
        };
        return false;
      }

      return true;
    });

    return {
      result,
      fields: failedFields,
    };
  };

  render() {
    return (
      <SliderContext.Consumer>
        {context => (
          <UntilReady
            ready={this.state.request.success}
            waiting={this.state.request.fetching}
            notReadyOrWaitingText={this.state.request.message}>
            <Form
              scrollToTop={context.scrollToTop}
              sections={FormConfig(this.state.data)}
              submitAct={this.submitForm}
              cancelAct={this.cancelForm}
              wrappingClass="gw"
              wrappingOuterClass=""
              validateBefore={this.validator}
              renderFooter={actions => (
                <FooterBar>
                  <div className="fl-right">
                    {actions.map((item, k) => (
                      <button
                        type="button"
                        {...item.attrs}
                        key={k}
                        onClick={item.action}
                        data-qe-id={`add-payment-${item.attrs['data-qe-id']}`}>
                        {l(item.label)}
                      </button>
                    ))}
                  </div>
                </FooterBar>
              )}
            />
          </UntilReady>
        )}
      </SliderContext.Consumer>
    );
  }
}

export default withRouter(connect()(AddPayment));
