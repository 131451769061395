import React, { Fragment, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { hasValue } from '@bonlineza/b-lib/functions';
import Roles from 'constants/roles';
import ONBOARDING from 'constants/onboardingStates.js';
import FooterBar from 'components/FooterBar';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import PageReady from 'components/AllianzPageReady';
import CustomerCard from 'shared/contractor/SingleContractor/cards/CustomerCard';
import FooterActions from './components/FooterActions';
import Panels from './Panels';

const {
  ContactPersonPanel,
  GeneralPanel,
  InvitationPanel,
  NominationPanel,
  BankPanel,
  BillingPanel,
} = Panels;

type ReduxStateShape = {
  userType: number,
};

type PropsShape = {
  backAction?: Function | void,
  dataUpdatedCB: Function,
} & ReduxStateShape;

type StateShape = {
  request: Object,
  data: Object,
  goBack: Function,
};

class SingleNomination extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    backAction: null,
  };

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      data: {},
      goBack: props.backAction
        ? props.backAction
        : (): any => this.props.history.push('/nominations'),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      hasValue(this.props.match.params.nomination_id) &&
      prevProps.match.params.nomination_id !==
        this.props.match.params.nomination_id
    ) {
      this.fetchData();
    }
  }

  state: StateShape;

  pageReady: Component;

  fetchData = (): any => this.pageReady.get();

  successHandler = (data: Object): any => {
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      data: data.payload,
    }));
    this.props.dataUpdatedCB(data.payload);
  };

  render(): React$Element<*> {
    const { state } = this;
    return (
      <Fragment>
        <ActionBar name="single_nomination" />
        <PageReady
          ref={instance => {
            this.pageReady = instance;
          }}
          url={`/api/1/contractor_profiles/${this.props.match.params.nomination_id}`}
          onData={res => this.successHandler(res.data)}>
          <div className="push--bottom">
            <GeneralPanel
              status={state.data.state}
              lastChange={state.data.updated_at}
              langCode={state.data.nomination_langcode}
            />
          </div>
          <div className="push--bottom">
            <CustomerCard
              canCollapse={false}
              status={state.data.customer_status || ''}
              customerNumber={state.data.customer_code || ''}
              recommended={false}
              showRecommended={false}
              editMode={
                [1, 2].includes(this.props.userType) &&
                state.data.state === 'manual_review_pending'
              }
            />
          </div>
          <div className="push--bottom">
            <NominationPanel reason={state.data.nomination_cause} />
          </div>
          <div className="push--bottom">
            {state.data.users && state.data.users.length ? (
              <ContactPersonPanel
                companyName={state.data.company}
                firstName={state.data.users[0].name}
                surname={state.data.users[0].surname}
                email={state.data.users[0].email}
                salutation={
                  (state.data.users[0].salutation &&
                    state.data.users[0].salutation.description) ||
                  null
                }
              />
            ) : (
              <ContactPersonPanel
                companyName={state.data.nomination_company}
                firstName={state.data.nomination_first_name}
                surname={state.data.nomination_last_name}
                email={state.data.nomination_email}
                salutation={
                  state.data.salutation !== undefined && state.data.salutation
                    ? state.data.salutation.description
                    : ''
                }
              />
            )}
          </div>
          {state.data.state !== ONBOARDING.nomination_signup_pending &&
          state.data.state !== ONBOARDING.invitation_signup_pending ? (
            <div className="push--bottom">
              <BillingPanel
                companyName={state.data.company || '-'}
                companyName2={state.data.company_2 || '-'}
                road={state.data.street || '-'}
                pobox={state.data.pobox || '-'}
                address_additional={state.data.street2 || '-'}
                postCode={state.data.zip || '-'}
                city={state.data.city || '-'}
                country={state.data.country || '-'}
                phone={state.data.phone || '-'}
                email={state.data.email || '-'}
                website={state.data.website || '-'}
                uid={state.data.uid_number || '-'}
                // trade: state.data.,
              />
            </div>
          ) : null}
          {state.data.state !== ONBOARDING.nomination_signup_pending &&
          state.data.state !== ONBOARDING.invitation_signup_pending ? (
            <div className="push--bottom">
              <BankPanel
                // bankName: state.data.,
                bankAddress={state.data.bank_address}
                iban={state.data.iban}
              />
            </div>
          ) : null}
          {([
            Roles.RoleIds.ADMINISTRATION,
            Roles.RoleIds.CENTRAL_PROCUREMENT,
          ].includes(this.props.userType) &&
            state.data.status === ONBOARDING.nomination_signup_pending) ||
          state.data.status === ONBOARDING.invitation_signup_pending ? (
            <div className="push--bottom">
              <InvitationPanel
                link={`${window.location.origin}/#/invitations/${state.data.public_id}`}
              />
            </div>
          ) : null}
          {}
          <FooterBar>
            <FooterActions
              userType={this.props.userType}
              close={this.state.goBack}
              nominationId={this.props.match.params.nomination_id}
              currentState={state.data.state || ''}
              refreshData={this.fetchData}
              nominee={[
                `${state.data.company} AG`,
                `${state.data.street}`,
                `${state.data.zip || ''} ${state.data.city || ''}`,
              ]}
            />
          </FooterBar>
        </PageReady>
      </Fragment>
    );
  }
}

const mapState = (state: Object): ReduxStateShape => ({
  userType: state.authStore.user.role,
  customerFields: state.customerRelationship.fields,
});

export default withRouter(connect(mapState)(SingleNomination));
