import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { States } from 'machines/constants';
import { SimplePopup } from '@bonlineza/b-lib';
import l from 'helpers/locale';
import ButtonGroup, { WithLoader } from 'components/ButtonGroup';
import { updateSimpleList } from 'components/ConnectedSimpleList/actions';
import { SliderContext } from 'components/RouteSlider';
import ErrorPopup from 'components/ErrorPopup';

import actions from './actions';
import { CLEAR_SUB_REQUEST } from '../../actions';
import useProjectTransitions from './hooks/useProjectTransitions';

type ReduxState = {
  isProjectEditing: boolean,
  transitions?: Array<Object>,
  projectId: number | void,
  concern_id: number,
  concern_type: string,
};

type Props = {
  isProjectCreate?: boolean,
  backAction: Function,
} & ReduxState;

function ProjectActions(props: Props) {
  const { scrollToTop } = useContext(SliderContext);
  const [popupProps, setPopupProps] = useState({ title: '', body: '' });
  const [popupOpen, setPopupOpen] = useState(false);

  function openPopupWithProps(action) {
    switch (action) {
      case 'open':
        setPopupProps({
          action,
          title: 'POPUP-confirm_open-title',
          body: 'POPUP-confirm_open-body',
        });
        setPopupOpen(true);
        break;
      case 'complete':
        setPopupProps({
          action,
          title: 'POPUP-confirm_complete-title',
          body: 'POPUP-confirm_complete-body',
        });
        setPopupOpen(true);
        break;
      default:
        break;
    }
  }

  const openPopup = React.useCallback(
    transition => openPopupWithProps(transition),
    [],
  );

  function popupAction(action) {
    switch (action) {
      case 'open':
      case 'complete':
        fire(action);
        break;
      default:
        break;
    }
  }

  const transitions = useProjectTransitions(props.transitions, {
    isEditing: props.isProjectEditing,
    isCreating: props.isProjectCreate,
    backAction: props.backAction,
    openPopup,
  });

  function fire(name) {
    const action = actions(props.projectId, scrollToTop)[name];

    return props.dispatch(
      action(
        {
          concern_type: props.concern_type,
          concern_id: props.concern_id,
        },
        props.refreshList,
      ),
    );
  }
  return (
    <div className="fl-right">
      <div className="fl-right__item">
        <WithLoader
          listeners={[{ loading: props.isFetching, name: 'projectRequest' }]}>
          <ButtonGroup alt numButtons={3}>
            {transitions.map((item, key) => (
              <button
                data-meta={{ type: item.type || 'default' }}
                key={key}
                type="button"
                href={item.href || null}
                onClick={
                  item.actionFunction
                    ? () => item.actionFunction()
                    : () => fire(item.name)
                }
                data-qe-id={`action-click-project-${item.name}`}>
                {l(`PROJECT_ACTION-${item.name}`)}
              </button>
            ))}
          </ButtonGroup>
        </WithLoader>
        <SimplePopup
          isOpen={popupOpen}
          close={() => setPopupOpen(false)}
          title={l(popupProps.title)}
          description={l(popupProps.body)}
          options={[
            {
              cb: () => setPopupOpen(false),
              buttonText: l('cancel'),
              buttonClass: 'btn--text',
            },
            {
              cb: () => {
                setPopupOpen(false);
                popupAction(popupProps.action);
              },
              buttonText: l(`PROJECT_ACTION-${popupProps.action}`),
              buttonClass: 'btn--primary',
              dataQeId: `project_popup-confirm-${popupProps.action}`,
            },
          ]}
        />
        <ErrorPopup
          isOpen={props.actionFailed}
          callback={props.clearSubRequest}
        />
      </div>
    </div>
  );
}

ProjectActions.defaultProps = {
  isProjectCreate: false,
  transitions: [],
};

const mapState = (
  { projectStore, machineStore },
  { isProjectCreate },
): ReduxState => ({
  isProjectEditing: [
    States.EDIT,
    States.UPDATING,
    States.UPDATE_ERROR,
  ].includes(machineStore.project),
  transitions:
    machineStore.project === States.LOADING
      ? []
      : projectStore.data.state_machine?.available_transitions,
  projectId: projectStore.data.project_id,
  project: projectStore.data,
  formData: projectStore.form,
  concern_id:
    projectStore[isProjectCreate ? 'form' : 'data'].concern?.concern_id || 0,
  concern_type:
    projectStore[isProjectCreate ? 'form' : 'data'].concern?.concern_type || '',
  isFetching: projectStore.subRequest.fetching,
  actionFailed: projectStore.subRequest.failed,
});

const mapActions = dispatch => ({
  refreshList(): any {
    return dispatch(updateSimpleList('project_list'));
  },
  dispatch,
  clearSubRequest: () => dispatch({ type: CLEAR_SUB_REQUEST }),
});

export default connect(mapState, mapActions)(ProjectActions);
