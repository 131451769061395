import assign from 'object.assign';
import axios from 'helpers/axios';
import stringBasing from 'helpers/stringBasing';
import singleJobConstants from 'views/singleJob/constants';
import { PROJECT_ADD_DOC } from 'views/singleProject/actions';
import Constants from '../constants';

const { ADD_JOB_DOC } = singleJobConstants;
const { UploadRequest, REMOVE_FILE } = Constants;
const { success, failed, fetching } = UploadRequest;

export function removeFile(instance: string, id: number) {
  return { type: REMOVE_FILE, instance, payload: { id } };
}

export function uploadFile(
  endpoint: string,
  data: Object,
  file: any,
  instance: string,
  callback?: Function,
): any {
  const form = new FormData();
  form.append('file', file);
  form.append('source_tag', data.source_tag);
  form.append('source_type', data.source_type || '');
  form.append('source_id', data.source_id || '');
  const config = { headers: { 'content-type': 'multipart/form-data' } };
  const fileKey = stringBasing(file.preview);
  return function uploadFileThunk(dispatch: Function): any {
    dispatch(uploadFileRequest(fileKey, file.name, file.preview, instance));

    return axios
      .post(endpoint, form, config)
      .then((res: Object): any => {
        dispatch(
          uploadFileSuccess({
            data: res.data,
            fileKey,
            instance,
            ...data,
          }),
        );
        if (callback) {
          callback({
            payload: { ...res.data.payload },
            machine: { ...res.data.machine },
          });
        }

        switch (instance) {
          case 'create_single_job_internal':
          case 'create_single_job_project_team':
          case 'create_single_job_tender':
          case 'create_single_job_exchange':
          case 'single_job_exchange':
          case 'single_job_internal':
          case 'single_job_tender':
          case 'single_job_project_team':
            return dispatch(
              addJobDoc({
                file: res.data.payload,
                machine: res.data.machine,
                fileKey,
                instance,
                ...data,
              }),
            );

          case 'create_project_team':
          case 'create_project_internal':
          case 'project_team':
          case 'project_internal':
          case 'static':
            return dispatch(
              addProjectDoc({
                file: res.data.payload,
                machine: res.data.machine,
                fileKey,
                instance,
                ...data,
              }),
            );

          case 'account_documents':
            return dispatch(
              addAccountDoc({
                file: res.data.payload,
                machine: res.data.machine,
                fileKey,
                instance,
                ...data,
              }),
            );

          case 'create_offer':
          default:
            return true;
        }
      })
      .catch((err: Object): any => {
        dispatch(
          uploadFileFailed(
            assign(err, {
              fileKey,
              instance,
              code: err.status,
              dispatch,
            }),
          ),
        );
      });
  };
}

function uploadFileRequest(
  fileKey: string,
  fileName: string,
  fileBlob: any,
  instance: string,
): Object {
  return {
    type: fetching,
    fileName,
    fileKey,
    fileBlob,
    instance,
  };
}

function addJobDoc(params: Object): Object {
  return { type: ADD_JOB_DOC, ...params };
}

function addProjectDoc(params: Object): Object {
  return { type: PROJECT_ADD_DOC, ...params };
}

function addAccountDoc(params: Object): Object {
  return { type: 'ACCOUNTS_ADD_DOC', ...params };
}

function uploadFileSuccess(params: Object): Object {
  return { type: success, ...params };
}

function uploadFileFailed({
  fileKey,
  instance,
  code,
  dispatch,
}: {
  fileKey: string,
  instance: string,
  code: number,
  dispatch: Function,
}): Object {
  window.setTimeout((): any => dispatch(resetErrorMessage(instance)), 10000);
  return {
    type: failed,
    fileKey,
    instance,
    code,
  };
}

function resetErrorMessage(instance: string): any {
  return { type: 'RESET_UPLOAD_ERROR_MESSAGE', instance };
}
