import React from 'react';
import GetSvg from 'components/GetSvg';
import Card from 'components/Card';
import l from 'helpers/locale';

type PropsShape = {
  data: {
    rating: string,
  },
};

const RatingPanel = ({ data }: PropsShape): React$Element<*> => (
  <Card title={`${l('JOB-rating-title')}`} isOpen qeId="job_card-rating">
    <div className="rating-panel__body fl-vh-center">
      {[1, 2, 3, 4, 5].map((item: number, k: number): React$Element<*> => (
        <span key={item} className="rating-panel__body__star">
          <span className="star-btn--disabled">
            <GetSvg
              key={k}
              svg={`star${item <= data.rating ? '_full' : '_empty'}`}
            />
          </span>
        </span>
      ))}
    </div>
    <div className="fl-vh-center">{l(`RATING_${data.rating}`)}</div>
  </Card>
);

export default RatingPanel;
