import React from 'react';
import Panel from './ratingPanel.js';

type PropsShape = {
  data: {
    rating: string | number,
  },
};

const RatingPanelWrapper = ({ data }: PropsShape): React$Element<*> => {
  if (!Object.prototype.hasOwnProperty.call(data, 'rating')) return null;
  return (
    <React.Fragment>
      {data.rating !== 0 && (
        <div>
          <Panel data={data} />
        </div>
      )}
    </React.Fragment>
  );
};

export default RatingPanelWrapper;
