import React from 'react';
import MapComp from 'components/Map/SafeMap';
import l, { fC } from 'helpers/locale';
import { parseDate } from 'helpers/dates';

const MainCard = ({
  admin_start_date,
  owner_name_3,
  purchase_date,
  renovation_years,
  jobs_count,
  tenants_count,
  construction_year,
  post_city,
  type,
  plot_volume,
  plot_size,
  location,
  current_year_payments_volume,
  current_year,
}) => (
  <div className="push--bottom">
    <div className="bgc--ntrl-min">
      <div className="gw">
        <div className="g g-1/2">
          <div className="soft--small">
            <div className="gw">
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-zip')}
                  </label>
                  <p className="text--dk--flushed--ff-alt">
                    {post_city || '-'}
                  </p>
                </div>
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-type')}
                  </label>
                  <p className="text--dk--flushed--ff-alt">{type || '-'}</p>
                </div>
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-owner')}
                  </label>
                  <p className="text--dk--flushed--ff-alt">
                    {owner_name_3 || '-'}
                  </p>
                </div>
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-purchase')}
                  </label>
                  <p className="text--dk--flushed--ff-alt">
                    {parseDate(purchase_date, 'LL')}
                  </p>
                </div>
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-construction_year')}
                  </label>
                  <p className="text--dk--flushed--ff-alt">
                    {construction_year || '-'}
                  </p>
                </div>
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-renovations')}
                  </label>
                  <p className="text--dk--flushed--ff-alt">
                    {renovation_years || '-'}
                  </p>
                </div>
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-volume_sia')}
                  </label>
                  <p className="text--dk--flushed--ff-alt">
                    {plot_volume ? parseInt(plot_volume, 10) : '-'} m3
                  </p>
                </div>
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-land_area')}
                  </label>
                  <p className="text--dk--flushed--ff-alt">
                    {plot_size ? `${parseInt(plot_size, 10)} m2` : '-'}
                  </p>
                </div>
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-managed_since')}
                  </label>
                  <p className="text--dk--flushed--ff-alt">
                    {admin_start_date ? parseDate(admin_start_date, 'LL') : '-'}
                  </p>
                </div>
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-tenants')}
                  </label>
                  <p className="text--dk--flushed--ff-alt">
                    {tenants_count || '-'}
                  </p>
                </div>
              </div>
              <div className="g g-1/1">
                <hr />
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-order_volume')}
                  </label>
                  <p className="text--dkst--flushed--largest">
                    {fC(current_year_payments_volume)}
                  </p>
                </div>
              </div>
              <div className="g g-1/2">
                <div>
                  <label className="form__label--small" htmlFor="__">
                    {l('HOUSE-jobs')} {current_year}
                  </label>
                  <p className="text--dkst--flushed--largest">
                    {jobs_count || '-'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="g g-1/2">
          <MapComp
            size={{ width: 400, height: 350 }}
            zoom={15}
            center={location}
            markers={[
              {
                ...location,
                color: 'blue',
              },
            ]}
          />
        </div>
      </div>
    </div>
  </div>
);

export default MainCard;
