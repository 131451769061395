/** @format */

// @flow

import React from 'react';
import Panel from './budgetPanel';
import type { DataShape } from './budgetPanel.js';

type KeysList = any; // @TODO

type PropsShape = {
  editNow: boolean,
  updateField: KeysList => (string | number) => any,
  data: DataShape,
};

const BudgetPanelWrapper = ({
  editNow,
  updateField,
  data,
}: PropsShape): React$Element<*> => (
  <Panel data={data} updateField={updateField} isEditing={editNow} />
);

BudgetPanelWrapper.defaultProps = {
  editNow: false,
  updateField: false,
};

export default BudgetPanelWrapper;
