import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import l from 'helpers/locale';
import ComplianceForm from 'components/ComplianceForm';
import GraphQLPageReady from 'components/GraphQLPageReady';
import { transitions as COMPLIANCE_TRANSITIONS } from 'constants/complianceStates.js';
import { setListItem } from 'components/ConnectedActionBar/actions';
import { setStatusBar } from '../../actions/app';
import graphQuery from './queries/initializeCompliance.js';

function useContractorActions(data) {
  const [canAct, setAct] = React.useState();
  const [availableTransition, setTransitions] = React.useState();
  const transitions =
    data?.vmp_contractor?.compliance.state_machine.available_transitions || [];

  React.useEffect(() => {
    if (transitions) {
      const action = transitions.find(
        e => e.name === COMPLIANCE_TRANSITIONS.submit,
      );
      setAct(action !== undefined);
      setTransitions(action);
    }
  }, [transitions]);

  return [canAct, availableTransition];
}

function ComplianceQuestionnaire({ removeNotification, showActionBarMessage }) {
  const [data, setData] = React.useState();
  const [canAct, transition] = useContractorActions(data);

  function onSuccess() {
    removeNotification();
    showActionBarMessage();
    return true;
  }

  return (
    <div className="compliance-questionnaire">
      <GraphQLPageReady
        queryId="getInitialCompliance"
        queryString={graphQuery()}
        onData={response => setData(response.data.data)}>
        {canAct ? (
          <ComplianceForm
            version="version_1"
            data={data.vmp_contractor}
            api={transition.action}
            emitSuccessSubmit={() => onSuccess()}
          />
        ) : (
          <div
            className="align--center fw--bold"
            data-qe-id="compliance-not_allowed">
            {data?.vmp_contractor === null ? (
              <Redirect to="/" />
            ) : (
              l('CONTRACTOR_COMPLIANCE-not_allowed')
            )}
          </div>
        )}
      </GraphQLPageReady>
    </div>
  );
}

const mapState = ({ appStore }) => ({ locale: appStore.locale });

const mapDispatch = dispatch => {
  return {
    removeNotification() {
      return dispatch(setStatusBar(''));
    },
    showActionBarMessage() {
      return dispatch(
        setListItem(
          'contractor_dashboard',
          'ACTION_BAR-contractor_compliance_submission',
        ),
      );
    },
  };
};

export default connect(mapState, mapDispatch)(ComplianceQuestionnaire);
