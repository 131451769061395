import React from 'react';
import l from 'helpers/locale';
import orderObjectByKeys from 'functions/orderObjectByKeys';

function formatFields(contact, keyOrder) {
  if (Array.isArray(contact)) {
    return contact;
  }
  if (Object.keys(contact).length) {
    return orderObjectByKeys(contact, keyOrder).filter(value => !!value);
  }
  return [];
}

function useContactFields(contact, keyOrder) {
  const [fields, setFields] = React.useState([]);
  React.useEffect(() => {
    setFields(formatFields(contact, keyOrder));
  }, [contact, setFields, keyOrder]);
  return fields;
}

function ContactBody({ contact, keyOrder }) {
  // @todo - name is not being saved in database correctly
  const fields = useContactFields(contact, keyOrder);

  if (contact === null) return null;

  return (
    <p className="text--dk--flushed">
      {fields.length ? (
        fields.map((value, key) => (
          <React.Fragment key={key}>
            <span>{value}</span>
            <br />
          </React.Fragment>
        ))
      ) : (
        <span>{l('RESULT-none')}</span>
      )}
    </p>
  );
}

ContactBody.defaultProps = {
  keyOrder: [
    'company',
    'full_name',
    'street',
    'postal_code.city',
    'email',
    'telephone',
    'cellphone',
  ],
};

export default ContactBody;
