import Machine, { State } from 'async-state-machine';
import redux from '../store';

class ReduxMachine extends Machine {
  machineKey: string;

  constructor(reduxEntry: string = null, enableLog: boolean = false) {
    super(enableLog);
    if (reduxEntry === null)
      throw new Error('reduxEntry is required in constructor');
    this.machineKey = reduxEntry;
  }

  log(message) {
    super.log(`${this.machineKey}: ${message}`);
  }

  storeRedux(newState) {
    redux.dispatch({
      type: 'UPDATE_REDUX_MACHINE',
      payload: { machine: this.machineKey, newState },
    });
  }

  setState(nextState: State) {
    super.setState(nextState);
    this.storeRedux(nextState ? nextState.toString() : '');
  }
}

export default ReduxMachine;
