export default function getLabel() {
  let careTakerCounter = 0;
  let tenantCounter = 0;

  return contact => {
    /*
     * this only exists because we need to append the index
     * of the care taker contact so that we can get the correct translation
     * ...... and tenants :/
     */

    if (contact.type === 'care_taker') {
      careTakerCounter += 1;
      return `CONTACT_LIST-care_taker_${careTakerCounter}`;
    }

    if (contact.type === 'tenant') {
      tenantCounter += 1;

      // we only have translations for 4 tenants
      return tenantCounter < 5
        ? `CONTACT_LIST-tenant_${tenantCounter}`
        : `CONTACT_LIST-tenant-many`;
    }

    return `CONTACT_LIST-${contact.type}`;
  };
}
