// @flow
import { scrollToElement } from 'helpers/smoothScrollTo';

// recursively look for a parent with overflowY set to 'scroll'
function findParentToScrollOn(base, offsetTop = 0) {
  if (!base) {
    return null;
  }

  const styles = window.getComputedStyle(base);
  const offset = offsetTop + base.offsetTop;

  if (styles.overflowY === 'scroll') {
    return base;
  }

  return findParentToScrollOn(base.offsetParent, offset);
}

export default function scroll(target: HTMLElement): null {
  let parent = findParentToScrollOn(target);
  if (!parent) {
    // no parents were found, use a default element to scroll on
    [parent] = document.getElementsByClassName('app-layout__body__main');
  }

  scrollToElement(parent);
  return null;
}
