import axios from 'helpers/axios';
import history from 'helpers/history';
import { matchPath } from 'react-router-dom';
import { JobMachine } from 'views/singleJob';
import { transitions } from 'machines/constants';
import { resetJob } from 'views/singleJob/actions';
import Constants from '../constants';

const {
  back,
  copy,
  cancel,
  grant,
  resend_grant,
  erase,
  complete,
  createOffer,
  execute,
  accept,
  requestOffers,
  noInterest,
  inviteContractor,
  addSingleContractor,
} = Constants;

export function jobActions(jobId) {
  return {
    back() {
      history.goBack();
      return dispatch =>
        dispatch({
          type: back,
        });
    },
    copy(_, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/copy`,
          { ...copy },
          undefined,
          ({ nextJobId, projectId }) => {
            if (
              matchPath(history.location.pathname, {
                path: `/projects/:project_id/:job_id`,
                exact: true,
              })
            ) {
              history.push(`/projects/${projectId}/${nextJobId}`);
            } else {
              history.push(`/jobs/${nextJobId}`);
              refreshList();
            }
          },
        );
    },
    grant(props, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/grant`,
          { ...grant },
          props,
          refreshList,
        );
    },
    resend_grant(props, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/resend_grant_notifications`,
          { ...resend_grant },
          props,
          refreshList,
        );
    },
    resend_offer_grant(props, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/resend_grant_notifications`,
          { ...resend_grant },
          props,
          refreshList,
        );
    },
    requestOffers(props, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/request`,
          { ...requestOffers },
          props,
          refreshList,
        );
    },
    erase(_, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/delete`,
          { ...erase },
          undefined,
          () => {
            refreshList();
            history.push('/jobs');
          },
        );
    },
    cancelEdit() {
      return dispatch => {
        dispatch(resetJob());
        JobMachine.triggerTransition(transitions.to_visible);
      };
    },
    change() {
      return async () => {
        await JobMachine.triggerTransition(transitions.to_update, {
          job_id: jobId,
        });
      };
    },
    cancel(_, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/cancel`,
          {
            ...cancel,
          },
          undefined,
          refreshList,
        );
    },
    complete(_, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/complete`,
          { ...complete },
          undefined,
          refreshList,
        );
    },
    createOffer(_, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/`, // @todo
          { ...createOffer },
          null,
          refreshList,
        );
    },
    execute(_, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/execute`,
          { ...execute },
          null,
          refreshList,
        );
    },
    accept(_, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          `/api/1/jobs/${jobId}/action/accept`,
          { ...accept },
          null,
          refreshList,
        );
    },
    noInterest(props, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          props.api,
          { ...noInterest },
          null,
          refreshList,
        );
    },
    edit() {
      return () => JobMachine.triggerTransition(transitions.to_editing);
    },
    inviteSuccess(localeString) {
      return dispatch =>
        dispatch({
          type: inviteContractor.success,
          payload: localeString,
        });
    },
    singleOfferRequest(props, refreshList) {
      return dispatch =>
        jobRequest(
          dispatch,
          jobId,
          props.api,
          { ...addSingleContractor },
          props.payload,
          refreshList,
        );
    },
  };
}

function jobRequest(
  dispatch,
  jobId,
  endpoint,
  { fetching, success, failed },
  additional = {},
  callback = () => true,
) {
  dispatch({ type: fetching, jobId });
  return axios
    .post(endpoint, {
      jobId,
      ...additional,
    })
    .then(res => {
      dispatch({
        type: success,
        jobId,
        payload: res.data,
      });
      return callback({
        jobId,
        nextJobId: res.data.payload.job_id,
        projectId: res.data.payload.project_id,
      });
    })
    .catch(err =>
      dispatch({
        type: failed,
        jobId,
        payload: err,
      }),
    );
}
