const CONTRACTOR_VIEW = ':contractor_id(\\d+)';
const CONTRACTOR_INVITE = 'invite';
const CONTRACTOR_JOB = 'jobs/:job_id(\\d+)';
const CONTRACTOR_PROPERTY = 'properties/:property_id(\\d+)';
const INVITE = 'nomination';
const CONTRACTOR_PROFILE_VIEW = ':contractor_id(\\d+)/profile';
const ROOT_PROFILE_VIEW = 'profile/view';

export const Names = {
  CONTRACTOR_VIEW,
  CONTRACTOR_INVITE,
  CONTRACTOR_JOB,
  CONTRACTOR_PROPERTY,
  INVITE,
  CONTRACTOR_PROFILE_VIEW,
  ROOT_PROFILE_VIEW,
};
