/** @format */

// @flow
import { ids as rid } from 'constants/roles'; // role id
import { decodeJwt } from 'functions/authenticator.func.js';

export type ViewPermissionsType = Array<ViewPermissionsItemType>;

export type ViewPermissionsItemType = {
  name: string,
  title: string,
  icon: string,
  allowed: Array<number> | 'jwt',
  route?: string,
};

const viewPermission: ViewPermissionsType = [
  {
    name: 'dashboard', // name according to /views/<NAME>
    title: 'MENU-dashboard', // title that must be localized
    sidebar: true, // should display in sidebar
    icon: 'dashboard', // used to render in sidebar
    allowed: [
      rid.ADMINISTRATION,
      rid.CENTRAL_PROCUREMENT,
      rid.PROPERTY_MANAGER,
      rid.AGENCY_MANAGER,
      rid.CONTRACTOR,
      rid.CONSTRUCTION_MANAGER,
      rid.HEAD_CONSTRUCTION_MANAGER,
      rid.ACCOUNT_MANAGER,
    ], // list of ids to allow, [] = all, [0] = none
  },
  {
    name: 'projects',
    title: 'PROJECT-plural',
    sidebar: true,
    icon: 'project',
    allowed: 'jwt',
  },
  {
    name: 'jobs',
    title: 'MENU-jobs',
    sidebar: true,
    icon: 'job',
    allowed: [
      rid.ADMINISTRATION,
      rid.CENTRAL_PROCUREMENT,
      rid.PROPERTY_MANAGER,
      rid.CONTRACTOR,
      rid.CONSTRUCTION_MANAGER,
      rid.HEAD_CONSTRUCTION_MANAGER,
      rid.ACCOUNT_MANAGER,
    ],
  },
  {
    name: 'properties',
    title: 'MENU-properties',
    sidebar: true,
    icon: 'house',
    allowed: [
      rid.ADMINISTRATION,
      rid.CENTRAL_PROCUREMENT,
      rid.PROPERTY_MANAGER,
      rid.CONSTRUCTION_MANAGER,
      rid.HEAD_CONSTRUCTION_MANAGER,
      rid.ACCOUNT_MANAGER,
    ],
  },
  {
    name: 'contractors',
    title: 'MENU-contractors',
    sidebar: true,
    icon: 'contractor',
    allowed: [
      rid.ADMINISTRATION,
      rid.CENTRAL_PROCUREMENT,
      rid.PROPERTY_MANAGER,
      rid.AGENCY_MANAGER,
      rid.CONSTRUCTION_MANAGER,
      rid.HEAD_CONSTRUCTION_MANAGER,
      rid.ACCOUNT_MANAGER,
    ],
  },
  {
    name: 'accounts',
    title: 'MENU-accounts',
    sidebar: true,
    icon: 'settings',
    allowed: [rid.ADMINISTRATION, rid.CENTRAL_PROCUREMENT],
  },
  {
    name: 'nominations',
    title: 'MENU-nominations',
    sidebar: true,
    icon: 'nominee',
    allowed: [
      rid.ADMINISTRATION,
      rid.CENTRAL_PROCUREMENT,
      rid.PROPERTY_MANAGER,
      rid.AGENCY_MANAGER,
      rid.CONSTRUCTION_MANAGER,
      rid.HEAD_CONSTRUCTION_MANAGER,
      rid.ACCOUNT_MANAGER,
    ],
  },
];

function canViewItem(
  item: ViewPermissionsItemType,
  role_id: number,
  token?: string,
): boolean {
  if (Array.isArray(item.allowed)) {
    return item.allowed.includes(role_id) || item.allowed.length === 0;
  }
  if (item.allowed === 'jwt') {
    /*
     * let's handle cases where we need to get data from jwt
     * this is a naive approach, we can implement this better
     * in future if this is a feature that is extended on
     */

    const decodedJwt = decodeJwt(token);
    const allowed_pages = decodedJwt?.allowed_pages || [];
    return allowed_pages.includes(item.name);
  }
  return false;
}

// returns if this component for this role id is allowed to view this component
export function isAllowed(id: number, component: string): boolean {
  return (
    viewPermission.filter(
      (i: ViewPermissionsItemType): boolean =>
        i.name === component && canViewItem(i, id),
    ).length > 0
  );
}

// returns a list of component names from a list of a component names which
// are allowed to be viewed by this role id
export function isAllowedMulti(
  id: number,
  components: Array<string>,
): Array<string> {
  return components.filter((i: string): boolean => isAllowed(id, i));
}

// returns a list of allowed components for this role id
export function allowedViews(
  id: number,
  userToken: string,
): ViewPermissionsType {
  return viewPermission.filter((i: ViewPermissionsItemType): boolean =>
    canViewItem(i, id, userToken),
  );
}
