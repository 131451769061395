/** @format */

import generator from '../../../constants/generator';

const viewAccounts = generator('ACCOUNTS_VIEW');
const createAccounts = generator('ACCOUNTS_CREATE');
const updateAccounts = generator('ACCOUNTS_UPDATE');

const ACCOUNTS_ADD_DOC = 'ACCOUNTS_ADD_DOC';
const ACCOUNTS_GET_DOCS = 'ACCOUNTS_GET_DOCS';
const ACCOUNTS_REMOVE_DOC = 'ACCOUNTS_REMOVE_DOC';

export default {
  viewAccounts,
  createAccounts,
  updateAccounts,
  ACCOUNTS_ADD_DOC,
  ACCOUNTS_GET_DOCS,
  ACCOUNTS_REMOVE_DOC,
};
