/** @format */

// @flow
import React from 'react';
import Card from 'components/Card';
import l, { fC } from 'helpers/locale';

import InputAttachment from 'components/Form/FormInputAttachment';

export type DataShape = {
  budget: number | string,
  remaining_budget: number,
};

const BudgetPanel = ({
  data,
  isEditing,
  updateField,
}: {
  data: DataShape,
  isEditing: boolean,
  updateField: Function,
}) => (
  <Card title="BUDGET-title" isOpen={false} canCollapse qeId="job_card-budget">
    <div className="gw">
      <div className="g g-1/2">
        <label className="form__label--small" htmlFor="__">
          {l('BUDGET-estimate')}
        </label>
        {isEditing ? (
          <InputAttachment attach text="CHF" toLeft>
            <input
              data-qe-id="input-edit-estimated_budget"
              className="form__input"
              type="number"
              name="budget_estimate"
              value={data.budget}
              onChange={(e: Object): any => {
                let value = parseInt(e.target.value, 10);
                if (Number.isNaN(value)) {
                  value = '';
                }
                return updateField({
                  key: 'budget_estimate',
                  value,
                });
              }}
            />
          </InputAttachment>
        ) : (
          <p className="text--dk--flushed">{fC(data.budget)}</p>
        )}
      </div>
      <div className="g g-1/2">
        <label className="form__label--small" htmlFor="__">
          {l('BUDGET-remainder')}
        </label>
        <p className="text--dk--flushed">{fC(data.remaining_budget)}</p>
      </div>
    </div>
  </Card>
);

BudgetPanel.defaultProps = {
  isEditing: false,
};

export default BudgetPanel;
