import ContactsCard from './ContactsCard';
import CustomerCard from './CustomerCard';
import JobsCard from './JobsCard';
import MainCard from './MainCard';
import PropertyCard from './PropertyCard';
import NotificationsCard from './NotificationsCard';
import RatingsCard from './RatingsCard';
import ComplianceQuestionnaireCard from './ComplianceQuestionnaireCard';

export default {
  ContactsCard,
  CustomerCard,
  JobsCard,
  MainCard,
  PropertyCard,
  NotificationsCard,
  RatingsCard,
  ComplianceQuestionnaireCard,
};
