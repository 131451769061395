/** @format */

// @flow

export const ids = {
  CANCELLED: -1,
  DRAFT: 0,
  OFFERS_REQUESTED: 1,
  OFFERS_RECEIVED: 3,
  GRANTED: 4,
  ACCEPTED: 5,
  EXECUTED: 6,
  RATED: 7,
  META_GRANTED_TO_COMPETITOR: 8,
  IMPLICIT_CONTRACTOR_NO_INTEREST: 9,
  COMPLETED: 10,
};

export default {
  StatusIDs: ids,
};
