import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'components/Card';
import SimpleList, {
  SimpleListItemArrayFormatter,
} from 'components/ConnectedSimpleList';
import l from 'helpers/locale';

type PropsShape = {
  apiEndpoint: string,
  history: Object,
  match: Object,
  listName: string,
  enableDetailView: boolean,
};

type StateShape = {
  parentMatch: Object,
};

class JobsCard extends React.Component<PropsShape, StateShape> {
  openJob = ({ job_id_raw: id }): any => {
    this.props.history.push(
      `/contractors/${this.props.match.params.contractor_id}/jobs/${id}`,
    );
  };

  render(): React$Element<*> {
    const extras = this.props.enableDetailView
      ? {
          clickAct: this.openJob,
          bgcAlt: true,
        }
      : {};

    return (
      <Fragment>
        <Card title="JOB-plural" isOpen>
          <SimpleList
            api={this.props.apiEndpoint}
            {...extras}
            name={this.props.listName}
            headings={[
              {
                name: 'job_id',
                sortable: true,
                text: l('JOB-COL-id'),
              },
              {
                name: 'description',
                sortable: false,
                text: l('JOB-COL-description'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'object',
                sortable: true,
                text: l('JOB-COL-object'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'last_update',
                sortable: true,
                text: l('JOB-COL-last_update'),
              },
              {
                name: 'status',
                sortable: true,
                text: l('JOB-COL-status'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
            ]}
            showDatepicker
            allowClick={this.props.role !== 4}
          />
        </Card>
      </Fragment>
    );
  }
}

const mapState = (state: any): Object => ({
  role: state.authStore.user.role,
});

export default withRouter(connect(mapState)(JobsCard));
