/** @format */

import {
  NOMINATION_CREATED,
  NOMINATION_EXPORTED,
  NOMINATION_DECLINED,
  NOMINATION_LINKED,
  NOMINATION_DELETED,
  NOMINATIONS_EXPORTING,
  NOMINATION_MAIL_RESENT,
  INVITATION_MAIL_RESENT,
} from '../constants';

export function nominationCreated() {
  return { type: NOMINATION_CREATED };
}

export function nominationExported() {
  return { type: NOMINATION_EXPORTED };
}

export function nominationDeclined() {
  return { type: NOMINATION_DECLINED };
}

export function nominationLinked(payload) {
  return { type: NOMINATION_LINKED, payload };
}

export function nominationDeleted() {
  return { type: NOMINATION_DELETED };
}

export function nominationsExporting() {
  return { type: NOMINATIONS_EXPORTING };
}

export function nominationMailResent() {
  return { type: NOMINATION_MAIL_RESENT };
}

export function invitationMailResent() {
  return { type: INVITATION_MAIL_RESENT };
}
