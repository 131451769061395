/** @format */

// @flow

import React from 'react';
import { connect } from 'react-redux';
import { SimplePopup } from '@bonlineza/b-lib';

import ActionRules from '../helpers/actionRules';
import l from '../../../../../helpers/locale';
import OfferContext from '../context';

type PropsShape = {
  declineOfferAction: Function,
  toggleVisibility: Function,
  userRole: number,
};

const DeclineOffer = ({
  declineOfferAction,
  toggleVisibility,
  userRole,
}: PropsShape) => (
  <ActionRules view="decline_offer" role={userRole}>
    {() => (
      <OfferContext.Consumer>
        {offer => (
          <SimplePopup
            isOpen={offer.visibleDeclineOfferModal}
            close={toggleVisibility.bind(null, 'DeclineOfferModal')}
            title={l('OFFER_FORM-decline-title')}
            description={l('OFFER_FORM-decline-body')
              .replace(':offer_name', offer.payload.title)
              .replace(':contractor_name', offer.payload.participant)}
            options={[
              {
                cb: () => {
                  toggleVisibility('DeclineOfferModal');
                },
                buttonText: l('cancel'),
                buttonClass: 'btn--text',
                dataQeId: 'action-decline-offer-cancel',
              },
              {
                cb: () => {
                  declineOfferAction();
                },
                buttonText: l('ACTION-decline'),
                buttonClass: 'btn--primary',
                dataQeId: 'action-decline-offer-confirm',
              },
            ]}
          />
        )}
      </OfferContext.Consumer>
    )}
  </ActionRules>
);

const mapStateToProps = state => ({
  userRole: state.authStore.user.role,
});

export default connect(mapStateToProps)(DeclineOffer);
