import axios from 'helpers/axios.js';

import { getConcern } from '../query';

export const CLEAR_SUB_REQUEST = 'CLEAR_SUB_REQUEST';
export const PROJECT_SET_DATA = 'PROJECT_SET_DATA';
export const PROJECT_FLUSH_STATE = 'PROJECT_FLUSH_STATE';
export const PROJECT_RESET_STATE = 'PROJECT_RESET_STATE';
export const PROJECT_UPDATE_PLACE = 'PROJECT_UPDATE_PLACE';
export const PROJECT_FORM_UPDATE = 'PROJECT_FORM_UPDATE';
export const PROJECT_FORM_UPDATE_CONTACTS = 'PROJECT_FORM_UPDATE_CONTACTS';
export const PROJECT_FORM_ADD_CUSTOM_CONTACT =
  'PROJECT_FORM_ADD_CUSTOM_CONTACT';
export const PROJECT_REMOVE_DOC = 'SINGLE_PROJECT_REMOVE_PROJECT_DOC';
export const PROJECT_ADD_DOC = 'SINGLE_PROJECT_ADD_PROJECT_DOC';

export const CREATE_PROJECT_SUPPORT_ADD = 'CREATE_PROJECT_SUPPORT_ADD';
export const CREATE_PROJECT_SUPPORT_REMOVE = 'CREATE_PROJECT_SUPPORT_REMOVE';
export const CREATE_PROJECT_SUPPORT_CHANGE = 'CREATE_PROJECT_SUPPORT_CHANGE';

export const CREATE_PROJECT_OWNER_CHANGE = 'CREATE_PROJECT_OWNER_CHANGE';

export const PROJECT_SUPPORT_ADD = 'PROJECT_SUPPORT_ADD';
export const PROJECT_SUPPORT_REMOVE = 'PROJECT_SUPPORT_REMOVE';
export const PROJECT_SUPPORT_CHANGE = 'PROJECT_SUPPORT_CHANGE';

export const PROJECT_OWNER_CHANGE = 'PROJECT_OWNER_CHANGE';

export const PROJECT_UPDATE_CONCERN = 'PROJECT_UPDATE_CONCERN';

export const setData = payload => ({
  type: PROJECT_SET_DATA,
  payload,
});

export function flushState() {
  return { type: PROJECT_FLUSH_STATE };
}

export function resetProject() {
  return { type: PROJECT_RESET_STATE };
}

export function updatePlace(concern: Object) {
  return {
    type: PROJECT_UPDATE_PLACE,
    payload: { concern },
  };
}

export function updateForm({ key, value }: { key: string, value: string }) {
  return { type: PROJECT_FORM_UPDATE, payload: { key, value } };
}

export function getNewConcern(type, id): Function {
  return dispatch => {
    getConcern(type, id).then(payload => {
      dispatch({ type: PROJECT_UPDATE_CONCERN, payload });
    });
  };
}

export function updateContactProjectFormData(
  target: string,
  value: any,
): Object {
  return {
    type: PROJECT_FORM_UPDATE_CONTACTS,
    payload: { key: target, value },
  };
}

export function addCustomContactToProject(target: string, value: any): Object {
  return {
    type: PROJECT_FORM_ADD_CUSTOM_CONTACT,
    payload: { key: target, value },
  };
}

export function removeProjectDocument(
  id: string | number,
  target: 'project_team' | 'internal',
  projectId: string | number,
  dispatch: Function,
  creation: boolean,
  api: string,
): any {
  if (creation) {
    return dispatch({
      type: PROJECT_REMOVE_DOC,
      target: 'form',
      payload: {
        id,
        target,
      },
    });
  }

  return id !== -1
    ? axios
        .delete(api)
        .then((): any =>
          dispatch({
            type: PROJECT_REMOVE_DOC,
            target: 'data',
            payload: {
              target,
              id,
            },
          }),
        )
        .catch((): any => dispatch({ type: 'ERROR' }))
    : false;
}

// target: create | update
export function projectSupportAdd(user, context = 'create') {
  if (context === 'create') {
    return { type: CREATE_PROJECT_SUPPORT_ADD, payload: user };
  }
  // context === 'update'
  return { type: PROJECT_SUPPORT_ADD, payload: user };
}

export function projectSupportRemove(user, context = 'create') {
  if (context === 'create') {
    return { type: CREATE_PROJECT_SUPPORT_REMOVE, payload: user };
  }
  // context === 'update'
  return { type: PROJECT_SUPPORT_REMOVE, payload: user };
}

export function projectSupportChange(oldUser, newUser, context = 'create') {
  if (context === 'create') {
    return {
      type: CREATE_PROJECT_SUPPORT_CHANGE,
      payload: { new: newUser, old: oldUser },
    };
  }

  // context === 'update'
  return {
    type: PROJECT_SUPPORT_CHANGE,
    payload: { new: newUser, old: oldUser },
  };
}

export function projectOwnerChange(user, context = 'create') {
  if (context === 'create') {
    return {
      type: CREATE_PROJECT_OWNER_CHANGE,
      payload: user,
    };
  }

  // context === 'update'
  return {
    type: PROJECT_OWNER_CHANGE,
    payload: user,
  };
}
