/** @format */

// @flow

import React from 'react';
import type { DocumentType } from 'views/singleJob/constants';
import Panel from '../GenericDocuments';

type PropsShape = {
  editNow?: boolean,
  listName: string,
  list?: [DocumentType],
  data: any,
  controls: Array<*>,
};

const TenderDocumentsPanelWrapper = ({
  editNow,
  data,
  list,
  listName,
  controls,
}: PropsShape): React$Element<*> => (
  <Panel
    isEditing={editNow}
    data={data}
    listName={listName}
    list={list}
    jobId={data.jobId}
    controls={controls}
    title="TENDER_DOCS-title"
    identifier="action-drop-tender_document"
    isOpen={false}
    footer="TENDER_DOCS-description"
  />
);

TenderDocumentsPanelWrapper.defaultProps = {
  editNow: false,
  list: [],
};

export default TenderDocumentsPanelWrapper;
