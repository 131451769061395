import React, { Fragment, useState } from 'react';
import Card from 'components/Card';
import MapComp from 'components/Map/SafeMap';
import Chart from 'components/Chart';
import l, { fC } from 'helpers/locale';
import PageReady from 'components/AllianzPageReady';
import GraphQLPageReady from 'components/GraphQLPageReady';
import convPropToInt from 'functions/convPropToInt.func';
import getDashboardUserDocuments from '../../query';
import DashboardItem from '../../components/DashboardItem.js';
import DashboardDocuments from '../../components/DashboardDocuments.js';

const AdminDashboard = () => {
  const [data, setData] = useState({
    statistics: {},
    map: {},
  });

  const [user, setUser] = useState({
    dashboard_user: {},
  });

  return (
    <GraphQLPageReady
      onData={res => setUser(res.data.data)}
      queryId="getDashboardUserDocuments"
      queryString={getDashboardUserDocuments()}>
      <PageReady
        url="/api/1/view/dashboard"
        onData={res => setData(res.data.payload)}>
        <Fragment>
          <Card title="ADM_DASHBOARD-latest_contracts" canCollapse={false}>
            <div style={{ height: '350px', overflow: 'hidden' }}>
              <MapComp
                markers={
                  data.map?.jobs
                    ? [
                        ...data.map.jobs.map((item: Object): Object => ({
                          ...item,
                          color: 'blue',
                        })),
                      ]
                    : []
                }
              />
            </div>
          </Card>
          <Card title="ADM_DASHBOARD-contractor_turnover" canCollapse={false}>
            <div className="gw">
              <div className="g--tall g-1/2">
                <DashboardItem>
                  <label className="form__label--small" htmlFor="__">
                    {l('ADM_DASHBOARD-top_10_last_quarter')}
                  </label>
                  {data.statistics.contractors?.entries?.length > 0 ? (
                    <Chart
                      data={data.statistics.contractors.entries.map(
                        convPropToInt('total'),
                      )}
                      numberKey="total"
                      categoryKey="name"
                    />
                  ) : null}
                </DashboardItem>
              </div>
              <div className="g--tall g-1/2">
                <div className="gw">
                  <div className="g--tall g-1/1">
                    <DashboardItem>
                      <label className="form__label--small" htmlFor="__">
                        {l('ADM_DASHBOARD-volume_year_recommended_contractors')}
                      </label>
                      <p className="text--dk--flushed--large">
                        {data.statistics.contractors?.volume
                          ? fC(data.statistics.contractors.volume)
                          : 'CHF -'}
                      </p>
                    </DashboardItem>
                  </div>
                  <div className="g--tall g-1/1">
                    <DashboardItem>
                      <label className="form__label--small" htmlFor="__">
                        {l('ADM_DASHBOARD-jobs_year_recommended_contractors')}
                      </label>
                      <p className="text--dk--flushed--large">
                        {data.statistics.contractors?.count
                          ? data.statistics.contractors.count
                          : '-'}
                      </p>
                    </DashboardItem>
                  </div>
                  <div className="g--tall g-1/1">
                    <DashboardItem>
                      <label className="form__label--small" htmlFor="__">
                        {l('DASHBOARD-paid-jobs-by-recommended')}
                      </label>
                      <p className="text--dk--flushed--large">
                        {data.statistics?.paid_jobs_count_recommended
                          ? data.statistics.paid_jobs_count_recommended
                          : '-'}
                      </p>
                    </DashboardItem>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <Card title="ADM_DASHBOARD-trades_turnover" canCollapse={false}>
            <div className="gw">
              <div className="g--tall g-1/2">
                <DashboardItem>
                  <label className="form__label--small" htmlFor="__">
                    {l('DASHBOARD-top_trades_this_year')}
                  </label>
                  {data.statistics.trades?.entries?.length > 0 ? (
                    <Chart
                      data={data.statistics.trades.entries.map(
                        convPropToInt('earnings'),
                      )}
                      numberKey="earnings"
                      categoryKey="name"
                    />
                  ) : null}
                </DashboardItem>
              </div>
              <div className="g--tall g-1/2">
                <div className="gw">
                  <div className="g--tall g-1/1">
                    <DashboardItem>
                      <label className="form__label--small" htmlFor="__">
                        {l('ADM_DASHBOARD-volume_year')}
                      </label>
                      <p className="text--dk--flushed--large">
                        {data.statistics.trades?.volume
                          ? fC(data.statistics.trades.volume)
                          : 'CHF -'}
                      </p>
                    </DashboardItem>
                  </div>
                  <div className="g--tall g-1/1">
                    <DashboardItem>
                      <label className="form__label--small" htmlFor="__">
                        {l('ADM_DASHBOARD-jobs_year')}
                      </label>
                      <p className="text--dk--flushed--large">
                        {data.statistics.trades?.count
                          ? data.statistics.trades.count
                          : '-'}
                      </p>
                    </DashboardItem>
                  </div>
                  <div className="g--tall g-1/1">
                    <DashboardItem>
                      <label className="form__label--small" htmlFor="__">
                        {l('DASHBOARD-paid-jobs')}
                      </label>
                      <p className="text--dk--flushed--large">
                        {data.statistics.contractors &&
                        data.statistics.paid_jobs_count
                          ? data.statistics.paid_jobs_count
                          : '-'}
                      </p>
                    </DashboardItem>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <DashboardDocuments
            documents={user?.dashboard_user?.documents || []}
          />
        </Fragment>
      </PageReady>
    </GraphQLPageReady>
  );
};

export default AdminDashboard;
