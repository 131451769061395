import React, { useState, useEffect, useRef } from 'react';

import GetSvg from 'components/GetSvg';
import l from 'helpers/locale';

function LocalisationReady({ children }) {
  const [hasLocale, setHasLocale] = useState(
    () => l('locale-loaded') === 'passed',
  );
  const [checkLocale, setCheckLocale] = useState(false);

  const interval = useRef(null);
  const counter = useRef(0);

  useEffect(() => {
    // if we are in a headless test
    // or locale does not exist on String
    // or hasLocale is already set to true
    if (typeof window !== 'object' || !String.locale || hasLocale) {
      return setHasLocale(true);
    }

    const { setInterval, clearInterval } = window;

    const id = setInterval(() => {
      const result = l('locale-loaded') === 'passed';
      counter.current += 1;
      // only run 5 iterations
      if (result || counter.current > 5) {
        setHasLocale(true);
        // clear interval so that it doesn't keep running
        clearInterval(interval.current);
      } else {
        // trigger another round of checking
        setCheckLocale(!checkLocale);
      }
    }, 500);
    interval.current = id;

    return () => clearInterval(interval.current);
  }, [checkLocale, hasLocale]);

  return hasLocale ? (
    children
  ) : (
    <div className="display--full-width display--full-height fl-vh-center">
      <GetSvg svg="loading" wrapperClass="loading-spinner--large" />
    </div>
  );
}

export default LocalisationReady;
