import React from 'react';

type Props = {
  children: any,
};

function FooterBar(props: Props) {
  return (
    <div className="footer-bar">
      <div className="footer-bar__inner">{props.children}</div>
    </div>
  );
}

export default FooterBar;
