/** @format */

// @flow
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { requestForgotPassword } from './actions';
import l from '../../helpers/locale';

type PropsShape = {
  success: boolean,
  fetching: boolean,
  requestCB: Function,
};

class ForgotPassword extends Component<PropsShape> {
  constructor(props: Object) {
    super(props);
    this.buttonRef = null;
    this.inputRef = null;
    this.forgotPasswordClicked = this.forgotPasswordClicked.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetching && !this.props.fetching) {
      this.props.requestCB(this.props.success);
    }
  }

  submitHandler(cb: Function): any {
    const payload = {
      email: this.emailRef.value,
    };
    return cb(payload);
  }

  attemptChangePasswordRequest(e: Object): Function {
    e.preventDefault();
    return this.submitHandler(this.props.actions.requestForgotPassword);
  }

  forgotPasswordClicked(): any {
    return this.props.goLogin();
  }

  render(): any {
    return (
      <form onSubmit={e => this.attemptChangePasswordRequest(e)}>
        <div>
          <div className="form__input-container push--bottom">
            <label htmlFor="email" className="form__label--small">
              {l('FORGOT_PASSWORD-email')}
            </label>
            <input
              type="text"
              name="email"
              className="form__input"
              data-qe-id="input-forgot-password-email"
              ref={(input: Object) => {
                this.emailRef = input;
              }}
            />
          </div>
        </div>
        <div className="fl-right">
          <div className="fl-right__item">
            {this.props.goLogin ? (
              <button
                type="button"
                className="btn--text"
                onClick={this.forgotPasswordClicked}>
                <span>
                  {l('FORGOT_PASSWORD-return')}
                  &nbsp;
                </span>
                <span>{l('FORGOT_PASSWORD-login')}</span>
              </button>
            ) : (
              ''
            )}
          </div>
          <div className="fl-right__item">
            <button
              disabled={this.props.fetching}
              type="submit"
              className="btn--primary"
              data-qe-id="submit-forgot-password-form"
              ref={(input: Object) => {
                this.buttonRef = input;
              }}>
              {l('FORGOT_PASSWORD-submit')}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

ForgotPassword.propTypes = {
  goLogin: PropTypes.func,
  actions: PropTypes.object.isRequired,
};

ForgotPassword.defaultProps = {
  goLogin: undefined,
};

const mapState = (state: Object): PropsShape => ({
  success: state.forgotPasswordStore.request.success,
  fetching: state.forgotPasswordStore.request.fetching,
});

const mapActions = (dispatch: any): Object => ({
  actions: bindActionCreators({ requestForgotPassword }, dispatch),
});

export default connect(mapState, mapActions)(ForgotPassword);
