/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import Card from 'components/Card';
import ContactBlock from 'components/GraphQLContactBlock';
import { ContentDisplayRows } from 'components/ContentDisplayer';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import l from 'helpers/locale';

import {
  updateContactProjectFormData,
  addCustomContactToProject,
} from '../../actions';
import MachineBuilder from './machine';

export const AddressesCardMachine = MachineBuilder();

type ReduxStateShape = {
  userType: number | string,
  isEditing: boolean,
};

type ReduxActionsShape = {
  updateContact: string => number => any,
};

const AddressesCard = ({
  addCustomContact,
  updateContact,
  address_owner,
  address_scan_center,
  contact_audit,
  contact_audit_form,
  panelEditMode,
  machine_state,
}) => {
  const auditData = panelEditMode ? contact_audit_form : contact_audit;

  return (
    <StateConditional
      state={machine_state}
      whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
      <Card title="PROJECT-addresses-title" isOpen>
        <div className="gw">
          <div className="g g-1/4">
            <div className="push--small--bottom">
              <label className="form__label--small" htmlFor="">
                {l('PROJECT-address_owner')}
              </label>
              <ContentDisplayRows rows={address_owner} />
            </div>
          </div>
          <div className="g g-1/4">
            <div className="push--small--bottom">
              <label className="form__label--small" htmlFor="">
                {l('PROJECT-address_scan_center')}
              </label>
              <ContentDisplayRows rows={address_scan_center} />
            </div>
          </div>
          <div className="g g-1/4">
            <div className="push--small--bottom">
              <ContactBlock
                type="audit"
                contact={{ ...auditData, type: 'custom' }}
                isEditing={panelEditMode}
                label={l('PROJECT_CONTACT-audit')}
                create={addCustomContact('contact_audit')}
                update={updateContact('contact_audit')}
              />
            </div>
          </div>
        </div>
      </Card>
    </StateConditional>
  );
};

const mapState = ({ projectStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.addresses_card,
  panelEditMode: machineStore.addresses_card === PanelStates.EDITING,
  address_owner: projectStore.data.address_owner,
  address_scan_center: projectStore.data.address_scan_center,
  contact_audit: projectStore.data.contact_audit,
  contact_audit_form: projectStore.form.contact_audit,
});

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  updateContact(target: string): Function {
    return (value: Object): any =>
      dispatch(updateContactProjectFormData(target, value));
  },
  addCustomContact(target: string): Function {
    return (value: Object): any =>
      dispatch(addCustomContactToProject(target, value));
  },
});

export default connect(mapState, mapActions)(AddressesCard);
