/** @format */

import assign from 'object.assign';

import requestStates from '../../../functions/requestStates.func.js';

const initialRequest = {
  ...requestStates().request,
};

const initialState = assign(
  {
    status: null,
  },
  requestStates(),
);

export function forgotPasswordReducer(state = initialState, { type, error }) {
  switch (type) {
    case 'FORGOT_PASSWORD_REQUEST_SUCCESS':
      return assign({}, state, {
        request: assign({}, initialRequest, {
          success: true,
        }),
      });
    case 'FORGOT_PASSWORD_REQUEST_FAILED':
      return assign({}, state, {
        request: assign({}, initialRequest, {
          failed: true,
          status: error.status,
        }),
      });
    case 'FORGOT_PASSWORD_REQUEST_FETCHING':
      return assign({}, state, {
        request: assign({}, initialRequest, {
          fetching: true,
        }),
      });
    default:
      return state;
  }
}
