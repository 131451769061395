export const transitions = {
  to_fail: 'to_fail',
  to_update: 'to_update',
  to_visible: 'to_visible',
  to_recover: 'to_recover',
  to_editing: 'to_editing',
  to_load: 'to_load',
  to_update_error: 'to_update_error',
};

export const States = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  UPDATING: 'UPDATING',
  UPDATE_ERROR: 'UPDATE_ERROR',
  ERROR: 'ERROR',
};

export const PanelStates = {
  HIDDEN: 'HIDDEN',
  VISIBLE: 'VISIBLE',
  EDITING: 'EDITING',
};
