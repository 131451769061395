/** @format */

// @flow

import generator from 'constants/generator';
import type { KeysList } from 'views/singleProject/constants/KeysList';
import graphi from 'helpers/graphi';
import { requestError, requestRequest } from 'functions/actionRequests.func.js';

import isValidEntry from '../helpers/validation.js';
import query from '../query';

export const ProjectFetchCreateData = generator('PROJECT_FETCH_CREATE_DATA');
export const ProjectCreateSave = generator('PROJECT_CREATE_SAVE');

export const PROJECT_CREATE_UPDATE = 'PROJECT_CREATE_UPDATE';
export const CLEAR_PROJECT_FORM_VALIDATION = 'CLEAR_PROJECT_FORM_VALIDATION';
export const PROJECT_CREATE_ADD_DOC = 'PROJECT_CREATE_ADD_DOC';
export const PROJECT_CREATE_REMOVE_DOC = 'PROJECT_CREATE_REMOVE_DOC';

export const PROJECT_CREATE_UPDATE_CONCERN = 'PROJECT_CREATE_UPDATE_CONCERN';

function resultHandler(result: Object, dispatch: Function, type: string): any {
  return dispatch({
    type,
    data: result.data.data.create_project,
    rawData: result.data.data.create_payload,
  });
}

// Fetch Job View Data

export function fetchProjectCreateData(placeType, placeId): Function {
  return (dispatch: Function) => {
    dispatch({
      type: CLEAR_PROJECT_FORM_VALIDATION,
      data: null,
    });
    dispatch(requestRequest(ProjectFetchCreateData.fetching));
    graphi(query(placeType, placeId), 'createProject')
      .then((res: Object): any =>
        resultHandler(res, dispatch, ProjectFetchCreateData.success),
      )
      .catch((err: Object): any =>
        dispatch(requestError(ProjectFetchCreateData.failed, err)),
      );
  };
}
// End Fetch Job View Data

export function updateProjectFormData(payload: {
  key: KeysList,
  value: string | number,
}): any {
  return isValidEntry(payload.key, payload.value)
    ? {
        type: PROJECT_CREATE_UPDATE,
        payload: {
          ...payload,
          batched: false,
        },
      }
    : { type: 'FAIL_TO_UPDATE' };
}

// Document Actions

export function removeDocument(
  imageId: string | number,
  instance: 'create_single_job_internal',
  docType: 'internal' | 'external',
  dispatch: Function,
): any {
  return dispatch({
    type: 'JOB_ASSIGN_REMOVE_DOCUMENT',
    imageId,
    instance,
    docType,
  });
}

export function addDoc(
  file: Object,
  listName: 'create_single_job_internal',
  docType: 'internal' | 'external',
  dispatch: Function,
): any {
  return dispatch({
    type: 'JOB_OFFER_ADD_DOC',
    file,
    listName,
    docType,
  });
}

export function clearDocs(
  listName: 'create_single_job_internal',
  dispatch: Function,
): any {
  return dispatch({
    type: 'JOB_OFFER_CLEAR_DOC',
    listName,
  });
}
