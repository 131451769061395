import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import GetSvg from 'components/GetSvg';
import l from 'helpers/locale';
import { parseDate } from 'helpers/dates';
import { SimplePopup, DocumentUploader } from '@bonlineza/b-lib';
import type { DocumentUploaderProps } from '@bonlineza/b-lib';
import Preview from 'components/Preview';
import PreviewImage from 'components/Preview/Image';
import PreviewPdf from 'components/Preview/Pdf';
import PreviewDefault from 'components/Preview/Default';

import DocConstants from './constants';
import { uploadFile } from './actions';

const { DELETE, VIEW } = DocConstants.DOCUMENT_ACTION;

type Transition = {
  name: string,
  action: string,
  type: string,
};

export type DocumentDataShape = {
  document_id: number | void,
  name: string,
  mime_type: string,
  source_tag: string,
  source_type: string,
  source_id: number | void,
  blob: string,
  url: string,
  timestamp: number | void,
  user: {
    full_name: string,
    role_id: string | number,
  } | void,
  state_machine: {
    status: string,
    available_transitions: Transition[],
  },
};

type ReduxStateShape = {
  errorMessage: string,
  isLoading: boolean,
};

type ReduxActionsShape = {
  uploadHandler: Function,
};

type Props = {
  previewPdf?: boolean,
  fullWidthOnImage: boolean,
  limitHeight: boolean,
  uploadCallback?: Function,
  showUploader?: boolean,
} & DocumentUploaderProps &
  ReduxStateShape &
  ReduxActionsShape;

const canEdit = list =>
  list.some(doc =>
    doc.state_machine.available_transitions.some(trans => trans.name === 'add'),
  );

const ConnectedDocumentUploader = (props: Props) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [actionData, setActionData] = useState({ action: null, data: null });

  const { controls } = props;

  function deleteAction() {
    const control = controls.find(item => item.documentAction === DELETE);
    control.action(
      actionData.data?.document_id,
      actionData.data?.state_machine.available_transitions
        .filter(trans => trans.name === 'trash')
        .reduce((pv, cv) => cv.action, ''),
    );
  }

  const onActionClicked = (action, data) => {
    switch (action) {
      case 'trash':
        setActionData({ action, data });
        setPopupOpen(true);
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <DocumentUploader
        {...props}
        list={props.list.filter(document => document.document_id !== null)}
        showUploader={canEdit(props.list) || props.showUploader}
        addFileRenderer={() => <GetSvg svg="add_file" wrapperClass="" />}
        loadingRenderer={() => (
          <GetSvg
            svg="loading"
            wrapperClass="image-preview__inner__body__loader"
          />
        )}
        errorMessage={l(props.errorMessage)}
        previewRenderer={(documentData: DocumentDataShape, k: number) => (
          <Preview
            baseClass="document-uploader__item"
            key={k}
            onActionClicked={onActionClicked}
            isFullWidth={props.fullWidthOnImage}
            limitHeight={props.limitHeight}
            data={documentData}
            previewComponents={[
              {
                condition: (data: DocumentDataShape) =>
                  data.mime_type.includes('image'),
                component: PreviewImage,
                controls,
              },
              {
                condition: (data: DocumentDataShape) =>
                  data.mime_type.includes('pdf'),
                component: props.previewPdf ? PreviewPdf : PreviewDefault,
                controls,
              },
              {
                condition: () => true,
                component: PreviewDefault,
                controls: controls.filter(
                  control => control.documentAction !== VIEW,
                ),
              },
            ]}
            footerRenderer={data => (
              <Fragment>
                <div className="image-preview__footer__content--primary">
                  {data.name}
                </div>
                <div className="image-preview__footer__content--primary">
                  {data.user.full_name}
                </div>
                <div className="image-preview__footer__content--secondary">
                  {parseDate(data.timestamp, 'L')}
                </div>
              </Fragment>
            )}
          />
        )}
      />
      <SimplePopup
        isOpen={popupOpen}
        close={() => setPopupOpen(false)}
        title={l('POPUP-delete_document-title')}
        description={l('POPUP-delete_document-body').replace(
          ':document_title',
          actionData.data?.imgAlt,
        )}
        options={[
          {
            cb: () => setPopupOpen(false),
            buttonText: l('cancel'),
            buttonClass: 'btn--text',
          },
          {
            cb: () => {
              setPopupOpen(false);
              deleteAction();
            },
            buttonText: l('ACTION-delete'),
            buttonClass: 'btn--primary',
          },
        ]}
      />
    </Fragment>
  );
};

ConnectedDocumentUploader.defaultProps = {
  uploadCallback: null,
  fullWidthOnImage: false,
  limitHeight: true,
  previewPdf: false,
  showUploader: false,
};

const mapState = (
  state: Object,
  { listName }: { listName: string },
): ReduxStateShape => ({
  errorMessage: state.docStore[listName].uploadMessage,
  isLoading: state.docStore[listName].request.fetching,
});

const mapActions = (
  dispatch: Function,
  {
    listName,
    endpoint,
    data,
    uploadCallback,
  }: {
    listName: string,
    endpoint: string,
    data: Object,
    uploadCallback: Function,
  },
): ReduxActionsShape => ({
  uploadHandler(file: any, callback?: Function): any {
    const combinedCallback = payload => {
      if (uploadCallback) {
        uploadCallback(payload); // from parent
      }
      if (callback) {
        callback(payload); // from child
      }
    };
    return dispatch(
      uploadFile(endpoint, data, file, listName, combinedCallback),
    );
  },
});

export default connect(mapState, mapActions)(ConnectedDocumentUploader);
