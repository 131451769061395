/** @format */

// @flow

import React from 'react';
import type { DocumentType } from 'views/singleJob/constants';
import GenericDocumentsPanel from 'shared/jobs/Panels/items/GenericDocuments';

type PropsShape = {
  isEditing?: boolean,
  data: Object,
  listName: string,
  list?: [DocumentType],
  controls: Array<*>,
};

const StaticDocsPanelWrapper = ({
  isEditing,
  data,
  list,
  listName,
  controls,
}: PropsShape): React$Element<*> => (
  <GenericDocumentsPanel
    isEditing={isEditing}
    data={data}
    listName={listName}
    list={list}
    controls={controls}
    title="STATIC_DOCS-title"
    identifier="action-drop-static_document"
    footer="STATIC_DOCS-footer"
  />
);

StaticDocsPanelWrapper.defaultProps = {
  isEditing: false,
  list: [],
};

export default StaticDocsPanelWrapper;
