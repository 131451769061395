import React from 'react';
import l from 'helpers/locale';
import AllianzSelect from 'components/AllianzSelect';

export default ({
  isEditing,
  classSuffix,
  onUpdate,
  occasions,
  defaultValue,
  error,
}) => (
  <div
    className={`form-and-view-container ${classSuffix} ${
      error ? 'form-and-view-container--error' : ''
    }
      `}>
    <label className="form__label--small" htmlFor="__">
      {l('PROJECT-occasion')}
    </label>
    <div className="form__input" data-qe-id="action-click-occasion_selector">
      {isEditing ? (
        <AllianzSelect
          placeholder={l('PROJECT-occasion-choose-placeholder')}
          defaultValue={{
            label: defaultValue
              ? l(`PROJECT_LIST_VALUES-occasion-${defaultValue}`)
              : '-',
            value: defaultValue,
          }}
          onValueChange={(value: { value: string, label: string }): any =>
            onUpdate({
              key: 'occasion',
              value: value ? value.value : null,
            })
          }
          options={occasions.map(v => ({
            label: v.value,
            value: v.key,
          }))}
        />
      ) : (
        <p className="text--dk--flushed">
          {l(`PROJECT_LIST_VALUES-occasion-${defaultValue}`)}
        </p>
      )}
    </div>
  </div>
);
