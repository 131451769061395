/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import FinancePanel from 'shared/projects/panels/items/Finance';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import { updateForm } from '../../actions';

type ReduxStateShape = {
  budget: number | string,
  status: string,
};

type PropsShape = ReduxStateShape;

const FinanceCard = ({
  budget,
  payments_total,
  projectAccount,
  status,
  updateFormField,
  machine_state,
  panelEditMode,
}: PropsShape): React$Element<*> => (
  <StateConditional
    state={machine_state}
    whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
    <FinancePanel
      data={{
        budget,
        projectAccount,
        payments_total,
      }}
      projectStatus={status}
      isEditing={panelEditMode}
      updateField={updateFormField}
    />
  </StateConditional>
);

const mapState = ({ projectStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.object_general_finance_cards,
  panelEditMode:
    machineStore.object_general_finance_cards === PanelStates.EDITING,
  budget: projectStore.form.budget,
  projectAccount: projectStore.data.project_account,
  payments_total: projectStore.data.payments_total,
  status: projectStore.data.status,
});

const mapActions = (dispatch: Function) => ({
  updateFormField(key: string, value: string): any {
    return dispatch(updateForm(key, value));
  },
});

export default connect(mapState, mapActions)(FinanceCard);
