/** @format */

// @flow

import moment from 'moment';
import constrain, { sanitizeInputForConstraints } from './constrainable.js';

export const queryBase = {
  grouping: '',
  search: '',
  perPage: '',
  sort: {},
  currentPage: '',
  exclude: [],
};

type ActionTypes =
  | 'grouping'
  | 'order'
  | 'search'
  | 'paginate'
  | 'perPage'
  | 'byDate';

type QueryStateShape = {
  search: string,
  perPage: string,
  sort: {
    [string]: 'asc' | 'desc',
  },
  exclude: Array,
};

type ReturnShape = {
  query: string,
  nextState: QueryStateShape,
};

function getNextOrder(current: string, column: string, order: string): string {
  return [
    ...[`${column}|${order}`],
    current
      .split(',')
      .filter((dirty: string): boolean => dirty !== '')
      .map((section: string): string[] => section.split('|'))
      .filter((couple: string[]): string[] => couple[0] !== column)
      .reduce(
        (acc: Array<string>, curr: Array<string>): Array<string> => [
          ...acc,
          ...[`${curr[0]}|${curr[1]}`],
        ],
        [],
      )
      .join(','),
  ].join(',');
}

function getNextSearch(current: string, next: string): string {
  return next && next.includes(':') ? removeConstraintsFromSearch(next) : next;
}

function getNextConstraint(next: string): string {
  return next && next.includes(':') ? constrain(next) : '';
}

function removeConstraintsFromSearch(next: string): string {
  return sanitizeInputForConstraints(next)
    .filter((item: string): boolean => !item.includes(':'))
    .join(' ');
}

function getNextPagination(current: string, next: string): string {
  return next;
}

function parseDate(dateStr: string): string {
  return dateStr ? moment(dateStr).unix() : '';
}

function incrementCounter(currentCounter: number | void): number {
  return currentCounter ? currentCounter + 1 : 1;
}

function buildQuery(stateObj: QueryStateShape): string {
  return [
    ...[`grouping=${stateObj.grouping}`],
    ...[`search=${stateObj.search}`],
    ...[`per_page=${stateObj.perPage}`],
    ...[`page=${stateObj.currentPage}`],
    ...[`date_from=${parseDate(stateObj.dateFrom)}`],
    ...[`date_to=${parseDate(stateObj.dateTo)}`],
    ...[`sort=${stateObj.sort}`],
    ...stateObj.exclude.map(item => `exclude[]=${item}`),
    ...[`${stateObj.constraint}`],
  ].join('&');
}

export default function setQueryParameters(
  currentState: QueryStateShape,
  action: ActionTypes,
  param1: string | number | Array,
  param2: string | number,
): ReturnShape {
  let nextState = {
    search: '',
    perPage: '',
    sort: '',
    currentPage: '',
    constraint: '',
    exclude: [],
    ...currentState,
  };

  switch (action) {
    case 'exclude':
      nextState = {
        ...nextState,
        exclude: [
          ...currentState.exclude.filter(ex => !param1.includes(ex)),
          ...param1,
        ],
        currentPage: 1,
      };
      break;
    case 'order':
      nextState = {
        ...nextState,
        counter: incrementCounter(currentState.counter),
        sort: getNextOrder(currentState.sort, param1, param2),
        currentPage: 1,
      };
      break;
    case 'search':
      nextState = {
        ...nextState,
        counter: incrementCounter(currentState.counter),
        search: getNextSearch(currentState.search, param1),
        constraint: getNextConstraint(param1),
        currentPage: 1,
      };
      break;
    case 'paginate': // √
      nextState = {
        ...nextState,
        counter: incrementCounter(currentState.counter),
        currentPage: getNextPagination(currentState.currentPage, param1),
      };
      break;
    case 'byDate':
      nextState = {
        ...nextState,
        currentPage: 1,
        counter: incrementCounter(currentState.counter),
        dateFrom: param1,
        dateTo: param2,
      };
      break;
    case 'grouping':
      nextState = {
        ...nextState,
        currentPage: 1,
        counter: incrementCounter(currentState.counter),
        grouping: param1,
      };
      break;
    default:
      nextState = {
        ...nextState,
        ...currentState,
        counter: incrementCounter(currentState.counter),
      };
  }

  return {
    ...currentState,
    nextState,
    query: buildQuery(nextState),
  };
}
