import React from 'react';

import BkpSelector from './bkpSelector.js';
import ContractorSelector from './contractorSelector.js';

export const validationConfig = [
  'type_id',
  'entry_date',
  'invoice_date',
  'total',
  'contractor_id',
];

const formConfig = data => {
  const hasPrefill = !!data.payment;

  let paymentTypeDefault = {};
  let temp = null;

  if (hasPrefill) {
    // payment type getter
    temp = data.types[data.types.findIndex(v => v.id === data.payment.type_id)];
    paymentTypeDefault = {
      label: temp.desc,
      value: temp.id,
    };
  }

  return [
    {
      title: 'PROJECT_PAYMENT-description_heading',
      sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
      fields: [
        {
          type: 'select',
          id: 'selector-1',
          name: 'type_id',
          mandatory: true,
          label: 'PROJECT_PAYMENT-type',
          itemClass: 'g g-1/2',
          wrappingClass: 'g g-1/1',
          opts: data.types
            ? data.types.map(v => ({ label: v.desc, value: v.id }))
            : [],
          default: hasPrefill ? paymentTypeDefault : undefined,
        },
        {
          type: 'date_selector',
          id: '1',
          name: 'entry_date',
          mandatory: true,
          label: 'PROJECT_PAYMENT-entry_date',
          itemClass: 'g g-1/2',
          value: hasPrefill ? data.payment.entry_date : '',
        },
        {
          type: 'date_selector',
          id: '2',
          name: 'invoice_date',
          mandatory: true,
          label: 'PROJECT_PAYMENT-invoice_date',
          itemClass: 'g g-1/2',
          value: hasPrefill ? data.payment.invoice_date : '',
        },
        {
          type: 'component',
          id: '3',
          name: 'bkp_id',
          label: 'PROJECT_PAYMENT-bkp-select',
          value: hasPrefill ? data.payment.bkp_id : '',
          component: ({ ...props }) => (
            <BkpSelector data={data.bkps} {...props} />
          ),
        },
        {
          type: 'number',
          name: 'total',
          appendLeft: 'CHF',
          id: '4',
          mandatory: true,
          label: 'PROJECT_PAYMENT-total',
          itemClass: 'g g-1/2',
          value: hasPrefill ? data.payment.total : '',
        },
      ],
    },
    {
      title: 'PROJECT_PAYMENT-biller_heading',
      sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
      fields: [
        {
          type: 'component',
          id: '8',
          mandatory: true,
          name: 'contractor_id',
          label: '',
          prefill:
            hasPrefill && data.payment.contractor.length > 0
              ? data.payment.contractor
              : false,
          value: hasPrefill ? data.payment.contractor_id : '',
          component: ({ ...props }) => <ContractorSelector {...props} />,
        },
      ],
    },
  ];
};

export default formConfig;
