import React from 'react';

type Props = {
  setVisibility: Function,
  children: any,
};

function Popup(props: Props) {
  const reactPopup = React.useRef(null);
  const [containerClass, setContainerClass] = React.useState(
    props.containerClass,
  );

  const eventHandler = event => {
    if (!reactPopup.current?.contains(event.target)) {
      props.setVisibility(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', eventHandler);
    return () => document.removeEventListener('click', eventHandler);
  });

  React.useLayoutEffect(() => {
    let newClassName = props.containerClass;
    const boundingRect = reactPopup.current.getBoundingClientRect();
    if (boundingRect.left < props.minEdgeSpacing) {
      newClassName = `${newClassName} ${props.containerClass}--pushed-right`;
    }
    setContainerClass(newClassName);
  }, [setContainerClass, props.minEdgeSpacing, props.containerClass]); // only run once

  return (
    <div
      className={containerClass}
      ref={reactPopup}
      data-qe-id={`${props.identifier}-${props.type}`}>
      <div className={`${props.containerClass}__inner`}>{props.children}</div>
    </div>
  );
}

Popup.defaultProps = {
  identifier: 'component-contact_popup',
  containerClass: 'popup',
  minEdgeSpacing: 52,
};

export default Popup;
