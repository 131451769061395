import l from 'helpers/locale';

export default function({ salutations, contractor, lang }) {
  const langOptions = [
    {
      value: 'de',
      label: 'Deutsch',
    },
    {
      value: 'fr',
      label: 'Français',
    },
    {
      value: 'en',
      label: 'English',
    },
  ];
  return [
    {
      title: 'CONTACT-contact_person',
      sectionClass: 'bgc--ntrl-min soft push--bottom',
      fields: [
        !contractor
          ? {
              type: 'text',
              id: '1',
              name: 'nomination_company',
              label: 'NOMINATION_FORM-company',
              itemClass: 'g g-1/2',
              mandatory: true,
            }
          : {
              type: 'text',
              id: '1',
              name: 'contractor_id',
              value: contractor.id,
              label: 'contractor_id',
              itemClass: 'g g-1/1 visuallyhidden',
              mandatory: true,
            },
        {
          type: 'select',
          id: '2',
          wrappingClass: 'g g-1/2 g--fill',
          name: 'nomination_salutation_id',
          label: l('NOMINATION_FORM-salutation'),
          placeholder: l('NOMINATION_FORM-salutation_placeholder'),
          // value: contractor ? contractor.salutations : '',
          options: salutations.map(v => ({
            value: `${v.id}`,
            label: `${v.description}`,
          })), // comes from api
          mandatory: true,
        },
        {
          type: 'text',
          id: '3',
          name: 'nomination_first_name',
          label: 'NOMINATION_FORM-first_name',
          itemClass: 'g g-1/2 g--fill',
          value: contractor ? contractor.name : '',
          mandatory: true,
        },
        {
          type: 'text',
          id: '4',
          name: 'nomination_last_name',
          label: 'NOMINATION_FORM-last_name',
          itemClass: 'g g-1/2 g--fill',
          value: contractor ? contractor.surname : '',
          mandatory: true,
        },
        {
          type: 'text',
          id: '5',
          name: 'nomination_email',
          label: 'NOMINATION_FORM-email',
          itemClass: 'g g-1/2 g--fill',
          value: contractor ? contractor.email : '',
          mandatory: true,
        },
        {
          type: 'select',
          id: '6',
          wrappingClass: 'g g-1/2 g--fill',
          name: 'nomination_langcode',
          label: 'NOMINATION_FORM-select_language',
          default:
            (lang && langOptions.find(item => item.value === lang)) || null,
          options: langOptions,
          mandatory: true,
        },
      ],
    },
    {
      title: 'NOMINATION-singular',
      sectionClass: 'bgc--ntrl-min soft push--bottom',
      fields: [
        {
          type: 'select',
          id: '7',
          name: 'nomination_cause',
          label: 'NOMINATION_FORM-cause',
          wrappingClass: 'g g-1/2 g--fill',
          placeholder: l('NOMINATION-reason'),
          mandatory: true,
          options: [
            {
              label: l('NOMINATION_REASON-new_customer'),
              value: 'new_customer',
            },
            {
              label: l('NOMINATION_REASON-recommended_central'),
              value: 'recommended_central',
            },
            {
              label: l('NOMINATION_REASON-inquiry_contractor'),
              value: 'inquiry_contractor',
            },
            {
              label: l('NOMINATION_REASON-recommended_contractor'),
              value: 'recommended_contractor',
            },
            {
              label: l('NOMINATION_REASON-recommended_property_manager'),
              value: 'recommended_property_manager',
            },
            {
              label: l('NOMINATION_REASON-job_based'),
              value: 'job_based',
            },
            {
              label: l('NOMINATION_REASON-known_contractor'),
              value: 'known_contractor',
            },
          ],
        },
      ],
    },
  ];
}
