/** @format */
import hasOwn from 'functions/hasOwn.func.js';

export default function(payload) {
  const rules = {
    title: { required: true },
    total: { required: true, isNumber: true },
    discount: { required: false, isNumber: true },
    cash_discount: { required: false, isNumber: true },
    payment_conditions: { required: true, isNumber: true },
    incl_vat: { required: false },
  };

  const validationResult = Object.keys(rules).reduce((accumulated, current) => {
    let result = {};
    if (rules[current].isNumber && hasOwn(payload, current)) {
      if (Number.isNaN(parseInt(payload[current], 10))) {
        result = { [current]: 'VALIDATION-invalid_number' };
      }
    }

    if (rules[current].required) {
      if (
        !payload[current] ||
        payload[current] === '' ||
        payload[current] === null
      ) {
        result = { [current]: 'VALIDATION-required' };
      }
    }

    return { ...accumulated, ...result };
  }, {});

  return {
    result: Object.keys(validationResult).length === 0,
    fields: validationResult,
  };
}
