import graphi from 'helpers/graphi';
import { contactShape } from 'views/singleJob/query';
import { hasValue } from '@bonlineza/b-lib/functions';

export const propertyQuery = propertyId => `
{
  property(property_id:${propertyId}) {
    jobs_count,
    tenants_count,
    entry_id,
    admin_start_date,
    purchase_date,
    plot_size,
    plot_volume,
    construction_year,
    external_number,
    street,
    postal_code,
    city,
    country_code,
    province_code,
    community_number,
    owner_name_3,
    renovation_years,
    post_city,
    type,
    current_year_payments_volume,
    manager{
      ${contactShape}
    },
    assistant{
      ${contactShape}
    },
    caretakers{
      ${contactShape}
    },
    notifications_count,
    notifications(limit:6){
      subject,
      message,
      timestamp,
    }
  }
}
`;

function getNotificationsQuery(propertyId, limit = 200) {
  return `{
    property(property_id:${propertyId}) {
      notifications(limit:${limit}){
        subject,
        message,
        timestamp,
      },
    },
  }`;
}

export async function getNotifications(propertyId): Object[] {
  if (!hasValue(propertyId)) return [];
  const { data } = await graphi(
    getNotificationsQuery(propertyId),
    'getPropertyNotifications',
  );
  return data.data.property.notifications;
}
