import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NominationForm from 'views/nominations/views/NominationForm';
import { nominationCreated } from 'views/nominations/actions';

const InviteFormRender = ({ history, context, nominated }) => {
  return (
    <NominationForm
      backAction={() => history.push(`/nominations`)}
      scrollToTop={context.scrollToTop}
      successCallback={newUser => {
        nominated();
        history.push(`/nominations/${newUser.id}`);
      }}
    />
  );
};

const mapActions = (dispatch: Function) => ({
  nominated(): any {
    return dispatch(nominationCreated());
  },
});

export const InviteForm = withRouter(
  connect(null, mapActions)(InviteFormRender),
);
