/** @format */

// @flow

import React, { Fragment } from 'react';
import m from 'moment';
import GetSvg from 'components/GetSvg';
import l from 'helpers/locale';

export type RatingShape = {
  user: Object,
  timestamp: number,
  comment: string,
  rating: number,
};

type PropsShape = {
  notifications: Array<RatingShape>,
};

export const StarList = ({ rating }) => (
  <Fragment>
    {[1, 2, 3, 4, 5].map((item: number) => (
      <span key={item} className="star-btn--sml disable-hover">
        <GetSvg svg={`star${item <= rating ? '_full' : '_empty'}`} />
      </span>
    ))}
  </Fragment>
);

const localiseUser = full_name =>
  l('RATINGS-user').replace(':full_name', full_name);

const RatingsListRenderer = ({ notifications }: PropsShape) => (
  <div className="notification-container">
    {notifications?.map((v: RatingShape, k: number) => (
      <div className="notification-container__item" key={k}>
        <div className="notification-container__item__title">
          <span>
            {m.unix(v.timestamp).format('LLL')} -{' '}
            {localiseUser(v.user.full_name)}
          </span>
          <span className="rating">
            <StarList rating={v.rating} />
          </span>
        </div>
        <div className="notification-container__item__body">{v.comment}</div>
      </div>
    )) || null}
  </div>
);

export default RatingsListRenderer;
