// @type
import React from 'react';

import l from 'helpers/locale';

type ContentDisplayRowsProps = {
  rows: string[] | void,
  fallback?: string,
  className?: string,
};

export function ContentDisplayRows({
  rows,
  fallback,
  className,
}: ContentDisplayRowsProps): React$Element {
  const nonEmptyRows = rows ? rows.filter(item => item !== '' && item) : [];

  return (
    <p className={className}>
      {nonEmptyRows.length ? (
        nonEmptyRows.map((item, key) => (
          <React.Fragment key={key}>
            {item}
            <br />
          </React.Fragment>
        ))
      ) : (
        <React.Fragment>{l(fallback)}</React.Fragment>
      )}
    </p>
  );
}
ContentDisplayRows.defaultProps = {
  fallback: 'RESULT-none',
  className: 'text--dk--flushed',
};
