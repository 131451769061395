/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import GeneralPanel from 'shared/projects/panels/items/General';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import { updateForm } from '../../actions';

type ReduxStateShape = {
  status: string,
  displayStatus: string,
  updateFormField: ({
    target: string,
    targetId: string,
  }) => any,
  onChange: ({
    target: string,
    targetId: string,
  }) => any,
};

type PropsShape = ReduxStateShape;

const GeneralCard = ({
  occasion,
  occasions,
  target_date,
  status,
  displayStatus,
  updateFormField,
  machine_state,
  panelEditMode,
}: PropsShape): React$Element<*> => (
  <StateConditional
    state={machine_state}
    whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
    <GeneralPanel
      data={{
        occasion,
        occasions,
        target_date,
        status,
        displayStatus,
      }}
      projectStatus={status}
      isEditing={panelEditMode}
      updateField={updateFormField}
    />
  </StateConditional>
);

const mapState = ({ projectStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.object_general_finance_cards,
  panelEditMode:
    machineStore.object_general_finance_cards === PanelStates.EDITING,
  occasion: projectStore.data.occasion,
  occasions: projectStore.data.occasions,
  target_date: projectStore.data.target_date,
  status: projectStore.data.status,
  displayStatus: projectStore.data.display_status,
});

const mapActions = (dispatch: Function) => ({
  updateFormField(key: string, value: string): any {
    return dispatch(updateForm(key, value));
  },
  onChange(key: string, value: string): any {
    return dispatch({ type: 'TODO', key, value });
  },
});

export default connect(mapState, mapActions)(GeneralCard);
