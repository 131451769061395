// @flow
import StateMachine from 'machines/reduxMachine';
import { transitions, PanelStates } from 'machines/constants';
import {
  State,
  Transition,
  Edge as EdgeBuilder,
  Conditions,
} from 'async-state-machine';
import { ids } from 'constants/jobStatus';

import JobConditions from '../../conditions';

const {
  isContractor,
  isCreating,
  isProjectJob,
  isJobStateWithin,
} = JobConditions;
const { and, not, or } = Conditions;

// Transitions for accounts card
export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) =>
    and([not(isContractor(params)), not(isProjectJob(params))]),
  );

export const to_editing = Object.create(Transition)
  .setName(transitions.to_editing)
  .addCondition((context, params) => {
    if (isProjectJob(params)) {
      return false;
    }

    const result = and([
      or([
        isCreating(params),
        isJobStateWithin(params, [
          ids.DRAFT,
          ids.OFFERS_REQUESTED,
          ids.OFFERS_RECEIVED,
        ]),
      ]),
      not(isContractor(params)),
    ]);

    return result;
  });

function generateAccountCardStateMachine() {
  const Edge = new EdgeBuilder();

  // States for Accounts Card
  const HIDDEN = Object.create(State).setName(PanelStates.HIDDEN);
  const VISIBLE = Object.create(State).setName(PanelStates.VISIBLE);
  const EDITING = Object.create(State).setName(PanelStates.EDITING);

  // Register Machine
  const Machine = new StateMachine('account_card')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN, EDITING])
        .to(VISIBLE),
    )
    .registerEdge(
      Edge.new()
        .transition(to_editing)
        .from([VISIBLE, HIDDEN])
        .to(EDITING),
    )
    .setInitialState(HIDDEN, {});

  return Machine;
}

export default generateAccountCardStateMachine;
