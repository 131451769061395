/**
 * Group an Array of Objects by unique property values
 * @param {Object[]} array - the array of objects that need to grouped
 * @param {Function} getGroupingValue - get a value from an item prop that will be used to group items by
 * @returns Object whose key is the groupBy value and whose value is an array of items that match the groupBy criteria
 */
const groupBy = (array: Object[], getGroupingValue: Function) => {
  const groups = Object.create(null);
  array.forEach(function(item) {
    const key = getGroupingValue(item);
    groups[key] = groups[key] || [];
    groups[key].push(item);
  });
  return groups;
};

export default groupBy;
