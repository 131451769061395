/** @format */

// @flow
import React from 'react';

import l from '../../../../../../helpers/locale';
import axios from '../../../../../../helpers/axios';

import UntilReady from '../../../../../../components/UntilReady';

type PropsShape = {
  callback: (number, string) => any,
  // eslint-disable-next-line react/no-unused-prop-types
  close: () => any,
  contractorId: string | number,
  // eslint-disable-next-line react/no-unused-prop-types
  nominee: string[],
};

type RequestShape = {
  loading: boolean,
  success: boolean,
  error: boolean,
};

type StateShape = {
  request: RequestShape,
  contractor: string[],
  contractorName: string,
};

class LinkContractor extends React.Component<PropsShape> {
  constructor(props: PropsShape) {
    super(props);
    this.baseRequest = {
      loading: true,
      success: false,
      error: false,
    };

    this.state = {
      request: this.baseRequest,
      contractor: [],
      contractorName: '',
    };

    this.fetchContractor();
  }

  state: StateShape;

  baseRequest: RequestShape;

  handleSubmit = (e: Object): any => {
    e.preventDefault();
    return this.props.callback(
      this.props.contractorId,
      this.state.contractorName,
    );
  };

  fetchContractor = (): any =>
    axios
      .get(`/api/1/view/contractors/${this.props.contractorId}`)
      .then((res: Object): any =>
        this.setState((prevState: StateShape): StateShape => ({
          ...prevState,
          request: {
            ...this.baseRequest,
            success: true,
          },
          contractor: [
            res.data.payload.name,
            res.data.payload.details[0],
            res.data.payload.details[1],
          ],
          contractorName: res.data.payload.name,
        })),
      )
      .catch((): any =>
        this.setState((prevState: StateShape): StateShape => ({
          ...prevState,
          request: {
            ...this.baseRequest,
            error: true,
          },
        })),
      );

  render(): React$Element<*> {
    return (
      <div className="layout-container">
        <div className="layout-container__header">
          <div className="gw">
            <div className="g g-1/1">
              <div className="align--left">
                <h2 className="">{l('NOMINATION-link_contractor-title')}</h2>
                <p>{l('NOMINATION-link_contractor-body')}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="layout-container__body">
          <div className="align--left">
            <div className="gw">
              <div className="g g-1/2">
                <label className="form__label--small" htmlFor="__">
                  {l('NOMINEE')}
                </label>
                {this.props.nominee.map((v: string, k: number): React$Element<
                  *,
                > => (
                  // eslint-disable-next-line react/no-array-index-key
                  <p className="push--smallest--bottom" key={k}>
                    {v}
                  </p>
                ))}
              </div>
              <div className="g g-1/2">
                <label className="form__label--small" htmlFor="__">
                  {l('CONTRACTOR-title')}
                </label>
                <UntilReady
                  ready={this.state.request.success}
                  waiting={this.state.request.loading}>
                  <div>
                    {this.state.contractor.map(
                      (v: string, k: number): React$Element<*> => (
                        // eslint-disable-next-line react/no-array-index-key
                        <p className="push--smallest--bottom" key={k}>
                          {v}
                        </p>
                      ),
                    )}
                  </div>
                </UntilReady>
              </div>
            </div>
          </div>
        </div>
        <div className="layout-container__footer">
          <span className="layout-container__footer__item">
            <button
              type="button"
              className="btn--text"
              onClick={this.props.close}>
              {l('cancel')}
            </button>
          </span>
          <span className="layout-container__footer__item">
            <button
              type="button"
              data-qe-id="action-link-nomination"
              className="btn--primary"
              onClick={this.handleSubmit}>
              {l('ACTION-link')}
            </button>
          </span>
        </div>
      </div>
    );
  }
}

export default LinkContractor;
