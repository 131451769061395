import React, { Fragment } from 'react';
import l from 'helpers/locale';
import CloseButton from 'components/AsideSlide/CloseButton';
import { AsideSlide } from '@bonlineza/b-lib';
import AssetHousePropertyView from 'workflows/jobAssign/views/assetHousePropertyView';

import Panel from './objectPanel.js';

type KeysList = any; // @TODO

type PropsShape = {
  editNow?: boolean,
  allowConcernChange?: boolean,
  updateParent: Function,
  updateField: KeysList => (string | number) => any,
  data: {
    data: Object,
    buildings: Array<*>,
    building_selected?: string | void,
  },
};

type StateShape = {
  isOpenEditObject: boolean,
  nextTarget: '',
  nextTargetId: '',
};

class ObjectPanelWrapper extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    editNow: false,
  };

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      isOpenEditObject: false, // TODO: this should be Route Driven
    };
  }

  state: StateShape;

  openEditObject = (e: Object): any => {
    e.preventDefault();

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      isOpenEditObject: true,
    }));
  };

  toggleEditObject = (e: Object): any => {
    e.preventDefault();

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      isOpenEditObject: !prevState.isOpenEditObject,
    }));
  };

  hasAccess: boolean;

  updateObjectAction = (id: string, name: string): Function => {
    this.props.updateParent({
      concern_type: name,
      concern_id: id,
    });

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      nextTarget: name,
      nextTargetId: id,
      isOpenEditObject: false,
    }));
  };

  render(): React$Element<*> {
    return (
      <Fragment>
        <Panel
          isEditing={this.props.editNow}
          allowConcernChange={this.props.allowConcernChange}
          building_selected={this.props.data.building_selected}
          buildings={this.props.data.buildings}
          data={this.props.data.data}
          updateField={this.props.updateField}
          openEdit={this.openEditObject}
        />

        <AsideSlide
          isOpen={this.state.isOpenEditObject}
          title={l('JOB-edit-object')}
          toggle={this.toggleEditObject}
          toggleButton={() => <CloseButton onClick={this.toggleEditObject} />}>
          <div>
            {this.state.isOpenEditObject ? (
              <AssetHousePropertyView nextAct={this.updateObjectAction} />
            ) : (
              <div />
            )}
          </div>
        </AsideSlide>
      </Fragment>
    );
  }
}

ObjectPanelWrapper.defaultProps = {
  editNow: false,
  allowConcernChange: true,
};

export default ObjectPanelWrapper;
