/** @format */

// @flow
import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import UntilReady from 'components/UntilReady';
import ActionBar from 'components/ConnectedActionBar';
import { publishFormFailed } from 'components/ConnectedActionBar/actions';
import Form from 'components/Form';
import axios from 'helpers/axios';
import formatErrors from 'helpers/formatErrors';
import request from 'constants/request';
import validatePhone from 'functions/validatePhone';
import validateWebsite from 'functions/validateWebsite';
import fields from './account/fields';
import errorFormatting from './account/formatting';

type ReduxStateShape = {
  locale: string,
};

type ReduxActionsShape = {
  publishFormFailed: Function,
};

type PropsShape = {
  onFilled: Function,
  invitation: Object,
  apiErrors: Object,
  isProcessing?: boolean,
} & ReduxActionsShape &
  ReduxStateShape;

class AccountForm extends Component {
  constructor(props: PropsShape) {
    super(props);

    this.state = {
      salutations: [],
      agencies: [],
      trades: [],
      countries: [],
      default_country_id: null,
      request: {
        ...request,
      },
    };
    this.submitData = this.submitData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale && prevProps.locale !== '') {
      this.getData();
    }
  }

  getData = (): Promise<*> => {
    this.setState({
      request: {
        ...request,
        fetching: true,
      },
    });
    return axios
      .get('/api/1/static-data')
      .then((res: Object): any =>
        this.setState({
          salutations: res.data.payload.salutations,
          agencies: res.data.payload.agencies,
          trades: res.data.payload.trades,
          countries: res.data.payload.countries,
          default_country_id: res.data.payload.default_country_id,
          request: {
            ...request,
            success: true,
          },
        }),
      )
      .catch((): any =>
        this.setState({
          request: {
            ...request,
            failed: true,
          },
        }),
      );
  };

  getFields(): Array {
    return fields({
      ...this.state,
      invitation: this.props.invitation,
    });
  }

  submitData(data: Object) {
    if (this.props.onFilled) {
      this.props.onFilled(data);
    }
  }

  validator = validationFailedCB => payload => {
    const requiredFields = [
      'salutation_id',
      'name',
      'surname',
      'telephone',
      'email',
      'language',
      'password',
      'password_confirm',
      'company',
      'street',
      'zip',
      'city',
      'country',
      'company_phone',
      'company_email',
      'trades',
      'iban',
      'bank_address',
    ];
    let validatorResponse = requiredFields.reduce(
      (acc: Object, curr: string) =>
        !payload[curr] ? { ...acc, [curr]: 'VALIDATION-required' } : acc,
      {},
    );
    if (payload.password) {
      if (payload.password !== payload.password_confirm) {
        validatorResponse = {
          ...validatorResponse,
          password: 'VALIDATION-passwords_must_match',
        };
      } else if (payload.password.length < 8) {
        validatorResponse = {
          ...validatorResponse,
          password: 'VALIDATION-password_min_len_8',
        };
      }
    } else {
      validatorResponse = {
        ...validatorResponse,
        password: 'VALIDATION-required',
      };
    }
    if (!payload['profile[accept]']) {
      validatorResponse = {
        ...validatorResponse,
        'profile[accept]': 'VALIDATION-required',
      };
    }
    if (payload.uid_number !== '') {
      // eslint-disable-next-line
      const regex = /^CHE-\d{3}\.\d{3}\.\d{3}$/;
      if (!regex.test(payload.uid_number)) {
        validatorResponse = {
          ...validatorResponse,
          uid_number: 'VALIDATION-required',
        };
      }
    }

    if (payload.website && !validateWebsite(payload.website)) {
      validatorResponse = {
        ...validatorResponse,
        ...{ website: 'FORM-website-description' },
      };
    }

    if (!validatePhone(payload.telephone)) {
      validatorResponse = {
        ...validatorResponse,
        ...{ telephone: 'FORM-phone-description' },
      };
    }
    if (!validatePhone(payload.company_phone)) {
      validatorResponse = {
        ...validatorResponse,
        ...{ company_phone: 'FORM-phone-description' },
      };
    }

    if (Object.keys(validatorResponse).length > 0 && validationFailedCB) {
      validationFailedCB();
    }
    return {
      result: Object.keys(validatorResponse).length === 0,
      fields: validatorResponse,
    };
  };

  render = () => (
    <Fragment>
      <ActionBar name="form" />
      <UntilReady
        ready={this.state.request.success}
        waiting={this.state.request.fetching}>
        <Form
          submitAct={this.submitData}
          sections={this.getFields()}
          wrappingClass="gw"
          wrappingOuterClass=""
          validateBefore={this.validator(this.props.publishFormFailed)}
          afterSubmitErrors={formatErrors(
            this.props.apiErrors,
            errorFormatting,
          )}
          isProcessing={this.props.isProcessing}
        />
      </UntilReady>
    </Fragment>
  );
}

const mapState = (state: Object): ReduxStateShape => ({
  locale: state.appStore.locale,
});

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  publishFormFailed(): any {
    return dispatch(publishFormFailed());
  },
});

export default connect(mapState, mapActions)(AccountForm);
