import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'helpers/axios';
import l from 'helpers/locale';
import goUp from 'functions/goUp';
import CloseButton from 'components/AsideSlide/CloseButton';
import ONBOARDING from 'constants/onboardingStates.js';
import { AsideSlide, Overlay, SimplePopup } from '@bonlineza/b-lib';
import { ids as RoleId } from 'constants/roles';

import {
  errorHandler,
  setInitialValues,
  // eslint-disable-next-line
} from 'shared/contractor/SingleContractor/components/CustomerRelationshipEdit/actions';
import Contractors from '../../../../../contractors';
import LinkContractor from '../LinkContractor';

import {
  nominationExported,
  nominationDeclined,
  nominationLinked,
  nominationDeleted,
  nominationMailResent,
  invitationMailResent,
} from '../../../../actions';

type ReduxActionsShape = {
  actionExported: Function,
  actionDeclined: Function,
  actionLinked: Function,
  actionDeleted: Function,
};

type CustomerFieldsShape = {
  customer_status: string,
  customer_code: string,
};

type ReduxPropsShape = {
  customerFields: CustomerFieldsShape,
};

type PropsShape = {
  close: Function,
  nominationId: string,
  currentState: string,
  nominee: string[],
  userType: number,
} & ReduxPropsShape &
  ReduxActionsShape;

type StateShape = {
  request: {
    success: boolean,
    error: boolean,
    loading: boolean,
  },
  contractorsVisible: boolean,
  selectedContractor: string | number,
  refreshData: Function,
  visibleLinkOverlay: boolean,
  showPop: string | null,
};

const {
  CENTRAL_PROCUREMENT,
  PROPERTY_MANAGER,
  CONSTRUCTION_MANAGER,
  ADMINISTRATION,
} = RoleId;

class FooterActions extends React.Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.baseApi = '/api/1/contractor_profiles';
    this.state = {
      contractorsVisible: false,
      selectedContractor: 0,
      visibleLinkOverlay: false,
      showPop: null,
    };
  }

  state: StateShape;

  componentDidMount() {
    this.props.actionSetInitialCustomerFields({
      customer_status: '',
      customer_code: '',
      recommended: '',
    });
  }

  baseApi: string;

  removeNomination = (id: string): Function => (e: Object): any => {
    e.preventDefault();
    this.updateRequest('loading', true);
    return axios
      .delete(`${this.baseApi}/${id}`)
      .then((): any => {
        this.updateRequest('success', true);
        this.props.actionDeleted();
        return this.props.close();
      })
      .catch((): any => this.updateRequest('error', true));
  };

  linkNomination = (
    contractorId: string | number,
    contractorName: string,
  ): any => {
    this.updateRequest('loading', true);
    return axios
      .post(`${this.baseApi}/${this.props.nominationId}/link`, {
        contractor_id: contractorId,
        ...this.props.customerFields,
      })
      .then((): any => {
        this.props.actionLinked({ contractorName });
        this.props.refreshData();
        this.setState((prevState: StateShape): StateShape => ({
          ...prevState,
          contractorsVisible: false,
        }));
        return this.updateRequest('success', true);
      })
      .catch((err: any): any => {
        this.setState((prevState: StateShape): StateShape => ({
          ...prevState,
          contractorsVisible: false,
        }));
        this.props.actionCustomerError(err.data);
        goUp();
        return this.updateRequest('error', true);
      });
  };

  exportNomination = (): any => {
    this.togglePop('export');
  };

  exportNominationConfirm = (): any => {
    this.togglePop('export');
    this.updateRequest('loading', true);
    return axios
      .post(
        `${this.baseApi}/${this.props.nominationId}/create`,
        this.props.customerFields,
      )
      .then((): any => {
        this.props.actionExported();
        this.props.refreshData();
        return this.updateRequest('success', true);
      })
      .catch((err: any): any => {
        this.props.actionCustomerError(err.data);
        goUp();
        return this.updateRequest('error', true);
      });
  };

  togglePop = (popName: string): any => {
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      showPop: (prevState.showPop !== popName && popName) || null,
    }));
  };

  declineNomination = (e: Object): any => {
    e.preventDefault();
    this.togglePop('decline');
  };

  declineNominationConfirmed = (): any => {
    this.togglePop('decline');
    return axios
      .post(`${this.baseApi}/${this.props.nominationId}/decline`)
      .then((): any => {
        this.props.actionDeclined();
        this.props.refreshData();
        return this.updateRequest('success', true);
      })
      .catch((): any => this.updateRequest('error', true));
  };

  updateRequest = (
    type: 'success' | 'error' | 'loading',
    value: boolean,
  ): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      request: {
        success: false,
        error: false,
        loading: false,
        [type]: value,
      },
    }));

  toggleContractorVisibility = (e: Object): any => {
    if (Object.prototype.hasOwnProperty.call(e, 'preventDefault')) {
      e.preventDefault();
    }

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      contractorsVisible: !prevState.contractorsVisible,
    }));
  };

  toggleLinkOverlayVisibility = (e: Object): any => {
    if (Object.prototype.hasOwnProperty.call(e, 'preventDefault')) {
      e.preventDefault();
    }

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      visibleLinkOverlay: !prevState.visibleLinkOverlay,
    }));
  };

  openLinkOverlay = (contractorId: string): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      visibleLinkOverlay: true,
      selectedContractor: contractorId,
    }));

  resendInvitation = (): any => {
    this.updateRequest('loading', true);
    return axios
      .post(`${this.baseApi}/${this.props.nominationId}/resend_invitation_mail`)
      .then((): any => {
        this.props.actionInvitationResent();
        this.props.refreshData();
        this.updateRequest('success', true);
      })
      .catch((): any => this.updateRequest('error', true));
  };

  getResendInvitationButton() {
    if (
      [
        CENTRAL_PROCUREMENT,
        PROPERTY_MANAGER,
        CONSTRUCTION_MANAGER,
        ADMINISTRATION,
      ].includes(this.props.userType) &&
      [ONBOARDING.invitation_signup_pending].includes(this.props.currentState)
    ) {
      return (
        <div className="fl-right__item">
          <button
            type="button"
            className="btn--quinary"
            onClick={this.resendInvitation}>
            {l('ACTION-resend_invitation_mail')}
          </button>
        </div>
      );
    }
    return null;
  }

  resendNomination = (): any => {
    this.updateRequest('loading', true);
    return axios
      .post(`${this.baseApi}/${this.props.nominationId}/resend_nomination_mail`)
      .then((): any => {
        this.props.actionNominationResent();
        this.props.refreshData();
        this.updateRequest('success', true);
      })
      .catch((): any => this.updateRequest('error', true));
  };

  getResendNominationButton() {
    if (
      [
        CENTRAL_PROCUREMENT,
        PROPERTY_MANAGER,
        CONSTRUCTION_MANAGER,
        ADMINISTRATION,
      ].includes(this.props.userType) &&
      [ONBOARDING.nomination_signup_pending].includes(this.props.currentState)
    ) {
      return (
        <div className="fl-right__item">
          <button
            type="button"
            className="btn--quinary"
            onClick={this.resendNomination}>
            {l('ACTION-resend_nomination_mail')}
          </button>
        </div>
      );
    }
    return null;
  }

  render(): React$Element<*> {
    return (
      <div className="fl-right">
        <div className="fl-right__item">
          <button
            type="button"
            className="btn--base--transp"
            onClick={this.props.close}>
            {l('JOB-ACTIONS-BACK')}
          </button>
        </div>

        {this.getResendInvitationButton()}
        {this.getResendNominationButton()}

        {([1, 2].includes(this.props.userType) &&
          (this.props.currentState === ONBOARDING.nomination_signup_pending ||
            this.props.currentState ===
              ONBOARDING.invitation_signup_pending) && (
            <div className="fl-right__item">
              <button
                type="button"
                className="btn--secondary"
                onClick={this.removeNomination(this.props.nominationId)}>
                {l('ACTION-delete')}
              </button>
            </div>
          )) ||
          ([1, 2].includes(this.props.userType) &&
            this.props.currentState === ONBOARDING.manual_review_pending && (
              <Fragment>
                <div className="fl-right__item">
                  <button
                    type="button"
                    className="btn--secondary"
                    onClick={this.declineNomination}
                    data-qe-id="action-show-decline_nomination_popup">
                    {l('ACTION-decline')}
                  </button>
                </div>
                <div className="fl-right__item">
                  <button
                    type="button"
                    className="btn--quinary"
                    onClick={this.exportNomination}
                    data-qe-id="action-show-accept_nomination_popup">
                    {l('ACTION-export')}
                  </button>
                </div>
                <div className="fl-right__item">
                  <button
                    type="button"
                    className="btn--quinary"
                    onClick={this.toggleContractorVisibility}
                    data-qe-id="action-select-existing_contractor">
                    {l('ACTION-link')}
                  </button>
                </div>

                <AsideSlide
                  isOpen={this.state.contractorsVisible}
                  title={l('NOMINATION-select_contractor_to_link')}
                  toggle={this.toggleContractorVisibility}
                  toggleButton={() => (
                    <CloseButton onClick={this.toggleContractorVisibility} />
                  )}
                  bgcAlt>
                  {this.state.contractorsVisible ? (
                    <div>
                      <Contractors
                        overrideOnClick
                        useOnClick={this.openLinkOverlay}
                        showHeader={false}
                        apiUrl="/api/1/view/contractors/linkable"
                      />

                      <Overlay isOpen={this.state.visibleLinkOverlay}>
                        <LinkContractor
                          close={this.toggleLinkOverlayVisibility}
                          callback={this.linkNomination}
                          contractorId={this.state.selectedContractor}
                          nominee={this.props.nominee}
                          visible={this.state.visibleLinkOverlay}
                        />
                      </Overlay>
                    </div>
                  ) : (
                    <div />
                  )}
                </AsideSlide>
                <SimplePopup
                  isOpen={this.state.showPop === 'decline'}
                  close={(): any => this.togglePop('decline')}
                  title={l('NOMINATION-decline-title')}
                  description={l('NOMINATION-decline-body')}
                  options={[
                    {
                      cb: () => {
                        this.togglePop('decline');
                      },
                      buttonText: l('cancel'),
                      buttonClass: 'btn--text',
                    },
                    {
                      cb: () => {
                        this.declineNominationConfirmed();
                      },
                      dataQeId: 'action-decline-nomination',
                      buttonText: l('ACTION-decline'),
                      buttonClass: 'btn--primary',
                    },
                  ]}
                />
                <SimplePopup
                  isOpen={this.state.showPop === 'export'}
                  close={(): any => this.togglePop('export')}
                  title={l('NOMINATION-export-title')}
                  description={l('NOMINATION-export-body')}
                  options={[
                    {
                      cb: () => {
                        this.togglePop('export');
                      },
                      dataQeId: 'action-cancel-popup',
                      buttonText: l('cancel'),
                      buttonClass: 'btn--text',
                    },
                    {
                      cb: () => {
                        this.exportNominationConfirm();
                      },
                      dataQeId: 'action-accept-nomination',
                      buttonText: l('ACTION-create'),
                      buttonClass: 'btn--primary',
                    },
                  ]}
                />
              </Fragment>
            )) || <div />}
      </div>
    );
  }
}

const mapState = (state): Object => ({
  customerFields: state.customerRelationship.fields,
});

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  actionExported(): any {
    return dispatch(nominationExported());
  },
  actionDeclined(): any {
    return dispatch(nominationDeclined());
  },
  actionLinked(payload: Object): any {
    return dispatch(nominationLinked(payload));
  },
  actionDeleted(): any {
    return dispatch(nominationDeleted());
  },
  actionCustomerError(data): any {
    return errorHandler(data, dispatch, () => false, 'nomination');
  },
  actionSetInitialCustomerFields(data): any {
    return dispatch(setInitialValues(data));
  },
  actionInvitationResent(): any {
    return dispatch(invitationMailResent());
  },
  actionNominationResent(): any {
    return dispatch(nominationMailResent());
  },
});

export default connect(mapState, mapActions)(FooterActions);
