import generator from 'constants/generator';

const GeneratedActionsGet = generator('get_single_job');
const GeneratedActionsGetContacts = generator('get_contacts_single_job');
const GeneratedActionsDelete = generator('delete_single_job');

const SINGLE_JOB_ADD_NEW_CONTACT = 'SINGLE_JOB_ADD_NEW_CONTACT';
const UPDATE_JOB_FORM = 'UPDATE_SINGLE_JOB_FORM';
const REMOVE_JOB_DOC = 'SINGLE_JOB_REMOVE_JOB_DOC';
const ADD_JOB_DOC = 'SINGLE_JOB_ADD_JOB_DOC';

const UPDATE_CONTACT = 'SINGLE_JOB_UPDATE_CONTACT';
const CREATE_CONTACT = 'SINGLE_JOB_CREATE_CONTACT';

const UPDATE_JOB_CONCERN = 'UPDATE_JOB_CONCERN';
const UPDATE_JOB_CONTRACTOR = 'UPDATE_JOB_CONTRACTOR';

const CLEAR_SUB_REQUEST = 'CLEAR_SUB_REQUEST';

export type DocumentType = {
  document_id: number,
  name: string,
  mime_type: string,
  source_tag: string,
  source_type: string,
  source_id: number,
  timestamp: number,
  blob: string,
  url: string,
  user: Object,
};

export default {
  getJob: GeneratedActionsGet,
  getContacts: GeneratedActionsGetContacts,
  deleteJob: GeneratedActionsDelete,
  SINGLE_JOB_ADD_NEW_CONTACT,
  UPDATE_JOB_FORM,
  REMOVE_JOB_DOC,
  ADD_JOB_DOC,
  UPDATE_CONTACT,
  CREATE_CONTACT,
  UPDATE_JOB_CONCERN,
  UPDATE_JOB_CONTRACTOR,
  CLEAR_SUB_REQUEST,
};
