import moment from 'moment';

// TODO: add thin wrapper to project at app level
// import {setLocale} helpers/dates
// import {getLocale} helpers/locale
// setLocale(getLocale)

class MomentWrapper {
  static momentInstance;

  static getInstance(locale: string): Object {
    if (!this.momentInstance) {
      if (locale) moment.locale(locale);
      this.momentInstance = moment;
    }
    return this.momentInstance;
  }

  static setLocale(l: string) {
    this.getInstance().locale(l);
  }
}

export function getMoment(locale): any {
  return MomentWrapper.getInstance(locale);
}

export function setLocale(): any {
  return MomentWrapper.setLocale();
}

export function parseDate(
  item: mixed = moment().unix(),
  dateFormat: string = 'L',
): string {
  let overrideFormat = dateFormat;
  if (dateFormat === 'L') {
    /*
     * After an update to momentjs all of our date formats
     * changed from the intended DD/MM/YYYY to the american format
     * of MM/DD/YYYY - because of this we need to manually set the
     * intended format.
     *
     * This is safe to do because at this time we will not be displaying any
     * other date formats in allianz
     *
     * @todo - Actually fix this moment interaction.
     *
     */
    overrideFormat = 'DD/MM/YYYY';
  }
  return item
    ? MomentWrapper.getInstance()
        .unix(item)
        .format(overrideFormat)
        .toString()
    : '-';
}
