import { UPDATE_TITLE } from '../actions';
import flattenRoutes from '../functions/flattenRoutes';

import {
  create as createProject,
  view as viewProject,
} from '../routes/projects';
import { create as createJob, view as viewJob } from '../routes/jobs';
import {
  create as createAccount,
  view as viewAccount,
} from '../routes/accounts';
import {
  view as viewContractor,
  create as createContractor,
  rootProfileView,
} from '../routes/contractors';
import {
  view as viewNomination,
  create as createNomination,
} from '../routes/nominations';
import { view as viewProperties } from '../routes/properties';

const initialState = [
  ...flattenRoutes(createJob()),
  ...flattenRoutes(viewJob()),
  ...flattenRoutes(createProject()),
  ...flattenRoutes(viewProject()),
  ...flattenRoutes(createAccount()),
  ...flattenRoutes(viewAccount()),
  ...flattenRoutes(viewContractor()),
  ...flattenRoutes(createContractor()),
  ...flattenRoutes(viewNomination()),
  ...flattenRoutes(createNomination()),
  ...flattenRoutes(viewProperties()),
  ...flattenRoutes(rootProfileView()),
].reduce(
  (acc, curr) => ({
    ...acc,
    [curr.name]: {
      title: curr.title,
    },
  }),
  {},
);

export default function reducer(state = initialState, { type, data }) {
  switch (type) {
    case UPDATE_TITLE:
      return {
        ...state,
        [data.name]: {
          title: data.title,
        },
      };
    default:
      return state;
  }
}
