/** @format */

// @flow
import Constants from './constants.js';
import axios from '../../../helpers/axios';

import {
  requestError,
  requestRequest,
} from '../../../functions/actionRequests.func.js';

const {
  success,
  failed,
  fetching,
  // UPDATE_LIST,
  // UPDATE_LIST_FAILED,
  // UPDATE_PARAMS_SORT,
  // UPDATE_PARAMS_SEARCH,
  // UPDATE_PARAMS_PER_PAGE,
  // UPDATE_PARAMS_CLEAR,
} = Constants;

type TargetShape = 'assets' | 'houses' | 'properties';

function resultHandler(
  result: Object,
  target: TargetShape,
  dispatch: Function,
): any {
  // do stuff
  return dispatch({ type: success, name: target, data: result.data.payload });
}

export function fetchData(target: TargetShape): Function {
  return function fetchDataThunk(dispatch: Function) {
    dispatch(requestRequest(fetching, { name: target }));
    axios
      .get(`/api/1/view/jobs/${target}`)
      .then((res: Object): any => resultHandler(res, target, dispatch))
      .catch((err: Object): any =>
        dispatch(requestError(failed, err, { name: target })),
      );
  };
}

export function fetchPageData(target: TargetShape, pageNum: number): Function {
  return function fetchPageDataThunk(
    dispatch: Function,
    getState: () => Object,
  ) {
    const { path } = getState().assetHousePropertyStore[target].pagination;
    dispatch(requestRequest(fetching, { target }));
    axios
      .get(`${path}?page=${pageNum}`)
      .then((res: Object): any => resultHandler(res, target, dispatch))
      .catch((err: Object): any =>
        dispatch(requestError(failed, err, { name: target })),
      );
  };
}
