/** @format */

import React from 'react';

import Card from '../../../../../components/Card';
import l from '../../../../../helpers/locale';

const NominationPanel = ({ reason }) => (
  <Card title="NOMINATION-title" isOpen canCollapse={false}>
    <div>
      <span className="form__label--small">
        {l('NOMINATION-reason-subtitle')}
      </span>
      <p className="text--dk--flushed">{reason}</p>
    </div>
  </Card>
);

export default NominationPanel;
