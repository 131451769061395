import React from 'react';
import ContactRow from './ContactRow';

const NotifyContactRow = props => {
  return (
    <ContactRow
      {...props}
      nameId="component-grant_modal-contractor_name"
      emailId={`action-send-commit_mail_${props.index}`}
    />
  );
};

export default NotifyContactRow;
