// @flow
import StateMachine from 'machines/reduxMachine.js';
import { transitions } from 'machines/constants.js';

import {
  State,
  Transition,
  Edge as EdgeBuilder,
  Conditions,
} from 'async-state-machine';

import isProjectJob from '../../conditions/isProjectJob';
import isInvitee from '../../conditions/isInvitee';
import isAssigned from '../../conditions/isAssigned';
import isProjectOwner from '../../conditions/isProjectOwner';
import isOnProjectTeam from '../../conditions/isOnProjectTeam';

const { and, not, or } = Conditions;

// Transitions for Document Exchange
export const to_editing = Object.create(Transition)
  .setName(transitions.to_editing)
  .addCondition((context, params) =>
    and([
      isProjectJob(params),
      and([not(isInvitee(params)), not(isAssigned(params))]),
      or([isProjectOwner(params), isOnProjectTeam(params)]),
    ]),
  );
export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) =>
    and([
      isProjectJob(params),
      and([not(isInvitee(params)), not(isAssigned(params))]),
      or([isProjectOwner(params), isOnProjectTeam(params)]),
    ]),
  );

function ProjectTeamDocsCardMachine() {
  const Edge = new EdgeBuilder();

  // States for Single Job
  const HIDDEN = Object.create(State).setName('HIDDEN');
  const VISIBLE = Object.create(State).setName('VISIBLE');

  const EDITING = Object.create(State).setName('EDITING');

  // Register Machine
  const Machine = new StateMachine('projectteamdocs_card')
    .registerEdge(
      Edge.new()
        .transition(to_editing)
        .from([VISIBLE, HIDDEN])
        .to(EDITING),
    )
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN, EDITING])
        .to(VISIBLE),
    )
    .setInitialState(HIDDEN, {}, true);

  return Machine;
}

export default ProjectTeamDocsCardMachine;
