/** @format */

export default function generateDefaultState() {
  return {
    request: {
      fetching: false,
      success: false,
      failed: false,
    },
  };
}
