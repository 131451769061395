/** @format */

// @flow
import type { DocumentType } from 'views/singleJob/constants';
import ReferenceSlide from '../referenceSlide';
import AccountDocumentsCard from '../accountDocuments';

type AdditionalData = {
  salutations: Array<Object> | void,
  branches: Array<Object> | void,
  agencies: Array<Object> | void,
  documents: Array<DocumentType>,
  user_id?: number,
  data?: Object,
  removeAccountDoc: Function,
};

function hasOwn(obj: Object, prop: string): boolean {
  return (
    typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, prop)
  );
}

function getReferenceLabel(forUser: string): string {
  switch (forUser) {
    case 'hw':
      return 'CONTRACTOR-title';
    case 'cm':
      return 'CONSTRUCTION-MANAGER-select_reference-label';
    case 'pm':
      return 'PROPERTY-MANAGER-select_reference-label';
    case 'hcm':
      return 'HEAD-CONSTRUCTION-MANAGER-select_reference-label';
    default:
      return '';
  }
}
export default function(
  forUser: string,
  {
    salutations,
    branches,
    agencies,
    documents,
    user_id = 0,
    data = {},
    removeAccountDoc,
  }: AdditionalData,
): Array<Object> {
  const hasData = data !== undefined;
  const userAccountFields = {
    title: 'CREATE_ACCOUNT-user_account',
    sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
    fields: [
      {
        type: 'select',
        mandatory: true,
        id: '1',
        wrappingClass: 'g g-1/2 g--fill',
        name: 'salutation_id',
        label: 'NOMINATION_FORM-select_salutation',
        options: salutations
          ? salutations.map((v: any): any => ({
              value: `${v.id}`,
              label: `${v.description}`,
              name: `${v.description}`,
            }))
          : [{ value: '', label: '', name: '' }], // comes from api
        default: hasData
          ? {
              value: hasOwn(data.salutation, 'code') ? data.salutation.id : '',
              label: hasOwn(data.salutation, 'description')
                ? data.salutation.description
                : '',
            }
          : undefined,
      },
      {
        type: 'text',
        id: '2',
        name: 'name',
        mandatory: true,
        label: 'NOMINATION_FORM-first_name',
        itemClass: 'g g-1/2 g--fill',
        value: hasData ? data.name : undefined,
      },
      {
        type: 'text',
        id: '3',
        name: 'surname',
        mandatory: true,
        label: 'NOMINATION_FORM-last_name',
        itemClass: 'g g-1/2 g--fill',
        value: hasData ? data.surname : undefined,
      },
      {
        type: 'phone',
        id: '4',
        name: 'telephone',
        mandatory: true,
        label: 'NOMINATION_FORM-phone',
        itemClass: 'g g-1/1 g--fill',
        value: hasData ? data.telephone : undefined,
        description: 'FORM-phone-description',
      },
      {
        type: 'text',
        id: '5',
        name: 'email',
        mandatory: true,
        label: 'NOMINATION_FORM-email',
        itemClass: 'g g-1/2 g--fill',
        value: hasData ? data.email : undefined,
      },
      {
        type: 'password',
        id: '6',
        name: 'password',
        mandatory: true,
        label: 'NOMINATION_FORM-password',
        itemClass: 'g g-1/2 g--fill',
        placeholder: hasData ? '********' : undefined,
      },
      {
        type: 'password',
        id: '7',
        name: 'password_confirm',
        mandatory: true,
        label: 'NOMINATION_FORM-password_confirm',
        itemClass: 'g g-1/2 g--fill',
        placeholder: hasData ? '********' : undefined,
      },
    ],
  };

  const referenceFields = ['pm', 'hw', 'cm', 'hcm'].includes(forUser)
    ? {
        title: 'CREATE_ACCOUNT-reference',
        sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
        fields: [
          {
            type: 'component',
            id: '8',
            mandatory: true,
            name: 'reference_id',
            label: getReferenceLabel(forUser),
            component: ReferenceSlide(forUser, data),
          },
        ],
      }
    : {};

  const branchesFields =
    forUser === 'pm'
      ? {
          title: 'CREATE_ACCOUNT-branches',
          sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
          displayOnView:
            hasData && hasOwn(data, 'reference')
              ? { ...data.reference }
              : undefined,
          fields: [
            {
              type: 'checkbox',
              id: '9',
              name: 'branches',
              mandatory: true,
              label: 'PROP-COL-branch',
              wrappingClass: 'g g-1/1',
              innerClass: 'g g-1/3 fl--row',
              selectedValues: hasOwn(data, 'assigned_branches')
                ? data.assigned_branches
                : [],
              reverse: true,
              options: branches
                ? branches.map((v: any): any => ({
                    label: v.value,
                    name: v.value,
                    value: v.key,
                    inputClass: 'push--small--right',
                  }))
                : [{ value: '', label: '', name: '' }],
            },
          ],
        }
      : {};

  const agencyFields =
    forUser === 'lga'
      ? {
          title: 'CREATE_ACCOUNT-agencies',
          sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
          fields: [
            {
              type: 'radio',
              id: '10',
              name: 'agency_id',
              mandatory: true,
              label: 'AGENCY-title',
              wrappingClass: 'g g-1/1 gw',
              innerClass: 'g g-1/3 fl--row',
              reverse: true,
              selectedValues:
                hasOwn(data, 'meta') && hasOwn(data.meta, 'agency_id')
                  ? [`${data.meta.agency_id}`]
                  : [],
              options: agencies
                ? agencies.map((v: any): any => ({
                    label: `${v.name}`,
                    name: `${v.name}`,
                    value: `${v.id}`,
                    inputClass: 'push--small--right',
                  }))
                : [{ value: '', label: '', name: '' }], // comes from api
            },
          ],
        }
      : {};

  const filesFields = {
    title: 'CREATE_ACCOUNT-documents',
    sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
    fields: [
      {
        type: 'component',
        id: '11',
        name: 'files',
        mandatory: false,
        label: 'CREATE_ACCOUNT-documents-label',
        component: AccountDocumentsCard(documents, user_id, removeAccountDoc),
      },
    ],
  };

  return [
    { ...userAccountFields },
    { ...referenceFields },
    { ...branchesFields },
    { ...agencyFields },
    { ...filesFields },
  ].filter((v: any): boolean => Object.keys(v).length > 0);
}
