import React from 'react';
import Loadable from 'react-loadable';

import GetSvg from 'components/GetSvg';

const Loading = () => (
  <div className="display--full-width display--full-height fl-vh-center">
    <GetSvg svg="loading" wrapperClass="loading-spinner--large" />
  </div>
);

const SimpleLoadable = ({ ...opts }) =>
  Loadable({
    loading: Loading,
    ...opts,
  });

export default SimpleLoadable;
