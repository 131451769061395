/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import ObjectPanel from 'shared/projects/panels/items/Object';
import { getNewConcern } from 'views/singleProject/actions';
import { updateProjectFormData } from '../../../actions';

type ReduxStateShape = {
  data: Object,
  status: string,
  onChange: ({
    target: string,
    targetId: string,
  }) => any,
};

type PropsShape = ReduxStateShape;

const ObjectCard = ({
  data,
  status,
  onChange,
  updateFormField,
}: PropsShape): React$Element<*> => (
  <ObjectPanel
    data={data}
    projectStatus={status}
    isEditing
    onChange={onChange}
    updateField={updateFormField}
  />
);

const mapState = ({ projectStore }): ReduxStateShape => ({
  data: projectStore.form.concern,
  status: 'draft',
});

const mapActions = (dispatch: Function) => ({
  updateFormField(key: string, value: string): any {
    return dispatch(updateProjectFormData(key, value));
  },
  onChange({ target, targetId }) {
    return dispatch(getNewConcern(target, targetId));
  },
});

export default connect(mapState, mapActions)(ObjectCard);
