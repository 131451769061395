import ObjectCard from './Object.js';
import GeneralCard from './General.js';
import FinanceCard from './Finance.js';
import AddressesCard from './Addresses.js';
import DescriptionCard from './Description.js';
import ProjectTeamCard from './ProjectTeam.js';
import ProjectTeamDocsCard from './ProjectTeamDocs.js';
import InternalDocsCard from './InternalDocs.js';

export default {
  ObjectCard,
  GeneralCard,
  FinanceCard,
  AddressesCard,
  DescriptionCard,
  ProjectTeamCard,
  ProjectTeamDocsCard,
  InternalDocsCard,
};
