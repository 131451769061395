/** @format */

import axios from '../../../helpers/axios';

import RequestGenerator from '../../../constants/generator';

import {
  requestSuccess,
  requestError,
  requestRequest,
} from '../../../functions/actionRequests.func.js';

const { success, failed, fetching } = RequestGenerator('reset_password');

export function requestResetPassword(payload) {
  const hashQuery =
    window.location.hash.split('?t=').length > 1
      ? window.location.hash.split('?t=')[1].split('&e=')
      : false;
  const token = hashQuery.length > 1 ? hashQuery[0] : false;
  const email = hashQuery.length > 1 ? hashQuery[1] : false;

  return function requestResetPasswordThunk(dispatch) {
    dispatch(requestRequest(fetching));
    axios
      .post('/api/1/password/reset', {
        email,
        token,
        password: payload.password,
        password_confirmation: payload['password-repeat'],
      })
      .then(res => dispatch(requestSuccess(success, res)))
      .catch(err => dispatch(requestError(failed, err)));
  };
}
