import React from 'react';
import { connect } from 'react-redux';
import type { DocumentType } from 'views/singleJob/constants';
import ProjectTeamDocs from 'shared/projects/panels/items/ProjectTeamDocuments';
import DocConstants from 'components/connected/DocumentUploader/constants';
import { removeProjectDocument } from 'views/singleProject/actions';

const { DELETE } = DocConstants.DOCUMENT_ACTION;

type ReduxActionShape = {
  removeImage: Function,
};

type ReduxStateShape = {
  documents: [DocumentType],
};

type PropsShape = ReduxStateShape & ReduxActionShape;

const ProjectTeamDocuments = ({
  removeImage,
  documents,
}: PropsShape): React$Element<*> => (
  <ProjectTeamDocs
    isEditing
    listName="create_project_team"
    data={{
      uploadProps: {
        source_tag: 'project_team',
        source_type: 'project',
      },
      endpoint: '/api/1/documents/action/add',
    }}
    list={documents}
    controls={[
      // passed to the ControlItem/renderer
      {
        documentAction: DELETE,
        svgName: 'close', // not present in default ControlItem
        action: (id: number, api: string): any => removeImage(id, api),
        canDisplay: true,
      },
    ]}
  />
);

const mapState = ({ projectStore }): ReduxStateShape => ({
  documents: [
    ...projectStore.form.documents.filter(
      doc => doc.source_tag === 'project_team',
    ),
  ],
});

const mapActions = (dispatch: Function): ReduxActionShape => ({
  removeImage(id: number, api: string): any {
    return removeProjectDocument(id, 'project_team', null, dispatch, true, api);
  },
});

export default connect(mapState, mapActions)(ProjectTeamDocuments);
