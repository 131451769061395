/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import InformationJobPanel from 'shared/jobs/Panels/items/InformationJob';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import MachineBuilder from './machine';
import { updateJobForm } from '../../actions';

type ReduxActionShape = {
  update: Function,
};

type ReduxStateShape = {
  status: string,
  occasions: Array<*>,
  info_occasion: string,
  info_desired_date: number | boolean,
  info_target_date: number,
  info_target_date_submission: number | boolean,
  summary: string,
  editNow: boolean,
  form_info_summary: string,
  form_info_occasion: string,
  form_info_desired_date: number | boolean,
  form_info_order_date?: number,
  form_info_target_date_submission?: number | boolean,
};

export const InformationCardMachine = MachineBuilder();

const InformationCard = ({
  status,
  occasions,
  info_occasion,
  info_occasion_description,
  info_desired_date,
  info_target_date,
  summary,
  panelEditMode,
  update,
  form_info_summary,
  form_info_desired_date,
  form_info_order_date,
  form_info_target_date_submission,
  info_target_date_submission,
  form_info_occasion,
  isJobOffer,
  machine_state,
}) => (
  <StateConditional
    state={machine_state}
    whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
    <InformationJobPanel
      updateField={update}
      jobState={status}
      formData={{
        info_desired_date: form_info_desired_date,
        info_target_date: form_info_order_date,
        info_target_date_submission: form_info_target_date_submission,
        info_summary: form_info_summary,
        info_occasion: form_info_occasion,
        occasions,
        updateField(): any {
          return true;
        },
      }}
      data={{
        status,
        info_desired_date,
        info_target_date,
        info_target_date_submission,
        info_occasion,
        info_occasion_description,
        summary,
      }}
      editNow={panelEditMode}
      isJobOffer={isJobOffer}
      storeTargetLabel="exists"
    />
  </StateConditional>
);

InformationCard.defaultProps = {
  form_info_order_date: '',
  form_info_target_date_submission: undefined,
};

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  panelEditMode: machineStore.jobinformation_card === PanelStates.EDITING,
  machine_state: machineStore.jobinformation_card,

  status: jobStore.data.job.info_status,
  occasions: jobStore.data.job.occasions,
  info_occasion: jobStore.data.job.info_occasion,
  info_occasion_description: jobStore.data.job.info_occasion_description,
  info_desired_date: parseInt(jobStore.data.job.info_desired_date, 10) || false,
  info_target_date: parseInt(jobStore.data.job.info_target_date, 10),
  info_target_date_submission: parseInt(
    jobStore.data.job.info_target_date_submission,
    10,
  ),
  desired_date_comment: jobStore.data.job.info_desired_date_comment,
  summary: jobStore.data.job.info_summary,
  editNow: jobStore.editNow,
  form_info_summary: jobStore.form.info_summary,
  form_info_occasion: jobStore.form.info_occasion || '',
  form_info_desired_date: parseInt(jobStore.form.info_desired_date, 10),
  form_info_order_date: parseInt(jobStore.form.info_order_date, 10),
  form_info_target_date_submission: parseInt(
    jobStore.form.info_target_date_submission,
    10,
  ),
  form_info_desired_date_comment: jobStore.form.info_desired_date_comment || '',
});

const mapAction = (dispatch: Function): ReduxActionShape => ({
  update(key: string, value: string): any {
    return dispatch(updateJobForm(key, value));
  },
});

export default connect(mapState, mapAction)(InformationCard);
