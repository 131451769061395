import React from 'react';
import { PageReady } from '@bonlineza/b-lib';
import graphi from 'helpers/graphi';
import GetSvg from 'components/GetSvg';
import { interpretErrorMessage } from 'helpers/formatErrors';

type PropsShape = {
  queryId: string,
  queryString: string,
  customErrorMessage?: string,
  customErrorHandler?: Function,
  onData?: Function,
};

type StateType = {
  data: any,
};

export const GraphQLPageReadyContext = React.createContext({ data: null });

class GraphQLPageReady extends React.Component<PropsShape, StateType> {
  static defaultProps = {
    queryId: '',
    queryString: '',
    customErrorMessage: '',
    customErrorHandler: () => null,
    onData() {
      return true;
    },
  };

  state = {
    data: null,
  };

  refreshPage = () => this.pageReadyInstance.initRequest();

  render() {
    const {
      queryId,
      queryString,
      customErrorMessage,
      customErrorHandler,
      onData,
      children,
    } = this.props;

    const { data } = this.state;

    return (
      <PageReady
        ref={inst => {
          this.pageReadyInstance = inst;
        }}
        onData={res => {
          this.setState({ data: res.data });
          onData(res);
        }}
        getRequestInstance={() => graphi(queryString, queryId)}
        customErrorMessage={customErrorMessage}
        customErrorHandler={customErrorHandler}
        renderCustomLoader={() => (
          <div className="align--center">
            <GetSvg svg="loading" wrapperClass="loading-spinner--large" />
          </div>
        )}
        errorMessageInterpreter={interpretErrorMessage}>
        <GraphQLPageReadyContext.Provider value={{ data }}>
          {children}
        </GraphQLPageReadyContext.Provider>
      </PageReady>
    );
  }
}

export default GraphQLPageReady;
