import React from 'react';
import { Paginator } from '@bonlineza/b-lib';
import GetSvg from 'components/GetSvg';
import l from 'helpers/locale';

export default props => {
  const newProps = {
    ...props,
    isLoadingContent: () => (
      <div className="loader--small">
        <GetSvg svg="loading" />
      </div>
    ),
    isEmptyContent: () => (
      <div className="push--small--top">{l('LIST-empty')}</div>
    ),
    prevBtnContent: () => <GetSvg svg="angle_left" />,
    nextBtnContent: () => <GetSvg svg="angle_right" />,
  };
  return <Paginator {...newProps} />;
};
