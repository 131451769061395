import React from 'react';
import history from 'helpers/history';
import { matchPath } from 'react-router-dom';
import CreateProject from 'workflows/projectCreate/views/project';
import ProjectView from 'views/singleProject';
import OfferSwitcher from 'views/singleJob/components/OfferSwitcher';
import AddPayment from 'views/singleProject/components/AddPayment';
import EditPayment from 'views/singleProject/components/AddPayment/EditPayment';
import pathWithoutHash from 'components/RouteSlider/functions/pathWithoutHash';
import { GrantRequest } from 'components/RouteSlider/routes/jobs/containers/GrantRequest';
import TargetSelection from '../targetSelection';

import {
  ProjectTeam,
  JobView,
  OfferView,
  AddContractor,
  ContractorSelection,
  JobCreate,
} from './containers';
import { Names } from './constants';

function backToProjectFrom(origin) {
  // CODE SMELL - must be a better way to get the current project id - maybe pass in the `path` from the call
  let matchingPath = '';
  switch (origin) {
    case 'offer_view':
      matchingPath =
        '/projects/:project_id(\\d+)/:job_id(\\d+)/offers/:offer_id(\\d+)';
      break;
    case 'grant_request':
      matchingPath = '/projects/:project_id(\\d+)/:job_id(\\d+)/grant-request';
      break;
    default:
      matchingPath = '/projects/:project_id(\\d+)/:job_id(\\d+)';
      break;
  }

  const match = matchPath(pathWithoutHash(), {
    path: matchingPath,
    exact: true,
  });

  if (match && match.params) {
    history.push(`/projects/${match.params.project_id}`);
  } else {
    // eslint-disable-next-line
    console.log('cannot find project_id in path');
  }
}

function backToProjectList() {
  history.push('/projects');
}

function backToSelectProjectTarget() {
  if (!window) return;
  const base = window.location.href.includes('dashboard')
    ? 'dashboard'
    : 'projects';
  history.push(`/${base}/project-create`);
}

function backToOrigin() {
  if (!window) return;
  const base = window.location.href.includes('dashboard')
    ? 'dashboard'
    : 'projects';
  history.push(`/${base}`);
}

export function create() {
  // projects/project-create
  // projects/project-create/:target_type/:target_id
  return {
    [Names.PROJECT_CREATE]: {
      position: 1,
      title: 'PM_DASHBOARD-object_search',
      render: () => <TargetSelection name="create" nextPath="new" />,
      backAction: backToOrigin,
      children: {
        [Names.PROJECT_CREATE_NEW]: {
          position: 2,
          title: 'PROJECT-CREATE-title',
          render: () => <CreateProject backAction={history.goBack} />,
          backAction: backToSelectProjectTarget,
        },
      },
    },
  };
}

export function view() {
  return {
    // projects/:project_id
    [Names.PROJECT_VIEW]: {
      position: 1,
      title: '',
      qeId: 'project',
      render: () => <ProjectView backAction={backToProjectList} />,
      backAction: backToProjectList,
      children: {
        // projects/:project_id/add-payment
        [Names.PROJECT_ADD_PAYMENT]: {
          position: 2,
          title: 'TITLE-ADD_PAYMENT',
          render: () => <AddPayment />,
        },
        // projects/:project_id/edit-payment/:payment_id
        [Names.PROJECT_EDIT_PAYMENT]: {
          position: 2,
          title: '',
          render: () => <EditPayment />,
        },
        // projects/:project_id/add-team
        [Names.PROJECT_ADD_TEAM]: {
          position: 2,
          title: '',
          render: () => <ProjectTeam />,
        },
        // projects/:project_id/open-job
        [Names.PROJECT_OPEN_JOB]: {
          position: 2,
          title: 'JOB-details',
          qeId: 'project-job-create',
          render: () => (
            <JobCreate name="open-job" routeName={Names.PROJECT_OPEN_JOB} />
          ),
        },

        // projects/:project_id/assign-job
        [Names.PROJECT_ASSIGN_JOB]: {
          position: 2,
          title: 'CONTRACTOR-title-plural',
          render: () => <ContractorSelection name="assign-job" />,
          children: {
            // projects/:project_id/assign-job/:contractor_id
            [Names.PROJECT_ASSIGN_JOB_CONTRACTOR]: {
              position: 3,
              qeId: 'project-job-create',
              title: 'JOB-details',
              render: () => (
                <JobCreate
                  name=":contractor_id"
                  routeName={Names.PROJECT_OPEN_JOB}
                />
              ),
            },
          },
        },
        // projects/:project_id/:job_id
        [Names.PROJECT_JOB]: {
          position: 2,
          title: 'Job',
          qeId: 'project-job',
          render: () => <JobView backAction={backToProjectFrom} />,
          backAction: backToProjectFrom,
          children: {
            // projects/:project_id/:job_id/offers/:offer_id
            [Names.PROJECT_JOB_OFFER]: {
              position: 3,
              title: '',
              qeId: 'view-offer',
              actionComponent: <OfferSwitcher />,
              render: () => <OfferView />,
            },
            // projects/:project_id/:job_id/add-contractor
            [Names.PROJECT_JOB_ADD_CONTRACTOR]: {
              position: 3,
              title: '',
              render: () => <AddContractor />,
            },
            // projects/:project_id/:job_id/grant-request
            [Names.PROJECT_JOB_GRANT_REQUEST]: {
              position: 3,
              title: 'GRANT-REQUEST-title',
              qeId: 'grant-request',
              render: () => <GrantRequest />,
              // backAction: () => backToProjectFrom('grant_request'),
            },
          },
        },
      },
    },
  };
}
