import React from 'react';
import GenericNotifications, {
  localiseTitle,
} from 'components/GenericNotifications';
import type { NotificationShape } from 'components/NotificationContainer';
import Card from 'components/Card';
import { getNotifications } from '../query';

type PropsShape = {
  notifications?: Array<NotificationShape>,
};

const NotificationCard = ({
  notifications,
  property,
  total,
}: PropsShape): React$Element<*> => (
  <Card title="NOTIFICATIONS-title">
    <GenericNotifications
      notifications={notifications}
      asideTitle={localiseTitle(property.street)}
      total={total}
      asideRequest={() => getNotifications(property.id)}
    />
  </Card>
);

NotificationCard.defaultProps = {
  notifications: [],
};

export default NotificationCard;
