import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import Form from 'components/Form';
import UntilReady from 'components/UntilReady';
import GraphQLPageReady from 'components/GraphQLPageReady';
import axios from 'helpers/axios';
import formatErrors from 'helpers/formatErrors';
import l from 'helpers/locale';
import { getRoleIdFromShortName } from 'helpers/roleStringNumberSwitch';
import type { RoleTypes } from 'constants/roles';
import defaultRequest from 'constants/request';
import type { RequestShape } from 'constants/request';
import FooterBar from 'components/FooterBar';
import ButtonGroup from 'components/ButtonGroup';
import { SliderContext } from 'components/RouteSlider';
import type { DocumentType } from 'views/singleJob/constants';
import accountCreateUpdateValidation from '../../helpers/accountCreateUpdateValidation';
import * as Actions from '../../actions';
import accountFields from './accountFields';
import getCreateAccountDocuments from './query';

const errorFormatting = [
  {
    field: 'email',
    condition: 'unique',
    message: 'VALIDATION-email_in_use',
  },
  {
    field: 'email',
    condition: 'email',
    message: 'VALIDATION-required',
  },
  {
    field: 'password',
    condition: 'min_string 8',
    message: 'VALIDATION-password_min_len_8',
  },
];

type PropsShape = {
  role: number,
  documents: Array<DocumentType>,
  history: Object,
  updateDocs: Function,
  removeDocument: Function,
  actions: {
    createAccountFailed: Function,
  },
};

type StateShape = {
  request: {
    get: RequestShape,
    submit: RequestShape,
  },
  role: RoleTypes,
  fields: {
    salutations: Array<Object>,
    references: Array<Object>,
    branches: Array<Object>,
    agencies: Array<Object>,
  },
  errors: {
    [string]: string,
  },
};

class CreateAccount extends React.Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      role: props.role,
      request: {
        get: {
          ...defaultRequest,
          fetching: true,
        },
        submit: {
          ...defaultRequest,
        },
      },
      fields: {
        salutations: [],
        references: [],
        branches: [],
        agencies: [],
      },
      errors: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = (): Promise<*> =>
    axios
      .get('/api/1/static-account-data')
      .then((res: Object): any =>
        this.setState((prevState: StateShape): StateShape => ({
          ...prevState,
          request: {
            ...prevState.request,
            get: {
              ...defaultRequest,
              success: true,
            },
          },
          fields: {
            ...prevState.fields,
            salutations: res.data.payload.salutations,
            agencies: res.data.payload.agencies,
            branches: res.data.payload.branches,
          },
        })),
      )
      .catch((): any =>
        this.setState((prevState: StateShape): StateShape => ({
          ...prevState,
          request: {
            ...prevState.request,
            get: {
              ...defaultRequest,
              failed: true,
            },
          },
        })),
      );

  getFields = (): any =>
    accountFields(this.state.role, {
      salutations: this.state.fields.salutations,
      references: this.state.fields.references,
      branches: this.state.fields.branches,
      agencies: this.state.fields.agencies,
      documents: this.props.documents,
      removeAccountDoc: this.props.removeDocument,
    });

  getFooterButtons = actions => {
    const actionConfig = [
      {
        name: 'cancel-form',
        'data-meta': {
          type: 'text',
        },
        type: 'button',
        label: 'JOB-ACTIONS-BACK',
      },
      { name: 'submit', className: 'btn--primary', type: 'submit' },
    ];

    return actionConfig
      .map(act => ({
        ...act,
        ...actions.find(item => item.name === act.name),
      }))
      .map(act => (
        <button
          {...act.attrs}
          data-meta={act['data-meta']}
          className={act.className}
          type={act.type}
          name={act.name}
          onClick={e => {
            if (act.action) {
              act.action(e);
            }
          }}>
          {l(act.label)}
        </button>
      ));
  };

  updateRoleTarget = (newRoleAbbrv: RoleTypes): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      role: newRoleAbbrv,
    }));

  submitData = (payload: Object): Promise<*> => {
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      request: {
        ...prevState.request,
        submit: {
          ...defaultRequest,
          fetching: true,
        },
      },
      errors: {},
    }));

    const uploadedDocuments = this.props.documents
      .filter(doc => doc.document_id)
      .map(doc => doc.document_id);

    return axios
      .post('/api/1/users/create', {
        role_id: getRoleIdFromShortName(this.state.role),
        ...payload,
        files: uploadedDocuments,
      })
      .then((res: Object): any => {
        this.setState((prevState: StateShape): StateShape => ({
          ...prevState,
          request: {
            ...prevState.request,
            submit: {
              ...defaultRequest,
              success: true,
            },
          },
        }));
        return this.navigateNewUser(res.data);
      })
      .catch((err: Object): any => this.handleSubmitError(err));
  };

  handleSubmitError = (err: Object): any => {
    this.props.actions.createAccountFailed();
    if (err.data && err.data.payload) {
      return this.setState((prevState: StateShape): StateShape => ({
        ...prevState,
        errors: err.data.payload,
        request: {
          ...prevState.request,
          submit: {
            ...defaultRequest,
            failed: true,
          },
        },
      }));
    }
    return true;
  };

  navigateNewUser = (data: Object): any =>
    this.props.history.push(`/accounts/${data.payload.id}`);

  onDocumentsResponse = ({ data }) => {
    this.props.updateDocs(data?.data?.create_user);
  };

  render(): React$Element<*> {
    return (
      <SliderContext.Consumer>
        {context => (
          <Fragment>
            <ActionBar name="form" />
            <GraphQLPageReady
              onData={res => this.onDocumentsResponse(res)}
              queryId="getCreateAccountDocuments"
              queryString={getCreateAccountDocuments()}>
              <UntilReady
                waiting={this.state.request.get.fetching}
                ready={this.state.request.get.success}>
                <div>
                  <Form
                    scrollToTop={context.scrollToTop}
                    submitAct={this.submitData}
                    cancelAct={() => this.props.history.go(-1)}
                    sections={this.getFields()}
                    wrappingClass="gw"
                    wrappingOuterClass=""
                    validateBefore={accountCreateUpdateValidation(
                      this.state.role,
                      true,
                      this.props.actions.createAccountFailed,
                    )}
                    afterSubmitErrors={formatErrors(
                      this.state.errors,
                      errorFormatting,
                    )}
                    isProcessing={this.state.request.submit.fetching}
                    renderFooter={actions => (
                      <FooterBar>
                        <div className="fl-right">
                          <div className="fl-right__item">
                            <ButtonGroup alt>
                              {this.getFooterButtons(actions)}
                            </ButtonGroup>
                          </div>
                        </div>
                      </FooterBar>
                    )}
                  />
                </div>
              </UntilReady>
            </GraphQLPageReady>
          </Fragment>
        )}
      </SliderContext.Consumer>
    );
  }
}

const mapState = ({ accountsStore }) => ({
  documents: [...accountsStore.base.documents],
});

const mapActions = (dispatch: Function) => ({
  updateDocs(payload) {
    dispatch({ type: 'ACCOUNTS_GET_DOCS', payload });
  },
  removeDocument(id: string | number, api: string): any {
    dispatch({ type: 'ACCOUNTS_REMOVE_DOC', payload: { id }, api });
  },
  actions: bindActionCreators(Actions, dispatch),
});

export default withRouter(connect(mapState, mapActions)(CreateAccount));
