import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import { Names } from 'components/RouteSlider/routes/projects/constants';
import { updateTitle } from 'components/RouteSlider/actions';
import GetSvg from 'components/GetSvg';
import FooterBar from 'components/FooterBar';
import { transitions, States } from 'machines/constants';
import { StateConditional } from 'machines/components/RenderConditional';
import ProjectActions from './components/ProjectActions';
import { flushState } from './actions';
import MachineBuilder from './machine';
import Cards from './cards';

export const JobMachine = MachineBuilder();

const {
  AddressesCard,
  ChartCard,
  DescriptionCard,
  InternalDocsCard,
  InvoicingProcessCard,
  JobsListCard,
  NotificationCard,
  ObjectCard,
  GeneralCard,
  FinanceCard,
  PaymentListCard,
  ProjectTeamCard,
  ProjectTeamDocsCard,
  StaticDocsCard,
} = Cards;

export const ProjectMachine = MachineBuilder();

type ReduxActionsShape = {
  resetState: () => any,
};

type ReduxStateShape = {};

type PropsShape = {
  location: {
    pathname: string,
  },
  backAction: Function,
} & ReduxActionsShape &
  ReduxStateShape;

class ProjectView extends React.Component<PropsShape> {
  componentDidMount() {
    ProjectMachine.start({
      project_id: this.props.match.params.project_id,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.lastRefresh !== null &&
      prevProps.lastRefresh < this.props.lastRefresh
    ) {
      this.handleLoadData();
    }
  }

  componentWillUnmount() {
    ProjectMachine.reset();
    this.props.resetState();
  }

  handleLoadData = () => {
    ProjectMachine.triggerTransition(transitions.to_load);
    return null;
  };

  render = () => (
    <Fragment>
      <ActionBar name="single_project_actions" />
      <StateConditional
        whenState={[States.INIT, States.LOADING]}
        state={this.props.machine_state}>
        <div className="align--center">
          <GetSvg svg="loading" wrapperClass="loading-spinner--large" />
        </div>
      </StateConditional>
      <StateConditional
        state={this.props.machine_state}
        whenState={[
          States.VIEW,
          States.EDIT,
          States.UPDATING,
          States.UPDATE_ERROR,
        ]}>
        <Fragment>
          <div className="gw">
            <div className="g--tall g-1/1">
              <ChartCard />
            </div>
          </div>
          <div className="gw">
            <div className="g--tall g-1/3">
              <ObjectCard />
            </div>
            <div className="g--tall g-1/3">
              <GeneralCard />
            </div>
            <div className="g--tall g-1/3">
              <FinanceCard />
            </div>
          </div>
          <div className="gw">
            <div className="g--tall g-1/1">
              <NotificationCard />
            </div>
          </div>
          <div className="gw">
            <div className="g--tall g-1/1">
              <JobsListCard
                projectId={this.props.match.params.project_id}
                api={`/api/1/view/projects/${this.props.match.params.project_id}/jobs`}
              />
            </div>
            <div className="g--tall g-1/1">
              <PaymentListCard projectId={this.props.match.params.project_id} />
            </div>
            <div className="g--tall g-1/1">
              <ProjectTeamCard />
            </div>
            <div className="g--tall g-1/1">
              <AddressesCard />
            </div>
            <div className="g--tall g-1/1">
              <DescriptionCard />
            </div>
            <div className="g--tall g-1/1">
              <InternalDocsCard
                projectId={this.props.match.params.project_id}
              />
            </div>
            <div className="g--tall g-1/1">
              <ProjectTeamDocsCard
                projectId={this.props.match.params.project_id}
              />
            </div>
            <div className="g--tall g-1/1">
              <StaticDocsCard projectId={this.props.match.params.project_id} />
            </div>
            <div className="g--tall g-1/1">
              <InvoicingProcessCard />
            </div>
          </div>
        </Fragment>
      </StateConditional>
      <FooterBar>
        <ProjectActions backAction={this.props.backAction} />
      </FooterBar>
    </Fragment>
  );
}

const mapState = ({ projectStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.project,
  project: projectStore.data,
  lastRefresh: projectStore.lastRefresh,
});

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  setTitle(title) {
    dispatch(updateTitle(Names.PROJECT_VIEW, title));
  },
  resetState(): any {
    return dispatch(flushState());
  },
});

export default withRouter(connect(mapState, mapActions)(ProjectView));
