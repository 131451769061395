import React from 'react';
import GoogleMap from 'google-map-react';
import mapConfig from './mapConfig.js';
import findZoomAndCenter from './findZoomAndCenter.js';
import MapMarker from './MapMarker.js';

type MapComponentTypes = {
  /* eslint-disable react/no-unused-prop-types */
  center?: {
    lat: number,
    lng: number,
  },
  zoom?: number,
  markers: Array<{
    lat: number | string,
    lng: number | string,
    name: string,
    isLink?: boolean,
    url?: string,
  }>,
  mapConfig: Object,
};

class MapComponent extends React.Component {
  static defaultProps = {
    center: { lat: 47.3773697, lng: 8.3966319 },
    zoom: 10,
    markers: [],
    size: {
      width: 800, // Map width in pixels
      height: 350, // Map height in pixels
    },
  };

  constructor(props: MapComponentTypes) {
    super(props);
    this.state = {
      center: props.center,
      zoom: props.zoom,
      hasError: false,
      error: '',
    };
    this.size = props.size;
    this.onMapLoaded = this.onMapLoaded.bind(this);
  }

  componentDidMount() {
    this.size = {
      height: this.mapContainer.offsetHeight,
      width: this.mapContainer.offsetWidth,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.markers.length > 0 &&
      this.props.markers.length !== prevProps.markers.length
    ) {
      this.zoomAndCenter(this.props.markers);
    }
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true, error: info });
  }

  // state may have changed during a map load - so check them markers here too
  onMapLoaded(): any {
    if (this.props.markers.length) {
      this.zoomAndCenter(this.props.markers);
    }
  }

  zoomAndCenter = markers => {
    const { center, zoom } = findZoomAndCenter({ size: this.size }, markers);
    this.setState(prev => ({
      ...prev,
      center,
      zoom,
    }));
  };

  render(): React$Element<*> {
    const { markers } = this.props;
    const { zoom, center } = this.state;
    return (
      <div
        ref={(container: any): any => {
          this.mapContainer = container;
        }}
        style={{ width: '100%', height: '100%' }}>
        <GoogleMap
          bootstrapURLKeys={{
            key: mapConfig.GOOGLE_MAPS_API_KEY,
          }}
          zoom={zoom}
          center={center}
          options={mapConfig.options}
          onGoogleApiLoaded={this.onMapLoaded}
          yesIWantToUseGoogleMapApiInternals>
          {markers && markers.length
            ? markers.map((item: Object, index: number): void => (
                <MapMarker
                  key={index}
                  title={item.street || ''}
                  lat={item.lat}
                  lng={item.lng}
                  svg={item.color ? `map_pin_${item.color}` : 'map_pin_red'}
                  isLink={!!item.isLink}
                  url={item.url || ''}
                />
              ))
            : null}
        </GoogleMap>
      </div>
    );
  }
}
export default MapComponent;
