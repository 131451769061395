export default function getAccountDocuments(userId) {
  return `
  {
    user(user_id:${userId}) {
      documents{
        document_id,
        name,
        mime_type,
        source_tag,
        source_type,
        source_id,
        blob,
        url,
        timestamp,

        user{
          full_name,
          role_id,
        },

        state_machine {
          status,
          available_transitions{
            name,
            action,
            type,
          },
        },
      },
    }
  }
  `;
}
