import React from 'react';

const Notifications = ({ notification }) => {
  const [visible, setVisibility] = React.useState(false);

  React.useEffect(() => {
    setVisibility(!!notification);
  }, [notification]);

  React.useEffect(() => {
    const bodyClass = 'react-notification--is-visible';
    const bodyHasClass = document.body.classList.contains(bodyClass);
    if (visible !== bodyHasClass) {
      const method = bodyHasClass ? 'remove' : 'add';
      document.body.classList[method](bodyClass);
    }

    return () => document.body.classList.remove(bodyClass);
  }, [visible]);

  const createMarkup = htmlString => {
    return { __html: htmlString };
  };

  return (
    <div className="react-notifications">
      <div
        className={`react-notifications__list ${visible ? 'is-visible' : ''}`}
        data-qe-id="react-notification-list">
        {visible ? (
          <div className="react-notifications__list__item">
            <p
              className="text--dk--flushed"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createMarkup(notification)}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

Notifications.defaultProps = {
  notifications: [],
};

export default Notifications;
