// need to get visible offers in order to match to vmp_subscribers and generalte email list...
export default (offerId): string => {
  const query = `{

    offer(offer_id: ${offerId}){
      offer_id,
      cash_discount,
      discount,
      total,
      title,
      description,
      payment_conditions,
      status,
      status_translated,
      incl_vat,
      participant,
      created_at,
      submitted_at,

      state_machine {
        status,
        available_transitions {
          name,
          action,
          type,
        },
      },

      user{
        name,
        role_id,
        user_id,
      },

      vmp_contractor{
        contractor_id
        has_profile

        billing_company
        billing_lang_code
        billing_email
        billing_telephone

        street
        pobox
        postal_code
        city
        country{
          id,
          name,
          shortname
        }
        post_city

        key_user_id
        key_user_salutation
        key_user_phone
        key_user_full_name
        key_user_email

      },

      job{
        job_id,
        vmp_subscribers(with_rejected: false){
          contractor_id,
          has_profile,

          street,
          post_city,

          billing_company,

          key_user_full_name,
          key_user_phone,
          key_user_email,
        },

        concern {
          concern_type,
          concern_id,
        },

        offers{
          offer_id,
          cash_discount,
          discount,
          total,
          title,
          description,
          status,
          status_translated,
          incl_vat,
          participant,
          created_at,
          submitted_at,

          state_machine {
            status,
            available_transitions {
              name,
              action,
              type,
            },
          },

          user{
            name,
            role_id,
            user_id,
          },

          vmp_contractor{
            contractor_id
            has_profile

            billing_company
            billing_lang_code
            billing_email
            billing_telephone

            street
            pobox
            postal_code
            city
            country{
              id,
              name,
              shortname
            }
            post_city

            key_user_id
            key_user_salutation
            key_user_phone
            key_user_full_name
            key_user_email

          },
        }
      }

      file {
        document_id,
        name,
        mime_type,
        source_tag,
        source_type,
        source_id,
        blob,
        url,

        user{
          name,
          role_id,
          user_id,
          full_name,
        },

        state_machine {
          status,
          available_transitions {
            name,
            action,
            type,
          },
        },
      },

      files {
        document_id,
        name,
        mime_type,
        source_tag,
        source_type,
        source_id,
        blob,
        url,

        user{
          name,
          role_id,
          user_id,
          full_name,
        },

        state_machine {
          status,
          available_transitions {
            name,
            action,
            type,
          },
        },
      },
    },
  }`;

  return query;
};
