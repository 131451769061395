import React from 'react';

export const SimpleButton = ({
  onClick,
  className = 'btn--base--transp',
  label,
  type = 'button',
}) => (
  <button type={type} className={className} onClick={onClick}>
    {label}
  </button>
);
