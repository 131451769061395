import React, { useState } from 'react';
import CloseButton from 'components/AsideSlide/CloseButton';
import { AsideSlide } from '@bonlineza/b-lib';
import SimpleList, {
  AllianzSimpleItem,
  SimpleListItemArrayFormatter,
  SimpleListItemRatingFormatter,
} from 'components/ConnectedSimpleList';
import l, { fC } from 'helpers/locale';
import GetSvg from 'components/GetSvg';
import { getContractorSelectorFilters } from 'workflows/jobAssign/views/contractorView';

export type PropsShape = {
  concernType: string,
  concernId: string | number,
  isOpen: boolean,
  listName: string,
  ignoreContractors: Array<string | number>,
  toggle: Function,
  clickAct: Function,
};

const ContractorSelectorPanelList = (props: PropsShape) => {
  const [cachedSearch, setCachedSearch] = useState('');

  const apiUrl = `/api/1/view/jobs/${props.concernType}/${props.concernId}/contractors`;

  const headings = [
    {
      name: 'name',
      sortable: true,
      text: l('CONT-COL-name'),
      flex: '14%',
      customFormatter: SimpleListItemArrayFormatter(),
    },
    {
      name: 'details',
      sortable: true,
      text: l('CONT-COL-address'),
      flex: '18%',
      customFormatter: SimpleListItemArrayFormatter(),
    },
    {
      name: 'rating',
      sortable: true,
      text: l('CONT-COL-rating'),
      flex: '14%',
      customFormatter: SimpleListItemRatingFormatter,
    },
    {
      name: 'display_status',
      sortable: false,
      text: l('CONT-COL-status'),
      flex: '14%',
      customFormatter: SimpleListItemArrayFormatter(true),
    },
    {
      name: 'knows_property',
      sortable: true,
      text: l('CONT-COL-knows_property'),
      flex: '14%',
    },
    {
      name: 'distance',
      sortable: true,
      text: l('CONT-COL-distance'),
      flex: '12%',
    },
    {
      name: 'earnings',
      sortable: false,
      text: l('CONT-COL-earnings'),
      customFormatter: fC,
      align: 'right',
      flex: '12%',
    },
    {
      name: '',
      sortable: false,
      text: '',
      flex: '2%',
    },
  ];

  return (
    <AsideSlide
      title={l('PARTICIPANTS-select_contractor')}
      isOpen={props.isOpen}
      toggle={props.toggle}
      toggleButton={() => <CloseButton onClick={props.toggle} />}>
      {props.isOpen ? (
        <SimpleList
          onSearch={term => setCachedSearch(term)}
          initialSearch={cachedSearch}
          api={apiUrl}
          filterOpts={getContractorSelectorFilters()}
          presetQuery={{ exclude: props.ignoreContractors }}
          bgcAlt
          name={props.listName}
          headings={headings}
          clickAct={(contractorData: Object) =>
            props.clickAct(contractorData.id, contractorData.display_contractor)
          }>
          {data =>
            data.map((row, index) => (
              <div style={{ position: 'relative' }} key={index}>
                <button
                  type="button"
                  className={`simple-list__body__row${
                    index % 2 ? '--bgc-alt' : ''
                  }`}
                  onClick={() => props.clickAct(row.id, row.display_contractor)}
                  data-qe-id={`${props.listName}-table-${index}`}>
                  {headings.map((heading, key) => (
                    <AllianzSimpleItem
                      key={key}
                      itemClass="simple-list__body__row__item"
                      flex={`${heading.flex}`}
                      column={heading.name}
                      text={row[heading.name]}
                      customFormatter={heading.customFormatter || null}
                    />
                  ))}
                </button>
                {row.website ? (
                  <a
                    href={row.website}
                    className="contractor-list-ext-anchor"
                    target="_blank"
                    rel="noopener noreferrer">
                    <GetSvg svg="ext_link" />
                  </a>
                ) : null}
              </div>
            ))
          }
        </SimpleList>
      ) : null}
    </AsideSlide>
  );
};

ContractorSelectorPanelList.defaultProps = {
  prefillData: [],
  hasError: false,
  label: '',
};

export default ContractorSelectorPanelList;
