/** @format */

// @flow

const ids = {
  ACTIVE: 0,
  LOGIN_NOT_ALLOWED: 1,
  DELETED: 2,
};

export default {
  IDs: ids,
};
