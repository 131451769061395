/** @format */

// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Card from 'components/Card';
import ContactBlock from 'components/GraphQLContactBlock';
import { ContentDisplayRows } from 'components/ContentDisplayer';
import {
  updateContactProjectFormData,
  addCustomContactToProject,
} from 'views/singleProject/actions';

import l from 'helpers/locale';

type ReduxStateShape = {
  address_owner: string[],
  address_scan_center: string[],
  contact_audit: Object,
};

type ReduxActionsShape = {
  addCustomContact: Function,
};

type PropsShape = {} & ReduxActionsShape & ReduxStateShape;

const AddressesCard = ({
  address_owner,
  address_scan_center,
  contact_audit,
  addCustomContact,
  updateContact,
}: PropsShape): React$Element<*> => (
  <Fragment>
    <Card title="PROJECT-addresses-title" isOpen>
      <div className="gw">
        <div className="g g-1/4">
          <div className="push--small--bottom">
            <label className="form__label--small" htmlFor="">
              {l('PROJECT-address_owner')}
            </label>
            <ContentDisplayRows rows={address_owner} />
          </div>
        </div>
        <div className="g g-1/4">
          <div className="push--small--bottom">
            <label className="form__label--small" htmlFor="">
              {l('PROJECT-address_scan_center')}
            </label>
            <ContentDisplayRows rows={address_scan_center} />
          </div>
        </div>
        <div className="g g-1/4">
          <div className="push--small--bottom">
            <ContactBlock
              type="audit"
              contact={{ ...contact_audit, type: 'custom' }}
              isEditing
              label={l('PROJECT_CONTACT-audit')}
              create={addCustomContact('contact_audit')}
              update={updateContact('contact_audit')}
            />
          </div>
        </div>
      </div>
    </Card>
  </Fragment>
);

const mapState = ({ projectStore }): ReduxStateShape => ({
  address_owner: projectStore.form.address_owner,
  address_scan_center: projectStore.form.address_scan_center,
  contact_audit: projectStore.form.contact_audit,
});

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  addCustomContact(target: string): Function {
    return (value: Object): any =>
      dispatch(addCustomContactToProject(target, value));
  },
  updateContact(target: string): Function {
    return (value: Object): any =>
      dispatch(updateContactProjectFormData(target, value));
  },
});

export default connect(mapState, mapActions)(AddressesCard);
