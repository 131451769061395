import React, { Fragment } from 'react';
import l from 'helpers/locale';
import { SimplePopup } from '@bonlineza/b-lib';

import { getJobContacts, getGrantContractors } from './queries';
import MultiList from './MultiList';
import NotifyContactRow from './MultiList/components/NotifyContactRow';
import ContactRow from './MultiList/components/ContactRow';

type PropsShape = {
  jobId: string | number,
  isOpen: boolean,
  callback: any => any,
  close: () => any,
};

type StateShape = {
  payload: Object,
};

const contractorToContact = contractor => ({
  salutation: contractor.key_user_salutation || '',
  name: contractor.billing_company,
  email: contractor.key_user_email,
  id: contractor.contractor_id,
  type: 'contractor',
});

const generatePayload = props => {
  const { selectedContractorId } = props;
  return {
    payload: {
      notify_contractor: props.contractors
        .filter(contractor => contractor.contractor_id === selectedContractorId)
        .map(contractorToContact),
      notify_copy: [],
      deny_mail_list: props.contractors
        .filter(contractor => contractor.contractor_id !== selectedContractorId)
        .map(contractorToContact),
    },
  };
};
class AcceptOfferModal extends React.Component<PropsShape, StateShape> {
  static getDerivedStateFromProps(props, state) {
    if (props.contractor) {
      return {
        ...state,
        ...generatePayload(props),
      };
    }
    return state;
  }

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      ...generatePayload(props),
    };
  }

  state: StateShape;

  getLists = () => {
    const baseLists = [
      {
        name: 'notify_contractor',
        label: l('GRANT_JOB-CONTACTS_POPUP-COMMITMENT'),
        rowComponent: NotifyContactRow,
        listQuery: () => getGrantContractors({ job_id: this.props.jobId }),
        initialData: this.state.payload.notify_contractor,
      },
      {
        name: 'notify_copy',
        label: l('GRANT_JOB-CONTACTS_POPUP-INFO_COPY'),
        rowComponent: ContactRow,
        listQuery: () => getJobContacts({ job_id: this.props.jobId }),
      },
    ];
    const deny_mail_list = {
      name: 'deny_mail_list',
      label: l('ACCEPT_OFFERS-deny_to'),
      rowComponent: ContactRow,
      initialData: this.state.payload.deny_mail_list,
    };

    return this.state.payload.deny_mail_list?.length
      ? [...baseLists, deny_mail_list]
      : baseLists;
  };

  submitGrant = (): any => {
    this.props.callback(this.state.payload);
  };

  closeModal = () => {
    this.props.close();
  };

  render = () => (
    <SimplePopup
      cannotOutsideClickClose
      isOpen={this.props.isOpen}
      title={l('ACCEPT_OFFERS-title')}
      close={this.closeModal}
      renderContent={() => {
        return (
          <Fragment>
            <p className="sub-title">{this.props.subTitle}</p>
            <MultiList
              onChange={data => {
                this.setState({ payload: data });
              }}
              lists={this.getLists()}
            />
          </Fragment>
        );
      }}
      options={[
        {
          cb: this.closeModal,
          buttonText: l('cancel'),
          buttonClass: 'btn--text',
          dataQeId: 'accept-offer-cancel',
        },
        {
          cb: this.submitGrant,
          buttonText: l('JOB-ACTIONS-GRANT'),
          buttonClass: 'btn--primary',
          dataQeId: 'action-click-accept-offer',
        },
      ]}
    />
  );
}

export default AcceptOfferModal;
