// @flow
import StateMachine from 'machines/reduxMachine.js';
import { transitions } from 'machines/constants.js';
import { ids as Status } from 'constants/jobStatus';
import { State, Transition, Edge as EdgeBuilder } from 'async-state-machine';
import JobConditions from '../../conditions';

const { isJobStateWithin } = JobConditions;

export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) =>
    isJobStateWithin(params, [
      Status.GRANTED,
      Status.ACCEPTED,
      Status.EXECUTED,
      Status.COMPLETED,
      Status.CANCELLED,
    ]),
  );

function invoiceProcessCardMachine() {
  const Edge = new EdgeBuilder();

  // States for Single Job
  const HIDDEN = Object.create(State).setName('HIDDEN');
  const VISIBLE = Object.create(State).setName('VISIBLE');

  // Register Machine
  const Machine = new StateMachine('invoiceprocess_card')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN])
        .to(VISIBLE),
    )
    .setInitialState(HIDDEN, {}, true);

  return Machine;
}

export default invoiceProcessCardMachine;
