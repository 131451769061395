import React from 'react';
import { connect } from 'react-redux';

import WYSIWYG from 'components/WYSIWYG';

const DescriptionEditor = ({ content, isEditing }) => (
  <WYSIWYG
    id="input-edit-job_description"
    isEditing={isEditing}
    content={content}
    inputClass="form__input--wysiwyg"
    onChange={false}
    paragraphClass="text--dk--flushed--wysiwyg"
  />
);

const mapState = (state, { storeTargetLabel }) => {
  switch (storeTargetLabel) {
    case 'create':
      return {
        content: state.jobAssignStore.createForm.info_summary,
      };
    default:
      return {
        content: state.jobStore.data.job.info_summary,
      };
  }
};

export default connect(mapState)(DescriptionEditor);
