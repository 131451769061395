/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import BudgetPanel from 'shared/jobs/Panels/items/BudgetPanel';
import { PanelStates } from 'machines/constants';
import { StateConditional } from 'machines/components/RenderConditional';
import { updateJobForm } from '../../actions';

import MachineBuilder from './machine';

type ReduxActionsShape = {
  updateField: Function,
};

type ReduxStateShape = {
  data: {
    budget: number,
    remaining_budget: number,
  },
  status: string,
};

export const BudgetCardMachine = MachineBuilder();

const BudgetCard = ({
  status,
  data,
  panelEditMode,
  updateField,
  machine_state,
}) => (
  <StateConditional
    state={machine_state}
    whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
    <BudgetPanel
      data={data}
      updateField={updateField}
      editNow={panelEditMode}
      jobState={status}
    />
  </StateConditional>
);

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.budget_card,
  panelEditMode: machineStore.budget_card === PanelStates.EDITING,

  status: parseInt(jobStore.data.job.info_status, 10),
  data: {
    budget: jobStore.form.budget_estimate,
    remaining_budget:
      jobStore.form.budget_estimate - jobStore.data.job.total_payments || 0,
  },
});

const mapAction = (dispatch: Function): ReduxActionsShape => ({
  updateField({ key, value }): any {
    return dispatch(updateJobForm({ key, value }));
  },
});

export default connect(mapState, mapAction)(BudgetCard);
