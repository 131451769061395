// @flow
import StateMachine from 'machines/reduxMachine.js';
import { transitions } from 'machines/constants.js';
import { ids as StatusIDs } from 'constants/jobStatus';
import {
  State,
  Transition,
  Edge as EdgeBuilder,
  Conditions,
} from 'async-state-machine';
import JobConditions from '../../conditions';

const {
  isPropertyManager,
  isConstructionManager,
  isAssigned,
  isInvitee,
  isOfferJob,
  isOnProjectTeam,
  isProjectJob,
  isProjectOwner,
  isJobStateWithin,
  isCreating,
} = JobConditions;
const { and, not, or } = Conditions;

// Transitions for contractor selector card
export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) =>
    and([not(isInvitee(params)), isOfferJob(params)]),
  );

export const to_editing = Object.create(Transition)
  .setName(transitions.to_editing)
  .addCondition((context, params) => {
    if (isCreating(params)) {
      return isOfferJob(params);
    }

    return and([
      isOfferJob(params),
      isJobStateWithin(params, [StatusIDs.DRAFT]),
      or([
        isProjectOwner(params),
        and([
          or([isPropertyManager(params), isConstructionManager(params)]),
          not(isProjectJob(params)),
        ]),
        and([
          not(or([isAssigned(params), isInvitee(params)])),
          isOnProjectTeam(params),
        ]),
      ]),
    ]);
  });

function generateContractorSelectorCardStateMachine() {
  const Edge = new EdgeBuilder();

  // States for Contractor Selector Card
  const HIDDEN = Object.create(State).setName('HIDDEN');
  const VISIBLE = Object.create(State).setName('VISIBLE');
  const EDITING = Object.create(State).setName('EDITING');

  // Register Machine
  const Machine = new StateMachine('contractorselector_card')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN, EDITING])
        .to(VISIBLE),
    )
    .registerEdge(
      Edge.new()
        .transition(to_editing)
        .from([VISIBLE, HIDDEN])
        .to(EDITING),
    )
    .setInitialState(HIDDEN, {}, true);

  return Machine;
}

export default generateContractorSelectorCardStateMachine;
