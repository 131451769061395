/** @format */

// @flow

import React from 'react';

import GetSvg from 'components/GetSvg';

import l from 'helpers/locale';

import useCardClass from './hooks/useCardClass';

/* Props for Card component */
type PropsShape = {
  identifier?: string,
  children?: any,
  title?: string,
  isOpen?: boolean,
  canCollapse?: boolean,
  asideComponent?: () => React$Element<*>,
  showLock?: boolean,
  showHeader?: boolean,
  hasPadding?: boolean,
  watermarkName?: string,
  qeId?: string | void,
};

function CardHeaderButton({
  title,
  showLock,
  isOpen,
  qeId,
}: {
  title: string,
  showLock: boolean,
  isOpen: boolean,
  qeId: string,
}) {
  return (
    <span className="gw">
      <span className="g g--auto">
        <span className="card__header__title">
          <span>{l(title)}</span>
          <span>
            {showLock ? (
              <GetSvg svg="lock" wrapperClass="card__header__title__svg" />
            ) : null}
          </span>
        </span>
      </span>
      <span className="g g--shrink-wrap">
        <div className="card__header__button-container">
          <span
            className="btn--text--base"
            data-qe-id={`action-toggle_card_visibility-${qeId}`}>
            <GetSvg
              svg={isOpen ? 'angle_down' : 'angle_up'}
              wrapperClass="card__header__handle"
            />
          </span>
        </div>
      </span>
    </span>
  );
}

function CardHeaderDiv({
  title,
  showLock,
  asideComponent,
}: {
  title: string,
  showLock: boolean,
  asideComponent: void | Function,
}) {
  return (
    <div className="gw">
      <div className="g g--auto">
        <div className="card__header__title">
          {l(title)}
          {showLock ? (
            <GetSvg svg="lock" wrapperClass="card__header__title__svg" />
          ) : null}
        </div>
      </div>
      <div className="g g--shrink-wrap">
        {/* does it have an aside component? */}
        {asideComponent ? (
          <div className="card__header__aside">{asideComponent()}</div>
        ) : null}
      </div>
    </div>
  );
}

function CardFooter({ watermarkName }: { watermarkName: string }) {
  if (!watermarkName) return null;

  return (
    <div className="card__watermark">
      <GetSvg wrapperClass="card__watermark__inner" svg={watermarkName} />
    </div>
  );
}

function Card(props: PropsShape) {
  const [isOpen, setOpen] = React.useState(props.isOpen);

  function toggle() {
    setOpen(!isOpen);
  }

  const computedClass = useCardClass(
    isOpen,
    props.hasPadding,
    props.watermarkName,
    props.hasError,
  );

  const qeId = props.qeId || props.title;

  return (
    <div className="card-container">
      <div className={computedClass} data-qe-id={`component-card-${qeId}`}>
        {props.showHeader ? (
          <div className="card__header">
            {props.canCollapse ? (
              <button
                type="button"
                data-qe-id={`action-card-toggle-${props.identifier}`}
                onClick={toggle}
                className="btn--text--base display--block display--full-width">
                <CardHeaderButton
                  title={props.title}
                  showLock={props.showLock}
                  isOpen={isOpen}
                  qeId={qeId}
                />
              </button>
            ) : (
              <div>
                <CardHeaderDiv
                  title={props.title}
                  showLock={props.showLock}
                  asideComponent={props.asideComponent}
                />
              </div>
            )}
          </div>
        ) : null}

        {/* body */}
        <div
          ref={props.containerRef}
          className="card__body"
          data-qe-id={`component-card_body-${qeId}`}>
          {props.children}
        </div>
        {/* footer */}
        <CardFooter watermarkName={props.watermarkName} />
      </div>
    </div>
  );
}

Card.defaultProps = {
  identifier: '',
  children: [],
  canCollapse: true,
  asideComponent: null,
  showLock: false,
  showHeader: true,
  hasPadding: true,
  isOpen: true,
  watermarkName: '',
  title: '',
  qeId: null,
  hasError: false,
};

export default Card;
