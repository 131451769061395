import React from 'react';
import history from 'helpers/history';
import { Names } from './constants';
import { AccountCreate } from './containers';
import { AccountView } from './containers/AccountView';

export function create(parentPath) {
  // create/:role_abbrv
  return {
    [Names.ACCOUNT_CREATE]: {
      position: 1,
      title: '',
      qeId: 'create-account',
      render: () => <AccountCreate />,
      backAction: () => history.push('/accounts'),
      parentPath,
    },
  };
}

export function view(parentPath) {
  return {
    // :account_id
    [Names.ACCOUNT_VIEW]: {
      position: 1,
      title: '',
      qeId: 'view-account',
      render: () => <AccountView />,
      backAction: () => history.push('/accounts'),
      parentPath,
    },
  };
}
