/** @format */

import {
  FLUSH_LIST,
  SET_ITEM,
  REMOVE_ITEM,
  PUBLISH_FAILED_SUBMISSION_FORM,
} from '../constants';

export function flushList(name) {
  return { type: FLUSH_LIST, payload: { name } };
}

export function setListItem(name, message) {
  return { type: SET_ITEM, payload: { name, message } };
}

export function removeListItem(name, id) {
  return { type: REMOVE_ITEM, payload: { name, id } };
}

export function emitAction(name) {
  return { type: name };
}

export function publishFormFailed() {
  return { type: PUBLISH_FAILED_SUBMISSION_FORM };
}
