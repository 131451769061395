import React from 'react';

export default ({ isOpen, children }) => (
  <div
    className={
      isOpen
        ? 'simple-list__body__group__list'
        : 'simple-list__body__group__list--closed'
    }>
    {children}
  </div>
);
