/** @format */

import axios from '../../../helpers/axios';
import Constants from '../constants';
import {
  requestSuccess,
  requestError,
  requestRequest,
} from '../../../functions/actionRequests.func.js';

const { viewAccounts, updateAccounts, ACCOUNTS_REMOVE_DOC } = Constants;
const { success, fetching, failed } = viewAccounts;

export function createAccountFailed() {
  return { type: 'PUBLISH_FAILED_SUBMISSION_FORM' };
}

export function updateAccountFailed() {
  return function dispatchActionUpdateAccountFailed(dispatch) {
    dispatch(requestError(updateAccounts.failed));
  };
}

export function fetchAccounts(page = 1) {
  return function fetchAccountsRequest(dispatch) {
    dispatch(requestRequest(fetching));
    axios
      .get(`/api/1/view/users?page=${page}`)
      .then(res => dispatch(requestSuccess(success, res)))
      .catch(err => dispatch(requestError(failed, err)));
  };
}

export function searchAccounts(searchTerm) {
  return function searchAccountsRequest(dispatch) {
    dispatch(requestRequest(fetching));
    return axios
      .get(`/api/1/view/users?search=${searchTerm}`)
      .then(res => dispatch(requestSuccess(success, res)))
      .catch(err => dispatch(requestError(failed, err)));
  };
}

export function orderAccounts(column, order) {
  return function orderAccountsRequest(dispatch) {
    dispatch(requestRequest(fetching));
    return axios
      .get(`/api/1/view/users?sortBy=${column}&sortType=${order}`)
      .then(res => dispatch(requestSuccess(success, res)))
      .catch(err => dispatch(requestError(failed, err)));
  };
}

export function removeAccountDocument(
  id: string | number,
  target: 'static',
  userId: string | number,
  dispatch: Function,
  api: string,
): any {
  return id !== -1
    ? axios
        .delete(api)
        .then((): any =>
          dispatch({
            type: ACCOUNTS_REMOVE_DOC,
            payload: {
              target,
              id,
            },
          }),
        )
        .catch((): any => dispatch({ type: 'ERROR' }))
    : false;
}
