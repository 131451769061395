/** @format */

import {
  FIELD_CHANGE,
  SET_FIELDS,
  EDIT_TOGGLE,
  REQUEST_LOADING,
  REQUEST_ERROR,
  REQUEST_SUCCESS,
  MAX_RECOMMENDATIONS,
  RECOMMENDATION_TOGGLE,
} from './actions.js';

type StateShape = {
  isEditting: boolean,
  fields: {
    customer_status: string,
    customer_code: string,
    recommended: string,
  },
  request: {
    loading: boolean,
    success: boolean,
    error: boolean,
  },
  maxRecommendation: boolean,
  errors: Object,
};

const defaultRequest = {
  loading: false,
  success: false,
  error: false,
};

const initialState = {
  isEditting: false,
  fields: {
    customer_status: '',
    customer_code: '',
    recommended: '',
  },
  request: defaultRequest,
  maxRecommendation: false,
  errors: {},
};

export function customerRelationshipReducer(
  state: StateShape = initialState,
  { type, name, value, fields, error, ...rest },
) {
  switch (type) {
    case SET_FIELDS:
      return {
        ...state,
        fields,
        maxRecommendation: false,
        errors: {},
      };
    case FIELD_CHANGE:
      return {
        ...state,
        fields: {
          ...state.fields,
          [name]: value,
        },
      };
    case EDIT_TOGGLE:
      return {
        ...state,
        isEditting: Object.prototype.hasOwnProperty.call(rest, 'toggleMode')
          ? rest.toggleMode
          : !state.isEditting,
        maxRecommendation: false,
        errors: {},
      };
    case REQUEST_SUCCESS:
      return {
        ...state,
        request: {
          ...defaultRequest,
          success: true,
        },
        isEditting: false,
        maxRecommendation: false,
        errors: {},
      };
    case REQUEST_ERROR:
      return {
        ...state,
        request: {
          ...defaultRequest,
          error: true,
        },
        errors: error || {},
      };
    case REQUEST_LOADING:
      return {
        ...state,
        request: {
          ...defaultRequest,
          loading: true,
        },
      };
    case MAX_RECOMMENDATIONS:
      return {
        ...state,
        request: {
          ...defaultRequest,
          success: true,
        },
        maxRecommendation: true,
      };
    case RECOMMENDATION_TOGGLE:
      return {
        ...state,
        maxRecommendation: !state.maxRecommendation,
      };
    default:
      return state;
  }
}
