/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import DescriptionProjectPanel from 'shared/projects/panels/items/Description';
import { updateForm } from '../../actions';
import MachineBuilder from './machine';

type ReduxStateShape = {
  title: string,
  summary: string,
  status: string,
  onChange: ({
    target: string,
    targetId: string,
  }) => any,
};

type PropsShape = ReduxStateShape;

export const DescriptionCardMachine = MachineBuilder();

const DescriptionCard = ({
  title,
  summary,
  status,
  onChange,
  updateFormField,
  machine_state,
  panelEditMode,
}: PropsShape): React$Element<*> => (
  <StateConditional
    state={machine_state}
    whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
    <DescriptionProjectPanel
      data={{ summary, title }}
      projectStatus={status}
      isEditing={panelEditMode}
      onChange={onChange}
      updateField={updateFormField}
      storeTargetLabel="exists"
    />
  </StateConditional>
);

const mapState = ({ machineStore, projectStore }): ReduxStateShape => ({
  machine_state: machineStore.description_card,
  panelEditMode: machineStore.description_card === PanelStates.EDITING,
  summary: projectStore.data.summary,
  title: projectStore.data.title,
  status: projectStore.data.status,
});

const mapActions = (dispatch: Function) => ({
  updateFormField(key: string, value: string): any {
    return dispatch(updateForm(key, value));
  },
  onChange(key: string, value: string): any {
    return dispatch({ type: 'TODO', value });
  },
});

export default connect(mapState, mapActions)(DescriptionCard);
