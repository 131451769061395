import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import SingleJobView from 'views/singleJob';
import { connect } from 'react-redux';
import { updateTitle } from 'components/RouteSlider/actions';
import { Names } from '../constants';

const PropertyJobRender = ({ setTitle, history, match }) => {
  useEffect(() => {
    setTitle('-');
    return () => setTitle('-');
  }, [setTitle]);
  return (
    <SingleJobView
      backAction={() => history.push(`/properties/${match.params.property_id}`)}
      routeName={Names.PROPERTY_JOB}
    />
  );
};

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.PROPERTY_JOB, title));
  },
});

export const PropertyJobView = withRouter(
  connect(null, mapActions)(PropertyJobRender),
);
