/** @format */

import React from 'react';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import { StateConditional } from 'machines/components/RenderConditional';
import { updateJobForm } from '../../actions';
import ContractorSelectorPanel from '../../../../shared/jobs/Panels/items/ContractorSelector';
import MachineBuilder from './machine';

type ReduxActionShape = {
  updateField: Function,
};

type ReduxStateShape = {
  status: string,
  contractorsList?: Array,
  concernId: string,
  concernType: string,
};

export const ParticipantsCardMachine = MachineBuilder();

const ParticipantsCard = ({
  status,
  contractorsList,
  concernId,
  concernType,
  panelEditMode,
  updateField,
  machine_state,
}) => (
  <StateConditional state={machine_state} whenState={['VISIBLE', 'EDITING']}>
    <ContractorSelectorPanel
      data={{
        isEditing: panelEditMode,
        listName: 'single_job_contractors',
        concernId,
        concernType,
        jobState: status,
        prefillData: contractorsList,
        label: l('PARTICIPANTS-singular'),
        updateField,
      }}
    />
  </StateConditional>
);

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  panelEditMode: machineStore.contractorselector_card === 'EDITING',
  machine_state: machineStore.contractorselector_card,
  status: jobStore.data.job.info_status,
  contractorsList: jobStore.data.job.vmp_subscribers || [],
  concernId: jobStore.data.job.concern.concern_id,
  concernType: jobStore.data.job.concern.concern_type,
});

const mapActions = (dispatch: Function): ReduxActionShape => ({
  updateField({ key, value }: { key: string, value: string }): Function {
    return dispatch(updateJobForm({ key, value }));
  },
});

export default connect(mapState, mapActions)(ParticipantsCard);
