import React from 'react';
import history from 'helpers/history';
import { Names } from './constants';
import { PropertyView, PropertyJobView } from './containers';

export function view() {
  return {
    // properties/:property_id
    [Names.PROPERTY_VIEW]: {
      position: 1,
      title: '',
      qeId: 'view-property',
      render: () => <PropertyView />,
      backAction: () => history.push('/properties'),
      children: {
        // properties/:property_id/jobs/:job_id(\\d+)
        [Names.PROPERTY_JOB]: {
          position: 2,
          title: '',
          render: () => <PropertyJobView />,
        },
      },
    },
  };
}
