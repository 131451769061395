import React from 'react';
import { connect } from 'react-redux';
import Profile from 'shared/contractor/Profile';
import { ids as RoleId } from 'constants/roles';

const ProfileViewRender = ({ context, action_bar_name }) => {
  return (
    <Profile
      backAction={context.backAction}
      scrollToTop={context.scrollToTop}
      action_bar_name={action_bar_name}
    />
  );
};

const mapProps = ({ authStore }) => ({
  action_bar_name:
    authStore.user.role === RoleId.CONTRACTOR
      ? 'contractor_dashboard' // send notifications to the contractor dash
      : 'single_contractor',
});

export const ProfileView = connect(mapProps)(ProfileViewRender);
