// @flow
import { ids as Status } from 'constants/projectStatus';
import StateMachine from 'machines/reduxMachine';
import { transitions, PanelStates } from 'machines/constants';
import {
  State,
  Transition,
  Edge as EdgeBuilder,
  Conditions,
} from 'async-state-machine';

import ProjectConditions from '../../conditions';

const { isProjectOwner, isProjectStateWithin } = ProjectConditions;
const { and } = Conditions;

// Transitions for accounts card
export const to_visible = Object.create(Transition).setName(
  transitions.to_visible,
);

export const to_editing = Object.create(Transition)
  .setName(transitions.to_editing)
  .addCondition((context, params) =>
    and([
      isProjectOwner(params),
      isProjectStateWithin(params, [
        Status.DRAFT,
        Status.CANCELLED,
        Status.COMPLETED,
        Status.OPENED,
        Status.EXECUTING,
      ]),
    ]),
  );

function generateStateMachine() {
  const Edge = new EdgeBuilder();

  // States for Accounts Card
  const HIDDEN = Object.create(State).setName(PanelStates.HIDDEN);
  const VISIBLE = Object.create(State).setName(PanelStates.VISIBLE);
  const EDITING = Object.create(State).setName(PanelStates.EDITING);

  // Register Machine
  const Machine = new StateMachine('addresses_card')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN, EDITING])
        .to(VISIBLE),
    )
    .registerEdge(
      Edge.new()
        .transition(to_editing)
        .from([VISIBLE])
        .to(EDITING),
    )
    .setInitialState(HIDDEN, {});

  return Machine;
}

export default generateStateMachine;
