import React from 'react';
import GetSvg from 'components/GetSvg';

type ContactRowProps = {
  data: Object,
  onRemoveClick: Function,
  nameId: string,
  emailId: string,
};

const ContactRow = ({
  data,
  onRemoveClick,
  nameId = '',
  emailId = '',
}: ContactRowProps) => {
  return (
    <div className="gw">
      <div className="g g--auto">
        <div className="gw">
          <div className="g g-1/2">
            <span data-qe-id={nameId}>
              {`${data.salutation || ''} ${data.full_name || data.name || ''}`}
            </span>
          </div>
          <div className="g g-1/2" data-qe-id={emailId}>
            {data.email}
          </div>
        </div>
      </div>
      <div className="g g--shrink-wrap">
        <button
          type="button"
          className="btn--text--secondary"
          onClick={(e: Object): any => {
            e.preventDefault();
            return onRemoveClick();
          }}>
          <GetSvg svg="circle_clear" />
        </button>
      </div>
    </div>
  );
};

export default ContactRow;
