import React, { Fragment } from 'react';
import formatString from 'functions/formatString';

const ContractorColumnFormatter = ({ data }) => {
  if (Array.isArray(data)) {
    return data.map((item, key) => <span key={key}>{formatString(item)}</span>);
  }
  return (
    <Fragment>
      <span>{formatString(data.company_name)}</span>
      <span>{formatString(data.contact_name)}</span>
      <span>{formatString(data.contact_phone)}</span>
      <span>{formatString(data.contact_email)}</span>
    </Fragment>
  );
};

export default ContractorColumnFormatter;
