import React from 'react';
import { withRouter } from 'react-router-dom';
import SinglePropertyView from 'views/properties/components/SingleProperty';
import { connect } from 'react-redux';
import { updateTitle } from 'components/RouteSlider/actions';
import { Names } from '../constants';

const PropertyViewRender = ({ setTitle, context }) => {
  return (
    <SinglePropertyView
      backAction={context.backAction}
      scrollToTop={context.scrollToTop}
      dataUpdatedCB={payload => setTitle(payload.street)}
      enableDetailView
    />
  );
};

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.PROPERTY_VIEW, title));
  },
});

export const PropertyView = withRouter(
  connect(null, mapActions)(PropertyViewRender),
);
