import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Form from 'components/Form';
import UntilReady from 'components/UntilReady';
import { updateSimpleList } from 'components/ConnectedSimpleList/actions';
import FooterBar from 'components/FooterBar';
import axios from 'helpers/axios';
import l from 'helpers/locale';
import { interpretErrorMessage } from 'helpers/formatErrors';
import { SliderContext } from 'components/RouteSlider';
import hasOwn from 'functions/hasOwn.func.js';
import FormConfig, { validationConfig } from './paymentFormConfig.js';

class EditPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request: {
        success: false,
        failed: false,
        message: '',
        fetching: true,
      },
      data: {},
    };
    this.getForm();
  }

  getForm = () =>
    axios
      .get(this.fetchApi)
      .then(this.handleResponse)
      .catch(this.handleError);

  fetchApi = `/api/1/projects/${this.props.match.params.project_id}/payments/${this.props.match.params.payment_id}`;

  submitApi = `/api/1/projects/${this.props.match.params.project_id}/payments/${this.props.match.params.payment_id}/action/update`;

  deleteApi = `/api/1/projects/${this.props.match.params.project_id}/payments/${this.props.match.params.payment_id}/action/delete`;

  submitForm = data =>
    axios
      .post(this.submitApi, data)
      .then(this.handleSubmitted)
      .catch(this.handleError);

  cancelForm = () => this.props.history.goBack();

  handleResponse = res =>
    this.setState({
      request: {
        success: true,
        failed: false,
        message: '',
        fetching: false,
      },
      data: res.data.payload,
    });

  handleError = err =>
    this.setState({
      request: {
        success: false,
        failed: true,
        message: interpretErrorMessage(err),
        fetching: false,
      },
    });

  handleSubmitted = () => {
    this.props.dispatch(updateSimpleList('project_payments_list'));
    return this.props.history.goBack();
  };

  validator = fields => {
    let failedFields = {};
    let result = true;
    validationConfig.map(v => {
      if (!hasOwn(fields, v) || fields[v] === '' || fields[v] === null) {
        result = false;
        failedFields = { ...failedFields, [v]: 'VALIDATION-required' };
        return false;
      }

      return true;
    });

    return {
      result,
      fields: failedFields,
    };
  };

  deletePayment = () =>
    axios
      .post(this.deleteApi)
      .then(this.handleSubmitted)
      .catch();

  actionOverrides = () => ({
    'cancel-form': {},
    trash: {
      attrs: {
        className: 'btn--secondary',
        'data-qe-id': 'action-delete-form',
        type: 'button',
      },
      action: () => this.deletePayment(),
      label: l('PAYMENT-delete'),
    },
    submit: {},
  });

  render() {
    return (
      <SliderContext.Consumer>
        {context => (
          <UntilReady
            ready={this.state.request.success}
            waiting={this.state.request.fetching}
            notReadyOrWaitingText={this.state.request.message}>
            <Form
              scrollToTop={context.scrollToTop}
              sections={FormConfig(this.state.data)}
              submitAct={this.submitForm}
              cancelAct={this.cancelForm}
              wrappingClass="gw"
              wrappingOuterClass=""
              validateBefore={this.validator}
              renderFooter={actions => (
                <FooterBar>
                  <div className="fl-right">
                    {Object.keys(this.actionOverrides())
                      .map(aok => ({
                        ...actions.find(act => act.name === aok),
                        ...this.actionOverrides()[aok],
                      }))
                      .map((item, k) => (
                        <div className="fl-right__item">
                          <button
                            type="button"
                            {...item.attrs}
                            key={k}
                            onClick={item.action}
                            data-qe-id={`add-payment-${item.attrs['data-qe-id']}`}>
                            {l(item.label)}
                          </button>
                        </div>
                      ))}
                  </div>
                </FooterBar>
              )}
            />
          </UntilReady>
        )}
      </SliderContext.Consumer>
    );
  }
}

export default withRouter(connect()(EditPayment));
