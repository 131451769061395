import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import FooterBar from 'components/FooterBar';
import GraphQLPageReady from 'components/GraphQLPageReady';
import l from 'helpers/locale';
import Cards from './cards';
import { propertyQuery } from './query';

const {
  ObjectsCard,
  HousesCard,
  ContactsCard,
  MainCard,
  NotificationsCard,
  JobsCard,
} = Cards;

type PropsShape = {
  backAction: Function,
  dataUpdatedCB: Function,
  enableDetailView: boolean,
};

type StateShape = {
  data: Object,
};

class SingleProperty extends React.Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      data: {},
    };
  }

  state: StateShape;

  componentDidUpdate(prevProps: PropsShape) {
    if (
      prevProps.match.params.property_id !==
        this.props.match.params.property_id &&
      this.props.match.params.property_id !== undefined
    ) {
      this.fetchData();
    }
  }

  onGraphQLResponse = responseData => {
    const property = responseData?.data?.data?.property || {};
    this.setState({
      data: property,
    });
    this.props.dataUpdatedCB(property);
  };

  graphi: GraphQLPageReady;

  fetchData = () => {
    this.graphi.refreshPage();
  };

  render(): React$Element<*> {
    const { data: payload } = this.state;
    return (
      <Fragment>
        <GraphQLPageReady
          ref={inst => {
            this.graphi = inst;
          }}
          onData={this.onGraphQLResponse}
          queryId="getProperty"
          queryString={propertyQuery(this.props.match.params.property_id)}>
          <div className="push--bottom">
            <MainCard {...payload} />
          </div>
          <div className="push--bottom">
            <ContactsCard
              contacts={[
                payload.manager,
                payload.assistant,
                ...(payload.caretakers || []),
              ]}
            />
          </div>
          <div className="push--bottom">
            <NotificationsCard
              notifications={payload.notifications}
              property={{ ...payload, id: this.props.match.params.property_id }}
              total={payload.notifications_count}
            />
          </div>
          <div className="push--bottom">
            <JobsCard
              enableDetailView={this.props.enableDetailView}
              id={this.props.match.params.property_id}
              apiEndpoint={`/api/1/view/properties/${this.props.match.params.property_id}/jobs`}
              listName="single_property_jobs"
            />
          </div>
          <div className="push--bottom">
            <HousesCard id={this.props.match.params.property_id} />
          </div>
          <div className="push--bottom">
            <ObjectsCard
              data={payload.assets}
              id={this.props.match.params.property_id}
            />
          </div>
          <FooterBar>
            <div className="fl-right">
              <div className="fl-right__item">
                <button
                  type="button"
                  className="btn--base--transp"
                  onClick={this.props.backAction}>
                  {l('JOB-ACTIONS-BACK')}
                </button>
              </div>
            </div>
          </FooterBar>
        </GraphQLPageReady>
      </Fragment>
    );
  }
}

export default withRouter(SingleProperty);
