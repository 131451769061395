export function requestSuccess(
  type: string,
  payload: Object,
  props?: Object = {},
): Object {
  return { type, payload: payload.data, ...props };
}

export function requestError(
  type: string,
  error: any,
  props?: Object = {},
): Object {
  return { type, error, ...props };
}

export function requestRequest(type: string, props?: Object = {}): Object {
  return { type, ...props };
}
