/** @format */

// @flow

import React from 'react';
import type { DocumentType } from 'views/singleJob/constants';
import Panel from '../GenericDocuments';

type PropsShape = {
  listName: string,
  list?: [DocumentType],
  data: any,
  controls: Array<*>,
};

const DocumentExchangePanelWrapper = ({
  data,
  list,
  listName,
  controls,
}: PropsShape): React$Element<*> => (
  <Panel
    data={data}
    listName={listName}
    list={list}
    jobId={data.jobId}
    controls={controls}
    title="EXCHANGE_DOCS-title"
    identifier="action-drop-exchange_document"
    isOpen={false}
    footer="EXCHANGE_DOCS-description"
  />
);

DocumentExchangePanelWrapper.defaultProps = {
  list: [],
};

export default DocumentExchangePanelWrapper;
