import hasOwnVal from 'functions/hasOwnVal.func';
import { request } from 'constants/request';
import type { RequestShape } from 'constants/request';
import JobActions from 'shared/jobs/Panels/constants';
import {
  OFFER_VARIANT_RESET_STATE,
  OFFER_VARIANT_SET_OFFERS,
} from 'views/jobs/components/offerForm/actions';
import SingleJobConstants from '../constants';

const {
  getJob,
  UPDATE_JOB_FORM,
  REMOVE_JOB_DOC,
  ADD_JOB_DOC,
  UPDATE_CONTACT,
  CREATE_CONTACT,
  UPDATE_JOB_CONCERN,
  UPDATE_JOB_CONTRACTOR,
  CLEAR_SUB_REQUEST,
} = SingleJobConstants;

type StateShape = {
  request: RequestShape,
  data: Object,
  editNow: boolean,
  form: Object,
  errors: { [string]: string[] },
  machine: Object,
};

type PropsShape = {
  type: string,
  payload?: Object,
  target?: string,
  rest: mixed,
};

const initialState: StateShape = {
  request,
  editNow: false,
  lastRefresh: 0,
  data: {
    offers: [],
    job: null,
  },
  form: {
    agreement_price: null,
    agreement_includes_vat: '',
    agreement_discount: '',
    agreement_cash_discount: '',
    agreement_payment_inert: null,
    info_summary: '',
    info_occasion: '',
    info_desired_date: '',
    order_date: '',
    info_desired_date_comment: '',
    info_target_date_submission: '',
    trade_id: null,
    building_id: null,
  },
  subRequest: {
    ...request,
  },
  errors: {},
};

function filterFormUpdateErrors(errors: any, formUpdate: any): any {
  const newObject = {};
  Object.keys(errors)
    .filter((key: string): boolean => key !== formUpdate.key)
    .map((item: string): Object => ({ [item]: errors[item] }))
    .forEach((item: any): any => {
      newObject[Object.keys(item)[0]] = item[Object.keys(item)[0]];
      return item;
    });
  return newObject;
}

export default function SingleJobReducer(
  state: StateShape = initialState,
  { type, payload, target, ...rest }: PropsShape,
): StateShape {
  let updater = {};
  let index = -1;
  switch (type) {
    case 'REFRESH_JOB':
      return {
        ...state,
        lastRefresh: Date.now(),
      };
    case 'FETCH_JOB_ASSIGN_JOB_DATA_REQUEST_SUCCESS':
    case getJob.success:
      return {
        ...state,
        subRequest: request,
        editNow: false,
        request: {
          ...request,
          success: true,
        },
        data:
          payload !== undefined
            ? { ...state.data, ...payload.data }
            : { ...initialState.data },
        form:
          payload !== undefined
            ? {
                selected_contractors_ids: payload.data.job.vmp_subscribers.map(
                  item => item.contractor_id,
                ),
                concern: payload.data.job.concern,
                accounting: payload.data.job.accounting,
                cost_center: payload.data.job.cost_center,
                vmp_contractor: payload.data.job.vmp_contractor,
                agreement_price: payload.data.job.agreement_price,
                agreement_includes_vat: payload.data.job.agreement_includes_vat,
                agreement_discount: payload.data.job.agreement_discount,
                agreement_cash_discount:
                  payload.data.job.agreement_cash_discount,
                agreement_payment_inert:
                  payload.data.job.agreement_payment_inert,
                info_summary: payload.data.job.info_summary,
                info_occasion: payload.data.job.info_occasion,
                info_desired_date: payload.data.job.info_desired_date,
                info_order_date: payload.data.job.info_target_date,
                info_desired_date_comment:
                  payload.data.job.info_desired_date_comment,
                trade_id: payload.data.job.trade?.key || null,
                building_id: payload.data.job.building?.key || null,
                account_id: payload.data.job.accounting?.account_id || null,

                concern_id: payload.data.job.concern.concern_id,
                concern_type: payload.data.job.concern.concern_type,
                cost_center_id:
                  payload.data.job.cost_center?.cost_center_id || null,
                account_description: payload.data.job.account_description,
                info_target_date_submission:
                  payload.data.job.info_target_date_submission,
                budget_estimate: payload.data.job.budget_estimate,
                contact_allianz: payload.data.job.contact_allianz,
                contact_care_taker: payload.data.job.contact_care_taker,
                contact_delivery: payload.data.job.contact_delivery,
                contact_tenant: payload.data.job.contact_tenant,
                project_id: payload.data.job.project_id,

                contact_allianz_id:
                  payload.data.job.contact_allianz?.contact_id,
                contact_tenant_id: payload.data.job.contact_tenant?.contact_id,
                contact_care_taker_id:
                  payload.data.job.contact_care_taker?.contact_id,
                contact_delivery_id:
                  payload.data.job.contact_delivery?.contact_id,
                contact_delivery_type:
                  payload.data.job.contact_delivery?.contact_type,
              }
            : { ...initialState.form },
        errors: {},
        machine: payload ? payload.machine : {},
      };
    case 'JOB_UPDATE_CONTRACTOR':
      return {
        ...state,
        form: {
          ...state.form,
          vmp_contractor: payload.contractor,
          contractor_id: payload.contractor.contractor_id,
          trade_id: payload.contractor.trade_id,
        },
      };
    case getJob.failed:
      return {
        ...state,
        request: {
          ...request,
          failed: true,
        },
      };
    case getJob.fetching:
      return {
        ...state,
        request: {
          ...request,
          fetching: true,
        },
        data: {
          ...state.data,
        },
      };
    case JobActions.cancelEdit:
      return {
        ...state,
        editNow: false,
        form: {
          concern: state.data.job.concern,
          vmp_contractor: state.data.job.vmp_contractor,
          agreement_price: state.data.job.agreement_price,
          agreement_includes_vat: state.data.job.agreement_includes_vat,
          agreement_discount: state.data.job.agreement_discount,
          agreement_cash_discount: state.data.job.agreement_cash_discount,
          agreement_payment_inert: state.data.job.agreement_payment_inert,
          info_summary: state.data.job.info_summary,
          info_occasion: state.data.job.info_occasion,
          info_desired_date: state.data.job.info_desired_date,
          info_order_date: state.data.job.info_target_date,
          info_desired_date_comment: state.data.job.info_desired_date_comment,
          trade_id: state.data.job.trade.key,
          building_id: state.data.job.building.key,
          info_target_date_submission:
            state.data.job.info_target_date_submission,
          budget_estimate: state.data.job.budget_estimate,
          account_id: state.data.job.accounting?.account_id,
          cost_center_id: state.data.job.accounting?.cost_center_id,
          account_description: state.data.job.account_description,

          concern_id: state.data.job.concern.concern_id,
          concern_type: state.data.job.concern.concern_type,
          contact_allianz: state.data.job.contact_allianz,
          contact_care_taker: state.data.job.contact_care_taker,
          contact_delivery: state.data.job.contact_delivery,
          contact_tenant: state.data.job.contact_tenant,

          contact_allianz_id: state.data.job.contact_allianz?.contact_id,
          contact_tenant_id: state.data.job.contact_tenant?.contact_id,
          contact_care_taker_id: state.data.job.contact_care_taker?.contact_id,
          contact_delivery_id: state.data.job.contact_delivery?.contact_id,
          contact_delivery_type: state.data.job.contact_delivery?.contact_type,
        },
      };
    case UPDATE_JOB_FORM:
      return {
        ...state,
        form: {
          ...state.form,
          [payload.key]: payload.value,
        },
        errors: filterFormUpdateErrors(state.errors, payload),
      };
    case REMOVE_JOB_DOC:
      index = state.data.job.documents.findIndex(
        (v: Object): boolean => v.document_id === payload.id,
      );

      return {
        ...state,
        data: {
          ...state.data,
          job: {
            ...state.data.job,
            documents: [
              ...state.data.job.documents.slice(0, index),
              ...state.data.job.documents.slice(index + 1),
            ],
          },
        },
      };
    case ADD_JOB_DOC:
      return {
        ...state,
        data: {
          ...state.data,
          job: {
            ...(state.data.job || {}),
            documents: [
              ...(state.data.job ? state.data.job.documents : []),
              {
                ...rest.file,
                document_id: rest.file.id,
                user: rest.file.user,
                state_machine: {
                  status: rest.machine.current,
                  available_transitions: rest.machine.transitions,
                },
              },
            ],
          },
        },
      };
    case 'JOB_CONTACTS_PUSH_CONTACT': // see PR #615
      return {
        ...state,
        form: {
          ...state.form,
          contact_delivery_type: 0,
          contact_delivery_id: null,
          contact: {
            surname: payload.surname,
            street: payload.street,
            city: payload.city,
            email: payload.email,
            name: payload.name,
            postal_code: payload.postal_code,
            telephone: payload.telephone,
            id: payload.id || null,
          },
        },
      };
    case 'JOB_CONTACTS_ADD_CONTACT':
      updater = {};

      if (['delivery', 'contractor'].includes(target)) {
        updater[`contact_${target}_type`] = rest.contactObj.type;
      }
      return {
        ...state,
        form: {
          ...state.form,
          [`contact_${target}_id`]: payload,
          ...updater,
        },
      };
    case 'JOB_ASSIGN_UPDATE_JOB_ASSIGN_FORM':
      return {
        ...state,
        errors: filterFormUpdateErrors(state.errors, payload),
      };
    case 'CREATE_JOB_ASSIGN_REQUEST_FAILED':
      return {
        ...state,
        errors: payload,
      };
    case 'FETCH_JOB_ASSIGN_JOB_DATA_REQUEST_FETCHING':
      return {
        ...state,
        errors: {},
      };

    case 'FLUSH_SINGLE_JOB_STATE':
      return {
        ...state,
        ...initialState,
      };
    case OFFER_VARIANT_RESET_STATE:
      return {
        ...state,
        data: {
          ...state.data,
          offers: [],
        },
      };
    case OFFER_VARIANT_SET_OFFERS:
      return {
        ...state,
        data: {
          ...state.data,
          offers: payload,
        },
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        form: {
          ...state.form,
          [target]: {
            ...payload,
            // because contacts from _list graph fields dont have state machines
            state_machine: state.form[target].state_machine,
          },
          [`${target}_id`]: payload ? payload.contact_id : null,
          ...(target === 'contact_delivery' && payload
            ? {
                contact_delivery_type: payload.parentType,
              }
            : {}),
        },
      };
    case CREATE_CONTACT:
      return {
        ...state,
        form: {
          ...state.form,
          [target]: {
            ...payload,
            // because contacts from _list graph fields dont have state machines
            state_machine: state.form[target].state_machine,
          },
          [`${target}_id`]: payload.contact_id,
          [`${target}_type`]: 'contact',
        },
      };
    case UPDATE_JOB_CONCERN:
      return {
        ...state,
        form: {
          ...state.form,
          concern: payload,
          concern_id: payload.concern_id,
          concern_type: payload.concern_type,
          accounting: null,
          account_id: null,
          cost_center_id: null,
          cost_center: null,
        },
      };
    case UPDATE_JOB_CONTRACTOR:
      return {
        ...state,
        form: {
          ...state.form,
          vmp_contractor: payload,
          contractor_id: payload.contractor_id,
          account_id: null,
          cost_center_id: null,
        },
      };

    // sub requests
    case JobActions.copy.fetching:
    case JobActions.grant.fetching:
    case JobActions.resend_grant.fetching:
    case JobActions.erase.fetching:
    case JobActions.requestOffers.fetching:
    case JobActions.change.fetching:
    case JobActions.inviteContractor.fetching:
    case JobActions.complete.fetching:
    case JobActions.withdrawTender.fetching:
    case JobActions.createOffer.fetching:
    case JobActions.createVariant.fetching:
    case JobActions.confirmReceipt.fetching:
    case JobActions.execute.fetching:
    case JobActions.cancel.fetching:
    case JobActions.accept.fetching:
    case JobActions.noInterest.fetching:
    case JobActions.addSingleContractor.fetching:
      return {
        ...state,
        subRequest: {
          ...request,
          fetching: true,
        },
      };

    case JobActions.copy.failed:
    case JobActions.grant.failed:
    case JobActions.resend_grant.failed:
    case JobActions.erase.failed:
    case JobActions.requestOffers.failed:
    case JobActions.inviteContractor.failed:
    case JobActions.complete.failed:
    case JobActions.withdrawTender.failed:
    case JobActions.createOffer.failed:
    case JobActions.createVariant.failed:
    case JobActions.confirmReceipt.failed:
    case JobActions.execute.failed:
    case JobActions.cancel.failed:
    case JobActions.accept.failed:
    case JobActions.noInterest.failed:
    case JobActions.addSingleContractor.failed:
      return {
        ...state,
        subRequest: {
          ...request,
          failed: true,
        },
      };

    case JobActions.submit.failed:
    case JobActions.change.failed:
      return {
        ...state,
        subRequest: {
          ...request,
          failed: !hasOwnVal(payload, 'status', 422),
        },
        errors: hasOwnVal(payload, 'status', 422)
          ? payload.data.payload
          : state.errors,
      };

    case JobActions.copy.success:
    case JobActions.grant.success:
    case JobActions.resend_grant.success:
    case JobActions.erase.success:
    case JobActions.requestOffers.success:
    case JobActions.change.success:
    case JobActions.inviteContractor.success:
    case JobActions.complete.success:
    case JobActions.withdrawTender.success:
    case JobActions.createOffer.success:
    case JobActions.createVariant.success:
    case JobActions.confirmReceipt.success:
    case JobActions.execute.success:
    case JobActions.cancel.success:
    case JobActions.accept.success:
    case JobActions.noInterest.success:
    case JobActions.addSingleContractor.success:
      return {
        ...state,
        subRequest: {
          ...request,
          success: true,
        },
      };
    case CLEAR_SUB_REQUEST:
      return {
        ...state,
        subRequest: {
          ...request,
        },
      };
    default:
      return state;
  }
}
