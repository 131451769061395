/** @format */

import assign from 'object.assign';
import authConst, { auth } from '../constants/auth';

const initialAuthState = {
  auth: false,
  token: '',
  user: {
    email: '',
    id: null,
    name: '',
    role: null,
  },
  request: {
    fetching: false,
    success: false,
    failed: false,
  },
};

export const userAuthReducer = (
  state = initialAuthState,
  { type, payload },
) => {
  switch (type) {
    case authConst.fetching:
      return assign({}, initialAuthState, {
        request: assign({}, initialAuthState.request, {
          fetching: true,
        }),
      });
    case authConst.failed:
      return assign({}, initialAuthState, {
        request: assign({}, initialAuthState.request, {
          failed: true,
        }),
      });
    case authConst.success:
      return assign({}, initialAuthState, {
        auth: true,
        request: assign({}, initialAuthState.request, {
          success: true,
        }),
        user: assign({}, payload),
      });
    case auth.AUTH_REMOVE:
      return assign({}, state, {
        auth: false,
      });
    case auth.AUTH_SET_TOKEN:
      return {
        ...state,
        token: payload,
      };
    default:
      return state;
  }
};
