/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import ProjectTeamDocs from 'shared/projects/panels/items/ProjectTeamDocuments';
import type { DocumentType } from 'views/singleJob/constants';
import DocConstants from 'components/connected/DocumentUploader/constants';
import { removeProjectDocument } from '../../actions';

const { DELETE, VIEW, DOWNLOAD } = DocConstants.DOCUMENT_ACTION;

type ReduxActionShape = {
  removeImage: Function,
};

type ReduxStateShape = {
  status: string,
  documentList: [DocumentType],
};

type PropsShape = {
  projectId: string | number,
} & ReduxStateShape &
  ReduxActionShape;

const ProjectTeamDocuments = ({
  status,
  projectId,
  removeImage,
  documentList,
}: PropsShape): React$Element<*> => (
  <ProjectTeamDocs
    listName="project_team"
    data={{
      uploadProps: {
        source_tag: 'project_team',
        source_type: 'project',
        source_id: projectId,
        projectId,
      },
      endpoint: '/api/1/documents/action/add',
    }}
    projectStatus={status}
    list={documentList}
    controls={[
      {
        documentAction: DELETE,
        svgName: 'close',
        action: (id: string | number, api: string): any => removeImage(id, api),
        canDisplay: true,
      },
      {
        documentAction: VIEW,
        svgName: 'view',
        action: null,
        canDisplay: true,
      },
      {
        documentAction: DOWNLOAD,
        svgName: 'download',
        action: null,
        canDisplay: true,
      },
    ]}
  />
);

const mapState = ({ projectStore, authStore }): ReduxStateShape => ({
  status: projectStore.data.status,
  userRole: authStore.user.role,

  documentList:
    projectStore.data.documents.filter(
      doc => doc.source_tag === 'project_team',
    ) || [],

  isEditing: projectStore.isEditing,
  projectId: projectStore.data.project_id,
});

const mapActions = (
  dispatch: Function,
  { projectId }: { projectId: string | number },
): ReduxActionShape => ({
  removeImage(id: string | number, api: string): any {
    return removeProjectDocument(
      id,
      'project_team',
      projectId,
      dispatch,
      false,
      api,
    );
  },
});

export default connect(mapState, mapActions)(ProjectTeamDocuments);
