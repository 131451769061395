import React from 'react';
import GenericNotifications from 'components/GenericNotifications';
import Card from 'components/Card';
import { getRatings } from '../query';
import RatingsListRenderer, {
  StarList,
} from '../components/RatingsListRenderer';
import type { RatingShape } from '../components/RatingsListRenderer';

type PropsShape = {
  notifications?: Array<RatingShape>,
};

const RatingsCard = ({
  notifications,
  contractorId,
  contractor,
  total,
  currentRating,
}: PropsShape): React$Element<*> => (
  <Card title="RATINGS-title">
    <div id="ratings-card-star-list">
      <StarList rating={currentRating} />
    </div>
    <GenericNotifications
      asideTitle={contractor.billing_company}
      titlePluralKey="RATINGS-title-plural"
      asideCardTitle="RATINGS-title-plural"
      containerComponent={RatingsListRenderer}
      notifications={notifications}
      total={total}
      asideRequest={() => getRatings(contractorId)}
    />
  </Card>
);

RatingsCard.defaultProps = {
  notifications: [],
};

export default RatingsCard;
