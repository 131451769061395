import React from 'react';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import Notification from 'components/Notification';
import graphi from 'helpers/graphi';
import { transitions as complianceTransitions } from 'constants/complianceStates';
import { setStatusBar } from '../../actions/app';

const getComplianceStateQuery = () => `
{
  vmp_contractor {
    compliance {
      state_machine {
        status,
        available_transitions {
          name
        }
      }
    }
  }
}
`;

const translateAndSubstitute = rawString =>
  l(rawString).replace(':compliance_link', '"/#/compliance-questionnaire"');

const generateMessagesFromComplianceStateResponse = ({ data }) => {
  const transitions =
    data.vmp_contractor.compliance?.state_machine?.available_transitions ||
    null;

  let message = null;

  if (
    transitions.findIndex(v => v.name === complianceTransitions.submit) !== -1
  ) {
    message = translateAndSubstitute('COMPLIANCE-contractor_notification');
  }

  return message;
};

function useSetMessageFromGraphQuery(update) {
  React.useEffect(() => {
    graphi(getComplianceStateQuery(), 'getComplianceState')
      .then(response => {
        update(generateMessagesFromComplianceStateResponse(response.data));
      })
      .catch(() => update(''));
  }, [update]);
}

function ContractorNotifcations({ message, setMessage }) {
  useSetMessageFromGraphQuery(setMessage);

  return <Notification notification={message} />;
}

const mapActions = dispatch => {
  return {
    setMessage: message => dispatch(setStatusBar(message)),
  };
};

const mapProps = ({ appStore }) => {
  return {
    message: appStore.statusBarMessage,
  };
};

export default connect(mapProps, mapActions)(ContractorNotifcations);
