/** @format */

// @flow
import React, { Fragment } from 'react';

import CollapsedGroup from './collapsedGroup';

export { default as WithLoader } from './withLoader';

type PropsType = {
  alt?: boolean,
  children?: any,
  openOnFirstChild?: boolean,
  numButtons?: number,
  disableButtons?: boolean,
};

type DefaultPropsType = {
  children: any,
  openOnFirstChild: boolean,
  alt: false,
  numButtons: number,
  disableButtons: boolean,
};

type StateShape = {
  expand: boolean,
};

class ButtonGroup extends React.Component<PropsType, StateShape> {
  static defaultProps = {
    children: [],
    openOnFirstChild: false,
    alt: false,
    numButtons: 2,
    disableButtons: false,
  };

  defaultProps: DefaultPropsType;

  constructor(props: PropsType) {
    super(props);
    this.state = {
      expand: false,
    };
  }

  // Return non undefined/null props.children
  getChildrenAsArray = (start = 0, end = 0) =>
    React.Children.toArray(this.props.children)
      .filter(child => child !== undefined || child !== null)
      .slice(start, end || this.getChildrenCount());

  getChildrenCount = () =>
    React.Children.toArray(this.props.children).filter(
      child => child !== undefined || child !== null,
    ).length;

  getButtonClasses = (child, index) => {
    let classes = 'button-group__primary__item';

    // return modifier if this is the first non-text child
    if (this.isFirstPrimaryButton(child)) {
      classes = `${classes} button-group__item--is-first`;

      // if this is also the last primary button it is the only primary button
      if (this.isLastPrimaryButton(index)) {
        classes = `${classes} button-group__item--is-only`;
      }
    }

    return classes;
  };

  killListener = (): any =>
    document.removeEventListener('click', this.listenerAction);

  startListener = (): any =>
    document.addEventListener('click', this.listenerAction);

  reactDropdown: HTMLElement;

  close = (): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      expand: false,
    }));

  toggle = (): any =>
    this.setState((prevState: StateShape): StateShape => {
      const nextExpand = !prevState.expand;

      if (nextExpand) {
        this.startListener();
      } else {
        this.killListener();
      }

      return {
        ...prevState,
        expand: nextExpand,
      };
    });

  listenerAction = (event: Object) => {
    if (this.reactDropdown && !this.reactDropdown.contains(event.target)) {
      this.close();
    }

    if (!this.reactDropdown) {
      this.killListener();
    }
  };

  isFirstFlag = false;

  isFirstPrimaryButton = child => {
    if (child.props['data-meta']?.type !== 'text' && !this.isFirstFlag) {
      this.isFirstFlag = true;
      return true;
    }

    return false;
  };

  isLastPrimaryButton = index => {
    const kids = this.getChildrenAsArray(0, this.props.numButtons);

    return kids.length === index + 1;
  };

  render(): React$Element<*> {
    const overrideObject = this.props.openOnFirstChild
      ? {
          onClick: this.toggle,
        }
      : {};
    this.isFirstFlag = false;
    return (
      <div
        className={`button-group${
          this.getChildrenCount() > this.props.numButtons ? '--has-expand' : ''
        } ${this.props.alt ? 'button-group--alt' : ''}`}
        ref={(c: HTMLElement): boolean => {
          this.reactDropdown = c;
          return true;
        }}>
        <div className="button-group__primary">
          {this.getChildrenAsArray(0, this.props.numButtons).map(
            (child, index) => (
              <div className={this.getButtonClasses(child, index)} key={index}>
                {React.cloneElement(child, {
                  disabled: this.props.disableButtons,

                  className:
                    child.props['data-meta'] &&
                    child.props['data-meta'].type === 'text'
                      ? 'button-group__primary__item__btn--text'
                      : 'button-group__primary__item__btn',
                  ...overrideObject,
                })}
              </div>
            ),
          )}
        </div>
        <CollapsedGroup expand={this.state.expand} toggle={this.toggle}>
          {this.getChildrenAsArray(this.props.numButtons).map(
            (child, index) => (
              <Fragment key={index}>
                {React.cloneElement(child, {
                  disabled: this.props.disableButtons,
                  className: 'button-group__secondary__item__btn',
                  onClick: e => {
                    this.toggle();
                    child.props.onClick(e);
                  },
                })}
              </Fragment>
            ),
          )}
        </CollapsedGroup>
      </div>
    );
  }
}

export default ButtonGroup;
