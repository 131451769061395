import React from 'react';
import Roles from 'constants/roles';
import getToken from 'functions/getToken';
import ButtonGroup from 'components/ButtonGroup';
import l from 'helpers/locale';

const { RoleIds } = Roles;

const ContractorListActionHeader = (
  openNewNomination: Function,
  onExport: Function,
  userRole: string,
): Function => (): React$Element<*> => (
  <ButtonGroup>
    {[
      RoleIds.PROPERTY_MANAGER,
      RoleIds.AGENCY_MANAGER,
      RoleIds.CONSTRUCTION_MANAGER,
    ].includes(userRole) ? (
      <button
        type="button"
        onClick={openNewNomination}
        data-qe-id="action-click-nominate_button">
        {l('NOMINATION-new')}
      </button>
    ) : null}
    <a
      data-qe-id="action-click-contractors-contractors_export"
      href={`/exports/contractors.xlsx?token=${getToken()}`}
      download="contractors.xlsx"
      onClick={onExport}>
      {l('ACTION-export_list')}
    </a>
    {[RoleIds.ADMINISTRATION].includes(userRole) ? (
      <a
        data-qe-id="action-click-contractors-recommendations_export"
        href={`/exports/recommendations.xlsx?token=${getToken()}`}
        download="recommendations.xlsx"
        onClick={onExport}>
        {l('ACTION-export_recommendations_list')}
      </a>
    ) : null}
  </ButtonGroup>
);

export default ContractorListActionHeader;
