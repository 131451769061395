/** @format */

// @flow
import React from 'react';
import l from 'helpers/locale';
import history from 'helpers/history';
import ButtonGroup from 'components/ButtonGroup';
import Roles from 'constants/roles';

const { RoleIds } = Roles;

const JobsListActionGroup = (onExport, userType) => (): React$Element<*> => (
  <ButtonGroup>
    {[RoleIds.PROPERTY_MANAGER, RoleIds.CONSTRUCTION_MANAGER].includes(
      userType,
    ) ? (
      <button
        type="button"
        className="action-group__button"
        onClick={(e: any): any => {
          e.preventDefault();
          return history.push('/jobs/job-assign');
        }}>
        {l('PM_DASHBOARD-new_direct_award_btn')}
      </button>
    ) : null}
    {[RoleIds.PROPERTY_MANAGER, RoleIds.CONSTRUCTION_MANAGER].includes(
      userType,
    ) ? (
      <button
        type="button"
        className="action-group__button"
        onClick={(e: any): any => {
          e.preventDefault();
          return history.push('/jobs/job-create');
        }}>
        {l('PM_DASHBOARD-new_offer_btn')}
      </button>
    ) : null}
    {[RoleIds.ADMINISTRATION].includes(userType) ? (
      <a
        data-qe-id="action-click-jobs-jobs_export"
        href={`/exports/jobs.xlsx?token=${window.localStorage
          .getItem('_tk')
          .substr(7)}`}
        download="jobs.xlsx"
        className="action-group__button"
        onClick={onExport}>
        {l('ACTION-export_list')}
      </a>
    ) : null}
  </ButtonGroup>
);

export default JobsListActionGroup;
