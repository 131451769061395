/** @format */

import React from 'react';
import type { DocumentType } from 'views/singleJob/constants';
import GenericDocuments from '../GenericDocuments';

type PropsShape = {
  listName: string,
  list?: [DocumentType],
  data: any,
  controls: Array<*>,
};

const InternalDocsPanelWrapper = ({
  data,
  list,
  listName,
  controls,
}: PropsShape): React$Element<*> => (
  <GenericDocuments
    data={data}
    listName={listName}
    list={list}
    jobId={data.jobId}
    controls={controls}
    title="INTERNAL_DOCS-title"
    identifier="action-drop-internal_document"
    footer="DOCUMENTS-for_internal_use"
    isOpen={false}
    showLock
  />
);

InternalDocsPanelWrapper.defaultProps = {
  list: [],
};

export default InternalDocsPanelWrapper;
