/** @format */

// @flow
import React from 'react';
import l from 'helpers/locale';
import history from 'helpers/history';
import ButtonGroup from 'components/ButtonGroup';
import Roles from 'constants/roles';

const { RoleIds } = Roles;

const ActionGroup = userType => () => (
  <ButtonGroup>
    {[RoleIds.CONSTRUCTION_MANAGER].includes(userType) ? (
      <button
        type="button"
        data-qe-id="action-start-create-project"
        className="action-group__button"
        onClick={(e: any): any => {
          e.preventDefault();
          return history.push('/projects/project-create');
        }}>
        {l('CM_DASHBOARD-new_project_btn')}
      </button>
    ) : null}
  </ButtonGroup>
);

export default ActionGroup;
