import React, { createContext, useState } from 'react';

export const PreviewContext = createContext({
  onActionClicked: () => null,
  toggleFullscreen: () => null,
  fullscreen: false,
});

export type PreviewComponentsShape = {
  component: Function,
  condition: Object => boolean,
  controls: [Object],
};

type PreviewPropsShape = {
  data?: Object,
  baseClass?: string,
  isFullWidth?: boolean,
  limitHeight?: boolean,
  onActionClicked: Function,
  previewComponents: [PreviewComponentsShape],
  footerRenderer?: Function,
};

const Preview = ({
  data,
  baseClass,
  isFullWidth,
  limitHeight,
  onActionClicked,
  previewComponents,
  footerRenderer,
}: PreviewPropsShape) => {
  const [isFullscreen, setFullscreen] = useState(false);

  function getPreviewClassModifier() {
    if (limitHeight) {
      return '';
    }
    return '--reset-height';
  }

  function getPreviewComponent() {
    const previewItem = previewComponents
      .filter(comp => comp.condition(data))
      .reduceRight((pv, cv) => cv, null);

    const { component: PreviewComponent, controls } = previewItem;

    const propsBag = {
      isFullWidth,
      data,
      controls,
    };

    return PreviewComponent ? <PreviewComponent {...propsBag} /> : null;
  }

  function toggleFullscreen() {
    setFullscreen(!isFullscreen);
  }

  /*
   * Returns 1/5 for default images when fullWidthOnImage is false
   * Return full width if image is set and fullWidthOnImage prop is true
   */
  function getImageGridSize() {
    return isFullWidth ? '--full-width' : '';
  }

  return (
    <div className={`${baseClass}${getImageGridSize()}`}>
      <div
        className={`image-preview-container ${
          isFullscreen ? 'ipc-is-fullscreen' : 'ipc-is-small'
        }`}>
        <PreviewContext.Provider
          value={{
            onActionClicked,
            toggleFullscreen,
            fullscreen: isFullscreen,
          }}>
          <div className={`image-preview${getPreviewClassModifier()}`}>
            {getPreviewComponent()}
          </div>
        </PreviewContext.Provider>
        {footerRenderer ? (
          <div className="image-preview__footer">{footerRenderer(data)}</div>
        ) : null}
      </div>
    </div>
  );
};

Preview.defaultProps = {
  isFullWidth: false,
  limitHeight: true,
  data: null,
  baseClass: 'document-uploader__item',
  footerRenderer: null,
};

export default Preview;
