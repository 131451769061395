/** @format */

// @flow
export const SIMPLE_LIST_SET_DATA = 'SIMPLE_LIST_SET_DATA';
export const SIMPLE_LIST_SET_DATA_LOADING = 'SIMPLE_LIST_SET_DATA_LOADING';
export const SIMPLE_LIST_SET_DATA_FAILED = 'SIMPLE_LIST_SET_DATA_FAILED';
export const SIMPLE_LIST_FLUSH_DATA = 'SIMPLE_LIST_FLUSH_DATA';
export const SIMPLE_LIST_SORT_BY_FILTER = 'SIMPLE_LIST_SORT_BY_FILTER';
export const SIMPLE_LIST_UPDATE_QUERIES = 'SIMPLE_LIST_UPDATE_QUERIES';

export const SIMPLE_LIST_UPDATE_DATA_REQUEST =
  'SIMPLE_LIST_UPDATE_DATA_REQUEST';
export const SIMPLE_LIST_UPDATE_DATA = 'SIMPLE_LIST_UPDATE_DATA';
export const SIMPLE_LIST_UPDATE_DATA_FAILED = 'SIMPLE_LIST_UPDATE_DATA_FAILED';
