/** @format */

// @flow
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import FooterBar from 'components/FooterBar';
import ButtonGroup from 'components/ButtonGroup';

import l from '../../../../../helpers/locale';
import AcceptOffer from './acceptOffer';
import DeclineOffer from './declineOffer';

type PropsShape = {
  changeEditState?: Function,
  close: Function,
  viewState: boolean,
  machine: Object,
  submitOffer?: Function,
  acceptOfferFn: Function,
  declineOfferFn: Function,
  toggleVisibility: Function,
  acceptOfferAction: Function,
  declineOfferAction: Function,
};

class OfferFormFooter extends React.Component<PropsShape> {
  static defaultProps = {
    changeEditState: () => true,
    submitOffer: () => true,
  };

  getAllowedActions = (): Array<*> => {
    const actions = {
      reject: {
        action: this.props.declineOfferFn,
        mod: 'btn--secondary',
        label: 'ACTION-DECLINE-OFFER',
      },
      accept: {
        action: this.props.acceptOfferFn,
        mod: 'btn--primary',
        label: 'ACTION-ACCEPT-OFFER',
      },
      update: {
        action: this.props.changeEditState.bind(null, 'edit'),
        mod: 'btn--inverse--primary',
        label: 'ACTIONS-EDIT',
      },
      submit: {
        action: this.props.submitOffer,
        mod: 'btn--primary',
        label: 'ACTION-SUBMIT-OFFER',
      },
      trash: {
        action: this.props.deleteOffer,
        mod: 'btn--inverse--secondary',
        label: 'ACTION-delete',
      },
    };

    const stateActions = this.props.viewState
      ? this.props.machine?.available_transitions?.map(item => ({
          ...actions[item.name],
          name: item.name,
        }))
      : [];

    let actionList = stateActions;

    if (this.props.formActions) {
      actionList = [...this.formatFormActions(), ...actionList];
    }

    if (this.props.viewState) {
      actionList = [
        {
          action: this.props.close,
          label: 'JOB-ACTIONS-BACK',
          name: 'back',
          attrs: {
            'data-meta': {
              type: 'text',
            },
          },
        },
        ...actionList,
      ];
    }

    return actionList;
  };

  formatFormActions = () => {
    const actions = this.props.formActions.map(item => {
      const toExpected = {
        mod: 'btn--primary',
        ...item,
      };

      return toExpected;
    });

    return actions;
  };

  render(): React$Element<*> {
    return (
      <Fragment>
        <FooterBar>
          <div className="fl-right">
            <div className="fl-right__item">
              <ButtonGroup alt numButtons={3}>
                {this.getAllowedActions().map(
                  (item: any, k: number): React$Element<*> => (
                    <button
                      key={k}
                      type="button"
                      onClick={item.action}
                      data-qe-id={`action-offer-${item.name}`}
                      {...item.attrs}>
                      {l(item.label)}
                    </button>
                  ),
                )}
              </ButtonGroup>
            </div>
          </div>
        </FooterBar>
        <AcceptOffer
          acceptOfferAction={this.props.acceptOfferAction}
          toggleVisibility={this.props.toggleVisibility}
        />
        <DeclineOffer
          declineOfferAction={this.props.declineOfferAction}
          toggleVisibility={this.props.toggleVisibility}
        />
      </Fragment>
    );
  }
}

export default withRouter(OfferFormFooter);
