import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import SingleContractorView from 'shared/contractor/SingleContractor';
import { connect } from 'react-redux';
import { updateTitle } from 'components/RouteSlider/actions';
import { Names } from '../constants';
import { Names as JobNames } from '../../jobs/constants';

const ContractorViewRender = ({ setTitle, context }) => {
  useEffect(() => {
    setTitle('-');
    return () => setTitle('-');
  }, [setTitle]);
  return (
    <SingleContractorView
      backAction={context.backAction}
      scrollToTop={context.scrollToTop}
      dataUpdatedCB={payload => setTitle(payload.billing_company)}
    />
  );
};

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.CONTRACTOR_VIEW, title));
    dispatch(updateTitle(JobNames.JOB_VIEW_CONTRACTOR, title));
  },
});

export const ContractorView = withRouter(
  connect(null, mapActions)(ContractorViewRender),
);
