// @flow
import { ids as Status } from 'constants/projectStatus';
import StateMachine from 'machines/reduxMachine';
import { transitions, PanelStates } from 'machines/constants';
import { State, Transition, Edge as EdgeBuilder } from 'async-state-machine';

import ProjectConditions from '../../conditions';

const { isProjectStateWithin } = ProjectConditions;

// Transitions for accounts card
export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) =>
    isProjectStateWithin(params, [
      Status.OPENED,
      Status.EXECUTING,
      Status.COMPLETED,
      Status.CANCELLED,
    ]),
  );

function generateStateMachine() {
  const Edge = new EdgeBuilder();

  // States for JobsList Card
  const HIDDEN = Object.create(State).setName(PanelStates.HIDDEN);
  const VISIBLE = Object.create(State).setName(PanelStates.VISIBLE);

  // Register Machine
  const Machine = new StateMachine('invoicingprocess_card')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN])
        .to(VISIBLE),
    )
    .setInitialState(HIDDEN, {});

  return Machine;
}

export default generateStateMachine;
