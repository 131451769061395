// @flow
import graphi from 'helpers/graphi';

import type { ContactType } from '../types/ContactType';

type Params = {
  job_id?: number,
  concern_id?: number,
  concern_type?: string,
  project_id?: number,
};

const contactFields = `
  contact_id,
  full_name,
  email,
  telephone,
  street,
  postal_code,
  city,
  company,
  type,
  state_machine {
    status,
    available_transitions {
      name
    }
  },
`;

function stringifyParams(params: Params) {
  const acceptedKeys = ['job_id', 'concern_id', 'concern_type', 'project_id'];
  const availableKeys = Object.keys(params).filter(
    key => params[key] && acceptedKeys.includes(key),
  );

  const stringifiedKeys = availableKeys.map(current => {
    const value =
      current === 'concern_type'
        ? params[current].toUpperCase()
        : params[current];

    return `${current}:${value}`;
  });

  return stringifiedKeys.join(',');
}

export async function getAllianzContacts(params: Params): ContactType {
  const body = `{
    job(${stringifyParams(params)}) {
      contact_allianz_list {
        ${contactFields}
      }
    }
  }`;

  const { job } = await graphRequest(body, 'getAllianzContacts');
  return job.contact_allianz_list;
}

export async function getCareTakerContacts(params: Params): ContactType {
  const body = `{
    job(${stringifyParams(params)}) {
      contact_care_taker_list {
        ${contactFields}
      }
    }
  }`;

  const { job } = await graphRequest(body, 'getCareTakerContacts');
  return job.contact_care_taker_list;
}

export async function getTenantContacts(params: Params): ContactType {
  const body = `{
    job(${stringifyParams(params)}) {
      contact_tenant_list {
        ${contactFields}
      }
    }
  }`;

  const { job } = await graphRequest(body, 'getTenantContacts');
  return job.contact_tenant_list;
}

function getParentTypeFromKey(key) {
  switch (key) {
    case 'contact_tenant_list':
      return 'tenant';
    case 'contact_allianz_list':
      return 'property_manager';
    case 'contact_care_taker_list':
      return 'care_taker';
    default:
      throw new Error(`Unkown ${key} encountered`);
  }
}
export async function getDeliveryContacts(params: Params): ContactType {
  const body = `{
    job(${stringifyParams(params)}) {
      contact_tenant_list {
        ${contactFields}
      }
      contact_care_taker_list {
        ${contactFields}
      }
    }
  }`;

  const { job } = await graphRequest(body, 'getDeliveryContacts');

  const contacts = Object.keys(job).reduce(
    (accumulated: Array<ContactType>, current: string): Array<ContactType> => [
      ...accumulated,
      ...job[current].map(contactItem => ({
        ...contactItem,
        parentType: getParentTypeFromKey(current),
      })),
    ],
    [],
  );

  return contacts;
}

export async function getContractorContacts(params: Params): ContactType {
  const body = `{
    job(${stringifyParams(params)}) {
      contact_contractor_list {
        ${contactFields}
      }
    }
  }`;

  const result = await graphRequest(body, 'getContractorContacts');
  return result?.job.contact_contractor_list;
}

async function graphRequest(body, tag) {
  const { data } = await graphi(body, tag);

  return data.data;
}
