// @flow
import StateMachine from 'machines/reduxMachine.js';
import { transitions } from 'machines/constants.js';
import { ids as Status } from 'constants/jobStatus';
import {
  State,
  Transition,
  Edge as EdgeBuilder,
  Conditions,
} from 'async-state-machine';

import JobConditions from '../../conditions';

const { isRejectedContractor, isJobStateWithin } = JobConditions;

const { and, not } = Conditions;

// Transitions for Document Exchange
export const to_editing = Object.create(Transition).setName(
  transitions.to_editing,
);

export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) =>
    and([
      not(isRejectedContractor(params)),
      isJobStateWithin(params, [
        Status.GRANTED,
        Status.ACCEPTED,
        Status.EXECUTED,
        Status.COMPLETED,
      ]),
    ]),
  );

function DocumentExchangeCardMachine() {
  const Edge = new EdgeBuilder();

  // States for Single Job
  const HIDDEN = Object.create(State).setName('HIDDEN');
  const VISIBLE = Object.create(State).setName('VISIBLE');
  const EDITING = Object.create(State).setName('EDITING');

  // Register Machine
  const Machine = new StateMachine('documentexchange_card')
    .registerEdge(
      Edge.new()
        .transition(to_editing)
        .from([VISIBLE])
        .to(EDITING),
    )
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN, EDITING])
        .to(VISIBLE),
    )
    .setInitialState(HIDDEN, {}, true);

  return Machine;
}

export default DocumentExchangeCardMachine;
