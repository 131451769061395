/** @format */

// @flow
import React, { Fragment } from 'react';
import Card from 'components/Card';
import l, { fC } from 'helpers/locale';
import InputFocus from 'helpers/InputFocus';
import FormInputAttachment from 'components/Form/FormInputAttachment';

const inputFocus = new InputFocus();

type PropsShape = {
  data: Object,
  isEditing?: boolean,
};

type accountDataType = {
  effective_account: {
    key: string,
    value: string,
  },
  entry_id: number,
  project_owner: string,
  property_id: number,
  title: string,
  value_add: string,
  value_adding_account: { key: string, value: string },
};

const FinancePanel = ({
  data,
  isEditing,
  updateField,
}: PropsShape): React$Element<*> => {
  function getPojectAccountInfo(account: accountDataType) {
    if (!account) return null;

    const valueAdd = parseInt(account.value_add, 10);
    const valuePreserving = 100 - valueAdd;
    return (
      <Fragment>
        <div className="g g-1/1">
          <div className="push--small--bottom">
            <label className="form__label--small" htmlFor="__">
              {l('FORMS-account_master')}
            </label>
            <div>
              <p className="text--dk--flushed text--ellipsis">
                {account.effective_account.value}
              </p>
              <p className="text--dk--flushed text--ellipsis">
                {account.value_adding_account.value}
              </p>
            </div>
          </div>
        </div>
        <div className="g g-1/2">
          <div className="push--small--bottom">
            <label className="form__label--small" htmlFor="__">
              {l('FORMS-value_preserving')}
            </label>
            <p className="text--dk--flushed">{valuePreserving}%</p>
          </div>
        </div>
        <div className="g g-1/2">
          <div className="push--small--bottom">
            <label className="form__label--small" htmlFor="__">
              {l('FORMS-value_appreciation')}
            </label>
            <p className="text--dk--flushed">{valueAdd}%</p>
          </div>
        </div>
      </Fragment>
    );
  }
  return (
    <Card title="FINANCE-singular" isOpen canCollapse={false}>
      <div className="gw">
        <div className="g g-1/1">
          <div className="push--small--bottom">
            <label className="form__label--small" htmlFor="__">
              {l('FORMS-budget')}
            </label>
            <FormInputAttachment attach text="CHF" toLeft viewOnly={!isEditing}>
              {!isEditing ? (
                <p className="text--dk--flushed">{fC(data.budget)}</p>
              ) : (
                <input
                  data-qe-id="input-edit-budget-total"
                  className="form__input"
                  type="number"
                  name="budget"
                  value={data.budget}
                  onFocus={inputFocus.selectTargetOnFocus()}
                  onWheel={e => e.target.blur()}
                  onChange={(e: Object): any =>
                    updateField({
                      key: 'budget',
                      value: e.target.value,
                    })
                  }
                />
              )}
            </FormInputAttachment>
          </div>
        </div>
        <Fragment>
          <div className="g g-1/1">
            <div className="push--small--bottom">
              <label className="form__label--small" htmlFor="__">
                {l('FORMS-payments_to_date')}
              </label>
              <p className="text--dk--flushed">{fC(data.payments_total)}</p>
            </div>
          </div>
          {getPojectAccountInfo(data.projectAccount)}
        </Fragment>
      </div>
    </Card>
  );
};

FinancePanel.defaultProps = {
  isEditing: false,
};

export default FinancePanel;
