import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import SingleProperty from 'views/properties/components/SingleProperty';
import { connect } from 'react-redux';
import { updateTitle } from 'components/RouteSlider/actions';
import { Names } from '../constants';

const ContractorPropertyViewRender = ({ setTitle }) => {
  useEffect(() => {
    setTitle('-');
    return () => setTitle('-');
  }, [setTitle]);
  return (
    <SingleProperty
      dataUpdatedCB={payload => setTitle(payload.street)}
      enableDetailView={false}
    />
  );
};

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.CONTRACTOR_PROPERTY, title));
  },
});

export const ContractorPropertyView = withRouter(
  connect(null, mapActions)(ContractorPropertyViewRender),
);
