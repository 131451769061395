/** @format */

import { createHashHistory, createMemoryHistory } from 'history';

let historyObj = createHashHistory;
if (typeof window === 'undefined') {
  // used in headless tests
  historyObj = createMemoryHistory;
}

export default historyObj();
