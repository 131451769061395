import React, { useState, useEffect } from 'react';
import { Overlay } from '@bonlineza/b-lib';

import { connect } from 'react-redux';
import graphi from 'helpers/graphi';
import axios from '../../helpers/axios';
import { hasAuth } from '../../functions/authenticator.func';
import TermsAndConditions from '../TermsAndConditions';

const TermsPopup = ({ isAuth }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [alertData, setAlertData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    axios
      .post('/api/1/alerts/read')
      .then(() => setPopupVisible(false))
      .catch(() => setPopupVisible(false));
  };

  useEffect(() => {
    setIsLoading(true);
    async function getAlert() {
      let alert = false;
      try {
        const {
          data: { data },
        } = await graphi(`{alert{ header, body }}`, 'getAlert');
        alert = data.alert;
      } catch {
        alert = false;
      }
      if (alert) {
        setPopupVisible(true);
        setAlertData(alert);
      }
    }
    if (isAuth) {
      getAlert();
      setIsLoading(false);
    }
  }, [setPopupVisible, setAlertData, isAuth, isLoading]);

  return (
    <div>
      {isAuth && hasAuth() ? (
        <Overlay isOpen={isPopupVisible}>
          <TermsAndConditions
            alertBody={alertData}
            handleClick={handleClick}
            isLoading={isLoading}
          />
        </Overlay>
      ) : null}
    </div>
  );
};

const mapState = ({ authStore }) => ({
  isAuth: authStore.auth,
});

export default connect(mapState)(TermsPopup);
