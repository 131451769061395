import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import { updateTitle } from 'components/RouteSlider/actions';
import NominationForm from 'views/nominations/views/NominationForm';
import { Names } from '../constants';

const ContractorInviteFormRender = ({ history, setTitle, match, context }) => {
  useEffect(() => {
    setTitle('-');
    return () => setTitle('-');
  }, [setTitle]);
  return (
    <NominationForm
      backAction={() =>
        history.push(`/contractors/${match.params.contractor_id}`)
      }
      scrollToTop={context.scrollToTop}
      successCallback={() =>
        history.push(`/contractors/${match.params.contractor_id}`)
      }
      onData={data => {
        setTitle(`${data.contractor.surname} ${l('INVITE-form_title')}`);
      }}
      contractorId={match.params.contractor_id}
    />
  );
};

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.CONTRACTOR_INVITE, title));
  },
});

export const ContractorInviteForm = withRouter(
  connect(null, mapActions)(ContractorInviteFormRender),
);
