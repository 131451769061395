import React from 'react';
import { connect } from 'react-redux';

import GetSvg from 'components/GetSvg';

import ListRowRender from './shared/ListRowRender';
import useGetContact from './shared/useGetContact';
import getLabel from './shared/getLabel';

function CareTakerList(props) {
  const [loading, data] = useGetContact('care_taker', props);

  function selectNewContact(contact) {
    props.setPopup(false);
    props.update(contact);
  }

  const labelFor = getLabel();

  return (
    <div className="popup__inner__container__body">
      {loading ? (
        <div className="fl-vh-center">
          <GetSvg svg="loading" wrapperClass="loader-base" />
        </div>
      ) : (
        <React.Fragment>
          {data.map((item, key) => (
            <ListRowRender
              key={key}
              row={item}
              selectRow={selectNewContact}
              label={labelFor(item)}
            />
          ))}
        </React.Fragment>
      )}
    </div>
  );
}

function mapState(state) {
  return {
    job_id: state.jobStore.data.job.job_id,
    concern_id: state.jobStore.data.job.concern.concern_id,
    concern_type: state.jobStore.data.job.concern.concern_type,
    project_id: state.jobStore.data.job.project_id,
  };
}

export default connect(mapState)(CareTakerList);
