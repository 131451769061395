import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Roles from 'constants/roles';
import Card from 'components/Card';
import SimpleList from 'components/ConnectedSimpleList';
import l from 'helpers/locale';
import { StateConditional } from 'machines/components/RenderConditional';
import MachineBuilder from './machine';
import isOnProjectTeam from '../conditions/isOnProjectTeam';

const { RoleIds } = Roles;

type PropsShape = {
  apiEndpoint: string,
  listName: string,
  enableDetailView: boolean,
  role: number,
};

type StateShape = {
  parentMatch: Object,
};

export const OfferListCardMachine = MachineBuilder();

class OfferListCard extends Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      parentMatch: {
        path: props.match.path,
        url: props.match.url,
      },
    };
  }

  openOffer = ({ id }): any => {
    this.props.history.push(`${this.state.parentMatch.url}/offers/${id}`);
  };

  render(): React$Element<*> {
    return (
      <StateConditional
        state={this.props.machine_state}
        whenState={['VISIBLE']}>
        <Card title="OFFERS-title" isOpen>
          <SimpleList
            enableSearch={false}
            api={this.props.apiEndpoint}
            clickAct={this.openOffer}
            name={this.props.listName}
            headings={[
              {
                name: 'title',
                sortable: true,
                text: l('OFFERS-COL-title'),
              },
              ...(this.props.role !== RoleIds.CONTRACTOR ||
              (this.props.job && isOnProjectTeam({ job: this.props.job }))
                ? [
                    {
                      name: 'participant',
                      sortable: false,
                      text: l('OFFERS-COL-participant'),
                    },
                  ]
                : []),
              {
                name: 'submitted_at',
                sortable: true,
                text: l('OFFERS-COL-submitted_at'),
              },
              {
                name: 'total',
                sortable: true,
                text: l('OFFERS-COL-total'),
              },
              {
                name: 'status',
                sortable: true,
                text: l('JOB-COL-status'),
              },
            ]}
            allowClick={this.props.enableDetailView}
          />
        </Card>
      </StateConditional>
    );
  }
}

const mapState = ({ jobStore, authStore, machineStore }): StateShape => ({
  machine_state: machineStore.offerlist_card,
  job: jobStore.data.job,
  role: authStore.user.role,
});

export default withRouter(connect(mapState)(OfferListCard));
