import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SimpleList from 'components/ConnectedSimpleList';
import Card from 'components/Card';
import l from 'helpers/locale';
import NewAccountSelect from './components/newAccountSelect';
import type { RoleTypes } from '../../constants/roles';
import { ids as RoleId } from '../../constants/roles';

const Accounts = ({ canCreateNewUser, history }) => {
  const openNewAccountForm = (role: RoleTypes) =>
    history.push(`/accounts/create/${role}`);

  const handleClick = ({ id }) => history.push(`/accounts/${id}`);

  return (
    <Fragment>
      <Card
        isOpen
        title="ACCOUNTS-title"
        watermarkName="settings"
        asideComponent={
          canCreateNewUser ? NewAccountSelect(openNewAccountForm) : null
        }
        canCollapse={false}>
        <SimpleList
          api="/api/1/view/users"
          name="accounts"
          clickAct={handleClick}
          headings={[
            {
              name: 'name',
              sortable: true,
              text: l('ACCOUNTS-name'),
            },
            {
              name: 'type',
              sortable: true,
              text: l('ACCOUNTS-type'),
            },
            {
              name: 'email',
              sortable: true,
              text: l('ACCOUNTS-email'),
            },
            {
              name: 'reference_name',
              sortable: true,
              text: l('JOB-COL-id'),
            },
            {
              name: 'last_active',
              sortable: false,
              text: l('ACCOUNTS-last_login'),
            },
            {
              name: 'status',
              sortable: false,
              text: l('ACCOUNTS-status'),
            },
          ]}
          filterOpts={[
            {
              text: l('ACCOUNTS_OVERVIEW_FILTER-login_deactivated'),
              value: 'status:false',
            },
            {
              text: l('ACCOUNTS_OVERVIEW_FILTER-administrators'),
              value: `type:"${l('ACCOUNTS-administrator')}"`,
            },
            {
              text: l('ACCOUNTS_OVERVIEW_FILTER-cp_managers'),
              value: `type:"${l('ACCOUNTS-proc_officer')}"`,
            },
            {
              text: l('ACCOUNTS_OVERVIEW_FILTER-agency_managers'),
              value: `type:"${l('ACCOUNTS-agency_manager')}"`,
            },
            {
              text: l('ACCOUNTS_OVERVIEW_FILTER-contractors'),
              value: `type:"${l('ACCOUNTS-contractor')}"`,
            },
            {
              text: l('ACCOUNTS_OVERVIEW_FILTER-property_managers'),
              value: `type:"${l('ACCOUNTS-property_manager')}"`,
            },
            {
              text: l('ACCOUNTS_OVERVIEW_FILTER-account_managers'),
              value: `type:"${l('ACCOUNTS-account_manager')}"`,
            },
            {
              text: l('ACCOUNTS_OVERVIEW_FILTER-construction_managers'),
              value: `type:"${l('ACCOUNTS-construction_manager')}"`,
            },
            {
              text: l('ACCOUNTS_OVERVIEW_FILTER-head_construction_managers'),
              value: `type:"${l('ACCOUNTS-head_construction_manager')}"`,
            },
          ]}
        />
      </Card>
    </Fragment>
  );
};

type ReduxStateShape = {
  canCreateNewUser: boolean,
};

const mapState = ({ authStore }): ReduxStateShape => ({
  canCreateNewUser: RoleId.ADMINISTRATION === authStore.user.role,
});

export default withRouter(connect(mapState)(Accounts));
