import React from 'react';
import Card from 'components/Card';
import l from 'helpers/locale';
import ViewItem from '../components/ViewItem';
import CustomerEdit, {
  recommendationList,
  defaultRecommendation,
} from '../components/CustomerRelationshipEdit';

type PropsShape = {
  status: string,
  customerNumber: string,
  recommended: string,
  showRecommended: boolean,
  editMode: boolean,
  canCollapse?: boolean,
};

export function getStatusSvg(status) {
  switch (status) {
    case 'former':
      return 'fc--quaternary';
    case 'current':
      return 'fc--primary';
    case 'unassigned':
    case 'none':
    default:
      return 'fc--base-lt';
  }
}

const CustomerCard = ({
  status,
  customerNumber,
  recommended,
  showRecommended = true,
  editMode,
  canCollapse = true,
}: PropsShape): React$Element<*> => {
  const recommendationItem =
    recommendationList.find(item => item.value === recommended) ||
    defaultRecommendation;
  return (
    <div>
      <Card
        title="CONTRACTOR-relationship-title"
        isOpen
        canCollapse={canCollapse}>
        {editMode ? (
          <CustomerEdit
            status={status}
            customerNumber={customerNumber}
            recommended={recommended}
            showRecommended={showRecommended}
          />
        ) : (
          <div className="gw">
            <div className="g g-1/3">
              <ViewItem
                label={l('CONTRACTOR-customer-status')}
                value={l(`CONTRACTOR-customer_state-${status}`)}
                hasSvg
                prependSvg={{
                  name: 'eagle',
                  svgClass: getStatusSvg(status),
                }}
              />
            </div>
            <div className="g g-1/3">
              <ViewItem
                label={l('CONTRACTOR-customer-number')}
                value={customerNumber !== '' ? customerNumber : '-'}
              />
            </div>
            {showRecommended ? (
              <div className="g g-1/3">
                <ViewItem
                  label={l('CONTRACTOR-agency-recommendation')}
                  value={l(recommendationItem.label)}
                  hasSvg
                  prependSvg={{
                    name: recommendationItem.svg,
                    svgClass: recommendationItem.svgClass,
                  }}
                />
              </div>
            ) : null}
          </div>
        )}
      </Card>
    </div>
  );
};

CustomerCard.defaultProps = {
  canCollapse: true,
  showRecommended: true,
};

export default CustomerCard;
