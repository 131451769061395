/** @format */

import React from 'react';

import Card from '../../../../../components/Card';
import l from '../../../../../helpers/locale';

const InvitationPanel = ({ link }) => (
  <Card title="INVITATION-title" isOpen canCollapse={false}>
    <div>
      <span className="form__label--small">{l('ACTION-link')}</span>
      <p className="text--dk--flushed">
        <a target="_blank" href={link} rel="noopener noreferrer">
          {link}
        </a>
      </p>
    </div>
  </Card>
);

export default InvitationPanel;
