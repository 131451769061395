import React, { Fragment } from 'react';
import l from 'helpers/locale';
import { SimplePopup } from '@bonlineza/b-lib';

import { getJobContacts, getGrantContractors } from './queries';
import MultiList from './MultiList';
import NotifyContactRow from './MultiList/components/NotifyContactRow';
import ContactRow from './MultiList/components/ContactRow';

type PropsShape = {
  jobId: string | number,
  isOpen: boolean,
  contractor: Object,
  callback: any => any,
  close: () => any,
};

type StateShape = {
  payload: Object,
};

const contractorToContact = contractor => ({
  name: contractor.billing_company,
  email: contractor.key_user_email,
  id: contractor.contractor_id,
  type: 'contractor',
});

const getInitialState = props => {
  return {
    payload: {
      notify_contractor: props.contractor?.has_profile
        ? [contractorToContact(props.contractor)]
        : [],
      notify_copy: [],
    },
  };
};

class GrantModal extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    contractor: null,
    jobId: null,
  };

  static getDerivedStateFromProps(props, state) {
    const newState = {
      payload: {
        ...state.payload,
      },
    };
    if (props.contractor && !state.payload.notify_contractor.length) {
      // set default contractor in contact list
      newState.payload.notify_contractor = [
        contractorToContact(props.contractor),
      ];
    }
    return newState;
  }

  constructor(props: PropsShape) {
    super(props);

    this.state = getInitialState(props);
  }

  resetState() {
    this.setState(getInitialState(this.props));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen && !this.props.isOpen) {
      this.resetState();
    }
  }

  state: StateShape;

  submitGrant = (): any => {
    this.props.callback(this.state.payload);
  };

  closeModal = () => {
    this.props.close();
  };

  render = () => (
    <SimplePopup
      cannotOutsideClickClose
      isOpen={this.props.isOpen}
      title={l('GRANT_JOB-CONTACTS_POPUP-TITLE')}
      close={this.closeModal}
      renderContent={() => {
        return (
          <Fragment>
            <p className="sub-title">{this.props.subTitle}</p>
            <MultiList
              onChange={data => {
                this.setState({ payload: data });
              }}
              lists={[
                {
                  name: 'notify_contractor',
                  label: l('GRANT_JOB-CONTACTS_POPUP-COMMITMENT'),
                  rowComponent: NotifyContactRow,
                  listQuery: () =>
                    getGrantContractors({ job_id: this.props.jobId }),
                  initialData: this.state.payload.notify_contractor,
                },
                {
                  name: 'notify_copy',
                  label: l('GRANT_JOB-CONTACTS_POPUP-INFO_COPY'),
                  rowComponent: ContactRow,
                  listQuery: () => getJobContacts({ job_id: this.props.jobId }),
                  initialData: this.state.payload.notify_copy,
                },
              ]}
            />
          </Fragment>
        );
      }}
      options={[
        {
          cb: this.closeModal,
          buttonText: l('cancel'),
          buttonClass: 'btn--text',
          dataQeId: 'grant-modal-cancel',
        },
        {
          cb: this.submitGrant,
          buttonText: l('JOB-ACTIONS-GRANT'),
          buttonClass: 'btn--primary',
          dataQeId: 'action-click-final_grant',
        },
      ]}
    />
  );
}

export default GrantModal;
