// @flow
import graphi from 'helpers/graphi';
import { hasValue } from '@bonlineza/b-lib/functions';

export default function getProjectQuery(project_id: string | number): string {
  const query = `
    {
      project(project_id: ${project_id}) {
        project_id
        address_owner
        address_scan_center
        budget
        has_jobs

        project_account {
          entry_id
          property_id
          title
          value_add
          project_owner
          effective_account {
            key
            value
          }
          value_adding_account {
            key
            value
          }
        }

        concern {
          street,
          postal_code,
          city,
          post_city,
          reference,
          description,
          concern_id,
          concern_type,
          has_elevator,
          rooms_count,

          accountings{
            key, value,
          },

          cost_centers{
            key, value,
          },

          floor{
            code,
            description,
          },
        }
        coversheet
        display_status
        document_tags

        contact_audit {
          contact_id,
          company,
          street,
          city,
          postal_code,
          email,
          full_name,
          telephone,
          type,
          state_machine {
            status
            available_transitions {
              name
            }
          }
        }

        documents {
          document_id
          name
          mime_type
          source_tag
          source_type
          source_id
          blob
          url
          timestamp

          user {
            full_name
            role_id
          }

          state_machine {
            status
            available_transitions {
              name
              action
              type
            }
          }
        }

        notifications_count,
        notifications {
          subject
          message
          timestamp
        }
        occasion
        occasions {
          key
          value
        }
        payments_total
        project_supports {
          user_id
          name
          surname
          telephone
          full_name
          email
        }
        project_owner {
          user_id
          name
          surname
          telephone
          full_name
          email
        }
        status
        summary
        target_date
        title
        state_machine {
          status
          available_transitions {
            name
            action
            type
          }
        }

        graph {
          start_date
          current_date
          trade_jobs {
            trade {
              key
              value
            }
            delivery_date
            state_machine_logs {
              type
              date
            }
          }
        }
      }
    }  `;

  return query;
}

export async function getConcern(type, id) {
  const body = `{
    concern(concern_type:${type.toUpperCase()}, concern_id:${id}) {
      street
      postal_code
      city
      post_city
      reference
      description
      concern_id
      concern_type
      has_elevator
      rooms_count

      accountings{
        key
        value
      }

      cost_centers{
        key
        value
      },

      floor{
        code,
        description,
      },
    }
  }`;

  const { data } = await graphi(body, 'NewProjectConcern');

  return data.data.concern;
}

function getNotificationsQuery(project_id, limit = 200) {
  return `{

    project(project_id: ${project_id}) {
      notifications(limit:${limit}) {
        subject
        message
        timestamp
      }
    }
  }`;
}

export async function getNotifications(project_id): Object[] {
  if (!hasValue(project_id)) return [];
  const { data } = await graphi(
    getNotificationsQuery(project_id),
    'getProjectNotifications',
  );
  return data.data.project.notifications;
}
