/** @format */

import assign from 'object.assign';

import generator from '../../../constants/generator';

const GeneratedActions = generator('fetch_asset_house_property_data');
const UPDATE_LIST = 'ASSET_HOUSE_PROPERTY_UPDATE_LIST';
const UPDATE_LIST_FAILED = 'ASSET_HOUSE_PROPERTY_UPDATE_LIST_FAILED';

const UPDATE_PARAMS_SORT = 'ASSET_HOUSE_PROPERTY_UPDATE_PARAMS_SORT';
const UPDATE_PARAMS_SEARCH = 'ASSET_HOUSE_PROPERTY_UPDATE_PARAMS_SEARCH';
const UPDATE_PARAMS_PER_PAGE = 'ASSET_HOUSE_PROPERTY_UPDATE_PARAMS_PER_PAGE';
const UPDATE_PARAMS_CLEAR = 'ASSET_HOUSE_PROPERTY_UPDATE_PARAMS_CLEAR';

export default assign({}, GeneratedActions, {
  UPDATE_LIST,
  UPDATE_LIST_FAILED,
  UPDATE_PARAMS_SORT,
  UPDATE_PARAMS_SEARCH,
  UPDATE_PARAMS_PER_PAGE,
  UPDATE_PARAMS_CLEAR,
});
