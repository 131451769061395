import React from 'react';
import history from 'helpers/history';
import { matchPath } from 'react-router-dom';
import { getJobChildren } from 'components/RouteSlider/routes/jobs/index.js';
import pathWithoutHash from 'components/RouteSlider/functions/pathWithoutHash';

import { Names } from './constants';
import {
  ContractorView,
  ContractorJobView,
  ContractorPropertyView,
  ContractorInviteForm,
  InviteForm,
  ProfileView,
} from './containers';

export function create(parentPath) {
  // nomination
  return {
    [Names.INVITE]: {
      position: 5,
      title: 'INVITE-form_title',
      render: () => <InviteForm />,
      parentPath,
    },
  };
}

function getBaseContractorPath(basePath) {
  const match = matchPath(pathWithoutHash(), {
    path: `${basePath}/:contractor_id(\\d+)/:tail`,
    exact: false,
  });

  if (!match || !match.params) {
    throw new Error('Unable to navigate back with insufficient match params');
  }
  return `${basePath}/${match.params.contractor_id}`;
}

export const rootProfileView = () => ({
  [Names.ROOT_PROFILE_VIEW]: {
    position: 5,
    name: 'profile',
    title: 'ASIDE-view-contractor-profile',
    qeId: 'view-contractor-profile',
    render: () => <ProfileView />,
  },
});

export const view = parentPath => ({
  // :contractor_id
  [Names.CONTRACTOR_VIEW]: {
    position: 1,
    title: '',
    qeId: 'view-contractor',
    render: () => <ContractorView />,
    backAction: () => history.push('/contractors'),
    parentPath,
    children: {
      // :contractor_id/jobs/:job_id(\\d+)
      [Names.CONTRACTOR_JOB]: {
        position: 2,
        title: '',
        backAction: () => history.push(getBaseContractorPath('/contractors')),
        render: () => <ContractorJobView />,
        children: getJobChildren(
          getBaseContractorPath.bind(null, '/contractors'),
        ),
      },
      [Names.CONTRACTOR_PROPERTY]: {
        position: 3,
        title: '',
        render: () => <ContractorPropertyView />,
      },
      [Names.CONTRACTOR_INVITE]: {
        position: 4,
        title: '',
        render: () => <ContractorInviteForm />,
      },
      // :contractor_id/profile
      [Names.CONTRACTOR_PROFILE_VIEW]: {
        position: 5,
        title: 'ASIDE-view-contractor-profile',
        qeId: 'view-contractor-profile',
        render: () => <ProfileView />,
      },
    },
  },
});
