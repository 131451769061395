import React from 'react';
import { withRouter } from 'react-router';
import getFlexStyle from 'functions/getFlexStyle';
import { fC } from 'helpers/locale';
import formatString from 'functions/formatString';
import PaymentListItemButton from './PaymentListItemButton';

// CODE SMELL: this will need to be refactored
const getStyle = (name, columns) => {
  const columnWithFlex = columns.find(item => item.name === name && item.flex);
  const columndWithAlign = columns.find(
    item => item.name === name && item.align,
  );

  let styleObject = getFlexStyle(columns, 1);

  if (columnWithFlex) {
    const { flex } = columnWithFlex;
    styleObject = {
      ...styleObject,
      minWidth: flex,
      maxWidth: flex,
      flexBasis: flex,
    };
  }
  if (columndWithAlign) {
    styleObject = { ...styleObject, textAlign: columndWithAlign.align };
  }

  return styleObject;
};

const PaymentListItem = ({ item, headings }) => (
  <div className="simple-list__body__group__row">
    <div
      style={getStyle('job_id', headings)}
      className="simple-list__body__row__item">
      {formatString(`${item.job_id}`)}
    </div>
    <div
      style={getStyle('contractor', headings)}
      className="simple-list__body__row__item">
      {item.contractor.map((v: string, k: number): React$Element<*> => (
        <div key={k}>
          {formatString(v)}
          <br />
        </div>
      ))}
    </div>
    <div
      style={getStyle('entry_date', headings)}
      className="simple-list__body__row__item">
      {formatString(item.entry_date)}
    </div>
    <div
      style={getStyle('invoice_date', headings)}
      className="simple-list__body__row__item">
      {formatString(item.invoice_date)}
    </div>
    <div
      style={getStyle('payment_type', headings)}
      className="simple-list__body__row__item">
      {item.payment_type}
    </div>
    <div
      style={getStyle('total', headings)}
      className="simple-list__body__row__item">
      {fC(item.total, false, true)}
    </div>
    {item.editable ? (
      <div
        style={getStyle('edit', headings)}
        className="simple-list__body__row__item">
        <PaymentListItemButton item={item} />
      </div>
    ) : null}
  </div>
);

export default withRouter(PaymentListItem);
