const PROJECT_CREATE = 'project-create';
const PROJECT_CREATE_NEW = 'new';
const PROJECT_VIEW = ':project_id(\\d+)';
const PROJECT_ADD_PAYMENT = 'add-payment';
const PROJECT_ADD_TEAM = 'add-team';
const PROJECT_OPEN_JOB = 'open-job';
const PROJECT_ASSIGN_JOB = ':type/:type_id(\\d+)/assign-job';
const PROJECT_ASSIGN_JOB_CONTRACTOR = ':contractor_id(\\d+)';
const PROJECT_JOB = ':job_id(\\d+)';
const PROJECT_JOB_OFFER = 'offers/:offer_id(\\d+)';
const PROJECT_JOB_ADD_CONTRACTOR = 'add-contractor';
const PROJECT_JOB_GRANT_REQUEST = 'grant-request';
const PROJECT_EDIT_PAYMENT = 'edit-payment/:payment_id(\\d+)';

export const Names = {
  PROJECT_CREATE,
  PROJECT_CREATE_NEW,
  PROJECT_VIEW,
  PROJECT_ADD_PAYMENT,
  PROJECT_ADD_TEAM,
  PROJECT_OPEN_JOB,
  PROJECT_ASSIGN_JOB,
  PROJECT_ASSIGN_JOB_CONTRACTOR,
  PROJECT_JOB,
  PROJECT_JOB_OFFER,
  PROJECT_JOB_ADD_CONTRACTOR,
  PROJECT_JOB_GRANT_REQUEST,
  PROJECT_EDIT_PAYMENT,
};
