/** @format */

// @flow

export const ids = {
  DRAFT: 'draft',
  OPENED: 'opened',
  EXECUTING: 'executed',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export default {
  StatusIDs: ids,
};
