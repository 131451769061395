/** @format */
import validatePhone from 'functions/validatePhone';

function isEmpty(obj, prop) {
  switch (true) {
    case !Object.prototype.hasOwnProperty.call(obj, prop):
    case Array.isArray(obj[prop]) && obj[prop].length === 0:
    case typeof obj[prop] === 'string' && obj[prop] === '':
    case typeof obj[prop] === 'number' && !obj[prop]:
      return true;
    default:
      return false;
  }
}

function getRequiredFields(userType, isNewAccount = true) {
  const defaultRequired = [
    'email',
    'name',
    'salutation_id',
    'surname',
    'telephone',
    ...(isNewAccount ? ['password', 'password_confirm'] : []),
  ];

  let addRequired = [];

  switch (userType) {
    case 'pm':
      addRequired = ['reference_id', 'branches'];
      break;
    case 'hw':
      addRequired = ['reference_id'];
      break;
    case 'lga':
      addRequired = ['agency_id'];
      break;
    case 'adm':
    case 'zek':
    default:
      break;
  }

  return [...defaultRequired, ...addRequired];
}

export default function validator(userType, isNewAccount, validationFailedCB) {
  const required = getRequiredFields(userType, isNewAccount);
  return data => {
    let fields = {}; // fields which contain errors

    if (data.password !== data.password_confirm) {
      fields = {
        ...fields,
        ...{ password: 'VALIDATION-required' },
      };
    }

    if (!validatePhone(data.telephone)) {
      fields = {
        ...fields,
        ...{ telephone: 'FORM-phone-description' },
      };
    }

    fields = required.reduce(
      (acc, curr) =>
        isEmpty(data, curr)
          ? {
              ...acc,
              [curr]: 'VALIDATION-required',
            }
          : acc,
      fields,
    );

    if (Object.keys(fields).length > 0 && validationFailedCB) {
      validationFailedCB();
    }

    return {
      result: Object.keys(fields).length === 0,
      fields,
    };
  };
}

export function formatApiErrors(errors) {
  let formattedErrors = errors;
  if (
    Object.prototype.hasOwnProperty.call(errors, 'email') &&
    errors.email[0] === 'unique'
  ) {
    formattedErrors = {
      ...errors,
      email: 'VALIDATION-email_in_use',
    };
  } else {
    formattedErrors = {
      ...errors,
    };
  }
  return formattedErrors;
}
