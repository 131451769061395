// @flow
import StateMachine from 'machines/reduxMachine';
import { transitions, PanelStates } from 'machines/constants';
import { ids as StatusIDs } from 'constants/jobStatus';
import {
  State,
  Transition,
  Edge as EdgeBuilder,
  Conditions,
} from 'async-state-machine';
import JobConditions from '../../conditions';

const {
  isContractor,
  isPropertyManager,
  isConstructionManager,
  isAssigned,
  isInvitee,
  isOnProjectTeam,
  isProjectJob,
  isProjectOwner,
  isJobStateWithin,
  isCreating,
} = JobConditions;

const { and, not, or } = Conditions;

// Transitions for budgets card
export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) => {
    const result = or([
      and([not(isProjectJob(params)), not(isContractor(params))]),
      and([
        isProjectJob(params),
        // isOnProjectTeam(params),
        // true
        and([
          // false
          not(isAssigned(params)), // false
          not(isInvitee(params)), // false
        ]),
      ]),
    ]);
    return result;
  });

export const to_editing = Object.create(Transition)
  .setName(transitions.to_editing)
  .addCondition((context, params) => {
    if (isCreating(params)) return true;
    return and([
      isJobStateWithin(params, [StatusIDs.DRAFT]),
      or([
        and([isPropertyManager(params), not(isProjectJob(params))]),
        and([
          isPropertyManager(params),
          isProjectJob(params),
          isOnProjectTeam(params),
        ]),
        and([
          isContractor(params),
          isProjectJob(params),
          isOnProjectTeam(params),
          not(or([isAssigned(params), isInvitee(params)])),
        ]),
        and([isConstructionManager(params), not(isProjectJob(params))]),
        and([
          isConstructionManager(params),
          isProjectJob(params),
          or([isOnProjectTeam(params), isProjectOwner(params)]),
        ]),
      ]),
    ]);
  });

function generateBudgetCardStateMachine() {
  const Edge = new EdgeBuilder();

  // States for Budgets Card
  const HIDDEN = Object.create(State).setName(PanelStates.HIDDEN);
  const VISIBLE = Object.create(State).setName(PanelStates.VISIBLE);
  const EDITING = Object.create(State).setName(PanelStates.EDITING);

  // Register Machine
  const Machine = new StateMachine('budget_card')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN, EDITING])
        .to(VISIBLE),
    )
    .registerEdge(
      Edge.new()
        .transition(to_editing)
        .from([VISIBLE, HIDDEN])
        .to(EDITING),
    )
    .setInitialState(HIDDEN, {}, true);

  return Machine;
}

export default generateBudgetCardStateMachine;
