/** @format */

// @flow
import React from 'react';

import ButtonGroup from '../../../../components/ButtonGroup';
import l from '../../../../helpers/locale';

import hasOwn from '../../../../functions/hasOwn.func.js';

const NewAccountSelect = (callback: Function): Function => (): React$Element<
  *,
> => (
  <ButtonGroup>
    <button
      type="button"
      onClick={function(e: Object): any {
        if (hasOwn(e, 'preventDefault')) {
          e.preventDefault();
        }
        return callback('pm');
      }}
      data-qe-id="action-menu_click-pm">
      {l('ACCOUNTS-create_account-pm')}
    </button>
    <button
      type="button"
      onClick={function(e: Object): any {
        if (hasOwn(e, 'preventDefault')) {
          e.preventDefault();
        }
        return callback('hw');
      }}
      data-qe-id="action-menu_click-hw">
      {l('ACCOUNTS-create_account-hw')}
    </button>
    <button
      type="button"
      onClick={function(e: Object): any {
        if (hasOwn(e, 'preventDefault')) {
          e.preventDefault();
        }

        return callback('adm');
      }}
      data-qe-id="action-menu_click-adm">
      {l('ACCOUNTS-create_account-adm')}
    </button>
    <button
      type="button"
      onClick={function(e: Object): any {
        if (hasOwn(e, 'preventDefault')) {
          e.preventDefault();
        }

        return callback('lga');
      }}
      data-qe-id="action-menu_click-lga">
      {l('ACCOUNTS-create_account-lga')}
    </button>
    <button
      type="button"
      onClick={function(e: Object): any {
        if (hasOwn(e, 'preventDefault')) {
          e.preventDefault();
        }

        return callback('zek');
      }}
      data-qe-id="action-menu_click-zek">
      {l('ACCOUNTS-create_account-zek')}
    </button>
    <button
      type="button"
      onClick={function(e: Object): any {
        if (hasOwn(e, 'preventDefault')) {
          e.preventDefault();
        }

        return callback('cm');
      }}
      data-qe-id="action-menu_click-cm">
      {l('ACCOUNTS-create_account-cm')}
    </button>
    <button
      type="button"
      onClick={function(e: Object): any {
        if (hasOwn(e, 'preventDefault')) {
          e.preventDefault();
        }

        return callback('hcm');
      }}
      data-qe-id="action-menu_click-hcm">
      {l('ACCOUNTS-create_account-hcm')}
    </button>
    <button
      type="button"
      onClick={function(e: Object): any {
        if (hasOwn(e, 'preventDefault')) {
          e.preventDefault();
        }

        return callback('acc');
      }}
      data-qe-id="action-menu_click-acc">
      {l('ACCOUNTS-create_account-acc')}
    </button>
  </ButtonGroup>
);

export default NewAccountSelect;
