// @flow
import StateMachine from 'machines/reduxMachine.js';
import { transitions } from 'machines/constants.js';

import {
  State,
  Transition,
  Edge as EdgeBuilder,
  Conditions,
} from 'async-state-machine';

import JobConditions from 'views/singleJob/cards/conditions';
import isProjectSupport from '../../conditions/isProjectSupport';
import isProjectOwner from '../../conditions/isProjectOwner';

const { isContractor } = JobConditions;
const { and, not } = Conditions;

// Transitions for internal docs card
export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) =>
    not(and([isContractor(params), isProjectSupport(params)])),
  );

export const to_editing = Object.create(Transition)
  .setName(transitions.to_editing)
  .addCondition((context, params) => {
    return isProjectOwner(params);
  });

function generateInternalDocsCardStateMachine() {
  const Edge = new EdgeBuilder();

  // States for Internal Docs Card
  const HIDDEN = Object.create(State).setName('HIDDEN');
  const VISIBLE = Object.create(State).setName('VISIBLE');
  const EDITING = Object.create(State).setName('EDITING');

  // Register Machine
  const Machine = new StateMachine('project_internaldocs_card')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN, EDITING])
        .to(VISIBLE),
    )
    .registerEdge(
      Edge.new()
        .transition(to_editing)
        .from([VISIBLE])
        .to(EDITING),
    )
    .setInitialState(HIDDEN, {}, true);

  return Machine;
}

export default generateInternalDocsCardStateMachine;
