export const OFFER_VARIANT_RESET_STATE = 'OFFER_VARIANT_RESET_STATE';
export const OFFER_VARIANT_SET_OFFERS = 'OFFER_VARIANT_SET_OFFERS';

export function removeDoc(
  imageId: string | number,
  instance: 'create_offer' | 'create_offer_secondary',
  dispatch: Function,
): any {
  return dispatch({
    type: 'JOB_OFFER_REMOVE_DOC',
    imageId,
    instance,
  });
}

export function addDoc(
  payload: Object,
  instance: 'create_offer' | 'create_offer_secondary',
  dispatch: Function,
  append: boolean,
): any {
  return dispatch({
    type: 'SET_DOC',
    payload,
    instance,
    append,
  });
}

export function clearDocs(instance: 'create_offer', dispatch: Function): any {
  return dispatch({
    type: 'JOB_OFFER_CLEAR_DOC',
    instance,
  });
}

export function setOffers(offers: Object[], dispatch: Function): any {
  return dispatch({
    type: OFFER_VARIANT_SET_OFFERS,
    payload: offers,
  });
}

export function resetOfferState(dispatch: Function): any {
  return dispatch({
    type: OFFER_VARIANT_RESET_STATE,
  });
}
