/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import ProjectTeamPanel from 'shared/projects/panels/items/ProjectTeam';
import { updateForm } from '../../actions';
import MachineBuilder from './machine';

type ReduxStateShape = {
  status: string,
};

type PropsShape = ReduxStateShape;

export const ProjectTeamCardMachine = MachineBuilder();

const ProjectTeamCard = ({
  team,
  editTeam,
  status,
  updateFormField,
  machine_state,
  panelEditMode,
  projectId,
  owner,
  editOwner,
}: PropsShape): React$Element<*> => {
  const getTeam = () => {
    const teams = panelEditMode
      ? [{ ...editOwner, is_owner: true }, ...editTeam]
      : [{ ...owner, is_owner: true }, ...team];

    return teams;
  };
  return (
    <StateConditional
      state={machine_state}
      whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
      <ProjectTeamPanel
        data={getTeam()}
        projectStatus={status}
        isEditing={panelEditMode}
        updateField={updateFormField}
        storeTargetLabel="exists"
        projectId={projectId}
      />
    </StateConditional>
  );
};

const mapState = ({ machineStore, projectStore }): ReduxStateShape => ({
  machine_state: machineStore.projectteam_card,
  panelEditMode: machineStore.projectteam_card === PanelStates.EDITING,
  team: projectStore.data.project_supports,
  owner: projectStore.data.project_owner,
  editTeam: projectStore.form.project_supports,
  editOwner: projectStore.form.project_owner,
  status: projectStore.data.status,
});

const mapActions = (dispatch: Function) => ({
  updateFormField(key: string, value: string): any {
    return dispatch(updateForm(key, value));
  },
});

export default connect(mapState, mapActions)(ProjectTeamCard);
