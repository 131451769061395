/**
 * /* eslint-disable
 *
 * @format
 */

import React from 'react';

import Card from '../../../../components/Card';

const Impressum = () => (
  <Card canCollapse={false} title="Impressum">
    <h2>Content &amp; Responsibility</h2>
    <p>
      Allianz Suisse Immobilien AG
      <br />
      Richtiplatz 1<br />
      8304 Wallisellen - Schweiz
    </p>
    <br />
    <h2>Implementation</h2>
    <p>
      Sensational AG
      <br />
      Giesshübelstrasse 62c
      <br />
      8021 Zurich
      <br />
      Switzerland
      <br />
      <a
        href="http://www.sensational.ch"
        target="_blank"
        rel="noopener noreferrer">
        www.sensational.ch
      </a>
    </p>
  </Card>
);

export default Impressum;
