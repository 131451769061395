import React from 'react';
import { connect } from 'react-redux';
import { ids as JobStatus } from 'constants/jobStatus';
import moment from 'moment';
import l, { getLocale } from 'helpers/locale';
import Card from 'components/Card';
import JobStatusSVG from './components/jobStatusSVG';
import DatePicker from './components/datePicker';
import OccasionSelect from './components/occasionSelect';
import DescriptionEditor from './descriptionEditor.js';

type ReduxStateShape = {
  error_occasion?: string[] | boolean,
  error_summary?: string[] | boolean,
  error_desired_date?: string[] | boolean,
  error_target_date?: string[] | boolean,
  error_order_date?: string[] | boolean,
};

export type DataShape = {
  status: string,
  info_desired_date: number | boolean,
  info_target_date?: number,
  info_target_date_submission?: number | boolean,
  info_occasion: string,
  info_occasion_description: string,
  summary: string,
};

export type FormDataShape = {
  info_desired_date: number,
  info_target_date: number,
  info_target_date_submission: number | boolean,
  info_summary: string,
  info_occasion: string,
  occasions: Object[],
  updateField: Function,
};

type PropsShape = {
  data: DataShape,
  jobStatus: string,
  isEditing: boolean,
  formData: FormDataShape,
  isJobOffer: boolean,
  storeTargetLabel: 'create' | 'exists',
} & ReduxStateShape;

class InformationJobPanel extends React.Component<PropsShape> {
  static defaultProps = {
    error_occasion: false,
    error_summary: false,
    error_desired_date: false,
    error_target_date: false,
    error_order_date: false,
  };

  constructor(props: PropsShape) {
    super(props);
    moment.locale(getLocale());
  }

  getJobStatus = classSuffix =>
    this.props.jobStatus !== '' && (
      <div className="g g-1/2">
        <div className={`form-and-view-container ${classSuffix}`}>
          <label className="form__label--small" htmlFor="__">
            {l('JOB-status')}
          </label>
          <p className="text--dk--flushed">
            <span className="v-align--center soft--smallest--right">
              <JobStatusSVG status={this.props.data.status} />
            </span>
            {l(`JOB_STATE_${this.props.data.status}`)}
          </p>
        </div>
      </div>
    );

  getOccasion = classSuffix => {
    return (
      <OccasionSelect
        label={l('JOB-occasion')}
        placeholder={l('JOB-occasion-choose-placeholder')}
        classSuffix={classSuffix}
        formatLabel={value => l(`JOB-occasion-option_${value}`)}
        value={this.props.formData.info_occasion}
        options={this.props.formData.occasions}
        onChange={value =>
          this.props.updateField({
            key: 'info_occasion',
            value,
          })
        }
        description={this.props.data.info_occasion_description}
        isEditing={this.props.isEditing}
        hasError={this.props.error_occasion}
      />
    );
  };

  getOrderDate = classSuffix => {
    return (
      <DatePicker
        label={l('JOB-order_date_offers')}
        placeholder={l('CALENDAR-select_date')}
        hasError={this.props.error_order_date}
        isEditing={this.props.isEditing}
        date={
          this.props.data.info_target_date
            ? moment.unix(this.props.data.info_target_date)
            : null
        }
        onChange={date =>
          this.props.updateField({
            key: 'info_order_date',
            value: date,
          })
        }
        classSuffix={classSuffix}
        dataQeId="input-select-order_date"
        innerClass="form__input"
      />
    );
  };

  getTargetDateSubmission = classSuffix => {
    return (
      <DatePicker
        label={l('JOB-target_date_submission')}
        placeholder={l('CALENDAR-select_date')}
        hasError={this.props.error_target_date}
        isEditing={this.props.isEditing}
        date={
          this.props.data.info_target_date_submission
            ? moment.unix(this.props.data.info_target_date_submission)
            : null
        }
        onChange={date =>
          this.props.updateField({
            key: 'info_target_date_submission',
            value: date,
          })
        }
        classSuffix={classSuffix}
        dataQeId="input-select-target_date_submission"
        innerClass="form__input"
        enableOutsideDays={false}
        showBeforeTodayDifferently={false}
      />
    );
  };

  getDeliveryDate = classSuffix => {
    const {
      isEditing,
      error_desired_date,
      data: { info_desired_date },
      updateField,
    } = this.props;
    return (
      <DatePicker
        label={l('JOB-desired_delivery_date')}
        placeholder={isEditing ? l('CALENDAR-select_date') : '-'}
        hasError={error_desired_date}
        isEditing={isEditing}
        date={info_desired_date ? moment.unix(info_desired_date) : null}
        onChange={date =>
          updateField({
            key: 'info_desired_date',
            value: date,
          })
        }
        classSuffix={classSuffix}
        dataQeId="input-select-delivery_date"
        innerClass="text--dk--flushed"
      />
    );
  };

  getDescription = classSuffix => (
    <div className="g g-1/1">
      <div
        className={`form-and-view-container ${classSuffix} ${
          this.props.error_summary ? 'form-and-view-container--error' : ''
        }`}>
        <label className="form__label--small" htmlFor="__">
          {l('JOB-order_summary')}
        </label>
        <DescriptionEditor
          updateField={this.props.updateField}
          isEditing={this.props.isEditing}
          target={this.props.storeTarget}
          storeTargetLabel={this.props.storeTargetLabel}
        />
      </div>
    </div>
  );

  canShowOrderDate = () => {
    const jobStatus = parseInt(this.props.jobStatus, 10);
    return (
      jobStatus >= JobStatus.GRANTED ||
      (jobStatus <= JobStatus.DRAFT && !this.props.isJobOffer) ||
      !this.props.isJobOffer
    );
  };

  render(): React$Element<*> {
    const classSuffix = this.props.isEditing ? '' : 'form-and-view--view';

    return (
      <Card
        title="JOB-information"
        canCollapse={false}
        qeId="job_card-information">
        <div className="gw">
          <div className="g g-1/1">
            <div className="gw">
              <div className="g g-1/2">
                <div className="gw">
                  {this.getJobStatus(classSuffix)}
                  {this.getOccasion(classSuffix)}
                  {this.canShowOrderDate() && this.getOrderDate(classSuffix)}
                </div>
              </div>
              <div className="g g-1/2">
                <div className="gw">
                  {this.props.isJobOffer &&
                    this.getTargetDateSubmission(classSuffix)}
                  {this.getDeliveryDate(classSuffix)}
                </div>
              </div>
            </div>
          </div>
          <div className="g g-1/1">
            <div className="gw">{this.getDescription(classSuffix)}</div>
          </div>
        </div>
      </Card>
    );
  }
}

const mapState = (state: Object): ReduxStateShape => ({
  // errors: state.jobStore.errors,
  error_occasion: state.jobStore.errors.info_occasion,
  error_summary: state.jobStore.errors.info_summary,
  error_desired_date: state.jobStore.errors.info_desired_date,
  error_target_date: state.jobStore.errors.info_target_date_submission,
  error_order_date: state.jobStore.errors.info_order_date,
});

export default connect(mapState)(InformationJobPanel);
