import { debounced } from 'functions/debounced.func';
import setQueryParameters from '../../../functions/setQueryParameters';

import axios from '../../../helpers/axios';

import {
  SIMPLE_LIST_SET_DATA,
  SIMPLE_LIST_SET_DATA_LOADING,
  SIMPLE_LIST_SET_DATA_FAILED,
  SIMPLE_LIST_FLUSH_DATA,
  SIMPLE_LIST_SORT_BY_FILTER,
  SIMPLE_LIST_UPDATE_QUERIES,
  SIMPLE_LIST_UPDATE_DATA_REQUEST,
  SIMPLE_LIST_UPDATE_DATA,
  SIMPLE_LIST_UPDATE_DATA_FAILED,
} from '../constants';

import { initialQueryObj } from '../reducers';

const debounceBuilder = debounced(200); // note that the Filter component does it's own debounce - might remove that in preference to this one

/*
 * :: IMPORTANT ::
 * -- Every simple list action must present a name so that we know
 * --  what list to apply the action to
 */
export function setInitialQuery(name, presetQueries = null, searchTerm = '') {
  return (dispatch, getStore) => {
    const state = getStore().simpleListStore.list[name];
    let currentQueries = state.queries || initialQueryObj;
    let newQueries = currentQueries;
    if (presetQueries) {
      Object.keys(presetQueries).forEach(term => {
        currentQueries = setQueryParameters(
          newQueries,
          term,
          presetQueries[term],
        );
      });
      newQueries = currentQueries.nextState;
    }

    if (searchTerm !== '') {
      currentQueries = setQueryParameters(newQueries, 'search', searchTerm);
      newQueries = currentQueries.nextState;
    }

    dispatch({
      type: SIMPLE_LIST_UPDATE_QUERIES,
      name,
      queries: newQueries,
    });
  };
}

export function makeBasicRequest(name: string, api: string): any {
  return (dispatch, getStore) => {
    const state = getStore().simpleListStore.list[name];
    const currentQueries = setQueryParameters(state.queries);
    dispatch({
      type: SIMPLE_LIST_SET_DATA_LOADING,
      name,
    });

    return (
      axios
        .get(`${api}?${currentQueries.query}`)
        // eslint-disable-next-line no-confusing-arrow
        .then((res: Object): any =>
          dispatch({
            type: SIMPLE_LIST_SET_DATA,
            name,
            response: res.data.payload,
            api,
          }),
        )
        // eslint-disable-next-line no-confusing-arrow
        .catch((err: Object): any =>
          dispatch({
            type: SIMPLE_LIST_SET_DATA_FAILED,
            name,
            err,
            api,
          }),
        )
    );
  };
}

export function flushListData(name: string): { type: string, name: string } {
  return { type: SIMPLE_LIST_FLUSH_DATA, name };
}

export function filterData(
  name: string,
  filterFields: [{ value: string, category: string }],
): {
  type: string,
  filterFields: [{ value: string, category: string }],
  name: string,
} {
  return { type: SIMPLE_LIST_SORT_BY_FILTER, filterFields, name };
}

// This replaces setQueryParamaters in the case when the 'initial_sort'
// string needs to loaded into the 'Queries' prop inside the Store
export function updateQueriesFromString(
  listName: string,
  queryString: string,
): any {
  return function updateQueriesFromStringThunk(
    dispatch: Function,
    getStore: Function,
  ): any {
    const state = getStore().simpleListStore.list[listName];
    dispatch({
      type: SIMPLE_LIST_UPDATE_QUERIES,
      name: listName,
      queries: {
        ...state.queries,
        sort: queryString,
      },
    });
  };
}

function startQueryProcess(
  listName: string,
  queryKey?: string = '',
  queryParam1?: string = '',
  queryParam2?: string = '',
) {
  return (dispatch: Function, getStore: Function): any => {
    /**
     * Stage 1
     * Init loading state in redux store for this list
     */
    dispatch({
      type: SIMPLE_LIST_UPDATE_DATA_REQUEST,
      name: listName,
    });

    /**
     * Stage 2
     * Create Query Object to be used in body of api query
     */
    const state = getStore().simpleListStore.list[listName];
    const queryObj = setQueryParameters(
      state.queries,
      queryKey,
      queryParam1,
      queryParam2,
    );

    /**
     * Stage 3
     * Update queries in store
     */
    dispatch({
      type: SIMPLE_LIST_UPDATE_QUERIES,
      name: listName,
      queries: queryObj.nextState,
    });

    /**
     * Stage 4
     * Make request to external api
     */
    return debounceBuilder(() =>
      axios
        .get(`${state.api}?${queryObj.query}`)
        .then((res: Object): any =>
          /**
           * Optionally update data on success
           */
          shouldDataUpdate(
            queryObj.nextState,
            getStore().simpleListStore.list[listName].queries,
            dispatch.bind(null, {
              type: SIMPLE_LIST_UPDATE_DATA,
              name: listName,
              response: res.data.payload,
              queries: queryObj.nextState,
            }),
          ),
        )
        .catch((err: Object): any =>
          /**
           * Optionally update data on error
           */
          shouldDataUpdate(
            queryObj.nextState,
            getStore().simpleListStore.list[listName].queries,
            dispatch.bind(null, {
              type: SIMPLE_LIST_UPDATE_DATA_FAILED,
              name: listName,
              err,
            }),
          ),
        ),
    );
  };
}

export function groupList(
  listName: string,
  // eslint-disable-next-line no-unused-vars
  apiEndpoint: string,
  groupBy: string,
): any {
  return startQueryProcess(listName, 'grouping', groupBy);
}

export function sortList(
  listName: string,
  // eslint-disable-next-line no-unused-vars
  apiEndpoint: string,
  sortBy: string,
  sortType: string,
): any {
  return startQueryProcess(listName, 'order', sortBy, sortType);
}

export function searchList(
  listName: string,
  // eslint-disable-next-line no-unused-vars
  apiEndpoint?: string = false,
  searchTerm?: string = '',
): any {
  return startQueryProcess(listName, 'search', searchTerm);
}

export function paginateList(
  listName: string,
  // eslint-disable-next-line no-unused-vars
  apiEndpoint: string,
  pageNumber: number,
): any {
  return startQueryProcess(listName, 'paginate', pageNumber);
}

export function updatePerPage(
  listName: string,
  // eslint-disable-next-line no-unused-vars
  apiEndpoint: string,
  itemsPerPage: number | string,
): any {
  return startQueryProcess(listName, 'perPage', itemsPerPage);
}

export function filterListByDate(
  listName: string,
  // eslint-disable-next-line no-unused-vars
  apiEndpoint: string,
  start: string,
  to: string,
): any {
  return startQueryProcess(listName, 'byDate', start, to);
}

export function updateSimpleList(listName: string): any {
  return startQueryProcess(listName);
}

function shouldDataUpdate(
  pastState: Object,
  nowState: Object,
  callback: Function,
): boolean {
  if (pastState.counter !== nowState.counter) return false;

  return callback();
}
