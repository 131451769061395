import ContactCard from './ContactsCard/index';
import ContractorCard from './ContractorCard/index';
import InternalDocsCard from './InternalDocsCard/index';
import InformationCard from './InformationCard/index';
import ObjectCard from './ObjectCard/index';
import RatingCard from './RatingCard/index';
import AgreementCard from './AgreementCard/index';
import PaymentListCard from './PaymentListCard/index';
import AccountCard from './AccountCard/index';
import NotificationCard from './NotificationCard/index';
import OfferListCard from './OfferListCard/index';
import ParticipantsCard from './ParticipantsCard/index';
import DocumentExchangeCard from './DocumentExchangeCard/index';
import TenderDocumentsCard from './TenderDocumentsCard/index';
import InvoiceProcessCard from './InvoiceProcessCard/index';
import ProjectTeamDocsCard from './ProjectTeamDocsCard/index';
import BudgetCard from './BudgetCard/index';

export default {
  ContactCard,
  ContractorCard,
  InternalDocsCard,
  InformationCard,
  ObjectCard,
  AgreementCard,
  PaymentListCard,
  AccountCard,
  NotificationCard,
  OfferListCard,
  ParticipantsCard,
  TenderDocumentsCard,
  DocumentExchangeCard,
  ProjectTeamDocsCard,
  BudgetCard,
  InvoiceProcessCard,
  RatingCard,
};
