/** @format */

// @flow

import React from 'react';
import { connect } from 'react-redux';
import getToken from 'functions/getToken';

import Panel from './billOrderPanel.js';

const BillManagementPanelWrapper = ({
  address,
  coversheet,
  address_account,
}) => (
  <Panel
    address={address}
    token={getToken()}
    coversheet={coversheet}
    address_account={address_account}
  />
);

const mapState = state => ({
  userRole: parseInt(state.authStore.user.role, 10),
});

export default connect(mapState)(BillManagementPanelWrapper);
