export default function createProject(concernType, concernId) {
  const params = `concern_id:${concernId}, concern_type:${concernType.toUpperCase()}`;

  const query = `
    {create_project(${params}) {
      address_scan_center,
      address_owner,

      contact_audit {
        contact_id,
        full_name,
        email,
        telephone,
        street,
        postal_code,
        city,
        type,
        state_machine {
          status,
          available_transitions {
            name
          }
        },

      },

      document_tags
      documents {
        document_id
        name
        mime_type
        source_tag
        source_type
        source_id
        blob
        url
        timestamp

        user {
          full_name
          role_id
        }

        state_machine {
          status
          available_transitions {
            name
            action
            type
          }
        }
      }

      occasions {
        key
        value
      }

      project_owner {
        user_id
        name
        surname,
        telephone,
        full_name
        email
      }

      concern {
        street,
        postal_code,
        city,
        post_city,
        reference,
        description,
        concern_id,
        concern_type,
        has_elevator,
        rooms_count,

        accountings{
          key, value,
        },

        cost_centers{
          key, value,
        },

        floor{
          code,
          description,
        },
      }

      state_machine {
        status
        available_transitions {
          name
          action
          type
        }
      }
    }}
  `;

  return query;
}
