export const getPrimaryPath = path =>
  path
    .split('/')
    .filter((item, index) => index === 1)
    .pop();

export function fullPathMatch(path, name) {
  return path === name;
}

export function pathIncludes(path, name) {
  if (name.includes('/')) {
    return forwardSlashComparison(path.split('/'), name);
  }
  return path.split('/').includes(name);
}

export function pathEndsWith(path, name) {
  const pieces = path.split('/');

  if (name.includes('/')) {
    return forwardSlashComparison(pieces, name);
  }

  return pieces[pieces.length - 1].includes(name);
}

/**
 * Looks for a matching Path part within a primary search
 * @param {array} primary - the path split by (/) to compare against 'search'
 * @param {*} search - the sub-path to find a full match within 'primary'
 */
function forwardSlashComparison(primary, search) {
  const searchSplit = search.split('/');
  const instances = searchSplit.length;
  const indexOfFirstPrimaryPartMatch = primary.indexOf(searchSplit[0]);
  const section = primary.slice(
    indexOfFirstPrimaryPartMatch,
    indexOfFirstPrimaryPartMatch + instances,
  );
  return section.join('/') === search;
}
