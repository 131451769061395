// @flow
import StateMachine from 'machines/reduxMachine.js';
import { transitions } from 'machines/constants.js';
import { State, Transition, Edge as EdgeBuilder } from 'async-state-machine';

// Transitions for tender documents card
export const to_visible = Object.create(Transition).setName(
  transitions.to_visible,
);

export const to_editing = Object.create(Transition).setName(
  transitions.to_editing,
);

function generateTenderDocumentsCardStateMachine() {
  const Edge = new EdgeBuilder();

  // States for Tender Documents Card
  const HIDDEN = Object.create(State).setName('HIDDEN');
  const VISIBLE = Object.create(State).setName('VISIBLE');
  const EDITING = Object.create(State).setName('EDITING');

  // Register Machine
  const Machine = new StateMachine('tenderdocuments_card')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN, EDITING])
        .to(VISIBLE),
    )
    .registerEdge(
      Edge.new()
        .transition(to_editing)
        .from([VISIBLE, HIDDEN])
        .to(EDITING),
    )
    .setInitialState(HIDDEN, {}, true);

  return Machine;
}

export default generateTenderDocumentsCardStateMachine;
