// @flow

import React from 'react';
import GetSvg from 'components/GetSvg';
import { wrapTooltip } from '@bonlineza/b-lib';
import { formatCurrency, formatDate } from './formatters';

type FormatItem = {
  start: number,
  end: number | void,
  format: string => any,
  match: string,
};

/*
 * Formats text as a date.
 *
 * We expect the text to include a `|` which separates the timestamp
 * from the format
 */
function formatAsDate(text: string): string | React$Element<*> {
  const [unix, format] = text.split('|');
  return formatDate(unix, format);
}

/*
 * Formats content around a bolded span, calls "formatter" on any children
 */
function formatAsBold(text: string): string | React$Element<*> {
  return <span className="fw--bold">{formatter(text)}</span>;
}

/*
 * Replaces content with an svg, `text` must be split with a `|` where the left
 * side will represent the name of the svg and the right side the class of the svg
 */
function formatAsSvg(text: string): string | React$Element<*> {
  const [svgName, svgClass] = text.split('|');

  return <GetSvg svg={svgName} wrapperClass={svgClass} />;
}

/*
 * Wraps some text around a b-lib/tooltip component
 *
 * `text` must be split with a `|` where the left side will represent
 * what is included as text in the tooltip and the right side represents
 * what the tooltip will wrap around
 */
function formatAsTooltip(text: string): string | React$Element<*> {
  const [toolContent, ...rest] = text.split('|');
  const visibleContent = formatter(rest.join('|'));
  return wrapTooltip(visibleContent, toolContent);
}

/*
 * A helpful method to wrap formatters in an easy to use format
 */
function buildFormatItem(
  text: string,
  match: string,
  format: string => any,
): FormatItem {
  const start = text.indexOf(match);
  const end = text.lastIndexOf(match);

  return {
    start,
    end,
    format,
    match,
  };
}

/*
 * Returns all available formatters in the shape of FormatItem
 */
function getFormatters(prefix: string, text: string): Array<FormatItem> {
  return [
    [`${prefix}d:`, formatAsDate],
    [`${prefix}c:`, formatCurrency],
    [`${prefix}*`, formatAsBold],
    [`${prefix}tp:`, formatAsTooltip],
    [`${prefix}:`, formatAsSvg],
  ].map(item => buildFormatItem(text, item[0], item[1]));
}

/*
 * Returns the formatter which will take affect first based on the position of
 * the mutation in the string
 */
function getFirstFormatterForString(
  formatters: Array<FormatItem>,
  text: string,
): FormatItem {
  return formatters.reduce(
    (acc, curr) => {
      if (curr.start !== -1 && curr.start < acc.start) {
        return curr;
      }
      return acc;
    },
    { start: text.length, format: str => str },
  );
}

/*
 * Attempts to formats a string with available formatters
 *
 * LIMITATIONS:
 *   - Follow html rules for closing tags
 *     This means that the following strings are valid:
 *     | √ | \\* hello \\* \\* world \\*
 *
 *     But the following strings are not valid
 *     | x | \\* hello \\tp:TooltipText|blah \\* \\tp: | Invalid - tp not closed before *
 */
function formatter(textInput: string = ''): string | Array<*> {
  const text = textInput || '';
  // How all formatters are expected to to be identified
  const FORMATTER_IDENTIFIER = '\\';

  const canFormat = text.includes(FORMATTER_IDENTIFIER);

  if (!canFormat) {
    /*
     * we know there is nothing in this string that we need to format so just return
     * the string
     */
    return text;
  }

  // we will use this variable to do mutations onto the passed in text
  let mutation: string = text;

  // available formatters
  const formatters = getFormatters(FORMATTER_IDENTIFIER, text);

  const { start, end, match, format } = getFirstFormatterForString(
    formatters,
    text,
  );

  // get content before the formatter is relevant
  const before = `${text.substring(0, start)} `;

  // get content after the formatter is done
  const after = ` ${text.substring(end + match.length)} `;

  // part of the string that will be formatted
  mutation = text.substring(start + match.length, end);

  // let's keep formatting the rest of the string
  let appendParts = formatter(after);

  if (!Array.isArray(appendParts)) {
    // we really want this structure to be an array but it might be a string so...
    appendParts = [appendParts];
  }

  return [before, format(mutation), ...appendParts];
}

export default formatter;
