import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DropOptions from '@bonlineza/b-lib/DropOptions';
import l from 'helpers/locale';
import GetSvg from 'components/GetSvg';
import { ids as RoleId } from 'constants/roles';

import { unAuth } from '../../actions/auth';

type ProfileMenuProps = { name: string };
const ProfileMenu = ({ name }: ProfileMenuProps): Function => () => (
  <div className="profile">
    <GetSvg svg="user" wrapperClass="profile__prefix" />
    <div className="profile__main">{`${name || ''}`}</div>
    <GetSvg svg="caret_down" wrapperClass="profile__postfix" />
  </div>
);

type ReduxStateShape = {
  isAuthed: boolean,
  name: string,
  canEditProfile: boolean,
};

type PropsShape = {
  logout: Function,
  history: Object,
} & ReduxStateShape;

const Header = ({
  logout,
  name,
  canEditProfile,
  isAuthed,
  history,
}: PropsShape): React$Element<*> => {
  // returns array as 'DropOptions' does not like inline ternaries
  const getButtons = () => {
    const buttons = [];
    if (canEditProfile) {
      buttons.push(
        <button
          key="edit-profile"
          type="button"
          className="drop-options__options__item__default"
          onClick={(e: Event) => {
            e.preventDefault();
            history.push(`/profile/view`);
          }}>
          {l('ACTION-edit-profile')}
        </button>,
      );
    }
    buttons.push(
      <button
        key="logout"
        type="button"
        className="drop-options__options__item__default"
        onClick={(e: Event) => {
          e.preventDefault();
          logout();
        }}>
        {l('ACTION-logout')}
      </button>,
    );
    return buttons;
  };

  return (
    <div className="area-header">
      <div className="gw">
        <div className="g g--shrink-wrap">
          <div className="area-header__logo">
            <a href="/">
              <img alt="Allianz" src="/images/logo.png?1" height="40" />
            </a>
          </div>
        </div>
        <div className="g g--auto">
          {isAuthed ? (
            <DropOptions baseElement={ProfileMenu({ name })} isOpen={false}>
              {getButtons()}
            </DropOptions>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const mapDispatch = (dispatch: Function): { logout: Function } => ({
  logout: (): any => dispatch(unAuth()),
});

const mapState = ({ authStore }): ReduxStateShape => ({
  isAuthed: authStore.auth,
  name: authStore.user.name,
  canEditProfile: RoleId.CONTRACTOR === authStore.user.role,
});

export default withRouter(connect(mapState, mapDispatch)(Header));
