import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'helpers/axios';
import l from 'helpers/locale';
import formatErrors from 'helpers/formatErrors';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import { publishFormFailed } from 'components/ConnectedActionBar/actions';
import Form from 'components/Form';
import FooterBar from 'components/FooterBar';
import ButtonGroup from 'components/ButtonGroup';
import UntilReady from 'components/UntilReady';
import defaultRequest from 'constants/request';
import { SliderContext } from 'components/RouteSlider';

import fieldsObj from './fieldsObj';

type ReduxActionsShape = {
  publishFormFailed: Function,
};

type PropsShape = {
  contractorId?: string | number | boolean,
  jobId?: string | number | boolean,
  successCallback?: Function | boolean,
  onData?: Function,
  backAction?: Function,
} & ReduxActionsShape;

type StateShape = {
  form: {
    nomination_company: string,
    nomination_salutation_id: number,
    nomination_first_name: string,
    nomination_last_name: string,
    nomination_email: string,
    nomination_cause: string,
    agency_id: number,
  },
  data: {
    agencies: Array<Object>,
    salutations: Array<Object>,
    causes: Array<Object>,
  },
  errors: { [string]: [string] | string[] },
};

const errorFormatting = [
  {
    field: 'nomination_email',
    condition: 'unique',
    message: 'VALIDATION-email_in_use',
  },
  {
    field: 'nomination_email',
    condition: 'email',
    message: 'VALIDATION-required',
  },
];

const initialState = {
  form: {
    nomination_company: '',
    nomination_salutation_id: 0,
    nomination_first_name: '',
    nomination_last_name: '',
    nomination_email: '',
    nomination_cause: '',
    agency_id: 0,
  },
  data: {
    agencies: [],
    salutations: [],
    causes: [],
  },
  request: {
    get: {
      ...defaultRequest,
      fetching: true,
    },
    submit: {
      ...defaultRequest,
    },
  },
  errors: {},
};

class NominationForm extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    contractorId: false,
    successCallback: false,
    jobId: false,
    onData: null,
    backAction: null,
  };

  constructor(props: PropsShape) {
    super(props);
    this.getApi = '/api/1/static-data';
    this.submitApi = '/api/1/contractor_profiles';
    this.state = {
      ...initialState,
    };
  }

  state: StateShape;

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contractorId !== this.props.contractorId) {
      this.getData();
    }
  }

  getApi: string;

  getData = (): Promise<*> => {
    this.setState(prevState => ({
      request: {
        ...prevState.request,
        get: {
          success: false,
          fetching: true,
          error: false,
        },
      },
    }));
    axios
      .get(
        `${this.getApi}?${
          this.props.contractorId
            ? `contractor_id=${this.props.contractorId}`
            : ''
        }`,
      )
      .then((res: Object): any => this.successHandler('get', res.data.payload))
      .catch((err: Object): any => this.errorHandler('get', err));
  };

  getFields = (data: any): Array<Object> => fieldsObj(data);

  getFooterButtons = actions => {
    const actionConfig = [
      {
        name: 'cancel-form',
        'data-meta': {
          type: 'text',
        },
        type: 'button',
        label: 'JOB-ACTIONS-BACK',
        action: this.props.backAction,
      },
      { name: 'submit', className: 'btn--primary', type: 'submit' },
    ];

    return actionConfig
      .map(act => ({
        ...act,
        ...actions.find(item => item.name === act.name),
      }))
      .map(act => {
        return (
          <button
            type={act.type || 'button'}
            {...act.attrs}
            data-meta={act['data-meta']}
            className={act.className}
            name={act.name}
            onClick={e => {
              if (act.action) {
                act.action(e);
              }
            }}>
            {l(act.label)}
          </button>
        );
      });
  };

  submitData = (payload: Object): Promise<*> => {
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      errors: {},
      request: {
        ...prevState.request,
        submit: {
          ...prevState.request.submit,
          fetching: true,
          error: false,
        },
      },
    }));

    return axios
      .post(this.submitApi, {
        ...payload,
        ...(this.props.jobId ? { job_id: this.props.jobId } : {}),
      })
      .then((res: Object): any =>
        this.successHandler('submit', res.data.payload),
      )
      .catch((err: Object): any => this.errorHandler('submit', err));
  };

  submitApi: string;

  successHandler = (type: string, data: Object): any => {
    if (type === 'submit') {
      if (this.props.successCallback) {
        this.props.successCallback(data);
      }
      return;
    }

    if (this.props.onData) {
      this.props.onData(data);
    }
    this.setState((prevState: StateShape): StateShape => {
      switch (type) {
        case 'get':
          return {
            ...prevState,
            data: {
              ...data,
            },
            request: {
              ...prevState.request,
              [type]: {
                success: true,
                fetching: false,
                error: false,
              },
            },
          };
        case 'submit':
        default:
          return prevState;
      }
    });
  };

  errorHandler = (type: string, errors: Object): any => {
    this.props.publishFormFailed();
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      request: {
        ...prevState.request,
        [type]: {
          success: false,
          fetching: false,
          error: true,
        },
      },
      errors: errors.data ? errors.data.payload : errors,
    }));
  };

  cancelForm = () => this.props.backAction();

  validator = (validationFailedCB: Function): any => (payload: Object): any => {
    const requiredFields = [
      'nomination_cause',
      !this.props.contractorId ? 'nomination_company' : '',
      'nomination_email',
      'nomination_first_name',
      'nomination_langcode',
      'nomination_last_name',
      'nomination_salutation_id',
    ].filter((v: string): boolean => v !== '');

    // eslint-disable-next-line no-confusing-arrow
    const validatorResponse = requiredFields.reduce(
      (
        acc: any,
        curr: any,
        // eslint-disable-next-line no-confusing-arrow
      ): any =>
        !payload[curr] ? { ...acc, [curr]: 'VALIDATION-required' } : acc,
      {},
    );

    if (Object.keys(validatorResponse).length > 0 && validationFailedCB) {
      validationFailedCB();
    }

    return {
      result: Object.keys(validatorResponse).length === 0,
      fields: validatorResponse,
    };
  };

  render(): React$Element<*> {
    return (
      <SliderContext.Consumer>
        {context => (
          <Fragment>
            <ActionBar name="form" />
            <UntilReady
              ready={this.state.request.get.success}
              waiting={this.state.request.get.fetching}>
              <Form
                scrollToTop={context.scrollToTop}
                submitAct={this.submitData}
                cancelAct={this.cancelForm}
                sections={this.getFields(this.state.data)}
                wrappingClass="gw"
                wrappingOuterClass=""
                validateBefore={this.validator(this.props.publishFormFailed)}
                isProcessing={this.state.request.submit.fetching}
                afterSubmitErrors={formatErrors(
                  this.state.errors,
                  errorFormatting,
                )}
                renderFooter={actions => (
                  <FooterBar>
                    <div className="fl-right">
                      <div className="fl-right__item">
                        <ButtonGroup alt numButtons={3}>
                          {this.getFooterButtons(actions)}
                        </ButtonGroup>
                      </div>
                    </div>
                  </FooterBar>
                )}
              />
            </UntilReady>
          </Fragment>
        )}
      </SliderContext.Consumer>
    );
  }
}

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  publishFormFailed(): any {
    return dispatch(publishFormFailed());
  },
});

export default connect(null, mapActions)(NominationForm);
