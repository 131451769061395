/** @format */

// @flow
import React from 'react';

import getToken from 'functions/getToken';
import l from 'helpers/locale';

const PropertiesListActionGroup = (
  clickAction: Function,
): Function => (): React$Element<*> => (
  <a
    data-qe-id="action-click-properties-properties_export"
    className="button-group__primary__item__btn"
    href={`/exports/properties.xlsx?token=${getToken()}`}
    download="properties.xlsx"
    onClick={clickAction}>
    {l('ACTION-export_list')}
  </a>
);

export default PropertiesListActionGroup;
