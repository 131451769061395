/** @format */

import React from 'react';
import Card from 'components/Card';
import DocumentUploader from 'components/connected/DocumentUploader';
import DocConstants from 'components/connected/DocumentUploader/constants';

const { VIEW, DOWNLOAD } = DocConstants.DOCUMENT_ACTION;

const DashboardDocuments = ({ documents }) => (
  <Card title="ADM_DASHBOARD-documents" canCollapse={false}>
    <DocumentUploader
      controls={[
        {
          documentAction: VIEW,
          svgName: 'view',
          canDisplay: true,
        },
        {
          documentAction: DOWNLOAD,
          svgName: 'download',
          canDisplay: true,
        },
      ]}
      dataQeId="action-drop-account_document"
      listName="account_documents"
      list={documents}
    />
  </Card>
);

export default DashboardDocuments;
