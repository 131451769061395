// @flow
import React from 'react';

import ButtonGroup from 'components/ButtonGroup';
import l from 'helpers/locale';
import getToken from 'functions/getToken';

import Roles from 'constants/roles';

const { RoleIds } = Roles;

const NomationActionButtons = (
  newNomination: Function,
  exportList: Function,
  userRole: string,
): Function => (): React$Element<*> => (
  <ButtonGroup>
    {[
      RoleIds.PROPERTY_MANAGER,
      RoleIds.AGENCY_MANAGER,
      RoleIds.CONSTRUCTION_MANAGER,
      RoleIds.HEAD_CONSTRUCTION_MANAGER,
    ].includes(userRole) ? (
      <button
        type="button"
        onClick={newNomination}
        data-qe-id="action-click-nominate_button">
        {l('NOMINATION-new')}
      </button>
    ) : null}
    <a
      data-qe-id="action-click-nominations-nominations_export"
      href={`/exports/nominations.xlsx?token=${getToken()}`}
      download="contractors.xlsx"
      onClick={exportList}>
      {l('ACTION-export_list')}
    </a>
  </ButtonGroup>
);

export default NomationActionButtons;
