import React from 'react';

import l from 'helpers/locale';
import Select from 'components/VirtualizedSelect';

class BKPSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: props.value ? this.getValueFromPropId(props.value) : {},
    };

    if (props.value) {
      props.controlRef(props.name, props.value);
    }
  }

  getValueFromPropId = id => {
    const item = this.props.data[this.props.data.findIndex(v => v.id === id)];
    if (!item) return {};
    return {
      value: item.id,
      label: item.name,
    };
  };

  update = item => {
    this.props.controlRef(this.props.name, item.value);
    this.setState({ item });
  };

  render() {
    const { data } = this.props;
    return (
      <div className="g g-1/1">
        <div className="form__item">
          <div className="gw">
            <div className="g g-1/1">
              <label className="form__item__label">{l(this.props.label)}</label>
            </div>
            <div className="g g-1/1">
              <div data-qe-id="action-click-form_bkp_select">
                <Select
                  value={this.state.item}
                  onChange={this.update}
                  options={data.map((v: Object): Object => ({
                    value: v.id,
                    label: v.name,
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BKPSelector;
