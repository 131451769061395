/** @format */

// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Card from 'components/Card';
import l from 'helpers/locale';

import OccasionSelect from './components/OccasionSelect';
import ProjectedEndDateSelect from './components/ProjectedEndDateSelect';

type PropsShape = {
  data: Object,
  isEditing?: boolean,
  updateField: Function,
  error_occasion?: boolean,
  error_target_date?: boolean,
};

const GeneralPanel = ({
  data,
  isEditing,
  updateField,
  error_occasion,
  error_target_date,
}: PropsShape) => {
  const classSuffix = isEditing ? '' : 'form-and-view--view';
  return (
    <Card title="GENERAL-singular" isOpen canCollapse={false}>
      <div className="gw">
        <div className="g g-1/1">
          {!data.isCreating ? (
            <Fragment>
              <label className="form__label--small" htmlFor="__">
                {l('PROJECT-status')}
              </label>
              <div className="push--small--bottom">
                <p className="text--dk--flushed">{data.displayStatus}</p>
              </div>
            </Fragment>
          ) : null}
          <div className="push--small--bottom">
            <OccasionSelect
              isEditing={isEditing}
              classSuffix={classSuffix}
              onUpdate={updateField}
              occasions={data.occasions}
              defaultValue={data.occasion}
              currentValue={data.occasion}
              error={error_occasion}
            />
          </div>
          <div className="push--small--bottom">
            <ProjectedEndDateSelect
              isEditing={isEditing}
              classSuffix={classSuffix}
              onUpdate={updateField}
              defaultValue={data.target_date}
              currentValue={data.target_date}
              error={error_target_date}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

GeneralPanel.defaultProps = {
  isEditing: false,
  error_occasion: false,
  error_target_date: false,
};

const mapState = ({ projectStore }) => ({
  error_occasion: !!projectStore.form.errors.occasion,
  error_target_date: !!projectStore.form.errors.target_date,
});

export default connect(mapState)(GeneralPanel);
