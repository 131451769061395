import React from 'react';
import Card from 'components/Card';
import l from 'helpers/locale';

type PropsShape = {
  contacts: Array<string[]>,
};

const allowedUserFields = ['full_name', 'email', 'telephone'];

const ContactsCard = ({ contacts }: PropsShape): React$Element<*> => (
  <div>
    <Card title="JOB-contacts-title" isOpen>
      <div className="gw">
        {contacts.map((item: string[], k: number): React$Element<*> => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="g g-1/4" key={k}>
            <div className="push--bottom">
              <label className="form__label--small" htmlFor="__">
                {`${l('CONTRACTOR_CONTACT-label')} ${k + 1}`}
              </label>
              {allowedUserFields.map((v: string, vk: number): React$Element => (
                <div key={vk}>
                  <span className="text--dk--flushed display--block">
                    {item[v]}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Card>
  </div>
);

export default ContactsCard;
