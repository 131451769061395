import React, { Fragment } from 'react';
import l from 'helpers/locale';
import AssetHousePropertyView from 'workflows/jobAssign/views/assetHousePropertyView';
import CloseButton from 'components/AsideSlide/CloseButton';
import { AsideSlide } from '@bonlineza/b-lib';
import Panel from './objectPanel.js';

type KeysList = any; // @TODO

type PropsShape = {
  onChange?: Function | boolean,
  updateField: KeysList => (string | number) => any,
  data: Object,
};

type StateShape = {
  isOpenEditObject: boolean,
  nextTarget: '',
  nextTargetId: '',
};

class ObjectPanelWrapper extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    onChange: false,
  };

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      isOpenEditObject: false,
    };
  }

  state: StateShape;

  openEditObject = (e: Object): any => {
    e.preventDefault();
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      isOpenEditObject: true,
    }));
  };

  toggleEditObject = (e: Object): any => {
    e.preventDefault();

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      isOpenEditObject: !prevState.isOpenEditObject,
    }));
  };

  updateObjectAction = (id: string, name: string): Function => {
    this.props.updateField({ key: name, value: id });
    if (this.props.onChange) {
      this.props.onChange({
        target: name,
        targetId: id,
      });
    }

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      nextTarget: name,
      nextTargetId: id,
      isOpenEditObject: false,
    }));
  };

  render(): React$Element<*> {
    return (
      <Fragment>
        <Panel
          isEditing={this.props.isEditing}
          data={this.props.data}
          updateField={this.props.updateField}
          openEdit={this.openEditObject}
        />

        <AsideSlide
          isOpen={this.state.isOpenEditObject}
          title={l('JOB-edit-object')}
          toggle={this.toggleEditObject}
          toggleButton={() => <CloseButton onClick={this.toggleEditObject} />}>
          {this.state.isOpenEditObject ? (
            <AssetHousePropertyView nextAct={this.updateObjectAction} />
          ) : null}
        </AsideSlide>
      </Fragment>
    );
  }
}

ObjectPanelWrapper.defaultProps = {
  isEditing: false,
};

export default ObjectPanelWrapper;
