/** @format */

import React from 'react';
import { connect } from 'react-redux';
import AllianzSelect from 'components/AllianzSelect';

import Card from 'components/Card';
import l from 'helpers/locale';
import LANG_OPTIONS from 'constants/lang';

import { setLocale } from '../../../actions/app';

const TitleCard = ({ callback, setLang, locale }) => {
  function changeAppLanguage({ value }) {
    if (!value || value === '') return;
    callback();
    setLang(value);
  }

  function languageSelector() {
    return (
      <div className="card__header__aside--wide">
        <AllianzSelect
          hideResetButton
          onValueChange={changeAppLanguage}
          defaultValue={
            LANG_OPTIONS[
              LANG_OPTIONS.findIndex(v => v.value === locale) > 0
                ? LANG_OPTIONS.findIndex(v => v.value === locale)
                : 0
            ]
          }
          options={LANG_OPTIONS}
        />
      </div>
    );
  }
  return (
    <Card
      title="INVITATIONS-title"
      isOpen
      canCollapse={false}
      asideComponent={languageSelector}>
      <div>
        <p className="flush--bottom">{l('INVITATIONS-intro_text')}</p>
      </div>
    </Card>
  );
};

const mapState = state => ({
  locale: state.appStore.locale,
});

const mapDispatch = dispatch => ({
  setLang(lang) {
    return dispatch(setLocale(lang));
  },
});

export default connect(mapState, mapDispatch)(TitleCard);
