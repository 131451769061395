import React, { useState, Fragment } from 'react';
import NotificationContainer from 'components/NotificationContainer';
import type { NotificationShape } from 'components/NotificationContainer';
import { AsideSlide } from '@bonlineza/b-lib';
import l from 'helpers/locale';
import CloseButton from 'components/AsideSlide/CloseButton';
import ButtonGroup from 'components/ButtonGroup';
import FooterBar from 'components/FooterBar';
import NotificationsCard from './asideNotifications';

type PropsShape = {
  asideTitle: string,
  asideRequest: Function,
  notifications?: Array<NotificationShape>,
  total?: number,
  titlePluralKey?: string,
  asideCardTitle: string,
};

export const localiseTitle = original =>
  l('EVENTS-aside').replace(':context', original);

const GenericNotifications = ({
  asideTitle,
  asideCardTitle,
  titlePluralKey,
  notifications = [],
  total = 0,
  asideRequest,
  containerComponent,
}: PropsShape): React$Element<*> => {
  const [asideOpen, setAsideOpen] = useState(false);

  const openAside = () => {
    setAsideOpen(true);
  };

  const closeAside = () => {
    setAsideOpen(false);
  };

  const getShowMoreButton = () => {
    if (total > 5) {
      return (
        <Fragment>
          &nbsp;|&nbsp;
          <button
            type="button"
            className="btn--text--base"
            onClick={() => openAside()}>
            {l('NOTIFICATIONS-show_more')}
          </button>
        </Fragment>
      );
    }
    return null;
  };

  const ContainerComponent = containerComponent;

  return (
    <Fragment>
      <ContainerComponent notifications={notifications} />
      <div className="card__body__footer">
        <div className="fl-right">
          <span className="fl-right__item">
            {total}&nbsp;{l(titlePluralKey)}
            {getShowMoreButton()}
          </span>
        </div>
      </div>
      <AsideSlide
        title={asideTitle}
        toggle={closeAside}
        toggleButton={() => <CloseButton onClick={closeAside} />}
        isOpen={asideOpen}
        bgcAlt>
        <NotificationsCard
          containerComponent={ContainerComponent}
          request={asideRequest}
          cardTitle={asideCardTitle}
        />
        <FooterBar>
          <div className="fl-right">
            <div className="fl-right__item">
              <ButtonGroup alt numButtons={3}>
                <button
                  data-meta={{
                    type: 'text',
                  }}
                  type="button"
                  className="btn--text"
                  onClick={closeAside}>
                  {l('JOB-ACTIONS-BACK')}
                </button>
              </ButtonGroup>
            </div>
          </div>
        </FooterBar>
      </AsideSlide>
    </Fragment>
  );
};

GenericNotifications.defaultProps = {
  notifications: [],
  total: 0,
  containerComponent: NotificationContainer,
  titlePluralKey: 'EVENTS-title',
};

export default GenericNotifications;
