// @flow
import React from 'react';

import l from 'helpers/locale';

import type { ContactType } from '../types/ContactType';

import Popup from './Popup';

type Props = {
  contact: ContactType,
  children: any,
  type: string,
};

function ContactFooter(props: Props) {
  const [popupVisibility, setPopupVisibility] = React.useState(false);

  const [transitions, setTransitions] = React.useState([]);
  React.useEffect(() => {
    const transitionProperties = {
      change: {
        label: 'CONTACT_LIST-click_to_change',
        button: {
          type: 'button',
          className: 'btn--text--primary',
          onClick: () => setPopupVisibility(true),
          'data-qe-id': `action-contact_item-${props.type}-change`,
        },
      },
    };

    if (props.contact.contact_id && props.contact.contact_id !== null) {
      // CODE SMELL - if the contact_id is implicitly not 'null' allow the showing of the 'remove' button
      // this implies a refactor of the Contacts StateMachine(s)
      transitionProperties.remove = {
        label: 'CONTACT_LIST-click_to_remove',
        button: {
          type: 'button',
          className: 'btn--text--primary',
          onClick: () => props.update(null),
          'data-qe-id': `action-contact_item-${props.type}-remove`,
        },
      };
    }

    const availableTransitions = Object.keys(transitionProperties)
      .filter(name =>
        props.contact.state_machine.available_transitions.some(
          stateTransition => stateTransition.name === name,
        ),
      )
      .reduce(
        (accumulated, current) => [
          ...accumulated,
          transitionProperties[current],
        ],
        [],
      );

    setTransitions(availableTransitions);
  }, [
    props,
    props.contact.state_machine.available_transitions,
    props.type,
    setTransitions,
  ]);

  return (
    <div className="soft--small--bottom pos-relative ">
      <span className="pos-relative">
        {transitions.map((transition, key) => (
          <span key={key} className="soft--smaller--right">
            <button type="button" {...transition.button}>
              {l(transition.label)}
            </button>
          </span>
        ))}
        {popupVisibility ? (
          <Popup setVisibility={setPopupVisibility} type={props.type}>
            {React.cloneElement(props.children, {
              setPopup: setPopupVisibility,
            })}
          </Popup>
        ) : null}
      </span>
    </div>
  );
}

export default ContactFooter;
