/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import Card from 'components/Card';
import l from 'helpers/locale';
import DescriptionEditor from './descriptionEditor.js';

type PropsShape = {
  data: Object,
  isEditing?: boolean,
  storeTargetLabel: 'create' | 'exists',
};

const DescriptionPanel = ({
  data,
  isEditing,
  storeTargetLabel,
  updateField,
  error_summary,
  error_title,
}: PropsShape): React$Element<*> => (
  <Card title="PROJECT_DESCRIPTION-singular" isOpen canCollapse={false}>
    <div className="gw">
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <div
            className={`form-and-view-container ${
              isEditing ? '' : 'form-and-view--view'
            }
            ${error_title ? 'form-and-view-container--error' : ''}
          `}>
            <label className="form__label--small" htmlFor="__">
              {l('PROJECT_DESCRIPTION-title')}
            </label>
            {isEditing ? (
              <input
                data-qe-id="input-edit-title"
                className="form__input"
                type="text"
                name="title"
                value={data.title}
                maxLength="80"
                onChange={(e: Object): any =>
                  updateField({
                    key: 'title',
                    value: e.target.value,
                  })
                }
              />
            ) : (
              <p className="text--dk--flushed">{l(data.title)}</p>
            )}
          </div>
        </div>
      </div>
      <div className="g g-1/1">
        <div
          className={`form-and-view-container ${
            isEditing ? '' : 'form-and-view--view'
          }
            ${error_summary ? 'form-and-view-container--error' : ''}
          `}>
          <label className="form__label--small" htmlFor="__">
            {l('PROJECT_DESCRIPTION-summary')}
          </label>
          <DescriptionEditor
            updateField={updateField}
            isEditing={isEditing}
            storeTargetLabel={storeTargetLabel}
          />
        </div>
      </div>
    </div>
  </Card>
);

DescriptionPanel.defaultProps = {
  isEditing: false,
};

const mapState = ({ projectStore }) => ({
  error_summary: projectStore.form.errors.summary,
  error_title: projectStore.form.errors.title,
});

export default connect(mapState)(DescriptionPanel);
