import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'components/Card';
import SimpleList, {
  SimpleListItemArrayFormatter,
} from 'components/ConnectedSimpleList';
import l from 'helpers/locale';

type PropsShape = {
  apiEndpoint: string,
  enableDetailView: boolean,
};

type StateShape = {
  parentMatch: Object,
};

class PropertyCard extends React.Component {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      parentMatch: {
        path: props.match.path,
        url: props.match.url,
      },
    };
  }

  state: StateShape;

  openProperty = ({ id }): any => {
    this.props.history.push(`${this.state.parentMatch.url}/properties/${id}`);
  };

  render(): React$Element<*> {
    const extras = this.props.enableDetailView
      ? {
          clickAct: this.openProperty,
          bgcAlt: true,
        }
      : {};
    return (
      <div>
        <Card title="PROPERTIES-title" isOpen>
          <SimpleList
            {...extras}
            api={this.props.apiEndpoint}
            name="contractors_property_list"
            defaultSort={{ column: 'reference', sortType: 'asc' }}
            headings={[
              {
                name: 'reference',
                sortable: true,
                text: l('PROP-COL-reference'),
              },
              {
                name: 'status',
                sortable: true,
                text: l('PROP-COL-status'),
              },
              {
                name: 'address',
                sortable: true,
                text: l('PROP-COL-address'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'branch',
                sortable: true,
                text: l('PROP-COL-branch'),
              },
              {
                name: 'care_taker',
                sortable: false,
                text: l('PROP-COL-care_taker'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
            ]}
            enableSearch={false}
            allowClick={this.props.role !== 4}
          />
        </Card>
      </div>
    );
  }
}

const mapState = (state: Object): Object => ({
  role: state.authStore.user.role,
});

export default withRouter(connect(mapState)(PropertyCard));
