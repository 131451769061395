import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import l, { fC } from 'helpers/locale';
import Roles from 'constants/roles';
import SimpleList, {
  AllianzSimpleItem,
  SimpleListItemArrayFormatter,
  SimpleListItemRatingFormatter,
} from 'components/ConnectedSimpleList';
import Card from 'components/Card';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import GetSvg from 'components/GetSvg';

import { contractorsExporting } from './actions';
import ActionHeader from './components/ContractorListActionHeader';

const { RoleIds } = Roles;

type ReduxActionsShape = {
  exporting: Function,
};

type PropsShape = {
  overrideOnClick?: boolean,
  apiUrl?: string,
  useOnClick?: Function,
  history: Object,
};

type StateShape = {
  activeContractor: string,
  contractorTitle: string,
  contractorVisibility: boolean,
  request: {
    success: boolean,
    failed: boolean,
    fetching: boolean,
    message: string,
  },
};

class Contractors extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    useOnClick: (): boolean => true,
    overrideOnClick: false,
    showHeader: true,
    apiUrl: '/api/1/view/contractors',
  };

  getFilterOpts = () => {
    const sharedFilters = [
      {
        text: l('CONTRACTOR-reccomended'),
        value: 'recommended:true',
      },
      {
        text: l('CONTRACTORS_OVERVIEW_FILTER-high_revenue'),
        value: 'revenue:>25000',
      },
    ];
    switch (this.props.userRole) {
      case RoleIds.AGENCY_MANAGER:
        return [...sharedFilters];
      case RoleIds.PROPERTY_MANAGER:
        return [
          ...sharedFilters,
          {
            text: l('CONTRACTORS_OVERVIEW_FILTER-invited'),
            value: 'invited:true login:false',
          },
          {
            text: l('CONTRACTORS_OVERVIEW_FILTER-top_rating'),
            value: 'rating:5',
          },
          {
            text: l('CONTRACTORS_OVERVIEW_FILTER-with_login'),
            value: 'login:true',
          },
        ];
      default:
        return [
          ...sharedFilters,
          {
            text: l('CONTRACTORS_OVERVIEW_FILTER-invited'),
            value: 'invited:true login:false',
          },
          {
            text: l('CONTRACTOR-unassigned_customer'),
            value: 'customer:unassigned login:true',
          },
          {
            text: l('CONTRACTORS_OVERVIEW_FILTER-top_rating'),
            value: 'rating:5',
          },
          {
            text: l('CONTRACTORS_OVERVIEW_FILTER-with_login'),
            value: 'login:true',
          },
          {
            text: l('PREDEFINED_SEARCH-CONTRACTOR-compliance_missing'),
            value: 'compliance_status:unchecked,refresh_needed',
          },
          {
            text: l('PREDEFINED_SEARCH-CONTRACTOR-compliance_initiated'),
            value: 'compliance_status:initiated',
          },
          {
            text: l('PREDEFINED_SEARCH-CONTRACTOR-compliance_submitted'),
            value: 'compliance_status:submitted',
          },
          {
            text: l('PREDEFINED_SEARCH-CONTRACTOR-compliance_test_needed'),
            value: 'compliance_status:test_needed',
          },
        ];
    }
  };

  openContractor = (contractor: Object): any => {
    if (this.props.overrideOnClick) {
      return this.props.useOnClick(contractor.id);
    }
    return this.props.history.push(`/contractors/${contractor.id}`);
  };

  export = () => {
    this.props.exporting();
  };

  updateList = () => {
    this.props.updateList();
  };

  headings = [
    {
      name: 'name',
      sortable: true,
      text: l('CONT-COL-name'),
      flex: '18%',
      customFormatter: SimpleListItemArrayFormatter(),
    },
    {
      name: 'details',
      sortable: true,
      text: l('CONT-COL-address'),
      flex: '27%',
      customFormatter: SimpleListItemArrayFormatter(),
    },
    {
      name: 'rating',
      sortable: true,
      text: l('CONT-COL-rating'),
      flex: '15%',
      customFormatter: SimpleListItemRatingFormatter,
    },
    {
      name: 'display_status',
      sortable: false,
      text: l('CONT-COL-status'),
      flex: '15%',
      customFormatter: SimpleListItemArrayFormatter(true),
    },
    {
      name: 'earnings',
      sortable: false,
      text: l('CONT-COL-earnings'),
      flex: '15%',
      customFormatter: fC,
      align: 'right',
    },
    {
      name: '',
      sortable: false,
      text: '',
      flex: '5%',
    },
  ];

  render(): React$Element<*> {
    return (
      <Fragment>
        <ActionBar name="contractors_list" />
        <Card
          showHeader={this.props.showHeader}
          title="CONTRACTOR-title-plural"
          watermarkName="contractor"
          canCollapse={false}
          asideComponent={ActionHeader(
            () => this.props.history.push('/contractors/nomination'),
            this.export,
            this.props.userRole,
          )}>
          <SimpleList
            api={this.props.apiUrl}
            name="contractor_list"
            headings={this.headings}
            bgcAlt
            filterOpts={this.getFilterOpts()}>
            {data =>
              data.map((row, index) => (
                <div style={{ position: 'relative' }} key={index}>
                  <button
                    type="button"
                    className={`simple-list__body__row${
                      index % 2 ? '--bgc-alt' : ''
                    }`}
                    onClick={this.openContractor.bind(null, row)}
                    data-qe-id={`contractor_list-table-${index}`}>
                    {this.headings.map((heading, key) => (
                      <AllianzSimpleItem
                        key={key}
                        itemClass="simple-list__body__row__item"
                        flex={`${heading.flex}`}
                        column={heading.name}
                        text={row[heading.name]}
                        customFormatter={heading.customFormatter || null}
                        align={heading.align}
                      />
                    ))}
                  </button>
                  {row.website ? (
                    <a
                      href={row.website}
                      className="contractor-list-ext-anchor"
                      target="_blank"
                      rel="noopener noreferrer">
                      <GetSvg svg="ext_link" />
                    </a>
                  ) : null}
                </div>
              ))
            }
          </SimpleList>
        </Card>
      </Fragment>
    );
  }
}

const mapState = (state): Object => ({
  userRole: state.authStore.user.role,
});

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  exporting(): any {
    return dispatch(contractorsExporting());
  },
});

export default withRouter(connect(mapState, mapActions)(Contractors));
