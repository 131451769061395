/** @format */

import generator from '../../../../constants/generator';

const JOB_ACTIONS_SUBMIT = 'JOB_ACTIONS_SUBMIT';
const JOB_ACTIONS_COPY = 'JOB_ACTIONS_COPY';
const JOB_ACTIONS_GRANT = 'JOB_ACTIONS_GRANT';
const JOB_ACTIONS_RESEND_GRANT = 'JOB_ACTIONS_RESEND_GRANT';
const JOB_ACTIONS_ERASE = 'JOB_ACTIONS_ERASE';
const JOB_ACTIONS_REQUEST_OFFERS = 'JOB_ACTIONS_REQUEST_OFFERS';
const JOB_ACTIONS_TO_CHANGE = 'JOB_ACTIONS_TO_CHANGE';
const JOB_ACTIONS_INVITE_CONTRACTOR = 'JOB_ACTIONS_INVITE_CONTRACTOR';
const JOB_ACTIONS_COMPLETE = 'JOB_ACTIONS_COMPLETE';
const JOB_ACTIONS_BACK = 'JOB_ACTIONS_BACK';
const JOB_ACTIONS_WITHDRAW_TENDER = 'JOB_ACTIONS_WITHDRAW_TENDER';
const JOB_ACTIONS_CREATE_OFFER = 'JOB_ACTIONS_CREATE_OFFER';
const JOB_ACTIONS_CREATE_OFFER_VARIANT = 'JOB_ACTIONS_CREATE_OFFER_VARIANT';
const JOB_ACTIONS_CONFIRM_RECEIPT = 'JOB_ACTIONS_CONFIRM_RECEIPT';
const JOB_ACTIONS_EXECUTE = 'JOB_ACTIONS_EXECUTE';
const JOB_ACTIONS_CANCEL = 'JOB_ACTIONS_CANCEL';
const JOB_ACTIONS_ACCEPT = 'JOB_ACTIONS_ACCEPT';
const JOB_ACTIONS_CANCEL_EDIT = 'JOB_ACTIONS_CANCEL_EDIT';
const JOB_ACTIONS_NO_INTEREST = 'JOB_ACTIONS_NO_INTEREST';
const JOB_ACTIONS_ADD_SINGLE_CONTRACTOR = 'JOB_ACTIONS_ADD_SINGLE_CONTRACTOR';

export default {
  submit: generator(JOB_ACTIONS_SUBMIT),
  copy: generator(JOB_ACTIONS_COPY),
  grant: generator(JOB_ACTIONS_GRANT),
  resend_grant: generator(JOB_ACTIONS_RESEND_GRANT),
  erase: generator(JOB_ACTIONS_ERASE),
  requestOffers: generator(JOB_ACTIONS_REQUEST_OFFERS),
  change: generator(JOB_ACTIONS_TO_CHANGE),
  inviteContractor: generator(JOB_ACTIONS_INVITE_CONTRACTOR),
  complete: generator(JOB_ACTIONS_COMPLETE),
  withdrawTender: generator(JOB_ACTIONS_WITHDRAW_TENDER),
  createOffer: generator(JOB_ACTIONS_CREATE_OFFER),
  createVariant: generator(JOB_ACTIONS_CREATE_OFFER_VARIANT),
  confirmReceipt: generator(JOB_ACTIONS_CONFIRM_RECEIPT),
  execute: generator(JOB_ACTIONS_EXECUTE),
  cancel: generator(JOB_ACTIONS_CANCEL),
  accept: generator(JOB_ACTIONS_ACCEPT),
  back: JOB_ACTIONS_BACK,
  cancelEdit: JOB_ACTIONS_CANCEL_EDIT,
  noInterest: generator(JOB_ACTIONS_NO_INTEREST),
  addSingleContractor: generator(JOB_ACTIONS_ADD_SINGLE_CONTRACTOR),
};

/*
 *
 *  Copy
 *    User Role   |   Job State
 *     1 -> 3     |    1 -> 7
 *
 *  Grant
 *    User Role   |   Job State
 *     1 -> 3     |      0
 *
 *  Abort
 *    User Role   |   Job State
 *     1 -> 3     |    0,5,6
 *
 *  Erase
 *    User Role   |   Job State
 *     1 -> 3     |      1
 *
 *  To Change
 *    User Role   |   Job State
 *     1 -> 3     |      1
 *
 *  Invite Tenders
 *    User Role   |   Job State
 *     1 -> 3     |      1
 *
 *  Rate
 *    User Role   |   Job State
 *     1 -> 3     |     5->7
 *
 *  Back
 *    User Role   |   Job State
 *     1 -> 3,5   |     1->7
 *
 *  Withdraw Tender
 *    User Role   |   Offer State
 *        5       |     1->5
 *
 *  Create Offer
 *    User Role   |   Job State   |    Offer State
 *        5       |     1->3      |  Request Opened
 *
 *  Create Variant Offer
 *    User Role   |   Job State   |    Offer State
 *        5       |     1->3      |  Variant Available
 *
 *  Confirm Receipt
 *    User Role   |   Job State   |    Offer State
 *        5       |       5       |  Variant Accepted
 *
 *  Executed
 *    User Role   |   Job State   |    Offer State
 *        5       |       6       |  Variant Accepted
 *
 */
