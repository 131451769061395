import axios from 'helpers/axios.js';
import SharedJobConstants from 'shared/jobs/Panels/constants';
import { getEditorContent } from 'components/WYSIWYG';
import Constants from '../constants';
import { getConcern, getContractor } from '../query';

const {
  UPDATE_JOB_FORM,
  REMOVE_JOB_DOC,
  UPDATE_CONTACT,
  CREATE_CONTACT,
  UPDATE_JOB_CONCERN,
  UPDATE_JOB_CONTRACTOR,
} = Constants;

export function resetJob() {
  return { type: SharedJobConstants.cancelEdit };
}

export function updateContact(contact_type: string, contact: any): Object {
  return { type: UPDATE_CONTACT, target: contact_type, payload: contact };
}

export function createContact(contact_type: string, contact: any): Object {
  return { type: CREATE_CONTACT, target: contact_type, payload: contact };
}

export function updateContractor({ contractor }: Object): any {
  return {
    type: 'JOB_UPDATE_CONTRACTOR',
    payload: { contractor },
  };
}

export function updateJobForm({
  key,
  value,
}: {
  key: string,
  value: string,
}): any {
  return { type: UPDATE_JOB_FORM, payload: { key, value } };
}

export function removeJobDocument(
  id: string | number,
  target: 'exchange' | 'internal' | 'tender' | 'project_team',
  jobId: string | number,
  dispatch: Function,
  api: string,
): any {
  return id !== -1
    ? axios
        .delete(api)
        .then((): any =>
          dispatch({
            type: REMOVE_JOB_DOC,
            payload: {
              target,
              id,
            },
          }),
        )
        .catch((): any => dispatch({ type: 'ERROR' }))
    : false;
}

export function flushState(): any {
  return { type: 'FLUSH_SINGLE_JOB_STATE' };
}

function mutateFormForSillyRequirements(form) {
  // backend expects things in certain places
  // hopefully this is a constant reminder that this is really unnecessary
  // and shouldn't exist... but does
  return {
    ...form,
    contractor_id: form.vmp_contractor?.contractor_id,
    concern_type: form.concern.concern_type,
    concern_id: form.concern.concern_id,
    agreement_conditions: 0,
  };
}

function getDocumentsFromStore(job) {
  const uploadedDocuments = job.documents.filter(doc => doc.document_id);

  return uploadedDocuments.reduce(
    (accumulated, current) => ({
      ...accumulated,
      [`${current.source_tag}_documents`]: [
        ...(accumulated[`${current.source_tag}_documents`]
          ? accumulated[`${current.source_tag}_documents`]
          : []),
        current.document_id,
      ],
    }),
    {},
  );
}

type CreateJobTypes =
  | 'create_orphan'
  | 'create_project_direct'
  | 'create_project_offer';

export function createJobAction(
  type: CreateJobTypes,
  onSuccess: Function,
  onFailed: Function,
): any {
  return (dispatch, getStore) => {
    dispatch({ type: SharedJobConstants.submit.fetching });
    const {
      jobStore: { form, data },
    } = getStore();
    // @todo - use shared function only available in `master`
    const info_summary = getEditorContent('#js-redactor-editor');
    const transitionIndex = data.job.state_machine.available_transitions.findIndex(
      transition => transition.name === type,
    );
    const { action } = data.job.state_machine.available_transitions[
      transitionIndex
    ];
    const documents = getDocumentsFromStore(data.job);

    return axios
      .post(action, {
        ...mutateFormForSillyRequirements(form),
        ...documents,
        info_summary,
      })
      .then(res => {
        dispatch({ type: SharedJobConstants.submit.success });
        onSuccess(res.data.payload.id);
      })
      .catch(err => {
        dispatch({ type: SharedJobConstants.submit.failed, payload: err });
        onFailed(err);
      });
  };
}

export function updateJobConcern(type: 'property' | 'asset' | 'house', id) {
  return dispatch => {
    getConcern(type, id).then(payload => {
      dispatch({ type: UPDATE_JOB_CONCERN, payload });
    });
  };
}

export function updateJobContractor(id) {
  return dispatch => {
    getContractor(id).then(payload => {
      dispatch({ type: UPDATE_JOB_CONTRACTOR, payload });
    });
  };
}
