import React from 'react';
import { matchPath } from 'react-router-dom';
import history from 'helpers/history';
import OfferSwitcher from 'views/singleJob/components/OfferSwitcher';

import pathWithoutHash from 'components/RouteSlider/functions/pathWithoutHash';

import TargetSelection from '../targetSelection';
import { Names } from './constants';
import {
  JobCreate,
  JobView,
  OfferView,
  ContractorSelection,
  GrantRequest,
} from './containers';

import { ContractorView } from '../contractors/containers';

/*
 * This function will return the route structure of the children of a job.
 * It requires a basePath param which will be used as the "base" of all path matching.
 * This parameter may be a function if you need to calculate where you are dyanmically,
 * for example: when the url is dynamic but you can navigate to a single job as a child of this
 * dyanmic route.
 *
 * @param basePath string|Function
 * @return Object
 */
function backToJobBuilder(basePath) {
  return () => {
    // basePath can be a function, so if it is we need to invoke it
    // the caller will need to bind all necessary parameters before this point.
    const base = typeof basePath === 'function' ? basePath() : basePath;
    // remove jobs path as this becomes duplicated when matching and pushing for that base only
    const adjustedBase = base === '/jobs' ? '' : base;
    const singleOfferPath = `${adjustedBase}/jobs/:job_id(\\d+)/offers/:offer_id`;
    const createOfferPath = `${adjustedBase}/jobs/:job_id(\\d+)/offer`;
    const grantRequestPath = `${adjustedBase}/jobs/:job_id(\\d+)/grant-request`;
    const contractorPath = `${adjustedBase}/jobs/:job_id(\\d+)/contractor/:contractor_id(\\d+)`;
    // match for single offer
    const match =
      matchPath(pathWithoutHash(), {
        path: singleOfferPath,
        exact: true,
      }) ||
      matchPath(pathWithoutHash(), {
        path: createOfferPath,
        exact: true,
      }) ||
      matchPath(pathWithoutHash(), {
        path: grantRequestPath,
        exact: true,
      }) ||
      matchPath(pathWithoutHash(), {
        path: contractorPath,
        exact: true,
      });

    if (match && match.params) {
      history.push(`${adjustedBase}/jobs/${match.params.job_id}`);
    } else {
      // eslint-disable-next-line
      console.log('cannot find job_id in path');
    }
  };
}

export function create(parentPath = '/jobs') {
  // job-assign
  // job-assign/:type/:type_id
  // job-assign/:type/:type_id/contractor
  // job-assign/:type/:type_id/contractor/:contractor_id
  return {
    [Names.JOB_ASSIGN]: {
      position: 1,
      parentPath,
      qeId: 'job-assign',
      title: 'PM_DASHBOARD-object_search',
      render: () => <TargetSelection nextPath="contractor" />,
      children: {
        [Names.JOB_ASSIGN_CONTRACTOR]: {
          position: 2,
          parentPath: `${parentPath}/job-assign/:type/:type_id`,
          qeId: 'job-assign-contractor',
          title: 'CONTRACTOR-title-plural',
          render: () => <ContractorSelection />,
          children: {
            [Names.JOB_ASSIGN_CONTRACTOR_ID]: {
              position: 3,
              parentPath: `${parentPath}/job-assign/:type/:type_id/contractor`,
              qeId: 'job-assign-contractor-id',
              title: 'JOB-details',
              render: () => (
                <JobCreate routeName={Names.JOB_ASSIGN_CONTRACTOR_ID} />
              ),
            },
          },
        },
      },
    },

    // job-create
    // job-create/:type/:type_id/job-offer
    [Names.JOB_CREATE]: {
      position: 1,
      parentPath,
      qeId: 'job-create',
      title: 'PM_DASHBOARD-object_search',
      render: () => <TargetSelection nextPath="job-offer" />,
      children: {
        [Names.JOB_CREATE_OFFER]: {
          position: 2,
          parentPath: `${parentPath}/job-create/:type/:type_id`,
          qeId: 'job-create-offer',
          title: 'JOB-details',
          render: () => <JobCreate routeName={Names.JOB_CREATE_OFFER} />,
        },
      },
    },
  };
}

export function getJobChildren(basePath, parentPath) {
  const backToJob = backToJobBuilder(basePath);
  return {
    [Names.JOB_VIEW_OFFER_CREATE]: {
      position: 2,
      title: 'OFFER_FORM-panel_title',
      backAction: backToJob,
      qeId: 'create-offer',
      render: () => <OfferView closeForm={backToJob} />, // code smell - back action is also beiong passed in here... should use slider context
      parentPath,
    },
    // jobs/:job_id/offer/:offer_id
    [Names.JOB_VIEW_OFFER_VIEW]: {
      position: 2,
      title: '',
      backAction: backToJob,
      qeId: 'view-offer',
      actionComponent: <OfferSwitcher />,
      render: () => <OfferView closeForm={backToJob} />,
      parentPath,
    },
    // jobs/:job_id/grant-request
    [Names.JOB_VIEW_GRANT_REQUEST]: {
      position: 2,
      title: 'GRANT-REQUEST-title',
      backAction: backToJob,
      qeId: 'grant-request',
      render: () => <GrantRequest />,
      parentPath,
    },
    // jobs/:job_id/contractor/:contractor_id
    [Names.JOB_VIEW_CONTRACTOR]: {
      position: 2,
      title: '',
      backAction: backToJob,
      qeId: 'view-contractor',
      render: () => <ContractorView />,
      parentPath,
    },
  };
}

export function view(parentPath = '/jobs') {
  return {
    // jobs/:job_id
    [Names.JOB_VIEW]: {
      position: 1,
      title: '',
      render: () => <JobView />,
      backAction: () => history.push('/jobs'),
      qeId: 'job',
      children: getJobChildren(parentPath, `${parentPath}/${Names.JOB_VIEW}`),
      parentPath,
    },
  };
}
