import React from 'react';
import Card from 'components/Card';
import SimpleList from 'components/ConnectedSimpleList';
import l from 'helpers/locale';

const HousesCard = ({ id, data }) => (
  <div>
    <Card title="HOUSE-title-plural">
      <SimpleList
        api={`/api/1/view/properties/${id}/houses`}
        name="single_property_houses"
        headings={[
          {
            name: 'reference',
            sortable: false,
            text: l('HOUSE-COL-reference'),
          },
          {
            name: 'type',
            sortable: false,
            text: l('HOUSE-COL-type'),
          },
          {
            name: 'street',
            sortable: false,
            text: l('HOUSE-COL-street'),
          },
          {
            name: 'lift',
            sortable: false,
            text: l('HOUSE-COL-lift'),
          },
          {
            name: 'status',
            sortable: false,
            text: l('HOUSE-COL-status'),
          },
        ]}
        data={data}
        enableSearch={false}
        noPointer
      />
    </Card>
  </div>
);

export default HousesCard;
