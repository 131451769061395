/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import Login from './Login.comp.js';

import LanguageDropdown from '../LanguageDropdown';
import l, { getLocale } from '../../helpers/locale';

const HomeLogin = ({ next }) => (
  <div className="wrap">
    <div className="layout-container">
      <div className="layout-container__header">
        <div className="gw">
          <div className="g g-4/5" />
          <div className="g g-1/5">
            <div className="align--right">
              <LanguageDropdown />
            </div>
          </div>
        </div>
      </div>
      <h1>
        <span className="layout-container__header__lt-section">
          {l('HOME_LOGIN-title_1')}
        </span>
        {l('HOME_LOGIN-title_2')}
      </h1>
      <div className="layout-container__body">
        <div className="gw">
          <div className="g g-1/2">
            <div className="form-container-wrapper--flex">
              <Login forgotAct={next} />
            </div>
          </div>
          <div className="g g-1/2">
            <div className="layout-container__body__seperated">
              <div className="layout-container__body__section--flexed">
                <a
                  href={
                    'https://www.allianz.ch/de/' +
                    'service/standorte-und-berater/'
                  }
                  target="_blank"
                  rel="noopener noreferrer">
                  <span>
                    <label className="form__label--small" htmlFor="__">
                      {l('HOME_LOGIN-learn_more')}
                    </label>
                    <span className="layout-container__body__seperated__inner">
                      {l('HOME_LOGIN-about_text')}
                    </span>
                  </span>
                </a>
                <div className="align--right">
                  <a
                    href="mailto:info@vmp-allianz.ch"
                    target="_top"
                    className="btn--secondary"
                    data-qe-id="action-click-info">
                    {l('HOME_LOGIN-contact')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="layout-container__footer">
        <span className="layout-container__footer__item">
          <a href={`/#/${getLocale().toLowerCase()}/terms-and-conditions`}>
            {l('GENERAL-terms')}
          </a>
        </span>
        <span className="layout-container__footer__item">
          <a href={`/#/${getLocale().toLowerCase()}/impressum`}>
            {l('GENERAL-impressum')}
          </a>
        </span>
      </div>
    </div>
  </div>
);

HomeLogin.propTypes = {
  next: PropTypes.func,
};

HomeLogin.defaultProps = {
  next: () => true,
};

export default HomeLogin;
