import React from 'react';
import OfferForm from 'views/jobs/components/offerForm';
import { Names } from 'components/RouteSlider/routes/projects/constants';
import { connect } from 'react-redux';
import { updateTitle } from 'components/RouteSlider/actions';

const OfferViewRender = ({ setTitle, context }) => (
  <OfferForm
    closeForm={context.backAction}
    onViewData={data => setTitle(data.title)}
  />
);

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.PROJECT_JOB_OFFER, title));
  },
});

export const OfferView = connect(null, mapActions)(OfferViewRender);
