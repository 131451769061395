// @flow
import StateMachine from 'machines/reduxMachine.js';
import { transitions, States } from 'machines/constants';
import { State, Transition, Edge as EdgeBuilder } from 'async-state-machine';
import graphi from 'helpers/graphi';
import axios from 'helpers/axios';
import { requestSuccess } from 'functions/actionRequests.func';
import SingleJobConstants from 'views/singleJob/constants';
import { updateSimpleList } from 'components/ConnectedSimpleList/actions';
import Constants from 'shared/jobs/Panels/constants';
import { getEditorContent } from 'components/WYSIWYG';

import * as CardMachines from '../cards/machines';
import redux from '../../../store';
import { getJobQuery, getJobCreateQuery } from '../query';

const { change } = Constants;
const { getJob } = SingleJobConstants;

// Transitions for single job
const load_data = Object.create(Transition).setName(transitions.to_load);
const fail = Object.create(Transition).setName(transitions.to_fail);
const update = Object.create(Transition).setName(transitions.to_update);
const view = Object.create(Transition).setName(transitions.to_visible);
const recover = Object.create(Transition).setName(transitions.to_recover);
const edit = Object.create(Transition).setName(transitions.to_editing);
const update_error = Object.create(Transition).setName(
  transitions.to_update_error,
);

async function loadData(params) {
  let data;
  let result;

  try {
    if (params.job_id) {
      data = await graphi(getJobQuery(params), 'getJob');
    } else {
      data = await graphi(getJobCreateQuery(params), 'getJobCreate');
    }
    result = {
      response: data,
      error: false,
    };
  } catch (error) {
    result = {
      response: error,
      error: true,
    };
  }

  return result;
}

async function updateJob(jobId) {
  let data;
  let result;
  try {
    const formData = redux.getState().jobStore.form;
    data = await axios.post(`/api/1/jobs/${jobId}/action/update`, {
      ...formData,
      info_summary:
        getEditorContent('#js-redactor-editor') || formData.info_summary,
    });
    result = {
      response: data,
      error: false,
    };
  } catch (error) {
    result = {
      response: error,
      error: true,
    };
  }

  return result;
}

// States for Single Job
const INIT = Object.create(State)
  .setName(States.INIT)
  .setOnEntry(function(params) {
    // start all children
    return Promise.all(
      Object.keys(CardMachines).map(key => CardMachines[key].start(params)),
    );
  })
  .setOnSuccess(function(params) {
    this.triggerTransition(load_data, { ...params });
  });

const LOADING = Object.create(State)
  .setName(States.LOADING)
  .setOnSuccess(async function(params) {
    try {
      this.data = await loadData(params);
      if (!this.data) {
        throw new Error(`No data found for Job: ${params}`);
      }
    } catch (err) {
      throw new Error(err);
    }

    if (this.data.error) {
      this.triggerTransition(fail, this.data.response);
      return this.data;
    }

    redux.dispatch(
      requestSuccess(getJob.success, this.data.response, {
        jobId: params.job_id,
      }),
    );

    const nextTransition = typeof params.job_id !== 'undefined' ? view : edit;

    this.triggerTransition(nextTransition, this.data.response);

    return this.data;
  });

const VIEW = Object.create(State)
  .setName(States.VIEW)
  .setOnSuccess(function() {
    const state = redux.getState();
    Object.keys(CardMachines).forEach(key => {
      CardMachines[key].triggerTransition(transitions.to_visible, {
        user: state.authStore.user,
        job: state.jobStore.data.job,
      });
    });
  });

const EDIT = Object.create(State)
  .setName(States.EDIT)
  .setOnSuccess(function() {
    const state = redux.getState();
    Object.keys(CardMachines).forEach(key => {
      CardMachines[key].triggerTransition(transitions.to_editing, {
        user: state.authStore.user,
        job: state.jobStore.data.job,
      });
    });
  });
const UPDATING = Object.create(State)
  .setName(States.UPDATING)
  .setOnEntry(async function(params: { job_id: number }) {
    redux.dispatch({ type: change.fetching, jobId: params.job_id });
    try {
      this.data = await updateJob(params.job_id);
      if (!this.data) {
        throw new Error(`Update failed on Job: ${params.job_id}`);
      }
    } catch (err) {
      throw new Error(err);
    }
    return this.data;
  })
  .setOnSuccess(function(params) {
    if (this.data.error) {
      this.triggerTransition(update_error, {
        job_id: params.job_id,
        payload: this.data.response,
      });
    } else {
      redux.dispatch({ type: change.success, jobId: params.job_id });
      redux.dispatch(updateSimpleList('jobs'));
      this.triggerTransition(load_data, { job_id: params.job_id });
    }
  });
const UPDATE_ERROR = Object.create(State)
  .setName(States.UPDATE_ERROR)
  .setOnEntry(function(params: { job_id: number, payload: Object }) {
    redux.dispatch({
      type: change.failed,
      jobId: params.job_id,
      payload: params.payload,
    });
  });

const ERROR = Object.create(State).setName(States.ERROR);

function generateJobStateMachine() {
  const Edge = new EdgeBuilder();

  // Register Machine
  const Machine = new StateMachine('job')
    .registerEdge(
      Edge.new()
        .transition(fail)
        .from([INIT, LOADING, EDIT, VIEW])
        .to(ERROR),
    )
    .registerEdge(
      Edge.new()
        .transition(recover)
        .from([ERROR])
        .to(INIT),
    )
    .registerEdge(
      Edge.new()
        .transition(load_data)
        .from([INIT, VIEW, UPDATING])
        .to(LOADING),
    )
    .registerEdge(
      Edge.new()
        .transition(view)
        .from([LOADING, UPDATING, EDIT, UPDATE_ERROR])
        .to(VIEW),
    )
    .registerEdge(
      Edge.new()
        .transition(edit)
        .from([LOADING, VIEW])
        .to(EDIT),
    )
    .registerEdge(
      Edge.new()
        .transition(update)
        .from([EDIT, UPDATE_ERROR])
        .to(UPDATING),
    )
    .registerEdge(
      Edge.new()
        .transition(update_error)
        .from([UPDATING])
        .to(UPDATE_ERROR),
    )
    .setInitialState(INIT, {}, true);

  return Machine;
}

export default generateJobStateMachine;
