/** @format */

import React from 'react';
import Panel from './projectTeamPanel.js';

const ProjectTeam = ({
  isEditing,
  updateField,
  storeTargetLabel,
  data,
  isCreating,
}) => (
  <Panel
    isEditing={isEditing}
    data={data}
    updateField={updateField}
    storeTargetLabel={storeTargetLabel}
    isCreating={isCreating} // only true on create
  />
);

ProjectTeam.defaultProps = {
  isCreating: false,
};

export default ProjectTeam;
