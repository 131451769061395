/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { StateConditional } from 'machines/components/RenderConditional';
import RatingPanel from 'shared/jobs/Panels/items/Rating';
import { PanelStates } from 'machines/constants';

import MachineBuilder from './machine';

type ReduxStateShape = {
  rating: number,
  status: string,
};

export const RatingCardMachine = MachineBuilder();
const RatingCard = ({ rating, status, machine_state }) => (
  <StateConditional state={machine_state} whenState={[PanelStates.VISIBLE]}>
    <RatingPanel
      data={{
        rating,
      }}
      jobState={status}
    />
  </StateConditional>
);

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.rating_card,
  rating: jobStore.data.job.rating || 0,
  status: jobStore.data.job.info_status,
});

export default connect(mapState)(RatingCard);
