import React from 'react';
import ActionBar from 'components/ConnectedActionBar';
import { SliderContext } from '../index';

const ActionBarWrapper = ({ name }) => (
  <SliderContext.Consumer>
    {sliderContext => (
      <ActionBar name={name} scrollToTop={sliderContext.scrollToTop} />
    )}
  </SliderContext.Consumer>
);

export default ActionBarWrapper;
