/** @format */

// @flow

export type RequestShape = {
  fetching: boolean,
  failed: boolean,
  success: boolean,
  message: string,
};

const request: RequestShape = {
  fetching: false,
  failed: false,
  success: false,
  message: '',
};

export { request };

export default request;
