/** @format */

// @flow

import React from 'react';
import Panel from './informationJobPanel.js';
import type { DataShape, FormDataShape } from './informationJobPanel.js';

type KeysList = any; // @TODO

type PropsShape = {
  editNow?: boolean,
  jobState: string,
  updateField: KeysList => (string | number) => any,
  data: DataShape,
  formData: ?FormDataShape | false,
  storeTargetLabel: 'create' | 'exists',
};

const InformationJobPanelWrapper = ({
  editNow,
  updateField,
  jobState,
  data,
  formData,
  isJobOffer,
  storeTargetLabel,
}: PropsShape): React$Element<*> => (
  <Panel
    isEditing={editNow}
    isJobOffer={isJobOffer}
    data={data}
    formData={formData}
    updateField={updateField}
    jobStatus={jobState}
    storeTargetLabel={storeTargetLabel}
  />
);
InformationJobPanelWrapper.defaultProps = {
  editNow: false,
  updateField: false,
};

export default InformationJobPanelWrapper;
