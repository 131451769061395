import React from 'react';
import { connect } from 'react-redux';
import InternalDocsPanel from 'shared/projects/panels/items/InternalDocuments';
import type { DocumentType } from 'views/singleJob/constants';
import DocConstants from 'components/connected/DocumentUploader/constants';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';

import { removeProjectDocument } from '../../actions';
import MachineBuilder from './machine';

const { DELETE, VIEW, DOWNLOAD } = DocConstants.DOCUMENT_ACTION;

type ReduxActionShape = {
  removeImage: Function,
};

type ReduxStateShape = {
  status: string,
  documentList: [DocumentType],
};

type PropsShape = {
  projectId: string | number | void,
} & ReduxStateShape &
  ReduxActionShape;

export const InternalDocsCardMachine = MachineBuilder();

const InternalDocsCard = ({
  status,
  projectId,
  removeImage,
  documentList,
  machineState,
}: PropsShape): React$Element<*> => (
  <StateConditional
    whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}
    state={machineState}>
    <InternalDocsPanel
      listName="project_internal"
      data={{
        uploadProps: {
          source_tag: 'internal',
          source_type: 'project',
          source_id: projectId,
          projectId,
        },
        endpoint: '/api/1/documents/action/add',
      }}
      projectStatus={status}
      list={documentList}
      controls={[
        {
          documentAction: DELETE,
          svgName: 'close',
          action: (id: string | number, api: string): any =>
            removeImage(id, api),
          canDisplay: true,
        },
        {
          documentAction: VIEW,
          svgName: 'view',
          action: (id: string | number): any => id,
          canDisplay: true,
        },
        {
          documentAction: DOWNLOAD,
          svgName: 'download',
          action: (id: string | number): any => id,
          canDisplay: true,
        },
      ]}
    />
  </StateConditional>
);

const mapState = (state: Object): ReduxStateShape => ({
  status: state.projectStore.data.status,
  documentList:
    state.projectStore.data.documents.filter(
      doc => doc.source_tag === 'internal',
    ) || [],

  isEditing: state.projectStore.isEditing,
  machineState: state.machineStore.project_internaldocs_card,
});

const mapActions = (
  dispatch: Function,
  { projectId }: { projectId: string | number },
): ReduxActionShape => ({
  removeImage(id: string | number, api: string): any {
    return removeProjectDocument(
      id,
      'internal',
      projectId,
      dispatch,
      false,
      api,
    );
  },
});

export default connect(mapState, mapActions)(InternalDocsCard);
