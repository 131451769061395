// @flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import { AsideSlide } from '@bonlineza/b-lib';
import CloseButton from 'components/AsideSlide/CloseButton';

import { SliderContext } from './index';
import { pathEndsWith, pathIncludes } from './functions/pathMatch';
import { Names as JobNames } from './routes/jobs/constants';
import { Names as ProjectNames } from './routes/projects/constants';
import { Names as ContractorNames } from './routes/contractors/constants';
import { Names as NominationNames } from './routes/nominations/constants';
import { Names as PropertiesNames } from './routes/properties/constants';

const Slider = ({
  name,
  storeTitle,
  history,
  match,
  actionComponent,
  children,
  backAction,
  qeId,
}: {
  name: string,
  storeTitle: string,
  children: any,
  history: any,
  match: any,
  backAction?: Function | null,
  qeId?: string,
}): any => {
  const bgcAltNames = [
    JobNames.JOB_VIEW,
    JobNames.JOB_ASSIGN_CONTRACTOR_ID,
    JobNames.JOB_CREATE_OFFER,
    ProjectNames.PROJECT_VIEW,
    ProjectNames.PROJECT_ASSIGN_JOB_CONTRACTOR,
    ProjectNames.PROJECT_JOB,
    ProjectNames.PROJECT_ADD_PAYMENT,
    ProjectNames.PROJECT_EDIT_PAYMENT,
    ContractorNames.CONTRACTOR_VIEW,
    ContractorNames.CONTRACTOR_PROPERTY,
    ContractorNames.CONTRACTOR_INVITE,
    ContractorNames.INVITE,
    ContractorNames.CONTRACTOR_PROFILE_VIEW,
    ContractorNames.ROOT_PROFILE_VIEW,
    NominationNames.NOMINATION_INVITE,
    NominationNames.NOMINATION_VIEW,
    PropertiesNames.PROPERTY_VIEW,
    PropertiesNames.PROPERTY_JOB,
  ];

  const toggleAction = backAction || history.goBack;

  // ISSUE: having duplicate route names (within the same primary route) will render both items - this is due to the match against path peices and not against the entire path (tree)
  const isOpen = pathIncludes(match.path, name);
  return (
    <SliderContext.Consumer>
      {sliderContext => (
        <AsideSlide
          title={l(storeTitle) || ''}
          isOpen={isOpen}
          toggle={toggleAction}
          toggleButton={() => <CloseButton onClick={toggleAction} />}
          actionComponent={actionComponent}
          renderEmpty={!pathEndsWith(match.path, name)}
          bgcAlt={bgcAltNames.includes(name)}
          innerId={`slider_${sliderContext.sliderIndex}`}
          qeId={qeId}>
          {React.cloneElement(children, { context: sliderContext })}
        </AsideSlide>
      )}
    </SliderContext.Consumer>
  );
};

Slider.defaultProps = {
  backAction: null,
  qeId: '',
  parentPath: undefined,
};

const mapState = ({ routeSlider }, { name }) => ({
  storeTitle: routeSlider[name].title,
});

export default withRouter(connect(mapState)(Slider));
