/** @format */

import assign from 'object.assign';
import { LOGIN_SWITCH_VIEW } from '../constants';

const initialState = {
  currentView: 'login', // can be login or forgot
};

export const loginReducer = (state = initialState, { type, nextView }) => {
  switch (type) {
    case LOGIN_SWITCH_VIEW:
      return assign({}, state, {
        currentView: nextView,
      });
    default:
      return state;
  }
};
