// returns the window.location.hash without the #
// this makes it super easy to use current path matching
// alongside matchPath from react-router-dom

export default function pathWithoutHash() {
  if (!window) return null;

  const { href, origin } = window.location;
  const path = href.substr(origin.length);

  let result = path;

  const badHash = '/#';

  // This hash code is added into the url by the Sensational "Let me in" extension
  const letMeInHash = '/?l#';

  let trimStringStart = 0;

  if (result.indexOf(badHash) === 0) {
    // bad hash exists!!!
    trimStringStart = badHash.length;
  } else if (result.indexOf(letMeInHash) === 0) {
    // let me in hash exists
    trimStringStart = letMeInHash.length;
  }

  result = result.substr(trimStringStart);

  return result;
}
