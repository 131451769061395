import React from 'react';
import formatString from 'functions/formatString';
import GetSvg from 'components/GetSvg';

export const SimpleListItemArrayFormatter = (spaced = false) => val => {
  return (
    <span className={spaced ? 'simple-list__body__row__item__row--spaced' : ''}>
      {val.map((j: string, jk: number): React$Element<*> => (
        <span key={jk}>{formatString(j)}</span>
      ))}
    </span>
  );
};

export const SimpleListItemRatingFormatter = val => {
  const { rating, count } = val;
  return (
    <span className="simple-list__body__row__item__row">
      {Array(5)
        .fill('')
        .map((_: string, k: number): React$Element<*> => (
          <GetSvg
            key={k}
            svg={`star${k + 1 <= parseInt(rating, 10) ? '_full' : '_empty'}`}
            wrapperClass={`${'star-btn--sml'}`}
          />
        ))}
      {count ? <div className="soft--smallest--sides">({count})</div> : null}
    </span>
  );
};
