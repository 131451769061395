import React, { useEffect, useState } from 'react';
import Card from 'components/Card';
import AllianzLoader from 'components/AllianzLoader';

const AsideNotifications = ({
  request,
  containerComponent,
  cardTitle,
}): React$Element<*> => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    request().then(list => {
      setNotifications(list);
      setLoading(false);
    });
  }, [setNotifications, request, setLoading]);

  const ContainerComponent = containerComponent;
  return (
    <Card title={cardTitle} canCollapse={false}>
      {loading ? <AllianzLoader /> : null}
      <ContainerComponent notifications={notifications} />
    </Card>
  );
};

AsideNotifications.defaultProps = {
  cardTitle: 'NOTIFICATIONS-title',
};

export default AsideNotifications;
