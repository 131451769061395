import React, { Fragment } from 'react';
import CloseButton from 'components/AsideSlide/CloseButton';
import { AsideSlide } from '@bonlineza/b-lib';
import SimpleList, {
  SimpleListItemArrayFormatter,
  SimpleListItemRatingFormatter,
} from 'components/ConnectedSimpleList';

import l, { fC } from 'helpers/locale';

import GetSvg from 'components/GetSvg';
import axios from 'helpers/axios';
import { getRoleIdFromShortName } from 'constants/roles';
import graphi from 'helpers/graphi';

import orderObjectByKeys from 'functions/orderObjectByKeys';

type RequestShape = {
  fetching: boolean,
  success: boolean,
  failed: boolean,
};

type StateShape = {
  loading: boolean,
  isOpen: boolean,
  request: RequestShape,
  data: Object,
  selected: {
    isSelected: boolean,
    data: Object,
  },
  hasError: boolean,
};

type PropsShape = {
  addRef: Function, // required from form component
  data?: Array<*> | Object,
  roleName: string | number,
  viewOnly: boolean,
  prefillData?: string[],
  hasError?: boolean,
  label?: string,
  mandatory?: boolean,
};

const initialRequest = {
  fetching: false,
  success: false,
  failed: false,
};

class ReferenceSlide extends React.Component<void, PropsShape> {
  static defaultProps = {
    prefillData: [],
    hasError: false,
    label: '',
    mandatory: false,
  };

  constructor(props: PropsShape) {
    super(props);

    this.state = {
      loading: false,
      isOpen: false,
      data: {},
      hasError: props.hasError,
      request: initialRequest,
      initialData: props.prefillData,
      selected: {
        isSelected: props.prefillData.length > 0,
        data: props.prefillData || [],
      },
    };

    if (
      props.data &&
      props.roleName === 'hw' &&
      Object.prototype.hasOwnProperty.call(props.data, 'reference') &&
      props.data.reference !== null &&
      Object.prototype.hasOwnProperty.call(
        props.data.reference,
        'contractor_profile_id',
      )
    ) {
      props.addRef('reference_id', props.data.reference.contractor_profile_id);
    } else if (
      props.data &&
      Object.prototype.hasOwnProperty.call(props.data, 'reference') &&
      ['pm', 'cm', 'hcm'].includes(props.roleName) &&
      props.data.reference instanceof Object &&
      Object.prototype.hasOwnProperty.call(props.data.reference, 'id')
    ) {
      props.addRef('reference_id', props.data.reference.id);
    }

    this.apiUrl = `api/1/view/users/references/${getRoleIdFromShortName(
      props.roleName,
    )}`;
  }

  state: StateShape;

  componentDidUpdate(prevProps) {
    if (prevProps.viewOnly !== this.props.viewOnly) {
      // we have switched to either edit or view mode
      this.resetStateAfterViewToggle();
    }
  }

  resetStateAfterViewToggle() {
    // only run the below code when switching from view to edit
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      hasError: this.props.hasError,
      selected: {
        isSelected:
          prevState.initialData.length > 0 ||
          prevState.selected.data.length > 0,
        data:
          (prevState.initialData.length && prevState.initialData) ||
          prevState.selected.data,
      },
    }));
  }

  getReferenceTitle = (): Object => {
    switch (this.props.roleName) {
      case 'pm':
        return l('PM-reference');
      case 'hw':
        return l('HW-reference');
      case 'cm':
        return l('CM-reference');
      case 'hcm':
        return l('HCM-reference');
      default:
        return '';
    }
  };

  getVmp = async id => {
    const body = `
        {
          vmp_contractor(contractor_id: ${id}) {
            billing_company
            street
            postal_code
            city
            billing_email
            billing_telephone
          }
        }
      `;
    const {
      data: { data },
    } = await graphi(body, 'getReferenceContractor');

    /**
     * We need to transform the object into an array so there our render
     * can loop over it to display the information
     *
     */
    const order = [
      'billing_company',
      'street',
      'postal_code.city',
      'billing_email',
      'billing_telephone',
    ];

    const { vmp_contractor } = data;

    const contractorArray = orderObjectByKeys(vmp_contractor, order);

    return contractorArray;
  };

  getHeadings = (): Object => {
    switch (this.props.roleName) {
      case 'hw':
        return [
          {
            name: 'name',
            sortable: true,
            text: l('CONT-COL-name'),
          },
          {
            name: 'details',
            sortable: true,
            text: l('CONT-COL-address'),
            customFormatter: SimpleListItemArrayFormatter(),
          },
          {
            name: 'rating',
            sortable: true,
            text: l('CONT-COL-rating'),
            customFormatter: SimpleListItemRatingFormatter,
          },
          {
            name: 'display_status',
            sortable: false,
            text: l('CONT-COL-status'),
            customFormatter: SimpleListItemArrayFormatter(true),
          },
          {
            name: 'earnings',
            sortable: false,
            text: l('CONT-COL-earnings'),
            customFormatter: fC,
            align: 'right',
          },
        ];
      case 'pm':
        return [
          {
            name: 'name',
            sortable: true,
            text: l('CONTACT_LIST-manager'),
          },
          {
            name: 'contact_number',
            sortable: true,
            text: l('CONTACT-telephone'),
          },
          {
            name: 'email',
            sortable: true,
            text: l('CONTACT-email'),
          },
          {
            name: 'created_at',
            sortable: true,
            text: l('ACCOUNT-created_at'),
          },
          {
            name: 'updated_at',
            sortable: true,
            text: l('ACCOUNT-updated_at'),
          },
        ];
      case 'hcm':
        return [
          {
            name: 'name',
            sortable: true,
            text: l('CONTACT_LIST-construction_manager'),
          },
          {
            name: 'contact_number',
            sortable: true,
            text: l('CONTACT-telephone'),
          },
          {
            name: 'email',
            sortable: true,
            text: l('CONTACT-email'),
          },
          {
            name: 'created_at',
            sortable: true,
            text: l('ACCOUNT-created_at'),
          },
          {
            name: 'updated_at',
            sortable: true,
            text: l('ACCOUNT-updated_at'),
          },
        ];
      case 'cm':
        return [
          {
            name: 'name',
            sortable: true,
            text: l('CONTACT_LIST-project_manager'),
          },
          {
            name: 'contact_number',
            sortable: true,
            text: l('CONTACT-telephone'),
          },
          {
            name: 'email',
            sortable: true,
            text: l('CONTACT-email'),
          },
          {
            name: 'created_at',
            sortable: true,
            text: l('ACCOUNT-created_at'),
          },
          {
            name: 'updated_at',
            sortable: true,
            text: l('ACCOUNT-updated_at'),
          },
        ];
      default:
        return [];
    }
  };

  apiUrl: string;

  toggle = (): Function => (e: Object): any => {
    if (Object.prototype.hasOwnProperty.call(e, 'preventDefault')) {
      e.preventDefault();
    }

    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  };

  selectAndAddRef = (referenceObject: Object): any => {
    // @todo - fix up references of pms/cms

    if (this.props.roleName === 'hw') {
      this.props.addRef('reference_id', referenceObject.contractor_profile_id);
      this.setState({ loading: true, isOpen: false, hasError: false });
      return this.getVmp(referenceObject.id).then(res => {
        this.setState({
          selected: {
            isSelected: true,
            data: res,
          },
        });

        return this.setState({ loading: false });
      });
    }

    this.props.addRef('reference_id', referenceObject.id);
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      isOpen: false,
      hasError: false,
      request: initialRequest,
      selected: {
        isSelected: true,
        data:
          this.props.roleName === 'hw'
            ? [
                `${referenceObject.first_name} ${referenceObject.surname}`,
                ...referenceObject.details.filter(
                  (i: string): boolean => i !== '',
                ),
              ]
            : [
                `${referenceObject.first_name} ${referenceObject.surname}`,
                referenceObject.email,
                referenceObject.telephone_1,
                referenceObject.telephone_2,
              ],
      },
    }));
  };

  fetchData = (): Promise<*> =>
    axios
      .get(this.apiUrl)
      .then((res: Object): any => this.handleSuccess(res.data))
      .catch((err: Object): any => this.handleError(err));

  handleSuccess = (data: Object): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      data: data.payload.references,
      request: {
        ...initialRequest,
        success: true,
      },
    }));

  handleError = (): boolean =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      request: {
        ...initialRequest,
        failed: false,
      },
    }));

  render(): React$Element<*> {
    const dataUsage = this.props.viewOnly
      ? this.state.initialData
      : this.state.selected.data;
    return (
      <Fragment>
        <div
          className={`${(this.state.hasError && 'form__item--danger') ||
            'form__item '}`}>
          <div className="g g-1/1">
            <label htmlFor={this.props.name} className="form__label--small">
              {l(this.props.label)}
              {!this.props.viewOnly && this.props.mandatory && '*'}
            </label>
            <div name={this.props.name}>
              {this.state.loading ? (
                <div>
                  <GetSvg svg="loading" wrapperClass="loader-base" />
                </div>
              ) : null}
              {!this.state.loading && this.state.selected.isSelected ? (
                <div className="push--bottom">
                  {dataUsage.length ? (
                    dataUsage.map((i: string, k: number): React$Element<*> => (
                      <div key={k}>{i}</div>
                    ))
                  ) : (
                    <p>-</p>
                  )}
                </div>
              ) : null}
              {!this.state.selected.isSelected && this.props.viewOnly && (
                <p>-</p>
              )}
              {(!this.state.loading &&
                this.state.selected.isSelected &&
                !this.props.viewOnly && (
                  <div>
                    <button
                      className="btn--text--primary"
                      onClick={this.toggle()}
                      type="button">
                      {l('ACCOUNTS-change-reference')}
                    </button>
                  </div>
                )) ||
                (!this.state.loading &&
                  !this.state.selected.isSelected &&
                  !this.props.viewOnly && (
                    <button
                      onClick={this.toggle()}
                      className={`button-container-bordered${
                        this.state.hasError ? '--danger' : ''
                      }`}
                      data-qe-id="action-show-reference_slide"
                      type="button">
                      <GetSvg
                        svg="add_file"
                        wrapperClass="button-container-bordered__inner"
                      />
                    </button>
                  )) ||
                null}
            </div>
            <AsideSlide
              title={this.getReferenceTitle()}
              isOpen={this.state.isOpen}
              toggle={this.toggle()}
              toggleButton={() => <CloseButton onClick={this.toggle()} />}>
              <div>
                {this.state.isOpen ? (
                  <SimpleList
                    api={this.apiUrl}
                    bgcAlt
                    name="references"
                    headings={this.getHeadings()}
                    data={this.state.data}
                    clickAct={this.selectAndAddRef}
                  />
                ) : null}
              </div>
            </AsideSlide>
          </div>
          {(this.state.hasError && (
            <div className="g g-1/1">
              <div className="form__item__validation">
                {l('VALIDATION-required')}
              </div>
            </div>
          )) ||
            null}
        </div>
      </Fragment>
    );
  }
}

const ReferenceSlideWrapper = (
  roleName: string | number,
  prefillData: string[],
): Function => (props: any): React$Element<*> => (
  <ReferenceSlide
    roleName={roleName}
    prefillData={prefillData ? prefillData.display_reference : undefined}
    data={prefillData}
    {...props}
  />
);

export default ReferenceSlideWrapper;
