/** @format */

import React from 'react';

import Card from '../../../../../components/Card';
import l from '../../../../../helpers/locale';

const BankPanel = ({ bankAddress, iban }) => (
  <Card title="NOMINATION-bank-card_title" isOpen canCollapse={false}>
    <div className="gw">
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION-bank-name_and_address')}
          </span>
          <p className="text--dk--flushed">{`${bankAddress}`}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-iban')}
          </span>
          <p className="text--dk--flushed">{iban}</p>
        </div>
      </div>
    </div>
  </Card>
);

BankPanel.defaultProps = {};

export default BankPanel;
