import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Card from 'components/Card';
import Chart from 'components/Chart';
import MapComp from 'components/Map/SafeMap';
import PageReady from 'components/AllianzPageReady';
import GraphQLPageReady from 'components/GraphQLPageReady';
import l, { fC } from 'helpers/locale';
import convPropToInt from 'functions/convPropToInt.func';
import getDashboardUserDocuments from '../../query';
import DashboardItem from '../../components/DashboardItem.js';
import DashboardDocuments from '../../components/DashboardDocuments.js';

type StateShape = {
  data: {
    statistics: Object,
    map: Object,
    dashboard_user: Object,
  },
};

class agencyManagerDashboard extends React.Component<null, StateShape> {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        statistics: {},
        map: {},
        dashboard_user: {},
      },
    };
  }

  state: StateShape;

  handleSuccess = (data: Object): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      data: {
        ...prevState.data,
        ...data,
      },
    }));

  render(): React$Element<*> {
    return (
      <Fragment>
        <GraphQLPageReady
          onData={res => this.handleSuccess(res.data.data)}
          queryId="getDashboardUserDocuments"
          queryString={getDashboardUserDocuments()}>
          <PageReady
            url="/api/1/view/dashboard"
            onData={res => this.handleSuccess(res.data.payload)}>
            <Fragment>
              <Card title="AM_DASHBOARD-latest_contracts" canCollapse={false}>
                <div style={{ height: '350px', overflow: 'hidden' }}>
                  <MapComp
                    markers={
                      this.state.data.map &&
                      this.state.data.map.contractors &&
                      Array.isArray(this.state.data.map.contractors)
                        ? [
                            ...this.state.data.map.contractors.map(
                              (item: Object): Object => ({
                                ...item,
                                color: 'blue',
                                isLink: true,
                                url: item.url,
                              }),
                            ),
                          ]
                        : []
                    }
                  />
                </div>
              </Card>
              <Card
                title="AM_DASHBOARD-contractor_turnover"
                canCollapse={false}>
                <DashboardItem>
                  <label className="form__label--small" htmlFor="__">
                    {l('AM_DASHBOARD-top_10_this_year')}
                  </label>
                  {this.state.data.statistics &&
                  this.state.data.statistics.contractors &&
                  this.state.data.statistics.contractors.length > 0 ? (
                    <Chart
                      data={this.state.data.statistics.contractors.map(
                        convPropToInt('earnings'),
                      )}
                      numberKey="earnings"
                      categoryKey="name"
                    />
                  ) : null}
                </DashboardItem>
              </Card>
              <Card title="AM_DASHBOARD-trades_turnover" canCollapse={false}>
                <div className="gw">
                  <div className="g--tall g-1/2">
                    <DashboardItem>
                      <label className="form__label--small" htmlFor="__">
                        {l('DASHBOARD-top_trades_this_year')}
                      </label>
                      {this.state.data.statistics &&
                      this.state.data.statistics.trades &&
                      this.state.data.statistics.trades.length > 0 ? (
                        <Chart
                          data={this.state.data.statistics.trades.map(
                            convPropToInt('earnings'),
                          )}
                          numberKey="earnings"
                          categoryKey="name"
                        />
                      ) : null}
                    </DashboardItem>
                  </div>
                  <div className="g--tall g-1/2">
                    <div className="gw">
                      <div className="g--tall g-1/1">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('AM_DASHBOARD-volume_year')}
                          </label>
                          <p className="text--dk--flushed--large">
                            {this.state.data.statistics
                              ? fC(this.state.data.statistics.turnover_total) ||
                                '-'
                              : '-'}
                          </p>
                        </DashboardItem>
                      </div>
                      <div className="g--tall g-1/1">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('AM_DASHBOARD-jobs_year')}
                          </label>
                          <p className="text--dk--flushed--large">
                            {this.state.data.statistics
                              ? this.state.data.statistics.jobs_count || '-'
                              : '-'}
                          </p>
                        </DashboardItem>
                      </div>
                      <div className="g--tall g-1/1">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('AM_DASHBOARD-paid-jobs')}
                          </label>
                          <p className="text--dk--flushed--large">
                            {this.state.data.statistics
                              ? this.state.data.statistics.paid_jobs_count ||
                                '-'
                              : '-'}
                          </p>
                        </DashboardItem>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
              <DashboardDocuments
                documents={this.state.data?.dashboard_user?.documents || []}
              />
            </Fragment>
          </PageReady>
        </GraphQLPageReady>
      </Fragment>
    );
  }
}

export default withRouter(agencyManagerDashboard);
