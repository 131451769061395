// @flow
import React, { useState } from 'react';
import l from 'helpers/locale';
import Form from 'components/Form';
import validatePhone from 'functions/validatePhone';
import validateEmail from 'functions/validateEmail';
import { createCustomContact } from '../mutations';

type Props = {
  prefill?: {
    id?: number,
    name?: string,
    address_1?: string,
    address_2?: string,
    postal_code?: string,
    city?: string,
    telephone?: string,
    email?: string,
  } | void,
  create: Function,
};

function ContactForm(props: Props) {
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  function validate(
    payload: Object,
  ): {
    result: boolean,
    fields: Object,
  } {
    const requiredFields = ['name', 'surname', 'street', 'postal_code', 'city'];

    let validatorResponse = requiredFields.reduce(
      (acc: any, curr: any): any =>
        !payload[curr] ? { ...acc, [curr]: 'VALIDATION-required' } : acc,
      {},
    );

    if (payload.telephone?.trim() && !validatePhone(payload.telephone)) {
      validatorResponse = {
        ...validatorResponse,
        telephone: 'FORM-phone-description',
      };
    }

    if (payload.email.trim() && !validateEmail(payload.email)) {
      validatorResponse = {
        ...validatorResponse,
        email: 'FORM-email-description',
      };
    }

    return {
      result: Object.keys(validatorResponse).length === 0,
      fields: validatorResponse,
    };
  }

  async function submitForm(params) {
    setFormErrors({});
    setLoading(true);
    const { contact, errors } = await createCustomContact(
      params,
      'create_audit_contact',
    );
    setLoading(false);
    if (errors) {
      return setFormErrors(errors);
    }
    props.setPopup(false);
    return props.create(contact);
  }

  return (
    <Form
      isProcessing={loading}
      submitAct={submitForm}
      validateBefore={validate}
      submitButtonLocaleKey="AUDIT_CONTACT-submit_form"
      afterSubmitErrors={formErrors}
      sections={[
        {
          fields: [
            {
              id: '0',
              name: 'contact_id',
              value: props.prefill?.id || null,
              label: '',
              itemClass: 'visuallyhidden',
            },
            {
              id: '01',
              name: 'company',
              value: props.prefill?.company,
              label: l('CONTACT-company'),
              itemClass: 'g g-1/1',
            },
            {
              id: '1',
              name: 'name',
              value: props.prefill?.name,
              label: l('CONTACT-name'),
              itemClass: 'g g-1/1',
            },
            {
              id: '2',
              name: 'surname',
              value: props.prefill?.surname,
              label: l('CONTACT-surname'),
              itemClass: 'g g-1/1',
            },
            {
              id: '3',
              name: 'street',
              value: props.prefill?.street,
              label: l('CONTACT-street'),
              itemClass: 'g g-1/1',
            },
            {
              id: '4',
              name: 'postal_code',
              value: props.prefill?.postal_code,
              label: l('CONTACT-postal_code'),
              itemClass: 'g g-1/4',
            },
            {
              id: '5',
              name: 'city',
              value: props.prefill?.city,
              label: l('CONTACT-city'),
              itemClass: 'g g-3/4',
            },
            {
              id: '6',
              name: 'telephone',
              value: props.prefill?.telephone,
              label: l('CONTACT-telephone'),
              itemClass: 'g g-1/1',
            },
            {
              id: '7',
              name: 'email',
              value: props.prefill?.email,
              label: l('CONTACT-email'),
              itemClass: 'g g-1/1',
            },
          ],
        },
      ]}
    />
  );
}

ContactForm.defaultProps = {
  prefill: null,
};

export default ContactForm;
