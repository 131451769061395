import React from 'react';
import GetSvg from 'components/GetSvg';

const CloseButton = ({ onClick }) => {
  return (
    <button type="button" onClick={onClick} className="btn--text--base">
      <GetSvg svg="close" wrapperClass="aside-slide__icon" />
    </button>
  );
};

export default CloseButton;
