import React from 'react';
import { connect } from 'react-redux';
import InternalDocsPanel from 'shared/projects/panels/items/InternalDocuments';
import type { DocumentType } from 'views/singleJob/constants';
import DocConstants from 'components/connected/DocumentUploader/constants';
import { removeProjectDocument } from 'views/singleProject/actions';

const { DELETE } = DocConstants.DOCUMENT_ACTION;

type ReduxActionShape = {
  removeImage: Function,
};

type ReduxStateShape = {
  documents?: [DocumentType],
};

type PropsShape = ReduxStateShape & ReduxActionShape;

const InternalDocsCard = ({
  removeImage,
  documents,
}: PropsShape): React$Element<*> => (
  <InternalDocsPanel
    isEditing
    listName="create_project_internal"
    data={{
      uploadProps: {
        source_tag: 'internal',
        source_type: 'project',
      },
      endpoint: '/api/1/documents/action/add',
    }}
    projectStatus="draft"
    list={documents}
    controls={[
      {
        documentAction: DELETE,
        svgName: 'close',
        action: (id: string | number, api: string): any => removeImage(id, api),
        canDisplay: true,
      },
    ]}
  />
);

InternalDocsCard.defaultProps = {
  documents: [],
};

const mapState = ({ projectStore }): ReduxStateShape => ({
  documents: [
    ...projectStore.form.documents.filter(doc => doc.source_tag === 'internal'),
  ],
});

const mapActions = (dispatch: Function): ReduxActionShape => ({
  removeImage(id: string | number, api: string): any {
    return removeProjectDocument(id, 'internal', null, dispatch, true, api);
  },
});

export default connect(mapState, mapActions)(InternalDocsCard);
