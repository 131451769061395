import React from 'react';
import { withRouter } from 'react-router-dom';
import { Names } from 'components/RouteSlider/routes/accounts/constants';
import CreateAccountView from 'views/accounts/components/createAccount';
import { connect } from 'react-redux';
import { updateTitle } from 'components/RouteSlider/actions';

const AccountCreateRender = ({ match, setTitle }) => {
  setTitle(
    match.params.role_abbrv
      ? `ACCOUNTS-create_account-${match.params.role_abbrv}`
      : '-',
  );
  return <CreateAccountView role={match.params.role_abbrv} />;
};

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.ACCOUNT_CREATE, title));
  },
});

export const AccountCreate = withRouter(
  connect(null, mapActions)(AccountCreateRender),
);
