import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import AllianzSelect from 'components/AllianzSelect';
import MapComp from 'components/Map/SafeMap';
import Place from 'constants/place';
import Card from 'components/Card';
import GetSvg from 'components/GetSvg';
import l from 'helpers/locale';
import EditButton from 'shared/jobs/Panels/items/Contractor/components/EditButton';

type ReduxStateShape = {
  placeType: string,
  error_building_id: string[] | void,
};

type PropsShape = {
  data: Object,
  building_selected: ?string,
  isEditing?: boolean,
  updateField: string => (string | number) => any,
  openEdit: () => any,
  buildings: Array<*>,
} & ReduxStateShape;

const ObjectPanel = ({
  isEditing,
  allowConcernChange,
  updateField,
  openEdit,
  data,
  building_selected,
  buildings,
  placeType,
  error_building_id,
}: PropsShape): React$Element<*> => (
  <Card
    showHeader={false}
    hasPadding={false}
    isOpen
    canCollapse={false}
    identifier="job-object"
    qeId="job_card-object">
    <div className="gw--tall">
      <div className="g g-1/2">
        <MapComp
          markers={
            data.location
              ? [
                  {
                    ...data.location,
                    color: 'blue',
                  },
                ]
              : []
          }
        />
      </div>
      <div className="g g-1/2">
        <div className="grid-gutter--ends grid-gutter--right layout-bg">
          <div className="gw--tall">
            <div className="g g-1/1">
              <div className="push--small--bottom">
                <label className="form__label--small" htmlFor="__">
                  {l('FORMS-address')}
                </label>
                <p className="text--dk--flushed">
                  {Object.keys(data)
                    .filter(key =>
                      ['street', 'postal_code', 'city'].includes(key),
                    )
                    .map((v: string, vk: number): React$Element<*> => (
                      <span key={vk}>
                        {data[v]}
                        <br />
                      </span>
                    ))}
                </p>
              </div>
              <div className="push--small--bottom">
                <label className="form__label--small" htmlFor="__">
                  {l('FORMS-description')}
                </label>
                <p className="text--dk--flushed">{data.description}</p>
              </div>
              <div className="push--small--bottom">
                <label className="form__label--small" htmlFor="__">
                  {l('OBJECT-number')}
                </label>
                <p className="text--dk--flushed">{data.reference}</p>
              </div>
              <div className="push--small--bottom">
                <div className="gw">
                  <div className="g g-1/3">
                    <label className="form__label--small" htmlFor="__">
                      {l('OBJECT-lift')}
                    </label>
                    <p className="text--dk--flushed">
                      <GetSvg
                        svg="circle_check"
                        wrapperClass={
                          data.has_elevator ? 'u-success' : 'u-default'
                        }
                      />
                    </p>
                  </div>
                  {Place.RENTAL_OBJECT === placeType ? (
                    <Fragment>
                      <div className="g g-1/3">
                        <label className="form__label--small" htmlFor="__">
                          {l('OBJECT-rooms')}
                        </label>
                        <p className="text--dk--flushed">
                          {data.rooms_count || '-'}
                        </p>
                      </div>
                      <div className="g g-1/3">
                        <label className="form__label--small" htmlFor="__">
                          {l('OBJECT-floor')}
                        </label>
                        <p className="text--dk--flushed">
                          {data.floor?.description || '-'}
                        </p>
                      </div>
                    </Fragment>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="g g-1/1">
              <div className="fl--column fl--flex-end">
                <div
                  className={`form-and-view-container ${
                    error_building_id !== undefined
                      ? 'form-and-view-container--error'
                      : ''
                  }`}>
                  <div data-qe-id="action-click-area_selector">
                    <label className="form__label--small" htmlFor="__">
                      {l('OBJECT-building')}
                    </label>
                    {isEditing ? (
                      <AllianzSelect
                        placeholder="JOB-according_to-placeholder"
                        onValueChange={function(newVal: { value: string }) {
                          updateField({
                            key: 'building_id',
                            value: newVal ? newVal.value : null,
                          });
                        }}
                        defaultValue={{
                          label: building_selected
                            ? l(`OBJECT-building-option_${building_selected}`)
                            : '',
                          value: building_selected,
                        }}
                        value={{
                          value: building_selected,
                          label: building_selected
                            ? l(`OBJECT-building-option_${building_selected}`)
                            : '',
                        }}
                        options={buildings.map(v => ({
                          label: l(`OBJECT-building-option_${v.key}`),
                          value: v.key,
                        }))}
                      />
                    ) : (
                      <p className="text--dk--flushed">
                        {building_selected
                          ? l(`OBJECT-building-option_${building_selected}`)
                          : '-'}
                      </p>
                    )}
                  </div>
                </div>
                <div className="align--right">
                  <EditButton
                    show={isEditing && allowConcernChange}
                    showSpacer={isEditing && !allowConcernChange}
                    editAction={openEdit}
                    identifier="action-edit-concern"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Card>
);

ObjectPanel.defaultProps = {
  isEditing: false,
};

const mapState = (state: Object): ReduxStateShape => ({
  placeType: state.jobStore.data.job.concern.concern_type,
  error_building_id: state.jobStore.errors.building_id,
});

export default connect(mapState)(ObjectPanel);
