import axios from 'axios';
import { flushAuth } from 'functions/authenticator.func.js';
import getUrlParamByName from 'functions/getUrlParamByName';
import history from './history';
import store from '../store';
import { setToken } from '../actions/auth.js';

function getEncodedUrl(currentUrl) {
  const splitUrl = currentUrl.split('?');
  const baseUrl = splitUrl[0];
  const nextUrlSegments = splitUrl[1].split('&');
  let nextQueryString = splitUrl[1];
  if (nextUrlSegments.length > 0) {
    nextQueryString = nextUrlSegments
      .map(v => {
        let nextValue = v;

        if (v.includes('=')) {
          const temp = v.split('=');
          nextValue = `${temp[0]}=${encodeURIComponent(temp[1])}`;
        }

        return nextValue;
      })
      .join('&');
  }
  return `${baseUrl}?${nextQueryString}`;
}

const axiosInstance = () => {
  const instance = axios.create({
    validateStatus: status => status >= 200 && status < 300,
  });

  let token = '';
  if (typeof window !== 'undefined') {
    if (window.localStorage.getItem('_tk')) {
      token = window.localStorage.getItem('_tk');
      instance.defaults.headers.common.Authorization = token;
    } else if (window.location.href.includes('token=')) {
      token = `Bearer ${getUrlParamByName(window.location.href, 'token')}`;
      instance.defaults.headers.common.Authorization = token;
    }
  }

  instance.defaults.headers.common['Content-Type'] =
    'application/json;charset=UTF-8';

  // Add a request interceptor
  instance.interceptors.request.use(
    (request, error) => {
      if (error) {
        return Promise.reject(error.response);
      }
      let language = '';
      if (typeof window !== 'undefined') {
        language =
          window.localStorage.getItem('_sl') ||
          store.getState().appStore.locale;
      }
      // eslint-disable-next-line no-param-reassign
      request.headers.common['Accept-Language'] = language;
      if (request.url.includes('search') && request.url.includes('?')) {
        // do some uri manipulation for ie
        // https://stackoverflow.com/questions/18220057
        // eslint-disable-next-line no-param-reassign
        request.url = getEncodedUrl(request.url);
      }

      // eslint-disable-next-line no-param-reassign
      request.headers.common['Content-Type'] =
        'application/json; charset=utf-8';
      if (typeof request.body === 'undefined') {
        request.body = {};
      }

      if (typeof window !== 'undefined' && window.localStorage.getItem('_tk')) {
        token = window.localStorage.getItem('_tk');
        // eslint-disable-next-line no-param-reassign
        request.headers.common.Authorization = token;
      }
      return request;
    },
    error => Promise.reject(error.message),
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response, error) => {
      if (error) {
        return Promise.reject(error.response);
      }
      if (response.headers.authorization && typeof window !== 'undefined') {
        const newToken = response.headers.authorization;
        window.localStorage.setItem('_tk', newToken);
        store.dispatch(setToken(newToken));
      }
      return response;
    },
    error => {
      if (error?.response) {
        // network error
        if (
          (error.response.data &&
            error.response.data.error &&
            (error.response.data.error === 'token_invalid' ||
              error.response.data.error === 'token_expired')) ||
          error.response.status === 401 ||
          error.response.status === 503 ||
          error.response.status === 400
        ) {
          history.push('/');
          flushAuth();
          store.dispatch({ type: 'AUTH_REMOVE' });
        }
        return Promise.reject(error.response);
      }
      return Promise.reject(error);
    },
  );

  return instance;
};

export default axiosInstance();
