import React from 'react';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import GetSvg from 'components/GetSvg';
import * as Actions from './actions';
import ListWrapper from './components/ListWrapper';

type ReduxPropsShape = {
  reduxActions: {
    fetchData: ('properties' | 'houses' | 'rental_object') => any,
    fetchPageData: ('properties' | 'houses' | 'rental_object', number) => any,
  },
};
type PropsShape = { nextAct: Function } & ReduxPropsShape;
type ListOptsShape = 'properties' | 'houses' | 'rental_object';
type StateShape = {
  activeList: ListOptsShape,
};

class AssetHouseProperty extends React.Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      activeList: 'rental_object',
    };
  }

  state: StateShape;

  switchList = (name: ListOptsShape): Function => (): any =>
    this.setState({
      activeList: name,
    });

  clickHandler = (id, name) => {
    this.props.reduxActions.update({
      placeId: id,
      placeType: name,
    });

    return this.props.nextAct(id, name);
  };

  render(): React$Element<*> {
    return (
      <div>
        <div className="align--center push--bottom">
          <div
            className={
              'tiny-group-buttons ' +
              'tiny-group-buttons--large ' +
              'tiny-group-buttons--alt-bg'
            }>
            <div
              className={`tiny-group-buttons__item${
                this.state.activeList === 'property' ? '--active' : ''
              }`}>
              <button
                type="button"
                className="btn"
                onClick={this.switchList('property')}>
                <GetSvg
                  svg="building"
                  wrapperClass="tiny-group-buttons__icon"
                />
                <span className="tiny-group-buttons__label">
                  {l('PROPERTY-singular')}
                </span>
              </button>
            </div>
            <div
              className={`tiny-group-buttons__item${
                this.state.activeList === 'house' ? '--active' : ''
              }`}>
              <button
                type="button"
                className="btn"
                onClick={this.switchList('house')}>
                <GetSvg svg="house" wrapperClass="tiny-group-buttons__icon" />
                <span className="tiny-group-buttons__label">
                  {l('HOUSE-singular')}
                </span>
              </button>
            </div>
            <div
              className={`tiny-group-buttons__item${
                this.state.activeList === 'rental_object' ? '--active' : ''
              }`}>
              <button
                type="button"
                className="btn"
                onClick={this.switchList('rental_object')}>
                <GetSvg svg="object" wrapperClass="tiny-group-buttons__icon" />
                <span className="tiny-group-buttons__label">
                  {l('OBJECT-singular')}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div>
          <ListWrapper act={this.clickHandler} target={this.state.activeList} />
        </div>
      </div>
    );
  }
}

const mapState = (): any => ({});

const mapActions = (dispatch: Function): ReduxPropsShape => ({
  reduxActions: {
    update(obj): any {
      return dispatch({ type: '@TODO_UPDATE_AHP', payload: obj });
    },
    fetchData(data: 'rental_object' | 'properties' | 'houses'): any {
      return dispatch(Actions.fetchData(data));
    },
    fetchPageData(
      target: 'properties' | 'houses' | 'rental_object',
      pageNum: number,
    ): any {
      return dispatch(Actions.fetchPageData(target, pageNum));
    },
  },
});

export default connect(mapState, mapActions)(AssetHouseProperty);
