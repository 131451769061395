// @flow
import StateMachine from 'machines/reduxMachine.js';
import { transitions } from 'machines/constants.js';
import { State, Transition, Edge as EdgeBuilder } from 'async-state-machine';

// Transitions for rating card
export const to_visible = Object.create(Transition).setName(
  transitions.to_visible,
);

function generateRatingCardStateMachine() {
  const Edge = new EdgeBuilder();

  // States for Rating Card
  const HIDDEN = Object.create(State).setName('HIDDEN');
  const VISIBLE = Object.create(State).setName('VISIBLE');

  // Register Machine
  const Machine = new StateMachine('rating_card')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN])
        .to(VISIBLE),
    )
    .setInitialState(HIDDEN, {}, true);

  return Machine;
}

export default generateRatingCardStateMachine;
