import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import Roles from 'constants/roles';
import l from 'helpers/locale';
import Card from 'components/Card';
import SimpleList, {
  SimpleListItemArrayFormatter,
} from 'components/ConnectedSimpleList';

import { jobsExporting } from './actions';
import JobsListActionGroup from './components/ActionGroup';

const { RoleIds } = Roles;

type ReduxStateShape = {
  email: string,
  userType: number,
};

type StateShape = {
  stateSearch: string,
};

type PropsShape = {
  history: Object,
  location: Object,
} & ReduxStateShape;

class JobsView extends React.Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      stateSearch: props.location.state ? props.location.state.search : '',
    };
  }

  getFilterOptions() {
    switch (this.props.userType) {
      case RoleIds.PROPERTY_MANAGER:
        return [
          {
            text: l('PREDEFINED_SEARCH-my_jobs'),
            value: `pm:${this.props.email}`,
          },
          {
            text: l('PREDEFINED_SEARCH-my_open_jobs'),
            value: `pm:${this.props.email} active:true`,
          },
          {
            text: l('PREDEFINED_SEARCH-my_granted_jobs'),
            value: `pm:${this.props.email} status:${l('JOB_STATE_4')}`,
          },
          {
            text: l('PREDEFINED_SEARCH-my_accepted_jobs'),
            value: `pm:${this.props.email} status:${l('JOB_STATE_5')}`,
          },
          {
            text: l('PREDEFINED_SEARCH-my_executed_jobs'),
            value: `pm:${this.props.email} status:${l('JOB_STATE_6')}`,
          },
        ];
      default:
        return [
          {
            text: l('PREDEFINED_SEARCH-granted'),
            value: `status:${l('JOB_STATE_4')}`,
          },
          {
            text: l('PREDEFINED_SEARCH-accepted'),
            value: `status:${l('JOB_STATE_5')}`,
          },
          {
            text: l('PREDEFINED_SEARCH-executed'),
            value: `status:${l('JOB_STATE_6')}`,
          },
        ];
    }
  }

  openJob = ({ id }): any => {
    this.props.history.push(`/jobs/${id}`);
  };

  export = () => {
    this.props.exporting();
  };

  render(): React$Element<*> {
    return (
      <div>
        <ActionBar name="job_list" />
        <Card
          title="JOB-plural"
          canCollapse={false}
          watermarkName="job_slanted"
          asideComponent={JobsListActionGroup(
            this.export,
            this.props.userType,
          )}>
          <SimpleList
            api="/api/1/view/jobs"
            bgcAlt
            name="jobs"
            headings={[
              {
                name: 'job_id',
                sortable: true,
                text: l('JOB-COL-id'),
              },
              {
                name: 'description',
                sortable: false,
                text: l('JOB-COL-description'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'object',
                sortable: true,
                text: l('JOB-COL-object'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'allianz_contact',
                sortable: true,
                text: l('JOB-COL-allianz_contact'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'tenant',
                sortable: false,
                text: l('JOB-COL-tenant'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'last_updated',
                sortable: true,
                text: l('JOB-COL-last_update'),
              },
              {
                name: 'status',
                sortable: true,
                text: l('JOB-COL-status'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
            ]}
            clickAct={this.openJob}
            filterOpts={this.getFilterOptions()}
            initialSearch={this.state.stateSearch}
            showDatepicker
          />
        </Card>
      </div>
    );
  }
}

const mapState = (state: Object): ReduxStateShape => ({
  email: state.authStore.user.email,
  userType: state.authStore.user.role,
});

const mapActions = (dispatch: Function) => ({
  exporting(): any {
    return dispatch(jobsExporting());
  },
});

export default withRouter(connect(mapState, mapActions)(JobsView));
