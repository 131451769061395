/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import DescriptionProjectPanel from 'shared/projects/panels/items/Description';
import { updateForm } from 'views/singleProject/actions';

type ReduxStateShape = {
  title: string,
  summary: string,
};

type PropsShape = {
  onChange: ({
    key: string,
    value: string,
  }) => any,
  updateFormField: ({
    key: string,
    value: string,
  }) => any,
} & ReduxStateShape;

const DescriptionCard = ({
  title,
  summary,
  onChange,
  updateFormField,
}: PropsShape): React$Element<*> => (
  <DescriptionProjectPanel
    data={{ summary, title }}
    projectStatus="draft"
    isEditing
    onChange={onChange}
    updateField={updateFormField}
    storeTargetLabel="exists"
  />
);

const mapState = ({ projectStore }): ReduxStateShape => ({
  summary: projectStore.form.summary,
  title: projectStore.form.title,
});

const mapActions = (dispatch: Function) => ({
  updateFormField(key: string, value: string): any {
    return dispatch(updateForm(key, value));
  },
  onChange(key: string, value: string): any {
    return dispatch({ type: 'TODO', value });
  },
});

export default connect(mapState, mapActions)(DescriptionCard);
