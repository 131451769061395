/** @format */

// Returns either the authedComp`onent` or the fallbackComponent
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import jwtDecode from 'jwt-decode';

import * as AuthActions from '../actions/auth.js';

export const requireAuth = (
  currentAuth,
  authFn = () => true,
  // authCb = () => true,
) => AuthedComp => FallbackComponent => {
  const isAuthed = hasAuth() && currentAuth;

  if (isAuthed) {
    authFn();
  }

  const FinalComponent = isAuthed === true ? AuthedComp : FallbackComponent;

  return (
    <div>
      <FinalComponent />
    </div>
  );
};

export function flushAuth() {
  window.localStorage.removeItem('_ud');
  window.localStorage.removeItem('_tk');
  return true;
}

export function hasAuth() {
  try {
    jwtDecode(window.localStorage.getItem('_tk'));
  } catch (_) {
    return false;
  }

  return true;
}

export function decodeJwt(jwt?: string) {
  const bearerPrefix = 'Bearer ';
  let token = jwt || window.localStorage.getItem('_tk');
  if (token.includes(bearerPrefix)) {
    token = token.substring(bearerPrefix.length);
  }
  return jwtDecode(token);
}

export function decryptAuth() {
  const decrypted = decodeJwt();
  const { id, role, name, email, lang } = decrypted;

  const auth = {
    id,
    role,
    name,
    email,
    lang,
  };

  return auth;
}

const AuthWrapper = ({ children, action }) => {
  if (hasAuth()) {
    // write to local and dispatch
    action.isAuth();
  }
  return children;
};

const mapState = state => ({
  locale: state.appStore.locale,
});

const mapDispatch = dispatch => ({
  action: bindActionCreators(AuthActions, dispatch),
});

export const AuthWrapperConnected = connect(mapState, mapDispatch)(AuthWrapper);
