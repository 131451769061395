/** @format */

// @flow

import { Component } from 'react';
import { connect } from 'react-redux';

import { userRoles as ROLE } from '../../../../../constants/auth';

type PropsShape = {
  role: number,
  view: 'accept_offer' | 'decline_offer',
  children: Function,
};

class OfferActionRules extends Component<PropsShape> {
  rules = {
    accept_offer: [ROLE.PROPERTY_MANAGER, ROLE.CONSTRUCTION_MANAGER],
    decline_offer: [ROLE.PROPERTY_MANAGER, ROLE.CONSTRUCTION_MANAGER],
  };

  projectJobValidation = () => this.props.isUserProjectOwner;

  orphanJobValidation = () =>
    this.rules[this.props.view].includes(this.props.role);

  validateView = () =>
    (this.props.isProjectJob && this.projectJobValidation()) ||
    (!this.props.isProjectJob && this.orphanJobValidation());

  // eslint-disable-next-line no-confusing-arrow
  renderView = () => (this.validateView() ? this.props.children() : null);

  render() {
    return this.renderView();
  }
}

const mapState = state => ({
  isProjectJob: state.jobStore.data.job && state.jobStore.data.job.project_id,
  isUserProjectOwner:
    state.jobStore.data.job && state.jobStore.data.job.is_project_owner_on_job,
});

export default connect(mapState)(OfferActionRules);
