import React from 'react';
import { PageReady } from '@bonlineza/b-lib';
import AllianzLoader from 'components/AllianzLoader';
import axios from 'helpers/axios';
import { interpretErrorMessage } from 'helpers/formatErrors';

type PropsShape = {
  url: string,
  onData: Function,
  requestType?: string,
  meta?: Object,
};

class AllianzPageReady extends React.Component<PropsShape> {
  pageReady: PageReady;

  getConfig = () => this.props.meta?.config || null;

  getPayload = () => this.props.meta?.payload || null;

  get = () => {
    if (this.pageReady) this.pageReady.initRequest();
  };

  render = () => (
    <PageReady
      ref={inst => {
        this.pageReady = inst;
      }}
      {...this.props}
      getRequestInstance={() =>
        axios[this.props.requestType](
          this.props.url,
          this.getPayload(),
          this.getConfig(),
        )
      }
      renderCustomLoader={AllianzLoader}
      errorMessageInterpreter={interpretErrorMessage}
    />
  );
}

AllianzPageReady.defaultProps = {
  meta: {},
  requestType: 'get',
};

export default AllianzPageReady;
