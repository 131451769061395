import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Place from 'constants/place';
import Card from 'components/Card';
import GetSvg from 'components/GetSvg';
import l from 'helpers/locale';

type ReduxStateShape = {
  placeType: string,
};

type PropsShape = {
  data: Object,
  openEdit: () => any,
  isEditing?: boolean,
} & ReduxStateShape;

const ObjectPanel = ({
  data,
  openEdit,
  isEditing,
  placeType,
}: PropsShape): React$Element<*> => (
  <Card title="OBJECT-singular" isOpen canCollapse={false}>
    <div className="gw">
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <label className="form__label--small" htmlFor="__">
            {l('FORMS-address')}
          </label>
          <p className="text--dk--flushed">
            {Object.keys(data)
              .filter(dataKey =>
                ['street', 'postal_code', 'city'].includes(dataKey),
              )
              .map((v: string, vk: number): React$Element<*> => (
                // eslint-disable-next-line react/no-array-index-key
                <span key={vk}>
                  {data[v]}
                  <br />
                </span>
              ))}
          </p>
        </div>
        <div className="push--small--bottom">
          <label className="form__label--small" htmlFor="__">
            {l('FORMS-description')}
          </label>
          <p className="text--dk--flushed">{data.description}</p>
        </div>
        <div className="push--small--bottom">
          <label className="form__label--small" htmlFor="__">
            {l('OBJECT-number')}
          </label>
          <p className="text--dk--flushed">{data.reference}</p>
        </div>
        <div className="push--small--bottom">
          <div className="gw">
            <div className="g g-1/3">
              <label className="form__label--small" htmlFor="__">
                {l('OBJECT-lift')}
              </label>
              <p className="text--dk--flushed">
                <GetSvg
                  svg="circle_check"
                  wrapperClass={data.lift ? 'u-success' : 'u-default'}
                />
              </p>
            </div>
            {Place.RENTAL_OBJECT === placeType ? (
              <Fragment>
                <div className="g g-1/3">
                  <label className="form__label--small" htmlFor="__">
                    {l('OBJECT-rooms')}
                  </label>
                  <p className="text--dk--flushed">{data.room || '-'}</p>
                </div>
                <div className="g g-1/3">
                  <label className="form__label--small" htmlFor="__">
                    {l('OBJECT-floor')}
                  </label>
                  <p className="text--dk--flushed">
                    {data.floor?.description || '-'}
                  </p>
                </div>
              </Fragment>
            ) : null}
          </div>
        </div>
        <div className="align--right">
          {isEditing ? (
            <button
              type="button"
              onClick={openEdit}
              className="btn--text"
              data-qe-id="action-edit-project-object">
              {l('OBJECT-change')}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  </Card>
);

ObjectPanel.defaultProps = {
  isEditing: false,
};

const mapState = (state: Object): ReduxStateShape => ({
  placeType: state.projectStore.data.concern.concern_type,
});

export default connect(mapState)(ObjectPanel);
