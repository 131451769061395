/**
 * /* eslint-disable
 *
 * @format
 */

import React from 'react';
import Card from '../../../components/Card';

const TermsAndConditions = (): React$Element<*> => (
  <Card title="GENERAL-terms" canCollapse={false}>
    <div className="u-para--small">
      <h2>
        Benutzungsbedingungen Virtueller Marktplatz{' '}
        <small>(im Folgenden „VMP“)</small>
      </h2>
      <p>Diese vertraglichen Bedingungen gelten für die Nutzung der von</p>
      <p>
        <strong>Allianz Suisse Immobilien AG</strong>
        <br /> <strong>Richtiplatz 1</strong>
        <br /> <strong>8304 Wallisellen - Schweiz</strong>
      </p>
      <p>
        (im Folgenden &quot;<strong>ASI</strong>&quot;) bereitgestellten
        Dienste.
      </p>
      <p>
        Diesen vertraglichen Bedingungen müssen Sie bei der Eröffnung eines
        Accounts im
        <b>V</b>irtuellen <b>M</b>arkt<b>p</b>latz (VMP) zustimmen. Sie gelten
        gleichermassen sowohl für auftraggebende (im Folgenden „Auftraggeber“,
        welche sowohl ASI als auch mit ihr verbundene Partner/ Unternehmen
        beinhaltet), als auch auftragnehmende (im Folgenden „Auftragnehmer“)
        Nutzer.
      </p>
      <p>
        Zukünftige Änderungen dieser vertraglichen Bedingungen treten für Sie zu
        dem in den aktualisierten VMP-Richtlinien genannten Datum in Kraft. ASI
        behält sich das Recht vor, diese Bedingungen einseitig zu ändern.
      </p>
      <p>
        Die vertraglichen Bedingungen und alle weiteren rechtlichen Bestimmungen
        oder Informationen in Bezug auf Allianz Dienstleistungen stehen Ihnen
        jederzeit auf der VMP Startseite unter dem Link &quot;VMP-Vertragliche
        Bedingungen&quot; zur Verfügung.
      </p>
      <p>Die vertraglichen Bedingungen unterteilen sich in</p>
      <p>
        A) Fair Play im VMP
        <br /> B) Nutzungsbedingungen
        <br /> C) Allgemeine Geschäftsbedingungen (AGB)
        <br /> D) Integeres Verhalten und Antikorruption
        <br /> F) Geheimhaltung und Datenschutz
      </p>
      <p>Wallisellen, 1.1.2020</p>

      <h2>A) Fair Play im VMP</h2>
      <p>
        Im Rahmen des VMP gelten die nachfolgenden Fair Play Regeln
        gleichermassen für alle Nutzer. Eine Nichtbefolgung dieser Grundsätze
        kann zum Ausschluss aus dem VMP führen.
      </p>
      <p>
        <strong>ASI fordert Respekt und Akzeptanz im VMP.</strong>
      </p>
      <p>Deshalb beachten und befolgen die Nutzer die folgenden Grundsätze:</p>
      <ol>
        <li>
          Die Nutzer erkennen den VMP als geschlossenes Allianz Suisse System
          an.
        </li>
        <li>
          Die Nutzer respektieren, dass alle Nutzer im VMP unterschiedliche
          Interessen vertreten.
        </li>
      </ol>
      <p>
        Die Auftragnehmer akzeptieren, dass sie als Nutzer im VMP Auftragnehmer
        der ASI sind.
      </p>
      <p>
        Die Auftragnehmer erstellen Offerten im VMP nach bestem Wissen und
        Gewissen.
      </p>
      <p>
        Die Auftragnehmer verstehen, dass über die Leistungsbewertung seitens
        Allianz keine Korrespondenz geführt wird.
      </p>
      <p>
        Die Auftragnehmer erklären sich damit einverstanden, dass im VMP der
        jeweilige Allianz-Kundenstatus (ggf. Kundennummer), die Arbeitsbewertung
        sowie potenzielle Empfehlungen durch Versicherungsagenten auch
        Geschäftspartnern / Subunternehmern / Dienstleistern angezeigt werden.
      </p>
      <h2>B) Nutzungsbedingungen</h2>
      <h4>1. Dienstleistungsangebot</h4>
      <p>
        Der VMP der ASI ist eine digitale Plattform, welche eine effizientere
        Gestaltung der Prozesse bei Instandhaltungs- und Instandsetzungsarbeiten
        von Liegenschaften sowie bei Bauprojekten ermöglicht. Die
        Vergabeprozesse von Dienstleistungsarbeiten der Schweizer Bauindustrie
        werden mit Hilfe einer grafischen Benutzeroberfläche durchgeführt. Der
        VMP wird als geschlossenes System der ASI ausgestaltet.
      </p>
      <h4>2. Legitimationsüberprüfung</h4>
      <p>
        Wer sich mit den entsprechenden Identifikationsmitteln ordnungsgemäss
        identifiziert, gilt gegenüber ASI als legitimiert, das Leistungsangebot
        des VMP in Anspruch zu nehmen. ASI darf in diesem Fall davon ausgehen,
        dass die erteilten Instruktionen und Mitteilungen vom Nutzer stammen.
        Die Nutzer haften für die Richtigkeit sämtlicher Daten, die sie online
        unter Verwendung ihrer Legitimationsmerkmale übermitteln. Schaden aus
        dem Nichterkennen von Legitimationsmängeln und Fälschungen tragen die
        Nutzer selbst, sofern ASI kein Verschulden trifft.
      </p>
      <h4>3. Sicherheit</h4>
      <p>
        Das Internet und das öffentliche Funknetz stellen weltweite und offene,
        grundsätzlich frei zugängliche Netze dar. AS I trifft im Rahmen der ihr
        zur Verfügung stehenden Möglichkeiten geeignete Massnahmen, um die
        Kommunikation über diese Kanäle so sicher wie möglich zu machen.
      </p>
      <p>
        Einen aus der Benutzung von Post, Telefon, Email, Benachrichtigungen im
        Cockpit oder anderen Übermittlungsarten entstehenden Schaden trägt der
        Nutzer, sofern ASI kein Verschulden trifft.
      </p>
      <h4>4. Hard- und Software, Zugang, Sperre</h4>
      <p>
        4.1 Für den Zugriff auf den VMP benötigt der Nutzer entsprechende Hard-
        und Software sowie eine Internetverbindung. Diese werden nicht von ASI
        zur Verfügung gestellt.
      </p>
      <p>
        4.2 Der Nutzer erhält Zugang zum VMP nach erfolgreicher Identifikation
        durch Eingabe der Legitimationsmerkale (Benutzername und persönliches
        Passwort).
      </p>
      <p>
        4.3 ASI hat das Recht, den Zugang zum VMP jederzeit ohne Angabe von
        Gründen und ohne sonstige Mitteilung zu sperren.
      </p>
      <p>
        Beispielsweise sperrt ASI den Zugang zur Plattform, falls der begründete
        Verdacht einer betrügerischen Verwendung der Legitimationsmerkmale
        besteht.
      </p>
      <h4>5. Sicherheitsvorkehrungen des Nutzers</h4>
      <p>
        Der Nutzer ist verpflichtet, seine Legitimationsmerkmale geheim zu
        halten, um einen Missbrauch durch unbefugte Dritte auszuschliessen.
      </p>
      <p>
        Für das zum Plattform-Zugang verwendete Endgerät hat der Nutzer
        notwendige Sicherheitsvorkehrungen zu treffen. Insbesondere hat der
        Nutzer dieses Endgerät angemessen gegen Zugriff von unbefugten Dritten
        und Computerviren zu schützen.
      </p>
      <h4>6. Kommunikation</h4>
      <p>
        Durch Annahme einer Leistungsvergabe seitens Auftragnehmenden wird ein
        rechtsverbindlicher Vertrag zwischen diesem und ASI geschlossen. Die
        weiteren vertraglichen Bestimmungen sind den AGB in Teil B zu entnehmen,{' '}
        <strong>gem. SIA 118.</strong>
      </p>
      <h4>7. Änderung der vertraglichen Bedingungen</h4>
      <p>
        ASI ist berechtigt, jederzeit ohne Vorankündigung die vorliegenden
        vertraglichen Bedingungen, einschliesslich der Fair Play Regeln sowie
        den allgemeinen Geschäftsbedingungen (AGB) zu ändern. Werden diese
        geändert, so gelten sie als akzeptiert, wenn der VMP weiter genutzt
        wird.
      </p>
      <h4>8. Datenschutz und Erlaubnis zur Datenverwendung</h4>
      <p>
        Der Schutz der Daten des VMP-Teilnehmers ist für die ASI sehr wichtig.
        Soweit die ASI im Rahmen ihrer Tätigkeit Personendaten des
        VMP-Teilnehmers erhebt, verarbeitet oder nutzt, geschieht dies
        ausschliesslich im Einklang mit den datenschutzrechtlichen Vorschriften.
      </p>
      <p>
        Der Nutzer räumt ASI das Recht ein, Inhalte und Informationen sowie
        allenfalls über den VMP abgewickelten Geschäftsvorfälle unter
        Berücksichtigung der gesetzlichen Vorgaben aufzuzeichnen, zu speichern,
        bei Bedarf auszuwerten und an andere Gesellschaften der Allianz Suisse
        Gruppe wie an die Generalagenturen der Allianz Suise zu übermitteln. Die
        Gesellschaften der Allianz Suisse Gruppe sowie die Generalagenturen der
        Allianz Suisse sind berechtigt, alle über den VMP übermittelten Daten
        für interne Marketing- und Werbezwecke zu verwenden. Zudem erklärt sich
        der Nutzer damit einverstanden, dass im VMP sein Allianz-Kundenstatus
        (ggf. Kundennummer), seine Arbeitsbewertung durch die Bewirtschaftung
        sowie potenzielle Empfehlungen durch Versicherungsagenten auch
        Geschäftspartnern / Subunternehmern / Dienstleistern der Allianz Suisse
        angezeigt werden.
      </p>
      <h4>9. Nutzung der Plattform VMP</h4>
      <p>
        Alle abrufbaren Informationen, Dienstleistungen und Daten des VMP von
        ASI sind ausschliesslich für den in diesen Bedingungen definierten Zweck
        zu nutzen. Das Kopieren, Vervielfältigen und Verbreiten zu kommerziellen
        Zwecken oder das Verändern der Plattform einschliesslich aller damit
        zusammenhängenden Informationen ist verboten.
      </p>
      <h4>10. Erfüllung der Eignungskriterien</h4>
      <p>
        Für den Zugriff auf den VMP ist der Nutzer dazu verpflichtet, die
        Eignungskriterien der ASI zu erfüllen. Diese werden im Rahmen der
        Registrierung vom Nutzer abgefragt. Bei falscher Eingabe der
        Eignungsmerkmale übernimmt die ASI keine Haftung für jegliche Schäden.
      </p>
      <h4>11. Haftungsausschluss</h4>
      <p>
        Informationen auf dem VMP werden regelmässig überprüft und aktualisiert.
        Trotzdem können sich Angaben zwischenzeitlich verändern. Für die
        inhaltliche Richtigkeit, Vollständigkeit, Vertraulichkeit oder
        Geheimhaltung der Daten und Aktualität der auf dem VMP angebotenen
        Dienstleistungen und Informationen übernimmt daher die ASI keine Gewähr.
        Die ASI behält sich vor, den Inhalt ihrer Plattform jederzeit abzuändern
        oder zu ergänzen. Insbesondere wird jegliche Haftung für direkte oder
        indirekte Schäden oder Folgeschäden, die Nutzern oder Dritten als Folge
        der Benutzung des VMPs und der daraus ersichtlichen Informationen in
        irgendeiner Weise entstehen könnten, ausgeschlossen. Dieser
        Haftungsausschluss umfasst u.a. auch den Datenverlust durch den
        Missbrauch Dritter und durch Computerviren. Die ASI übernimmt
        insbesondere keine Haftung für Inhalte, die mit rechtlichen Bestimmungen
        ausserhalb der Schweiz nicht vereinbart werden können.
      </p>
      <p>
        Eine Haftung wird ferner ausgeschlossen für Übermittlungsfehler,
        technische Mängel, Störungen oder für den Missbrauch des Telefon- und
        Datennetzes, für die Überlastung der Netze und für allfällige
        Legitimationsmängel.
      </p>
      <p>
        Die ASI ist nicht verantwortlich für die von Nutzern vorgenommenen
        Eintragungen sowie für den Inhalt von Seiten, die nicht von ASI geprüft
        oder unterhalten werden (z.B. Links).
      </p>
      <h2>C) Allgemeine Geschäftsbedingungen (AGB)</h2>
      <h4>1. Anwendungsbereich und Geltung</h4>
      <p>
        1.1 Die AGB regeln Abschluss, Inhalt und Abwicklung von Verträgen für
        werkvertragliche und auftragsrechtliche <b>Mandate</b> im Bereich der
        Instandhaltungs- und Instandsetzungsarbeiten von Liegenschaften sowie
        bei Bauprojekten zwischen der ASI und einem Auftragnehmer. Im Folgenden
        werden sie gemeinsam als „Vertragspartner“ bezeichnet.
      </p>
      <p>
        1.2 Die ASI weist in der Offertanfrage auf die geltenden AGB hin. Mit
        der Einreichung eines schriftlichen Angebotes oder, falls diese fehlt,
        spätestens bei Annahme einer Vergabe, erkennt der Auftragnehmer die
        Anwendbarkeit dieser AGB an. Allgemeine Geschäfts- oder
        Lieferbedingungen der Auftragnehmer finden keine Anwendung, auch wenn in
        deren Angebot oder dazugehörigen weiteren Unterlagen darauf verwiesen
        wird.
      </p>
      <p>
        1.3 Abweichungen von diesen AGB sind in der Offertanfrage bzw. im
        Angebot ausdrücklich als solche zu bezeichnen. Sie bedürfen zu ihrer
        Gültigkeit der schriftlichen Erwähnung in der Vertragsurkunde.
      </p>
      <h4>2. Vertragsbestandteile und Rangfolge</h4>
      <p>
        Bei Widersprüchen hat der Vertragsinhalt gem. Vertragsurkunde Vorrang
        vor diesen AGBs.
      </p>
      <h4>3. Angebot</h4>
      <p>
        3.1 Das Einreichen von Angeboten des Auftragnehmers im VMP erfolgt
        unentgeltlich.
      </p>
      <p>
        3.2 Weicht das Angebot von der Offertanfrage der ASI ab, so weist der
        Auftragnehmer ausdrücklich darauf hin.
      </p>
      <p>
        3.3 Soweit in der Offertanfrage nichts anders angefragt, bleibt der
        Auftragnehmer vom Datum der Einreichung des Angebotes an während drei
        Monaten gebunden.
      </p>
      <p>
        3.4 Bis zur Unterzeichnung der Vertragsurkunde oder der digitalen
        Annahme des Angebots (Bestellung) durch die ASI können sich die Parteien
        ohne finanzielle Folgen von den Vertragsverhandlungen zurückziehen.
        Vorbehalten bleibt die Bindung der Auftragnehmer an ihr Angebot gemäss
        Ziff. 3.3.&nbsp;
      </p>
      <h4>4. Produkte und Leistungen, Lieferungen</h4>
      <p>
        4.1 Art, Umfang und Eigenschaften der Produkte und Leistungen etc.
        werden in der Vertragsurkunde geregelt. Darin kann auf weitere Dokumente
        verwiesen werden.
      </p>
      <p>
        4.2 Nutzen- und Gefahrenübergang erfolgen mit Entgegennahme der Leistung
        oder der Lieferung durch die ASI am Erfüllungsort (Ziff. 17).
      </p>
      <h4>5. Ausführung</h4>
      <p>
        5.1 Die Vertragspartner zeigen sich gegenseitig unverzüglich alle
        Umstände aus ihren Bereichen an, welche die vertragsgemässe Erfüllung
        gefährden oder gefährden könnten.
      </p>
      <p>
        5.2 Die Ausführung von Leistungen erfolgt unter Anwendung anerkannter
        Methoden und aktueller Standards sowie unter Beachtung aller von der ASI
        vertragsgemäss erteilten Weisungen.
      </p>
      <p>
        5.3 Der Auftragnehmer informiert die ASI regelmässig über den
        Fortschritt der Arbeiten und holt bei Unklarheiten erforderliche
        Vorgaben der ASI ein.
      </p>
      <h4>6. Beizug von Subunternehmern</h4>
      <p>
        Der Auftragnehmer zieht Subunternehmer nur mit schriftlicher Genehmigung
        der ASI bei. Die ASI darf die Genehmigung nicht ohne begründeten Anlass
        verweigern, wobei unter dem Amtsgeheimnis stehende Gründe nicht offen
        gelegt werden. Der Auftragnehmer bleibt gegenüber der ASI für das
        Erbringen der Leistungen verantwortlich.
      </p>
      <h4>7. Vergütung</h4>
      <p>
        7.1 Der Auftragnehmer erbringt die Leistungen zu Festpreisen oder nach
        Aufwand mit oberer Begrenzung der Vergütung (Kostendach), wobei er in
        seinem Angebot die Kostenarten und -sätze, welche nunmehr
        Vertragsbestandteil geworden sind, bekannt gegeben hat.
      </p>
      <p>
        7.2 Erbringt der Auftragnehmer Leistungen nach Aufwand, so liefert er
        zusammen mit der Rechnung einen durch die zuständige Person der ASI
        visierten Rapport. Der Rapport nennt pro Tag die Leistungen und den
        Aufwand jeder eingesetzten Person seitens des Auftragnehmers.
      </p>
      <p>
        7.3 Die vereinbarte Vergütung deckt alle Leistungen ab, die zur
        gehörigen Vertragserfüllung notwendig sind.
      </p>
      <p>
        7.4 Die Rechnungsstellung erfolgt nach Erbringung bzw. ─ sofern
        vertraglich vorgesehen ─ nach der Abnahme der abgerechneten Leistungen.
        Rechnungen sind innert 30 Tagen nach Erhalt zu bezahlen. Vorbehalten
        bleiben abweichende Vereinbarungen in der Vertragsurkunde, insbesondere
        ein allfälliger Zahlungsplan.
      </p>
      <p>
        7.5 Werden Teilzahlungen (Anzahlungen und Abschlagszahlungen)
        vereinbart, kann die ASI vom Auftragnehmer die Sicherstellung des
        Betrags auf geeignete Weise (z.B. mittels Bankgarantie) verlangen.
      </p>
      <p>
        7.6 Eine Anpassung der Vergütung während der Vertragslaufzeit erfolgt
        nur, falls dies in der Vertragsurkunde festgehalten ist.
      </p>
      <h4>8. Leistungsänderungen</h4>
      <p>
        8.1 Der Auftragnehmer orientiert die ASI über Verbesserungen und
        Weiterentwicklungen, die aus technischen oder wirtschaftlichen Gründen
        eine Änderung der Leistungen angezeigt erscheinen lassen. Des Weiteren
        informiert er die ASI über die Folgen einer Änderung von Leistungen auf
        den bestehenden Fortschritt.
      </p>
      <p>
        8.2 Beide Vertragspartner können schriftlich Änderungen der vereinbarten
        Leistungen über die verantwortlichen Personen (siehe Ziff. 11.2)
        beantragen. Sind Auswirkungen auf Kosten oder Termine zu erwarten, sind
        die Leistungsänderungen in einem zwischen den Vertragspartnern zu
        vereinbarenden Zeitrahmen zu offerieren. Dieses Angebot umfasst die
        Einschätzung der Realisierbarkeit, die Umschreibung der notwendigen
        Zusatzleistungen und die Konsequenzen auf die Leistungen, insbesondere
        bezüglich der Kosten und Termine. Es enthält einen Hinweis, ob die
        Leistungserbringung bis zum Entscheid über die Vornahme der Änderung
        ganz oder teilweise unterbrochen werden soll und wie sich ein solcher
        Unterbruch auf die Vergütung und die Termine auswirken würde. Für solche
        Angebote erhält der Auftragnehmer nur dann eine Vergütung, wenn dies
        ausdrücklich vereinbart wurde.
      </p>
      <p>
        8.3 Ohne gegenteilige Vereinbarung setzt der Auftragnehmer während der
        Prüfung von Änderungsvorschlägen seine Arbeiten vertragsgemäss fort.
      </p>
      <p>
        8.4 Die Leistungsänderung und allfällige Anpassungen von Vergütung,
        Terminen und anderen Vertragspunkten werden vor der Ausführung in einem
        Nachtrag zur Vertragsurkunde schriftlich festgehalten. Die Anpassung der
        Vergütung berechnet sich nach den Ansätzen im Zeitpunkt der Vereinbarung
        der Änderung. Für die Vereinbarung von Änderungen, welche keinen
        Einfluss auf Kosten, Termine und Qualität haben, genügt die
        Unterzeichnung eines Änderungsprotokolls durch die verantwortlichen
        Personen der ASI und des Auftragnehmers (siehe Ziff. 11.2).
      </p>
      <h4>9. Rechtsgewährleistung</h4>
      <p>
        9.1 Der Auftragnehmer leistet Gewähr dafür, dass er mit seinem Angebot
        und seinen Leistungen keine anerkannten Schutzrechte Dritter verletzt.
      </p>
      <p>
        9.2 Ansprüche Dritter wegen Verletzung von Schutzrechten wehrt der
        Auftragnehmer auf eigene Kosten und Gefahr ab. Die ASI gibt solche
        Forderungen dem Auftragnehmer schriftlich und ohne Verzug bekannt und
        überträgt ihm, soweit nach dem anwendbaren Prozessrecht möglich, die
        Führung eines allfälligen Prozesses und die Ergreifung von
        entsprechenden angemessenen Massnahmen für die gerichtliche oder
        aussergerichtliche Erledigung des Rechtsstreits. Unter diesen
        Voraussetzungen übernimmt der Auftragnehmer die der ASI im Zusammenhang
        mit dem Rechtsstreit entstandenen Gerichts-, Anwalts- und sonstigen
        angemessenen Kosten und auferlegten Lizenzvergütungen, Genugtuungs- und
        Schadenersatzleistungen, unter der Voraussetzung, dass die
        Schutzrechtsverletzung nicht auf eine vertragswidrige Nutzung der
        Leistungen des Auftragnehmers durch die ASI zurückzuführen ist.
      </p>
      <p>
        9.3 Wird eine Klage wegen Verletzung von Schutzrechten eingereicht oder
        eine vorsorgliche Massnahme beantragt, so kann der Auftragnehmer auf
        eigene Kosten, nach seiner Wahl entweder der ASI das Recht verschaffen,
        die Leistungen frei von jeder Haftung wegen Verletzung von Schutzrechten
        zu benutzen oder die Leistungen anzupassen bzw. durch andere zu
        ersetzen, welche die vertraglichen Anforderungen gleichwertig erfüllen.
        Sofern diese Möglichkeiten nicht bestehen, wird der Auftragnehmer die
        bezahlte Vergütung für die Leistung zurückerstatten unter Abzug eines
        anteilmässigen Betrags für die bereits erfolgte Nutzung der Leistung
        bezogen auf die Gesamtlaufzeit (der Leistung) oder die übliche Nutzung
        (des Produkts). Vorbehalten bleibt ferner die Haftung des Auftragnehmers
        für allfällige Schäden gemäss Ziff. 14.
      </p>
      <h4>10. Informationssicherheit, Geheimhaltung und Datenschutz</h4>
      <p>
        10.1 Die Vertragspartner verpflichten sich zur Geheimhaltung von
        Tatsachen und Daten, die weder offenkundig, noch allgemein zugänglich
        sind. Diese Pflicht ist durch die einziehende Vertragspartei auch
        einbezogenen Dritten, z.B. Subunternehmern, aufzuerlegen. Im
        Zweifelsfall sind Tatsachen und Daten vertraulich zu behandeln. Die
        Geheimhaltungspflichten bestehen schon vor Vertragsabschluss und auch
        nach Beendigung des Vertragsverhältnisses bzw. nach der Erfüllung der
        vereinbarten Leistungen fort. Vorbehalten bleiben gesetzliche
        Aufklärung- und Informationspflichten.
      </p>
      <p>
        10.2 Der Auftragnehmer darf den wesentlichen Inhalt der Offertanfrage
        potentiellen, zu beauftragenden Subunternehmern bekanntgeben, hat die
        Offertanfrage aber ansonsten vertraulich zu behandeln.
      </p>
      <p>
        10.3 Werbung und Publikationen über projektspezifische Leistungen
        bedürfen der vorgängigen schriftlichen Zustimmung der ASI, ebenso deren
        Nennung als Referenz.
      </p>
      <p>
        10.4 Verletzt ein Vertragspartner oder ein von ihm einbezogener Dritter
        vorstehende Geheimhaltungspflichten, so schuldet der verletzende
        Vertragspartner dem anderen Vertragspartner eine Konventionalstrafe,
        sofern er nicht beweist, dass weder ihn, noch einbezogene Dritte ein
        Verschulden trifft. Diese beträgt je Fall 10% der gesamten Vergütung,
        höchstens jedoch CHF 50‘000 je Fall. Die Bezahlung der
        Konventionalstrafe befreit nicht von den Geheimhaltungspflichten.
        Schadenersatzansprüche gemäss allgemeinen Haftungsgrundsätzen (OR 97
        ff.) bzw. Ziff. 14 bleiben vorbehalten; die Konventionalstrafe wird auf
        den allenfalls zu leistenden Schadenersatz angerechnet.
      </p>
      <p>
        10.5 Der Auftragnehmer verpflichtet sich und sein Personal zur
        Einhaltung der betrieblichen, technischen und sicherheitsrelevanten
        Vorschriften der ASI, insbesondere der Zugriffsvorgaben auf Systeme
        etc., sofern diese dem Aufragnehmer vor Vertragsabschluss schriftlich
        bekanntgegeben bzw. nachträglich vereinbart werden.
      </p>
      <p>
        10.6 Geltende Datenschutz- und Sicherheitsbestimmungen sowie die
        Vorschriften über das Amts- beziehungsweise Berufsgeheimnis (Art. 320
        bzw. 321 StGB) sind einzuhalten. Insbesondere ist der Auftragnehmer
        verpflichtet, an ihn weitergegebene oder ihm zugängliche Personendaten
        aus dem Bereich der ASI nur in dem Umfang und ausschliesslich zu
        denjenigen Zwecken zu bearbeiten, wie dies für die Vertragserfüllung
        notwendig ist. Die Parteien können weitere vertragliche Abmachungen,
        z.B. Vertraulichkeitsvereinbarungen, abschliessen.
      </p>
      <p>
        10.7 Die Beauftragte hält sämtliche vertraulichen Informationen der
        Auftraggeberin sowie den Inhalt des Vertrags während seiner Laufzeit und
        [drei] Jahre darüber hinaus geheim. Sie verpflichtet sich, die geltenden
        Datenschutzgesetze jederzeit einzuhalten.
      </p>
      <p>
        Bei Verlust von Daten oder vertraulichen Informationen ist die
        Auftraggeberin umgehend unter{' '}
        <a href="mailto:data-privacy@allianz.ch">data-privacy@allianz.ch</a>
        zu benachrichtigen und bei der Schadensbegrenzung ist gemäss Anweisungen
        der Auftraggeberin zu kooperieren. Die Beauftragte wird für sämtliche
        Schäden in diesem Zusammenhang haftbar.
      </p>
      <h4>11. Umweltschutz</h4>
      <p>
        Der Unternehmer ist bemüht keine Baustoffe oder Methoden zu benutzen,
        die Gesundheit, Sicherheit oder Umwelt gefährden. Zum Schutz der Umwelt
        hat er alle möglichen Vorkehrungen zu treffen.
      </p>
      <h4>12. Personaleinsatz</h4>
      <p>
        12.1 Der Auftragnehmer setzt zur Erbringung von Dienstleistungen, auch
        wenn es sich hierbei lediglich um eine Nebenleistung handelt, nur
        vertrauenswürdiges, sorgfältig ausgewähltes und gut ausgebildetes
        Personal zur Vertragserfüllung ein. Er ersetzt auf Verlangen der ASI
        innerhalb nützlicher Frist Personen, welche gemäss der Beurteilung der
        ASI nicht über die erforderlichen Fachkenntnisse verfügen oder sonst wie
        die Vertragserfüllung beeinträchtigen.
      </p>
      <p>
        12.2 Die Vertragspartner vereinbaren die Arbeitsorganisation und
        bezeichnen die verantwortlichen Personen.
      </p>
      <p>
        12.3 Die ASI kann in Fällen eines aus seiner Sicht erhöhten
        Schutzbedarfs vom Leistungserbringer verlangen, dass er Unterlagen über
        weitere Abklärungen bezüglich der von ihm eingesetzten Mitarbeitenden
        vorlegt (z.B. Strafregisterauszug). Einzelheiten werden im Vertrag
        geregelt.
      </p>
      <h4>13. Verzug</h4>
      <p>
        13.1 Die Vertragspartner kommen bei Nichteinhalten der in der
        Vertragsurkunde als verzugsbegründend vereinbarten Termine ohne Weiteres
        in Verzug, bei anderen Terminen nach Mahnung unter Einräumung einer
        angemessenen Nachfrist.
      </p>
      <p>
        13.2 Befindet sich der Auftragnehmer in Verzug, kann die ASI, wenn die
        Erfüllung auch nach Ablauf einer dem Auftragnehmer gesetzten,
        angemessenen Nachfrist noch nicht vollständig erfolgt ist, nach ihrer
        Wahl:
      </p>
      <ul>
        <li>
          auf die nachträgliche Erfüllung durch den Auftragnehmer beharren und
          bei Verschulden dem Auftragnehmer den Ersatz desjenigen Schadens
          geltend machen, der eine Folge der nicht vertragsgemässen Erfüllung
          ist, oder
        </li>
        <li>
          auf die nachträgliche Erfüllung des Vertrags verzichten und bei
          Verschulden des Auftragnehmers den Ersatz des aus der Nichterfüllung
          entstandenen Schadens geltend machen, oder
        </li>
        <li>
          auf die nachträgliche Erfüllung des Vertrags verzichten und den
          Vertrag vollständig oder teilweise rückwirkend auf den Zeitpunkt des
          Vertragsabschlusses aufheben, unter Rückabwicklung der bisher
          gegenseitig erbrachten, vom Rücktritt betroffenen gegenseitigen
          Leistungen, und bei Verschulden des Auftragnehmers den Ersatz
          desjenigen Schadens geltend machen, der der ASI aus dem Dahinfallen
          des Vertrags entstanden ist. Bei Dauerverträgen tritt an die Stelle
          der rückwirkenden Vertragsauflösung die ausserordentliche Beendigung
          des Vertrags mit sofortiger Wirkung.
        </li>
      </ul>
      <p>
        13.3 Kommt der Auftragnehmer in Verzug, schuldet er bezüglich der in der
        Vertragsurkunde entsprechend bezeichneten Termine eine
        Konventionalstrafe, sofern er nicht beweist, dass weder ihn, noch von
        ihm beauftragte Dritte ein Verschulden trifft. Diese beträgt pro
        Verzugs- und Verspätungstag 1 Promille, insgesamt aber höchstens 10% der
        gesamten Vergütung des entsprechenden Vertrags bei Einmalleistungen
        beziehungsweise der Vergütung für 12 Monate bei wiederkehrenden
        Leistungen. Die Konventionalstrafe ist auch dann geschuldet, wenn die
        Leistungen vorbehaltlos angenommen werden oder der Leistungsbezüger von
        den Rechtsbehelfen gemäss vorstehender Ziffer 12.2 Gebrauch macht. Die
        Bezahlung der Konventionalstrafe befreit den Auftragnehmer nicht von den
        anderen vertraglichen Verpflichtungen. Schadenersatzansprüche der ASI
        gemäss Ziff. 14 bleiben vorbehalten, die Konventionalstrafe wird auf den
        allenfalls zu leistenden Schadenersatz angerechnet.
      </p>
      <h4>14. Gewährleistung</h4>
      <p>
        14.1 Der Auftragnehmer gewährleistet, dass die von ihm gelieferten
        Produkte und werkvertraglichen Leistungen die vereinbarten Eigenschaften
        aufweisen, ferner diejenigen Eigenschaften, welche die ASI auch ohne
        besondere Vereinbarung nach dem jeweiligen Stand der Technik bei
        Vertragsabschluss (sofern sich aus dem Vertrag nicht etwas anderes
        ergibt) und in guten Treuen voraussetzen darf.
      </p>
      <p>
        14.2 Liegt ein Mangel vor, kann die ASI unentgeltliche Nachbesserung
        verlangen oder einen dem Minderwert entsprechenden Abzug von der
        Vergütung machen. Der Auftragnehmer behebt den Mangel innerhalb
        angemessener Frist und trägt alle daraus entstehenden Kosten.
      </p>
      <p>
        14.3 Hat der Auftragnehmer die verlangte Mängelbehebung nicht, nicht
        rechtzeitig oder nicht erfolgreich vorgenommen, kann die ASI einen dem
        Minderwert entsprechenden Abzug von der Vergütung vornehmen. Bei
        erheblichen Mängeln kann er stattdessen auch gemäss Ziff. 12.2 vorgehen.
      </p>
      <p>
        14.4 Mängel sind innerhalb von 60 Tagen nach Entdeckung zu beanstanden.
        Die Gewährleistungsrechte verjähren innerhalb von einem Jahr ab
        Ablieferung bzw. Abnahme. Nach der Behebung von beanstandeten Mängeln
        beginnen die Fristen für Ersatzteile neu zu laufen. Arglistig
        verschwiegene Mängel können während zehn Jahren ab Ablieferung bzw.
        Abnahme geltend gemacht werden.
      </p>
      <p>
        14.5 Nach Ablauf der Gewährleistungsfrist erbrachte Leistungen sind
        entgeltlich und erfolgen zu marktüblichen Bedingungen.
      </p>
      <p>
        14.6 Abweichende Garantieleistungen für Drittprodukte sind in der
        Vertragsurkunde zu regeln.
      </p>
      <h4>15. Haftung</h4>
      <p>
        15.1 Der Auftragnehmer haftet für den von ihm, seinen Hilfspersonen und
        einbezogenen Subunternehmern im Zusammenhang mit dem Vertragsverhältnis
        verursachten Schaden, wenn er nicht beweist, dass weder er, noch die
        Hilfspersonen/Subunternehmer ein Verschulden trifft. Ist in der
        Vertragskurkunde nicht etwas Abweichendes vereinbart, so ist die Haftung
        für leichte Fahrlässigkeit auf maximal CHF 1 Mio. pro Vertrag
        beschränkt.
      </p>
      <p>
        15.2 Ausgeschlossen ist, soweit gesetzlich möglich, die Haftung für
        entgangenen Gewinn.
      </p>
      <h4>16. Folgen der Beendigung des Vertragsverhältnisses</h4>
      <p>
        Die Vertragspartner regeln im Vertrag, welche im Rahmen des
        Vertragsverhältnisses zur Verfügung gestellte Betriebsmittel, Daten und
        Unterlagen bei Beendigung des Vertragsverhältnisses und innerhalb
        welcher Frist dem anderen Vertragspartner zurückzugeben oder
        unwiederbringlich zu vernichten sind.
      </p>
      <h4>17. Abtretung, Übertragung und Verpfändung</h4>
      <p>
        Rechte und Pflichten aus dem Vertragsverhältnis dürfen ohne vorherige
        schriftliche Zustimmung des Vertragspartners an Dritte weder abgetreten,
        übertragen, noch verpfändet werden. Die ASI wird die Zustimmung zur
        Abtretung und Verpfändung von Forderungen durch den Auftragnehmer nur in
        begründeten Fällen verweigern.
      </p>
      <h4>18. Erfüllungsort</h4>
      <p>
        Erfüllungsort für die Leistungen des Auftragnehmers ist der in der
        Vertragsurkunde vereinbarte Ort, in Ermangelung eines solchen die
        Adresse des Leistungsbezügers.
      </p>
      <h4>19. Anwendbares Recht und Gerichtsstand</h4>
      <p>
        19.1 Auf das Vertragsverhältnis ist schweizerisches Recht anwendbar.
      </p>
      <p>
        19.2 Die Bestimmungen des Wiener Kaufrechtes (Übereinkommen der
        Vereinigten Nationen über Verträge über den internationalen Warenkauf,
        abgeschlossen in Wien am 11.4.1980) werden wegbedungen.
      </p>
      <p>
        19.3 Gerichtsstand ist der Sitz der ASI, sofern im Vertrag nicht etwas
        Abweichendes festgehalten ist.
      </p>
      <p>
        In begründeten Fällen kann von vorstehenden Regelungen im Kapitel{' '}
        <i>C Allgemeine Geschäftsbedingungen</i> (im Einzelfall) abgewichen
        werden.
      </p>
      <h2>D) Integeres Verhalten und Antikorruption</h2>
      <p>
        Im Zusammenhang mit dem Abschluss des Vertrags unterzieht sich die
        Beauftragte einer regelmässigen Integritätsprüfung („Vendor Integrity
        Screening, VIS“), welche wesentliche Grundlage dieses Vertrags
        darstellt. Gemäss Anweisungen der Auftraggeberin kommt die Beauftragte
        einer digitalen Abfrage des VIS ordnungsgemäss nach.
      </p>
      <p>
        Die Parteien verpflichten sich, nicht gegen geltendes Korruptionsrecht
        zu verstossen. Dies gilt insbesondere für unrechtmässige Zahlungen an
        Staatsbedienstete oder Behördenvertreter und für unangemessene Geschenke
        an Mitarbeitende oder Dritte/Unterbeauftragte oder von letzteren an eine
        der Parteien.
      </p>
      <h2>E) Geheimhaltung und Datenschutz</h2>
      <p>
        Die Beauftragte hält sämtliche vertraulichen Informationen der
        Auftraggeberin sowie den Inhalt des Vertrags während seiner Laufzeit und
        [drei] Jahre darüber hinaus geheim. Sie verpflichtet sich, die geltenden
        Datenschutzgesetze jederzeit einzuhalten.
        <br />
        Bei Verlust von Daten oder vertraulichen Informationen ist die
        Auftraggeberin umgehend unter{' '}
        <a href="mailto:data-privacy@allianz.ch">data-privacy@allianz.ch</a> zu
        benachrichtigen und bei der Schadensbegrenzung ist gemäss Anweisungen
        der Auftraggeberin zu kooperieren. Die Beauftragte wird für sämtliche
        Schäden in diesem Zusammenhang haftbar.
      </p>
    </div>
  </Card>
);

export default TermsAndConditions;
