/** @format */

import generator from './generator';

export const auth = {
  AUTH_REMOVE: 'AUTH_REMOVE',
  AUTH_ADD: 'AUTH_ADD',
  AUTH_SET_TOKEN: 'AUTH_SET_TOKEN',
};

export default generator('auth');

export const userRoles = {
  ADMINISTRATOR: 1,
  CENTRAL_PROCUREMENT: 2,
  PROPERTY_MANAGER: 3,
  AGENCY_MANAGER: 4,
  CONTRACTOR: 5,
  CONSTRUCTION_MANAGER: 6,
  HEAD_CONSTRUCTION_MANAGER: 7,
  ACCOUNT_MANAGER: 8,
};
