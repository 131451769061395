/** @format */

// API Constants
const SITE_URL = '/';

// Listing constants
const FETCH_LISTINGS = 'FETCH_LISTINGS';
const FETCH_LISTINGS_ERROR = 'FETCH_LISTINGS_ERROR';
const FETCH_LISTINGS_FAILED = 'FETCH_LISTINGS_FAILED';
const FETCH_LISTINGS_SUCCESS = 'FETCH_LISTINGS_SUCCESS';

const COLLAPSE_LISTINGS_TOGGLE = 'COLLAPSE_LISTINGS_TOGGLE';

const TOGGLE_CARD_FOOTER_DISPLAY = 'TOGGLE_CARD_FOOTER_DISPLAY';

const FETCH_IGNORED_LISTINGS = 'FETCH_IGNORED_LISTINGS';
const FETCH_IGNORED_LISTINGS_SUCCESS = 'FETCH_IGNORED_LISTINGS_SUCCESS';
const FETCH_IGNORED_LISTINGS_FAILED = 'FETCH_IGNORED_LISTINGS_FAILED';

// locale control
const SET_LOCALE = 'SET_LOCALE';
const FETCH_LOCALE = 'FETCH_LOCALE';
const FETCH_LOCALE_FAILED = 'FETCH_LOCALE_FAILED';
const SET_LOGIN_REDIRECT = 'SET_LOGIN_REDIRECT';
const SET_STATUS_BAR = 'SET_STATUS_BAR';

// modal
const TOGGLE_MODAL = 'TOGGLE_MODAL';

// auth actions
const SIGN_IN = 'HiBoards::AUTH_SIGN_IN';
const SIGN_OUT = 'HiBoards::AUTH_SIGN_OUT';

// notifications
const NOTIFICATION_IN = 'HiServer::NotificationInbound';
const NOTIFICATION_BATCH_INCOMING = 'HiServer::NOTIFICATION_BATCH_INCOMING';

export default {
  API: {
    SITE_URL,
  },
  APP: {
    SET_LOCALE,
    FETCH_LOCALE,
    FETCH_LOCALE_FAILED,
    SET_LOGIN_REDIRECT,
    SET_STATUS_BAR,
  },
  AUTH: {
    SIGN_IN,
    SIGN_OUT,
  },
  LISTINGS: {
    FETCH_LISTINGS,
    FETCH_LISTINGS_ERROR,
    FETCH_LISTINGS_FAILED,
    FETCH_LISTINGS_SUCCESS,
    FETCH_IGNORED_LISTINGS,
    FETCH_IGNORED_LISTINGS_FAILED,
    FETCH_IGNORED_LISTINGS_SUCCESS,
    TOGGLE_CARD_FOOTER_DISPLAY,
    COLLAPSE_LISTINGS_TOGGLE,
  },
  MODAL: {
    TOGGLE_MODAL,
  },
  SERVER: {
    NOTIFICATION_IN,
    NOTIFICATION_BATCH_INCOMING,
  },
};
