/** @format */

import React from 'react';

import Card from '../../../../../components/Card';
import l from '../../../../../helpers/locale';
import { parseDate } from '../../../../../helpers/dates';

const GeneralPanel = ({ status, lastChange, langCode }) => (
  <Card title="GENERAL-title" isOpen canCollapse={false}>
    <div className="gw">
      <div className="g g-1/2">
        <div className="push--small--bottom">
          <span className="form__label--small">{l('ACCOUNTS-status')}</span>
          <p className="text--dk--flushed">{l(`NOMINATION_STATE-${status}`)}</p>
        </div>
      </div>
      <div className="g g-1/2">
        <div className="push--small--bottom">
          <span className="form__label--small">{l('CONTACT-last_login')}</span>
          <p className="text--dk--flushed">{parseDate(lastChange, 'LL')}</p>
        </div>
      </div>
      <div className="g g-1/2">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-select_language')}
          </span>
          <p className="text--dk--flushed">
            {(langCode === 'fr' && 'Français') ||
              (langCode === 'de' && 'Deutsch') ||
              (langCode === 'it' && 'Italiano') ||
              (langCode === 'en' && 'English')}
          </p>
        </div>
      </div>
    </div>
  </Card>
);

export default GeneralPanel;
