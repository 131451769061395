/** @format */

// @flow

type RequestShape = {
  fetching: boolean,
  failed: boolean,
  success: boolean,
};

type StateShape = {
  request: RequestShape,
  payload: Object,
  form: Object,
};

type PropsShape = {
  type: string,
  payload?: Object,
  error?: Object,
};

const request: RequestShape = {
  fetching: false,
  failed: false,
  success: false,
};

const initialState: StateShape = {
  request: {
    ...request,
  },
  payload: {},
  form: {},
};

export default function ResetPasswordReducer(
  state: StateShape = initialState,
  { type, payload, error }: PropsShape,
): StateShape {
  switch (type) {
    case 'RESET_PASSWORD_REQUEST_SUCCESS':
      return {
        ...state,
        request: {
          ...state.request,
          success: true,
        },
        payload,
      };
    case 'RESET_PASSWORD_REQUEST_FETCHING':
      return {
        ...state,
        request: {
          ...state.request,
          fetching: true,
        },
      };
    case 'RESET_PASSWORD_REQUEST_FAILED':
      return {
        ...state,
        request: {
          ...state.request,
          failed: true,
        },
        payload: (error && error.data && error.data.payload) || {},
      };
    default:
      return state;
  }
}
