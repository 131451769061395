export default () => {
  // this doesn't work for AsideSlides
  const container = document.querySelector('.app-layout__body__main');
  const currentScroll = container.scrollTop;

  if (currentScroll > 0) {
    if (typeof container.scrollTo === 'function') {
      container.scrollTo(0, 0);
    } else {
      container.scrollTop = 0;
    }
  }
};
