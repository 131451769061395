/** @format */

// @flow

import Constants from './constants.js';

const {
  success,
  failed,
  fetching,
  UPDATE_LIST,
  UPDATE_PARAMS_SORT,
  UPDATE_PARAMS_SEARCH,
  UPDATE_PARAMS_PER_PAGE,
  UPDATE_PARAMS_CLEAR,
} = Constants;

type PaginationDataShape = {
  current_page: number,
  from: number,
  last_page: number,
  path: number,
  next_page_url: string,
  prev_page_url: string,
};

type RequestShape = {
  loading: boolean,
  success: boolean,
  failed: boolean,
};

type DataShape =
  | {
      // properties
      property_id: string,
      street: string,
      post_city: string,
      type: string,
    }
  | {
      // houses
      house_id: string,
      property_id: string,
      type: string,
      street: string,
      post_city: string,
      tenants: Array<string>,
    }
  | {
      // assets
      asset_id: string,
      house_id: string,
      post_city: string,
      property_id: string,
      street: string,
      tenants: Array<string>,
      type: string,
    };

type StateShape = {
  properties: {
    pager: {
      [number]: DataShape,
    },
    pagination: PaginationDataShape,
    request: RequestShape,
    params: {
      sort: { [string]: [string] },
      search: string,
      perPage: string | number,
    },
  },
  houses: {
    pager: {
      [number]: DataShape,
    },
    pagination: PaginationDataShape,
    request: RequestShape,
    params: {
      sort: { [string]: [string] },
      search: string,
      perPage: string | number,
    },
  },
  assets: {
    pager: Array<DataShape>,
    pagination: PaginationDataShape,
    request: RequestShape,
    params: {
      sort: { [string]: [string] },
      search: string,
      perPage: string | number,
    },
  },
};

const baseObjectState = {
  params: {
    sort: {},
    search: '',
    perPage: '',
  },
  data: {},
  pagination: {
    current_page: 0,
    from: 0,
    last_page: 0,
    next_page_url: '',
    prev_page_url: '',
  },
  request: {
    loading: false,
    success: false,
    failed: false,
  },
};

const initialState = {
  properties: { ...baseObjectState },
  houses: { ...baseObjectState },
  assets: { ...baseObjectState },
};

export default function AHPReducer(
  state: StateShape = initialState,
  {
    type,
    name,
  }: {
    type: string,
    name: 'properties' | 'houses' | 'assets',
    data: Object,
  },
): StateShape {
  switch (type) {
    case success:
    case UPDATE_LIST:
      return {
        ...state,
        [name]: {
          ...state[name],
          request: {
            loading: false,
            success: true,
            failed: false,
          },
          // pagination: {
          //   current_page: data.payload.current_page,
          //   from: data.payload.from,
          //   last_page: data.payload.last_page,
          //   path: data.payload.path,
          //   next_page_url: data.payload.next_page_url,
          //   prev_page_url: data.payload.prev_page_url,
          // },
          // data: data.payload,
          // rawData: data,
        },
      };
    case 'SIMPLE_LIST_UPDATE_DATA':
      return name === 'assets' || name === 'houses' || name === 'properties'
        ? {
            ...state,
            [name]: {
              ...state[name],
              // data: response,
            },
          }
        : state;
    case failed:
      return {
        ...state,
        [name]: {
          request: {
            loading: false,
            success: false,
            failed: true,
          },
        },
      };
    case fetching:
      return {
        ...state,
        [name]: {
          request: {
            loading: true,
            success: false,
            failed: false,
          },
        },
      };
    case UPDATE_PARAMS_SEARCH:
    case UPDATE_PARAMS_SORT:
    case UPDATE_PARAMS_PER_PAGE:
    case UPDATE_PARAMS_CLEAR:
    default:
      return state;
  }
}
