// @flow
import { State, Transition, Edge as EdgeBuilder } from 'async-state-machine';
import StateMachine from 'machines/reduxMachine.js';
import { transitions, States } from 'machines/constants';
import axios from 'helpers/axios';
import graphi from 'helpers/graphi';
import Transitions from 'views/singleProject/components/ProjectActions/transitions';
import { updateSimpleList } from 'components/ConnectedSimpleList/actions';
import { updateTitle } from 'components/RouteSlider/actions';
import { Names as RouteNames } from 'components/RouteSlider/routes/projects/constants';
import { getEditorContent } from 'components/WYSIWYG';

import * as CardMachines from '../cards/machines';
import getTitle from '../helper/getProjectTitle';
import redux from '../../../store';
import { setData } from '../actions';
import projectQuery from '../query';

const { update: UpdateAction } = Transitions;

// Define Transitions
const load_data = Object.create(Transition).setName(transitions.to_load);
const fail = Object.create(Transition).setName(transitions.to_fail);
const update = Object.create(Transition).setName(transitions.to_update);
const view = Object.create(Transition).setName(transitions.to_visible);
const recover = Object.create(Transition).setName(transitions.to_recover);
const edit = Object.create(Transition).setName(transitions.to_editing);
const update_error = Object.create(Transition).setName(
  transitions.to_update_error,
);

// Helper Functions
async function loadData(projectId) {
  let data;
  let result;
  try {
    data = await graphi(projectQuery(projectId), 'getProject');
    result = {
      response: data,
      error: false,
    };
  } catch (error) {
    result = {
      response: error,
      error: true,
    };
  }
  return result;
}

async function updateProject(projectId) {
  let data;
  let result;
  try {
    const formData = redux.getState().projectStore.form;
    const payload = {
      budget: formData.budget,
      concern_id: formData.concern.concern_id,
      concern_type: formData.concern.concern_type,
      contact_audit: formData.contact_audit,
      contact_audit_id: formData.contact_audit_id,
      contact_audit_type: formData.contact_audit_type,
      occasion: formData.occasion,
      project_owner_id: formData.project_owner.user_id,
      summary: getEditorContent('#js-redactor-editor'),
      target_date: formData.target_date,
      title: formData.title,
      internal_documents: formData.internal_documents.map(item => item.id),
      project_team_documents: formData.project_team_documents.map(
        item => item.id,
      ),
      project_supports: formData.project_supports.map(
        support => support.user_id,
      ),
    };
    data = await axios.post(`/api/1/projects/${projectId}/action/update`, {
      ...payload,
    });
    result = {
      response: data,
      error: false,
    };
  } catch (error) {
    result = {
      response: error,
      error: true,
    };
  }
  return result;
}

// Define States
const INIT = Object.create(State)
  .setName(States.INIT)
  .setOnEntry(function(params) {
    return Promise.all(
      Object.keys(CardMachines).map(key => CardMachines[key].start(params)),
    );
  })
  .setOnSuccess(function(params) {
    this.triggerTransition(load_data, { ...params });
  });

const LOADING = Object.create(State)
  .setName(States.LOADING)
  .setOnSuccess(async function(params) {
    let result = null;
    try {
      result = await loadData(params.project_id);
      if (!result) {
        throw new Error(`Did not find data for Project: ${params.project_id}`);
      }
    } catch (err) {
      throw new Error(err);
    }

    this.data = result;

    if (this.data.error) {
      this.triggerTransition(fail, this.data.response);
      return this.data;
    }

    redux.dispatch(
      updateTitle(
        RouteNames.PROJECT_VIEW,
        getTitle(this.data.response.data.data.project),
      ),
    );

    redux.dispatch(
      setData(this.data.response.data.data.project, {
        projectId: params.project_id,
      }),
    );
    this.triggerTransition(view, this.data.response);

    return this.data;
  });

const VIEW = Object.create(State)
  .setName(States.VIEW)
  .setOnSuccess(function() {
    const state = redux.getState();
    Object.keys(CardMachines).forEach(key => {
      CardMachines[key].triggerTransition(transitions.to_visible, {
        user: state.authStore.user,
        project: state.projectStore.data,
      });
    });
  });

const EDIT = Object.create(State)
  .setName(States.EDIT)
  .setOnSuccess(function() {
    const state = redux.getState();
    Object.keys(CardMachines).forEach(key => {
      CardMachines[key].triggerTransition(transitions.to_editing, {
        user: state.authStore.user,
        project: state.projectStore.data,
      });
    });
  });

const UPDATING = Object.create(State)
  .setName(States.UPDATING)
  .setOnEntry(async function(params: { project_id: number }) {
    redux.dispatch({
      type: UpdateAction.fetching,
      projectId: params.project_id,
    });

    try {
      this.data = await updateProject(params.project_id);
    } catch (err) {
      throw new Error(err);
    }

    return this.data;
  })
  .setOnSuccess(function(params) {
    if (this.data.error) {
      this.triggerTransition(update_error, {
        project_id: params.project_id,
        payload: this.data.response,
      });
    } else {
      redux.dispatch({
        type: UpdateAction.success,
        projectId: params.project_id,
      });
      redux.dispatch(updateSimpleList('project_list'));
      this.triggerTransition(load_data, { project_id: params.project_id });
    }
  });

const UPDATE_ERROR = Object.create(State)
  .setName(States.UPDATE_ERROR)
  .setOnEntry(function(params: { project_id: number, payload: Object }) {
    redux.dispatch({
      type: UpdateAction.failed,
      projectId: params.project_id,
      payload: params.payload,
    });
  });

const ERROR = Object.create(State).setName(States.ERROR);

function generateStateMachine() {
  const Edge = new EdgeBuilder();
  const Machine = new StateMachine('project')
    .registerEdge(
      Edge.new()
        .transition(fail)
        .from([INIT, LOADING, EDIT, VIEW])
        .to(ERROR),
    )
    .registerEdge(
      Edge.new()
        .transition(recover)
        .from([ERROR])
        .to(INIT),
    )
    .registerEdge(
      Edge.new()
        .transition(load_data)
        .from([INIT, VIEW, UPDATING])
        .to(LOADING),
    )
    .registerEdge(
      Edge.new()
        .transition(view)
        .from([LOADING, UPDATING, EDIT, UPDATE_ERROR])
        .to(VIEW),
    )
    .registerEdge(
      Edge.new()
        .transition(edit)
        .from([LOADING, VIEW])
        .to(EDIT),
    )
    .registerEdge(
      Edge.new()
        .transition(update)
        .from([EDIT, UPDATE_ERROR])
        .to(UPDATING),
    )
    .registerEdge(
      Edge.new()
        .transition(update_error)
        .from([UPDATING])
        .to(UPDATE_ERROR),
    )
    .setInitialState(INIT, {}, true);

  return Machine;
}

export default generateStateMachine;
