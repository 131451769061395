/** @format */

// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import GetSvg from 'components/GetSvg';
import { fC } from 'helpers/locale';
import getFlexStyle from 'functions/getFlexStyle';

import PaymentListItem from './PaymentListItem';

type PropsShape = {
  canCollapse?: boolean,
  groupRows: Object[],
  title: string,
  total: string | number,
  headings: Object[],
};

type StateShape = {
  open: boolean,
};

class PaymentListGroup extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    canCollapse: true,
    isOpen: true,
  };

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      open: props.isOpen,
    };
  }

  toggle = (): boolean => {
    this.setState((prevState: StateShape): StateShape => ({
      open: !prevState.open,
    }));
    return true;
  };

  render() {
    const { toggle } = this;
    const { canCollapse, title, total, headings } = this.props;
    return (
      <div className="simple-list__body__group">
        <div className="simple-list__body__group__header">
          <div style={getFlexStyle(headings.length, 4)}>
            {canCollapse ? (
              <span>
                <button
                  type="button"
                  onClick={toggle}
                  className="btn--text--base">
                  <GetSvg
                    svg={this.state.open ? 'angle_down' : 'angle_up'}
                    wrapperClass="simple-list__body__group__header__handle"
                  />
                  <span className="fs--h4 lh--reset">{title}</span>
                </button>
              </span>
            ) : (
              <span>{title}</span>
            )}
          </div>
          <div style={getFlexStyle(headings.length, 1)} className="fw--bold">
            {fC(total)}
          </div>
        </div>
        <div
          className={
            this.state.open
              ? 'simple-list__body__group__list'
              : 'simple-list__body__group__list--closed'
          }>
          {this.props.groupRows.map((item, itemKey) => (
            <PaymentListItem headings={headings} item={item} key={itemKey} />
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(PaymentListGroup);
