import React from 'react';
import validateEmail from 'functions/validateEmail';
import Form from 'components/Form';
import PageReady from 'components/AllianzPageReady';
import l from 'helpers/locale';
import languages, { defaultLang } from 'constants/lang';

type StateShape = {
  salutations: Array<*>,
};

type PropsShape = {
  callback: Function,
};

class SimpleNewForm extends React.Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      salutations: [],
    };
  }

  handleData = payload => {
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      salutations: payload.map((v: Object): Object => ({
        label: v.description,
        name: v.description,
        value: v.id,
      })),
    }));
  };

  validateForm = (
    payload: Object,
  ): {
    result: boolean,
    fields: Object,
  } => {
    const requiredFields = ['salutation_id', 'name', 'surname', 'email'];

    let validatorResponse = requiredFields.reduce(
      (acc: any, curr: any): any =>
        !payload[curr] ? { ...acc, [curr]: 'VALIDATION-required' } : acc,
      {},
    );

    if (payload.email.trim() && !validateEmail(payload.email)) {
      validatorResponse = {
        ...validatorResponse,
        email: 'FORM-email-description',
      };
    }

    return {
      result: Object.keys(validatorResponse).length === 0,
      fields: validatorResponse,
    };
  };

  render(): React$Element<*> {
    return (
      <PageReady
        ref={instance => {
          this.pageReady = instance;
        }}
        url="/api/1/salutations"
        onData={res => this.handleData(res.data.payload)}>
        <Form
          submitAct={(data: any): any =>
            this.props.callback(this.state.salutations)(data)
          }
          validateBefore={this.validateForm}
          wrappingClass="gw--small"
          sections={[
            {
              fields: [
                {
                  id: '1',
                  name: 'salutation_id',
                  type: 'select',
                  wrappingClass: 'g g-1/1',
                  innerClass: 'push--smaller--bottom',
                  label: l('NOMINATION_FORM-select_salutation'),
                  options: this.state.salutations,
                },
                {
                  id: '2',
                  name: 'name',
                  label: l('CONTACT-name'),
                  itemClass: 'g g-1/1',
                },
                {
                  id: '3',
                  name: 'surname',
                  label: l('ACCOUNTS-surname'),
                  itemClass: 'g g-1/1',
                },
                {
                  id: '4',
                  name: 'email',
                  label: l('CONTACT-email'),
                  itemClass: 'g g-1/1',
                },
                {
                  id: '5',
                  name: 'lang',
                  type: 'select',
                  wrappingClass: 'g g-1/1',
                  innerClass: 'push--smaller--bottom',
                  label: l('CONTRACTOR_FORM-select_language'),
                  options: languages,
                  default: defaultLang,
                },
              ],
            },
          ]}
        />
      </PageReady>
    );
  }
}

export default SimpleNewForm;
