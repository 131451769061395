import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'helpers/axios.js';
import l from 'helpers/locale';
import UntilReady from 'components/UntilReady';
import Card from 'components/Card';
import { publishFormFailed } from 'components/ConnectedActionBar/actions';
import { request } from 'constants/request';
import ONBOARDING from 'constants/onboardingStates.js';
import type { RequestShape } from 'constants/request';

import { setLocale } from '../../actions/app';

import Segments from './segments';

const { TitleCard, AccountForm } = Segments;

type PropsShape = {
  match: Object,
} & ReduxActionsShape;

type ReduxActionsShape = {
  publishFormFailed: Function,
  setLang: Function,
};

type StateShape = {
  public_id: string,
  invitation: Object,
  errors: Object,
  request: {
    get: RequestShape,
    submit: RequestShape,
  },
};

const defaultRequest = request;

class Invitation extends React.Component<PropsShape> {
  constructor(props: PropsShape) {
    super(props);

    this.state = {
      public_id: props.match.params.public_id,
      invitation: null,
      errors: {},
      request: {
        get: {
          ...defaultRequest,
        },
        submit: {
          ...defaultRequest,
        },
      },
    };
    this.onFilledForm = this.onFilledForm.bind(this);
  }

  state: StateShape;

  componentDidMount() {
    this.getData(true);
  }

  onFilledForm(form_data: Object) {
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      errors: {},
      request: {
        ...prevState.request,
        submit: {
          ...defaultRequest,
          fetching: true,
        },
      },
    }));

    const data = {
      user: {
        salutation_id: form_data.salutation_id,
        name: form_data.name,
        surname: form_data.surname,
        email: form_data.email,
        password: form_data.password,
        telephone: form_data.telephone,
        language:
          form_data.language === ''
            ? this.state.invitation.nomination_langcode
            : form_data.language,
      },
      profile: {
        company: form_data.company,
        company_2: form_data.company_2,
        street: form_data.street,
        pobox: form_data.pobox,
        zip: form_data.zip,
        city: form_data.city,
        country_id: form_data.country,
        email: form_data.company_email,
        uid_number: form_data.uid_number,
        iban: form_data.iban,
        bank_address: form_data.bank_address,
        website: form_data.website,
        phone: form_data.company_phone,
        trades: form_data.trades || false,
      },
    };

    // @todo discuss refactoring the API
    // to support a flat request/response structure
    axios
      .post(`/api/1/invitations/${this.state.public_id}`, data)
      .then((res: Object): any => this.handleSuccess('submit', res))
      .catch((err: Object): any => this.handleError('submit', err));
  }

  getData = (useNextLang: boolean = false) => {
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      request: {
        ...prevState.request,
        get: {
          ...defaultRequest,
          fetching: true,
        },
      },
    }));

    axios
      .get(`/api/1/invitations/${this.state.public_id}`)
      .then((res: Object): any => this.handleSuccess('get', res, useNextLang))
      .catch((err: Object): any => this.handleError('get', err));
  };

  getLangCode = (str: string): string => {
    switch (str) {
      case 'en':
        return 'en-GB';
      default:
        return `${str}-CH`;
    }
  };

  handleSuccess = (
    type: string,
    res: Object,
    useNextLang: boolean = false,
  ): any => {
    return this.setState(
      (prevState: StateShape): StateShape => ({
        ...prevState,
        invitation: res.data.payload,
        request: {
          ...prevState.request,
          [type]: {
            ...defaultRequest,
            success: true,
          },
        },
      }),
      () => {
        if (type === 'get' && useNextLang) {
          this.props.setLang(
            this.getLangCode(res.data.payload.nomination_langcode),
          );
        }
      },
    );
  };

  handleError = (type: string, err: Object): any => {
    let newErrors = {};
    if (type === 'submit') {
      if (
        err.data.payload.user.email &&
        err.data.payload.user.email[0] === 'unique'
      ) {
        newErrors = {
          ...newErrors,
          email: 'VALIDATION-email_unqiue',
        };
      }

      if (
        err.data.payload.user.password &&
        err.data.payload.user.password[0] === 'min_string 8'
      ) {
        newErrors = {
          ...newErrors,
          password: 'VALIDATION-password_min_len_8',
        };
      }

      if (err.data.payload.profile.email) {
        newErrors = {
          ...newErrors,
          company_email: 'VALIDATION-required',
        };
      }
    }

    if (Object.keys(newErrors).length > 0) {
      this.props.publishFormFailed();
    }

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      errors: newErrors,
      request: {
        ...prevState.request,
        [type]: {
          ...defaultRequest,
          failed: true,
        },
      },
    }));
  };

  render(): React$Element<*> {
    return (
      <div className="island--smaller--no-menu">
        <div className="wrap--large">
          <div className="gw--small">
            <div className="g g-1/1">
              <UntilReady
                ready={this.state.request.get.success}
                waiting={this.state.request.get.fetching}
                notReadyOrWaitingText={l('INVITATION-not_found')}>
                {this.state.invitation &&
                (this.state.invitation.state ===
                  ONBOARDING.nomination_signup_pending ||
                  this.state.invitation.state ===
                    ONBOARDING.invitation_signup_pending) ? (
                  <Fragment>
                    <div className="push--bottom">
                      <TitleCard callback={this.getData} />
                    </div>
                    <AccountForm
                      onFilled={this.onFilledForm}
                      invitation={this.state.invitation}
                      apiErrors={this.state.errors}
                      isProcessing={this.state.request.submit.fetching}
                    />
                  </Fragment>
                ) : (
                  <Card title="INVITATION-thanks-title" canCollapse={false}>
                    <p className="u-text__large--dk">
                      {l('INVITATION-thanks-body')}
                    </p>
                  </Card>
                )}
              </UntilReady>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (): any => ({});

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  setLang(lang: string): any {
    return dispatch(setLocale(lang));
  },
  publishFormFailed(): any {
    return dispatch(publishFormFailed());
  },
});

export default withRouter(connect(mapState, mapActions)(Invitation));
