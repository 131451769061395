// @flow
import React from 'react';
import GetSvg from 'components/GetSvg';

import ContactBody from './components/ContactBody';
import ContactFooter from './components/ContactFooter';
import ContactPopup from './components/ContactPopup';
import type { ContactType } from './types/ContactType';

export type Props = {
  contact: ContactType | null,
  label: string,
  isEditing: boolean,
  type:
    | 'owner'
    | 'scan_center'
    | 'allianz'
    | 'care_taker'
    | 'tenant'
    | 'delivery'
    | 'audit',
  update?: Function,
  create?: Function,
};

/**
 * returns a single contact block
 * and manages available transitions of this contact
 *
 * contacts are only editable in edit mode of the context in renderj
 * where the context can either be a job or project job
 */

function GraphQLContactBlock(props: Props): React$Element {
  const [loading, setLoading] = React.useState(false);

  function getPrefill() {
    // code smell - type specific code
    if (['delivery'].includes(props.type)) {
      return props.contact;
    }
    return undefined;
  }

  return (
    <div className="push--bottom">
      <label className="form__label--small" htmlFor="">
        {props.label}
      </label>
      {loading ? (
        <GetSvg svg="loading" wrapperClass="loader-base" />
      ) : (
        <ContactBody contact={props.contact} />
      )}
      {props.contact && props.isEditing ? (
        <ContactFooter
          type={props.type}
          contact={props.contact}
          update={props.update}>
          <ContactPopup
            type={props.type}
            setLoading={setLoading}
            update={props.update}
            create={props.create}
            prefill={getPrefill()}
          />
        </ContactFooter>
      ) : null}
    </div>
  );
}

GraphQLContactBlock.defaultProps = {
  create() {
    throw new Error('GraphQLContactBlock- Create Function does not Exist');
  },
  update() {
    throw new Error('GraphQLContactBlock- Update Function does not Exist');
  },
};

export default GraphQLContactBlock;
