import React from 'react';
import { connect } from 'react-redux';
import axios from 'helpers/axios';
import Offer from 'constants/offerStatus';
import DocumentUploader from 'components/connected/DocumentUploader';
import { removeFile } from 'components/connected/DocumentUploader/actions';
import DocConstants from 'components/connected/DocumentUploader/constants';
import { clearDocs } from '../actions';

const OFFER_STATUS = Offer.OfferStatusIDs;
const { DELETE, DOWNLOAD, VIEW } = DocConstants.DOCUMENT_ACTION;

type ReduxStateShape = {
  userRole: string | number,
  documents: Object[],
};

type PropsShape = {
  viewOnly: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  hasError: boolean,
  fetchedData: {
    jobId: number | string,
    offerId?: number | string,
    file?: Object,
  },
};

// documents are from redux, files are from api
function useDocuments(documents) {
  const [documentList, setDocumentList] = React.useState(documents || []);

  const addDocument = React.useCallback(
    payload =>
      setDocumentList(current => {
        const nextDocument = {
          ...payload.payload,
          document_id: payload.payload.id,
          state_machine: {
            status: payload.machine.current,
            available_transitions: payload.machine.transitions,
          },
        };
        return [...current, nextDocument];
      }),
    [setDocumentList],
  );

  const removeDocument = React.useCallback(
    id => {
      const index = documentList.findIndex(
        v => v.id === id || v.document_id === id,
      );

      return setDocumentList(current => [
        ...current.slice(0, index),
        ...current.slice(index + 1),
      ]);
    },
    [setDocumentList, documentList],
  );

  React.useEffect(() => {
    setDocumentList(documents || []);
  }, [documents, setDocumentList]);

  return [documentList, addDocument, removeDocument];
}

function useContextualEndpoint(data) {
  const getEndpoint = React.useCallback((job_id, offer_id) => {
    if (offer_id) {
      return `/api/1/jobs/${job_id}/offers/${offer_id}/action/add_document`;
    }

    return 'api/1/documents/action/add';
  }, []);

  const { offer_id } = data;
  const job_id = data.job?.job_id;

  const [endpoint, setEndpoint] = React.useState(() => {
    const result = getEndpoint(job_id, offer_id);
    return result;
  });

  React.useEffect(() => {
    setEndpoint(getEndpoint(job_id, offer_id));
  }, [job_id, offer_id, getEndpoint]);
  return endpoint;
}

function useCanUpload({ view, offer, status }) {
  const canUpload = React.useCallback(() => {
    if (!view && !offer) {
      // we are in creation
      return true;
    }
    // always allow upload in pending
    return status === OFFER_STATUS.DRAFT;
  }, [view, offer, status]);

  const [allowed, setAllowed] = React.useState(false);

  React.useEffect(() => {
    setAllowed(canUpload());
  }, [setAllowed, canUpload]);

  return allowed;
}

function SecondaryDocuments(props: PropsShape) {
  const { flushDocs } = props;

  const clear = React.useCallback(() => flushDocs(), [flushDocs]);

  React.useEffect(() => () => clear(), [clear]);

  const [documentList, addDocument, removeDocument] = useDocuments(
    props.fetchedData?.files,
  );

  // control form whenever list changes
  const { controlRef } = props;
  React.useEffect(() => {
    controlRef(
      'files',
      documentList.map(doc => doc.id),
    );
  }, [documentList, controlRef]);
  // --> end

  const api = useContextualEndpoint(props.fetchedData);

  const canUpload = useCanUpload({
    view: props.viewOnly,
    offer: props.fetchedData.offer_id,
    status: props.fetchedData.state_machine?.status || 'draft',
  });

  function removeDocumentAction(id) {
    axios.delete(`/api/1/documents/${id}/action/trash`).then(() => {
      props.removeDocument(id);
      removeDocument(id);
    });
    return id;
  }

  const controls = [
    {
      svgName: 'close',
      action: (id: string | number): any => removeDocumentAction(id),
      documentAction: DELETE,
      canDisplay: true,
    },
    {
      documentAction: DOWNLOAD,
      svgName: 'download',
      action: null,
      canDisplay: true,
    },
    {
      documentAction: VIEW,
      svgName: 'view',
      action: null,
      canDisplay: true,
    },
  ];

  return (
    <div className="g g-1/1">
      <div className="push--bottom">
        <DocumentUploader
          dataQeId="action-drop-secondary_offer_document"
          listName="create_offer_secondary"
          list={documentList}
          endpoint={api}
          data={{
            job_id: props.fetchedData.jobId,
            source_tag: 'offer_secondary',
          }}
          showUploader={canUpload}
          controls={controls}
          uploadCallback={addDocument}
        />
      </div>
    </div>
  );
}

const mapState = (state: Object): ReduxStateShape => ({
  userRole: state.authStore.user.role,
});

const mapActions = (dispatch: Function) => ({
  flushDocs(): any {
    return clearDocs('create_offer_secondary', dispatch);
  },
  removeDocument(id) {
    return dispatch(removeFile('create_offer_secondary', id));
  },
});

const SecondaryDocumentsConnected = connect(
  mapState,
  mapActions,
)(SecondaryDocuments);

export default (fetchedData: Object, viewOnly: boolean): Function => (
  props: Object,
): React$Element<*> => (
  <SecondaryDocumentsConnected
    {...props}
    fetchedData={fetchedData}
    viewOnly={viewOnly}
  />
);
