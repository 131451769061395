export function canUseRedactor() {
  let result = false;

  // eslint-disable-next-line no-undef
  if (Selection.prototype.containsNode) {
    result = true;
  }

  return result;
}
