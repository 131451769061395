// @flow
import StateMachine from 'machines/reduxMachine.js';
import { ids as StatusIDs } from 'constants/jobStatus';
import { transitions, PanelStates } from 'machines/constants.js';

import {
  State,
  Transition,
  Edge as EdgeBuilder,
  Conditions,
} from 'async-state-machine';
import JobConditions from '../../conditions';

const {
  isContractor,
  isPropertyManager,
  isConstructionManager,
  isAssigned,
  isInvitee,
  isOnProjectTeam,
  isProjectJob,
  isProjectOwner,
  isJobStateWithin,
  isCreating,
} = JobConditions;

const { and, not, or } = Conditions;

// Transitions for single job
export const to_editing = Object.create(Transition)
  .setName(transitions.to_editing)
  .addCondition((context, params) => {
    if (isCreating(params)) return true;

    return and([
      isJobStateWithin(params, [StatusIDs.DRAFT]),

      or([
        and([isPropertyManager(params), not(isProjectJob(params))]),
        and([
          isPropertyManager(params),
          isProjectJob(params),
          isOnProjectTeam(params),
        ]),
        and([
          isContractor(params),
          isProjectJob(params),
          isOnProjectTeam(params),
          not(or([isAssigned(params), isInvitee(params)])),
        ]),
        and([isConstructionManager(params), not(isProjectJob(params))]),
        and([
          isConstructionManager(params),
          isProjectJob(params),
          or([isOnProjectTeam(params), isProjectOwner(params)]),
        ]),
      ]),
    ]);
  });

export const to_visible = Object.create(Transition)
  .setName(transitions.to_visible)
  .addCondition((context, params) => {
    const result = or([
      not(isContractor(params)),
      and([
        isContractor(params),
        isProjectJob(params),
        isOnProjectTeam(params),
        or([not(isInvitee(params)), isAssigned(params)]),
      ]),
      and([
        isContractor(params),
        isAssigned(params),
        isJobStateWithin(params, [
          StatusIDs.CANCELLED,
          StatusIDs.GRANTED,
          StatusIDs.ACCEPTED,
          StatusIDs.EXECUTED,
          StatusIDs.COMPLETED,
        ]),
      ]),
    ]);

    return result;
  });

function agreementCardMachine() {
  const Edge = new EdgeBuilder();

  // States for Single Job
  const HIDDEN = Object.create(State).setName(PanelStates.HIDDEN);
  const VISIBLE = Object.create(State).setName(PanelStates.VISIBLE);
  const EDITING = Object.create(State).setName(PanelStates.EDITING);

  // Register Machine
  const Machine = new StateMachine('agreement_card')
    .registerEdge(
      Edge.new()
        .transition(to_editing)
        .from([VISIBLE, HIDDEN])
        .to(EDITING),
    )
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN, EDITING])
        .to(VISIBLE),
    )
    .setInitialState(HIDDEN, {});

  return Machine;
}

export default agreementCardMachine;
