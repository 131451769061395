import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import SingleJobView from 'views/singleJob';
import { connect } from 'react-redux';
import { updateTitle } from 'components/RouteSlider/actions';
import { SliderContext } from 'components/RouteSlider';
import { Names } from '../constants';

const ContractorJobViewRender = ({ setTitle }) => {
  useEffect(() => {
    setTitle('-');
    return () => setTitle('-');
  }, [setTitle]);

  const context = React.useContext(SliderContext);
  return (
    <SingleJobView
      routeName={Names.CONTRACTOR_JOB}
      backAction={context.backAction}
    />
  );
};

const mapActions = (dispatch: Function) => ({
  setTitle(title) {
    dispatch(updateTitle(Names.CONTRACTOR_JOB, title));
  },
});

export const ContractorJobView = withRouter(
  connect(null, mapActions)(ContractorJobViewRender),
);
