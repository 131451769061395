import AHPReducer from 'shared/jobs/AssetHousePropertySelectionList/reducers.js';

import { loginReducer } from 'components/Login/reducers';
import { forgotPasswordReducer } from 'components/ForgotPassword/reducers';
import simpleListReducer from 'components/ConnectedSimpleList/reducers';
import actionBarReducer from 'components/ConnectedActionBar/reducers';
import docsUploadReducer from 'components/connected/DocumentUploader/reducers';
import routeSliderReducer from 'components/RouteSlider/reducers';
import appReducer from './app';
import { userAuthReducer } from './auth';
import machineStore from './machine';

// eslint-disable-next-line
import { customerRelationshipReducer } from '../shared/contractor/SingleContractor/components/CustomerRelationshipEdit/reducer';

/* Views */
import viewAccountsReducer from '../views/accounts/reducers';
import viewSingleJobReducer from '../views/singleJob/reducers';
import resetPasswordReducer from '../views/resetPassword/reducers';
import getSvgStore from '../components/GetSvg/reducers';
import projectStore from '../views/singleProject/reducers';

export default {
  appStore: appReducer,
  authStore: userAuthReducer,
  docStore: docsUploadReducer,
  loginStore: loginReducer,
  forgotPasswordStore: forgotPasswordReducer,
  simpleListStore: simpleListReducer,
  accountsStore: viewAccountsReducer,
  assetHousePropertyStore: AHPReducer,
  jobStore: viewSingleJobReducer,
  svgStore: getSvgStore,
  actionBarStore: actionBarReducer,
  resetPasswordStore: resetPasswordReducer,
  customerRelationship: customerRelationshipReducer,
  routeSlider: routeSliderReducer,
  projectStore,
  machineStore,
};
