import React from 'react';

import {
  getDeliveryContacts,
  getCareTakerContacts,
  getTenantContacts,
  getAllianzContacts,
} from '../../queries';

function getQueryForContact(type) {
  switch (type) {
    case 'delivery':
      return getDeliveryContacts;
    case 'care_taker':
      return getCareTakerContacts;
    case 'tenant':
      return getTenantContacts;
    case 'allianz':
      return getAllianzContacts;
    default:
      throw new Error(
        `Get Query For Contact- Unknown Contact ${type} Encountered`,
      );
  }
}

function useGetContact(contactType, params) {
  const [loader, setLoader] = React.useState(true);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;

    (async () => {
      const contacts = await getQueryForContact(contactType)(params);
      if (mounted) {
        setLoader(false);
        setData(contacts);
      }
    })();

    return () => {
      mounted = false;
    };
  }, [contactType, params]);

  return [loader, data];
}

export default useGetContact;
