/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import ObjectPanel from 'shared/jobs/Panels/items/Object';
import { StateConditional } from 'machines/components/RenderConditional';
import MachineBuilder from './machine';
import { updateJobForm } from '../../actions';

type ReduxActionShape = {
  update: Function,
};

type ReduxStateShape = {
  data: Object,
  buildings: Array<*>,
  building_selected?: string,
  status: string,
  getNewInformation: ({
    contractor: string,
    target: string,
    targetId: string,
  }) => any,
};

export const ObjectCardMachine = MachineBuilder();

const ObjectCard = ({
  data,
  buildings,
  building_selected,
  status,
  panelEditMode,
  getNewInformation,
  update,
  machine_state,
  allowConcernChange,
}) => (
  <StateConditional state={machine_state} whenState={['VISIBLE', 'EDITING']}>
    <ObjectPanel
      data={{
        data,
        building_selected,
        buildings,
      }}
      updateField={update}
      jobState={status}
      editNow={panelEditMode}
      allowConcernChange={allowConcernChange}
      updateParent={getNewInformation}
    />
  </StateConditional>
);

ObjectCard.defaultProps = {
  building_selected: '',
};

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  allowConcernChange: !jobStore.data.job?.project_id,
  panelEditMode: machineStore.object_card === 'EDITING',
  machine_state: machineStore.object_card,
  data: jobStore.form.concern,
  building_selected: jobStore.form.building_id,
  buildings: jobStore.data.buildings,
  status: jobStore.data.job.info_status,
});

const mapActions = (dispatch: Function): ReduxActionShape => ({
  update(key: string, value: string): any {
    return dispatch(updateJobForm(key, value));
  },
});

export default connect(mapState, mapActions)(ObjectCard);
