import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import l, { fC } from 'helpers/locale';
import Card from 'components/Card';
import Chart from 'components/Chart';
import MapComp from 'components/Map/SafeMap';
import PageReady from 'components/AllianzPageReady';
import GraphQLPageReady from 'components/GraphQLPageReady';
import GenericNotifications, {
  localiseTitle,
} from 'components/GenericNotifications';
import convPropToInt from 'functions/convPropToInt.func';
import axios from 'helpers/axios';
import getDashboardUserDocuments from '../../query';
import DashboardItem from '../../components/DashboardItem.js';
import DashboardDocuments from '../../components/DashboardDocuments.js';

type StateShape = {
  data: {
    statistics: Object,
    map: Object,
    dashboard_user: Object,
    notifications: Object,
  },
};

type PropsShape = {
  history: Object,
  userEmail: string,
};

class ConstructionManagerDashboard extends Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      data: {
        statistics: {},
        map: {},
        dashboard_user: {},
        notifications: {},
      },
    };
  }

  handleSuccess = (data: Object): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      data: {
        ...prevState.data,
        ...data,
      },
    }));

  getAllNotifications = () =>
    axios
      .get('/api/1/view/dashboard/notifications')
      .then(res => res.data.notifications);

  render(): React$Element<*> {
    return (
      <GraphQLPageReady
        onData={res => this.handleSuccess(res.data.data)}
        queryId="getDashboardUserDocuments"
        queryString={getDashboardUserDocuments()}>
        <PageReady
          url="/api/1/view/dashboard"
          onData={res => this.handleSuccess(res.data.payload)}>
          <Card title="CM_DASHBOARD-projects_title" canCollapse={false}>
            <div style={{ height: '350px', overflow: 'hidden' }}>
              <MapComp
                markers={
                  this.state.data.map.projects
                    ? [
                        ...this.state.data.map.projects.map(
                          (item: Object): Object => ({
                            ...item,
                            color: 'blue',
                          }),
                        ),
                      ]
                    : []
                }
              />
            </div>
          </Card>
          <div className="gw">
            <div className="g--tall g-1/2">
              <Card title="CM_DASHBOARD-latest_activity" canCollapse={false}>
                <GenericNotifications
                  asideTitle={localiseTitle('')}
                  notifications={
                    this.state.data.notifications?.notifications || []
                  }
                  total={this.state.data.notifications?.count}
                  asideRequest={this.getAllNotifications}
                />
              </Card>
            </div>
            <div className="g--tall g-1/2">
              <Card title="CM_DASHBOARD-quick_action" canCollapse={false}>
                <div className="gw">
                  <div className="push--top g g-1/2">
                    <ul className="quick-actions">
                      <li>
                        <button
                          type="button"
                          data-qe-id="action-start-job_process"
                          className="btn--primary"
                          onClick={() => {
                            this.props.history.push(
                              '/dashboard/project-create',
                            );
                          }}>
                          {l('CM_DASHBOARD-new_project_btn')}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          data-qe-id="action-start-job_process"
                          className="btn--primary"
                          onClick={() => {
                            this.props.history.push('/dashboard/job-assign');
                          }}>
                          {l('CM_DASHBOARD-new_direct_award_btn')}
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          data-qe-id="action-start-job_offer_process"
                          className="btn--primary"
                          onClick={() => {
                            this.props.history.push('/dashboard/job-create');
                          }}>
                          {l('CM_DASHBOARD-new_offer_btn')}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="push--top g g-1/2">
                    <label className="form__label--small" htmlFor="__">
                      {l('DASHBOARD-predefined_searches')}
                    </label>
                    <div className="align--left">
                      <Link
                        to={{
                          pathname: '/projects',
                          state: {
                            search: `owner:${this.props.userEmail} running_projects:true`,
                          },
                        }}>
                        {l('CM_DASHBOARD_FILTER-my_open_project')}
                      </Link>
                    </div>
                    <div className="align--left">
                      <Link
                        to={{
                          pathname: '/jobs',
                          state: {
                            search: `pm:${this.props.userEmail} active:true`,
                          },
                        }}>
                        {l('CM_DASHBOARD_FILTER-my_open_job')}
                      </Link>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="g g-1/1">
              <Card title="CM_DASHBOARD-my_jobs_title" canCollapse={false}>
                <div className="gw">
                  <div className="g--tall g-1/2">
                    <DashboardItem>
                      <label className="form__label--small" htmlFor="__">
                        {l('DASHBOARD-top_trades_this_year')}
                      </label>
                      {this.state.data.statistics.trades?.length > 0 ? (
                        <Chart
                          data={this.state.data.statistics.trades.map(
                            convPropToInt('earnings'),
                          )}
                          numberKey="earnings"
                          categoryKey="name"
                        />
                      ) : null}
                    </DashboardItem>
                  </div>
                  <div className="g--tall g-1/2">
                    <div className="gw">
                      <div className="g--tall g-1/2">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('CONTRACTOR-total_volume_in')}
                          </label>
                          <p className="text--dk--flushed--large">
                            {fC(this.state.data.statistics.earnings)}
                          </p>
                        </DashboardItem>
                      </div>
                      <div className="g--tall g-1/2">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('CONTRACTOR-jobs_in')}
                            &nbsp;
                            {this.state.data.statistics.current_year}
                          </label>
                          <p className="text--dk--flushed--large">
                            {this.state.data.statistics.count_jobs || '-'}
                          </p>
                        </DashboardItem>
                      </div>
                      <div className="g--tall g-1/2">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('DASHBOARD-paid-jobs')}
                          </label>
                          <p className="text--dk--flushed--large">
                            {this.state.data.statistics.paid_jobs_count || '-'}
                          </p>
                        </DashboardItem>
                      </div>
                      <div className="g--tall g-1/2">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('DASHBOARD-main_project_occasion')}
                          </label>
                          <p className="text--dk--flushed--large">
                            {this.state.data.statistics.top_occasion || '-'}
                          </p>
                        </DashboardItem>
                      </div>
                      <div className="g--tall g-1/1">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('DASHBOARD-top_property_project_volume')}
                          </label>
                          <p className="text--dk--flushed--large">
                            {this.state.data.statistics.top_property || '-'}
                          </p>
                        </DashboardItem>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <DashboardDocuments
            documents={this.state.data?.dashboard_user?.documents || []}
          />
        </PageReady>
      </GraphQLPageReady>
    );
  }
}

const mapState = state => ({
  userEmail: state.authStore.user.email,
});

export default withRouter(connect(mapState)(ConstructionManagerDashboard));
