import React from 'react';
import { connect } from 'react-redux';
import { StateConditional } from 'machines/components/RenderConditional';
import Card from 'components/Card';
import type { NotificationShape } from 'components/NotificationContainer';
import GenericNotifications, {
  localiseTitle,
} from 'components/GenericNotifications';
import MachineBuilder from './machine';
import { useGetTitleFromProps } from '../../index';
import { getNotifications } from '../../query';

type ReduxStateShape = {
  notifications: Array<NotificationShape>,
};

export const NotificationCardMachine = MachineBuilder();

const NotificationCard = ({
  notifications,
  machine_state,
  total,
  jobId,
  trade,
}) => (
  <StateConditional state={machine_state} whenState={['VISIBLE']}>
    <Card title="NOTIFICATIONS-title" qeId="job_card-notifications">
      <GenericNotifications
        notifications={notifications}
        asideTitle={localiseTitle(useGetTitleFromProps(jobId, trade))}
        total={total}
        asideRequest={() => getNotifications(jobId)}
      />
    </Card>
  </StateConditional>
);

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.notification_card,
  notifications: jobStore.data.job.notifications,
  total: jobStore.data.job.notifications_count,
  jobId: jobStore.data.job?.job_id,
  trade: jobStore.data.job?.trade?.value,
});

export default connect(mapState)(NotificationCard);
