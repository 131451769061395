import React from 'react';
import { connect } from 'react-redux';
import fallbackScrollToTop from 'functions/fallbackScrollToTop.func';
import l from 'helpers/locale';
import { ActionBar } from '@bonlineza/b-lib';
import { flushList } from './actions';

/**
 * ConnectedActionBar
 * this component is ProjectSpecific along with the reducer which this depends on
 */

type PropsShape = {
  name: string,
};

const ConnectedActionBar = (props: PropsShape) => {
  const { list, name } = props;
  const currentList = list[name];
  return (
    <ActionBar
      {...props}
      focus={props.scrollToTop || fallbackScrollToTop}
      messages={currentList}
      formatter={l}
      visibleDuration={15000}
    />
  );
};

const mapActions = (dispatch: Function, { name }) => ({
  clearList() {
    return dispatch(flushList(name));
  },
});

const mapState = (state: Object) => ({
  list: state.actionBarStore,
});

export default connect(mapState, mapActions)(ConnectedActionBar);
