import React from 'react';
import GetSvg from 'components/GetSvg';
import CloseButton from 'components/AsideSlide/CloseButton';
import { AsideSlide } from '@bonlineza/b-lib';
import SimpleList, {
  SimpleListItemArrayFormatter,
  SimpleListItemRatingFormatter,
} from 'components/ConnectedSimpleList';

import l, { fC } from 'helpers/locale';

class ContractorSelector extends React.Component {
  static getDerivedPropsFromState(props) {
    if (!props.viewOnly) {
      return {
        hasError: props.hasError,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hasContractor: !!props.prefill,
      contractor: props.prefill ? props.prefill : [],
      hasError: props.hasError,
    };

    if (props.value) {
      props.controlRef('contractor_id', props.value);
    }
  }

  toggle = () =>
    this.setState(prevState => ({
      open: !prevState.open,
    }));

  handleSelect = ({ id, display_contractor }) => {
    this.props.controlRef('contractor_id', id);
    return this.setState({
      open: false,
      hasContractor: true,
      contractor: display_contractor,
      hasError: false,
    });
  };

  render() {
    return (
      <div
        className={`${(this.state.hasError && 'form__item--danger') ||
          'form__item '}`}>
        <div className="g g-1/1">
          <label className="form__item__label">{l(this.props.label)}</label>
          <div className="gw">
            {this.state.hasContractor ? (
              <div className="g g-1/1">
                {this.state.contractor
                  .filter(v => v !== '')
                  .map((v, k) => (
                    <span key={k}>
                      {v}
                      <br />
                    </span>
                  ))}
                <button
                  className="btn--text--primary"
                  data-qe-id="contractor_change_button"
                  onClick={this.toggle}
                  type="button">
                  {l('PROJECT_PAYMENTS-CONTRACTOR_CHANGE')}
                </button>
              </div>
            ) : (
              <div className="g g-1/1">
                <button
                  data-qe-id="contractor_selector_button"
                  onClick={this.toggle}
                  className={`button-container-bordered${
                    this.state.hasError ? '--danger' : ''
                  }`}
                  type="button">
                  <GetSvg
                    svg="add_file"
                    wrapperClass="button-container-bordered__inner"
                  />
                </button>
              </div>
            )}
            {(this.state.hasError && (
              <div className="g g-1/1">
                <div className="form__item__validation">
                  {l('VALIDATION-required')}
                </div>
              </div>
            )) ||
              null}
          </div>
        </div>
        <AsideSlide
          isOpen={this.state.open}
          title={l('PROJECT_PAYMENTS-CONTRACTORS_SELECT')}
          toggle={this.toggle}
          toggleButton={() => <CloseButton onClick={this.toggle} />}>
          <SimpleList
            name="payment_contractor_list"
            api="/api/1/view/contractors"
            clickAct={this.handleSelect}
            headings={[
              {
                name: 'name',
                sortable: true,
                text: l('CONT-COL-name'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'details',
                sortable: true,
                text: l('CONT-COL-address'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'rating',
                sortable: true,
                text: l('CONT-COL-rating'),
                customFormatter: SimpleListItemRatingFormatter,
              },
              {
                name: 'display_status',
                sortable: false,
                text: l('CONT-COL-status'),
                customFormatter: SimpleListItemArrayFormatter(true),
              },
              {
                name: 'earnings',
                sortable: false,
                text: l('CONT-COL-earnings'),
                customFormatter: fC,
                align: 'right',
              },
            ]}
            clickPropTwo="details"
            bgcAlt
          />
        </AsideSlide>
      </div>
    );
  }
}

export default ContractorSelector;
