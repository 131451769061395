import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import GetSvg from 'components/GetSvg';
import Roles from 'constants/roles';
import { translateApiErrorMessages } from 'helpers/formatErrors';
import { setValue, setInitialValues } from './actions.js';

const Role = Roles.RoleIds;

type ReduxPropsShape = {
  userRole: string,
};

type ReduxActionsShape = {
  setReduxValue: (string, string) => any,
};

type PropsShape = {
  status: string,
  customerNumber: string,
  recommended: string,
} & ReduxActionsShape;

type StateShape = {
  edit: Object,
  hasError: {
    customer_status: boolean,
    customer_code: boolean,
    recommended: boolean,
  },
};

export const recommendationList = [
  {
    label: 'CONTRACTOR-recommendation-no_recommendation',
    name: 'recommended',
    id: 'radio-no_reccomendation',
    value: 'no_recommendation',
    svg: 'thumbs_up',
    svgClass: 'fc--base-lt',
  },
  {
    label: 'CONTRACTOR-recommendation-recommended',
    name: 'recommended',
    id: 'radio-recommended',
    value: 'recommended',
    svg: 'thumbs_up',
    svgClass: 'fc--primary',
  },
  {
    label: 'CONTRACTOR-recommendation-not_recommended',
    name: 'recommended',
    id: 'radio-not_recommended',
    value: 'not_recommended',
    svg: 'thumbs_down',
    svgClass: 'fc--primary',
  },
];

export const defaultRecommendation = recommendationList[0];

class CustomerRelationshipEdit extends React.Component<PropsShape, StateShape> {
  static getDerivedStateFromProps(props) {
    if (props.errors && Object.keys(props.errors).length > 0) {
      return {
        hasError: Object.keys(props.errors).reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: true,
          }),
          {},
        ),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    const initialFields = {
      customer_status: props.status,
      customer_code: props.customerNumber,
      recommended: props.recommended,
    };

    this.state = {
      edit: initialFields,
      hideCustomerNumber: !this.customerNumberDisplayConditions(props.status),
      hasError: {
        customer_status: false,
        customer_code: false,
        recommended: false,
      },
    };

    props.setReduxFields(initialFields);
  }

  getReccomendationList = () => {
    return recommendationList;
  };

  getCustomerStatusList = () => {
    let statusList = [
      {
        label: 'CONTRACTOR-customer_state-current',
        name: 'customer_status',
        id: 'customerStatus_current',
        value: 'current',
        svg: 'eagle',
        svgClass: 'fc--primary',
      },
      {
        label: 'CONTRACTOR-customer_state-former',
        name: 'customer_status',
        id: 'customerStatus_former',
        value: 'former',
        svg: 'eagle',
        svgClass: 'fc--quaternary',
      },
      {
        label: 'CONTRACTOR-customer_state-none',
        name: 'customer_status',
        id: 'customerStatus_none',
        value: 'none',
        svg: 'eagle',
        svgClass: 'fc--base-lt',
      },
    ];

    if (
      [Role.CENTRAL_PROCUREMENT, Role.ADMINISTRATION].includes(
        this.props.userRole,
      )
    ) {
      statusList = [
        ...statusList,
        {
          label: 'CONTRACTOR-customer_state-unassigned',
          name: 'customer_status',
          id: 'customerStatus_unassigned',
          value: 'unassigned',
          svg: 'eagle',
          svgClass: 'fc--base-lt',
        },
      ];
    }

    return statusList;
  };

  getHideCustomerNumber = () =>
    !this.customerNumberDisplayConditions(this.state.edit.customer_status);

  handleToggleChange = (name, toggle): any => {
    this.handleEditChange(name, { target: { value: toggle.checked } });
  };

  customerNumberDisplayConditions = status => {
    const statesShown = ['former', 'current'];
    const statesHidden = ['unassigned', 'none'];
    return statesShown.includes(status) && !statesHidden.includes(status);
  };

  handleEditChange = (name, event) => {
    const nextValue = event.target.value;

    this.setState(
      prevState => ({
        ...prevState,
        edit: {
          ...prevState.edit,
          [name]: nextValue,
        },
        hasError: {
          [name]: false,
        },
      }),
      () =>
        this.setState({
          hideCustomerNumber: this.getHideCustomerNumber(),
        }),
    );

    return this.props.setReduxValue(name, nextValue);
  };

  doesFieldHaveError = fieldName => this.state.hasError[fieldName];

  render() {
    return (
      <div className="gw">
        <div className="g g-1/3">
          <div
            className={`form__item${
              this.doesFieldHaveError('customer_status') ? '--danger' : ''
            }`}>
            <label className="form__label--small" htmlFor="__">
              {l('CONTRACTOR-customer-status')}
            </label>

            <div className="form__item--group">
              {this.getCustomerStatusList().map((item, key) => (
                <div className="fl--row" key={key}>
                  <input
                    type="radio"
                    name="customerStatus"
                    className="push--small--right"
                    data-qe-id={`choice-customer_status-${item.value}`}
                    id={item.id}
                    value={item.value}
                    checked={item.value === this.state.edit.customer_status}
                    onChange={this.handleEditChange.bind(null, item.name)}
                  />
                  <label htmlFor={item.id} className="texticon--dk--flushed">
                    <span className={item.svgClass}>
                      <GetSvg svg={item.svg} wrapperClass="" />
                    </span>
                    <span className="text--dk--flushed">{l(item.label)}</span>
                  </label>
                </div>
              ))}
            </div>
            {this.doesFieldHaveError('customer_status') ? (
              <div className="form__item__validation">
                {l(`VALIDATION-${this.props.errors.customer_status[0]}`)}
              </div>
            ) : null}
          </div>
        </div>
        <div className="g g-1/3">
          <div
            className={`form__item${
              this.doesFieldHaveError('customer_code') ? '--danger' : ''
            }`}>
            <label className="form__label--small" htmlFor="__">
              {l('CONTRACTOR-customer-number')}
            </label>
            {!this.state.hideCustomerNumber ? (
              <input
                type="text"
                className="form__item__input"
                value={this.state.edit.customer_code}
                data-qe-id="input-customer_code"
                onChange={this.handleEditChange.bind(null, 'customer_code')}
              />
            ) : (
              '-'
            )}
            {this.doesFieldHaveError('customer_code') ? (
              <div className="form__item__validation">
                {translateApiErrorMessages(this.props.errors.customer_code[0])}
              </div>
            ) : null}
          </div>
        </div>
        <div className="g g-1/3">
          {[Role.AGENCY_MANAGER].includes(this.props.userRole) ? (
            <Fragment>
              <label className="form__label--small" htmlFor="__">
                {l('CONTRACTOR-agency-recommendation')}
              </label>

              <div className="form__item--group">
                {this.getReccomendationList().map((item, key) => (
                  <div className="fl--row" key={key}>
                    <input
                      type="radio"
                      name={item.name}
                      className="push--small--right"
                      data-qe-id={`choice-recommendation-${item.value}`}
                      id={item.id}
                      value={item.value}
                      checked={item.value === this.state.edit.recommended}
                      onChange={this.handleEditChange.bind(null, item.name)}
                    />
                    <label htmlFor={item.id} className="texticon--dk--flushed">
                      <span className="text--dk--flushed">{l(item.label)}</span>
                    </label>
                  </div>
                ))}
              </div>
              {this.doesFieldHaveError('recommended') ? (
                <div className="form__item__validation">
                  {translateApiErrorMessages(this.props.errors.recommended[0])}
                </div>
              ) : null}
            </Fragment>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state): ReduxPropsShape => ({
  userRole: state.authStore.user.role,
  errors: state.customerRelationship.errors,
});

const mapActionsToProps = (dispatch): ReduxActionsShape => ({
  setReduxValue(name, value) {
    return dispatch(setValue(name, value));
  },
  setReduxFields(fields) {
    return dispatch(setInitialValues(fields));
  },
});

export default connect(
  mapStateToProps,
  mapActionsToProps,
)(CustomerRelationshipEdit);
