import React, { useState } from 'react';
import { SingleDatePicker } from 'components/Dates';
import type { DateSelectorPropsShape } from '../types';

export default ({
  label,
  hasError,
  isEditing,
  placeholder,
  date,
  onChange,
  classSuffix,
  dataQeId = '',
  innerClass = '',
  enableOutsideDays = true,
  isOutsideRange = (): boolean => false,
  showBeforeTodayDifferently = true,
  format = 'LL',
}: DateSelectorPropsShape) => {
  const [isFocused, setIsFocused] = useState(false);
  const [currentDate, setCurrentDate] = useState(date);
  return (
    <div className="g g-1/1">
      <div
        className={`form-and-view-container ${classSuffix} ${
          hasError ? 'form-and-view-container--error' : ''
        }`}>
        <label className="form__label--small" htmlFor="__">
          {label}
        </label>
        {!isEditing ? (
          <p className="text--dk--flushed">
            {date ? date.format(format) : '-'}
          </p>
        ) : (
          <div
            className={`custom-date-picker ${innerClass}`}
            data-qe-id={dataQeId}>
            <SingleDatePicker
              showClearDate
              verticalSpacing={10}
              hideKeyboardShortcutsPanel
              disabled={!isEditing}
              placeholder={placeholder}
              numberOfMonths={1}
              displayFormat={format}
              date={currentDate}
              enableOutsideDays={enableOutsideDays}
              isOutsideRange={isOutsideRange}
              showBeforeTodayDifferently={showBeforeTodayDifferently}
              onDateChange={(changedDate: Object): any => {
                setCurrentDate(changedDate);
                return onChange(
                  changedDate ? parseInt(changedDate.format('X'), 10) : null,
                );
              }}
              focused={isFocused}
              onFocusChange={({ focused }: boolean): boolean => {
                setIsFocused(focused);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
