import React, { Fragment } from 'react';
import l from 'helpers/locale';
import ButtonSpacer from './ButtonSpacer';

export default ({ show, editAction, identifier, showSpacer = false }) => {
  return (
    <Fragment>
      {show && (
        <button
          type="button"
          onClick={editAction}
          className="btn--text"
          data-qe-id={identifier}>
          {l('OBJECT-change')}
        </button>
      )}
      {showSpacer && <ButtonSpacer />}
    </Fragment>
  );
};
