/** @format */

// @flow
import React from 'react';
import AllianzSelect from 'components/AllianzSelect';
import l from 'helpers/locale';
import Card from 'components/Card';

type SelectShape = {
  key: string,
  value: string,
};

type StateShape = {
  selectedAccount: Object,
  selectedCostCenter: Object,
};

type PropsShape = {
  data: Object,
  formData: Object,
  isEditing: boolean,
  updateField: Function,
};

class AccountPanel extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    isEditing: false,
    updateField: () => null,
  };

  constructor(props: PropsShape) {
    super(props);

    this.state = {
      selectedAccount: this.getSelectObject(
        props.formData.accounts,
        props.data.account_id || null,
      ),
      selectedCostCenter: this.getSelectObject(
        props.formData.cost_centers,
        props.data.cost_center_id || null,
      ),
    };
  }

  componentDidUpdate(prevProps: PropsShape) {
    const accountChanged =
      prevProps.data.account_id !== this.props.data.account_id;
    const costCenterChanged =
      prevProps.data.cost_center_id !== this.props.data.cost_center_id;

    if (accountChanged || costCenterChanged) {
      this.rebuildSelected(this.props);
    }
  }

  onDescriptionChanged = e => {
    e.preventDefault();
    const { value } = e.target;
    this.props.updateField({ key: 'account_description', value });
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      description: value,
    }));
  };

  onAccountSelected = (obj: { value: string }): any => {
    this.props.updateField({
      key: 'account_id',
      value: obj ? obj.value : null,
    });

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      selectedAccount: obj,
    }));
  };

  onCostCenterSelected = (obj: { value: string }): any => {
    this.props.updateField({
      key: 'cost_center_id',
      value: obj ? obj.value : null,
    });

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      selectedCostCenter: obj,
    }));
  };

  getSelectObject = (list, key) =>
    key
      ? list
          .filter((i: SelectShape) => i.key === key)
          .map(this.transform)
          .pop()
      : null;

  transform = item => ({ label: item.value, value: item.key });

  rebuildSelected = (props): void =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      selectedAccount: this.getSelectObject(
        props.formData.accounts,
        props.data.account_id || null,
      ),
      selectedCostCenter: this.getSelectObject(
        props.formData.cost_centers,
        props.data.cost_center_id || null,
      ),
    }));

  render(): React$Element<*> {
    const isEditing = !!(this.props.isEditing && this.props.formData);
    const classSuffix = isEditing ? '' : 'form-and-view--view';
    return (
      <Card
        title="ACCOUNT-title"
        isOpen
        canCollapse={false}
        qeId="job_card-accounting">
        <div className="gw">
          <div className="g g-1/2">
            <div className={`form-and-view-container ${classSuffix}`}>
              <label className="form__label--small" htmlFor="__">
                {l('ACCOUNT-account_id')}
              </label>
              {isEditing ? (
                <div data-qe-id="action-click-account_selector">
                  <AllianzSelect
                    placeholder={l('FORMS-select_account')}
                    value={this.state.selectedAccount}
                    onValueChange={this.onAccountSelected}
                    options={this.props.formData.accounts.map(this.transform)}
                  />
                </div>
              ) : (
                <input
                  className="form__input"
                  type="text"
                  name="account"
                  value={this.state.selectedAccount?.label || '-'}
                  readOnly
                />
              )}
            </div>
          </div>
          <div className="g g-1/2">
            <div className={`form-and-view-container ${classSuffix}`}>
              <label className="form__label--small" htmlFor="__">
                {l('ACCOUNT-cost_center-id')}
              </label>
              {isEditing ? (
                <div data-qe-id="action-click-cost_centre_selector">
                  <AllianzSelect
                    placeholder={l('FORMS-cost_center')}
                    onValueChange={this.onCostCenterSelected}
                    value={this.state.selectedCostCenter}
                    options={this.props.formData.cost_centers.map(
                      this.transform,
                    )}
                  />
                </div>
              ) : (
                <input
                  className="form__input"
                  type="text"
                  name="cost_center"
                  value={this.state.selectedCostCenter?.label || '-'}
                  readOnly
                />
              )}
            </div>
          </div>
        </div>
        <div className="gw">
          <div className="g g-1/1">
            <div className={`form-and-view-container ${classSuffix}`}>
              <label className="form__label--small" htmlFor="__">
                {l('ACCOUNT-account_description')}
              </label>
              {isEditing ? (
                <input
                  data-qe-id="action-input-account_description_field"
                  className="form__input"
                  type="text"
                  name="account_description"
                  defaultValue={this.props.data.account_description}
                  onChange={this.onDescriptionChanged}
                />
              ) : (
                <p className="form__input">
                  {this.props.data.account_description || '-'}
                </p>
              )}
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default AccountPanel;
