/** @format */

// @flow
// @todo: complete validation rules
import type { KeysList } from 'views/singleProject/constants/KeysList';

export default function validator(target: KeysList, value: any): boolean {
  switch (target) {
    case 'contact_agency_manager_id':
      return !Number.isNaN(parseInt(value, 10));
    case 'contractor_id':
      return !Number.isNaN(parseInt(value, 10));
    case 'selected_contractors_ids':
      return true;
    case 'asset_id':
      return !Number.isNaN(parseInt(value, 10));
    case 'house_id':
      return !Number.isNaN(parseInt(value, 10));
    case 'property_id':
      return !Number.isNaN(parseInt(value, 10));
    case 'building_id':
      return !Number.isNaN(parseInt(value, 10)) || value === null;
    case 'info_occasion':
      return true;
    case 'info_order_date':
      return true;
    case 'info_desired_date':
      return true;
    case 'info_summary':
      return true;
    case 'info_description':
      return true;
    case 'contact_tenant_id':
      return !Number.isNaN(parseInt(value, 10));
    case 'contact_care_taker_id':
      return !Number.isNaN(parseInt(value, 10));
    case 'contact_delivery_id':
      return !Number.isNaN(parseInt(value, 10));
    case 'contact_delivery_type':
      return true;
    case 'agreement_according_to':
      return true;
    case 'agreement_conditions':
      return true;
    case 'agreement_price':
      return true;
    case 'agreement_includes_vat':
      return true;
    case 'agreement_discount':
      return true;
    case 'agreement_cash_discount':
      return true;
    case 'agreement_payment_inert':
      return !Number.isNaN(parseInt(value, 10)) || value === null;
    case 'internal_documents':
      return true;
    case 'subscribers':
      return true;
    default:
      return true;
  }
}
