// @flow
import React from 'react';
import { createPortal } from 'react-dom';
import l from 'helpers/locale';
import { parseDate } from 'helpers/dates';
import If from 'components/Conditions/If';
import { AsideSlide } from '@bonlineza/b-lib';
import CloseButton from 'components/AsideSlide/CloseButton';
import DocConstants from 'components/connected/DocumentUploader/constants';
import type { DocumentDataShape } from 'components/connected/DocumentUploader';

import PdfPreview from 'components/PDFPreview';
import PreviewSliderImage from './components/PreviewSliderImage';

type PropsShape = {
  data: DocumentDataShape,
  open: boolean,
  onCloseClick: Function,
  props: any,
};

function PreviewSlider({ data, open, onCloseClick, ...props }: PropsShape) {
  const { MINIMIZE, DOWNLOAD } = DocConstants.DOCUMENT_ACTION;

  const customControls = [
    {
      documentAction: DOWNLOAD,
      canDisplay: true,
    },
    {
      documentAction: MINIMIZE,
      canDisplay: true,
      action: onCloseClick,
    },
  ];

  function description() {
    return (
      <p className="push--smaller--top flush--bottom">
        <label className="form__label--small">{l('UPLOAD-last_updated')}</label>
        <span className="text--dk--flushed">
          {parseDate(parseInt(data.timestamp, 10))}
        </span>
      </p>
    );
  }

  function render() {
    return (
      <div data-qe-id="component-preview-slider">
        <AsideSlide
          isOpen={open}
          toggle={onCloseClick}
          toggleButton={() => <CloseButton onClick={onCloseClick} />}
          title={data.name || ''}
          bgcAlt>
          <If condition={data.mime_type.includes('pdf')}>
            <PdfPreview
              data={data}
              customControls={customControls}
              {...props}
              description={description}
            />
          </If>
          <If condition={data.mime_type.includes('image')}>
            <PreviewSliderImage data={data} customControls={customControls} />
            {description()}
          </If>
        </AsideSlide>
      </div>
    );
  }

  return createPortal(
    render(),
    document.querySelector('#react-preview-slider'),
  );
}

export default PreviewSlider;
