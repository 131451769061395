/** @format */

// @flow

import React from 'react';
import DocConstants from 'components/connected/DocumentUploader/constants';
import DocumentUploader from 'components/connected/DocumentUploader';
import type { DocumentType } from 'views/singleJob/constants';

const { DELETE, VIEW, DOWNLOAD } = DocConstants.DOCUMENT_ACTION;

const AccountDocumentsCard = (
  documents: Array<DocumentType>,
  userId: number,
  removeAccountDoc: Function,
): Function => (): React$Element<*> => (
  <div className="form__item ">
    <div className="g g-1/1">
      <DocumentUploader
        controls={[
          {
            documentAction: DELETE,
            svgName: 'close',
            action: (id: string | number, api: string): any =>
              removeAccountDoc(id, api),
            canDisplay: true,
          },
          {
            documentAction: VIEW,
            svgName: 'view',
            canDisplay: true,
          },
          {
            documentAction: DOWNLOAD,
            svgName: 'download',
            canDisplay: true,
          },
        ]}
        dataQeId="action-drop-account_document"
        listName="account_documents"
        list={documents}
        endpoint="/api/1/documents/action/add"
        data={{
          source_tag: 'dashboard',
          source_type: 'user',
          source_id: userId,
        }}
      />
    </div>
  </div>
);

AccountDocumentsCard.defaultProps = {
  documents: [],
};

export default AccountDocumentsCard;
