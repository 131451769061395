import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import Roles from 'constants/roles';
import l from 'helpers/locale';
import SimpleList, {
  SimpleListItemArrayFormatter,
} from 'components/ConnectedSimpleList';
import Card from 'components/Card';
import PropertiesListActionGroup from './components/ActionGroup';
import { propertiesExporting } from './actions';

const { RoleIds } = Roles;

type ReduxActionsShape = {
  exporting: Function,
};

type PropsShape = {
  history: Object,
} & ReduxActionsShape;

const Properties = ({
  history,
  exporting,
  userEmail,
  userRole,
}: PropsShape) => {
  function openProperty({ property_id }) {
    history.push(`/properties/${property_id}`);
  }

  return (
    <div>
      <ActionBar name="properties_list" />
      <Card
        title="PROPERTIES-title"
        asideComponent={PropertiesListActionGroup(exporting)}
        watermarkName="house"
        canCollapse={false}>
        <SimpleList
          api="/api/1/view/properties"
          name="properties-list"
          headings={[
            {
              name: 'reference',
              sortable: true,
              text: l('PROP-COL-reference'),
            },
            {
              name: 'status',
              sortable: true,
              text: l('PROP-COL-status'),
            },
            {
              name: 'address',
              sortable: true,
              text: l('PROP-COL-address'),
              customFormatter: SimpleListItemArrayFormatter(),
            },
            {
              name: 'branch',
              sortable: true,
              text: l('PROP-COL-branch'),
            },
            {
              name: 'manager',
              sortable: true,
              text: l('PROP-COL-property_manager'),
              customFormatter: SimpleListItemArrayFormatter(),
            },
            {
              name: 'care_taker',
              sortable: false,
              text: l('PROP-COL-care_taker'),
              customFormatter: SimpleListItemArrayFormatter(),
            },
          ]}
          filterOpts={[
            userRole === RoleIds.PROPERTY_MANAGER
              ? {
                  text: l('CONTRACTOR_OVERVIEW_FILTER-my_properties'),
                  value: `pm:${userEmail}`,
                }
              : {},
            {
              text: l('CONTRACTOR_OVERVIEW_FILTER-properties_active_jobs'),
              value: 'status:true',
            },
            {
              text: l('CONTRACTOR_OVERVIEW_FILTER-zurich'),
              value: 'office:"ASI Zürich"',
            },
            {
              text: l('CONTRACTOR_OVERVIEW_FILTER-bern'),
              value: 'office:ASIBERN',
            },
            {
              text: l('CONTRACTOR_OVERVIEW_FILTER-basel'),
              value: 'office:LIVIBASEL',
            },
            {
              text: l('CONTRACTOR_OVERVIEW_FILTER-lausanne'),
              value: 'office:ASILAUS',
            },
            {
              text: l('CONTRACTOR_OVERVIEW_FILTER-geneva'),
              value: 'office:ASIGENF',
            },
            {
              text: l('CONTRACTOR_OVERVIEW_FILTER-livit_basel'),
              value: 'office:LIVIBASEL',
            },
          ].filter(v => v.value !== undefined)}
          clickAct={openProperty}
          bgcAlt
        />
      </Card>
    </div>
  );
};

const mapState = (state: Object): Object => ({
  userRole: state.authStore.user.role,
  userEmail: state.authStore.user.email,
});

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  exporting(): any {
    return dispatch(propertiesExporting());
  },
});

export default withRouter(connect(mapState, mapActions)(Properties));
