/** @format */

import React from 'react';
import { connect } from 'react-redux';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import AccountPanel from 'shared/jobs/Panels/items/Account';
import { updateJobForm } from '../../actions';
import MachineBuilder from './machine';

export const AccountCardMachine = MachineBuilder();

type ReduxActionsShape = {
  update: Function,
};

type ReduxStateShape = {
  status: string,
  account: string,
  cost_center: string,
  accounts: any,
  cost_centers: any,
  panelEditMode: boolean,
};

const AccountCard = ({
  status,
  account,
  cost_center,
  accounts,
  cost_centers,
  panelEditMode,
  update,
  machine_state,
  account_description,
}) => (
  <StateConditional
    state={machine_state}
    whenState={[PanelStates.VISIBLE, PanelStates.EDITING]}>
    <AccountPanel
      editNow={panelEditMode}
      jobState={status}
      data={{
        account_id: account,
        cost_center_id: cost_center,
        account_description,
      }}
      formData={{
        accounts,
        cost_centers,
        account_description,
      }}
      updateField={update}
    />
  </StateConditional>
);

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.account_card,
  panelEditMode: machineStore.account_card === PanelStates.EDITING,
  status: jobStore.data.job.info_status,
  account: jobStore.form.account_id,
  cost_center: jobStore.form.cost_center_id,
  accounts: jobStore.form.concern.accountings,
  cost_centers: jobStore.form.concern.cost_centers,
  account_description: jobStore.form.account_description,
});

const mapAction = (dispatch: Function): ReduxActionsShape => ({
  update(payload: { key: string, value: string }): any {
    return dispatch(updateJobForm(payload));
  },
});

export default connect(mapState, mapAction)(AccountCard);
