/** @format */

// @flow

type FilterItem = {
  value: string,
  category: string,
};

export default function filterDataByFields(
  dataSet: Array<Object>,
  filterFields: [FilterItem],
): Array<Object> {
  return dataSet.filter((j: Object): any =>
    filterFields.length
      ? filterFields
          .map((i: FilterItem): boolean => {
            const hasCat =
              Object.prototype.hasOwnProperty.call(i, 'category') &&
              i.category !== '';
            const hasVal =
              Object.prototype.hasOwnProperty.call(i, 'value') &&
              i.value !== '';

            let res = false;
            if (hasCat && hasVal) {
              res = !!String.prototype.toUpperCase
                .call(j[i.category] || false)
                .includes(String.prototype.toUpperCase.call(i.value || false));
            } else if (hasVal) {
              res = Object.keys(j)
                .filter((k: string): boolean =>
                  String.prototype.toUpperCase
                    .call(j[k] || false)
                    .includes(
                      String.prototype.toUpperCase.call(i.value || false),
                    ),
                )
                .reduce(
                  (itr: boolean, curr: any): boolean => itr || !!curr,
                  false,
                );
            } else {
              res = true;
            }

            return res;
          })
          .reduce((acc: boolean, curr: boolean): boolean => curr && acc, true)
      : [dataSet],
  );
}
