/** @format */

import React from 'react';

import Card from '../../../../../components/Card';
import l from '../../../../../helpers/locale';

const ContactPersonPanel = ({
  companyName,
  companyName2,
  road,
  pobox,
  address_additional,
  postCode,
  city,
  // country,
  phone,
  email,
  website,
  uid,
}) => (
  <Card title="NOMINATION-billing-card_title" isOpen canCollapse={false}>
    <div className="gw">
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-company')}
          </span>
          <p className="text--dk--flushed">{companyName}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-company_2')}
          </span>
          <p className="text--dk--flushed">{companyName2}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">{l('NOMINATION-address')}</span>
          <p className="text--dk--flushed">{road}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-pobox')}
          </span>
          <p className="text--dk--flushed">{pobox}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION-address_additional')}
          </span>
          <p className="text--dk--flushed">{address_additional}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="gw">
          <div className="g g-1/12">
            <div className="push--small--bottom">
              <span className="form__label--small">
                {l('NOMINATION_FORM-zip')}
              </span>
              <p className="text--dk--flushed">{postCode}</p>
            </div>
          </div>
          <div className="g g-11/12">
            <div className="push--small--bottom">
              <span className="form__label--small">
                {l('NOMINATION_FORM-city')}
              </span>
              <p className="text--dk--flushed">{city}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-phone')}
          </span>
          <p className="text--dk--flushed">{phone}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-email')}
          </span>
          <p className="text--dk--flushed">{email}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-website')}
          </span>
          <p className="text--dk--flushed">{website}</p>
        </div>
      </div>
      <div className="g g-1/1">
        <div className="push--small--bottom">
          <span className="form__label--small">
            {l('NOMINATION_FORM-uid_number')}
          </span>
          <p className="text--dk--flushed">{uid}`</p>
        </div>
      </div>
    </div>
  </Card>
);

export default ContactPersonPanel;
