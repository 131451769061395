import React, { Fragment, createContext } from 'react';
import { createPortal } from 'react-dom';
import { withRouter } from 'react-router-dom';
import { scrollToSelector } from 'helpers/smoothScrollTo';

import flattenRoute from './functions/flattenRoutes.js';
import { getPrimaryPath, pathEndsWith } from './functions/pathMatch';
import {
  create as createProject,
  view as viewProject,
} from './routes/projects';
import { create as createJob, view as viewJob } from './routes/jobs';
import {
  create as createAccount,
  view as viewAccount,
} from './routes/accounts';
import {
  view as viewContractor,
  rootProfileView,
  create as createContractor,
} from './routes/contractors';
import {
  view as viewNomination,
  create as createNomination,
} from './routes/nominations';
import { view as viewProperties } from './routes/properties';
import Slider from './slider';

export const SliderContext = createContext({
  // provide a 'scrollToTop' action that is linked to the current slider DOM
  scrollToTop() {
    return null;
  },
  sliderIndex: null,
  // provide a 'back' action for children of sliders to use
  backAction() {
    return null;
  },
  isActive: false, // let children know if the current Route is still valid, can be used to prevent some overeager HTTP requests
});

const RouteSlider = ({ location, history, match }) => {
  const routes = {
    dashboard: {
      ...createJob('/dashboard'),
      ...createProject(),
    },
    jobs: { ...viewJob('/jobs'), ...createJob('/jobs') },
    projects: { ...viewProject(), ...createProject() },
    accounts: { ...createAccount('/accounts'), ...viewAccount('/accounts') },
    contractors: {
      ...createContractor('/contractors'),
      ...viewContractor('/contractors'),
    },
    nominations: {
      ...createNomination('/nominations'),
      ...viewNomination('/nominations'),
    },
    properties: { ...viewProperties() },
    profile: { ...rootProfileView() },
  };

  const getPathPieces = () => {
    const primaryPath = getPrimaryPath(location.pathname);
    if (!routes[primaryPath]) {
      return [];
    }

    const flatRoutes = flattenRoute(routes[primaryPath]).sort((a, b) => {
      if (a.position > b.position) return 1;
      if (a.position < b.position) return -1;
      return 0;
    });
    return flatRoutes;
  };

  const scrollToTop = id => {
    const elementQuery = `#slider_${id}`;
    scrollToSelector(elementQuery);
  };

  const renderPieces = () => {
    return (
      <Fragment>
        {getPathPieces().map((PieceComponent, index) => {
          const backAction = PieceComponent.backAction
            ? PieceComponent.backAction
            : () => history.go(-1);
          return (
            <SliderContext.Provider
              value={{
                scrollToTop: () => scrollToTop(index),
                sliderIndex: index,
                backAction,
                isActive: pathEndsWith(match.path, PieceComponent.name),
              }}
              key={index}>
              <Slider
                name={PieceComponent.name}
                bar={PieceComponent.bar}
                actionComponent={PieceComponent.actionComponent}
                qeId={PieceComponent.qeId}
                parentPath={PieceComponent.parentPath}
                backAction={backAction}>
                {PieceComponent.render()}
              </Slider>
            </SliderContext.Provider>
          );
        })}
      </Fragment>
    );
  };

  return createPortal(
    renderPieces(),
    document.querySelector('#react-route-slider'),
  );
};

export default withRouter(RouteSlider);
