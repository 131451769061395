function preventDefault(e) {
  e.preventDefault();
}

class InputFocus {
  listeners = [];

  clearListeners = () => {
    this.listeners.map(li => li.removeEventListener('mouseup', preventDefault));
  };

  selectTargetOnFocus = () => {
    let listenerAdded = null;
    return e => {
      const target = e.currentTarget;
      if (target && !listenerAdded) {
        target.addEventListener('mouseup', preventDefault);
        listenerAdded = true;
        this.listeners.push(target);
      }

      target.select();
    };
  };
}

export default InputFocus;
