/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import FinancePanel from 'shared/projects/panels/items/Finance';
import { updateProjectFormData } from '../../../actions';

type ReduxStateShape = {
  budget: string | number,
};

type PropsShape = {
  updateFormField: ({
    key: string,
    value: string,
  }) => any,
} & ReduxStateShape;

const FinanceCard = ({
  budget,
  updateFormField,
}: PropsShape): React$Element<*> => (
  <FinancePanel
    data={{ budget }}
    projectStatus="draft"
    isEditing
    updateField={updateFormField}
  />
);

const mapState = ({ projectStore }): ReduxStateShape => ({
  budget: projectStore.form.budget,
});

const mapActions = (dispatch: Function) => ({
  updateFormField(key: string, value: string): any {
    return dispatch(updateProjectFormData(key, value));
  },
});

export default connect(mapState, mapActions)(FinanceCard);
