import ObjectsCard from './ObjectsCard.js';
import HousesCard from './HousesCard.js';
import JobsCard from './JobsCard.js';
import ContactsCard from './ContactsCard.js';
import MainCard from './MainCard.js';
import NotificationsCard from './NotificationsCard.js';

export default {
  ObjectsCard,
  HousesCard,
  JobsCard,
  ContactsCard,
  MainCard,
  NotificationsCard,
};
