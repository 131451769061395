// @flow

type StateShape = {
  [string]: string,
};

type PropsShape = {
  machine: string,
  type: string,
  newState: string,
};

const initialState = {};

export default function reducerReducer(
  state: StateShape = initialState,
  { type, payload }: PropsShape,
): StateShape {
  switch (type) {
    case 'REGISTER_REDUX_MACHINE':
    case 'UPDATE_REDUX_MACHINE':
      return {
        ...state,
        [payload.machine]: payload.newState,
      };
    default:
      return state;
  }
}
