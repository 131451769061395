import AddressesCard from './AddressesCard/index';
import ChartCard from './ChartCard/index';
import DescriptionCard from './DescriptionCard/index';
import InternalDocsCard from './InternalDocsCard';
import InvoicingProcessCard from './InvoicingProcessCard';
import JobsListCard from './JobsListCard';
import NotificationCard from './NotificationCard';
import ObjectCard from './ObjectGeneralFinanceCard/ObjectCard';
import GeneralCard from './ObjectGeneralFinanceCard/GeneralCard';
import FinanceCard from './ObjectGeneralFinanceCard/FinanceCard';
import PaymentListCard from './PaymentListCard';
import ProjectTeamCard from './ProjectTeamCard';
import ProjectTeamDocsCard from './ProjectTeamDocsCard';
import StaticDocsCard from './StaticDocsCard';

export default {
  AddressesCard,
  ChartCard,
  DescriptionCard,
  InternalDocsCard,
  InvoicingProcessCard,
  JobsListCard,
  NotificationCard,
  ObjectCard,
  GeneralCard,
  FinanceCard,
  PaymentListCard,
  ProjectTeamCard,
  ProjectTeamDocsCard,
  StaticDocsCard,
};
