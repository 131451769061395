// job is a project job
const isProjectJob = ({ job, project }) => {
  if (project) {
    return project.id !== null;
  }
  if (job) {
    return job.project_id !== null;
  }

  return false;
};

export default isProjectJob;
