/** @format */

// @flow

import React from 'react';
import Panel from './agreementPanel.js';
import type { DataShape, FormDataShape } from './agreementPanel.js';

type KeysList = any; // @TODO

type PropsShape = {
  editNow?: boolean,
  updateField: KeysList => (string | number) => any,
  data: DataShape,
  formData: ?FormDataShape | false,
};

const AgreementPanelWrapper = ({
  editNow,
  updateField,
  data,
  formData,
}: PropsShape): React$Element<*> => (
  <Panel
    data={data}
    formData={formData}
    updateField={updateField}
    isEditing={editNow}
  />
);
AgreementPanelWrapper.defaultProps = {
  editNow: false,
  updateField: false,
};

export default AgreementPanelWrapper;
