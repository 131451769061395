import assign from 'object.assign';
import filterDataByFields from 'functions/filterDataByFields';

import {
  SIMPLE_LIST_SET_DATA,
  SIMPLE_LIST_FLUSH_DATA,
  SIMPLE_LIST_SORT_BY_FILTER,
  SIMPLE_LIST_UPDATE_QUERIES,
  SIMPLE_LIST_UPDATE_DATA_REQUEST,
  SIMPLE_LIST_UPDATE_DATA,
  SIMPLE_LIST_UPDATE_DATA_FAILED,
  SIMPLE_LIST_SET_DATA_LOADING,
} from '../constants';

export type ColumnType = {
  name: string,
  order: boolean | null,
};

type StateType = {
  nameList: Array<string>, // store all of our lists here
  list: {
    // store our lists here as ...
    [field: string]: {
      api: string,
      data: Array<*>,
      initialLoad: {
        loading: boolean,
        success: boolean,
        error: boolean,
      },
      loadingData: {
        loading: boolean,
        success: boolean,
        error: boolean,
      },
      queries: {
        raw: string,
        search: string,
        constraint: string,
        perPage: string,
        page: string,
        sortString: string,
        sort: string,
        dateFrom: string,
        dateTo: string,
        counter: number,
        exclude: Array<*>,
      },
    },
  },
};

type PropTypes = {
  api?: string,
  type: string,
  name: string,
  data?: Array<*>,
  column?: string,
  order?: 'DESC' | 'ASC' | void,
  response?: Object,
  queries?: Object,
  filterFields: [{ value: string, category: string }],
};

const initialTables = [
  'rental_object',
  'house',
  'property',
  'select_contractors_table',
  'jobs',
  'contractors_property_list',
  'references',
  'accounts',
  'contractor_list',
  'nominations',
  'single_property_houses',
  'single_property_jobs',
  'single_property_assets',
  'properties-list',
  'contractor_job_list',
  'edit_contractors_table',
  'single_job_contractors',
  'create_offer',
  'single_property',
  'single_job_project_team',
  'single_job_exchange',
  'single_job_internal',
  'single_job_offers',
  'contractor_jobs_list',
  'project_list',
  'project_contacts_owners',
  'project_contacts_audits',
  'project_contacts_supports',
  'project_jobs_list',
  'payment_contractor_list',
  'project_payments_list',
  'job_payments_list',
  'grant_request_offers',
];

export const initialQueryObj = {
  raw: '',
  search: '',
  currentPage: 0,
  lastPage: 0,
  perPage: 40,
  page: 1,
  sort: '',
  dateFrom: '',
  dateTo: '',
  counter: 0,
  exclude: [],
  constraint: '',
  grouping: '',
};

const baseListItem = {
  url: '', // issues/1891#issuecomment-445155221
  api: '',
  data: [],
  initialLoad: {
    loading: true,
    success: false,
    error: false,
  },
  loadingData: {
    loading: false,
    success: false,
    error: false,
  },
  queries: { ...initialQueryObj },
};

export const initialState = {
  nameList: initialTables,
  list: initialTables.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        ...baseListItem,
      },
    }),
    {},
  ),
};

export default function simpleListReducer(
  state: StateType = initialState,
  { type, name, filterFields, response, queries, api }: PropTypes,
): StateType {
  let dataPointer;
  let windowUrl;
  switch (type) {
    case SIMPLE_LIST_SET_DATA_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...state.list[name],
            initialLoad: {
              success: false,
              error: false,
              loading: true,
            },
          },
        },
      };
    case SIMPLE_LIST_SET_DATA:
      dataPointer = name === 'references' ? response.references : response;
      return assign({}, state, {
        nameList: state.nameList.includes(name)
          ? state.nameList
          : [...state.nameList, name],
        list: assign({}, state.list, {
          [name]: {
            api,
            data: dataPointer.data,
            queries: {
              ...initialQueryObj,
              search:
                state.list[name] && state.list[name].queries
                  ? state.list[name].queries.search
                  : '',
              perPage: dataPointer.per_page,
              sort:
                state.list[name] && state.list[name].queries
                  ? state.list[name].queries.sort
                  : '',
              sortString: dataPointer.initial_sort || '',
              currentPage: dataPointer.current_page,
              lastPage: dataPointer.last_page,
              counter:
                state.list[name] && state.list[name].queries
                  ? state.list[name].queries.counter
                  : 0,
              exclude:
                state.list[name] && state.list[name].queries
                  ? state.list[name].queries.exclude
                  : [],
              grouping: state.list[name]?.queries?.grouping || '',
            },
            loadingData: {
              success: true,
              error: false,
              loading: false,
            },
            initialLoad: {
              success: true,
              error: false,
              loading: false,
            },
            url: window.location.href,
          },
        }),
      });
    case SIMPLE_LIST_FLUSH_DATA:
      return assign({}, state, {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...state.list[name],
            data: [],
            loadingData: {
              loading: false,
              success: false,
              error: false,
            },
            initialLoad: {
              success: false,
              error: false,
              loading: false,
            },
            queries: {
              ...initialQueryObj,
            },
          },
        },
      });
    case SIMPLE_LIST_SORT_BY_FILTER:
      return assign({}, state, {
        list: assign({}, state.list, {
          [name]: assign({}, state.list[name], {
            data: assign(
              [],
              filterDataByFields(state.list[name].data, filterFields),
            ),
          }),
        }),
      });

    case SIMPLE_LIST_UPDATE_DATA:
      dataPointer = name === 'references' ? response.references : response;
      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...state.list[name],
            ...dataPointer,
            loadingData: {
              success: true,
              error: false,
              loading: false,
            },
            queries: {
              ...state.list[name].queries,
              ...queries,
              currentPage: dataPointer.current_page,
              lastPage: dataPointer.last_page,
              perPage: dataPointer.per_page,
              sortString: dataPointer.initial_sort,
            },
          },
        },
      };
    case SIMPLE_LIST_UPDATE_DATA_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...state.list[name],
            loadingData: {
              success: false,
              error: false,
              loading: true,
            },
          },
        },
      };
    case SIMPLE_LIST_UPDATE_DATA_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...state.list[name],
            loadingData: {
              success: false,
              error: true,
              loading: false,
            },
          },
        },
      };
    case SIMPLE_LIST_UPDATE_QUERIES:
      return {
        ...state,
        list: {
          ...state.list,
          [name]: {
            ...state.list[name],
            queries: {
              ...state.list[name].queries,
              ...queries,
            },
          },
        },
      };
    case '@@router/LOCATION_CHANGE':
      windowUrl = window.location.href;
      return {
        ...state,
        list: Object.keys(state.list).reduce((acc, curr) => {
          if (
            state.list[curr].url !== '' &&
            !windowUrl.includes(state.list[curr].url)
          ) {
            return {
              ...acc,
              [curr]: {
                ...state.list[curr],
                queries: {
                  ...initialQueryObj,
                },
                url: '',
              },
            };
          }

          return {
            ...acc,
            [curr]: {
              ...state.list[curr],
            },
          };
        }, {}),
      };
    default:
      return state;
  }
}
