// @flow

import graphi from 'helpers/graphi';

type AuditContact = {
  street: string,
  city: string,
  postal_code: string,
  email: string,
  name: string,
  surname: string,
  company: string,
};

const contactGraphBody = `
  contact_id,
  company,
  street,
  city,
  postal_code,
  email,
  full_name,
  telephone,
  type,
  state_machine {
    status
    available_transitions {
      name
    }
  }
`;

function formatDataAsParams(data: AuditContact) {
  const fields = [
    'street',
    'city',
    'postal_code',
    'email',
    'name',
    'surname',
    'telephone',
    ...(data.company ? ['company'] : []),
    ...(data.type ? ['type'] : []),
  ];

  const stringParamFormatter = (key, value) => `${key}:"${value}"`;
  const enumParamFormatter = (key, value) => `${key}:${value}`;
  const getFieldFormatter = (field, value) => {
    switch (field) {
      case 'type':
        return enumParamFormatter(field, value);
      default:
        return stringParamFormatter(field, value);
    }
  };
  const params = fields
    .map(field => getFieldFormatter(field, data[field]))
    .join(',');

  return params;
}

function mapErrorKeyToTranslationKey(errorKey) {
  const translationKeys = {
    required: 'VALIDATION-required',
  };
  return translationKeys[errorKey] || 'VALIDATION-required';
}

function formatGQLErrors(errors) {
  if (errors && errors.length) {
    return errors
      .map(error => error.validation)
      .reduce((pv, cv) => {
        const validationKeys = Object.keys(cv).reduce(
          (vkPv, vkCv) => ({
            ...vkPv,
            [vkCv]: mapErrorKeyToTranslationKey(cv[vkCv]),
          }),
          {},
        );
        return { ...pv, ...validationKeys };
      }, {});
  }
  return null;
}

function mutateByRequestId(data, requestId) {
  switch (requestId) {
    case 'create_delivery_contact':
      return { ...data, type: 'delivery' };
    default:
      return data;
  }
}

export async function createCustomContact(
  newContact: AuditContact,
  requestId: string,
) {
  const mutatedContact = mutateByRequestId(newContact, requestId);
  const body = `
    mutation contact {
      add_contact(${formatDataAsParams(mutatedContact)}) {
        ${contactGraphBody}
      }
    }
  `;

  try {
    const {
      data: { add_contact: contact },
      errors,
    } = await request(body, requestId);
    const formattedErrors = formatGQLErrors(errors);
    return { contact, errors: formattedErrors };
  } catch (e) {
    throw new Error('Unexpected GQL Error ocurred');
  }
}

async function request(body, tag) {
  const response = await graphi(body, tag);
  return { data: response.data.data, errors: response.data.errors };
}
