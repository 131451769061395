import React from 'react';
import history from 'helpers/history';
import { Names } from './constants';
import { NominationView, InviteForm } from './containers';

export function create(parentPath) {
  // new
  return {
    [Names.NOMINATION_INVITE]: {
      position: 2,
      title: 'NOMINATION-form_title',
      render: () => <InviteForm />,
      parentPath,
    },
  };
}

export function view(parentPath) {
  return {
    // :nomination_id
    [Names.NOMINATION_VIEW]: {
      position: 1,
      title: 'NOMINATION-form_title',
      qeId: 'view-contractor',
      render: () => <NominationView />,
      backAction: () => history.push('/nominations'),
      parentPath,
    },
  };
}
