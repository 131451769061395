/** @format */

import generator from 'constants/generator';

const PROJECT_ACTIONS_EDIT = 'PROJECT_ACTIONS_EDIT';
const PROJECT_ACTIONS_CANCEL_EDIT = 'PROJECT_ACTIONS_CANCEL_EDIT';
const PROJECT_ACTIONS_COPY = 'PROJECT_ACTIONS_COPY';
const PROJECT_ACTIONS_DELETE = 'PROJECT_ACTIONS_DELETE';
const PROJECT_ACTIONS_EXPORT = 'PROJECT_ACTIONS_EXPORT';

const PROJECT_ACTIONS_BACK = 'PROJECT_ACTIONS_BACK';
const PROJECT_ACTIONS_CANCEL = 'PROJECT_ACTIONS_CANCEL';
const PROJECT_ACTIONS_EXECUTE = 'PROJECT_ACTIONS_EXECUTE';
const PROJECT_ACTIONS_OPEN = 'PROJECT_ACTIONS_OPEN';
const PROJECT_CHANGE_OWNER = 'PROJECT_CHANGE_OWNER';
const PROJECT_CHANGE_SUPPORT = 'PROJECT_CHANGE_SUPPORT';
const PROJECT_COMPLETE = 'PROJECT_COMPLETE';
const PROJECT_END = 'PROJECT_END';
const PROJECT_REMOVE_SUPPORT = 'PROJECT_REMOVE_SUPPORT';
const PROJECT_REMOVE_OWNER = 'PROJECT_REMOVE_OWNER';
const PROJECT_UPDATE = 'PROJECT_UPDATE';
const PROJECT_ADD_PAYMENT = 'PROJECT_ADD_PAYMENT';
const PROJECT_SAVE = 'PROJECT_SAVE';
const PROJECT_CHANGE_AUDIT_ADDRESS = 'PROJECT_CHANGE_AUDIT_ADDRESS';
const PROJECT_ADD_AUDIT_ADDRESS = 'PROJECT_ADD_AUDIT_ADDRESS';
const PROJECT_NEW_JOB_DIRECT = 'PROJECT_NEW_JOB_DIRECT';
const PROJECT_NEW_JOB_OFFER = 'PROJECT_NEW_JOB_OFFER';

export default {
  edit: PROJECT_ACTIONS_EDIT,
  cancelEdit: PROJECT_ACTIONS_CANCEL_EDIT,
  copy: generator(PROJECT_ACTIONS_COPY),
  erase: generator(PROJECT_ACTIONS_DELETE),
  back: PROJECT_ACTIONS_BACK,
  cancel: generator(PROJECT_ACTIONS_CANCEL),
  execute: generator(PROJECT_ACTIONS_EXECUTE),
  open: generator(PROJECT_ACTIONS_OPEN),
  change_owner: generator(PROJECT_CHANGE_OWNER),
  change_support: generator(PROJECT_CHANGE_SUPPORT),
  complete: generator(PROJECT_COMPLETE),
  end: generator(PROJECT_END),
  remove_support: generator(PROJECT_REMOVE_SUPPORT),
  remove_owner: generator(PROJECT_REMOVE_OWNER),
  update: generator(PROJECT_UPDATE),
  add_payment: PROJECT_ADD_PAYMENT,
  save: generator(PROJECT_SAVE),
  change_audit: PROJECT_CHANGE_AUDIT_ADDRESS,
  add_audit: PROJECT_ADD_AUDIT_ADDRESS,
  project_export: generator(PROJECT_ACTIONS_EXPORT),
  new_job_direct: generator(PROJECT_NEW_JOB_DIRECT),
  new_job_offer: generator(PROJECT_NEW_JOB_OFFER),
};
