/** @format */

// @flow
import actionTypes from '../constants';

type StateShape = {
  locale: 'en-GB' | 'de-CH' | 'fr-CH' | 'it-CH',
  redirectPath: string,
  statusBarMessage: string,
};

type PropsShape = {
  type: string,
  payload: {
    locale: 'en-GB' | 'de-CH' | 'fr-CH' | 'it-CH',
    redirectPath: string,
  },
};

const initialState = {
  locale:
    typeof window !== 'undefined'
      ? window.localStorage.getItem('_sl') || 'de-CH'
      : '', // default locale
  redirectPath: '',
  statusBarMessage: '',
};

export default function appReducer(
  state: StateShape = initialState,
  { type, payload }: PropsShape,
): StateShape {
  switch (type) {
    case actionTypes.APP.SET_LOCALE:
      return {
        ...state,
        locale: payload.locale,
      };
    case actionTypes.APP.SET_LOGIN_REDIRECT:
      return {
        ...state,
        redirectPath: payload.path,
      };
    case actionTypes.APP.SET_STATUS_BAR:
      return {
        ...state,
        statusBarMessage: payload,
      };
    default:
      return state;
  }
}
