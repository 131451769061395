import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import GetSvg from 'components/GetSvg';

const PaymentListItemButton = ({ item, history, location }) => (
  <div className="align--right">
    <Fragment>
      <button
        type="button"
        className="btn--text--base"
        onClick={() =>
          history.push(
            `${location.pathname}/edit-payment/${item.payment_id_raw}`,
          )
        }>
        <GetSvg svg="edit" />
      </button>
    </Fragment>
  </div>
);

export default withRouter(PaymentListItemButton);
