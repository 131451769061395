/** @format */

// @flow
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import AllianzSelect from 'components/AllianzSelect';
import l from 'helpers/locale';
import Card from 'components/Card';
import If from 'components/Conditions/If';

import EditButton from './components/EditButton';

const OfferStatus = {
  UNKNOWN: '-',
  OPEN: 'open',
  CLOSED: 'closed',
};

type ReduxStateShape = {
  error_trade_id: string[] | boolean,
};

type ContractorShape = {
  contractor_id?: string,

  billing_company: string,
  billing_lang_code: string,
  billing_email: string,
  billing_telephone: string,
  billing_website: string,

  street: string,
  pobox: string,
  postal_code: string,
  city: string,
  country: string,

  key_user_id: number,
  key_user_salutation: string,
  key_user_phone: string,
  key_user_full_name: string,
  key_user_email: string,

  has_profile: boolean,
  post_city: string,
};

type PropsShape = {
  data?:
    | {
        trades: Array<{ key: string, value: string }>,
        trade?: { key?: string | void, value: string },
      }
    | (ContractorShape & {
        trades: Array<{ key: string, value: string }>,
        trade?: { key?: string | void, value: string },
      })
    | void,
  isEditing?: boolean,
  openEdit?: Function,
  updateField: Function,
  selectedTradeId?: string | number,
  remainingTime?: number,
  contractorStatus?: string,
} & ReduxStateShape;

function getDeadlineText(remainingTime, contractorStatus): any {
  if (contractorStatus === '' && remainingTime === 0) return '-';

  if (contractorStatus === OfferStatus.UNKNOWN) {
    return contractorStatus;
  }
  return (
    (remainingTime <= 0 && l('CONTRACTOR-deadline_passed')) ||
    l('CONTRACTOR-days_remaining-text').replace(':days', remainingTime)
  );
}

function getStatusText(contractorStatus): any {
  if (contractorStatus === '') return '-';
  return (
    (contractorStatus !== OfferStatus.UNKNOWN &&
      l(`CONTRACTOR-status-${contractorStatus}`)) ||
    contractorStatus
  );
}

const ContractorPanel = ({
  data,
  isEditing,
  openEdit,
  selectedTradeId,
  updateField,
  error_trade_id,
  contractorStatus,
  remainingTime,
  asideComponent,
}: PropsShape): React$Element<*> => (
  <Card
    title="CONTRACTOR-title"
    isOpen
    canCollapse={false}
    qeId="job_card-contractor"
    asideComponent={asideComponent}>
    <div className="gw--tall">
      <div className="g g-1/1">
        {data.contractor ? (
          <div>
            <div className="push--bottom">
              <label className="form__label--small" htmlFor="__">
                {l('CONTRACTOR_COMPANY-contact')}
              </label>
              <p className="text--dk--flushed">
                <If condition={data.contractor.billing_company}>
                  <span>{data.contractor.billing_company}</span>
                  <br />
                </If>
                <If condition={data.contractor.street}>
                  <span>{data.contractor.street}</span>
                  <br />
                </If>
                <If condition={data.contractor.post_city}>
                  <span>{data.contractor.post_city}</span>
                  <br />
                </If>
                <If condition={data.contractor.billing_website}>
                  <span>
                    <a
                      href={data.contractor.billing_website}
                      target="_blank"
                      rel="noopener noreferrer">
                      {data.contractor.billing_website}
                    </a>
                  </span>
                  <br />
                </If>
                <If condition={data.contractor.billing_telephone}>
                  <span>{data.contractor.billing_telephone}</span>
                  <br />
                </If>
              </p>
            </div>
            <div className="push--bottom">
              <label className="form__label--small" htmlFor="__">
                {l('CONTRACTOR_KEY_USER-contact')}
              </label>
              <If condition={!data.contractor.key_user_id}>
                <p className="text--dk--flushed">-</p>
              </If>
              <If condition={data.contractor.key_user_id}>
                <p className="text--dk--flushed">
                  <If condition={data.contractor.key_user_full_name}>
                    <span>{data.contractor.key_user_full_name}</span>
                    <br />
                  </If>
                  <If condition={data.contractor.key_user_email}>
                    <span>{data.contractor.key_user_email}</span>
                    <br />
                  </If>
                  <If condition={data.contractor.key_user_phone}>
                    <span>{data.contractor.key_user_phone}</span>
                    <br />
                  </If>
                </p>
              </If>
            </div>
          </div>
        ) : (
          <Fragment>
            <div className="push--small--bottom">
              <label className="form__label--small" htmlFor="__">
                {l('JOB-status')}
              </label>
              <p className="text--dk--flushed">
                {getStatusText(contractorStatus)}
              </p>
            </div>
            {contractorStatus !== OfferStatus.CLOSED && (
              <div className="push--small--bottom">
                <label className="form__label--small" htmlFor="__">
                  {l('CONTRACTOR-days_remaining')}
                </label>
                <p className="text--dk--flushed">
                  {getDeadlineText(remainingTime, contractorStatus)}
                </p>
              </div>
            )}
          </Fragment>
        )}
      </div>
      <div className="g g-1/1">
        <div className="fl--column fl--flex-end">
          <div
            className={`form-and-view-container ${
              error_trade_id ? 'form-and-view-container--error' : ''
            }`}>
            <div data-qe-id="action-click-trade_selector">
              <label className="form__label--small" htmlFor="__">
                {l('FORMS-trade')}
              </label>
              {isEditing ? (
                <AllianzSelect
                  placeholder={l('JOB-trades-placeholder')}
                  onValueChange={function(newVal: {
                    value: string,
                    label: string,
                  }): any {
                    return updateField({
                      key: 'trade_id',
                      value: newVal ? newVal.value : null,
                    });
                  }}
                  defaultValue={{
                    label: (data.trade && data.trade.value) || '',
                    value: (data.trade && data.trade.key) || '',
                  }}
                  value={data.trades.reduce(
                    (acc: any, curr: any): string => {
                      if (curr.key === selectedTradeId) {
                        return {
                          label: curr.value,
                          value: curr.key,
                        };
                      }
                      return acc;
                    },
                    { label: '', value: '' },
                  )}
                  options={data.trades.map(i => ({
                    value: i.key,
                    label: i.value,
                  }))}
                />
              ) : (
                <p className="text--dk--flushed">{data.trade.value || ''}</p>
              )}
            </div>
          </div>
          <div className="align--right">
            <EditButton
              show={isEditing && data.contractor}
              showSpacer={isEditing && !data.contractor}
              editAction={openEdit}
              identifier="action-edit-contractor"
            />
          </div>
        </div>
      </div>
    </div>
  </Card>
);

ContractorPanel.defaultProps = {
  data: {
    contractor: null,
  },
  contractorStatus: '',
  remainingTime: 0,
  isEditing: false,
  openEdit: (): any => true,
  error_trade_id: false,
  selectedTradeId: '',
};

const mapState = (state: Object): ReduxStateShape => ({
  error_trade_id: state.jobStore.errors.trade_id,
});

export default connect(mapState)(ContractorPanel);
