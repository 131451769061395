import React from 'react';
import Controls, { canControl } from '../Controls';

const PreviewImage = ({ isFullWidth, data, controls }) => (
  <div className={`image-preview__inner${isFullWidth ? '--wide' : ''}`}>
    {canControl(controls) ? (
      <Controls
        controls={controls}
        transitions={data.state_machine.available_transitions}
        data={data}
      />
    ) : null}
    <div className="image-preview__inner__body">
      <div className="image-preview__inner__body__placeholder">
        <img src={data.blob !== '' ? data.blob : data.url} alt={data.name} />
      </div>
    </div>
  </div>
);

export default PreviewImage;
