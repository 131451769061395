import axios from 'helpers/axios';
import generator from 'constants/generator';

const CONTRACTOR_RATE_REQUEST = generator('CONTRACTOR_RATE');

export function rateContractor(
  { contractor_id, rating, comment },
  successCallback = () => null,
  errorCallback = () => null,
) {
  return dispatch => {
    dispatch({ type: CONTRACTOR_RATE_REQUEST.fetching });
    axios
      .post(`/api/1/contractor_profiles/${contractor_id}/rate`, {
        rating,
        comment,
      })
      .then(resp => {
        dispatch({
          type: CONTRACTOR_RATE_REQUEST.success,
          payload: resp,
        });
        successCallback(resp);
      })
      .catch(err => {
        dispatch({ type: CONTRACTOR_RATE_REQUEST.failed });
        errorCallback(err);
      });
  };
}
