import axios from 'helpers/axios';
import history from 'helpers/history';
import getToken from 'functions/getToken';
import { transitions } from 'machines/constants';
import { ProjectMachine } from 'views/singleProject';
import { resetProject } from 'views/singleProject/actions';
import { getEditorContent } from 'components/WYSIWYG';
import Transitions from './transitions';

const {
  back,
  cancel,
  complete,
  copy,
  erase,
  execute,
  open,
  save,
} = Transitions;

export default function projectActions(projectId, scrollToTop) {
  return {
    back() {
      history.goBack();
      return dispatch =>
        dispatch({
          type: back,
        });
    },
    save() {
      return (dispatch, getStore) => {
        const formData = getStore().projectStore.form;

        const payload = {
          budget: formData.budget,
          concern_id: formData.concern.concern_id,
          concern_type: formData.concern.concern_type,
          contact_audit_id: formData.contact_audit_id,
          contact_audit_type: formData.contact_audit_type,
          occasion: formData.occasion,
          project_owner_id: formData.project_owner.user_id,
          summary: getEditorContent('#js-redactor-editor'),
          target_date: formData.target_date,
          title: formData.title,
          internal_documents: formData.documents
            .filter(d => d.source_tag === 'internal')
            .map(item => item.id),
          project_team_documents: formData.documents
            .filter(d => d.source_tag === 'project_team')
            .map(item => item.id),
          project_supports: formData.project_supports.map(v => v.user_id),
        };

        return projectRequest(
          dispatch,
          projectId,
          `/api/1/projects/create`,
          save,
          payload,
          nextProjectId => {
            history.push(`/projects/${nextProjectId}`);
          },
        );
      };
    },
    copy(_, refreshList) {
      return dispatch =>
        projectRequest(
          dispatch,
          projectId,
          `/api/1/projects/${projectId}/action/copy`,
          { ...copy },
          undefined,
          nextProjectId => {
            history.push(`/projects/${nextProjectId}`);
            refreshList();
            ProjectMachine.triggerTransition(transitions.to_load, {
              project_id: nextProjectId,
            });
          },
        );
    },
    open(props, refreshList) {
      return dispatch =>
        projectRequest(
          dispatch,
          projectId,
          `/api/1/projects/${projectId}/action/open`,
          open,
          props,
          nextProjectId => {
            refreshList();
            ProjectMachine.triggerTransition(transitions.to_load, {
              project_id: nextProjectId,
            });
          },
        );
    },
    project_export() {
      return () => {
        document.location.assign(
          `/exports/${projectId}/project.xlsx?token=${getToken()}`,
        );
      };
    },
    erase() {
      return dispatch =>
        projectRequest(
          dispatch,
          projectId,
          `/api/1/projects/${projectId}/action/erase`,
          erase,
          undefined,
          () => {
            history.push('/projects');
          },
        );
    },
    cancelEdit() {
      return dispatch => {
        dispatch(resetProject());
        ProjectMachine.triggerTransition(transitions.to_visible);
      };
    },
    update() {
      return async (dispatch, getState) => {
        const nextProjectId = getState().projectStore.data.project_id;
        await ProjectMachine.triggerTransition(transitions.to_update, {
          project_id: nextProjectId,
        });
      };
    },
    cancel() {
      return dispatch =>
        projectRequest(
          dispatch,
          projectId,
          `/api/1/projects/${projectId}/action/cancel`,
          cancel,
          undefined,
          nextProjectId => {
            ProjectMachine.triggerTransition(transitions.to_load, {
              project_id: nextProjectId,
            });
          },
        );
    },
    execute() {
      return dispatch =>
        projectRequest(
          dispatch,
          projectId,
          `/api/1/projects/${projectId}/action/execute`,
          execute,
          null,
          nextProjectId => {
            ProjectMachine.triggerTransition(transitions.to_load, {
              project_id: nextProjectId,
            });
          },
        );
    },
    edit() {
      return () => {
        scrollToTop();
        ProjectMachine.triggerTransition(transitions.to_editing);
      };
    },
    create_job_direct({ concern_type, concern_id }) {
      return () => {
        history.push(
          `${history.location.pathname}/${concern_type}/${concern_id}/assign-job`,
        );
      };
    },
    create_job_offer() {
      return () => {
        history.push(`${history.location.pathname}/open-job`);
      };
    },
    complete() {
      return dispatch =>
        projectRequest(
          dispatch,
          projectId,
          `/api/1/projects/${projectId}/action/complete`,
          complete,
          null,
          nextProjectId => {
            ProjectMachine.triggerTransition(transitions.to_load, {
              project_id: nextProjectId,
            });
          },
        );
    },
    add_payment() {
      return () => history.push(`${history.location.pathname}/add-payment`);
    },
  };
}

function projectRequest(
  dispatch,
  projectId,
  endpoint,
  { fetching, success, failed },
  additional = {},
  callback = () => true,
) {
  dispatch({ type: fetching, projectId });
  return axios
    .post(endpoint, {
      projectId,
      ...additional,
    })
    .then(res => {
      dispatch({
        type: success,
        projectId,
        payload: res.data,
      });
      return callback(res.data.payload.project_id);
    })
    .catch(res =>
      dispatch({
        type: failed,
        projectId,
        payload: res,
        data: res.data.payload,
      }),
    );
}
