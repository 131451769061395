import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  LabelList,
  Bar,
} from 'recharts';
import { fC } from 'helpers/locale';

export default ({
  data,
  barHeight = 20,
  barGap = 10,
  numberKey = 'total',
  categoryKey = 'name',
  valueFormatter = fC,
  margin = {
    top: 5,
    right: 100,
    left: 0,
    bottom: 0,
  },
}) => (
  <ResponsiveContainer
    width="99%" // workaround for caculating width properly fpr responsive sizing
    height={data.length * (barHeight + barGap) + margin.top}>
    <BarChart
      barCategoryGap={barGap}
      layout="vertical"
      data={data}
      margin={margin}>
      <Bar barSize={barHeight} dataKey={numberKey} minPointSize={0}>
        <LabelList
          width={100}
          dataKey={numberKey}
          position="right"
          offset={5}
          formatter={valueFormatter}
        />
      </Bar>
      <YAxis
        tickLine={false}
        width={150}
        interval={0}
        dataKey={categoryKey}
        type="category"
        // tickFormatter={formatString}
      />
      <XAxis
        padding={{ right: 20 }}
        tickCount={0}
        axisLine={false}
        tickLine={false}
        dataKey={numberKey}
        type="number"
      />
    </BarChart>
  </ResponsiveContainer>
);
