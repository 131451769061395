/** @format */

// @flow
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Card from 'components/Card';
import SimpleList from 'components/ConnectedSimpleList';
import l, { fC } from 'helpers/locale';
import PaymentListGroup from './PaymentListGroup';
import PaymentListItem from './PaymentListGroup/PaymentListItem';

type PaymentDataShape = {
  payment_id: string,
  payment_id_raw: number,
  job_id: string,
  job_id_raw: number,
  contractor: Array<string>,
  entry_date: string,
  invoice_date: string,
  total: number,
  payment_type: string,
  bkp_id: string,
  bkp_code: string,
  bkp_desc: string,
  bkp_item: string,
};

type PropsShape = {
  api: string,
  list_name: string,
  enableDetailView?: boolean,
  history: Object,
  match: Object,
};

class PaymentListPanel extends Component {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      grouping: 'none',
      editable: false,
      headings: this.getHeadings(),
    };

    this.extras = this.props.enableDetailView
      ? {
          bgcAlt: true,
        }
      : {};
  }

  getHeadings = (editable = false) => {
    const baseHeadings = [
      {
        name: 'job_id',
        sortable: true,
        text: l('PAYMENT-COL-id'),
        flex: '10%',
      },
      {
        name: 'contractor',
        sortable: false,
        text: l('PAYMENT-COL-contractor'),
        flex: '25%',
      },
      {
        name: 'entry_date',
        sortable: true,
        text: l('PAYMENT-COL-booking_date'),
        flex: '15%',
      },
      {
        name: 'invoice_date',
        sortable: true,
        text: l('PAYMENT-COL-date_of_payment'),
        flex: '15%',
      },
      {
        name: 'payment_type',
        text: l('PAYMENT-COL-payment_type'),
        flex: '15%',
      },
      {
        name: 'total',
        sortable: true,
        text: l('PAYMENT-COL-total'),
        customFormatter: fC,
        flex: editable ? '15%' : '20%',
        align: 'right',
      },
    ];
    return editable
      ? [
          ...baseHeadings,
          {
            name: 'edit',
            sortable: false,
            text: '',
            flex: '5%',
          },
        ]
      : baseHeadings;
  };

  setHeadings = headings => {
    this.setState({
      headings,
    });
  };

  setGrouping = value => {
    this.setState({ grouping: value });
  };

  checkItemsEditable = rows => {
    const newEditableValue = rows.some(item => item.editable);
    if (this.state.editable !== newEditableValue) {
      this.setState(
        () => ({ editable: newEditableValue }),
        () => this.setHeadings(this.getHeadings(newEditableValue)),
      );
    }
  };

  render = () => (
    <Fragment>
      <Card title="PAYMENT-plural" isOpen={false}>
        <SimpleList
          api={this.props.api}
          {...this.extras}
          presetQuery={{ grouping: 'none' }}
          name={this.props.list_name}
          headings={this.state.headings}
          showDatepicker
          filterOpts={[
            {
              text: l('PAYMENT_PREDEFINED-WITH_JOBS'),
              value: 'jobs:true',
            },
            {
              text: l('PAYMENT_PREDEFINED-WITHOUT_JOBS'),
              value: 'jobs:false',
            },
          ]}
          allowClick
          clickAct={item =>
            this.props.history.push(
              `${this.props.location.pathname}/edit-payment/${item.payment_id_raw}`,
            )
          }
          groupSelection={[
            { label: l('PAYMENT_GROUP-NONE'), value: 'none', default: true },
            { label: l('BKP-level_1'), value: 'bkp_1' },
            { label: l('BKP-level_2'), value: 'bkp_2' },
            { label: l('BKP-level_3'), value: 'bkp_3' },
          ]}
          groupSelectionCB={this.setGrouping}>
          {this.state.grouping !== 'none'
            ? rows => {
                this.checkItemsEditable(rows);
                let currentGroup = false;
                let groupCount = 0;
                const groups = [];
                rows.forEach((item: PaymentDataShape, itemKey: number) => {
                  if (
                    currentGroup === false ||
                    item.bkp_code !== currentGroup
                  ) {
                    currentGroup = item.bkp_code;
                    const lastIndexOfGroup = rows
                      .map(el => el.bkp_code)
                      .lastIndexOf(currentGroup);
                    const groupRows = rows.slice(itemKey, lastIndexOfGroup + 1);
                    const title = groupRows[0].bkp_item
                      ? groupRows[0].bkp_item
                      : l('GROUPED_LIST-TITLE-UNKNOWN');
                    const total = groupRows
                      .map(row => row.total)
                      .reduce((pv, cv) => pv + cv, 0);
                    groupCount += 1;
                    groups.push(
                      <PaymentListGroup
                        key={groupCount}
                        groupRows={groupRows}
                        title={title}
                        total={total}
                        status=""
                        headings={this.state.headings}
                      />,
                    );
                  }
                });
                return groups;
              }
            : rows => {
                this.checkItemsEditable(rows);
                return rows.map((item, itemKey) => (
                  <PaymentListItem
                    headings={this.state.headings}
                    item={item}
                    key={itemKey}
                  />
                ));
              }}
        </SimpleList>
      </Card>
    </Fragment>
  );
}

PaymentListPanel.defaultProps = {
  enableDetailView: true,
};

export default withRouter(PaymentListPanel);
