/** @format */

import l from 'helpers/locale';

const langCodes = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
};

export default function({
  salutations,
  invitation,
  trades,
  countries,
  default_country_id,
}) {
  const selectedSalutation =
    (invitation.salutation &&
      Object.keys(salutations).length &&
      salutations.find(item => item.id === invitation.salutation.id)) ||
    null;
  return [
    {
      title: 'INVITATION-personal_account-title',
      subtitle: 'INVITATION-personal_account-intro',
      sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
      fields: [
        {
          type: 'select',
          id: '1',
          wrappingClass: 'g g-1/2 g--fill',
          innerClass: 'form__item',
          name: 'salutation_id',
          mandatory: true,
          label: 'NOMINATION_FORM-select_salutation',
          options: salutations.map(v => ({
            value: `${v.id}`,
            label: `${v.description}`,
          })),
          default:
            (selectedSalutation && {
              value: `${selectedSalutation.id}`,
              label: `${selectedSalutation.description}`,
            }) ||
            null,
        },
        {
          type: 'text',
          id: '2',
          mandatory: true,
          name: 'name',
          label: 'NOMINATION_FORM-first_name',
          itemClass: 'g g-1/2 g--fill',
          value: invitation.nomination_first_name,
        },
        {
          type: 'text',
          id: '3',
          mandatory: true,
          name: 'surname',
          label: 'NOMINATION_FORM-last_name',
          itemClass: 'g g-1/2 g--fill',
          value: invitation.nomination_last_name,
        },
        {
          type: 'phone',
          id: '4',
          mandatory: true,
          name: 'telephone',
          label: 'NOMINATION_FORM-phone',
          description: 'NOMINATION_FORM-phone-description',
          itemClass: 'g g-1/1',
        },
        {
          type: 'text',
          id: '5',
          name: 'email',
          mandatory: true,
          label: 'NOMINATION_FORM-email',
          description: 'NOMINATION_FORM-email-description',
          itemClass: 'g g-1/1',
          value: invitation.nomination_email,
        },
        {
          type: 'select',
          mandatory: true,
          id: '6',
          wrappingClass: 'g g-1/2 g--fill',
          innerClass: 'form__item',
          name: 'language',
          label: 'NOMINATION_FORM-select_language',
          default: invitation.nomination_langcode
            ? {
                value: invitation.nomination_langcode,
                label: langCodes[invitation.nomination_langcode],
                name: langCodes[invitation.nomination_langcode],
              }
            : {
                value: 'en',
                label: 'English',
                name: 'English',
              },
          options: Object.keys(langCodes).map(v => ({
            value: v,
            label: langCodes[v],
            name: langCodes[v],
          })),
        },
        {
          type: 'password',
          id: '7',
          name: 'password',
          mandatory: true,
          label: 'NOMINATION_FORM-password',
          description: 'NOMINATION_FORM-password-description',
          itemClass: 'g g-1/1',
        },
        {
          type: 'password',
          id: '8',
          mandatory: true,
          name: 'password_confirm',
          label: 'NOMINATION_FORM-password_confirm',
          itemClass: 'g g-1/2 g--fill',
        },
      ],
    },
    getBillingInfoFields({
      vmp_contractor: {
        company: invitation.nomination_company,
        street: invitation.street,
        zip: invitation.zip,
        city: invitation.city,
        company_phone: invitation.phone,
      },
      default_country_id,
      countries,
    }),
    getTradesFields(trades),
    getBankInfoFields(),
    {
      title: 'GENERAL-terms',
      subtitle: l('GENERAL-terms-intro'),
      sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
      fields: [
        {
          type: 'checkbox',
          id: '6',
          wrappingClass: 'g g-1/1',
          innerClass: 'g g-1/1 fl--row',
          name: 'profile[accept]',
          reverse: true,
          options: [
            {
              label: 'NOMINATION_FORM-accept_conditions',
              value: 'NOMINATION_FORM-accept_conditions',
              inputClass: 'push--small--right',
            },
          ],
        },
      ],
    },
  ];
}

export const getBillingInfoFields = ({
  vmp_contractor,
  default_country_id,
  countries,
}) => {
  const defaultCountry = countries.filter(
    v => parseInt(v.key, 10) === parseInt(default_country_id, 10),
  );
  return {
    title: 'NOMINATION-billing-card_title',
    sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
    fields: [
      {
        type: 'text',
        id: '1',
        name: 'company',
        mandatory: true,
        label: 'NOMINATION_FORM-company',
        itemClass: 'g g-1/2 g--fill',
        value: vmp_contractor.company || '',
      },
      {
        type: 'text',
        id: '1',
        name: 'company_2',
        mandatory: false,
        label: 'NOMINATION_FORM-company_2',
        itemClass: 'g g-1/2 g--fill',
        value: vmp_contractor.company_2 || '',
      },
      {
        type: 'text',
        id: '2',
        name: 'street',
        mandatory: true,
        label: 'NOMINATION_FORM-street',
        itemClass: 'g g-1/2 g--fill',
        value: vmp_contractor.street || '',
      },
      {
        type: 'text',
        id: '2',
        name: 'pobox',
        mandatory: false,
        label: 'NOMINATION_FORM-pobox',
        itemClass: 'g g-1/2 g--fill',
        value: vmp_contractor.pobox || '',
      },
      {
        type: 'text',
        id: '3',
        name: 'zip',
        mandatory: true,
        label: 'NOMINATION_FORM-zip',
        itemClass: 'g g-1/2 g--fill',
        value: vmp_contractor.zip,
      },
      {
        type: 'text',
        id: '4',
        name: 'city',
        mandatory: true,
        label: 'NOMINATION_FORM-city',
        itemClass: 'g g-1/2 g--fill',
        value: vmp_contractor.city,
      },
      {
        type: 'select',
        mandatory: true,
        id: '6',
        wrappingClass: 'g g-1/2 g--fill',
        innerClass: 'form__item',
        name: 'country',
        label: 'NOMINATION_FORM-select_country',
        default: defaultCountry.map(v => ({
          value: v.key,
          label: v.value,
          name: v.value,
        }))[0],
        options: countries.map(v => ({
          value: v.key,
          label: v.value,
        })),
      },
      {
        type: 'phone',
        id: '5',
        name: 'company_phone',
        mandatory: true,
        label: 'NOMINATION_FORM-company_phone',
        description: 'NOMINATION_FORM-company_phone-description',
        itemClass: 'g g-1/1',
        value: vmp_contractor.company_phone || '',
      },
      {
        type: 'text',
        id: '6',
        name: 'company_email',
        mandatory: true,
        label: 'NOMINATION_FORM-company_email',
        itemClass: 'g g-1/2 g--fill',
        value: vmp_contractor.company_email || '',
      },
      {
        type: 'text',
        id: '7',
        name: 'website',
        label: 'NOMINATION_FORM-website',
        description: 'NOMINATION_FORM-website-description',
        itemClass: 'g g-1/1',
        value: vmp_contractor.website || '',
      },
      {
        type: 'text',
        id: '8',
        name: 'uid_number',
        label: 'NOMINATION_FORM-uid_number',
        description: 'NOMINATION_FORM-uid_number-description',
        itemClass: 'g g-1/1',
        value: vmp_contractor.uid_number || '',
      },
    ],
  };
};

export const getTradesFields = (trades, selectedTrades = []) => ({
  title: l('CONTRACTOR-trades'),
  sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
  fields: [
    {
      type: 'checkbox',
      id: '9',
      label: l('CONTRACTOR-trades'),
      mandatory: true,
      name: 'trades',
      wrappingClass: 'g g-1/1',
      innerClass: 'g g-1/3 fl--row',
      groupTitleContainerClass: 'g g-1/1',
      groupTitleClass: '',
      groupSubtitle: l('NOMINATION_FORM-trade-intro'),
      reverse: true,
      options: trades
        ? trades.map(v => ({
            label: v.name,
            value: v.id,
            inputClass: 'push--small--right',
          }))
        : [],
      selectedValues: selectedTrades,
    },
  ],
});

export const getBankInfoFields = ({ vmp_contractor } = {}) => ({
  title: 'INVITATION-bank_information-title',
  sectionClass: 'bgc--ntrl-min soft push--bottom u-shadows--base',
  fields: [
    {
      type: 'text',
      id: '1',
      mandatory: true,
      name: 'bank_address',
      label: 'NOMINATION_FORM-bank_address',
      itemClass: 'g g-1/2 g--fill',
      value: vmp_contractor?.bank_address || '',
    },
    {
      type: 'text',
      id: '2',
      mandatory: true,
      name: 'iban',
      label: 'NOMINATION_FORM-iban',
      itemClass: 'g g-1/2 g--fill',
      value: vmp_contractor?.iban || '',
    },
  ],
});
