// @flow
import { ids as Status } from 'constants/projectStatus';
import StateMachine from 'machines/reduxMachine';
import { transitions, PanelStates } from 'machines/constants';
import {
  State,
  Transition,
  Edge as EdgeBuilder,
  Conditions,
} from 'async-state-machine';

import ProjectConditions from '../../conditions';

const {
  isProjectOwner,
  isProjectStateWithin,
  isProjectSupport,
} = ProjectConditions;
const { and, or } = Conditions;

// Transitions for accounts card
export const to_visible = Object.create(Transition).setName(
  transitions.to_visible,
);

export const to_editing = Object.create(Transition)
  .setName(transitions.to_editing)
  .addCondition((context, params) =>
    and([
      or([isProjectOwner(params), isProjectSupport(params)]),
      isProjectStateWithin(params, [Status.DRAFT]),
    ]),
  );

function generateStateMachine() {
  const Edge = new EdgeBuilder();

  // States for Accounts Card
  const HIDDEN = Object.create(State).setName(PanelStates.HIDDEN);
  const VISIBLE = Object.create(State).setName(PanelStates.VISIBLE);
  const EDITING = Object.create(State).setName(PanelStates.EDITING);

  // Register Machine
  const Machine = new StateMachine('object_general_finance_cards')
    .registerEdge(
      Edge.new()
        .transition(to_visible)
        .from([HIDDEN, EDITING])
        .to(VISIBLE),
    )
    .registerEdge(
      Edge.new()
        .transition(to_editing)
        .from([VISIBLE])
        .to(EDITING),
    )
    .setInitialState(HIDDEN, {});

  return Machine;
}

export default generateStateMachine;
