/** @format */

// @flow
import React from 'react';
import assign from 'object.assign';
import GetSvg from 'components/GetSvg';

import l from 'helpers/locale';

type ReduxStateShape = {};

type PropsShape = {
  callback: number => any,
  close: () => any,
  rating?: number,
  errors?: [],
} & ReduxStateShape;

type StateShape = {
  rating: number,
  message: string,
};

class RatingModal extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    rating: 0,
    errors: [],
  };

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      rating: props.rating || 0,
      comment: '',
    };
  }

  state: StateShape;

  submitRating = () =>
    this.props.callback(this.state.rating, this.state.comment);

  tempRating = (n: number) =>
    this.setState((prevState: StateShape): StateShape =>
      assign({}, prevState, {
        rating: n,
      }),
    );

  updateState = (name, value) => {
    this.setState({ [name]: value });
  };

  closeRating = (e: Object) => this.props.close(e);

  onCommentChange = e => {
    e.preventDefault();
    this.updateState('comment', e.target.value);
  };

  render() {
    const canSubmit = this.state.rating !== 0;
    const { errors } = this.props;
    return (
      <div className="wrap--small">
        <div className="layout-container">
          <div className="layout-container__header">
            <div className="gw">
              <div className="g g-1/1">
                <h2 className="align--left">{l('RATINGS-title')}</h2>
              </div>
            </div>
          </div>
          <div className="layout-container__body">
            <p>{l('CONTRACTOR-ACTIONS-RATE-description')}</p>
            <textarea
              id="rating-comment"
              rows="15"
              name="comment"
              onChange={this.onCommentChange}
            />
            {errors?.comment && <p className="fc--danger">{errors.comment}</p>}
            <div>
              {[1, 2, 3, 4, 5].map((item: number, k: number) => (
                <span key={item} className="push--small--right">
                  <button
                    type="button"
                    data-qe-id={`action-click-star_${item}`}
                    className="star-btn"
                    onClick={(e: Object) => {
                      e.preventDefault();
                      return this.tempRating(item);
                    }}>
                    <GetSvg
                      key={k}
                      svg={`star${
                        item <= this.state.rating ? '_full' : '_empty'
                      }`}
                      wrapperClass={`${'stuff'}`}
                    />
                    <span className="star-btn__text">
                      {l(`RATING_${item}`)}
                    </span>
                  </button>
                </span>
              ))}
            </div>

            {errors?.rating && <p className="fc--danger">{errors.rating}</p>}
          </div>
          <div className="layout-container__footer">
            <span className="layout-container__footer__item">
              <button
                type="button"
                data-qe-id="action-click-popup_cancel"
                className="btn--text"
                onClick={this.closeRating}>
                {l('cancel')}
              </button>
            </span>
            <span className="layout-container__footer__item">
              <button
                disabled={!canSubmit}
                type="button"
                data-qe-id="action-click-popup_rate"
                className="btn--quinary"
                onClick={(e: any): boolean => {
                  e.preventDefault();
                  this.submitRating();
                  return true;
                }}>
                {l('JOB-ACTIONS-JOB_ACTIONS_RATE')}
              </button>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default RatingModal;
