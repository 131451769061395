import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { StateConditional } from 'machines/components/RenderConditional';
import ProjectChart from 'components/ProjectChart';
import { PanelStates } from 'machines/constants';
import Card from 'components/Card';
import If from 'components/Conditions/If';
import { connect } from 'react-redux';
import MachineBuilder from './machine';

export const ChartCardMachine = MachineBuilder();

const ChartCard = ({ graph, machine_state }) => {
  const defaultWidth = 960;
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(defaultWidth);

  const reportCardContainerSize = () => {
    setContainerWidth(containerRef?.current?.offsetWidth || defaultWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', reportCardContainerSize);
    return () => {
      window.removeEventListener('resize', reportCardContainerSize);
    };
  }, []);

  useLayoutEffect(() => {
    if (machine_state === PanelStates.VISIBLE) {
      reportCardContainerSize();
    }
  }, [machine_state]);

  return (
    <If condition={graph !== null}>
      <StateConditional state={machine_state} whenState={[PanelStates.VISIBLE]}>
        <Card
          title="PROJECT-graph-title"
          isOpen
          canCollapse
          identifier="project-graph"
          qeId="project_card-graph"
          containerRef={containerRef}>
          <div className="gw">
            <div className="g g-1/2">
              <ProjectChart parentWidth={containerWidth} graph={graph} />
            </div>
          </div>
        </Card>
      </StateConditional>
    </If>
  );
};

const mapState = ({ projectStore, machineStore }) => ({
  machine_state: machineStore.chart_card,
  graph: projectStore.data.graph,
});

export default connect(mapState)(ChartCard);
