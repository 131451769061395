import React, { Fragment } from 'react';
import PDF from 'react-pdf-js';
import GetSvg from 'components/GetSvg';
import Control from 'components/Preview/Controls/item';

type PropsShape = {
  customControls: Array<*>,
  data: Object,
  description?: Function | void,
};

class PDFPreview extends React.Component<PropsShape> {
  static defaultProps = {
    description: () => null,
  };

  constructor(props) {
    super(props);
    this.pdfBody = React.createRef();
  }

  state = {};

  onDocumentComplete = pages => {
    this.setState({ page: 1, pages });
  };

  handlePrevious = () => {
    this.setState(prev => ({ page: prev.page - 1 }));
  };

  handleNext = () => {
    this.setState(prev => ({ page: prev.page + 1 }));
  };

  renderControls = (page, pages) => {
    const groupClassName = 'pdf-preview__footer__group';
    const itemClassName = 'image-preview__inner__controls__item';
    return (
      <Fragment>
        <div className={groupClassName}>
          {this.props.customControls.map((control, key) => (
            <Fragment key={key}>
              <Control
                item={control}
                // className={itemClassName}
                wrappingClassName={itemClassName}
                data={this.props.data}
                showPreviewButton={false}
              />
            </Fragment>
          ))}
        </div>
        <div className={`${groupClassName}--right`}>
          <div className={itemClassName}>
            <button
              type="button"
              disabled={page === 1}
              className="btn-svg--base"
              onClick={this.handlePrevious}>
              <GetSvg svg="angle_left" />
            </button>
          </div>
          <div className={itemClassName}>
            <button
              type="button"
              disabled={page === pages}
              className="btn-svg--base"
              onClick={this.handleNext}>
              <GetSvg svg="angle_right" />
            </button>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    let controls = null;
    if (this.state.pages) {
      controls = this.renderControls(this.state.page, this.state.pages);
    }
    return (
      <div className="pdf-preview">
        <div className="pdf-preview__body" ref={this.pdfBody}>
          <PDF
            file={this.props.data.url}
            onDocumentComplete={this.onDocumentComplete}
            page={this.state.page}
            scale={1}
            containerRef={this.pdfBody.current}
          />
        </div>
        <div className="pdf-preview__footer">{controls}</div>
        {typeof this.props.description === 'function'
          ? this.props.description()
          : null}
      </div>
    );
  }
}

PDFPreview.defaultProps = {
  pdfClass: 'image-preview__inner__body__pdf',
  navClass: 'image-preview__inner__body__paginator',
};

export default PDFPreview;
