import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { Overlay } from '@bonlineza/b-lib';

/* Components for Routing */

import { requireAuth, hasAuth } from '../functions/authenticator.func.js';
import * as AuthActions from '../actions/auth.js';
import { setLocale } from '../actions/app.js';
import Login from '../components/Login/HomeLogin.comp.js';
import ForgotPassword from '../components/ForgotPassword/ForgotPasswordWrapper.js';
import MapComp from '../components/Map/SafeMap';
import mapConfig from '../components/Map/mapConfig.js';

const App = ({
  authActions,
  isAuth,
  history,
  redirectPath,
  setDefaultLang,
}) => {
  // Dont update state during render - useEffect
  useEffect(() => {
    setDefaultLang(window.localStorage.getItem('_sl') || undefined);
  }, [setDefaultLang]);

  const Authed = () => {
    if (redirectPath !== '') {
      return <Redirect to={redirectPath} />;
    }
    history.push('/dashboard'); // this push will be picked up by the primary route switch
    return null;
  };

  const UnAuthed = () => (
    <div className="layout-bg">
      <div className="layout-bg__bg">
        <MapComp
          mapConfig={{
            ...mapConfig,
            options: {
              ...mapConfig.options,
              panControl: false,
              zoomControl: false,
              scrollwheel: false,
              gestureHandling: 'none',
            },
          }}
        />
      </div>
      <div className="layout-bg__header">
        <a href="/">
          <img
            className="layout-bg__logo"
            alt="Allianz"
            src="/images/logo.png?1"
            height="40"
          />
        </a>
      </div>
      <div className="layout-bg__content">
        <Overlay isOpen>
          <Login />
          <ForgotPassword />
        </Overlay>
      </div>
    </div>
  );

  return (
    <div>
      <div>
        {isAuth && hasAuth() ? (
          <Authed />
        ) : (
          requireAuth(isAuth, authActions.isAuth)(Authed)(UnAuthed)
        )}
      </div>
    </div>
  );
};

App.propTypes = {
  authActions: PropTypes.object.isRequired,
  isAuth: PropTypes.bool.isRequired,
};

const mapState = ({ authStore, appStore }) => ({
  isAuth: authStore.auth,
  redirectPath: appStore.redirectPath,
  currentLocale: appStore.locale,
});

const mapDispatch = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  setDefaultLang(lang) {
    return dispatch(setLocale(lang));
  },
});

export default withRouter(connect(mapState, mapDispatch)(App));
