/** @format */

import axios from '../../../helpers/axios';
import Actions from '../constants';

import {
  requestSuccess,
  requestError,
  requestRequest,
} from '../../../functions/actionRequests.func.js';

const { success, failed, fetching } = Actions;

export function requestForgotPassword({ email }) {
  return function requestForgotPasswordThunk(dispatch) {
    dispatch(requestRequest(fetching));

    axios
      .post('/api/1/password/email', {
        email,
        redirect: '/reset-password?q=',
      })
      .then(res => dispatch(requestSuccess(success, res)))
      .catch(err => dispatch(requestError(failed, err)));
  };
}
