/** @format */

// @flow

import React from 'react';
import type { DocumentType } from 'views/singleJob/constants';
import GenericDocumentsPanel from 'shared/jobs/Panels/items/GenericDocuments';

type PropsShape = {
  isEditing?: boolean,
  jobState: number | string,
  data: Object,
  listName: string,
  list?: [DocumentType],
  controls: Array<*>,
};

// todo: add Job wrapper card for this Panel wrapper

const ProjectTeamDocsPanelWrapper = ({
  isEditing,
  jobState,
  data,
  list,
  listName,
  controls,
}: PropsShape): React$Element<*> => (
  <GenericDocumentsPanel
    editMode={isEditing}
    data={data}
    canEdit={jobState === 'draft'}
    listName={listName}
    list={list}
    controls={controls}
    isOpen={false}
    title="PROJECT_TEAM_DOCS-title"
    identifier="action-drop-project_team_document"
    footer="DOCUMENTS-for_project-team_use"
  />
);

ProjectTeamDocsPanelWrapper.defaultProps = {
  isEditing: false,
  list: [],
};

export default ProjectTeamDocsPanelWrapper;
