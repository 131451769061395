/** @format */

// @flow
import React from 'react';
import Card from 'components/Card';
import MapComp from 'components/Map/SafeMap';
import l, { fC } from 'helpers/locale';
import { parseDate } from 'helpers/dates';
import GetSvg from 'components/GetSvg';
import { withRouter } from 'react-router-dom';
import ComplianceField from '../components/ComplianceField';

type PropsShape = {
  contact: string[],
  trades: string[],
  uid: string,
  lastJob: number,
  earnings: string,
  totalJobs: string,
  geocode: {
    lat?: string | number,
    lng?: string | number,
  },
  job_locations: Array<{
    lat?: string | number,
    lng?: string | number,
  }>,
  lastQuestionnaire: string,
  questionnaireStatus: string,
  canEditProfile: boolean,
};

const MainCard = ({
  contact,
  trades,
  uid,
  lastJob,
  earnings,
  totalJobs,
  geocode,
  job_locations,
  lastQuestionnaire,
  questionnaireStatus,
  status,
  canEditProfile,
  history,
  match,
}: PropsShape) => {
  const viewProfile = e => {
    e.preventDefault();
    history.push(`/contractors/${match.params.contractor_id}/profile`);
  };
  return (
    <Card showHeader={false} hasPadding={false} isOpen canCollapse={false}>
      <div className="gw">
        <div className="g g-1/2">
          <div className="soft--small">
            <div className="gw">
              <div className="g g-1/1">
                <div className="gw">
                  <div className="g g-1/2">
                    <div className="push--bottom">
                      <label className="form__label--small" htmlFor="__">
                        {l('CONTRACTOR-billing_address')}
                      </label>
                      <p className="text--dk--flushed">
                        {contact.map((v: string, vk: number) => (
                          <span className="display--block" key={vk}>
                            {v}
                          </span>
                        ))}
                      </p>
                    </div>
                  </div>
                  <div className="g g-1/2">
                    <div className="push--bottom">
                      <label className="form__label--small" htmlFor="__">
                        {l('CONTRACTOR-trades')}
                      </label>
                      <p className="text--dk--flushed">
                        {trades.length
                          ? trades.map((v: string, vk: number) => (
                              <span className="display--block" key={vk}>
                                {v}
                              </span>
                            ))
                          : '-'}
                      </p>
                    </div>
                    <div className="push--bottom">
                      <label className="form__label--small" htmlFor="__">
                        {l('CONTRACTOR-uid')}
                      </label>
                      <p className="text--dk--flushed">{uid}</p>
                    </div>
                  </div>
                </div>
                <div className="gw">
                  <div className="g g-1/2">
                    <div className="push--bottom">
                      <label className="form__label--small" htmlFor="__">
                        {l('CONTRACTOR-last_job')}
                      </label>
                      <p className="text--dk--flushed">{parseDate(lastJob)}</p>
                    </div>
                  </div>
                  <div className="g g-1/2">
                    <div className="push--bottom">
                      <label className="form__label--small" htmlFor="__">
                        {l('CONTRACTOR-onboarding_status')}
                      </label>
                      <p className="texticon--dk--flushed v-align--center">
                        <span
                          className={`${
                            status === 'admitted'
                              ? 'fc--primary'
                              : 'fc--base-lt'
                          }`}>
                          <GetSvg svg="user" wrapperClass="" />
                        </span>
                        <span className="text--dk--flushed">
                          {l(`NOMINATION_STATE-${status}`)}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="gw">
                  <div className="g g-1/2">
                    <div className="push--bottom">
                      <ComplianceField
                        complianceStatus={questionnaireStatus}
                        complianceDate={lastQuestionnaire}
                      />
                    </div>
                  </div>
                  {canEditProfile ? (
                    <div className="g g-1/2">
                      <div className="fl--align-end fl--direction-rr">
                        <button
                          type="button"
                          className="btn--text--primary"
                          onClick={viewProfile}>
                          {l('CONTRACTOR-MAIN_CARD-edit_company_data')}
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
                <hr />
                <div className="gw">
                  <div className="g g-4/8">
                    <div className="push--bottom">
                      <label className="form__label--small" htmlFor="__">
                        {l('HOUSE-order_volume')}
                      </label>
                      <p className="text--dk--flushed--large">{fC(earnings)}</p>
                    </div>
                  </div>
                  <div className="g g-1/3">&nbsp;</div>
                  <div className="g g-1/8">
                    <div className="push--bottom">
                      <label className="form__label--small" htmlFor="__">
                        {l('JOB-plural')}
                      </label>
                      <p className="text--dk--flushed--large">
                        {parseInt(totalJobs, 10) || '-'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="g-1/2">
          <MapComp
            markers={job_locations}
            zoom={15}
            center={
              Object.prototype.hasOwnProperty.call(geocode, 'lat') &&
              Object.prototype.hasOwnProperty.call(geocode, 'lng')
                ? {
                    lat: parseFloat(geocode.lat) || '',
                    lng: parseFloat(geocode.lng) || '',
                  }
                : undefined
            }
          />
        </div>
      </div>
    </Card>
  );
};

export default withRouter(MainCard);
