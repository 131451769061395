/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { allowedViews } from '../../functions/permissions.func.js';
import l from '../../helpers/locale';
import GetSvg from '../GetSvg';

import type {
  ViewPermissionsType,
  ViewPermissionsItemType,
} from '../../functions/permissions.func.js';

type StateShape = {
  expanded: boolean,
  items: ViewPermissionsType,
  token: string,
};

type PropsShape = {
  roleId: number,
  token: string,
};

class Sidebar extends React.Component<PropsShape, StateShape> {
  static getDerivedStateFromProps(props: PropsShape, state: StateShape): any {
    if (props.token !== state.token) {
      return {
        items: allowedViews(props.roleId, props.token),
        token: props.token,
      };
    }

    return null;
  }

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      token: props.token,
      expanded: false,
      items: allowedViews(this.props.roleId, this.props.token),
    };
  }

  state: StateShape;

  toggle = (): boolean => {
    this.setState((prevState: StateShape): StateShape => ({
      expanded: !prevState.expanded,
      items: prevState.items,
    }));

    return true;
  };

  render(): React$Element<*> {
    return (
      <div
        className={`sidebar ${this.state.expanded ? 'sidebar--expanded' : ''}`}>
        <div className="sidebar__inner">
          <div className="sidebar__inner__body">
            <ul className="sidebar__inner__list">
              {this.state.items.map(
                (item: ViewPermissionsItemType): React$Element<*> => (
                  <li
                    className="sidebar__inner__list__item"
                    key={item.name}
                    data-qe-id={`action-sidebar_click-${item.name}`}>
                    <Link to={`/${item.name}`}>
                      <span className="sidebar__inner__list__item__visible">
                        <GetSvg svg={item.icon} title={l(item.title)} />
                      </span>
                      <span className="sidebar__inner__list__item__buried">
                        {l(item.title)}
                      </span>
                    </Link>
                  </li>
                ),
              )}
            </ul>
          </div>
          <div className="sidebar__inner__footer">
            {this.state.items.length ? (
              <button
                type="button"
                onClick={this.toggle}
                className="btn--text--ntrl-min">
                <GetSvg svg="angle_right" />
              </button>
            ) : null}
          </div>
          <div className="sidebar__inner__expander" />
        </div>
      </div>
    );
  }
}

const mapState = (state: Object): { roleId: number | void } => ({
  roleId: state.authStore.user.role,
  locale: state.appStore.locale,
  token: state.authStore.token,
});

export default connect(mapState)(Sidebar);
