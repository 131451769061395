const JOB_CREATE = 'job-create';
const JOB_CREATE_OFFER = 'job-offer';
const JOB_ASSIGN = 'job-assign';
const JOB_ASSIGN_CONTRACTOR = 'job-assign/:type/:type_id/contractor';
const JOB_ASSIGN_CONTRACTOR_ID =
  'job-assign/:type/:type_id/contractor/:contractor_id(\\d+)';
const JOB_VIEW = ':job_id(\\d+)';
const JOB_VIEW_OFFER_CREATE = 'offer';
const JOB_VIEW_OFFER_VIEW = 'offers/:offer_id';
const JOB_VIEW_GRANT_REQUEST = 'grant-request';
const JOB_VIEW_CONTRACTOR = 'contractor/:contractor_id(\\d+)';

export const Names = {
  JOB_CREATE,
  JOB_CREATE_OFFER,
  JOB_ASSIGN,
  JOB_ASSIGN_CONTRACTOR,
  JOB_ASSIGN_CONTRACTOR_ID,
  JOB_VIEW,
  JOB_VIEW_OFFER_CREATE,
  JOB_VIEW_OFFER_VIEW,
  JOB_VIEW_GRANT_REQUEST,
  JOB_VIEW_CONTRACTOR,
};
