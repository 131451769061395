import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import GetSvg from 'components/GetSvg';
import Form from 'components/Form';
import axios from 'helpers/axios';
import HTTP_CODES from 'constants/httpCodes';
import formatErrors, { translateApiErrorMessages } from 'helpers/formatErrors';

type ComplianceFormProps = {
  version: string, // what version of compliance form should we render?
  history: Object, // From redux
  emitSuccessSubmit?: Function, // Successful submission callback
  api: string, // Api endpoint to submit compliance form to
  formSections: Function, // Versioned sections of the Form
  translations: Array<string>, // Versioned translations for the form
  validator: Function, // Versioned Validator of the form
  formProps?: Object, // Props to pass into the generated Form
};

function convertComplianceErrorsToFormErrors(errors) {
  const newErrorObject = Object.keys(errors)
    .map(key => ({
      newKey: key.split('.').pop(),
      newValue: translateApiErrorMessages(errors[key][0]),
    }))
    .reduce((pv, cv) => ({ ...pv, [cv.newKey]: cv.newValue }), {});
  return newErrorObject;
}

function ComplianceForm({
  version,
  api,
  history,
  emitSuccessSubmit = null,
  formProps = {},
  formSections = () => null,
  translations,
  validator,
}): ComplianceFormProps {
  const [errors, setErrors] = React.useState();
  const [processing, setProcessing] = React.useState();
  const [blockSubmit, setBlockSubmit] = React.useState();

  const focusElement = React.useRef();

  React.useEffect(() => {
    /*
     * This effect will set `blockSubmit` to true
     * whenever `enter` is pressed
     */
    function keyListener(event) {
      const keyCode = event.which || event.keyCode;
      if (keyCode === 13) {
        // disable enter from submitting a form
        focusElement.current = document.activeElement;
        setBlockSubmit(true);
      }
    }

    document.addEventListener('keydown', keyListener);
    return document.body.removeEventListener('keydown', keyListener);
  }, [setBlockSubmit, focusElement]);

  React.useEffect(() => {
    /*
     * This effect will reset `blockSubmit` to false 100ms
     * after `blockSubmit` has been set to true, it will also
     * manage focus so that focus remains on the element where "enter"
     * was pressed *if that element is of type `text`
     *
     * Thanks IE.
     */
    let resetSubmit = null;

    if (blockSubmit) {
      // set can submit to false after 100ms
      // this disables ie11 from submitting a form for just long enough
      resetSubmit = setTimeout(() => {
        setBlockSubmit(false);
        focusElement.current.focus();
      });
    }

    return () => clearTimeout(resetSubmit);
  }, [setBlockSubmit, blockSubmit, focusElement]);

  function submitForm(formData) {
    setProcessing(true);
    axios
      .post(api, {
        submission: {
          formData,
          version,
          translations,
        },
      })
      .then(() => {
        setProcessing(false);
        history.push('/dashboard');
        if (emitSuccessSubmit) {
          emitSuccessSubmit();
        }
      })
      .catch(error => {
        setProcessing(false);
        if (error.status !== HTTP_CODES.validation_error) {
          throw error;
        }
        setErrors(convertComplianceErrorsToFormErrors(error.data.payload));
      });
  }

  const errorFormatting = [
    {
      field: 'email_address',
      condition: 'email',
      message: 'VALIDATION-required',
    },
  ];

  return (
    <div className="compliance-questionnaire">
      <Form
        sections={formSections}
        submitAct={submitForm}
        wrappingClass="gw"
        isProcessing={processing}
        validateBefore={validator}
        afterSubmitErrors={formatErrors(errors, errorFormatting)}
        canSubmit={() => {
          return !blockSubmit;
        }}
        {...formProps}
      />
    </div>
  );
}

function WithVersionedForm(props) {
  const { version, data, formProps } = props;

  const [versionLoading, setLoading] = React.useState(true);
  const [formInfo, setInfo] = React.useState({});

  React.useEffect(() => {
    import(`./forms/${version}`)
      .then(module => {
        const formData = module.default(data, formProps?.viewOnly);

        setInfo({
          sections: formData,
          translations: module.translations,
          validator: module.validator,
        });
        setLoading(false);
      })
      .catch(() => {
        // we were not able to load this form, perhaps a bad version
        throw new Error(
          `Unable to load Compliance form (with version: ${version})`,
        );
      });
  }, [version, data, formProps]);

  return versionLoading ? (
    <div className="display--full-width display--full-height fl-vh-center">
      <GetSvg svg="loading" wrapperClass="loading-spinner--large" />
    </div>
  ) : (
    <ComplianceForm
      {...props}
      formSections={formInfo.sections}
      translations={formInfo.translations}
      validator={formInfo.validator}
    />
  );
}

const mapState = ({ appStore }) => ({ locale: appStore.locale });

export default withRouter(connect(mapState)(WithVersionedForm));
