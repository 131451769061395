// @flow
import graphi from 'helpers/graphi';

type User = {
  user_id: number,
  name: string,
  surname: string,
  telephone: string,
  full_name: string,
};

export async function requestUser(userId: number): User {
  const body = `{
    user(user_id:${userId}) {
      user_id
      name
      surname
      telephone
      full_name
      email
    }
  }`;

  const { user } = await request(body, 'GetSupportUser');
  return user;
}

async function request(body: string, tag: string): Object {
  const response = await graphi(body, tag);

  return response.data.data;
}
