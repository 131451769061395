import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContractorSelect from 'workflows/jobAssign/views/contractorView';
import CloseButton from 'components/AsideSlide/CloseButton';
import { AsideSlide } from '@bonlineza/b-lib';
import l from 'helpers/locale';
import Panel from './contractorPanel.js';

type KeysList = any; // @TODO

type ReduxActionsShape = {
  updateMeta: () => any,
};

type PropsShape = {
  editNow?: boolean,
  jobState: string,
  updateField: KeysList => (string | number) => any,
  updateParent?: Function,
  data: Object,
  selectedTradeId?: string | number,
} & ReduxActionsShape;

type StateShape = {
  visibleContractor: boolean,
};

class ContractorPanelWrapper extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    editNow: false,
    updateParent: (): any => true,
    selectedTradeId: null,
    concern: {
      concern_id: null,
      concern_type: null,
    },
  };

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      visibleContractor: false,
    };
  }

  state: StateShape;

  setContractor = (contractor: Object): Function => {
    this.props.updateMeta();
    this.props.updateParent(contractor.display_contractor);
    this.toggleContractorSelect();
    return true;
  };

  toggleContractorSelect = (): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      visibleContractor: !prevState.visibleContractor,
    }));

  canEdit: boolean;

  render(): React$Element<*> {
    return (
      <Fragment>
        <Panel
          openEdit={this.toggleContractorSelect}
          {...this.props}
          isEditing={this.props.editNow}
        />
        <AsideSlide
          qeId="edit_contractors_slider"
          isOpen={this.state.visibleContractor}
          title={l('JOB-edit-contractor')}
          toggle={this.toggleContractorSelect}
          toggleButton={() => (
            <CloseButton onClick={this.toggleContractorSelect} />
          )}>
          <Fragment>
            {this.state.visibleContractor ? (
              <ContractorSelect
                concern_type={
                  this.props.concern.concern_type ||
                  this.props.match.params.type
                }
                concern_id={
                  this.props.concern.concern_id ||
                  this.props.match.params.type_id
                }
                listName="edit_contractors_table"
                nextAct={this.setContractor}
                stateTarget="editContractorList"
              />
            ) : null}
          </Fragment>
        </AsideSlide>
      </Fragment>
    );
  }
}

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  updateMeta(): any {
    return dispatch({
      type: '@TODO_UPDATE_HW',
      payload: 'setJobAssignBaseData',
    });
  },
});

export default withRouter(connect(null, mapActions)(ContractorPanelWrapper));
