import graphi from 'helpers/graphi';
import { hasValue } from '@bonlineza/b-lib/functions';

export const contactShape = `
  company,
  contact_id,
  name,
  surname,
  full_name,
  email,
  telephone,
  cellphone,
  street,
  postal_code,
  city,
  type,
  state_machine {
    status,
    available_transitions {
      name
    }
  },
`;

export const getJobQuery = ({ job_id }): string =>
  getQuery([`job_id:${job_id}`]);

export function getJobCreateQuery(params) {
  const potentialParams = [
    'concern_id',
    'concern_type',
    'contractor_id',
    'project_id',
  ];

  const existingParams = potentialParams.filter(key => params[key]);

  const queryParameters = existingParams.map(param => {
    if (param === 'concern_type') {
      return `${param}:${params[param].toUpperCase()}`;
    }
    return `${param}:${params[param]}`;
  });
  return getQuery(queryParameters);
}

function getQuery(params) {
  return `{
    trades{
      key, value,
    },

    buildings{
      key, value,
    },

    agreement_according_tos{
      key, value,
    },

    job(${params.join(',')}) {
      job_id,
      project_id,
      trade{ key, value},
      type,

      budget_estimate,
      total_payments,

      remaining_time,

      contractor_status,

      occasions{
        key, value,
      },

      building{
        key, value,
      },

      vmp_subscribers{
        contractor_id,
        has_profile,

        key_user_full_name,
        key_user_phone,
        key_user_email,

        billing_company,
        billing_email,

        street,
        post_city,
      },

      notifications_count,
      notifications{
        subject,
        message,
        timestamp,
      },

      address_scan_center,
      address_audit,
      address_account,
      address_invoicing,

      is_assigned_contractor,
      is_project_team_on_job,
      is_project_owner_on_job,
      is_invitee_on_job,
      coversheet,

      contact_care_taker{
        ${contactShape}
      },

      contact_allianz{
        ${contactShape}
      },

      contact_delivery{
        ${contactShape}
      },

      contact_tenant{
        ${contactShape}
      },

      concern_reference,
      info_status,
      info_occasion,
      info_occasion_description,
      info_target_date,
      info_target_date_submission,
      info_desired_date,
      info_summary,
      status_translated,

      concern{
        street,
        postal_code,
        city,
        post_city,
        reference,
        description,
        concern_id,
        concern_type,
        has_elevator,
        rooms_count,

        location{
          lat,
          lng,
        },

        accountings{
          key, value,
        },

        cost_centers{
          key, value,
        },

        floor{
          code,
          description,
        },
      },

      vmp_contractor {
        contractor_id,
        has_profile,

        billing_company,

        key_user_salutation,
        key_user_full_name,
        key_user_email,
        key_user_phone,
        key_user_id,

        street,
        postal_code,
        city,
        post_city,
        website,
      },

      agreement_price,
      agreement_includes_vat,
      agreement_discount,
      agreement_cash_discount,
      agreement_payment_inert,

      agreement_according_to{
        key, value
      },

      accounting{
        account_id, key, value,
      },

      cost_center{
        cost_center_id, key, value,
      },

      account_description,

      documents{
        document_id,
        name,
        mime_type,
        source_tag,
        source_type,
        source_id,
        blob,
        url,
        timestamp,

        user{
          full_name,
          role_id,
        },

        state_machine {
          status,
          available_transitions{
            name,
            action,
            type,
          },
        },
      },

      state_machine {
        status,
        available_transitions{
          name,
          action,
          type,
        },
      },
    },

  }`;
}

export async function getConcern(type, id) {
  const body = `{
    job(concern_id: ${id}, concern_type: ${type.toUpperCase()}) {
      concern {
        street
        postal_code
        city
        post_city
        reference
        description
        concern_id
        concern_type
        has_elevator
        rooms_count

        location{
          lat
          lng
        },

        accountings{
          key
          value
        }

        cost_centers{
          key
          value
        }
      }
    }
  }`;

  const { data } = await graphi(body, 'getNewConcern');

  return data.data.job.concern;
}

export async function getContractor(id) {
  const body = `{
    vmp_contractor(contractor_id: ${id}) {
      contractor_id
      has_profile

      billing_company

      key_user_salutation
      key_user_full_name
      key_user_email
      key_user_phone
      key_user_id

      street
      postal_code
      city
      post_city
      website
    }
  }`;

  const { data } = await graphi(body, 'getNewContractor');

  return data.data.vmp_contractor;
}

function getNotificationsQuery(jobId, limit = 200) {
  return `{
    job(job_id:${jobId}) {
      notifications(limit:${limit}){
        subject,
        message,
        timestamp,
      },
    },
  }`;
}

export async function getNotifications(jobId): Object[] {
  if (!hasValue(jobId)) return [];
  const { data } = await graphi(
    getNotificationsQuery(jobId),
    'getJobNotifications',
  );
  return data.data.job.notifications;
}
