/** @format */

// @flow
import React from 'react';
import AllianzSelect from 'components/AllianzSelect';
import Card from '../../../../../components/Card';
import l, { fC } from '../../../../../helpers/locale';

export type DataShape = {
  according_to: { key: string } | string,
  // conditions: string,
  fixed_price?: string | void,
  vat_included: string | boolean,
  discount: string | void,
  cash_discount: string | void,
  payment_inert: number | void,
};

export type FormDataShape = {
  updateField: Function,
  agreement_according_to_options: Array<{ key: string, value: string }>,
  agreement_price: number | void,
  agreement_price_error: boolean | void,
  agreement_includes_vat: boolean,
  agreement_discount: string | number | void,
  agreement_cash_discount: string | number | void,
  agreement_payment_inert: number | void,
};

type PropsShape = {
  data: DataShape,
  formData: FormDataShape,
  isEditing?: boolean,
};

type StateShape = {
  selectedAccordingTo: Object,
};

class AgreementPanel extends React.Component<PropsShape, StateShape> {
  static defaultProps = {
    isEditing: false,
  };

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      initialAccordingTo: props.data.according_to
        ? {
            value: props.data.according_to.key,
            label: l(`JOB-according_to-option_${props.data.according_to.key}`),
          }
        : null,
      selectedAccordingTo: props.data.according_to
        ? {
            value: props.data.according_to.key,
            label: l(`JOB-according_to-option_${props.data.according_to.key}`),
          }
        : null,
    };
  }

  onChangeAccordingTo = (obj: Object | undefined) => {
    const newValue = obj ? obj.value : null;
    this.props.updateField({
      key: 'agreement_according_to',
      value: newValue,
    });

    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      selectedAccordingTo: newValue
        ? {
            value: newValue,
            label: l(`JOB-according_to-option_${newValue}`),
          }
        : null,
    }));
  };

  render(): React$Element<*> {
    const isEditting = !!(this.props.isEditing && this.props.formData);
    const classSuffix = isEditting ? '' : 'form-and-view--view';
    return (
      <Card
        title="JOB-agreement-title"
        isOpen
        canCollapse={false}
        qeId="job_card-agreement">
        <div className="gw">
          <div className="g g-1/2">
            <div className="gw">
              <div className="g g-1/1">
                <div className={`form-and-view-container ${classSuffix}`}>
                  <label className="form__label--small" htmlFor="__">
                    {l('JOB-according_to-title')}
                  </label>
                  {isEditting ? (
                    <div data-qe-id="action-click-agreement_selector">
                      <AllianzSelect
                        placeholder={l('JOB-according_to-placeholder')}
                        value={this.state.selectedAccordingTo}
                        onValueChange={this.onChangeAccordingTo}
                        options={this.props.formData.agreement_according_to_options.map(
                          v => ({
                            value: v.key,
                            label: l(`JOB-according_to-option_${v.key}`),
                          }),
                        )}
                      />
                    </div>
                  ) : (
                    <span className="text--dk--flushed">
                      {this.state.initialAccordingTo?.label || '-'}
                    </span>
                  )}
                </div>
              </div>
              {/*
                <div className="g g-1/1">
                  <div
                    className={
                      `form-and-view-container ${classSuffix}`
                    }
                  >
                    <label className="form__label--small" htmlFor="__">
                      {l('JOB-conditions-title')}

                    </label>
                    <AllianzSelect
                      placeholder={
                        l('JOB-conditions-placeholder')
                      }
                      value={this.state.selectedCondition}
                      onValueChange={this.onChangeConditions}
                    >
                      {
                        this.props.formData.agreement_conditions_options.map(
                          (i: string): React$Element<*> => (
                            <option value={i} key={i}>
                              {
                                l(
                                  `JOB-conditions-option_${i}`,
                                )
                              }
                            </option>
                          ),
                        )
                      }
                    </AllianzSelect>
                  </div>
                </div>
              */}
            </div>
          </div>
          <div className="g g-1/2">
            <div className="gw">
              <div className="g g-1/3">
                <div
                  className={`form-and-view-container ${classSuffix} ${
                    this.props.formData.agreement_price_error
                      ? 'form-and-view-container--error'
                      : ''
                  }`}>
                  <label className="form__label--small" htmlFor="fixed_price">
                    {l('JOB-fixed_price')}
                  </label>
                  {isEditting ? (
                    <span className="form__input--attach-container">
                      <input
                        data-qe-id="input-edit-fixed_price"
                        className="form__input"
                        type="text"
                        name="fixed_price"
                        value={
                          (isEditting
                            ? this.props.formData.agreement_price
                            : this.props.data.fixed_price) || ''
                        }
                        onChange={(e: Object): any =>
                          this.props.updateField({
                            key: 'agreement_price',
                            value: e.target.value,
                          })
                        }
                      />
                      <span className="form__input-attachment">
                        {l('GENERAL-base_currency')}
                      </span>
                    </span>
                  ) : (
                    <span className="text--dk--flushed">
                      {this.props.data.fixed_price !== '0.00'
                        ? fC(this.props.data.fixed_price, false)
                        : '-'}
                    </span>
                  )}
                </div>
              </div>
              <div className="g g-1/3">
                <div className={`form-and-view-container ${classSuffix}`}>
                  <label className="form__label--small" htmlFor="vat_included">
                    {l('JOB-vat_included')}
                  </label>
                  <input
                    data-qe-id="checkbox-select-vat_included"
                    type="checkbox"
                    name="vat_included"
                    readOnly={!isEditting}
                    checked={
                      isEditting
                        ? this.props.data.vat_included
                        : this.props.formData.agreement_includes_vat
                    }
                    onChange={(e: Object): any =>
                      this.props.updateField({
                        key: 'agreement_includes_vat',
                        value: e.target.checked,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="gw">
              <div className="g g-1/3">
                <div className={`form-and-view-container ${classSuffix}`}>
                  <label className="form__label--small" htmlFor="discount">
                    {l('JOB-discount')}
                  </label>
                  {isEditting ? (
                    <span className="form__input--attach-container">
                      <input
                        data-qe-id="input-edit-discount"
                        className="form__input"
                        type="text"
                        name="discount"
                        value={
                          (isEditting
                            ? this.props.formData.agreement_discount
                            : this.props.data.discount) || ''
                        }
                        onChange={(e: Object): any =>
                          this.props.updateField({
                            key: 'agreement_discount',
                            value: e.target.value,
                          })
                        }
                      />
                      <span className="form__input-attachment">%</span>
                    </span>
                  ) : (
                    <span className="text--dk--flushed">
                      {this.props.data.discount !== '0' &&
                      this.props.data.discount !== ''
                        ? `${this.props.data.discount} %`
                        : '-'}
                    </span>
                  )}
                </div>
              </div>
              <div className="g g-1/3">
                <div className={`form-and-view-container ${classSuffix}`}>
                  <label className="form__label--small" htmlFor="cash_discount">
                    {l('JOB-cash_discount')}
                  </label>
                  {isEditting ? (
                    <span className="form__input--attach-container">
                      <input
                        data-qe-id="input-edit-cash_discount"
                        className="form__input"
                        type="text"
                        name="cash_discount"
                        value={
                          (isEditting
                            ? this.props.formData.agreement_cash_discount
                            : this.props.data.cash_discount) || ''
                        }
                        onChange={(e: Object): any =>
                          this.props.updateField({
                            key: 'agreement_cash_discount',
                            value: e.target.value,
                          })
                        }
                      />
                      <span className="form__input-attachment">%</span>
                    </span>
                  ) : (
                    <span className="text--dk--flushed">
                      {this.props.data.cash_discount !== '0' &&
                      this.props.data.cash_discount !== ''
                        ? `${this.props.data.cash_discount} %`
                        : '-'}
                    </span>
                  )}
                </div>
              </div>
              <div className="g g-1/3">
                <div className={`form-and-view-container ${classSuffix}`}>
                  <label className="form__label--small" htmlFor="payment_inert">
                    {l('JOB-payment_inert')}
                  </label>
                  {isEditting ? (
                    <span className="form__input--attach-container">
                      <input
                        data-qe-id="input-edit-payment_before"
                        className="form__input"
                        type="text"
                        name="payment_inert"
                        value={
                          (isEditting
                            ? this.props.formData.agreement_payment_inert
                            : this.props.data.payment_inert) || ''
                        }
                        onChange={(e: Object): any =>
                          this.props.updateField({
                            key: 'agreement_payment_inert',
                            value: e.target.value,
                          })
                        }
                      />
                      <span className="form__input-attachment">
                        {l('GENERAL-days')}
                      </span>
                    </span>
                  ) : (
                    <span className="text--dk--flushed">
                      {this.props.data.payment_inert !== 0 &&
                      this.props.data.payment_inert !== null
                        ? `${this.props.data.payment_inert} ${l(
                            'GENERAL-days',
                          )}`
                        : '-'}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default AgreementPanel;
