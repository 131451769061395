/** @format */

import React from 'react';
import { connect } from 'react-redux';

import AllianzSelect from 'components/AllianzSelect';
import { setLocale } from '../../actions/app.js';

import LANG_OPTIONS from '../../constants/lang';

class LanguageDropdown extends React.Component {
  componentDidMount() {
    if (this.props.locale) {
      this.props.updateLocale(this.props.locale);
    }
  }

  render() {
    return (
      <AllianzSelect
        hideResetButton
        onValueChange={newVal =>
          this.props.updateLocale((newVal && newVal.value) || '')
        }
        defaultValue={
          LANG_OPTIONS[
            LANG_OPTIONS.findIndex(v => v.value === this.props.locale) > 0
              ? LANG_OPTIONS.findIndex(v => v.value === this.props.locale)
              : 0
          ]
        }
        options={LANG_OPTIONS}
      />
    );
  }
}

const mapState = state => ({
  locale: state.appStore.locale,
});

const mapActions = dispatch => ({
  updateLocale(newLocale) {
    return dispatch(setLocale(newLocale));
  },
});

export default connect(mapState, mapActions)(LanguageDropdown);
