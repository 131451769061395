import React from 'react';
import SimpleList from 'components/ConnectedSimpleList';
import l from 'helpers/locale';
import GetSvg from 'components/GetSvg';

type AssetDataShape = {
  asset_id: string,
  house_id: string,
  post_city: string,
  property_id: string,
  street: string,
  tenants: Array<string>,
  type_lang: string,
  house_reference: string,
  property_reference: string,
  asset_reference: string,
};

type ReduxStateShape = {};

type PropsShape = {
  nextAct: Function,
} & ReduxStateShape;

const AssetList = ({ nextAct }: PropsShape): React$Element<*> => {
  const columnOrder = [
    'type_lang',
    'street',
    'post_city',
    'tenant_name',
    'id_list',
  ];
  const flexStyle = {
    flexBasis: `${(1 / columnOrder.length) * 100}%`,
  };

  return (
    <div>
      <SimpleList
        api="/api/1/view/jobs/rental_object"
        bgcAlt
        name="rental_object"
        headings={[
          {
            name: 'type_lang',
            sortable: true,
            text: l('ASSET_LIST-type_lang'),
          },
          {
            name: 'street',
            sortable: true,
            text: l('ASSET_LIST-street'),
          },
          {
            name: 'post_city',
            sortable: true,
            text: l('ASSET_LIST-post_city'),
          },
          {
            name: 'tenant_name',
            sortable: false,
            text: l('ASSET_LIST-tenant_name'),
          },
          {
            name: 'id_list',
            sortable: true,
            text: l('ASSET_LIST-id_list'),
            align: 'right',
          },
        ]}
        filterIsCentered>
        {row =>
          row.map((item: AssetDataShape, itemKey: number): React$Element<*> => (
            <div
              key={item.asset_id}
              id={item.asset_id}
              className="custom-list__body__row">
              <div className="custom-list__body__row__item" style={flexStyle}>
                {l(`${item.type_lang}`)}
              </div>
              <div className="custom-list__body__row__item" style={flexStyle}>
                {item.street}
              </div>
              <div className="custom-list__body__row__item" style={flexStyle}>
                {item.post_city}
              </div>
              <div className="custom-list__body__row__item" style={flexStyle}>
                {item.tenant_name.map((v: string, k: number): React$Element<
                  *,
                > => (
                  <div key={k}>
                    {v}
                    <br />
                  </div>
                ))}
              </div>
              <div className="custom-list__body__row__item" style={flexStyle}>
                <div className="align--right">
                  <div className="tiny-group-buttons">
                    <div className="tiny-group-buttons__item">
                      <span className="tiny-group-buttons__item__header">
                        {item.property_reference}
                        &nbsp;&nbsp;.
                      </span>
                      <button
                        type="button"
                        title=""
                        onClick={function(): boolean {
                          nextAct(item.property_id, 'property');
                          return true;
                        }}
                        className="btn--tooltip"
                        data-qe-id={`assets-table-${itemKey}-property-btn`}>
                        <GetSvg
                          svg="building"
                          wrapperClass="tiny-group-buttons__icon"
                        />
                        <span className="btn__tooltip">
                          {l('PROPERTY-singular')}
                        </span>
                      </button>
                    </div>
                    <div className="tiny-group-buttons__item">
                      <span className="tiny-group-buttons__item__header">
                        {item.house_reference}
                      </span>
                      <button
                        type="button"
                        onClick={function(): boolean {
                          nextAct(item.house_id, 'house');
                          return true;
                        }}
                        className="btn--tooltip"
                        data-qe-id={`assets-table-${itemKey}-house-btn`}>
                        <GetSvg
                          svg="house"
                          wrapperClass="tiny-group-buttons__icon"
                        />
                        <span className="btn__tooltip">
                          {l('HOUSE-singular')}
                        </span>
                      </button>
                    </div>
                    <div className="tiny-group-buttons__item">
                      <span className="tiny-group-buttons__item__header">
                        .&nbsp;&nbsp;
                        {item.asset_reference}
                      </span>
                      <button
                        type="button"
                        onClick={function(): boolean {
                          nextAct(item.asset_id, 'rental_object');
                          return true;
                        }}
                        className="btn--tooltip"
                        data-qe-id={`assets-table-${itemKey}-asset-btn`}>
                        <GetSvg
                          svg="object"
                          wrapperClass="tiny-group-buttons__icon"
                        />
                        <span className="btn__tooltip">
                          {l('OBJECT-singular')}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </SimpleList>
    </div>
  );
};

export default AssetList;
