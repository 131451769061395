import React from 'react';
import Card from 'components/Card';
import SimpleList from 'components/ConnectedSimpleList';
import l from 'helpers/locale';

const ObjectCards = ({ id, data }) => (
  <div>
    <Card title="OBJECT-title-plural">
      <SimpleList
        api={`/api/1/view/properties/${id}/assets`}
        bgcAlt
        name="single_property_assets"
        headings={[
          {
            name: 'reference',
            sortable: false,
            text: l('OBJECTS-COL-reference'),
          },
          {
            name: 'type',
            sortable: false,
            text: l('OBJECTS-COL-type'),
          },
          {
            name: 'category',
            sortable: false,
            text: l('OBJECTS-COL-category'),
          },
          {
            name: 'room',
            sortable: false,
            text: l('OBJECTS-COL-room'),
          },
          {
            name: 'floor',
            sortable: false,
            text: l('OBJECTS-COL-floor'),
          },
          {
            name: 'status',
            sortable: false,
            text: l('OBJECTS-COL-status'),
          },
        ]}
        data={data}
        sections
        sectionTarget="objects"
        sectionTitleKeys={['house_reference', 'house_type', 'house_street']}
        enableSearch={false}
        noPointer
      />
    </Card>
  </div>
);

export default ObjectCards;
