import axios from 'helpers/axios';
import {
  requestSuccess,
  requestError,
  requestRequest,
} from 'functions/actionRequests.func.js';

export const FIELD_CHANGE = 'CUSTOMER_RELATIONSHIP_FIELD_CHANGE';
export const SET_FIELDS = 'CUSTOMER_RELATIONSHIP_SET_FIELDS';
export const EDIT_TOGGLE = 'CUSTOMER_RELATIONSHIP_EDIT_TOGGLE';

export const REQUEST_LOADING = 'CUSTOMER_RELATIONSHIP_REQUEST_LOADING';
export const REQUEST_ERROR = 'CUSTOMER_RELATIONSHIP_REQUEST_ERROR';
export const REQUEST_SUCCESS = 'CUSTOMER_RELATIONSHIP_REQUEST_SUCCESS';

export const RECOMMENDATION_TOGGLE =
  'CUSTOMER_RELATIONSHIP_RECOMMENDATION_TOGGLE';

export const MAX_RECOMMENDATIONS =
  'CUSTOMER_RELATIONSHIP_REQUEST_SUCCESS_MAX_RECOMMENDATIONS';

export function setValue(name, value) {
  return {
    type: FIELD_CHANGE,
    name,
    value,
  };
}

export function setInitialValues(fields) {
  return {
    type: SET_FIELDS,
    fields,
  };
}

export function toggleEdit(payload) {
  return { type: EDIT_TOGGLE, ...payload };
}

export function reduxToggleRecommendation() {
  return { type: RECOMMENDATION_TOGGLE };
}

export function submitContractorRelationship(
  contractorId,
  successCallback = () => null,
  errorCallback = () => null,
) {
  return (dispatch, getState) => {
    const { fields } = getState().customerRelationship;
    dispatch(requestRequest(REQUEST_LOADING));
    axios
      .post(`/api/1/contractors/${contractorId}/`, fields)
      .then(resp => {
        successCallback();
        return dispatch(requestSuccess(REQUEST_SUCCESS, resp));
      })
      .catch(err => errorHandler(err.data, dispatch, errorCallback));
  };
}

export function errorHandler(
  data,
  dispatch,
  callback = () => null,
  origin = 'contractor',
) {
  if (data?.payload?.recommendations) {
    dispatch({ type: MAX_RECOMMENDATIONS });
  } else {
    callback();
    dispatch(requestError(REQUEST_ERROR, data?.payload, origin));
  }
}
