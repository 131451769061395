import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Card from 'components/Card';
import SimpleList from 'components/ConnectedSimpleList';
import l from 'helpers/locale';
import { StateConditional } from 'machines/components/RenderConditional';
import { PanelStates } from 'machines/constants';
import ContractorColumnFormatter from 'views/singleProject/components/JobListGroup/ContractorColumnFormatter';
import { SliderContext } from 'components/RouteSlider';
import If from 'components/Conditions/If';
import JobListGroup from '../../components/JobListGroup';
import MachineBuilder from './machine';

export const JobsListCardMachine = MachineBuilder();

type JobDataShape = {
  contractor: Array<string>,
  contractor_id: number,
  description: Array<string>,
  job_estimate: Array<string>,
  job_id: string,
  job_id_raw: number,
  offer_total: string,
  status: Array<string>,
};

type PropsShape = {
  enableDetailView?: boolean,
  projectId?: number | string,
  history: Object,
  match: Object,
  api: string,
};

const func_sum = (pv, cv) => pv + cv;

class JobsListPanel extends Component {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      grouping: 'contractor',
    };
    this.extras = this.props.enableDetailView
      ? {
          bgcAlt: true,
        }
      : {};
    this.headings = [
      {
        name: 'job_id',
        sortable: true,
        text: l('JOB-COL-id'),
      },
      {
        name: 'contractor',
        sortable: false,
        text: l('JOB-COL-contractor'),
        customFormatter: data => <ContractorColumnFormatter data={data} />,
      },
      {
        name: 'description',
        sortable: true,
        text: l('JOB-COL-description'),
      },
      {
        name: 'job_estimate',
        sortable: true,
        text: l('JOB-COL-job_estimate'),
      },
      {
        name: 'offer_total',
        sortable: true,
        text: l('JOB-COL-offer_total'),
      },
      {
        name: 'status',
        sortable: true,
        text: l('JOB-COL-status_payments'),
      },
    ];
  }

  setGrouping = value => {
    this.setState({ grouping: value });
  };

  getGroupSummary = contractorRows => ({
    title: contractorRows[0].contractor_title
      ? contractorRows[0].contractor_title
      : l('GROUPED_LIST-TITLE-UNKNOWN'),
    totalEstimate: contractorRows
      .map(row => +row.job_estimate)
      .reduce(func_sum, 0),
    totalOffer: contractorRows.map(row => row.offer_total).reduce(func_sum, 0),
    totalPayment: contractorRows
      .map(row => row.payment_total)
      .reduce(func_sum, 0),
  });

  groupBy = (array: JobDataShape[], cb: Function) => {
    const groups = Object.create(null);
    array.forEach(function(o) {
      const key = cb(o);
      groups[key] = groups[key] || [];
      groups[key].push(o);
    });
    return groups;
  };

  render = () => (
    <StateConditional
      state={this.props.machine_state}
      whenState={[PanelStates.VISIBLE]}>
      <SliderContext.Consumer>
        {({ isActive }) => (
          <If condition={isActive}>
            <Fragment>
              <Card title={l('JOB-plural')} isOpen qeId="project-jobs-list">
                <SimpleList
                  api={`/api/1/view/projects/${this.props.projectId}/jobs`}
                  {...this.extras}
                  presetQuery={{ grouping: 'contractor' }}
                  name="project_jobs_list"
                  headings={this.headings}
                  showDatepicker
                  allowClick
                  clickAct={({ job_id_raw }) =>
                    this.props.history.push(
                      `${this.props.location.pathname}/${job_id_raw}`,
                    )
                  }
                  groupSelection={[
                    { label: l('JOB_GROUPING-flat'), value: null },
                    {
                      label: l('JOB_GROUPING-by_contractor'),
                      value: 'contractor',
                      default: true,
                    },
                  ]}
                  groupSelectionCB={this.setGrouping}>
                  {this.state.grouping
                    ? rows => {
                        const groupedJobs = this.groupBy(
                          rows,
                          obj => obj.contractor_id,
                        );
                        return Object.keys(groupedJobs).map((key, index) => {
                          const summary = this.getGroupSummary(
                            groupedJobs[key],
                          );
                          return (
                            <JobListGroup
                              key={index}
                              groupRows={groupedJobs[key]}
                              title={summary.title}
                              estimateTotal={summary.totalEstimate}
                              offerTotal={summary.totalOffer}
                              status={summary.totalPayment}
                              headings={this.headings}
                            />
                          );
                        });
                      }
                    : null}
                </SimpleList>
              </Card>
            </Fragment>
          </If>
        )}
      </SliderContext.Consumer>
    </StateConditional>
  );
}

JobsListPanel.defaultProps = {
  enableDetailView: true,
  projectId: '',
};

const mapState = ({ machineStore }) => ({
  machine_state: machineStore.jobslist_card,
});

export default withRouter(connect(mapState)(JobsListPanel));
