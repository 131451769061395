/** @format */

// import desired actions
// import * as Notifications from '../'

// return an id in a url such as domain.com/articles/123/
// function getIdFromPathName(pathname, precede) {
//   const pattern = new RegExp(`${precede}\/([0-9]{1,10})`);
//   return pathname.match(pattern)[1];
// }

// actual dispatched
const onRouteDispatcher = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    switch (action.payload.pathname) {
      case '/':
        store.dispatch({ type: 'NULL' });
        break;
      default:
        break;
    }
  }
  return next(action);
};

export default onRouteDispatcher;
