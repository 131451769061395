/** @format */

// @flow
import hasOwn from 'functions/hasOwn.func.js';
import Constants from '../constants';

const { UploadRequest, REMOVE_FILE } = Constants;
const { success, failed, fetching } = UploadRequest;

type RequestState = {
  success: boolean,
  failed: boolean,
  fetching: boolean,
  message: string,
};

const baseRequest: RequestState = {
  success: false,
  failed: false,
  fetching: false,
  message: '',
};

type StateShape = {
  [string]: {
    uploadMessage: string,
    request: RequestState,
    docs: Object,
  },
};

type PropsShape = {
  type: string,
  instance: string,
  fileKey: string,
  code: number,
  rest: Object,
};

const initialState = {
  create_single_job_internal: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  create_single_job_project_team: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  create_single_job_tender: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  create_single_job_exchange: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  create_offer: {
    uploadMessage: '',
    request: baseRequest,
    docs: [],
  },
  create_offer_secondary: {
    uploadMessage: '',
    request: baseRequest,
    docs: [],
  },
  single_job_internal: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  single_job_tender: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  single_job_exchange: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  single_job_project_team: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  project_internal: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  project_team: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  create_project_internal: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  create_project_team: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  account_documents: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
  static: {
    uploadMessage: '',
    request: baseRequest,
    docs: {},
  },
};

export default function documentUploadReducer(
  state: StateShape = initialState,
  { type, instance, fileKey, code, ...rest }: PropsShape,
): StateShape {
  let temp;
  switch (type) {
    case fetching:
      return {
        ...state,
        [instance]: {
          ...state[instance],
          request: {
            ...baseRequest,
            fetching: true,
          },
        },
      };
    case failed:
      return {
        ...state,
        [instance]: {
          ...state[instance],
          request: {
            ...baseRequest,
            failed: true,
          },
          uploadMessage:
            code === 413 || code === 422
              ? `UPLOAD-error-${code}`
              : 'UPLOAD-error-generic',
        },
      };
    case success:
      return {
        ...state,
        [instance]: {
          ...state[instance],
          request: {
            ...baseRequest,
            success: true,
          },
          docs:
            instance !== 'create_offer'
              ? {
                  ...state[instance].docs,
                  [fileKey]: {
                    ...state[instance][fileKey], // ???
                    ...rest.data.payload,

                    // ...rest.file,
                    document_id: rest.data.payload.id,
                    user: rest.data.payload.user,
                    state_machine: {
                      status: rest.data.machine.current,
                      available_transitions: rest.data.machine.transitions,
                    },
                  },
                }
              : state[instance].docs,
          uploadMessage: '',
        },
      };
    case 'RESET_UPLOAD_ERROR_MESSAGE':
      return {
        ...state,
        [instance]: {
          ...state[instance],
          uploadMessage: '',
        },
      };
    case 'JOB_ASSIGN_REMOVE_DOCUMENT':
      return {
        ...state,
        [instance]: {
          ...state[instance],
          docs: Object.keys(state[instance].docs)
            .map((v: string): Object => ({
              item: state[instance].docs[v],
              key: v,
            }))
            .filter((v: Object) => v.item.id !== rest.imageId)
            .reduce(
              (pv, cv) => ({
                ...pv,
                [cv.key]: cv.item,
              }),
              {},
            ),
        },
      };
    case 'JOB_OFFER_REMOVE_DOC':
      return {
        ...state,
        [instance]: {
          ...state[instance],
          docs: state[instance].docs.filter(
            (v: Object) => v.document_id !== rest.imageId,
          ),
        },
      };
    case 'JOB_OFFER_ADD_DOC':
      return {
        ...state,
        [instance]: {
          ...state[instance],
          docs: [...state[instance].docs, { ...rest.file }],
        },
      };
    case 'SET_DOC':
      if (hasOwn(rest.payload.payload, 'state_machine')) {
        // graph ql
        temp = rest.payload.payload;
      } else {
        // api
        temp = {
          ...rest.payload.payload,
          document_id: rest.payload.payload.id,
          state_machine: {
            status: rest.payload.machine
              ? rest.payload.machine.current
              : 'active',
            available_transitions: rest.payload.machine
              ? rest.payload.machine.transitions
              : [],
          },
        };
      }
      return {
        ...state,
        [instance]: {
          ...state[instance],
          docs: [temp],
        },
      };
    case 'JOB_OFFER_CLEAR_DOC':
      return {
        ...state,
        [instance]: {
          ...state[instance],
          docs: [],
        },
      };
    case REMOVE_FILE:
      temp = Object.keys(state[instance].docs)
        .filter(key => state[instance].docs[key].id !== rest.payload.id)
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr]: state[instance].docs[curr],
          }),
          {},
        );
      return {
        ...state,
        [instance]: {
          ...state[instance],
          docs: temp,
        },
      };
    case 'FETCH_JOB_ASSIGN_JOB_DATA_REQUEST_FETCHING':
      return initialState;
    default:
      return state;
  }
}
