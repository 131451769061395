import React, { Fragment } from 'react';
import l, { fC } from 'helpers/locale';
import Card from 'components/Card';
import MapComp from 'components/Map/SafeMap';
import PageReady from 'components/AllianzPageReady';
import GraphQLPageReady from 'components/GraphQLPageReady';
import GenericNotifications, {
  localiseTitle,
} from 'components/GenericNotifications';
import ActionBar from 'components/ConnectedActionBar';
import axios from 'helpers/axios';
import getDashboardUserDocuments from '../../query';
import DashboardItem from '../../components/DashboardItem.js';
import DashboardDocuments from '../../components/DashboardDocuments.js';

type StateShape = {
  data: Object,
};

type PropsShape = {};

class ContractorDashboard extends React.Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      data: {
        statistics: {
          earnings: '',
          jobs: '',
          trade: '',
        },
        notifications: [],
        map: {},
        misc: [],
        dashboard_user: {},
      },
    };
  }

  state: StateShape;

  handleSuccess = (data: Object): any =>
    this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      data: {
        ...prevState.data,
        ...data,
      },
    }));

  getAllNotifications = () =>
    axios
      .get('/api/1/view/dashboard/notifications')
      .then(res => res.data.notifications);

  render(): React$Element<*> {
    return (
      <Fragment>
        <GraphQLPageReady
          onData={res => this.handleSuccess(res.data.data)}
          queryId="getDashboardUserDocuments"
          queryString={getDashboardUserDocuments()}>
          <PageReady
            url="/api/1/view/dashboard"
            onData={res => this.handleSuccess(res.data.payload)}>
            <ActionBar name="contractor_dashboard" />
            <Card title="DASHBOARD-recent_orders" canCollapse={false}>
              <div style={{ height: '250px' }}>
                <MapComp
                  markers={
                    this.state.data.map &&
                    this.state.data.map.contractor &&
                    this.state.data.map.jobs
                      ? [
                          {
                            ...this.state.data.map.contractor,
                            color: 'red',
                          },
                          ...this.state.data.map.jobs.map(
                            (item: Object): Object => ({
                              ...item,
                              color: 'blue',
                            }),
                          ),
                        ]
                      : []
                  }
                />
              </div>
            </Card>
            <div className="gw">
              <div className="g--tall g-1/2">
                <Card title="DASHBOARD-latest_activities" canCollapse={false}>
                  <GenericNotifications
                    asideTitle={localiseTitle('')}
                    notifications={
                      this.state.data.notifications?.notifications || []
                    }
                    total={this.state.data.notifications?.count}
                    asideRequest={this.getAllNotifications}
                  />
                </Card>
              </div>
              <div className="g--tall g-1/2">
                <Card title="DASHBOARD-statistics" canCollapse={false}>
                  <div className="gw">
                    <div className="g--tall g-1/2">
                      <div className="push--small--bottom">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('CONTRACTOR-total_volume_in')}
                          </label>
                          <p className="text--dk--flushed--large">
                            {fC(this.state.data.statistics.earnings)}
                          </p>
                        </DashboardItem>
                      </div>
                    </div>
                    <div className="g--tall g-1/2">
                      <div className="push--small--bottom">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('CONTRACTOR-jobs_in')}
                          </label>
                          <p className="text--dk--flushed--large">
                            {this.state.data.statistics.jobs || '-'}
                          </p>
                        </DashboardItem>
                      </div>
                    </div>
                    <div className="g--tall g-1/2">
                      <div className="push--small--bottom">
                        <DashboardItem>
                          <label className="form__label--small" htmlFor="__">
                            {l('CONTRACTOR-trades')}
                          </label>
                          <p className="text--dk--flushed--large">
                            {this.state.data.statistics.trade || '-'}
                          </p>
                        </DashboardItem>
                      </div>
                    </div>
                    <div className="g--tall g-1/2">
                      <div className="push--small--bottom">
                        <label className="form__label--small" htmlFor="__">
                          {l('DASHBOARD-paid-jobs')}
                        </label>
                        <p className="text--dk--flushed--large">
                          {this.state.data.statistics.paid_jobs_count || '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
            <div>
              <div className="layout-fill push--bottom">
                <Card title="DASHBOARD-solutions_for_smes" canCollapse={false}>
                  <iframe
                    id="solutions__iframe"
                    title="Contractor News"
                    src={l('CONTRACTOR_DASHBOARD-banner_url')}
                    style={{
                      borderStyle: 'none',
                      overflow: 'hidden',
                    }}
                    scrolling="no"
                    height="230px"
                    width="100%"
                  />
                </Card>
              </div>
            </div>
            <DashboardDocuments
              documents={this.state.data?.dashboard_user?.documents || []}
            />
          </PageReady>
        </GraphQLPageReady>
      </Fragment>
    );
  }
}

export default ContractorDashboard;
