import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import SimpleList, {
  AllianzSimpleItem,
  SimpleListItemArrayFormatter,
  SimpleListItemRatingFormatter,
} from 'components/ConnectedSimpleList';
import GetSvg from 'components/GetSvg';
import l, { fC } from 'helpers/locale';

type PropsShape = {
  children?: any,
  nextAct: Function,
  listName?: string,
  concern_type?: string,
  concern_id?: number | void,
};

type DefaultPropsShape = {
  children: any,
  listName: string,
};

export const getContractorSelectorFilters = () => [
  {
    text: l('CONTRACTOR_SELECTOR-FILTER-distance_lt_30'),
    value: 'distance:<30',
  },
  {
    text: l('CONTRACTORS_OVERVIEW_FILTER-top_rating'),
    value: 'rating:5',
  },
  {
    text: l('CONTRACTORS_OVERVIEW_FILTER-with_login'),
    value: 'login:true',
  },
  {
    text: l('CONTRACTOR-reccomended'),
    value: 'recommended:true',
  },
  {
    text: l('PREDEFINED_SEARCH-CONTRACTOR-compliance_missing'),
    value: 'compliance_status:unchecked,refresh_needed',
  },
  {
    text: l('PREDEFINED_SEARCH-CONTRACTOR-compliance_initiated'),
    value: 'compliance_status:initiated',
  },
  {
    text: l('PREDEFINED_SEARCH-CONTRACTOR-compliance_submitted'),
    value: 'compliance_status:submitted',
  },
  {
    text: l('PREDEFINED_SEARCH-CONTRACTOR-compliance_test_needed'),
    value: 'compliance_status:test_needed',
  },
];

class ContractorView extends React.Component<
  DefaultPropsShape,
  PropsShape,
  void,
> {
  static defaultProps = {
    children: [],
    listName: 'select_contractors_table',
    concern_type: '',
    concern_id: null,
  };

  defaultProps: DefaultPropsShape;

  clickHandler = selectedContractor => this.props.nextAct(selectedContractor);

  headings = [
    {
      name: 'name',
      sortable: true,
      text: l('CONT-COL-name'),
      flex: '14%',
      customFormatter: SimpleListItemArrayFormatter(),
    },
    {
      name: 'details',
      sortable: true,
      text: l('CONT-COL-address'),
      flex: '18%',
      customFormatter: SimpleListItemArrayFormatter(),
    },
    {
      name: 'rating',
      sortable: true,
      text: l('CONT-COL-rating'),
      flex: '14%',
      customFormatter: SimpleListItemRatingFormatter,
    },
    {
      name: 'display_status',
      sortable: false,
      text: l('CONT-COL-status'),
      flex: '14%',
      customFormatter: SimpleListItemArrayFormatter(true),
    },
    {
      name: 'knows_property',
      sortable: true,
      text: l('CONT-COL-knows_property'),
      flex: '14%',
    },
    {
      name: 'distance',
      sortable: true,
      text: l('CONT-COL-distance'),
      flex: '12%',
    },
    {
      name: 'earnings',
      sortable: false,
      text: l('CONT-COL-earnings'),
      flex: '12%',
      customFormatter: fC,
      align: 'right',
    },
    {
      name: '',
      sortable: false,
      text: '',
      flex: '2%',
    },
  ];

  render(): React$Element<*> {
    return (
      <Fragment>
        <SimpleList
          api={`/api/1/view/jobs/${this.props.concern_type ||
            this.props.match.params.type}/${this.props.concern_id ||
            this.props.match.params.type_id}/contractors`}
          name={this.props.listName}
          clickAct={this.clickHandler}
          bgcAlt
          filterOpts={getContractorSelectorFilters()}
          headings={this.headings}>
          {data =>
            data.map((row, index) => (
              <div style={{ position: 'relative' }} key={index}>
                <button
                  type="button"
                  className={`simple-list__body__row${
                    index % 2 ? '--bgc-alt' : ''
                  }`}
                  onClick={this.clickHandler.bind(null, row)}
                  data-qe-id={`${this.props.listName}-table-${index}`}>
                  {this.headings.map((heading, key) => (
                    <AllianzSimpleItem
                      key={key}
                      itemClass="simple-list__body__row__item"
                      flex={`${heading.flex}`}
                      column={heading.name}
                      text={row[heading.name]}
                      customFormatter={heading.customFormatter || null}
                    />
                  ))}
                </button>
                {row.website ? (
                  <a
                    href={row.website}
                    className="contractor-list-ext-anchor"
                    target="_blank"
                    rel="noopener noreferrer">
                    <GetSvg svg="ext_link" />
                  </a>
                ) : null}
              </div>
            ))
          }
        </SimpleList>
        {this.props.children}
      </Fragment>
    );
  }
}

export default withRouter(ContractorView);
