import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ButtonGroup, { WithLoader } from 'components/ButtonGroup';
// eslint-disable-next-line
import ContractorSelectorPanelList from 'shared/jobs/Panels/items/ContractorSelector/contractorSelectorPanelList';
import l from 'helpers/locale';
import replaceSubKeys from 'functions/replaceSubKeys';
import { AsideSlide, Overlay, SimplePopup } from '@bonlineza/b-lib';
import NominationForm from 'views/nominations/views/NominationForm';
import CloseButton from 'components/AsideSlide/CloseButton';
import { setListItem } from 'components/ConnectedActionBar/actions';
import Roles from 'constants/roles';
import ErrorPopup from 'components/ErrorPopup';

import { createJobAction } from 'views/singleJob/actions';
import JobConstants from 'views/singleJob/constants';

import GrantModal from 'components/GrantModal/GrantModal';
import RequestOffersModal from 'shared/jobs/Panels/components/RequestOffersModal';
import InviteModal from 'components/GrantModal/InviteModal';
import { jobActions } from '../../actions';
import actionTitles from './actionTitles';

const { CLEAR_SUB_REQUEST } = JobConstants;
const { RoleIds } = Roles;

type ReduxStateShape = {
  jobId: number,
  contractorId: ?number,
  contractorName: ?string,
  inviteRequired: boolean,
  canNotWithdraw: boolean,
  isJobOffer: boolean,
  contractors: Object[],
  jobActionLoading: boolean,
  contractorsWithoutUsers: Object[],
};

type ReduxPropsShape = {
  setNotification: Function,
  dispatch: Function,
  createJob: Function,
};

type StateShape = {
  visibleRating: boolean,
  visibleGrant: boolean,
  visibleInvite: boolean,
  visibleOffer: boolean,
  visibleAddContractor: boolean,
  visibleContractorSelector: boolean,
  contractorSelectorData: Object,
  visibleDecline: boolean,
  visibleConfirmAddSingleContractor: boolean,
  confirmAddSingleContractor: Object,
  visibleCancelJobPopup: boolean,
  request: {
    addContractor: Object,
  },
};

type PropsShape = {
  refreshData: Function,
  refreshList: Function,
  history: Object,
  backAction: Function,
} & ReduxStateShape &
  ReduxPropsShape;

class JobFooter extends React.Component<PropsShape> {
  static getDerivedStateFromProps(
    nextProps: PropsShape,
    thisState: StateShape,
  ) {
    if (
      nextProps.contractorId !== null &&
      nextProps.contractorId !== thisState.contractorId
    ) {
      return { contractorId: nextProps.contractorId };
    }
    if (nextProps.contractorsWithoutUsers.length) {
      return {
        invitesRequiredForContractors: nextProps.contractorsWithoutUsers,
        inviteRequired: true,
      };
    }
    if (
      nextProps.contractorsWithoutUsers.length === 0 &&
      thisState.invitesRequiredForContractors.length !== 0
    ) {
      return {
        invitesRequiredForContractors: nextProps.contractorsWithoutUsers,
        inviteRequired: false,
      };
    }
    return null;
  }

  constructor(props: PropsShape) {
    super(props);
    this.state = {
      visibleRequestOffers: false,
      visibleRating: false,
      visibleCompleteJobPopup: false,
      visibleGrant: false,
      visibleResendGrant: false,
      visibleResendOfferGrant: false,
      visibleInviteForm: false,
      visibleInviteModal: false,
      visibleOffer: false,
      contractorId: props.contractorId,
      invitesRequiredForContractors: props.contractorsWithoutUsers,
      contractorName: props.contractorName,
      inviteRequired: props.inviteRequired,
      visibleContractorSelector: false,
      visibleDecline: false,
      visibleConfirmAddSingleContractor: false,
      visibleCancelJobPopup: false,
      confirmAddSingleContractor: {
        name: [],
      },
    };
    this.actionFunctions = {
      back: this.props.backAction,
      delete: (e: Object): any => {
        e.preventDefault();
        this.fire('erase');
      },
      cancel: (e: Object): any => {
        e.preventDefault();
        this.toggleVisibility('CancelJobPopup');
      },
      copy: (e: Object): any => {
        e.preventDefault();
        this.fire('copy');
      },
      update: (e: Object): any => {
        e.preventDefault();
        this.props.scrollToTop();
        this.fire('edit');
      },
      grant: (e: Object): any => {
        e.preventDefault();
        this.onGrant();
      },
      resend_grant: (e: Object): any => {
        e.preventDefault();
        this.onGrant('ResendGrant');
      },
      resend_offer_grant: (e: Object): any => {
        e.preventDefault();
        this.onResendOfferGrant();
      },
      request: this.onRequestOffers,
      cancelEdit: (e: Object): any => {
        e.preventDefault();
        this.fire('cancelEdit');
      },
      change: (e: Object): any => {
        e.preventDefault();
        this.fire('change', null, () => {
          this.props.refreshData();
          this.props.refreshList();
        });
      },
      complete: this.toggleVisibility.bind(null, 'CompleteJobPopup'),
      create_offer: this.navigateTo.bind(null, 'Offer'),
      accept: (e: Object): any => {
        e.preventDefault();
        this.fire('accept', null, () => {
          this.props.refreshData();
          this.props.refreshList();
        });
      },
      execute: (e: Object): any => {
        e.preventDefault();
        this.fire('execute', null, () => {
          this.props.refreshData();
          this.props.refreshList();
        });
      },
      no_interest: (e: Object): any => {
        e.preventDefault();
        this.toggleVisibility('Decline');
      },
      add_contractor: this.toggleVisibility.bind(null, 'ContractorSelector'),
      create_orphan: (e: Object): any => {
        e.preventDefault();
        this.props.createJob('create_orphan', id =>
          this.props.history.push(`/jobs/${id}`),
        );
      },
      create_project_offer: (e: Object): any => {
        e.preventDefault();
        this.props.createJob('create_project_offer', id => {
          const nextPath = `/projects/${this.props.match.params.project_id}/${id}`;
          return this.props.history.push(nextPath);
        });
      },
      create_project_direct: (e: Object): any => {
        e.preventDefault();
        this.props.createJob('create_project_direct', id => {
          const nextPath = `/projects/${this.props.match.params.project_id}/${id}`;
          return this.props.history.push(nextPath);
        });
      },
      export_grant_request: e => {
        e.preventDefault();
        if (this.props.match.params.project_id) {
          this.props.history.push(
            `/projects/${this.props.match.params.project_id}/${this.props.match.params.job_id}/grant-request`,
          );
        } else {
          this.props.history.push(
            `/jobs/${this.props.match.params.job_id}/grant-request`,
          );
        }
      },
    };
  }

  state: StateShape;

  componentDidMount() {
    if (this.getNextNavPath() === this.props.history.location.pathname) {
      this.navigateTo('Offer');
    }
  }

  onNoInterestConfirmed = () =>
    this.fire(
      'noInterest',
      {
        api: this.getFooterActions()
          .filter(item => item.name === 'no_interest')
          .reduce((_, item) => item.action, null),
      },
      this.props.refreshData,
    );

  onGrant = (type = 'Grant') => {
    if (this.props.inviteRequired) {
      this.updateContractorStateAnd(() => this.toggleVisibility('InviteModal'));
    } else {
      this.extraNominationSuccessCB = () => {
        this.fire(
          'inviteSuccess',
          this.props.isJobOffer
            ? this.replaceLangString(
                'ACTION_BAR-invitation_sent_in_offer_request',
              )
            : this.replaceLangString(
                'ACTION_BAR-invitation_sent_in_direct_job',
              ),
        );
        this.extraNominationSuccessCB = null;
      };
      this.updateContractorStateAnd(() => this.toggleVisibility(type));
    }
  };

  onResendOfferGrant = () => {
    this.toggleVisibility('ResendOfferGrant');
  };

  onRequestOffers = () => {
    if (this.state.invitesRequiredForContractors.length) {
      this.setState(prevState => {
        const [nextContractor] = prevState.invitesRequiredForContractors;
        return {
          contractorId: nextContractor.contractor_id,
          contractorName: nextContractor.billing_company,
          inviteRequired: true,
          visibleInviteModal: true,
          grantContractor: nextContractor,
        };
      });
    } else {
      this.toggleVisibility('RequestOffers');
    }
    this.extraNominationSuccessCB = () => {
      this.fire(
        'inviteSuccess',
        this.props.isJobOffer
          ? this.replaceLangString(
              'ACTION_BAR-invitation_sent_in_offer_request',
            )
          : this.replaceLangString('ACTION_BAR-invitation_sent_in_direct_job'),
      );
      this.extraNominationSuccessCB = null;
    };
  };

  getNextNavPath = (): string => {
    switch (true) {
      case !this.state.visibleOffer:
        // go to offer
        return `${this.props.match.url}/offer`;
      case this.state.visibleOffer:
        // go to job
        return `/jobs/${this.props.match.params.job_id}`;
      default:
        return '';
    }
  };

  getFooterActions = () => {
    if (this.props.isJobEditing && this.props.jobId) {
      return [
        {
          name: 'cancelEdit',
          type: 'text',
        },
        {
          name: 'change',
          type: 'default',
        },
      ];
    }

    const { available_transitions: transitions } = this.props.machine;

    const orderedTransitions = this.transitionOrder
      .filter(t => {
        if (this.props.canNotWithdraw) {
          return t.name !== 'no_interest';
        }

        const transitionExists = transitions.findIndex(v => v.name === t);
        return transitionExists !== -1;
      })
      .map(t => {
        const transitionIndex = transitions.findIndex(v => v.name === t);
        return transitions[transitionIndex];
      });
    return [
      {
        name: 'back',
        type: 'text',
        action: null,
      },
      ...orderedTransitions,
    ];
  };

  transitionOrder = [
    'create_orphan',
    'create_project_direct',
    'create_project_offer',
    'update',
    'create_offer',
    'no_interest',
    'grant',
    'export_grant_request',
    'resend_grant',
    'resend_offer_grant',
    'request',
    'add_contractor',
    'accept',
    'execute',
    'complete',
    'copy',
    'cancel',
    'delete',
  ];

  updateContractorStateAnd = (callback = () => null) => {
    this.setState(
      (prevState: StateShape): StateShape => ({
        ...prevState,
        grantContractor: this.props.contractor,
        contractorName: this.props.contractor.billing_company,
        contractorId: this.props.contractor.contractor_id,
      }),
      callback(),
    );
  };

  singleContractorClicked = (contractorId, contractorData) => {
    // close selector
    this.toggleVisibility('ContractorSelector');
    this.setState({
      confirmAddSingleContractor: {
        ...contractorData,
      },
    });
    this.toggleVisibility('ConfirmAddSingleContractor');
  };

  singleContractorClickedConfirmed = () => {
    const contractorData = this.state.confirmAddSingleContractor;
    if (!contractorData.has_profile) {
      // invite..
      this.setState((prevState: StateShape): StateShape => ({
        ...prevState,
        contractorId: contractorData.contractor_id,
        contractorName: contractorData.billing_company,
        visibleInviteModal: true,
        inviteRequired: true,
        grantContractor: contractorData,
      }));

      this.extraNominationSuccessCB = () => {
        this.addSingleContractor(contractorData.contractor_id);
        this.props.setNotification(l('ACTION_BAR-new_contractor_added'));
        this.extraNominationSuccessCB = null;
      };
    } else {
      this.addSingleContractor(contractorData.contractor_id);
      this.props.refreshData();
      this.props.setNotification(l('ACTION_BAR-new_contractor_added'));
    }
  };

  addSingleContractor = contractorId => {
    this.fire('singleOfferRequest', {
      api: this.getFooterActions()
        .filter(item => item.name === 'add_contractor')
        .reduce((___, item) => item.action, null),
      payload: { contractor_id: contractorId },
    });
  };

  permActs: Object;

  toggleVisibility = (
    target:
      | 'Rating'
      | 'Grant'
      | 'Offer'
      | 'RequestOffers'
      | 'ContractorSelector'
      | 'Decline',
    e?: Object,
  ): any => {
    if (e) {
      e.preventDefault();
    }
    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      [`visible${target}`]: !prevState[`visible${target}`],
    }));
  };

  navigateTo = (target: 'Offer', e?: Object): any => {
    if (e) e.preventDefault();
    const nextUrl = this.getNextNavPath(target);
    if (nextUrl !== this.props.history.location.pathname) {
      this.props.history.push(nextUrl);
    }

    return this.toggleVisibility(target);
  };

  openInvitationForm = () => {
    return this.setState({
      visibleGrant: false,
      visibleInviteForm: true,
    });
  };

  closeInvitationForm = (e: Object): any => {
    if (e && Object.prototype.hasOwnProperty.call(e, 'preventDefault')) {
      e.preventDefault();
    }

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      visibleInviteForm: false,
    }));
  };

  replaceLangString = string => {
    if (typeof this.state.contractorName === 'undefined') {
      return l(string).replace(
        ':contractor_name',
        this.state.grantContractor.billing_company,
      );
    }
    return l(string).replace(':contractor_name', this.state.contractorName);
  };

  nominationProccesed = (): any => {
    if (this.extraNominationSuccessCB) {
      this.extraNominationSuccessCB();
    }
    this.props.refreshData();

    const nextContractor = this.state.invitesRequiredForContractors[0];
    const nextList = this.state.invitesRequiredForContractors.filter(
      (c: Object): any => c.id !== nextContractor.contractor_id,
    );

    return this.setState((prevState: StateShape): StateShape => ({
      ...prevState,
      visibleInviteForm: false,
      invitesRequiredForContractors: nextList,
    }));
  };

  fire = (name: string, props?: any, overrideCb?: any): any =>
    this.props.dispatch(
      jobActions(this.props.jobId)[name](
        props,
        overrideCb || this.props.refreshList,
      ),
    );

  toggleGrantModal = (type = 'Grant') => {
    let key = `visible${type}`;
    let val = this.state[key];
    if (!val && this.state.visibleResendGrant) {
      val = true;
      key = 'visibleResendGrant';
    }
    this.setState({ [key]: !val });
  };

  render(): React$Element<*> {
    return (
      <div className="fl-right">
        <div className="fl-right__item">
          <WithLoader
            listeners={[
              { name: 'jobRequest', loading: this.props.jobActionLoading },
            ]}
            loadingText={l('LOADING_POPUP-description')}>
            <ButtonGroup alt numButtons={3}>
              {this.getFooterActions().map((item, key) => (
                <button
                  key={key}
                  type="button"
                  data-meta={{ type: item.type || 'default' }}
                  onClick={this.actionFunctions[item.name]}
                  data-qe-id={`action-click-${item.name}`}>
                  {l(actionTitles[item.name].title)}
                </button>
              ))}
            </ButtonGroup>
          </WithLoader>
        </div>

        <SimplePopup
          isOpen={this.state.visibleDecline}
          close={this.toggleVisibility.bind(null, 'Decline')}
          title={l('POPUP-withdraw-title')}
          description={l('POPUP-withdraw-body')}
          options={[
            {
              cb: () => {
                this.toggleVisibility('Decline');
              },
              buttonText: l('cancel'),
              buttonClass: 'btn--text',
            },
            {
              cb: () => {
                this.toggleVisibility('Decline');
                this.onNoInterestConfirmed();
              },
              buttonText: l('JOB-ACTIONS-NO_INTEREST'),
              buttonClass: 'btn--primary',
              dataQeId: 'confirm-no-interest',
            },
          ]}
        />
        <SimplePopup
          isOpen={this.state.visibleConfirmAddSingleContractor}
          close={this.toggleVisibility.bind(null, 'ConfirmAddSingleContractor')}
          title={l('POPUP-add_contractor_title')}
          description={l('POPUP-add_contractor_body').replace(
            ':contractor_name',
            this.state.confirmAddSingleContractor.billing_company,
          )}
          options={[
            {
              cb: () => {
                this.toggleVisibility('ConfirmAddSingleContractor');
              },
              buttonText: l('cancel'),
              buttonClass: 'btn--text',
            },
            {
              cb: () => {
                this.toggleVisibility('ConfirmAddSingleContractor');
                this.singleContractorClickedConfirmed();
              },
              buttonText: l('POPUP_Button-ok'),
              buttonClass: 'btn--primary',
              dataQeId: 'confirm-add-single-contractor',
            },
          ]}
        />
        <SimplePopup
          isOpen={this.state.visibleDecline}
          close={this.toggleVisibility.bind(null, 'Decline')}
          title={l('POPUP-withdraw-title')}
          description={l('POPUP-withdraw-body')}
          options={[
            {
              cb: () => {
                this.toggleVisibility('Decline');
              },
              buttonText: l('cancel'),
              buttonClass: 'btn--text',
            },
            {
              cb: () => {
                this.toggleVisibility('Decline');
                this.onNoInterestConfirmed();
              },
              buttonText: l('JOB-ACTIONS-NO_INTEREST'),
              buttonClass: 'btn--primary',
            },
          ]}
        />
        <SimplePopup
          isOpen={this.state.visibleCancelJobPopup}
          close={this.toggleVisibility.bind(null, 'CancelJobPopup')}
          title={l('POPUP-job_cancel_title')}
          description={l('POPUP-job_cancel_body')}
          options={[
            {
              cb: () => {
                this.toggleVisibility('CancelJobPopup');
              },
              buttonText: l('cancel'),
              buttonClass: 'btn--text',
            },
            {
              cb: () => {
                this.toggleVisibility('CancelJobPopup');
                this.fire('cancel', null, () => {
                  this.props.refreshData();
                  this.props.refreshList();
                });
              },
              buttonText: l('POPUP_Button-ok'),
              buttonClass: 'btn--primary',
              dataQeId: 'confirm-cancel-job',
            },
          ]}
        />

        <SimplePopup
          isOpen={this.state.visibleCompleteJobPopup}
          close={this.toggleVisibility.bind(null, 'CompleteJobPopup')}
          title={l('POPUP-job_complete_title')}
          description={l('POPUP-job_complete_body')}
          options={[
            {
              cb: () => {
                this.toggleVisibility('CompleteJobPopup');
              },
              buttonText: l('cancel'),
              buttonClass: 'btn--text',
            },
            {
              cb: () => {
                this.toggleVisibility('CompleteJobPopup');
                this.fire('complete', null, () => {
                  this.props.refreshData();
                  this.props.refreshList();
                });
              },
              buttonText: l('POPUP_Button-close'),
              buttonClass: 'btn--primary',
              dataQeId: 'confirm-complete-job',
            },
          ]}
        />

        <GrantModal
          jobId={this.props.jobId}
          isOpen={this.state.visibleGrant || this.state.visibleResendGrant}
          contractor={this.state.grantContractor}
          subTitle={replaceSubKeys(l('GRANT_JOB-CONTACTS_POPUP-DESCRIPTION'), {
            job_trade: (this.props.trade && this.props.trade.value) || '',
            job_id: this.props.jobId,
            contractor_name: this.props.contractor.billing_company,
          })}
          close={() => {
            this.toggleGrantModal();
          }}
          callback={(n: any): any =>
            this.fire(
              this.state.visibleGrant ? 'grant' : 'resend_grant',
              n,
              () => {
                this.props.refreshData();
                this.props.refreshList();
                this.toggleGrantModal();
              },
            )
          }
        />
        <InviteModal
          isOpen={this.state.visibleInviteModal}
          onInviteConfirm={() => {
            this.toggleVisibility('InviteModal');
            this.openInvitationForm();
          }}
          close={() => this.toggleVisibility('InviteModal')}
          description={replaceSubKeys(l('GRANT_JOB-INVITIATION-body'), {
            contractor_name: this.state.contractorName,
          })}
        />
        <GrantModal
          jobId={this.props.jobId}
          isOpen={this.state.visibleResendOfferGrant}
          contractor={this.props.job.vmp_contractor}
          subTitle={replaceSubKeys(l('ACCEPT_OFFERS-description'), {
            job_name: `${(this.props.trade && this.props.trade.value) ||
              ''} Job ${this.props.jobId}`,
            contractor_name: this.props.job.vmp_contractor?.billing_company,
          })}
          callback={(n: any): any => {
            this.toggleVisibility('ResendOfferGrant');
            this.fire('resend_offer_grant', n, () => {
              this.props.refreshData();
              this.props.refreshList();
            });
          }}
          submitLoading={this.props.jobActionLoading}
          close={this.toggleVisibility.bind(null, 'ResendOfferGrant')}
        />
        {/** @todo: add nomination slider route */}
        <AsideSlide
          isOpen={this.state.visibleInviteForm}
          title={this.state.contractorName || ''}
          toggle={this.closeInvitationForm}
          toggleButton={() => (
            <CloseButton onClick={this.closeInvitationForm} />
          )}
          bgcAlt>
          <NominationForm
            backAction={this.closeInvitationForm}
            contractorId={this.state.grantContractor?.contractor_id}
            jobId={this.props.jobId}
            successCallback={this.nominationProccesed}
          />
        </AsideSlide>
        <Overlay isOpen={this.state.visibleRequestOffers} size="--small">
          <RequestOffersModal
            visible={this.state.visibleRequestOffers}
            close={this.toggleVisibility.bind(null, 'RequestOffers')}
            callback={(payload: any): any =>
              this.fire('requestOffers', payload, () => {
                this.props.refreshData();
                this.props.refreshList();
              })
            }
          />
        </Overlay>
        <ContractorSelectorPanelList
          ignoreContractors={this.props.contractors.map(
            (c: Object): any => c.contractor_id,
          )}
          isOpen={this.state.visibleContractorSelector}
          listName="single_job_contractors"
          toggle={this.toggleVisibility.bind(null, 'ContractorSelector')}
          clickAct={this.singleContractorClicked}
          concernType={this.props.concernType}
          concernId={this.props.concernId}
        />
        <ErrorPopup
          isOpen={this.props.jobActionFailed}
          callback={this.props.clearSubRequest}
        />
      </div>
    );
  }
}

const mapState = ({
  jobStore,
  authStore,
  simpleListStore,
  machineStore,
}): ReduxStateShape => ({
  isJobVisible: machineStore.job === 'VIEW',
  isJobEditing: ['UPDATING', 'UPDATE_ERROR', 'EDIT'].includes(machineStore.job),
  request: jobStore.request,
  status: jobStore.data.job.info_status,
  job: jobStore.data.job,
  vmp_subscribers: jobStore.data.job?.vmp_subscribers || [],
  jobId: parseInt(jobStore.data.job.job_id, 10),
  contractorId: jobStore.data.job.vmp_contractor?.contractor_id,
  contractorName: jobStore.data.job.vmp_contractor?.billing_company || '',
  inviteRequired: jobStore.data.job.vmp_contractor
    ? !jobStore.data.job.vmp_contractor.has_profile
    : false,
  canNotWithdraw:
    jobStore.data.type === 'offer' &&
    authStore.user.role === RoleIds.CONTRACTOR &&
    !!simpleListStore.list.single_job_offers.data.filter(
      offer =>
        parseInt(offer.user_id, 10) === authStore.user.id &&
        offer.offer_status === 'submitted',
    ).length,
  isJobOffer: jobStore.request.success && jobStore.data.job.type === 'offer',
  contractor:
    (jobStore.data.job.type === 'direct' && jobStore.data.job.vmp_contractor) ||
    {},
  contractors:
    (jobStore.data.job.type === 'offer' && jobStore.data.job.vmp_subscribers) ||
    [],
  contractorsWithoutUsers:
    (jobStore.data.job.type === 'offer' &&
      jobStore.data.job.vmp_subscribers &&
      jobStore.data.job.vmp_subscribers.filter(
        (sub: Object): any => sub.has_profile === false,
      )) ||
    (jobStore.data.job.vmp_contractor &&
      jobStore.data.job.vmp_contractor.has_profile === false && [
        jobStore.data.job.vmp_contractor,
      ]) ||
    [],
  concernId: jobStore.data.job.concern.concern_id,
  concernType: jobStore.data.job.concern.concern_type,
  machine:
    machineStore.job === 'LOADING'
      ? { available_transitions: [] }
      : jobStore.data.job.state_machine,
  jobActionLoading: jobStore.subRequest.fetching,
  jobActionFailed: jobStore.subRequest.failed,
});

const mapActions = (dispatch: Function, props): ReduxPropsShape => ({
  setNotification(message): any {
    return dispatch(setListItem('single_job_actions', message));
  },
  createJob(type, onSuccess, onFailed = () => props.scrollToTop()): any {
    return dispatch(createJobAction(type, onSuccess, onFailed));
  },
  dispatch,
  clearSubRequest: () => dispatch({ type: CLEAR_SUB_REQUEST }),
});

export default withRouter(connect(mapState, mapActions)(JobFooter));
