/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import { StateConditional } from 'machines/components/RenderConditional';
import BillOrderPanel from 'shared/jobs/Panels/items/BillOrder';
import { PanelStates } from 'machines/constants';
import MachineBuilder from './machine';

type ReduxStateShape = {
  address: string[],
  status: string,
  coversheet: string,
  address_account: string[],
};

export const InvoiceProcessCardMachine = MachineBuilder();
const InvoiceProcessCard = ({
  address,
  status,
  coversheet,
  machine_state,
  address_account,
}) => (
  <StateConditional state={machine_state} whenState={[PanelStates.VISIBLE]}>
    <BillOrderPanel
      address={address}
      jobStatus={status}
      coversheet={coversheet}
      address_account={address_account}
    />
  </StateConditional>
);

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  machine_state: machineStore.invoiceprocess_card,
  address: jobStore.data.job.address_invoicing,
  coversheet: jobStore.data.job.coversheet,
  status: jobStore.data.job.info_status,
  address_account: jobStore.data.job?.address_account,
});

export default connect(mapState)(InvoiceProcessCard);
