import React from 'react';
import { connect } from 'react-redux';
import DocumentUploader from 'components/connected/DocumentUploader';
import DocConstants from 'components/connected/DocumentUploader/constants';
import defaultRoles, { getRoleNameFromId } from 'constants/roles';
import l from 'helpers/locale';
import { removeDoc, addDoc, clearDocs } from '../actions';

const { RoleName } = defaultRoles;

const { DELETE, DOWNLOAD, VIEW } = DocConstants.DOCUMENT_ACTION;

type ReduxStateShape = {
  userRole: string | number,
  documents: Object[],
};

type StateShape = {
  docs: Object[],
  roleName: string,
  isErrored: boolean,
  // eslint-disable-next-line react/no-unused-state
  prevErrorState: boolean,
};

type PropsShape = {
  viewOnly: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  hasError: boolean,
  fetchedData: {
    jobId: number | string,
    offerId?: number | string,
    file?: Object,
  },
};

class OfferDocuments extends React.Component<PropsShape> {
  static getDerivedStateFromProps = (
    nextProps: Object,
    prevState: StateShape,
  ) => ({
    ...prevState,
    docs: nextProps.documents,
    isErrored: nextProps.hasError && !prevState.prevErrorState,
  });

  constructor(props) {
    super(props);
    this.state = {
      docs: [],
      /* we only get to do this because userRole is bound by redux
       * so we will always have it available here
       */
      roleName: getRoleNameFromId(props.userRole),
      isErrored: false,
      // eslint-disable-next-line react/no-unused-state
      prevErrorState: false,
    };
  }

  componentDidMount() {
    if (this.props.fetchedData.file) {
      this.props.addDoc({
        payload: this.props.fetchedData.file,
        machine: this.props.fetchedData.file.state_machine,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearDocs();
  }

  onUpload = (payload: Object) => {
    this.props.addDoc(payload);
    this.setState((prevState: StateShape) => ({
      ...prevState,
      isErrored: false,
      prevErrorState: prevState.isErrored,
    }));
  };

  getDocumentControls = () => [
    {
      svgName: 'close',
      action: (id: string | number): any => this.removeDocument(id),
      documentAction: DELETE,
      canDisplay:
        getRoleNameFromId(this.props.userRole) === RoleName.CONTRACTOR &&
        !this.props.viewOnly,
    },
    {
      documentAction: DOWNLOAD,
      svgName: 'download',
      action: null,
      canDisplay: true,
    },
    {
      documentAction: VIEW,
      svgName: 'view',
      action: null,
      canDisplay: true,
    },
  ];

  removeDocument = id => {
    this.props.deleteDoc(id);
  };

  isUploaderVisible = () =>
    !this.props.viewOnly &&
    this.state.roleName === RoleName.CONTRACTOR &&
    this.state.docs.length < 1;

  render() {
    return (
      <div className="g g-1/1">
        <div className="push--bottom">
          <DocumentUploader
            dataQeId="action-drop-offer_document"
            listName="create_offer"
            list={this.state.docs}
            endpoint="api/1/documents/action/add"
            data={{
              job_id: this.props.fetchedData.jobId,
              source_tag: 'offer',
            }}
            showUploader={this.isUploaderVisible()}
            fullWidthOnImage
            previewPdf
            limitHeight={false}
            controls={this.getDocumentControls()}
            uploadCallback={(payload: Object) => this.onUpload(payload)}
          />
          {this.state.isErrored ? (
            <p className="form__item__validation push--small--top">
              {l('OFFER_FILE_UPLOAD_REQUIRED')}
            </p>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapState = (state: Object): ReduxStateShape => ({
  userRole: state.authStore.user.role,
  documents: state.docStore.create_offer.docs,
});

const mapActions = (dispatch: Function) => ({
  deleteDoc(id: string | number): any {
    return removeDoc(id, 'create_offer', dispatch);
  },
  addDoc(file: Object): any {
    return addDoc(file, 'create_offer', dispatch);
  },
  clearDocs(): any {
    return clearDocs('create_offer', dispatch);
  },
});

const OfferDocumentsConnected = connect(mapState, mapActions)(OfferDocuments);

export default (fetchedData: Object, viewOnly: boolean): Function => (
  props: Object,
): React$Element<*> => (
  <OfferDocumentsConnected
    {...props}
    fetchedData={fetchedData}
    viewOnly={viewOnly}
  />
);
