import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import RouteSlider from 'components/RouteSlider';
import ContractorNotifications from 'components/ContractorNotifications';
import roles from 'constants/roles';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { hasAuth } from '../functions/authenticator.func.js';
import { setLoginRedirect } from '../actions/app.js';
import TermsPopup from '../components/TermsPopup';

type AuthPropsShape = {
  auth: boolean,
  storeAuth: boolean,
  rest: any,
  location: Object,
  setRedirectPath: Function,
  redirectPath: string,
};

const getNotifications = roleId => {
  switch (roleId) {
    case roles.RoleIds.CONTRACTOR:
      return <ContractorNotifications />;
    default:
      return null;
  }
};

/*
 * do not attempt to set a redirect path on these pages...
 */
const redirectExceptions = ['invitations'];

const AuthWrapper = ({
  auth,
  redirectPath,
  setRedirectPath,
  location,
  ...rest
}: AuthPropsShape): React$Element<*> => {
  const { pathname } = location;
  let toRender;
  const currentPath = `${location.pathname}${location.hash}`;

  if (
    !hasAuth() &&
    redirectPath !== currentPath &&
    !redirectExceptions.some(test => currentPath.includes(test))
  ) {
    setRedirectPath(currentPath);
  }

  React.useEffect(() => {
    if (redirectPath !== '') {
      if (typeof window === 'object' && pathname !== '/') {
        setRedirectPath('');
      }
    }
  }, [redirectPath, setRedirectPath, pathname]);

  if (auth) {
    toRender = hasAuth() ? (
      <AppLayout {...rest} to />
    ) : (
      <Redirect to="/" {...rest} />
    );
  } else {
    toRender = <AppLayout noSidebar {...rest} to />;
  }
  return toRender;
};

type PropsShape = {
  showTermsPopUp: boolean,
  noSidebar: boolean,
  component?: any,
  rest?: any,
};

const AppLayout = ({
  showTermsPopUp,
  noSidebar,
  component: Component,
  roleId,
  userId,
  ...rest
}: PropsShape): React$Element<*> => (
  <Fragment>
    {showTermsPopUp ? <TermsPopup /> : null}
    <div className="app-layout">
      <div className="app-layout__head">
        <div className="app-layout__head__item">
          {getNotifications(roleId, userId)}
        </div>
        <div className="app-layout__head__item">
          <Header />
        </div>
      </div>
      <div className="app-layout__body">
        {noSidebar ? null : (
          <div className="app-layout__body__aside">
            <Sidebar />
          </div>
        )}
        <div className="app-layout__body__main">
          <div className="island--smaller">
            <div className="wrap--large">
              <Route
                {...rest}
                render={() => (
                  <Fragment>
                    <Component />
                    <RouteSlider />
                  </Fragment>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

AppLayout.defaultProps = {
  component: <div />,
  rest: [{ empty: true }],
  noSidebar: false,
  showTermsPopUp: true,
};

const mapState = (state: { authStore: any, appStore: any }): any => ({
  roleId: state.authStore.user.role,
  storeAuth: state.authStore.auth,
  redirectPath: state.appStore.redirectPath,
});

const mapActions = (dispatch: Function): any => ({
  setRedirectPath(path: string): any {
    return dispatch(setLoginRedirect(path));
  },
});

export default withRouter(connect(mapState, mapActions)(AuthWrapper));
