import React from 'react';
import DocumentUploader from 'components/connected/DocumentUploader';
import type { ControlShape } from 'components/connected/DocumentUploader';
import Card from 'components/Card';
import l from 'helpers/locale';

type PropsShape = {
  data: {
    uploadProps: Object,
    endpoint: string,
  },
  listName: string,
  list: Object[],
  controls?: [ControlShape],
};

const GenericDocumentsPanel = ({
  data,
  listName,
  list,
  controls,
  title,
  identifier,
  isOpen = true,
  showLock = false,
  footer = null,
}: PropsShape): React$Element<*> => (
  <Card
    identifier={listName}
    title={title}
    isOpen={isOpen}
    showLock={showLock}
    footer={footer}
    qeId={`job_card-${identifier}`}>
    <DocumentUploader
      dataQeId={identifier}
      listName={listName}
      list={list}
      endpoint={data.endpoint}
      data={data.uploadProps}
      controls={controls}
    />
    {footer ? (
      <div className="card__body__footer">
        <div className="gw">
          <div className="g g-1/1">
            <span className="form__label--small">{l(footer)}</span>
          </div>
        </div>
      </div>
    ) : null}
  </Card>
);

GenericDocumentsPanel.defaultProps = {
  controls: [],
};

export default GenericDocumentsPanel;
