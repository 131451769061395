import React from 'react';
import l from 'helpers/locale';
import Select, { components } from 'react-select';
import GetSvg from 'components/GetSvg';

type OptionType = {
  label: string,
  value: string | number,
};

type PropType = {
  // REQUIRED
  options: Array<OptionType>,
  onValueChange: OptionType => any,

  // OPTIONAL
  className?: string,
  placeholder?: string,
  classNamePrefix?: string,
  hideResetButton?: boolean,
  defaultValue?: OptionType,
};

const DropdownIndicator = props => {
  const modClass = props.selectProps.menuIsOpen ? 'rotate-180' : '';
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <GetSvg svg="caret_down" wrapperClass={modClass} />
      </components.DropdownIndicator>
    )
  );
};

function AllianzSelect(props: PropType) {
  return (
    <Select
      options={props.options}
      onChange={props.onValueChange}
      className={props.className}
      placeholder={l(props.placeholder)}
      classNamePrefix={props.classNamePrefix}
      isClearable={!props.hideResetButton}
      defaultValue={props.defaultValue}
      components={{ DropdownIndicator }}
    />
  );
}

AllianzSelect.defaultProps = {
  className: 'allianz-selector',
  classNamePrefix: 'Select',
  placeholder: 'JOB-according_to-placeholder',
  hideResetButton: false,
  defaultValue: null,
};

export default AllianzSelect;
