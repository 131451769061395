import React from 'react';
import GetSvg from 'components/GetSvg';

export default ({ status }) => {
  let stateSvgName = '';
  let stateSvgClass = '';
  switch (status) {
    case '-1': // canceled
      stateSvgName = 'triangle_ex';
      stateSvgClass = 'u-warning';
      break;
    case '0': // draft
      stateSvgName = 'triangle_ex';
      stateSvgClass = 'u-success';
      break;
    case '1': // offers-requested
      stateSvgName = 'scale';
      stateSvgClass = 'u-warning';
      break;
    case '3': // offers-received
      stateSvgName = 'scale';
      stateSvgClass = 'u-warning';
      break;
    case '4': // granted
      stateSvgName = 'job_slanted';
      stateSvgClass = 'u-warning';
      break;
    case '5': // accepted
      stateSvgName = 'job_slanted';
      stateSvgClass = 'u-warning';
      break;
    case '6': // executed
      stateSvgName = 'circle_check';
      stateSvgClass = 'u-success';
      break;
    case '7': // rated
      stateSvgName = 'circle_check';
      stateSvgClass = 'u-success';
      break;
    case '8': // META_GRANTED_TO_COMPETITOR
    case '9': // META_NO_INTEREST
    case '10': // Completed
      stateSvgName = 'circle_check';
      stateSvgClass = 'u-success';
      break;
    default:
      stateSvgName = 'triangle_ex';
      stateSvgClass = 'u-default';
      break;
  }
  return <GetSvg svg={stateSvgName} wrapperClass={stateSvgClass} />;
};
