import React, { Fragment } from 'react';
import ActionBar from 'components/ConnectedActionBar';
import * as ActionBarConstants from 'components/ConnectedActionBar/constants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PageReady from 'components/AllianzPageReady';
import GraphQLPageReady from 'components/GraphQLPageReady';
import Form from 'components/Form';
import axios from 'helpers/axios';
import validatePhone from 'functions/validatePhone';
import validateWebsite from 'functions/validateWebsite';
import validateUID from 'functions/validateUID';
import {
  getBillingInfoFields,
  getTradesFields,
  getBankInfoFields,
} from 'views/invitation/segments/account/fields';
import formatErrors from 'helpers/formatErrors';
import errorFormatting from 'views/invitation/segments/account/formatting';
import { getContractorQuery } from '../SingleContractor/query';

// map Graph Schema to Field schema
const ProfileFields = ({ trades, countries, vmp_contractor }) => [
  getBillingInfoFields({
    vmp_contractor: {
      company: vmp_contractor.billing_company,
      company_2: vmp_contractor.company_2,
      street: vmp_contractor.street,
      pobox: vmp_contractor.pobox,
      zip: vmp_contractor.postal_code,
      city: vmp_contractor.city,
      country: vmp_contractor.country, // this is an Object
      company_phone: vmp_contractor.billing_telephone,
      company_email: vmp_contractor.billing_email,
      website: vmp_contractor.website,
      uid_number: vmp_contractor.uid_number,
    },
    countries,
    default_country_id: vmp_contractor.country?.id,
  }),
  getTradesFields(
    trades,
    vmp_contractor.trades?.map(item => parseInt(item.key, 10)) || [],
  ),
  getBankInfoFields({ vmp_contractor }),
];

type ProfileProps = {
  backAction: Function,
  scrollToTop: Function,
  action_bar_name?: string,
};

class Profile extends React.Component {
  constructor(props: ProfileProps) {
    super(props);
    this.state = {
      trades: [],
      countries: [],
      default_country_id: null,
      vmp_contractor: {},
      errors: {},
    };
  }

  onStaticData = payload => {
    this.setState({
      trades: payload.trades,
      countries: payload.countries,
      default_country_id: payload.default_country_id,
    });
  };

  onProfileResponse = ({ data }) => {
    this.setState({ vmp_contractor: data.data.vmp_contractor });
  };

  getFields(): Array {
    return ProfileFields({
      ...this.state,
    });
  }

  submitData(form_data: Object) {
    this.setState({
      errors: {},
      isProcessing: true,
    });

    const data = {
      profile: {
        company: form_data.company,
        company_2: form_data.company_2,
        street: form_data.street,
        pobox: form_data.pobox,
        zip: form_data.zip,
        city: form_data.city,
        country_id: form_data.country,
        email: form_data.company_email,
        uid_number: form_data.uid_number,
        iban: form_data.iban,
        bank_address: form_data.bank_address,
        website: form_data.website,
        phone: form_data.company_phone,
        trades: form_data.trades || false,
      },
    };

    // @todo discuss refactoring the API
    // to support a flat request/response structure
    axios
      .post(
        `/api/1/contractor_profiles/${this.state.vmp_contractor.profile.id}/update`,
        data,
      )
      .then(this.onProfileUpdate)
      .catch(this.onProfileUpdateError);
  }

  onProfileUpdate = () => {
    this.setState({
      errors: {},
      isProcessing: false,
    });
    this.props.publishProfileUpdated();
    this.props.backAction();
  };

  onProfileUpdateError = errors => {
    this.setState({
      errors,
      isProcessing: false,
    });
    this.props.publishFormFailed();
  };

  validator = validationFailedCB => payload => {
    const requiredFields = [
      'company',
      'street',
      'zip',
      'city',
      'country',
      'company_phone',
      'company_email',
      'trades',
      'iban',
      'bank_address',
    ];
    let validatorResponse = requiredFields.reduce(
      (acc: Object, curr: string) =>
        !payload[curr] ? { ...acc, [curr]: 'VALIDATION-required' } : acc,
      {},
    );

    if (payload.uid_number !== '' && !validateUID(payload.uid_number)) {
      validatorResponse = {
        ...validatorResponse,
        uid_number: 'VALIDATION-required',
      };
    }

    if (payload.website && !validateWebsite(payload.website)) {
      validatorResponse = {
        ...validatorResponse,
        ...{ website: 'FORM-website-description' },
      };
    }

    if (!validatePhone(payload.company_phone)) {
      validatorResponse = {
        ...validatorResponse,
        ...{ company_phone: 'FORM-phone-description' },
      };
    }

    if (Object.keys(validatorResponse).length > 0 && validationFailedCB) {
      validationFailedCB();
    }

    return {
      result: Object.keys(validatorResponse).length === 0,
      fields: validatorResponse,
    };
  };

  graphi: GraphQLPageReady;

  render() {
    return (
      <Fragment>
        <ActionBar
          name="contractor_profile"
          scrollToTop={this.props.scrollToTop}
        />
        <PageReady
          url="/api/1/static-data"
          onData={res => this.onStaticData(res.data.payload)}>
          <GraphQLPageReady
            ref={inst => {
              this.graphi = inst;
            }}
            onData={this.onProfileResponse}
            queryId="getContractorProfile"
            queryString={getContractorQuery(
              this.props.match.params.contractor_id,
            )}>
            <Form
              cancelAct={this.props.backAction}
              submitAct={formData => this.submitData(formData)}
              sections={this.getFields()}
              wrappingClass="gw"
              wrappingOuterClass=""
              validateBefore={this.validator(this.props.publishFormFailed)}
              afterSubmitErrors={formatErrors(
                this.state.errors,
                errorFormatting,
              )}
              isProcessing={this.state.isProcessing}
            />
          </GraphQLPageReady>
        </PageReady>
      </Fragment>
    );
  }
}

Profile.defaultProps = {
  action_bar_name: 'single_contractor',
};

const mapActions = (dispatch: Function, { action_bar_name }) => ({
  publishFormFailed(): any {
    return dispatch({
      type: ActionBarConstants.SET_ITEM,
      payload: {
        name: action_bar_name,
        message: 'ACTION_BAR-form_submission-failed',
      },
    });
  },
  publishProfileUpdated(): any {
    return dispatch({
      type: ActionBarConstants.SET_ITEM,
      payload: {
        name: action_bar_name,
        message: 'ACTION_BAR-contractor_profile-update_success',
      },
    });
  },
});

export default withRouter(connect(null, mapActions)(Profile));
