import React, { Fragment } from 'react';
import GetSvg from 'components/GetSvg';
import type {
  DocumentDataShape,
  ControlShape,
} from 'components/connected/DocumentUploader';
import DocConstants from 'components/connected/DocumentUploader/constants';

import l from 'helpers/locale';

import PreviewSlider from 'components/PreviewSlider';
import If from 'components/Conditions/If';

const { DELETE, VIEW, DOWNLOAD, MINIMIZE } = DocConstants.DOCUMENT_ACTION;

type PropsShape = {
  item: ControlShape,
  data: DocumentDataShape,
  wrappingClassName?: string,
  className?: string,
  showPreviewButton?: boolean,
  clickAction?: Function | boolean,
};

class ControlItem extends React.Component<PropsShape> {
  static defaultProps = {
    wrappingClassName: 'image-preview__inner__controls__item',
    className: 'btn-svg--primary',
    showPreviewButton: true,
    clickAction: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  getItemJsx = () => {
    // if (!this.props.item.canDisplay) return null;

    switch (this.props.item.documentAction) {
      case VIEW:
        return (
          <Fragment>
            <div className={this.props.wrappingClassName}>
              <button
                type="button"
                className={`${this.props.className} btn--tooltip`}
                onClick={this.open}
                data-qe-id="action-image_upload-view">
                <GetSvg svg="full_screen" />
                <span className="btn__tooltip">{l('FILE_ACTION-view')}</span>
              </button>
            </div>
          </Fragment>
        );
      case MINIMIZE:
        return (
          <Fragment>
            {this.props.data.url ? (
              <div className={this.props.wrappingClassName}>
                <button
                  type="button"
                  className={`${this.props.className} btn--tooltip`}
                  onClick={this.props.item.action}
                  data-qe-id="action-image_upload-minimize">
                  <GetSvg svg="part_screen" />
                  <span className="btn__tooltip">
                    {l('FILE_ACTION-minimize')}
                  </span>
                </button>
              </div>
            ) : null}
          </Fragment>
        );
      case DOWNLOAD:
        return (
          <Fragment>
            {this.props.data.url ? (
              <div className={this.props.wrappingClassName}>
                <a
                  href={this.props.data.url}
                  download={!!this.props.data}
                  className="btn-svg--primary btn--tooltip">
                  <GetSvg svg="download" />
                  <span className="btn__tooltip">
                    {l('FILE_ACTION-download')}
                  </span>
                </a>
              </div>
            ) : null}
          </Fragment>
        );
      case DELETE:
        return (
          <div className={this.props.wrappingClassName}>
            <button
              type="button"
              className="btn-svg--primary btn--tooltip"
              data-qe-id="action-image_upload-delete"
              onClick={e => {
                e.preventDefault();
                if (this.props.clickAction) {
                  return this.props.clickAction(this.props.data.document_id);
                }

                return this.props.item.action(this.props.data.document_id);
              }}>
              <GetSvg svg="dumpster" />
              <span className="btn__tooltip">{l('FILE_ACTION-delete')}</span>
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  close = () => this.setState({ show: false });

  open = () => this.setState({ show: true });

  render() {
    return (
      <Fragment>
        {this.getItemJsx()}
        <If condition={this.props.showPreviewButton}>
          <PreviewSlider
            open={this.state.show}
            data={this.props.data}
            onCloseClick={this.close}
          />
        </If>
      </Fragment>
    );
  }
}

export default ControlItem;
