import React from 'react';
import { withRouter } from 'react-router-dom';
import NominationForm from 'views/nominations/views/NominationForm';

const InviteFormRender = ({ history, context }) => {
  return (
    <NominationForm
      backAction={() => history.push(`/contractors`)}
      scrollToTop={context.scrollToTop}
      successCallback={newUser => history.push(`/nominations/${newUser.id}`)}
    />
  );
};

export const InviteForm = withRouter(InviteFormRender);
