/** @format */

import React from 'react';
import l from 'helpers/locale';
import { canUseRedactor } from 'helpers/featureDetection';

export function getEditorContent(target: string): string {
  if (canUseRedactor()) {
    // use Redactor
    return window.$R(target, 'source.getCode');
  }

  // get content from targets children
  const node = document.querySelector(target);
  if (!node) return '';
  const children = Array.from(node.children);
  let stringifiedHTML = '';
  if (!children.length) {
    // we have no children so use text content
    stringifiedHTML = node.textContent;
  } else {
    stringifiedHTML = children.reduce(
      (accumulated, current) => `${accumulated}${current.outerHTML}`,
      ``,
    );
  }
  return stringifiedHTML;
}

class Editor extends React.Component {
  state = {
    featureDetectionResult: canUseRedactor(),
  };

  componentDidMount() {
    const { $R } = window;

    // Define a Redactor Plugin
    // pass the update handler into context without
    // messing around with redactor's internals
    (updateHandler =>
      $R.add('plugin', 'myplugin', {
        init(app) {
          this.app = app;
          this.customUpdate = updateHandler;
        },
        onsynced(html) {
          this.customUpdate(html);
        },
      }))(this.addChangeHandler);

    if (canUseRedactor()) {
      $R.lang.selected = {
        format: l('EDITOR-BUTTON_format'),
        paragraph: l('EDITOR-BUTTON_paragraph'),
        heading1: l('EDITOR-BUTTON_h1'),
        heading2: l('EDITOR-BUTTON_h2'),
        heading3: l('EDITOR-BUTTON_h3'),

        bold: l('EDITOR-BUTTON_bold'),
        italic: l('EDITOR-BUTTON_italic'),
        lists: l('EDITOR-BUTTON_lists'),

        unorderedlist: l('EDITOR-BUTTON_ul'),
        orderedlist: l('EDITOR-BUTTON_ol'),
        outdent: l('EDITOR-BUTTON_outdent'),
        indent: l('EDITOR-BUTTON_indent'),
      };

      // eslint-disable-next-line no-undef
      $R('#js-redactor-editor', {
        buttons: ['format', 'bold', 'italic', 'lists'],
        plugins: ['myplugin'],
        lang: 'selected',
        minHeight: '100px',
        formatting: ['p', 'h1', 'h2', 'h3'],
        pastePlainText: false,
        pasteKeepStyle: [],
        pasteKeepClass: [],
        pasteInlineTags: ['br', 'strong', 'em'],
        pasteBlockTags: ['p', 'h1', 'h2', 'h3', 'ul', 'ol', 'li'],
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.content !== nextProps.content) {
      return true;
    }
    return false;
  }

  listener = null;

  addChangeHandler = content => {
    if (this.props.onChange !== false) {
      clearTimeout(this.listener);
      this.listener = setTimeout(() => {
        this.props.onChange(content);
      }, 1000);
    }
  };

  legacyChangeListener = event => {
    const items = event.target.children;
    const stringifiedHTML = Array.from(items).reduce(
      (accumulated, current) => `${accumulated}${current.outerHTML}`,
      ``,
    );
    this.addChangeHandler(stringifiedHTML);
  };

  render() {
    return (
      <div className={this.props.inputClass} data-qe-id={this.props.id}>
        {this.state.featureDetectionResult ? (
          <div
            id="js-redactor-editor"
            className="textarea"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: this.props.content,
            }}
          />
        ) : (
          <div
            contentEditable
            tabIndex="0"
            role="textbox"
            id="js-redactor-editor"
            className="textarea u-bordered--ltr soft--smallest redactor--textarea"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{
              __html: this.props.content,
            }}
            onKeyUp={this.legacyChangeListener}
          />
        )}
      </div>
    );
  }
}

Editor.defaultProps = {
  id: 'input-edit-wysiwyg_editor',
};

export default Editor;
