import React from 'react';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import { interpretErrorMessage } from 'helpers/formatErrors';
import GetSvg from 'components/GetSvg';

type StateShape = {
  list: {
    [string]: {
      contact: string,
      email: string,
      id: string,
      name: string,
      type: string,
    },
  },
};

type ReduxState = {
  jobId: string,
  concernType: string,
  concernId: number,
};

type PropsShape = {
  clickAct: Function,
  hiddenIds: number[],
} & ReduxState;

const baseRequest = {
  loading: false,
  error: false,
  success: false,
  message: '',
};

class List extends React.Component<PropsShape, StateShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      list: {},
      request: baseRequest,
    };
  }

  state: StateShape;

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    this.setState(state => ({
      ...state,
      request: {
        ...baseRequest,
        loading: true,
      },
    }));

    const getData = this.props.listQuery;

    let data = [];
    let isError = false;
    let message = '';

    try {
      // CODE SMELL - too much coupling on Job data
      data = await getData({
        job_id: this.props.jobId,
        concern_id: this.props.concernId,
        concern_type: this.props.concernType,
      });
    } catch (e) {
      isError = true;
      message = e;
    }

    return this.setState({
      list: data.map(item => {
        const nextItem = item;
        if (nextItem.contact_id) {
          // use data consistently
          nextItem.id = nextItem.contact_id;
        }
        return nextItem;
      }),
      request: {
        ...baseRequest,
        success: !isError,
        message,
        failed: isError,
      },
    });
  };

  render(): React$Element<*> {
    return (
      <div className="popup__inner__container__body">
        {this.state.request.loading ? (
          <GetSvg svg="loading" className="loader-base" />
        ) : null}
        {this.state.request.success ? (
          <React.Fragment>
            {this.state.list
              .filter(
                (v: Object): boolean =>
                  !this.props.hiddenIds.includes(v.contact_id),
              )
              .map((v: Object, k: number): React$Element<*> => (
                <div key={k} className="popup__inner__container__body__item">
                  <button
                    type="button"
                    className="gw big-info-btn"
                    onClick={(e: Object): any => {
                      e.preventDefault();
                      return this.props.clickAct(v.id, v);
                    }}>
                    <label className="form__label--small" htmlFor="__">
                      {l(`CONTACT_LIST-${v.type}`)}
                    </label>
                    <div className="g g-1/2 big-info-btn__info text--dk--medium">
                      {v.full_name}
                    </div>
                    {v.email !== '' ? (
                      <div
                        className={
                          'g g-1/2 big-info-btn__info__right' +
                          ' text--dk-medium'
                        }>
                        {v.email}
                      </div>
                    ) : null}
                  </button>
                </div>
              ))}
          </React.Fragment>
        ) : null}
        {this.state.request.failed ? (
          <p>{interpretErrorMessage(this.state.request.message)}</p>
        ) : null}
      </div>
    );
  }
}

const mapState = state => ({
  concernId: state.jobStore.data.job.concern.concern_id,
  concernType: state.jobStore.data.job.concern.concern_type,
  jobId: state.jobStore.data.job.job_id,
});

export default connect(mapState)(List);
