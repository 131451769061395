/** @format */

// @flow

export function getRoleIdFromShortName(roleShort: string): number {
  switch (roleShort) {
    case 'pm':
      return 3;
    case 'adm':
      return 1;
    case 'hw':
      return 5;
    case 'zek':
      return 2;
    case 'lga':
      return 4;
    case 'cm':
      return 6;
    case 'hcm':
      return 7;
    case 'acc':
      return 8;
    default:
      return 0;
  }
}

export function getRoleNameFromId(roleId: string | number): string {
  switch (roleId) {
    case 3:
      return 'pm';
    case 1:
      return 'adm';
    case 5:
      return 'hw';
    case 2:
      return 'zek';
    case 4:
      return 'lga';
    case 6:
      return 'cm';
    case 7:
      return 'hcm';
    case 8:
      return 'acc';
    default:
      return '';
  }
}
