// @flow
export default function orderObjectByKeys(
  obj: Object,
  keys: string[],
): string[] {
  const orderedArray = keys.map(prop => {
    let current = '';

    if (prop.includes('.')) {
      const props = prop.split('.');
      current = props.reduce((acc, curr) => {
        if (!obj[curr]) return acc;

        return `${acc} ${obj[curr]}`;
      }, '');
    } else {
      current = obj[prop];
    }

    return current;
  });
  return orderedArray;
}
