import l from 'helpers/locale';
import {
  NOMINATION_CREATED,
  NOMINATION_EXPORTED,
  NOMINATION_DECLINED,
  NOMINATION_LINKED,
  NOMINATION_DELETED,
  NOMINATIONS_EXPORTING,
  NOMINATION_MAIL_RESENT,
  INVITATION_MAIL_RESENT,
} from 'views/nominations/constants';
import AccountsConstants from 'views/accounts/constants';
import { GRANT_REQUEST_EXPORTING } from 'views/grantRequest/constants';
import { PROPERTIES_EXPORTING } from 'views/properties/constants';
import { CONTRACTORS_EXPORTING } from 'views/contractors/constants';
import { JOBS_EXPORTING } from 'views/jobs/constants';
import JobsConstants from 'shared/jobs/Panels/constants';
// eslint-disable-next-line
import * as CustomerActions from 'shared/contractor/SingleContractor/components/CustomerRelationshipEdit/actions';
import ProjectActions from 'views/singleProject/components/ProjectActions/transitions';
import { FLUSH_LIST, SET_ITEM, REMOVE_ITEM } from '../constants';

const { update: updateProject, save: saveProject } = ProjectActions;
const { updateAccounts } = AccountsConstants;

const { cancel, requestOffers, inviteContractor, resend_grant } = JobsConstants;

type StateShape = {
  [string]: string[],
};

type PayloadShape = {
  type: string,
  payload: Object,
};

const initialState = {
  single_job_actions: [],
  job_list: [],
  nominations_list: [],
  properties_list: [],
  contractors_list: [],
  single_contractor: [],
  single_nomination: [],
  create_job_view: [],
  payload: {},
  single_account: [],
  form: [],
  single_offer_actions: [],
  project_list: [],
  single_project_actions: [],
  create_project_view: [],
  contractor_dashboard: [],
  contractor_profile: [],
};

export default function actionBarReducer(
  state: StateShape = initialState,
  { type, payload, error, origin }: PayloadShape,
): StateShape {
  const failureMessage =
    (error && !error.response && !error.data && 'ACTION_BAR-network_error') ||
    'ACTION_BAR-form_submission-failed';

  if (payload?.status === 500) {
    // ensure the ErrorPop is the ONLY one showing
    return state;
  }

  switch (type) {
    case 'PUBLISH_FAILED_SUBMISSION_FORM':
      return {
        ...state,
        form: [...state.form, failureMessage],
      };
    case updateProject.failed:
      return {
        ...state,
        single_project_actions: [
          ...state.single_project_actions,
          failureMessage,
        ],
      };
    case saveProject.failed:
      return {
        ...state,
        create_project_view: [...state.create_project_view, failureMessage],
      };
    case updateAccounts.failed:
      return {
        ...state,
        single_account: [...state.single_account, failureMessage],
      };
    case requestOffers.success:
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-offers_requested',
        ],
      };
    case requestOffers.failed:
      return {
        ...state,
        single_job_actions: [...state.single_job_actions, failureMessage],
      };
    case cancel.success:
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-job_cancelled',
        ],
      };
    case inviteContractor.success:
      return {
        ...state,
        single_job_actions: [...state.single_job_actions, payload],
      };
    case 'JOB_ACTIONS_SAVE_REQUEST_SUCCESS':
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-saved_job',
        ],
      };
    case resend_grant.success:
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-resend_grant_notification',
        ],
      };
    case 'JOB_ACTIONS_GRANT_REQUEST_SUCCESS':
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-granted_job',
        ],
      };
    case 'JOB_ACTIONS_ACCEPT_REQUEST_SUCCESS':
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-accept_success',
        ],
      };
    case 'JOB_ACTIONS_EXECUTE_REQUEST_SUCCESS':
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-marked_as_executed',
        ],
      };
    case 'JOB_ACTIONS_RATE_REQUEST_SUCCESS':
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-rated_job',
        ],
      };
    case 'JOB_ACTIONS_TO_CHANGE_REQUEST_SUCCESS':
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-updated_job',
        ],
      };
    case 'JOB_ACTIONS_ERASE_REQUEST_SUCCESS':
      return {
        ...state,
        job_list: [...state.job_list, 'ACTION_BAR-job_removed'],
      };
    case 'JOB_ACTIONS_COPY_REQUEST_SUCCESS':
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-job_copied',
        ],
      };
    case NOMINATION_CREATED:
      return {
        ...state,
        nominations_list: [
          ...state.nominations_list,
          'ACTION_BAR-invitation_sent',
        ],
      };
    case NOMINATION_DELETED:
      return {
        ...state,
        nominations_list: [
          ...state.nominations_list,
          'ACTION_BAR-nomination_removed',
        ],
      };
    case NOMINATION_EXPORTED:
      return {
        ...state,
        single_nomination: [
          ...state.single_nomination,
          'ACTION_BAR-nomination_exported',
        ],
      };
    case NOMINATION_DECLINED:
      return {
        ...state,
        single_nomination: [
          ...state.single_nomination,
          'ACTION_BAR-nomination_declined',
        ],
      };
    case NOMINATION_MAIL_RESENT:
      return {
        ...state,
        single_nomination: [
          ...state.single_nomination,
          'ACTION_BAR-nomination_mail_resent',
        ],
      };
    case INVITATION_MAIL_RESENT:
      return {
        ...state,
        single_nomination: [
          ...state.single_nomination,
          'ACTION_BAR-invitation_mail_resent',
        ],
      };
    case NOMINATION_LINKED:
      return {
        ...state,
        single_nomination: [
          ...state.single_nomination,
          l('ACTION_BAR-nomination_linked').replace(
            ':contractor_name',
            payload.contractorName,
          ),
        ],
        payload,
      };
    case FLUSH_LIST:
      return {
        ...state,
        [payload.name]: [],
      };
    case SET_ITEM:
      return {
        ...state,
        [payload.name]: [...state[payload.name], payload.message],
      };
    case REMOVE_ITEM:
      return {
        ...state,
      };
    case 'CREATE_JOB_ASSIGN_REQUEST_FAILED':
      return {
        ...state,
        create_job_view: [...state.create_job_view, failureMessage],
      };
    case 'JOB_ACTIONS_TO_CHANGE_REQUEST_FAILED':
      return {
        ...state,
        single_job_actions: [...state.single_job_actions, failureMessage],
      };
    case PROPERTIES_EXPORTING:
      return {
        ...state,
        properties_list: [
          ...state.properties_list,
          'ACTION_BAR-export-initiated',
        ],
      };
    case JOBS_EXPORTING:
      return {
        ...state,
        job_list: [...state.job_list, 'ACTION_BAR-export-initiated'],
      };
    case GRANT_REQUEST_EXPORTING:
      return {
        ...state,
        single_job_actions: [
          ...state.single_job_actions,
          'ACTION_BAR-export-initiated',
        ],
      };

    case CONTRACTORS_EXPORTING:
      return {
        ...state,
        contractors_list: [
          ...state.contractors_list,
          'ACTION_BAR-export-initiated',
        ],
      };
    case NOMINATIONS_EXPORTING:
      return {
        ...state,
        nominations_list: [
          ...state.nominations_list,
          'ACTION_BAR-export-initiated',
        ],
      };
    case 'SINGLE_ACCOUNT_UPDATE_SUCCESS':
      return {
        ...state,
        single_account: [
          ...state.single_account,
          'SINGLE_ACCOUNT-update_success',
        ],
      };
    case CustomerActions.REQUEST_SUCCESS:
      return {
        ...state,
        single_contractor: [
          ...state.single_contractor,
          'SINGLE_CONTRACTOR-update_success',
        ],
      };
    case CustomerActions.REQUEST_ERROR:
      return origin === 'contractor'
        ? {
            ...state,
            single_contractor: [
              ...state.single_contractor,
              'SINGLE_CONTRACTOR-update_error',
            ],
          }
        : {
            ...state,
            single_nomination: [
              ...state.single_nomination,
              'NOMINATION-submit-error',
            ],
          };
    default:
      return state;
  }
}
