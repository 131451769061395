function initializeCompliance() {
  return `{
    vmp_contractor {
      contractor_id
      billing_company
      street
      post_city
      key_user_phone
      uid_number
      compliance {
        state_machine {
          available_transitions {
            name
            action
          }
        }
      }
    }
  }`;
}

export default initializeCompliance;
