import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import routeDispatcher from './routeDispatcher.js';
import reducers from '../reducers';
import historyCore from '../helpers/history';

let devTools = f => f;

// eslint-disable-next-line no-underscore-dangle
if (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
  // eslint-disable-next-line no-underscore-dangle
  devTools = window.__REDUX_DEVTOOLS_EXTENSION__();
}

function createAppStore(history) {
  return createStore(
    combineReducers({
      ...reducers,
      router: connectRouter(history),
    }),
    compose(
      applyMiddleware(thunk, routerMiddleware(history), routeDispatcher),
      devTools,
    ),
  );
}

export default createAppStore(historyCore);
