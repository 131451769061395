import React from 'react';
import history from 'helpers/history';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import JobFooterItem from 'shared/jobs/Panels/items/Footer';
import { updateSimpleList } from 'components/ConnectedSimpleList/actions';

import { JobMachine } from 'views/singleJob';
import { transitions } from 'machines/constants';
import { SliderContext } from 'components/RouteSlider';

const { to_load } = transitions;

const FooterActions = ({ backAction, refreshList, showFooter, jobId }) => {
  if (showFooter === true) {
    const refreshData = () =>
      JobMachine.triggerTransition(to_load, { job_id: jobId });

    return (
      <SliderContext.Consumer>
        {sliderContext => (
          <JobFooterItem
            scrollToTop={sliderContext.scrollToTop}
            backAction={
              backAction ||
              function() {
                history.push('/jobs');
              }
            }
            refreshData={refreshData}
            refreshList={refreshList}
          />
        )}
      </SliderContext.Consumer>
    );
  }
  return null;
};

const mapState = ({ jobStore }) => ({
  showFooter: jobStore.request.success,
  jobId: jobStore.data.job?.job_id,
});

const mapActions = (dispatch: Function) => ({
  refreshList(): any {
    return dispatch(updateSimpleList('jobs'));
  },
});

export default withRouter(connect(mapState, mapActions)(FooterActions));
