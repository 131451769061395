import React from 'react';
import { SimplePopup } from '@bonlineza/b-lib';
import l from 'helpers/locale';

const ErrorPoup = ({ isOpen, callback }) => {
  return (
    <SimplePopup
      isOpen={isOpen}
      close={callback}
      title={l('POPUP-error-title')}
      description={l('POPUP-error-body')}
      options={[
        {
          cb: callback,
          buttonText: l('POPUP-error-ok_button'),
          buttonClass: 'btn--primary',
        },
      ]}
    />
  );
};

export default ErrorPoup;
