/** @format */

// @flow

import React, { Fragment } from 'react';
import GetSvg from 'components/GetSvg';
import Contact from 'components/GraphQLContactBlock/components/ContactBody.js';
import { ContentDisplayRows } from 'components/ContentDisplayer';
import l from 'helpers/locale';

type PropsShape = {
  downloadUrl: string,
  address: string[],
};

const BillingOrder = ({
  downloadUrl,
  address,
  address_account,
}: PropsShape): React$Element<*> => (
  <Fragment>
    <p className="fw--bold">{l('INVOICE-PANEL-description')}</p>
    <div className="billing-order">
      <div className="billing-order__group--left">
        <div className="billing-order__item--coversheet">
          <div className="billing-order__item__svg">
            <GetSvg svg="file_barcode_noviewbox" />
          </div>
          <div className="billing-order__item__content">
            <span className="billing-order__item__text">
              {l('BILLING-ORDER-coversheet')}
            </span>
            <span className="billing-order__item__text">
              <a href={downloadUrl} download="coversheet.pdf">
                {l('ACTION-download-here')}
              </a>
            </span>
          </div>
        </div>

        <div className="billing-order__item--plus">
          <div className="billing-order__item__svg">
            <GetSvg svg="fat_plus" />
          </div>
        </div>

        <div className="billing-order__item--invoice">
          <div className="billing-order__item__svg">
            <GetSvg svg="file_noviewbox" />
          </div>
          <div className="billing-order__item__content">
            <span className="billing-order__item__text">
              {l('BILLING-ORDER-invoice')}
            </span>
          </div>
        </div>
      </div>

      <div className="billing-order__group--right">
        <div className="billing-order__item--post">
          <div className="billing-order__item__svg">
            <div className="billing-order__item__svg__icon">
              <GetSvg svg="open_envelope" />
            </div>
            <div className="billing-order__item__svg__content">
              <div className="billing-order__item__svg__content__block">
                <label className="form__label--small" htmlFor="__">
                  {l('PER_EMAIL-title')}
                </label>
                <p className="text--dk--flushed">
                  <span>{l('PER_EMAIL-content')}</span>
                </p>
              </div>
              <div className="billing-order__item__svg__content__block">
                <label className="form__label--small" htmlFor="__">
                  {l('PER_POST-title')}
                </label>
                <Contact contact={address} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div className="billing-owner">
      <label className="billing-owner__header">
        {l('BILLING_OWNER-title')}
      </label>
      <div className="billing-owner__text">{l('BILLING_OWNER-content')}</div>

      <p className="billing-owner__paragraph">
        <ContentDisplayRows rows={address_account} />
      </p>
    </div>
  </Fragment>
);

BillingOrder.defaultProps = {
  address: [],
  downloadUrl: '',
  address_account: [],
};
export default BillingOrder;
