/** @format */

// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import l from 'helpers/locale';
import Card from 'components/Card';
import SimpleList, {
  SimpleListItemArrayFormatter,
} from 'components/ConnectedSimpleList';
import ActionBar from 'components/RouteSlider/contextWrappers/ActionBar';
import ActionGroup from './components/ActionGroup';

type ReduxStateShape = {
  userType: number,
};

type PropsShape = {
  history: Object,
  location: Object,
} & ReduxStateShape;

class Projects extends React.Component<PropsShape> {
  constructor(props: PropsShape) {
    super(props);
    this.state = {
      initialSearch: props.location.state ? props.location.state.search : '',
    };
  }

  getFilterOptions() {
    return [
      {
        text: l('PREDEFINED_SEARCH-my_projects'),
        value: `owner:${this.props.email}`,
      },
      {
        text: l('PREDEFINED_SEARCH-running_projects'),
        value: `running_projects:true`,
      },
    ];
  }

  openProject = ({ id }): any => this.props.history.push(`/projects/${id}`);

  render(): React$Element<*> {
    return (
      <div>
        <ActionBar name="project_list" />
        <Card
          watermarkName="project"
          title="PROJECT-plural"
          canCollapse={false}
          asideComponent={ActionGroup(this.props.userType)}>
          <SimpleList
            api="/api/1/view/projects"
            bgcAlt
            name="project_list"
            headings={[
              {
                name: 'project_id',
                sortable: true,
                text: l('PROJECT-singular'),
              },
              {
                name: 'description',
                sortable: false,
                text: l('JOB-COL-description'),
              },
              {
                name: 'address',
                sortable: true,
                text: l('PROP-COL-address'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'owner',
                sortable: true,
                text: l('PROJECT-owner'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
              {
                name: 'last_change',
                sortable: true,
                text: l('JOB-COL-last_update'),
              },
              {
                name: 'status',
                sortable: true,
                text: l('JOB-COL-status'),
                customFormatter: SimpleListItemArrayFormatter(),
              },
            ]}
            clickAct={this.openProject}
            filterOpts={this.getFilterOptions()}
            initialSearch={this.state.initialSearch}
            showDatepicker
          />
        </Card>
      </div>
    );
  }
}

const mapState = (state: Object): ReduxStateShape => ({
  email: state.authStore.user.email,
  userType: state.authStore.user.role,
});

export default withRouter(connect(mapState)(Projects));
