/**
 * /* eslint-disable
 *
 * @format
 */

import React from 'react';
import Engb from './impressum-en-gb';
import Dech from './impressum-de-ch';
import Frch from './impressum-fr-ch';

const Impressum = ({ lang }) => {
  switch (lang) {
    case 'en-gb':
      return <Engb />;
    case 'de-ch':
      return <Dech />;
    case 'fr-ch':
      return <Frch />;
    default:
      return <Dech />;
  }
};

export default Impressum;
