/** @format */

export const NOMINATION_CREATED = 'NOMINATION_LIST_CREATED_SUCCESS';
export const NOMINATION_EXPORTED = 'NOMINATION_LIST_EXPORTED_SUCCESS';
export const NOMINATION_DECLINED = 'NOMINATION_LIST_DECLINED_SUCCESS';
export const NOMINATION_LINKED = 'NOMINATION_LIST_LINKED_SUCCESS';
export const NOMINATION_DELETED = 'NOMINATION_LIST_DELETED_SUCCESS';
export const NOMINATIONS_EXPORTING = 'NOMINATION_LIST_EXPORTING';
export const NOMINATION_MAIL_RESENT =
  'NOMINATION_LIST_NOMINATION_RESENT_SUCCESS';
export const INVITATION_MAIL_RESENT =
  'NOMINATION_LIST_INVITATION_RESENT_SUCCESS';
