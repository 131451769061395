import React, { Component } from 'react';
import l, { getLocale } from 'helpers/locale';
import { SingleDatePicker } from 'components/Dates';
import moment from 'moment';

type propsShape = {
  isEditing: boolean,
  classSuffix: string,
  onUpdate: Function,
  defaultValue: number,
  currentValue: string | number,
  error: boolean,
};

class ProjectedEndDate extends Component {
  constructor(props: propsShape) {
    super(props);
    moment.locale(getLocale()); // Ensure Date Selection Locale is Set Correctly
    this.state = {
      target_date: moment.unix(props.defaultValue),
      selectorFocus: false,
    };
  }

  render = () => (
    <div
      className={`form-and-view-container ${this.props.classSuffix} ${
        this.props.error ? 'form-and-view-container--error' : ''
      }`}>
      <label className="form__label--small" htmlFor="__">
        {l('PROJECT_target-date')}
      </label>
      <div
        className="custom-date-picker form__input"
        data-qe-id="project-input-select-target_date_submission">
        <SingleDatePicker
          showClearDate
          verticalSpacing={10}
          hideKeyboardShortcutsPanel
          disabled={!this.props.isEditing}
          placeholder={l('CALENDAR-select_date')}
          numberOfMonths={1}
          displayFormat="LL"
          date={this.state.target_date}
          onDateChange={(date: Object): any => {
            this.setState({ target_date: date });
            return this.props.onUpdate({
              key: 'target_date',
              value: date ? parseInt(date.format('X'), 10) : null,
            });
          }}
          focused={this.state.selectorFocus}
          onFocusChange={({ focused }: boolean): boolean => {
            this.setState({
              selectorFocus: focused,
            });
          }}
        />
      </div>
    </div>
  );
}

export default ProjectedEndDate;
