/** @format */

// @flow
/*
 * taken from https://developer.mozilla.org/
 * en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
 *
 */
// export default function b64EncodeUnicode(str: string): string {
//   // first we use encodeURIComponent to get percent-encoded UTF-8,
//   // then we convert the percent encodings into raw bytes which
//   // can be fed into btoa.
//   return window.btoa(encodeURIComponent(str).replace(
//     /%([0-9A-F]{2})/g,
//     (match: string, p1: number): string => String.fromCharCode(
//       parseInt(`0x${p1}`, 10),
//     ),
//   ));
// }

export default function stringBase(str: string): string {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i += 1) {
    const chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0;
  }
  return `#${hash}`;
}
