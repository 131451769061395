/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import Card from 'components/Card';
import { StateConditional } from 'machines/components/RenderConditional';
import ContactBlock from 'components/GraphQLContactBlock';
import { ContentDisplayRows } from 'components/ContentDisplayer';
import l from 'helpers/locale';

import MachineBuilder from './machine';
import { updateContact, createContact } from '../../actions';

type ReduxStateShape = {
  isEditting: boolean,
};

type ReduxActionsShape = {
  updateContact: string => number => any,
};

export const ContactsCardMachine = MachineBuilder();

const ContactsCard = ({
  address_account,
  address_audit,
  address_scan_center,

  contact_allianz,
  contact_allianz_form,

  contact_care_taker,
  contact_care_taker_form,

  contact_tenant,
  contact_tenant_form,

  contact_delivery,
  contact_delivery_form,

  contactCreate,
  contactUpdate,

  machine_state,
  panelEditMode,

  forceEdit,
}) => {
  /* @robguy21
   * in creation we do not use state machines
   * but I really don't want to duplicate this entire component for creation
   * because that would a bit insane.
   */
  const machineStateOverride = !forceEdit ? 'EDITING' : '';
  const isEditing = forceEdit || panelEditMode;

  const allianzData = isEditing ? contact_allianz_form : contact_allianz;
  const careTakerData = isEditing
    ? contact_care_taker_form
    : contact_care_taker;
  const tenantData = isEditing ? contact_tenant_form : contact_tenant;
  const deliveryData = isEditing ? contact_delivery_form : contact_delivery;

  return (
    <StateConditional
      state={machine_state || machineStateOverride}
      whenState={['VISIBLE', 'EDITING']}>
      <Card title="JOB-contacts-title" isOpen qeId="job_card-contacts">
        <div className="gw">
          {address_account ? (
            <div className="g g-1/3">
              <div className="push--small--bottom">
                <label className="form__label--small" htmlFor="">
                  {l('JOB_CONTACT-allianz_accounting_address')}
                </label>

                <ContentDisplayRows rows={address_account} />
              </div>
            </div>
          ) : null}
          {address_audit ? (
            <div className="g g-1/3" data-qe-id="contact-audit">
              <div className="push--small--bottom">
                <label className="form__label--small" htmlFor="">
                  {l('JOB-audit_contact')}
                </label>

                <ContentDisplayRows rows={address_audit} />
              </div>
            </div>
          ) : null}
          {address_scan_center ? (
            <div className="g g-1/3" data-qe-id="contact-scan_center">
              <div className="push--small--bottom">
                <label className="form__label--small" htmlFor="">
                  {l('JOB_CONTACT-scan_center')}
                </label>

                <ContentDisplayRows rows={address_scan_center} />
              </div>
            </div>
          ) : null}
          <div className="g g-1/3" data-qe-id="contact-allianz">
            <div className="push--small--bottom">
              <ContactBlock
                contact={allianzData}
                label={l('JOB_CONTACT-allianz')}
                isEditing={isEditing}
                type="allianz"
                update={contactUpdate('contact_allianz')}
              />
            </div>
          </div>
          {deliveryData ? (
            <div className="g g-1/3" data-qe-id="contact-delivery">
              <div className="push--small--bottom">
                <ContactBlock
                  contact={deliveryData}
                  label={l('JOB_CONTACT-delivery')}
                  isEditing={isEditing}
                  type="delivery"
                  update={contactUpdate('contact_delivery')}
                  create={contactCreate('contact_delivery')}
                />
              </div>
            </div>
          ) : null}
          {careTakerData ? (
            <div className="g g-1/3" data-qe-id="contact-care_taker">
              <div className="push--small--bottom">
                <ContactBlock
                  contact={careTakerData}
                  label={l('JOB_CONTACT-care_taker')}
                  isEditing={isEditing}
                  type="care_taker"
                  update={contactUpdate('contact_care_taker')}
                />
              </div>
            </div>
          ) : null}
          {tenantData ? (
            <div className="g g-1/3" data-qe-id="contact-tenant">
              <div className="push--small--bottom">
                <ContactBlock
                  contact={tenantData}
                  label={l('JOB_CONTACT-tenant')}
                  isEditing={isEditing}
                  type="tenant"
                  update={contactUpdate('contact_tenant')}
                />
              </div>
            </div>
          ) : null}
        </div>
      </Card>
    </StateConditional>
  );
};

ContactsCard.defaultProps = {
  forceEdit: false,
};

const mapState = ({ jobStore, machineStore }): ReduxStateShape => ({
  panelEditMode: machineStore.contacts_card === 'EDITING',
  machine_state: machineStore.contacts_card,

  address_audit: jobStore.data.job?.address_audit,
  address_scan_center: jobStore.data.job?.address_scan_center,
  address_account: jobStore.data.job?.address_account,

  contact_allianz: jobStore.data.job?.contact_allianz,
  contact_care_taker: jobStore.data.job?.contact_care_taker,
  contact_tenant: jobStore.data.job?.contact_tenant,
  contact_delivery: jobStore.data.job?.contact_delivery,

  contact_allianz_form: jobStore.form.contact_allianz,
  contact_care_taker_form: jobStore.form.contact_care_taker,
  contact_tenant_form: jobStore.form.contact_tenant,
  contact_delivery_form: jobStore.form.contact_delivery,
});

const mapActions = (dispatch: Function): ReduxActionsShape => ({
  contactUpdate(target: string): Function {
    return (contact: Object) => dispatch(updateContact(target, contact));
  },
  contactCreate(target: string): Function {
    return (contact: Object) => dispatch(createContact(target, contact));
  },
});

export default connect(mapState, mapActions)(ContactsCard);
