import { ids } from 'constants/roles';

const isInternalStaff = ({ user }) =>
  [
    ids.ADMINISTRATION,
    ids.HEAD_CONSTRUCTION_MANAGER,
    ids.CONSTRUCTION_MANAGER,
    ids.ACCOUNT_MANAGER,
    ids.CENTRAL_PROCUREMENT,
    ids.PROPERTY_MANAGER,
  ].includes(user.role);

export default isInternalStaff;
