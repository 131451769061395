/** @format */

// @flow
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import l from 'helpers/locale';

import ForgotForm from './index.js';

class ForgotPasswordWrapper extends Component {
  constructor(props: Object) {
    super(props);
    this.state = {
      didRequest: false,
      success: false,
    };
  }

  render = () => (
    <div className="wrap--small">
      <div className="layout-container">
        {(this.state.didRequest && (
          <Fragment>
            <h1>
              <span className="layout-container__header__lt-section">
                {l('FORGOT_PASSWORD-change')}
              </span>
              {l('FORGOT_PASSWORD-change_header_2')}
            </h1>
            <div className="layout-container__body">
              <div className="gw">
                <div className="g g-1/1">
                  <div className="layout-container__body-section">
                    {(this.state.success && l('FORGOT_PASSWORD-email-sent')) ||
                      l('FORGOT_PASSWORD-email-not-sent')}
                  </div>
                </div>
              </div>
            </div>
            <div className="align--right">
              <button
                type="button"
                className="btn--primary"
                data-qe-id="action-login-submit"
                onClick={this.props.prev}>
                {l('FORGOT_PASSWORD-back_to_login_button')}
              </button>
            </div>
          </Fragment>
        )) || (
          <Fragment>
            <h1 className="layout-container__header__lt-section">
              {l('FORGOT_PASSWORD-title')}
            </h1>
            <div className="layout-container__header__subtitle-section">
              {l('FORGOT_PASSWORD-subtitle')}
            </div>
            <div className="gw">
              <div className="g g-1/1">
                <ForgotForm
                  goLogin={this.props.prev}
                  requestCB={success =>
                    this.setState({ didRequest: true, success })
                  }
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

ForgotPasswordWrapper.propTypes = {
  prev: PropTypes.func,
};

ForgotPasswordWrapper.defaultProps = {
  prev: undefined,
};

export default ForgotPasswordWrapper;
