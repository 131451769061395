import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import l from 'helpers/locale';
import scroll from 'functions/scroll';
import formatString from 'functions/formatString';
import GetSvg from 'components/GetSvg';
import {
  SimpleList,
  SimpleListSections,
  SimpleListHeader,
  SimpleListBody,
  SimpleListItem,
  Filter,
  PredefinedFilter,
  DateRangePicker,
} from '@bonlineza/b-lib';
import type { SimpleListPropsShape } from '@bonlineza/b-lib';
import AllianzPaginator from 'components/AllianzPaginator';
import * as Actions from './actions';

export {
  SimpleListItemArrayFormatter,
  SimpleListItemRatingFormatter,
} from './helpers/formatters';

type ReduxActionShape = {
  setInitialQuery: Function,
  updateQueriesFromString: Function,
  makeBasicRequest: Function,
  flushListData: Function,
  updatePerPage: Function,
  sortList: Function,
  groupList: Function,
  searchList: Function,
  paginateList: Function,
  filterListByDate: Function,
};

type ConnectedSimpleListPropsShape = SimpleListPropsShape & {
  actions: ReduxActionShape,
};

const AllianzDateRangePicker = props => (
  <DateRangePicker
    {...props}
    startDatePlaceholderText={l('CALENDAR-select_date')}
    endDatePlaceholderText={l('CALENDAR-select_date')}
  />
);

const AllianzPredefinedFilter = props => (
  <PredefinedFilter
    {...props}
    filterButtonContent={() => (
      <GetSvg
        svg="filter_menu"
        wrapperClass="list-filter__item__filter-btn__svg"
      />
    )}
  />
);

const AllianzSimpleListFilter = props => (
  <Filter
    {...props}
    clearInputButton={({ onClick, isEmpty }) =>
      !isEmpty ? (
        <button onClick={onClick} type="button" className="btn--text">
          <GetSvg svg="circle_clear" />
        </button>
      ) : null
    }
    isLoadingContent={() => <GetSvg svg="loading" wrapperClass="loader-base" />}
    PredefinedFilterComponent={AllianzPredefinedFilter}
    DateRangePickerComponent={AllianzDateRangePicker}
    searchInputLabel={l('FILTER-text')}
    searchInputPlaceholderText={l('FILTER-text-placeholder')}
    datePickerLabel={l('SELECT_DATE_RANGE')}
    groupSelectionLabel={l('SELECT_GROUPING')}
  />
);

const AllianzSortRenderer = ({ active = false, type = 'asc' }) => (
  <div>
    {(active === true && type === 'asc' && (
      <div className="simple-list__header__item__aside">
        <GetSvg svg="sort_up" wrapperClass="u-fill--base-dkst" />
        <GetSvg svg="sort_down" wrapperClass="u-fill--base-lt" />
      </div>
    )) ||
      (active === true && type === 'desc' && (
        <div className="simple-list__header__item__aside">
          <GetSvg svg="sort_up" wrapperClass="u-fill--base-lt" />
          <GetSvg svg="sort_down" wrapperClass="u-fill--base-dkst" />
        </div>
      )) ||
      (active === false && (
        <div className="simple-list__header__item__aside">
          <GetSvg svg="sort_up" wrapperClass="u-fill--base-lt" />
          <GetSvg svg="sort_down" wrapperClass="u-fill--base-lt" />
        </div>
      )) ||
      null}
  </div>
);

// Use AllianzaSortRenderer in AllianzSimpleListHeader
const AllianzSimpleListHeader = () => (
  <SimpleListHeader SortComponent={AllianzSortRenderer} />
);

// Allianz SimpleItem will use 'formatString' by default, unless specified otherwise
export const AllianzSimpleItem = props => (
  <SimpleListItem
    {...props}
    customFormatter={props.customFormatter || formatString}
  />
);

// Use AllianzaSimpleItem in AllianzSimpleListBody
const AllianzSimpleListBody = () => (
  <SimpleListBody
    SimpleItemComponent={AllianzSimpleItem}
    PaginatorComponent={AllianzPaginator}
  />
);
// Use AllianzaSimpleItem in AllianzSimpleListBody
const AllianzSimpleListSections = () => (
  <SimpleListSections
    SimpleItemComponent={AllianzSimpleItem}
    PaginatorComponent={AllianzPaginator}
  />
);

const ConnectedSimpleList = (props: ConnectedSimpleListPropsShape) => {
  useEffect(() => {
    if (props.initial_sort) {
      props.actions.updateQueriesFromString(props.name, props.initial_sort);
    }
    if (props.initialSearch || props.presetQuery) {
      props.actions.setInitialQuery(
        props.name,
        props.presetQuery,
        props.initialSearch,
      );
    }
    props.actions.makeBasicRequest(props.name, props.api);
    return () => props.actions.flushListData(props.name);
    // eslint-disable-next-line
  }, []);

  const updateQuery = (type, ...rest) => {
    switch (type) {
      case 'per-page':
        props.actions.updatePerPage(props.name, props.api, rest[0]);
        break;
      case 'sort':
        props.actions.sortList(props.name, props.api, rest[0], rest[1]);
        break;
      case 'group':
        if (props.groupSelectionCB) {
          props.groupSelectionCB(rest[0]);
        }
        props.actions.groupList(props.name, props.api, rest[0]);
        break;
      case 'search':
        if (props.onSearch) {
          props.onSearch(rest[0]);
        }
        props.actions.searchList(props.name, props.api, rest[0]);
        break;
      case 'paginate':
        scroll(rest[1]);
        props.actions.paginateList(props.name, props.api, rest[0]);
        break;
      case 'date-filter':
        props.actions.filterListByDate(props.name, props.api, rest[0], rest[1]);
        break;
      default:
        props.actions.makeBasicRequest(props.name, props.api);
        break;
    }
  };
  return (
    <SimpleList
      FilterComponent={AllianzSimpleListFilter}
      HeaderComponent={AllianzSimpleListHeader}
      BodyComponent={AllianzSimpleListBody}
      SectionsComponent={AllianzSimpleListSections}
      {...props}
      updateQuery={updateQuery}
    />
  );
};

const mapStateWrapper = ({ simpleListStore }, { name }): Object => ({
  initial_sort: simpleListStore.list[name].queries.sort,
  sortString: simpleListStore.list[name].queries.sortString,
  data: simpleListStore.list[name].data,
  initialLoad: simpleListStore.list[name].initialLoad.loading,
  isLoading: simpleListStore.list[name].loadingData.loading,
  currentPage: simpleListStore.list[name].queries.currentPage,
  lastPage: simpleListStore.list[name].queries.lastPage,
  perPage: simpleListStore.list[name].queries.perPage,
});

const mapDispatchWrapper = (dispatch: any): ReduxActionShape => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(
  mapStateWrapper,
  mapDispatchWrapper,
)(ConnectedSimpleList);
