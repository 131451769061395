/** @format */

// @flow
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GetSvg from 'components/GetSvg';
import ContractorPanel from 'shared/jobs/Panels/items/Contractor';
import { StateConditional } from 'machines/components/RenderConditional';
import { ids as JobStatus } from 'constants/jobStatus';
import { ids as RoleId } from 'constants/roles';
import MachineBuilder from './machine';

import { updateJobForm } from '../../actions';

type ReduxActionShape = {
  update: Function,
};

type ReduxStateShape = {
  data?: {
    contractor_id: string,

    billing_company: string,
    billing_lang_code: string,
    billing_email: string,
    billing_telephone: string,

    street: string,
    pobox: string,
    postal_code: string,
    city: string,
    country: string,
    website: string,

    key_user_id: number,
    key_user_salutation: string,
    key_user_phone: string,
    key_user_full_name: string,
    key_user_email: string,

    has_profile: boolean,
    post_city: string,
  },
  trade: { key?: string | void, value: string },
  trades: Array<{ key: string, value: string }>,
  remainingTime?: number,
  status: string,
  contractor_status?: string,
  editNow: boolean,
};

export const ContractorCardMachine = MachineBuilder();

const ContractorPageButton = withRouter(({ contractor_id, history, match }) => (
  <div>
    <button
      className="btn--text--base"
      type="button"
      onClick={() =>
        history.push(`/jobs/${match.params.job_id}/contractor/${contractor_id}`)
      }>
      <GetSvg svg="angle_right" wrapperClass="card__header__handle" />
    </button>
  </div>
));

const ContractorCard = ({
  data,
  formData,
  concern,
  trades,
  trade,
  selected_trade,
  status,
  panelEditMode,
  getNewInformation,
  update,
  remainingTime,
  contractor_status,
  machine_state,
  roleId,
}) => {
  const relevantData = panelEditMode ? formData : data;
  return (
    <StateConditional state={machine_state} whenState={['VISIBLE', 'EDITING']}>
      <ContractorPanel
        data={{
          contractor: relevantData ? { ...relevantData } : null,
          trades,
          trade,
        }}
        concern={{
          concern_id: concern.concern_id,
          concern_type: concern.concern_type,
        }}
        updateField={update}
        jobState={status}
        editNow={panelEditMode}
        updateParent={getNewInformation}
        selectedTradeId={selected_trade}
        remainingTime={remainingTime}
        contractorStatus={contractor_status}
        asideComponent={() =>
          status >= JobStatus.GRANTED &&
          status <= JobStatus.COMPLETED &&
          roleId !== RoleId.CONTRACTOR && (
            <ContractorPageButton
              contractor_id={relevantData?.contractor_id || null}
            />
          )
        }
      />
    </StateConditional>
  );
};

ContractorCard.defaultProps = {
  remainingTime: 0,
  contractor_status: '',
  data: {},
  trades: [],
  trade: { key: '0', value: '' },
};

const mapState = ({ authStore, machineStore, jobStore }): ReduxStateShape => ({
  roleId: authStore.user.role,
  panelEditMode: machineStore.contractor_card === 'EDITING',
  machine_state: machineStore.contractor_card,
  formData: jobStore.form.vmp_contractor,
  data: jobStore.data.job.vmp_contractor,
  remainingTime: jobStore.data.job.remaining_time,
  concern: jobStore.data.job.concern,
  trade: jobStore.data.job.trade,
  status: jobStore.data.job.info_status,
  contractor_status: jobStore.data.job.contractor_status,
  selected_trade: jobStore.form.trade_id,
  trades: jobStore.data.trades,
});

const mapActions = (dispatch: Function): ReduxActionShape => ({
  update(key: string, value: string): any {
    return dispatch(updateJobForm(key, value));
  },
});

export default connect(mapState, mapActions)(ContractorCard);
