/** @format */

// @flow

import React from 'react';
import Panel from './descriptionPanel.js';

const DescriptionPanelWrapper = ({
  isEditing,
  updateField,
  storeTargetLabel,
  data,
}) => (
  <Panel
    isEditing={isEditing}
    data={data}
    updateField={updateField}
    storeTargetLabel={storeTargetLabel}
  />
);

export default DescriptionPanelWrapper;
