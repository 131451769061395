// @flow
import {
  getDeliveryContacts,
  getContractorContacts,
} from 'components/GraphQLContactBlock/queries';

export function getJobContacts({ job_id }: { job_id: number }) {
  return getDeliveryContacts({ job_id });
}

export function getGrantContractors({ job_id }: { job_id: string | number }) {
  return getContractorContacts({ job_id });
}
