import React, { Component } from 'react';
import { connect } from 'react-redux';
import GenericNotifications, {
  localiseTitle,
} from 'components/GenericNotifications';
import type { NotificationShape } from 'components/NotificationContainer';
import Card from 'components/Card';
import { getNotifications } from '../../query';
import getTitle from '../../helper/getProjectTitle';

type ReduxActionShape = {
  notifications: Array<NotificationShape>,
};

type PropsShape = {} & ReduxActionShape;

const NotificationCard = ({
  notifications,
  project,
  total,
}: PropsShape): Component => (
  <Card title="NOTIFICATIONS-title">
    <GenericNotifications
      notifications={notifications}
      asideTitle={localiseTitle(getTitle(project))}
      total={total}
      asideRequest={() => getNotifications(project.project_id)}
    />
  </Card>
);

NotificationCard.defaultProps = {
  notifications: [],
};

const mapState = ({ projectStore }): ReduxActionShape => ({
  notifications: projectStore.data.notifications,
  total: projectStore.data.notifications_count,
  project: projectStore.data,
});

export default connect(mapState)(NotificationCard);
