import React, { Fragment } from 'react';
import l from 'helpers/locale';
import { defaultLang } from 'constants/lang';
import AddContactPopup from '../AddContactPopup';

type ContractorShape = {
  salutation: string,
  name: string,
  email: string,
  id: number,
};

type ContactShape = {
  id: 'new' | number,
  name: string,
  email: string,
  type: string,
};

type ListShape = {
  name: string,
  rowComponent: Function,
  listQuery: Function,
  initialData?: Array<ContactShape | ContractorShape>,
};

type StateShape = {
  notify: Array<ContactShape>,
  copy: Array<ContactShape>,
};

type ContactModalProps = {
  onChange: Function,
  lists: Array<ListShape>,
};

class MultiList extends React.Component<ContactModalProps> {
  constructor(props: ContactModalProps) {
    super(props);
    this.state = props.lists.reduce(
      (pv, cv) => ({ ...pv, [cv.name]: cv.initialData || [] }),
      {},
    );
  }

  addItem = (list: string, contact: Object): any => {
    this.setState(
      (prevState: StateShape): StateShape => ({
        ...prevState,
        [list]: [
          ...prevState[list],
          {
            id: contact.contact_id || contact.id,
            name: contact.full_name || contact.name || '',
            email: contact.email || '',
            type: contact.type,
            lang: contact.lang || defaultLang.value,
          },
        ],
      }),
      this.contactsChanged,
    );
  };

  removeItem = (list: string, index: number): any =>
    this.setState(
      (prevState: StateShape): StateShape => ({
        ...prevState,
        [list]: [
          ...prevState[list].slice(0, index),
          ...prevState[list].slice(index + 1),
        ],
      }),
      this.contactsChanged,
    );

  contactsChanged = () => {
    this.props.onChange(this.state);
  };

  render = () => {
    return (
      <Fragment>
        {this.props.lists.map((list, listIndex) => (
          <Fragment key={listIndex}>
            <div className="gw">
              <div className="g g-1/1">
                <label htmlFor="" className="form__item__label">
                  {list.label}
                </label>
              </div>
            </div>
            {this.state[list.name].map(
              (item: Object, itemIndex: number): React$Element => {
                const ItemRow = list.rowComponent;
                return (
                  <ItemRow
                    key={itemIndex}
                    data={item}
                    onRemoveClick={() => this.removeItem(list.name, itemIndex)}
                  />
                );
              },
            )}
            <div>
              <hr className="flush--bottom" />
              {list.listQuery ? (
                <Fragment>
                  <div className="multi-list__add-button pos-relative display--iblock push--bottom">
                    <AddContactPopup
                      buttonLabel={l('ADD-ADDRESS')}
                      onAdd={item => this.addItem(list.name, item)}
                      listQuery={list.listQuery}
                      hiddenIds={this.state[list.name].map(
                        (v: Object): string => v.id,
                      )}
                    />
                  </div>
                </Fragment>
              ) : null}
            </div>
          </Fragment>
        ))}
      </Fragment>
    );
  };
}

export default MultiList;
