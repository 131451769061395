/** @format */

// @flow
import React from 'react';
import Lists from '../../lists';

const { HouseList, AssetList, PropertyList } = Lists;
type TargetShape = 'property' | 'house' | 'rental_object';

type PropsShape = {
  target: TargetShape,
  act: Function,
};

// CODE SMELL: nested ternaries
const ListWrapper = ({ target, act }: PropsShape): React$Element<*> =>
  (target === 'rental_object' && <AssetList nextAct={act} />) ||
  (target === 'house' && <HouseList nextAct={act} />) ||
  (target === 'property' && <PropertyList nextAct={act} />) ||
  '' ||
  '';

export default ListWrapper;
