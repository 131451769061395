// @flow
import React from 'react';
import { createPortal } from 'react-dom';

type ListenerShape = {
  loading: boolean,
  name: string,
};

type Props = {
  listeners: Array<ListenerShape>,
  children: any, // expected to be ButtonGroup
};

function throwDevelopmentError(message) {
  if (process.env.NODE_ENV !== 'production') {
    throw new Error(message);
  }
}

export const TIME_BEFORE_SHOW = 1500;
export const MINIMUM_TIME_BEFORE_REMOVE = 1000;

export function useRequestLoader(listeners: Array<ListenerShape>) {
  /*
   * we need both an in flight flag as well as a show loader flag
   * because these two behave fairly different and track different cases
   * for example - set flight needs to be made true immediately but
   * loader should only be true after {TIME_BEFORE_SHOW} ms has passed
   */
  const [inFlight, setFlight] = React.useState(false);
  const [showLoader, setLoader] = React.useState(false);

  const [activeRequest, setRequest] = React.useState(null);

  const loaderCanUnset = React.useRef(true);
  const loaderPromise = React.useRef();

  // just a super easy way to reset all of our state variables
  function resetStates() {
    setFlight(false);
    setLoader(false);
    setRequest(null);
  }
  const Loader = React.useCallback(
    (function() {
      let timeout = null;
      return {
        end() {
          clearTimeout(timeout);

          if (loaderCanUnset.current === true) {
            // alls clear - we can end this whole process now
            resetStates();
          } else if (typeof loaderPromise.current.then === 'function') {
            // let's just wait for this to finish then
            loaderPromise.current.then(() => {
              resetStates();
            });
          }
        },
        start(name) {
          setFlight(true);
          setRequest(name);

          timeout = setTimeout(() => {
            setLoader(true);
            // we can attach a promise to this ref so that our
            // start function can just wait until it's resolved - woo hoo
            loaderCanUnset.current = false;
            loaderPromise.current = new Promise(res => {
              // we need the loader to show for at least a few seconds
              // before allowing it to disappear
              setTimeout(() => {
                loaderCanUnset.current = true;
                res(true);
              }, MINIMUM_TIME_BEFORE_REMOVE);
            });
          }, TIME_BEFORE_SHOW);
        },
      };
    })(),
    [],
  );

  React.useEffect(() => {
    // we first find the request which is loading
    const current = listeners.filter(listener => listener.loading);

    if (!current.length && activeRequest) {
      // no requests are loading, this means that the request has finished
      Loader.end();
    } else if (current.length) {
      /* okay we have loaders, and we only care about the first one,
       * if there is more than one we can throw an error here
       * because this is a developer problem
       */
      if (current.length > 1) {
        throwDevelopmentError(
          `Encountered multiple loaders - ${JSON.stringify(current)}`,
        );
      }

      const [request] = current;
      if (request.name !== activeRequest && activeRequest !== null) {
        /* how does this happen? why does this happen? why did you suddenly
         * change the loader without telling me the preivous one stopped?
         * why do you hate me so end user?
         * why......?
         */
        throwDevelopmentError(
          `Unexpected request encountered - Received ${request.name} expected ${activeRequest}`,
        );
      }

      if (activeRequest === null) {
        Loader.start(request.name);
      }
      /* the else here isn't necessary because it would only be triggered
       * when request.name === activeRequest and this is a case
       * we don't really want to care about
       */

      // what else do we need here.... hmmmmm......
    }
  }, [listeners, Loader, activeRequest]);

  /*
   * Clean up for loader needs to happen separately because otherwise
   * we're going to run the clean up every time active request changes
   * which causes loopooloopooloops
   */
  React.useEffect(() => () => Loader.end(), [Loader]);

  return [inFlight, showLoader, Loader];
}

export function ScreenLoader({ show }: { show: boolean }) {
  const portalLocation = '#react-fullscren-loader';

  function render() {
    return (
      <div
        className={`screen-loader ${
          show ? 'screen-loader--visible' : 'screen-loader--hidden'
        }`}
      />
    );
  }

  return createPortal(render(), document.querySelector(portalLocation));
}

function WithLoader(props: Props) {
  const [inFlight, showLoader] = useRequestLoader(props.listeners);

  const child = React.Children.only(props.children);
  return (
    <React.Fragment>
      <ScreenLoader show={showLoader} />
      {React.cloneElement(child, { disableButtons: inFlight })}
    </React.Fragment>
  );
}

export default WithLoader;
