/** @format */

import React, { Fragment } from 'react';
import { parseDate } from 'helpers/dates';
import GetSvg from 'components/GetSvg';
import l from 'helpers/locale';
import states from 'constants/complianceStates';

const getComplianceSvgObj = status => {
  const defaults = {
    label: status ? l(`CONTRACTOR-compliance-${status}`) : '-',
  };

  switch (status) {
    case states.passed:
      return {
        ...defaults,
        spanClass: 'fc--primary',
        svgName: 'circle_check',
      };
    case states.unchecked:
      return {
        ...defaults,
        spanClass: 'fc--base-lt',
        svgName: 'triangle_ex',
      };
    case states.failed:
      return {
        ...defaults,
        spanClass: 'fc--secondary',
        svgName: 'circle_clear',
      };
    case states.refresh_needed:
    case states.submitted:
    case states.timed_out:
    case states.initiated:
    default:
      return {
        ...defaults,
        spanClass: 'fc--quaternary',
        svgName: 'triangle_ex',
      };
  }
};

const ComplianceField = ({ complianceStatus, complianceDate = false }) => {
  const thisCompliance = getComplianceSvgObj(complianceStatus);

  return (
    <Fragment>
      <label className="form__label--small" htmlFor="__">
        {l('CONTRACTOR-last_questionaire')}
      </label>
      <div className="fl-left">
        <div
          className={`align-self--end texticon--dk--flushed soft--smallest--right ${thisCompliance.spanClass}`}>
          <GetSvg svg={thisCompliance.svgName} wrapperClass="" />
        </div>
        <div>
          <div className="text--dk--flushed display--inline">
            {thisCompliance.label}
          </div>
          {complianceDate ? (
            <div className="fs--milli">
              {`${l('COMPLIANCE-last_updated')} ${parseDate(complianceDate)}`}
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default ComplianceField;
