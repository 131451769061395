/** @format */

import React from 'react';

const InvoiceSummaryLayout = ({
  TotalInput,
  DiscountInput,
  CashDiscountInput,
  PaymentConditionsInput,
  TotalDisplay,
  DiscountDisplay,
  GrossDisplay,
  GrossInclCash,
  VatDisplay,
}) => (
  <div className="g g-1/1">
    <hr />
    <div className="gw">
      <div className="g g-1/2">
        <div className="gw">
          <div className="g g-1/4">{TotalInput()}</div>
          <div className="g g-1/4">{DiscountInput()}</div>
          <div className="g g-1/4">{CashDiscountInput()}</div>
          <div className="g g-1/4">{PaymentConditionsInput()}</div>
        </div>
      </div>
      <div className="g g-1/2">
        <div className="gw">
          <div className="g g-1/1">{TotalDisplay()}</div>
        </div>
        <div className="gw">
          <div className="g g-1/1">{DiscountDisplay()}</div>
        </div>
      </div>
    </div>
    <hr className="push--small--bottom" />
    <div className="gw">
      <div className="g g-1/2" />
      <div className="g g-1/2">
        <div className="gw">
          <div className="g g-1/1">{GrossDisplay()}</div>
        </div>
        <div className="gw">
          <div className="g g-1/1">{VatDisplay()}</div>
        </div>
        <div className="gw">
          <div className="g g-1/1">{GrossInclCash()}</div>
        </div>
      </div>
    </div>
  </div>
);

export default InvoiceSummaryLayout;
